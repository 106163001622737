import { FC } from "react";

import IconFilter from "@/components/_icons/IconFilter";
import { Button } from "@/components/_ui/button";
import { Cross2Icon } from "@radix-ui/react-icons";

import { useClimateActionListState } from "../../../hooks";
import { ClimateActionFilterSelect } from "../../climate-action-list-filter-pane-view/climate-action-filter-select-dropdown";

export interface ClimateActionListFilterButtonProps {}

export const ClimateActionListFilterButton: FC<ClimateActionListFilterButtonProps> = () => {
  const { columnFilters, table } = useClimateActionListState();

  return (
    <ClimateActionFilterSelect side="bottom" align="end" asChild={true} data-testid="climate-action-list-filter-button">
      <Button
        variant="ghost"
        size="xs"
        className="h-6 w-full justify-start overflow-x-hidden border text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
        onClick={(e) => {
          if (columnFilters.length > 0) {
            e.preventDefault();
            e.stopPropagation();
            table.setColumnFilters([]);
          }
        }}
      >
        {columnFilters.length === 0 && (
          <>
            <IconFilter className="mr-2 h-3.5 w-3.5 text-foreground/60" />
            Filter
          </>
        )}
        {columnFilters.length > 0 && (
          <>
            Filter entfernen
            <Cross2Icon className="ml-2 h-3.5 w-3.5 text-foreground/60" />
          </>
        )}
      </Button>
    </ClimateActionFilterSelect>
  );
};
