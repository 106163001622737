import { Outlet } from "react-router-dom";

import { CurrentFocusAreaListContextProvider } from "@/components/_domain/focusArea/hooks/useCurrentFocusAreaListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";

export const FocusAreaListLayout = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  return (
    <CurrentFocusAreaListContextProvider workspaceId={currentWorkspace.id}>
      <Outlet />
    </CurrentFocusAreaListContextProvider>
  );
};
