import { graphql } from "../generated/gql";

export const loginMutationDocument = graphql(/* GraphQL */ `
  mutation login($input: LoginInput!) {
    login(loginInput: $input) {
      token
    }
  }
`);

export const refreshAccessTokenMutationDocument = graphql(/* GraphQL */ `
  mutation refresh {
    refresh {
      token
    }
  }
`);

export const requestPasswordResetLinkMutationDocument = graphql(/* GraphQL */ `
  mutation requestPasswordResetLink($input: PasswordResetLinkRequestInput!) {
    requestPasswordResetLink(passwordResetLinkRequestInput: $input) {
      success
      email
      message
    }
  }
`);

export const checkPasswordResetTokenMutationDocument = graphql(/* GraphQL */ `
  mutation checkPasswordResetToken($input: PasswordResetTokenCheckInput!) {
    checkPasswordResetToken(passwordResetTokenCheckInput: $input) {
      success
      email
      message
    }
  }
`);

export const updatePasswordWithTokenMutationDocument = graphql(/* GraphQL */ `
  mutation updatePasswordWithToken($input: PasswordUpdateWithTokenInput!) {
    updatePasswordWithToken(passwordUpdateWithTokenInput: $input) {
      success
      email
      message
    }
  }
`);
