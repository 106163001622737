import { type FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ClimateAction } from "@/components/_domain/climate-action/models/climateAction";
import { PhaseList } from "@/components/_domain/phase/phase-type";
import { type Workspace } from "@/components/_domain/workspace/models/workspace-model";
import { BarChart } from "@/components/charts/bar-chart";
import { GaugeChart } from "@/components/charts/gauge-chart";
import { PieChart } from "@/components/charts/pie-chart";
import { useClimateActionDashboardStats } from "@/hooks/api/common/useClimateAction/useClimateActionDashboardStats";

import { columns } from "./dashboard-table/columns";
import { PHASE_NAME_MAP } from "./dashboard-table/constants";
import { DataTable } from "./dashboard-table/datatable";

interface ClimateActionDashboardProps {
  workspace: Workspace;
  climateActionList: ClimateAction[];
  navigateToUrl: string;
}

export const DashboardOverview: FC<ClimateActionDashboardProps> = ({ workspace, climateActionList, navigateToUrl }) => {
  const navigate = useNavigate();

  const {
    climateActionTotal,
    completedClimateActionCount,
    climateActionPerProgressSnapshotByDate,
    climateActionPerFocusArea,
    climateActionPerPhaseList,
  } = useClimateActionDashboardStats({
    workspace,
    climateActionList,
  });

  const formattedPhaseList = useMemo(() => {
    if (!climateActionPerPhaseList) return [];

    const PHASE_COLORS: Record<string, string> = {
      [PhaseList.backlog]: "#4C1D95",
      [PhaseList.unstarted]: "#6D28D9",
      [PhaseList.started]: "#8B5CF6",
      [PhaseList.completed]: "#C4B5FD",
      [PhaseList.canceled]: "#EDEDED",
    };

    return Object.keys(PHASE_COLORS).map((phase: string) => ({
      name: climateActionPerPhaseList[phase]?.name || PHASE_NAME_MAP[phase],
      phaseType: phase,
      count: climateActionPerPhaseList[phase]?.count || 0,
      color: PHASE_COLORS[phase],
    }));
  }, [climateActionPerPhaseList]);

  const navigateToClimateActionListView = (searchParams?: string | null | undefined) => {
    navigate(`${navigateToUrl}?${searchParams}`);
  };

  return (
    <div className="overflow-y-auto">
      <div className="flex flex-col gap-6 p-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <GaugeChart
            remainingSectionConfig={{
              label: "Offen",
              value: (climateActionTotal ?? 0) - (completedClimateActionCount ?? 0),
              color: "#EEF2FF",
            }}
            completedSectionConfig={{
              label: "Abgeschlossen",
              value: completedClimateActionCount ?? 0,
              color: "#4F46E5",
            }}
            text="Maßnahmen abgeschlossen"
            title="Maßnahmen gesamt"
          />
          <BarChart
            data={formattedPhaseList}
            navigateToClimateActionListView={navigateToClimateActionListView}
            title={"Maßnahmen nach Phasen"}
          />
          <PieChart
            data={climateActionPerProgressSnapshotByDate}
            navigateToClimateActionListView={navigateToClimateActionListView}
          />
        </div>

        {/* Table Section */}
        <DataTable columns={columns} data={climateActionPerFocusArea} />
      </div>
    </div>
  );
};
