import { ClimateAction } from "@/components/_domain/climate-action/models";
import { sortStatus } from "@/components/_domain/progress-snapshot";
import { ColumnDef } from "@tanstack/react-table";

import { ClimateActionListColumnHeader, ClimateActionListStatusCell } from "../..";
import { filterByIdWithUnknownPayload } from "../../filter-functions";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import { FilterByStatusConfigurationView, FilterByStatusDisplayView, FilterByStatusFilterPaneView } from "./components";
interface StatusColumnDefinitionProps {
  workspaceId: string;
  columnId: string;
  orderValue: number;
}

export const StatusColumnDefinition = ({
  columnId,
  orderValue,
}: StatusColumnDefinitionProps): ClimateActionColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Status",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<ClimateAction> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          if (rowA.original.progressSnapshotList.length > 0 && rowB.original.progressSnapshotList.length === 0) {
            return 1;
          }
          if (rowB.original.progressSnapshotList.length > 0 && rowA.original.progressSnapshotList.length === 0) {
            return -1;
          }

          if (rowA.original.progressSnapshotList.length === 0) {
            return 0;
          }
          if (rowB.original.progressSnapshotList.length === 0) {
            return 0;
          }

          return sortStatus(rowA.original.progressSnapshotList[0].status, rowB.original.progressSnapshotList[0].status);
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          if (row.original.progressSnapshotList.length === 0) {
            return filterByIdWithUnknownPayload(value, null);
          }
          // Todo: problem – the progress Snapshot at the start of the array is not always the most recent one
          return filterByIdWithUnknownPayload(value, row.original.progressSnapshotList[0].status);
        },
        size: 1, // set to 1 instead to 0 for it to work in Safari
        header: ({ column }) => (
          <ClimateActionListColumnHeader column={column} title="" className="px-1 text-xs font-normal" />
        ),
        cell: ({ row }) => <ClimateActionListStatusCell row={row} className="mx-2" />,
      };
    },
    getDisplayView: () => <FilterByStatusDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByStatusConfigurationView column={{ id: columnId }} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "popover",
    getFilterPaneView: () => <FilterByStatusFilterPaneView column={{ id: columnId }} />,
  };
};
