import { FC, useEffect, useMemo, useState } from "react";

import {
  FilterByTextList,
  FilterByTextListPayload,
  FilterByTextListPayloadSchema,
} from "@/components/_domain/climate-action/climate-action-list-overview/filter-functions";
import { useClimateActionListState } from "@/components/_domain/climate-action/hooks";
import { FocusAreaView } from "@/components/_domain/focusArea";
import { Checkbox } from "@/components/_ui/checkbox";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { useGetFocusAreaList } from "@/hooks/api/common/useFocusArea";
import { cn } from "@/lib/utils";

export interface FilterByFocusAreaConfigurationViewProps {
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByFocusAreaConfigurationView: FC<FilterByFocusAreaConfigurationViewProps> = ({
  onFinished,
  ...props
}) => {
  const columnId = props.column.id;
  const { table, columnFilters, config } = useClimateActionListState();
  const { focusAreaList } = useGetFocusAreaList({
    workspaceId: config.workspace.id,
  });

  const [selectedFocusAreaNameList, setSelectedFocusAreaNameList] = useState<FilterByTextList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(columnId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByTextListPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedFocusAreaNameList(filterValue.textList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedFocusAreaNameList) {
      return;
    }

    if (selectedFocusAreaNameList.length === 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_text_list",
      mode: filterValue?.mode ?? "included",
      textList: selectedFocusAreaNameList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFocusAreaNameList]);

  const setFilter = (payload: FilterByTextListPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleSelect = (focusAreaName: string | null, shouldFinish?: boolean) => {
    const selectedNameList = selectedFocusAreaNameList ?? [];
    const updatedNameList = selectedNameList.slice().filter((name) => name !== focusAreaName);
    setSelectedFocusAreaNameList([...updatedNameList, focusAreaName]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (focusAreaName: string | null, shouldFinish?: boolean) => {
    const selectedNameList = selectedFocusAreaNameList ?? [];
    const updatedNameList = selectedNameList.slice().filter((name) => name !== focusAreaName);
    setSelectedFocusAreaNameList(updatedNameList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command>
      <CommandInput placeholder={"FocusArea"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedFocusAreaNameList && !!selectedFocusAreaNameList.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <FocusAreaView focusArea={null} className="h-3.5 w-3.5 rounded-full" />
              </CommandItem>
            );
          })}

          {focusAreaList.map((focusArea) => {
            const isSelected =
              !!selectedFocusAreaNameList && !!selectedFocusAreaNameList.find((name) => name === focusArea.name);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={focusArea.id}
                value={focusArea.name}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(focusArea.name, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(focusArea.name);
                  }}
                />
                <FocusAreaView focusArea={focusArea} className="h-3.5 w-3.5" />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
