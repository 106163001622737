import { FC, useCallback, useMemo, useState } from "react";
import * as z from "zod";

import { Milestone, MilestoneSchema } from "@/components/_domain/milestone";
import { MilestoneCheckbox } from "@/components/_domain/milestone/milestone-checkbox";
import { ValueWithUnit } from "@/components/_domain/unit";
import IconFlag from "@/components/_icons/IconFlag";
import { Button } from "@/components/_ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import { useGetKeyResult } from "@/hooks/api/common/useKeyResult";
import { cn } from "@/lib/utils";

export interface ProgressSnapshotValueMilestoneInputProps {
  keyResult: {
    id: string;
  };
  value?: {
    value: number;
    properties: object;
  };
  onValueChange?: (
    value:
      | {
          value: number;
          properties: object;
        }
      | undefined,
  ) => void;
}

const milestonePropertiesSchema = z.object({
  milestones: z.array(MilestoneSchema),
});

export const ProgressSnapshotValueMilestoneInput: FC<ProgressSnapshotValueMilestoneInputProps> = ({
  onValueChange,
  ...props
}) => {
  const defaultMilestones = useMemo(() => {
    if (!props.value) {
      return undefined;
    }
    const parsedProperties = milestonePropertiesSchema.safeParse(props.value.properties);

    if (parsedProperties.success) {
      return parsedProperties.data.milestones;
    }

    return undefined;
  }, [props.value]);
  const { milestoneKeyResult } = useGetKeyResult({ id: props.keyResult.id });
  const [showPopover, setShowPopover] = useState(false);
  const [savedValue, setSavedValue] = useState<number | undefined>(props.value ? props.value.value : undefined);
  const [savedMilestones, setSavedMilestones] = useState<Milestone[] | undefined>(defaultMilestones);

  const compareMilestoneLists = (a: Milestone[], b: Milestone[]) => {
    for (const aMilestone of a) {
      const bMilestone = b.find((ms) => ms.id === aMilestone.id);
      if (bMilestone && bMilestone.completed !== aMilestone.completed) {
        return false; // something was changed
      }
    }
    return true;
  };

  const fieldWasModified = useCallback(() => {
    if (!milestoneKeyResult) {
      return false;
    }
    if (savedValue === undefined) {
      return false;
    }

    if (savedValue !== milestoneKeyResult.currentValue) {
      return true;
    }

    if (savedMilestones === undefined) {
      return false;
    }

    if (!compareMilestoneLists(milestoneKeyResult.properties.milestones, savedMilestones)) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedValue]);

  const handleValueChanged = (milestoneList: Milestone[]) => {
    const completedMilestoneList = milestoneList.filter((ms) => {
      return ms.completed;
    });
    const newValue = completedMilestoneList.length;
    setSavedValue(newValue);
    setSavedMilestones(milestoneList);

    // notify the parent component about the change
    if (!onValueChange) {
      return;
    }
    if (!milestoneKeyResult) {
      return;
    }

    if (compareMilestoneLists(milestoneKeyResult.properties.milestones, milestoneList)) {
      // we are back at the start, so no change should be saved
      onValueChange(undefined);
      return;
    }

    const properties = {
      ...milestoneKeyResult?.properties,
      milestones: milestoneList,
    };

    onValueChange({ value: newValue, properties: properties });
  };

  if (!milestoneKeyResult) {
    return <></>;
  }

  return (
    <Popover open={showPopover} onOpenChange={setShowPopover}>
      <PopoverTrigger asChild={true}>
        <Button
          variant={"ghost"}
          size="xs"
          className={cn(["shadow-sm rounded-md border text-left text-xs font-normal"])}
        >
          <IconFlag
            className={cn([
              "ml-auto mr-1 h-2.5 w-2.5 opacity-50",
              savedValue !== undefined ? "text-primary opacity-100" : "",
            ])}
          />
          <span>{milestoneKeyResult.currentValue}</span>
          {savedValue !== undefined && (
            <div className="flex h-full flex-row items-center">
              <span className="ml-0.5 px-0.5">→</span>
              <ValueWithUnit
                value={savedValue}
                unit={milestoneKeyResult.unitId ? { id: milestoneKeyResult.unitId } : undefined}
              />
            </div>
          )}
          <span>
            {"/"}
            {milestoneKeyResult.targetValue}
            {milestoneKeyResult.currentValue === 1 && " Meilenstein"}
            {milestoneKeyResult.currentValue !== 1 && " Meilensteine"}
          </span>
          {fieldWasModified() && <div className="ml-1 h-1.5 w-1.5 rounded-full bg-primary"></div>}
        </Button>
      </PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="w-auto">
        <div className="w-56">
          <div className="mb-1 text-xs">{milestoneKeyResult.title}</div>
          <div className="mb-1 text-xs text-foreground/60">Meilensteine</div>

          <div>
            <MilestoneCheckbox
              defaultValue={savedMilestones ?? milestoneKeyResult.properties.milestones}
              onValueChange={handleValueChanged}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ProgressSnapshotValueMilestoneInput;
