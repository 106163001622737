import { teamsForUserInWorkspaceQueryDocument } from "@/components/_domain/team/hooks/useTeam/team.gql";
import {
  joinTeamMutationDocument,
  teamMembershipsForTeamQueryDocument,
} from "@/components/_domain/team/hooks/useTeam/team-membership.gql";
import { JoinTeamMutation } from "@/graphql/generated/graphql";
import { EVENT_TEAM_JOIN, trackTeamEvent } from "@/hooks/api/common/useWorkspace/tracking";
import { useMutation } from "@apollo/client";

export type JoinedTeamMembership = JoinTeamMutation["joinTeam"];

export interface UseJoinTeamProps {
  workspace: {
    id: string;
  };
  team: {
    id: string;
  };
  onCompleted?: (o: JoinedTeamMembership | undefined) => void;
  onError?: (e: Error) => void;
}

export const useTeamJoin = ({ onCompleted, onError, ...props }: UseJoinTeamProps) => {
  const [joinTeam, queryResult] = useMutation(joinTeamMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const joinTeamMembership = data?.joinTeam;
      if (!joinTeamMembership) {
        return;
      }
      trackTeamEvent(EVENT_TEAM_JOIN, joinTeamMembership);
    },
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
      {
        query: teamsForUserInWorkspaceQueryDocument,
        variables: { input: props.workspace.id },
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const joinTeamMembership = o?.joinTeam as JoinedTeamMembership;

    if (onCompleted) {
      onCompleted(joinTeamMembership ?? undefined);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    joinTeam,
  };
};
