import { FC, useMemo } from "react";

import { useClimateActionListState } from "../../hooks";

import { AddAdditionalFilterButton } from "./climate-action-filter-select-dropdown/components/add-additional-filter-button";

export interface ClimateActionListFilterViewProps {}

export const ClimateActionListFilterView: FC<ClimateActionListFilterViewProps> = () => {
  const { table, columnFilters, fn } = useClimateActionListState();

  const columns = useMemo(() => {
    return table
      .getAllColumns()
      .slice()
      .flatMap((column) => {
        if (!column.getCanFilter()) {
          return [];
        }

        const filterValue = column.getFilterValue();
        if (!filterValue) {
          return [];
        }

        return column;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, columnFilters]);

  if (columns.length === 0) {
    return <></>;
  }
  return (
    <div className="max-w-screen relative flex h-[45px] shrink-0 items-center justify-between gap-2 border-b border-solid px-6 text-sm">
      <div className="flex flex-row items-center justify-start gap-1" data-testid="filter-pane-view">
        {columns.map((column) => {
          const filterPaneView = fn.getConfigurationForColumn(column.id)?.getFilterPaneView();

          if (!filterPaneView) {
            return <></>;
          }

          return <div key={column.id}>{filterPaneView}</div>;
        })}
        <AddAdditionalFilterButton />
      </div>
      <div className="flex grow flex-row items-center justify-start"></div>
      <div className="flex flex-row items-center justify-end gap-2"></div>
    </div>
  );
};
