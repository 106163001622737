import { Outlet } from "react-router-dom";

import { CurrentIndicatorListContextProvider } from "@/components/_domain/indicator/hooks/useCurrentIndicatorListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { NotFoundView } from "@/components/not-found-view";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";

import { IndicatorCreateContextProvider } from "../components/indicator-forms/hooks/useIndicatorCreateContext";

export const IndicatorListLayout = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: currentWorkspace.id,
    action: "manage",
  });

  if (!hasPermissionToManageWorkspace) {
    return <NotFoundView />;
  }

  return (
    <CurrentIndicatorListContextProvider workspaceId={currentWorkspace.id}>
      <IndicatorCreateContextProvider workspaceId={currentWorkspace.id}>
        <Outlet />
      </IndicatorCreateContextProvider>
    </CurrentIndicatorListContextProvider>
  );
};
