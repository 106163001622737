import { Outlet, useParams } from "react-router-dom";

import { ClimateActionCreateHandlerProvider } from "@/components/_domain/climate-action/hooks/useCreateClimateActionHandler";
import { GlobalSavedViewsProvider } from "@/components/_domain/saved-view/hooks/useGlobalSavedViewContext";
import {
  CurrentWorkspaceContextProvider,
  useCurrentWorkspaceContext,
} from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { BasePageLayout } from "@/components/_layout/base-page-layout";
import { SidebarApp } from "@/components/_layout/sidebar-app/sidebar-app";
import { NotFoundView } from "@/components/not-found-view";
// load all essential data for the workspace
import { PreLoader } from "@/components/preloader";
import { ProductlaneWidget } from "@/components/productlane-widget";
// Make sure all child components have access to the workspace object
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

const InnerLayout = () => {
  const { currentUser } = useCurrentUserContext();
  const { currentWorkspace } = useCurrentWorkspaceContext();

  return (
    <GlobalSavedViewsProvider workspaceId={currentWorkspace.id}>
      <PreLoader workspaceId={currentWorkspace.id} userId={currentUser.id}>
        <BasePageLayout
          renderSidebar={() => <SidebarApp workspace={currentWorkspace} user={currentUser} />}
          renderContent={() => (
            <ClimateActionCreateHandlerProvider>
              <Outlet />
            </ClimateActionCreateHandlerProvider>
          )}
        />
      </PreLoader>
      <ProductlaneWidget />
    </GlobalSavedViewsProvider>
  );
};

export function WorkspaceRootLayout() {
  const { workspaceUrl } = useParams();
  const { currentUser } = useCurrentUserContext();

  if (!workspaceUrl) {
    return <NotFoundView />;
  }

  return (
    <CurrentWorkspaceContextProvider workspaceUrl={workspaceUrl} userId={currentUser.id}>
      <InnerLayout />
    </CurrentWorkspaceContextProvider>
  );
}
