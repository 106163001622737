import { updateLabelGroupMutationDocument } from "@/graphql/common/label-group";
import { type UpdateLabelGroupMutation } from "@/graphql/generated/graphql";
import { useMutation } from "@apollo/client";

import { EVENT_LABEL_GROUP_UPDATED, trackLabelEvent } from "./tracking";

export type UpdatedLabelGroup = UpdateLabelGroupMutation["updateLabelGroup"];

export interface UseUpdateLabelGroupProps {
  onCompleted?: (data: UpdatedLabelGroup) => void;
  onError?: (e: Error) => void;
}

export const useUpdateLabelGroup = ({ onCompleted, onError }: UseUpdateLabelGroupProps = {}) => {
  const [updateLabelGroup] = useMutation(updateLabelGroupMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const updatedLabelGroup = data?.updateLabelGroup;
      if (!updatedLabelGroup) {
        return;
      }
      trackLabelEvent(EVENT_LABEL_GROUP_UPDATED, updatedLabelGroup);
    },
  });

  function onUpdateCompleted(data: UpdateLabelGroupMutation) {
    if (onCompleted) {
      onCompleted(data.updateLabelGroup);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }
  return {
    updateLabelGroup,
  };
};
