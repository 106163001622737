import { FC } from "react";

import { FocusAreaIcon } from "@/components/_domain/focusArea/FocusAreaIcon";
import { cn } from "@/lib/utils";
interface FocusAreaViewEmptyProps {
  logoOnly?: boolean;
  className?: string;
}

export const FocusAreaViewEmpty: FC<FocusAreaViewEmptyProps> = ({ ...props }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <FocusAreaIcon className={cn(["h-5 w-5 ", props.className])} />
      {!props.logoOnly && <div className="text-xs font-normal">Kein Handlungsfeld</div>}
    </div>
  );
};

export default FocusAreaViewEmpty;
