import React, { useEffect, useState } from "react";

import { Dialog, DialogContentWithoutClose, DialogTrigger } from "@/components/_ui/dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

import { TaskEditForm } from "./task-edit-form";
import { Task } from "./task-type";

interface TaskEditDialogProps {
  task: Task;
  children?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const TaskEditDialog: React.FC<TaskEditDialogProps> = ({ task, children, open, onOpenChange }) => {
  const [taskDetailOpen, setTaskDetailOpen] = useState(open);

  useEffect(() => {
    setTaskDetailOpen(open);
  }, [open]);

  const handleOpenChange = (open: boolean) => {
    setTaskDetailOpen(open);
    onOpenChange?.(open);
  };

  const handleSave = () => {
    // do nothing and keep the dialog open
  };

  const handleDelete = () => {
    setTaskDetailOpen(false);
  };

  return (
    <Dialog open={taskDetailOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild={true}>{children}</DialogTrigger>
      <DialogContentWithoutClose
        className="top-[1%] max-w-3xl translate-y-[0%] p-0 md:top-[20%]"
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === "Escape") {
            handleOpenChange(false);
          }
        }}
      >
        <TaskEditForm task={task} onSuccess={handleSave} onDelete={handleDelete} />

        <div
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:cursor-pointer hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500 dark:ring-offset-slate-950 dark:focus:ring-slate-300 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-400"
          onClick={() => {
            handleOpenChange(false);
          }}
        >
          <Cross2Icon className="h-4 w-4" />
          <span className="sr-only">Schließen</span>
        </div>
      </DialogContentWithoutClose>
    </Dialog>
  );
};
