import { FC } from "react";

import { Badge } from "@/components/_ui/badge";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";

interface ClimateActionBadgeProps {
  climateAction: {
    id: string;
  };
}
export const ClimateActionBadge: FC<ClimateActionBadgeProps> = ({ ...props }) => {
  const { climateAction } = useGetClimateAction(props.climateAction);

  if (!climateAction) {
    return <></>;
  }

  return (
    <Badge variant="secondary" className="text-xs font-light">
      {climateAction.title}
    </Badge>
  );
};
