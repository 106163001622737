export class APIError extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }

  toArray(): string[] {
    return [this.message];
  }
}

export class UnknownAPIError extends Error {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }

  toArray(): string[] {
    return [this.message];
  }
}

export class ValidationAPIError extends APIError {
  validationErrors: string[];

  constructor(message: string, statusCode: number, validationErrors: string[]) {
    super(message, statusCode);
    this.validationErrors = validationErrors;
  }

  toArray(): string[] {
    return this.validationErrors;
  }
}

type APIResponse = {
  statusCode: number;
  message: string | string[];
};

export const createAPIError = (data: APIResponse): APIError => {
  const statusCode = data.statusCode;
  const message = Array.isArray(data.message) ? data.message.toString() : data.message;

  if (statusCode === 400) {
    const validationErrors = Array.isArray(data.message) ? data.message : [data.message];

    return new ValidationAPIError(message, 400, validationErrors);
  }

  return new APIError(message, data.statusCode);
};
