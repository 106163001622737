import { FC, useMemo } from "react";

import { OwnerType } from "@/components/_domain/labels/models/owner-types";
import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import { useGetLabelInstanceList } from "@/hooks/api/common/useLabel";

import { ClimateActionLabelInstanceItem } from "./climate-action-label-instance-item";

interface ClimateActionLabelInstanceViewProps {
  owner: {
    id: string;
    type: OwnerType;
  };
  workspace: Workspace;
}

export const ClimateActionLabelInstanceView: FC<ClimateActionLabelInstanceViewProps> = ({ workspace, owner }) => {
  const { labelInstanceList } = useGetLabelInstanceList({ ownerId: owner.id });

  const filteredLabelInstanceList = useMemo(() => {
    return labelInstanceList.filter((li) => !li.label?.labelGroupId);
  }, [labelInstanceList]);

  if (filteredLabelInstanceList.length === 0) {
    return <></>;
  }
  return (
    <div className="mb-5 flex flex-row items-start gap-2">
      <div className="w-24 shrink-0 overflow-hidden text-ellipsis font-medium"></div>
      <div className="flex grow flex-row flex-wrap gap-1">
        {filteredLabelInstanceList.map((labelInstance) => {
          return (
            <ClimateActionLabelInstanceItem
              key={labelInstance.id}
              workspace={workspace}
              owner={owner}
              labelInstance={labelInstance}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ClimateActionLabelInstanceView;
