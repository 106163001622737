import FocusAreaView from "@/components/_domain/focusArea/FocusAreaView/FocusAreaView";
import { PhaseIcon } from "@/components/_domain/phase";
import { PhaseList } from "@/components/_domain/phase/phase-type";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";
import { Link } from "@/components/link";
import { Row } from "@tanstack/react-table";

import { StatusView } from "../../progress-snapshot";
import { StatusList } from "../../progress-snapshot/status-types";

import { NO_FOCUS_AREA } from "./constants";
import { PHASE_NAME_MAP } from "./constants";
import { ClimateActionRow } from "./types";

export const ClimateActionFocusArea = ({ row }: { row: Row<ClimateActionRow> }) => {
  const workspace = row.original.workspace;
  const focusArea = row.original.focusArea;

  if (focusArea.id === NO_FOCUS_AREA) {
    return (
      <div className="opacity-50 hover:cursor-not-allowed">
        {/* Do not pass a fake focus area (!) */}
        <FocusAreaView focusArea={null} />
      </div>
    );
  }

  return (
    <Link to={`/${workspace.workspaceUrl}/focus-areas/${focusArea.id}`} className="flex items-center gap-2">
      <FocusAreaView focusArea={{ id: focusArea.id }} />
    </Link>
  );
};

export const ClimateActionPhase = ({ row }: { row: Row<ClimateActionRow> }) => {
  const phaseList = row.original.phase;

  const notYetStarted = (phaseList[PhaseList.backlog] ?? 0) + (phaseList[PhaseList.unstarted] ?? 0);
  const started = phaseList[PhaseList.started] ?? 0;
  const completed = phaseList[PhaseList.completed] ?? 0;
  return (
    <div className="flex items-center gap-4 self-end">
      <HoverCard>
        <HoverCardTrigger>
          <span className="flex min-w-[30px] items-center gap-2">
            <PhaseIcon phase={{ color: "#95999f", type: PhaseList.unstarted }} className="h-2.5 w-2.5" />
            <span className="text-xs">{notYetStarted}</span>
          </span>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="w-auto p-2">
          <div className="flex flex-col gap-2">
            {`${phaseList[PhaseList.backlog]} ${PHASE_NAME_MAP[PhaseList.backlog]}`}
          </div>
          <div className="flex flex-col gap-2">
            {`${phaseList[PhaseList.unstarted]} ${PHASE_NAME_MAP[PhaseList.unstarted]}`}
          </div>
        </HoverCardContent>
      </HoverCard>
      <HoverCard>
        <HoverCardTrigger>
          <span className="flex min-w-[30px] items-center gap-2">
            <PhaseIcon phase={{ color: "#95999f", type: PhaseList.started }} className="h-2.5 w-2.5" />
            <span className="text-xs">{started}</span>
          </span>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="w-auto p-2">
          {`${phaseList[PhaseList.started]} ${PHASE_NAME_MAP[PhaseList.started]}`}
        </HoverCardContent>
      </HoverCard>
      <HoverCard>
        <HoverCardTrigger>
          <span className="flex min-w-[30px] items-center gap-2">
            <PhaseIcon phase={{ color: "#95999f", type: PhaseList.completed }} className="h-2.5 w-2.5" />
            <span className="text-xs">{completed}</span>
          </span>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="w-auto p-2">
          {`${completed} ${PHASE_NAME_MAP[PhaseList.completed]}`}
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};

export const ClimateActionStatus = ({ row }: { row: Row<ClimateActionRow> }) => {
  const statusList = row.original.status;
  const onTrack = statusList[StatusList.onTrack] ?? 0;
  const atRisk = statusList[StatusList.atRisk] ?? 0;
  const offTrack = statusList[StatusList.offTrack] ?? 0;
  const blocked = statusList[StatusList.blocked] ?? 0;
  return (
    <div className="flex min-w-[150px] items-center gap-4">
      <HoverCard>
        <HoverCardTrigger>
          <span id={StatusList.onTrack} className="flex min-w-[30px] items-center gap-2">
            <StatusView type={StatusList.onTrack} logoOnly className="h-2.5 w-2.5" />
            <span className="text-xs">{onTrack}</span>
          </span>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="w-auto p-2">
          {`${onTrack} ${StatusList.onTrack}`}
        </HoverCardContent>
      </HoverCard>
      <HoverCard>
        <HoverCardTrigger>
          <span id={StatusList.atRisk} className="flex min-w-[30px] items-center gap-2">
            <StatusView type={StatusList.atRisk} logoOnly className="h-2.5 w-2.5" />
            <span className="text-xs">{atRisk}</span>
          </span>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="w-auto p-2">
          {`${atRisk} ${StatusList.atRisk}`}
        </HoverCardContent>
      </HoverCard>
      <HoverCard>
        <HoverCardTrigger>
          <span id={StatusList.offTrack} className="flex min-w-[30px] items-center gap-2">
            <StatusView type={StatusList.offTrack} logoOnly className="h-2.5 w-2.5" />
            <span className="text-xs">{offTrack}</span>
          </span>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="w-auto p-2">
          {`${offTrack} ${StatusList.offTrack}`}
        </HoverCardContent>
      </HoverCard>
      <HoverCard>
        <HoverCardTrigger>
          <span id={StatusList.blocked} className="flex min-w-[30px] items-center gap-2">
            <StatusView type={StatusList.blocked} logoOnly className="h-2.5 w-2.5" />
            <span className="text-xs">{blocked}</span>
          </span>
        </HoverCardTrigger>
        <HoverCardContent side="top" className="w-auto p-2">
          {`${blocked} ${StatusList.blocked}`}
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};
