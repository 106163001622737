import { FC, useMemo, useState } from "react";

import IconParentClimateAction from "@/components/_icons/IconParentClimateAction";
import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { useGetClimateActionListForTeams } from "@/hooks/api/common/useClimateAction/useGetClimateActionListForTeams";
import { useUpdateClimateAction } from "@/hooks/api/common/useClimateAction/useUpdateClimateAction";
import { DialogProps } from "@radix-ui/react-dialog";

import { ClimateActionBadge } from "./climate-action-badge";

type ClimateActionProps = {
  id: string;
  teamId?: string | null | undefined;
  parentId?: string | null | undefined;
  teamInstanceList?: {
    id: string;
    teamId: string;
  }[];
};

interface ClimateActionSetParentDialogProps extends DialogProps {
  climateAction: ClimateActionProps;
  workspaceId: string;
  teamId: string;
}

export const ClimateActionSetParentDialog: FC<ClimateActionSetParentDialogProps> = ({
  climateAction,
  teamId,
  workspaceId,
  ...props
}) => {
  const [queryString, setQueryString] = useState("");

  const { climateActionList } = useGetClimateActionListForTeams({
    teamList: [{ id: teamId }],
    workspaceId,
  });

  const filteredClimateActionList = useMemo(() => {
    return climateActionList.slice().filter((o) => {
      return (
        o.id !== climateAction.id && // not itself
        (!climateAction.parentId || o.id !== climateAction.parentId) && // not its current parent
        (queryString === "" || o.title.toLowerCase().indexOf(queryString.toLowerCase()) !== -1) // matching the querystring
        // Todo: we don't really check the tree of parents here. It could be that we end up with a cyclic dependency
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [climateActionList, queryString]);

  const { updateClimateAction } = useUpdateClimateAction({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleParentClimateActionSelected({ id }: { id: string }) {
    await updateClimateAction({
      variables: {
        input: {
          id: climateAction.id,
          parentId: id,
          teamIdList: climateAction.teamInstanceList?.map((tca) => tca.teamId),
        },
      },
    });
  }

  async function handleRemoveParentClimateActionSelected() {
    await updateClimateAction({
      variables: {
        input: {
          id: climateAction.id,
          parentId: null,
          teamIdList: climateAction.teamInstanceList?.map((tca) => tca.teamId),
        },
      },
    });
  }

  async function onUpdateCompleted() {
    if (props.onOpenChange) {
      props.onOpenChange(false);
    }
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <Dialog {...props}>
      <DialogContent className="top-[1%] max-w-3xl translate-y-[0%] md:top-[20%]">
        <div className="flex flex-col gap-2">
          {climateAction.parentId && (
            <div>
              <ClimateActionBadge climateAction={{ id: climateAction.parentId }} />
            </div>
          )}
          <div className="mr-4">
            <Input
              value={queryString}
              onChange={(e) => setQueryString(e.currentTarget.value)}
              className="shadow-none border-none pl-0 text-xl font-light outline-none ring-0 focus-visible:border-none focus-visible:ring-0"
              placeholder="Übergeordnete Maßnahme setzen..."
              autoFocus
            />
          </div>
          <div className="h-0 w-full border-b"></div>
          {climateAction.parentId && (
            <div className="flex flex-col">
              <div
                key={climateAction.id}
                onClick={() => handleRemoveParentClimateActionSelected()}
                className="flex cursor-pointer select-none flex-row items-center rounded-md px-1 py-2 text-sm hover:bg-muted"
              >
                <IconParentClimateAction className="ml-1 mr-2 h-4 w-4" />
                <span>Übergeordnete Maßnahme entfernen</span>
              </div>
              <div>
                <span className="my-2 text-xs font-medium text-muted-foreground">Weitere Maßnahmen: </span>
              </div>
            </div>
          )}
          <div className="flex max-h-[240px] flex-col overflow-y-scroll">
            {filteredClimateActionList.map((climateAction) => {
              return (
                <div
                  key={climateAction.id}
                  onClick={() => handleParentClimateActionSelected(climateAction)}
                  className="flex cursor-pointer select-none flex-row items-center rounded-md px-2 py-2 text-sm hover:bg-muted"
                >
                  <span>{climateAction.title}</span>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
