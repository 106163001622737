import { useCallback } from "react";

import { currentUserQueryDocument } from "@/graphql/common/user";
import { deleteUserFavoriteMutationDocument } from "@/graphql/common/user-favorite";
import { useMutation } from "@apollo/client";

import { EVENT_USER_FAVORITE_DELETED, trackUserFavoriteEvent } from "./tracking";

export interface UseDeleteUserFavoriteProps {
  id: string;
}

export const useDeleteUserFavorite = () => {
  const [deleteFavoriteMutation] = useMutation(deleteUserFavoriteMutationDocument, {
    update: (_cache, { data }) => {
      const userFavorite = data?.deleteUserFavorite;
      if (!userFavorite) {
        return;
      }
      trackUserFavoriteEvent(EVENT_USER_FAVORITE_DELETED, {
        userFavoriteId: userFavorite.id,
      });
    },
  });

  // Todo: refactor (no unneeded useCallback, update cach instead of refetchQueries)
  const deleteFavorite = useCallback(
    async ({ id }: UseDeleteUserFavoriteProps) => {
      await deleteFavoriteMutation({
        variables: {
          userFavoriteDeleteInput: {
            id,
          },
        },
        refetchQueries: [currentUserQueryDocument],
      });
    },
    [deleteFavoriteMutation],
  );

  return {
    deleteFavorite,
    mutation: deleteFavoriteMutation,
  };
};
