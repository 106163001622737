import { Navigate } from "react-router-dom";

import { indicatorRoutes } from "@/components/_domain/indicator/routes/indicator.routes.tsx";
import { NotFoundView } from "@/components/not-found-view";

import AdminIndexRoute from "./admin/route.tsx";
import AdminWorkspaceDetailRoute from "./admin/workspace/:id/route.tsx";
import AdminWorkspaceListRoute from "./admin/workspace/route.tsx";
import ForgotRoute from "./auth/forgot/route.tsx";
import InviteTeamRoute from "./auth/invite-team/route.tsx";
import InviteWorkspaceRoute from "./auth/invite-workspace/route.tsx";
// AUTH STUFF
import LoginRoute from "./auth/login/route.tsx";
import ResetRoute from "./auth/reset/route.tsx";
import DownloadNotFoundRoute from "./misc/download/route.tsx";
import SettingsProfileRoute from "./settings/account/profile/route.tsx";
import SettingsSecurityRoute from "./settings/account/security/route.tsx";
import SettingsNewTeamRoute from "./settings/new-team/route.tsx";
import { SettingsRootLayout } from "./settings/settings.layout.tsx";
import SettingsTeamsGeneralRoute from "./settings/teams/:id/general/route.tsx";
import SettingsTeamsMembersRoute from "./settings/teams/:id/members/route.tsx";
import SettingsTeamsAllRoute from "./settings/teams/route.tsx";
import SettingsWorkspaceFocusAreaRoute from "./settings/workspace/focus-area/route.tsx";
import SettingsWorkspaceGeneralRoute from "./settings/workspace/general/route.tsx";
import SettingsWorkspaceLabelsRoute from "./settings/workspace/labels/route.tsx";
import SettingsWorkspaceMembersRoute from "./settings/workspace/members/route.tsx";
import SettingsWorkspacePhaseRoute from "./settings/workspace/phase/route.tsx";
import SettingsWorkspacePriorityRoute from "./settings/workspace/priority/route.tsx";
import SettingsWorkspaceUnitsRoute from "./settings/workspace/units/route.tsx";
import { ClimateActionLayout } from "./workspace/climate-actions/:climateActionId/climate-action.layout.tsx";
import { ClimateActionPage } from "./workspace/climate-actions/:climateActionId/climate-action.page.tsx";
import { ClimateActionListAllPage } from "./workspace/climate-actions/(list)/climate-action-list.all.page.tsx";
import { ClimateActionListDashboardPage } from "./workspace/climate-actions/(list)/climate-action-list.dashboard.page.tsx";
import { ClimateActionListLayout } from "./workspace/climate-actions/(list)/climate-action-list.layout.tsx";
import { ClimateActionListRootLayout } from "./workspace/climate-actions/climate-action-list-root.layout.tsx";
import { focusAreaRoutes } from "./workspace/focus-areas/focus-area.routes.tsx";
import { MyClimateActionListLayout } from "./workspace/my-climate-actions/my-climate-actions.layout.tsx";
import { MyClimateActionsPage } from "./workspace/my-climate-actions/my-climate-actions.page.tsx";
import TasksRoute from "./workspace/tasks/route.tsx";
import { TeamClimateActionListPage } from "./workspace/teams/:teamId/climate-actions/all/team-climate-action-list.page.tsx";
import { TeamClimateActionListLayout } from "./workspace/teams/:teamId/climate-actions/team-climate-action-list.layout.tsx";
import { TeamLayout } from "./workspace/teams/:teamId/team.layout.tsx";
import { TeamPage } from "./workspace/teams/:teamId/team.page.tsx";
import { TeamListLayout } from "./workspace/teams/team-list.layout.tsx";
import { TeamListPage } from "./workspace/teams/team-list.page.tsx";
import { WorkspaceRootLayout } from "./workspace/workspace.layout.tsx";
import { ProtectedRoute } from "./ProtectedRoute.tsx";
import WorkspaceInitialization from "./WorkspaceInitialization.tsx";

export const routes = (isAuthenticated: boolean) => [
  {
    path: "/",
    element: isAuthenticated ? (
      <ProtectedRoute isAuthenticated={isAuthenticated}>
        <WorkspaceInitialization />
      </ProtectedRoute>
    ) : (
      <Navigate to="/login" />
    ),
  },
  { path: "/download", ...DownloadNotFoundRoute },
  { ...LoginRoute, path: "/login", element: isAuthenticated ? <Navigate to="/" replace /> : LoginRoute.element },
  {
    ...ForgotRoute,
    path: "/forgot-password",
    element: isAuthenticated ? <Navigate to="/" replace /> : ForgotRoute.element,
  },
  {
    ...ResetRoute,
    path: "/reset-password",
    element: isAuthenticated ? <Navigate to="/" replace /> : ResetRoute.element,
  },
  { ...InviteWorkspaceRoute, path: "/invitation/workspace", element: InviteWorkspaceRoute.element },
  { ...InviteTeamRoute, path: "/invitation/team", element: InviteTeamRoute.element },

  // Wrap all authenticated routes inside ProtectedRoute
  // -------------------- MAIN APPLICATION ROUTES --------------------
  {
    path: "/:workspaceUrl",
    element: (
      <ProtectedRoute isAuthenticated={isAuthenticated}>
        <WorkspaceRootLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: "", element: <Navigate to="my-climate-actions" replace /> },

      // My Climate Actionss
      // ------------------------------------------------------------
      {
        path: "my-climate-actions",
        element: <MyClimateActionListLayout />,
        children: [{ path: "", element: <MyClimateActionsPage /> }],
      },
      { path: "my-tasks", element: TasksRoute.element },

      // Teams
      {
        path: "teams",
        element: <TeamListLayout />,
        children: [
          { path: "", element: <TeamListPage /> },
          {
            path: ":teamId",
            element: <TeamLayout />,
            children: [
              { path: "", element: <TeamPage /> },
              {
                path: "climate-actions",
                element: <TeamClimateActionListLayout />,
                children: [
                  { path: "", element: <Navigate to="all" replace /> },
                  { path: "all", element: <TeamClimateActionListPage /> },
                ],
              },
            ],
          },
        ],
      },

      // Climate Actions
      // ------------------------------------------------------------
      {
        path: "climate-actions",
        element: <ClimateActionListRootLayout />, // data loading for currentClimateActionList context
        children: [
          {
            path: "",
            element: <ClimateActionListLayout />, // shared header with tabs
            children: [
              { path: "", element: <Navigate to="all" replace /> },
              { path: "all", element: <ClimateActionListAllPage /> },
              { path: "dashboard", element: <ClimateActionListDashboardPage /> },
            ],
          },
          {
            path: ":climateActionId",
            element: <ClimateActionLayout />,
            children: [
              { path: "", element: <ClimateActionPage /> },
              { path: ":tab", element: <ClimateActionPage /> },
            ],
          },
        ],
      },

      // Focus Areas
      // ------------------------------------------------------------
      ...focusAreaRoutes,
      // Indicators
      // ------------------------------------------------------------
      ...indicatorRoutes,

      // Fall Through
      // ------------------------------------------------------------
      { path: "*", element: <NotFoundView /> },
    ],
  },

  // -------------------- SETTINGS ROUTES --------------------
  {
    path: "/:workspaceUrl/settings",
    element: (
      <ProtectedRoute isAuthenticated={isAuthenticated}>
        <SettingsRootLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: "", element: <Navigate to="account/profile" replace /> },
      { path: "account/profile", ...SettingsProfileRoute },
      { path: "account/security", ...SettingsSecurityRoute },
      { path: "workspace", element: <Navigate to="workspace/general" replace /> },
      { path: "workspace/general", ...SettingsWorkspaceGeneralRoute },
      { path: "workspace/members", ...SettingsWorkspaceMembersRoute },
      { path: "workspace/labels", ...SettingsWorkspaceLabelsRoute },
      { path: "workspace/focus-areas", ...SettingsWorkspaceFocusAreaRoute },
      { path: "workspace/phase", ...SettingsWorkspacePhaseRoute },
      { path: "workspace/priority", ...SettingsWorkspacePriorityRoute },
      { path: "workspace/units", ...SettingsWorkspaceUnitsRoute },
      { path: "new-team", ...SettingsNewTeamRoute },
      { path: "teams", ...SettingsTeamsAllRoute },
      {
        path: "teams/:teamId",
        children: [
          { path: "", element: <Navigate to="general" replace /> },
          { path: "general", ...SettingsTeamsGeneralRoute },
          { path: "members", ...SettingsTeamsMembersRoute },
        ],
      },
      { path: "*", element: <NotFoundView /> },
    ],
  },

  {
    ...AdminIndexRoute,
    path: "/admin",
    element: <ProtectedRoute isAuthenticated={isAuthenticated}>{AdminIndexRoute.element}</ProtectedRoute>,
    children: [
      { path: "", element: <Navigate to="workspaces" replace /> },
      { path: "workspaces", ...AdminWorkspaceListRoute },
      { path: "workspaces/:workspaceId", ...AdminWorkspaceDetailRoute },
      { path: "*", element: <NotFoundView /> },
    ],
  },
  { path: "*", element: <NotFoundView /> },
];

export default routes;
