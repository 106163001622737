import { Workspace } from "@/components/_domain/workspace/models/workspace-model";

import { Team } from "../models/team-model";

export const makeTeamUrl = ({ workspace, team, path }: { workspace: Workspace; team: Team; path: string }) => {
  return `/${workspace.workspaceUrl}/teams/${team.id}/${path}`;
};

export const makeTeamSettingsUrl = ({ workspace, team, path }: { workspace: Workspace; team: Team; path: string }) => {
  return `/${workspace.workspaceUrl}/settings/teams/${team.id}/${path}`;
};
