import { EditorThemeClasses } from "lexical";

export const theme: EditorThemeClasses = {
  link: "text-blue-500 underline cursor-pointer",
  text: {
    strikethrough: "line-through",
    underline: "underline",
    underlineStrikethrough: "underline line-through",
    bold: "font-medium",
    italic: "italic",
    code: "font-mono text-xs bg-gray-100 dark:bg-gray-800 rounded px-1 py-1 text-[#E24040]",
  },

  ltr: "ltr",
  rtl: "rtl",
  placeholder: "text-gray-400 overflow-hidden text-ellipsis absolute inline-block pointer-events-none select-none",
  paragraph: "mb-2 last:mb-0 relative mx-0 mt-0 text-gray-800 dark:text-white text-sm",
  quote: "ml-4 text-sm text-gray-500 border-l-4 border-gray-300 pl-4",
  heading: {
    h1: "mb-2 text-lg leading-7 font-semibold sm:tracking-tight sm:truncate dark:text-white",
    h2: "mb-2 text-base leading-6 font-semibold sm:tracking-tight dark:text-white",
    h3: "mb-2 text-sm leading-6 font-semibold sm:tracking-tight dark:text-white",
    h4: "mb-2 text-sm leading-5 font-semibold sm:tracking-tight dark:text-white",
    h5: "mb-2 text-sm leading-4 font-semibold sm:tracking-tight dark:text-white",
    h6: "mb-2 text-sm leading-3 font-semibold sm:tracking-tight dark:text-white",
  },
  list: {
    nested: {
      listitem: "list-none",
    },
    ol: "ml-4 m-0 p-0 list-decimal text-sm",
    ul: "ml-4 m-0 p-0 list-disc text-sm",
    listitem: "my-2 mx-8",
    listitemChecked:
      "line-through text-gray-400 relative mx-2 px-6 list-none outline-none before:border before:border-sento-primary before:bg-sento-primary before:rounded-sm before:bg-no-repeat before:w-4 before:h-4 before:absolute before:top-0.5 before:left-0 before:cursor-pointer before:block before:bg-cover after:cursor-pointer after:border-white after:border after:border-solid after:absolute after:block after:top-1.5 after:left-1.5 after:right-1.5 after:w-[0.1rem] after:h-[0.6rem] after:transform after:rotate-90 after:border-r-2 after:border-b-2 after:bg-[url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2216%22 height=%2216%22 fill=%22white%22 %3E%3Cpath d=%22M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z%22/%3E%3Cpath d=%22M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z%22/%3E%3C/svg%3E')]",
    listitemUnchecked:
      "text-gray-800 relative mx-2 px-6 dark:text-gray-200 focus-visible:outline-none list-none list-inside before:border before:border-solid before:border-gray-800 before:rounded-sm focus:before:shadow-sm before:w-4 before:h-4 before:absolute before:top-0.5 before:left-0 before:cursor-pointer before:block before:bg-cover",
  },
  image: "select-none inline-block relative cursor-default",
};
