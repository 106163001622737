import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { cn } from "@/lib/utils";

import { useLayout } from "../useLayout";

import { SheetIcon } from "./sheet-icon";
import { SheetIconFixed } from "./sheet-icon-fixed";

interface SheetProps extends PropsWithChildren {
  userCanResize?: boolean;
}

export const Sheet: FC<SheetProps> = ({ userCanResize, children }) => {
  // Configuration constants
  const defaultWidth = 320;
  const minWidth = 320;
  const maxWidth = 480;
  const breakpoint = 1280;
  const sheetRef = useRef<HTMLElement>(null);

  // Initialize state based on window width to prevent flickering on initial load
  const initialScreenWidth = typeof window !== "undefined" ? window.innerWidth : 0;
  const initialIsHidden = initialScreenWidth < breakpoint;

  // State management
  const [isHidden, setIsHidden] = useState(initialIsHidden);
  const [isHover, setIsHover] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [sheetWidth, setSheetWidth] = useState(initialIsHidden ? 0 : defaultWidth);
  const [screenWidth, setScreenWidth] = useState(initialScreenWidth);

  const { insetApplication, offsetTop } = useLayout();

  // Determine if sheet can be resized based on screen width
  const canResize = useMemo(() => {
    if (screenWidth < breakpoint) {
      return false;
    }
    return userCanResize;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth]);

  // Calculate sheet styles based on current state
  const sheetStyle = useMemo(() => {
    // Case 1: Sheet is hidden and not being hovered
    if (isHidden && !isHover) {
      return {
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: sheetWidth,
        right: -defaultWidth,
        top: 56,
        bottom: 0,
        borderRadius: "0.25rem",
        overflow: "hidden",
        transition: "0.155s cubic-bezier(.36,-0.01,0,.77)",
        borderWidth: "0.5px",
        zIndex: 10,
      };
    }

    // Case 2: Sheet is hidden but being hovered (showing as overlay)
    if (isHidden && isHover) {
      return {
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: defaultWidth,
        right: 8,
        top: 56,
        bottom: insetApplication,
        borderRadius: "0.25rem",
        overflow: "hidden",
        transition: "0.155s cubic-bezier(.36,-0.01,0,.77)",
        borderWidth: "0.5px",
        zIndex: 12, // make sure it is above the backdrop
      };
    }

    // Case 3: Sheet is permanently visible (fixed mode)
    return {
      minWidth: minWidth,
      maxWidth: maxWidth,
      width: sheetWidth,
      right: 0,
      top: 0,
      transition: "0s cubic-bezier(.36,-0.01,0,.77)",
      marginTop: `${insetApplication + offsetTop}px`,
      marginBottom: `${insetApplication}px`,
      marginRight: `${insetApplication}px`,
      borderWidth: "0.5px",
      maxHeight: `calc(100vh - ${insetApplication * 2 + offsetTop}px)`,
      borderRadius: `0  calc(0.25rem - 2px) calc(0.25rem - 2px) 0`, // rounded-md
      zIndex: 10,
    };
  }, [isHidden, isHover, sheetWidth, insetApplication, offsetTop]);

  function hideSheet() {
    setIsHidden(true);
    setIsHover(false);
    setSheetWidth(0);
  }

  function showSheet() {
    setIsHidden(false);
    setIsHover(false);
    setSheetWidth(defaultWidth);
  }

  function showsheetOverlay() {
    setIsHover(true);
  }

  function hideSheetOverlay() {
    setIsHover(false);
  }

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (event: MouseEvent) => {
      if (!isResizing) {
        return;
      }
      if (!sheetRef.current) {
        return;
      }

      const translatedWidth = sheetRef.current.getBoundingClientRect().right - event.clientX;
      // Hide sheet if we are getting close to the edge
      if (translatedWidth < 50) {
        setIsResizing(false);
        hideSheet();
        return;
      }

      // otherwise just minimize until minWidth
      const sheetWidth = Math.min(maxWidth, Math.max(minWidth, translatedWidth));
      setSheetWidth(sheetWidth);
    },
    [isResizing],
  );

  const handleWindowResize = () => {
    const { innerWidth: width } = window;

    if (width < breakpoint) {
      hideSheet();
    }
    setScreenWidth(width);
  };

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    handleWindowResize(); // call initially on first component load
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{ width: isHidden ? 0 : sheetWidth }}></div>

      {/* Overlay across the whole screen when the sheet is temporarily shown */}
      {/* ------------------------------------------------------------------ */}
      {isHidden && isHover && (
        <div
          className="backdrop-blur-sm fixed bottom-0 left-0 right-0 top-0 bg-foreground/50"
          style={{ zIndex: 10 }}
          onMouseEnter={() => (canResize ? hideSheetOverlay() : () => {})}
          onClick={() => hideSheetOverlay()}
        ></div>
      )}
      {/* ------------------------------------------------------------------ */}

      {/* The actual sheet and its content */}
      {/* ------------------------------------------------------------------ */}
      <aside
        ref={sheetRef}
        className={`fixed right-0 border-l border-solid ${isHover ? "shadow-lg border" : ""} overflow-hidden `}
        style={sheetStyle}
      >
        {/* The resize handle */}
        {/* ------ */}
        {canResize && (
          <div
            onMouseDown={startResizing}
            onClick={hideSheet}
            className={cn([
              "absolute bottom-0 top-0 w-2 cursor-col-resize resize-x hover:border-l-2 hover:border-foreground",
              isResizing ? "border-l-2 border-foreground" : "",
            ])}
            style={{ left: -2 }}
          />
        )}
        {/* ------ */}

        <nav
          className="relative flex h-full min-h-screen shrink-0 grow flex-col bg-background"
          style={{
            minHeight: isHidden ? `calc(100vh - 56px - ${insetApplication * 2 + offsetTop}px)` : "100vh",
          }}
        >
          {children}
        </nav>
      </aside>
      {/* ------------------------------------------------------------------ */}

      {/* The icons to control sheet visibility in different states */}
      {/* ------------------------------------------------------------------ */}
      {/* 1. Sheet is visible - show icon to hide it */}
      {!isHidden && (
        <div
          className={`fixed right-3 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onClick={hideSheet}
          style={{ top: `${insetApplication + offsetTop}px` }}
        >
          <SheetIconFixed className="text-muted-foreground hover:text-foreground" />
        </div>
      )}

      {/* 2. Sheet is hidden and can be resized - show icon to show overlay or fix */}
      {isHidden && canResize && (
        <div
          className={`fixed right-3 z-10 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onMouseEnter={() => showsheetOverlay()}
          onClick={() => showSheet()} // fix the sheet
          style={{ top: `${insetApplication + offsetTop}px` }}
        >
          <SheetIcon className="text-muted-foreground hover:text-foreground" />
        </div>
      )}

      {/* 3. Sheet is hidden and cannot be resized - show icon to toggle overlay */}
      {isHidden && !canResize && !isHover && (
        <div
          className={`fixed right-3 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onClick={() => showsheetOverlay()} // show the sheet as overlay
          style={{ top: `${insetApplication + offsetTop}px` }}
        >
          <SheetIcon className="text-muted-foreground hover:text-foreground" />
        </div>
      )}

      {isHidden && !canResize && isHover && (
        <div
          className={`fixed right-3 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onClick={() => hideSheetOverlay()}
          style={{ top: `${insetApplication + offsetTop}px` }}
        >
          <SheetIconFixed className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {/* {isHidden && (
        // overlay on the corner to bring up the sheet by just hovering to the side
        <div
          className={`fixed top-0 bottom-0 right-0 h-full flex flex-col justify-center`}
          style={{ width: isHover ? defaultWidth : 24, zIndex: 8 }}
          onMouseEnter={showsheetOverlay}
        />
      )} */}
    </div>
  );
};

export default Sheet;
