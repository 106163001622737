import { FC } from "react";
import { Star } from "lucide-react";

import { useCreateUserFavorite, useDeleteUserFavorite, useGetUserFavorites } from "@/hooks/api/common/useUserFavorites";

import { Button } from "../_ui/button";
import { toast } from "../_ui/use-toast";

interface FavoriteButtonProps {
  className?: string;
  pageName?: string;
}

export const FavoriteButton: FC<FavoriteButtonProps> = ({ className, pageName }) => {
  const { favorites } = useGetUserFavorites();
  const { deleteFavorite } = useDeleteUserFavorite();
  const { createFavorite } = useCreateUserFavorite();

  // Check if current URL is already saved as a favorite
  const currentUrl = window.location.pathname;
  const isFavorited = favorites.some((favorite) => favorite.pageUrl === currentUrl);

  const handleToggleFavorite = async () => {
    try {
      if (isFavorited) {
        const favorite = favorites.find((favorite) => favorite.pageUrl === currentUrl);
        if (favorite) {
          await deleteFavorite({ id: favorite.id });
          toast({
            title: "Aus Favoriten entfernt",
            description: "Die Ansicht wurde aus Ihren Favoriten entfernt",
            variant: "success",
          });
        }
      } else {
        await createFavorite({ pageUrl: currentUrl, pageName: pageName || "Favorisierte Ansicht" });
        toast({
          title: "Zu Favoriten hinzugefügt",
          description: "Die Ansicht wurde zu Ihren Favoriten hinzugefügt",
          variant: "success",
        });
      }
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Beim Speichern des Favoriten ist ein Fehler aufgetreten",
        variant: "error",
      });
    }
  };

  return (
    <Button variant="ghost" size="icon" className={className} onClick={handleToggleFavorite}>
      <Star className={`h-4 w-4 ${isFavorited ? "fill-current text-yellow-500" : ""}`} />
    </Button>
  );
};
