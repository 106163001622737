import { type FC, useState } from "react";
import { Bookmark, Copy, Edit, MoreHorizontal, PlusIcon, RotateCcw, Save, Trash2 } from "lucide-react";

import { Button } from "@/components/_ui/button";
import { toast } from "@/components/_ui/use-toast";
import { cn } from "@/lib/utils";
// TODO: Replace radix with shadcn
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as Popover from "@radix-ui/react-popover";

import { useGlobalSavedViewContext } from "../hooks/useGlobalSavedViewContext";
import { SavedView, SavedViewInput } from "../models/saved-views-model";

import { SaveViewCreateForm } from "./SavedViewCreateForm";
import { SavedViewEditForm } from "./SavedViewEditForm";
import { SaveViewModal } from "./SaveViewModal";

export const SavedViewsMenu: FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [savedViewToEdit, setSavedViewToEdit] = useState<SavedView | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const {
    pathname,
    savedViewState,
    isDirty,

    setSelectedView,
    resetCurrentView,
    saveCurrentView,
    deleteSavedView,
    createSavedView,
  } = useGlobalSavedViewContext();

  const handleSaveCurrentView = async () => {
    await saveCurrentView(pathname);
    toast({
      title: "Erfolg",
      description: "Änderungen gespeichert",
      variant: "success",
    });
  };

  const handleResetView = () => {
    resetCurrentView(pathname);
    toast({
      title: "Erfolg",
      description: "Ansicht zurückgesetzt",
      variant: "success",
    });
  };

  const handleDeleteView = async (view: SavedView) => {
    if (!view.id) {
      return;
    }

    await deleteSavedView(view.id);

    toast({
      title: "Erfolg",
      description: "Ansicht gelöscht",
      variant: "success",
    });
  };

  const handleDuplicateView = async (view: SavedViewInput) => {
    const newView = await createSavedView(pathname, {
      name: `${view.name} (Copy)`,
      url: view.url,
      workspaceId: view.workspaceId,
      isDefault: false,
      tableConfiguration: view.tableConfiguration,
    });

    if (newView) {
      toast({
        title: "Erfolg",
        description: "Ansicht dupliziert",
        variant: "success",
      });

      setDropdownOpen(null);
    }
  };

  const handleViewSelect = async (view: SavedView) => {
    setSelectedView(pathname, view);
  };

  return (
    <>
      <DropdownMenu.Root
        onOpenChange={(open) => {
          if (!open) {
            setDropdownOpen(null); // Close submenu when main menu closes
          }
        }}
      >
        <DropdownMenu.Trigger asChild>
          <Button
            variant="outline"
            size="xs"
            className={cn(
              "shadow-sm h-6 rounded-md border px-2 text-xs text-foreground/75",
              isDirty && "border-orange-500",
            )}
          >
            <div className="flex items-center gap-2">
              <Bookmark className={cn("h-4 w-4", isDirty && "text-orange-500")} />
              <span className="max-w-[150px] truncate">{savedViewState.currentView?.name || "Ansichten"}</span>
            </div>
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="shadow-lg z-50 w-[280px] rounded-md border bg-background p-2" align="end">
            <div className="mb-2 flex items-center justify-between border-b pb-2">
              <span className="text-sm font-medium">{savedViewState.savedViews.length} Ansichten</span>
              <Button
                variant="outline"
                size="xs"
                className="h-6"
                onClick={() => {
                  setSavedViewToEdit(null);
                  setDropdownOpen(null);
                  setCreateDialogOpen(true);
                }}
              >
                <PlusIcon className="mr-1 h-4 w-4" />
                Neue Ansicht
              </Button>
            </div>

            {savedViewState.savedViews.map((view: SavedView) => (
              <DropdownMenu.Item
                key={view.id}
                className={cn(
                  "group flex cursor-default items-center justify-between rounded px-3 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800",
                  savedViewState.currentView?.id === view.id && "bg-primary/5",
                )}
                onSelect={() => handleViewSelect(view)}
              >
                <div className="flex items-center gap-2">
                  <Bookmark className="h-4 w-4 text-muted-foreground" />
                  <span>{view.name}</span>
                  {view.isDefault && !view.isBaseView && (
                    <span className="text-xs text-muted-foreground">(Standard)</span>
                  )}
                  {view.isBaseView && <span className="text-xs text-muted-foreground">(Basis)</span>}
                </div>

                <div className="flex items-center gap-1">
                  {isDirty && savedViewState.currentView?.id === view.id && (
                    <>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSaveCurrentView();
                        }}
                        className="rounded p-1 text-orange-500 hover:bg-gray-200 dark:hover:bg-gray-700"
                        title="Änderungen speichern"
                      >
                        <Save className="h-4 w-4" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleResetView();
                        }}
                        className="rounded p-1 text-orange-500 hover:bg-gray-200 dark:hover:bg-gray-700"
                        title="Zurücksetzen"
                      >
                        <RotateCcw className="h-4 w-4" />
                      </button>
                    </>
                  )}
                  <Popover.Root
                    open={dropdownOpen === view.id}
                    onOpenChange={(open) => {
                      if (!open) {
                        setDropdownOpen(null);
                      }
                    }}
                  >
                    <Popover.Trigger asChild>
                      <button
                        className="rounded p-1 hover:bg-gray-200 dark:hover:bg-gray-700"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDropdownOpen(view.id);
                        }}
                      >
                        <MoreHorizontal className="h-4 w-4" />
                      </button>
                    </Popover.Trigger>
                    <Popover.Portal>
                      <Popover.Content
                        className="shadow-md z-[100] w-[160px] rounded-md border bg-background p-1"
                        sideOffset={5}
                        side="right"
                        align="start"
                        onInteractOutside={(e) => {
                          // Prevent closing when clicking outside
                          e.preventDefault();
                        }}
                      >
                        <button
                          className="flex w-full items-center gap-2 rounded px-2 py-1.5 text-sm hover:bg-gray-100 dark:hover:bg-gray-800"
                          onMouseDown={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDropdownOpen(null);
                            setSavedViewToEdit(view);
                            setEditDialogOpen(true);
                          }}
                        >
                          <Edit className="h-4 w-4" />
                          Bearbeiten
                        </button>
                        <button
                          className="flex w-full items-center gap-2 rounded px-2 py-1.5 text-sm hover:bg-gray-100 dark:hover:bg-gray-800"
                          onClickCapture={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDuplicateView(view);
                          }}
                        >
                          <Copy className="h-4 w-4" />
                          Duplizieren
                        </button>
                        {!view.isBaseView && (
                          <>
                            <div className="my-1 h-px bg-gray-200" />
                            <button
                              className="flex w-full items-center gap-2 rounded px-2 py-1.5 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-800"
                              onClickCapture={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDropdownOpen(null);
                                await handleDeleteView(view);
                              }}
                            >
                              <Trash2 className="h-4 w-4" />
                              Löschen...
                            </button>
                          </>
                        )}
                      </Popover.Content>
                    </Popover.Portal>
                  </Popover.Root>
                </div>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      <SaveViewModal
        open={createDialogOpen}
        onOpenChange={setCreateDialogOpen}
        title={"Ansicht speichern"}
        description={"Speichern Sie die aktuelle Ansicht für späteren Zugriff."}
      >
        <SaveViewCreateForm
          initialData={savedViewState.currentView}
          onSuccess={() => {
            setCreateDialogOpen(false);
          }}
        />
      </SaveViewModal>

      {savedViewToEdit && (
        <SaveViewModal
          open={editDialogOpen}
          onOpenChange={setEditDialogOpen}
          title={"Ansicht bearbeiten"}
          description={"Bearbeiten Sie die Details der gespeicherten Ansicht."}
        >
          <SavedViewEditForm
            initialData={savedViewToEdit}
            onSuccess={() => {
              setEditDialogOpen(false);
            }}
          />
        </SaveViewModal>
      )}
    </>
  );
};
