import { FC, useEffect, useMemo, useState } from "react";

import {
  FilterByIdList,
  FilterByIdPayload,
  FilterByIdPayloadSchema,
} from "@/components/_domain/climate-action/climate-action-list-overview/filter-functions";
import { useClimateActionListState } from "@/components/_domain/climate-action/hooks";
import { LabelView } from "@/components/_domain/labels";
import { Checkbox } from "@/components/_ui/checkbox";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { useGetLabelListForWorkspace } from "@/hooks/api/common/useLabel";
import { cn } from "@/lib/utils";

export interface FilterByLabelGroupConfigurationViewProps {
  workspace: {
    id: string;
  };
  labelGroup: {
    id: string;
  };
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByLabelGroupConfigurationView: FC<FilterByLabelGroupConfigurationViewProps> = ({
  workspace,
  labelGroup,
  onFinished,
  ...props
}) => {
  const { table, columnFilters } = useClimateActionListState();
  const { labelListGroupedByGroupId } = useGetLabelListForWorkspace({
    workspaceId: workspace.id,
  });

  const labelList = useMemo(() => {
    return labelListGroupedByGroupId.get(labelGroup.id) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelListGroupedByGroupId]);

  const [selectedIds, setSelectedIds] = useState<FilterByIdList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(props.column.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByIdPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedIds(filterValue.idList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedIds) {
      return;
    }

    if (selectedIds.length === 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_id",
      mode: filterValue?.mode ?? "included",
      idList: selectedIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const setFilter = (payload: FilterByIdPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleSelect = (labelId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== labelId);
    setSelectedIds([...updatedIdList, labelId]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (labelId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== labelId);

    setSelectedIds(updatedIdList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command data-testid="filter-by-label-group-configuration-view">
      <CommandInput placeholder={"Label"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedIds && !!selectedIds.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <LabelView label={null} />
              </CommandItem>
            );
          })}

          {labelList.map((label) => {
            const isSelected = !!selectedIds && !!selectedIds.find((id) => id === label.id);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={label.id}
                value={label.id}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(label.id, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(label.id);
                  }}
                />
                <LabelView label={label} />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
