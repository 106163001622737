import { FC } from "react";

import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";

import { useClimateActionUIState } from "../../../hooks/useClimateActionUIState";

import { ClimateActionChildList } from "./climate-action-child-list";
import { ClimateActionCreateChildForm } from "./climate-action-create-child-form";

interface ClimateActionChildTableProps {
  climateAction: {
    id: string;
  };
  workspace: {
    id: string;
  };
}

export const ClimateActionChildTable: FC<ClimateActionChildTableProps> = ({ ...props }) => {
  const { climateAction } = useGetClimateAction({ id: props.climateAction.id });
  const { showCreateChildClimateActionView, setShowCreateChildClimateActionView } = useClimateActionUIState();

  function hideCreateChildClimateAction() {
    setShowCreateChildClimateActionView(false);
  }

  if (!climateAction) {
    return <></>;
  }

  return (
    <>
      <ClimateActionChildList climateAction={climateAction} />

      {showCreateChildClimateActionView && (
        <ClimateActionCreateChildForm
          workspace={{ id: props.workspace.id }}
          parentClimateAction={{
            id: climateAction.id,
            teamInstanceList: climateAction.teamInstanceList.map((ti) => ({
              teamId: ti.teamId,
            })),
          }}
          callbacks={{ hideCreateChildClimateAction }}
        />
      )}
    </>
  );
};
