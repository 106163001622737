import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from "react";

interface IndicatorCreateForm {
  title: string;
  type: string;
  description?: string;
  unitId?: string;
  workspaceId: string;
}

interface ResetConfig {
  title?: boolean;
  type?: boolean;
  description?: boolean;
  unitId?: boolean;
}

interface IndicatorCreateContext {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  newIndicatorForm: IndicatorCreateForm;
  hasActiveChanges: boolean;
  updateNewIndicator: (indicatorCreateFormFragment: Partial<IndicatorCreateForm>) => void;
  resetNewIndicatorForm: (config?: ResetConfig) => void;
}

const IndicatorCreateContext = createContext<IndicatorCreateContext | null>(null);

interface IndicatorCreateContextProviderProps {
  children: ReactNode;
  workspaceId: string;
}

export const IndicatorCreateContextProvider: FC<IndicatorCreateContextProviderProps> = ({ children, workspaceId }) => {
  const defaultValue: IndicatorCreateForm = useMemo(() => {
    return {
      title: "",
      type: "",
      description: "",
      unitId: "",
      workspaceId,
    };
  }, [workspaceId]);

  const [showModal, setShowModal] = useState(false);
  const [newIndicatorForm, setNewIndicatorForm] = useState<IndicatorCreateForm>(defaultValue);

  const resetNewIndicatorForm = useCallback(
    (config?: ResetConfig) => {
      if (!config) {
        setNewIndicatorForm(defaultValue);
        return;
      }

      const resetFields: Partial<IndicatorCreateForm> = {};

      if (config.title) resetFields.title = defaultValue.title;
      if (config.type) resetFields.type = defaultValue.type;
      if (config.description) resetFields.description = defaultValue.description;
      if (config.unitId) resetFields.unitId = defaultValue.unitId;

      setNewIndicatorForm((prev) => ({
        ...prev,
        ...resetFields,
      }));
    },
    [defaultValue],
  );

  const updateNewIndicator = useCallback(
    (indicatorCreateFormFragment: Partial<IndicatorCreateForm>) => {
      const newValues = {
        ...newIndicatorForm,
        ...indicatorCreateFormFragment,
      };
      setNewIndicatorForm(newValues);
    },
    [newIndicatorForm],
  );

  const hasActiveChanges = useMemo(() => {
    if (defaultValue.title !== newIndicatorForm.title) {
      return true;
    }
    if (defaultValue.type !== newIndicatorForm.type) {
      return true;
    }
    if (defaultValue.description !== newIndicatorForm.description) {
      return true;
    }
    if (defaultValue.unitId !== newIndicatorForm.unitId) {
      return true;
    }
    return false;
  }, [defaultValue, newIndicatorForm]);

  return (
    <IndicatorCreateContext.Provider
      value={{
        showModal,
        setShowModal,
        newIndicatorForm,
        hasActiveChanges,
        resetNewIndicatorForm,
        updateNewIndicator,
      }}
    >
      {children}
    </IndicatorCreateContext.Provider>
  );
};

export const useIndicatorCreateContext = (): IndicatorCreateContext => {
  const context = useContext(IndicatorCreateContext);

  if (!context) {
    throw new Error("useIndicatorCreateContext must be used within an IndicatorCreateContextProvider");
  }

  return context;
};

/**
 *
 * **Important:** Generally, you should NOT use this hook, but the `useIndicatorCreateContext` hook.
 *
 * This hook exists to allow components depending on it to optionally initialize it if it has not
 * been set at a higher point in the component tree (see CreateForm or CreateDialog)
 *
 * This returns a nullable value, so you need to handle the case where the context is not available.
 *
 */
export const useUnsafeIndicatorCreateContext = (): IndicatorCreateContext | null => {
  return useContext(IndicatorCreateContext);
};
