import { FC, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";
import { Button } from "@/components/_ui/button";
import { Card, CardContent, CardHeader } from "@/components/_ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import LoadingDots from "@/components/loading-dots";
import { acceptWorkspaceInviteMutationDocument } from "@/graphql/common/workspace-membership";
import { useUnsafeCurrentUserContext } from "@/hooks/api/common/useUser";
import { cn } from "@/lib/utils";
import { signout } from "@/services/auth.service";
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";

import { UserProp, WorkspaceProp } from "./page";

const formSchema = z
  .object({
    email: z.string().email().optional(), // let's just show it so it is visible for the user
    password: z.string().min(8, {
      message: "Das Passwort muss mindestens 8 Zeichen lang sein.",
    }),
    confirmation: z.string(),
    fullname: z
      .string()
      .min(3, {
        message: "Der Name muss mindestenz drei Zeichen lang sein.",
      })
      .optional(),
    username: z
      .string()
      .min(3, {
        message: "Der Benutzername muss mindestenz drei Zeichen lang sein.",
      })
      .optional(),
  })
  .refine((data) => data.password === data.confirmation, {
    message: "Die Passwörter stimmen nicht überein.",
    path: ["confirmation"],
  });

export interface AcceptInviteCardProps extends PropsWithChildren {
  token: string;
  workspace: WorkspaceProp;
  user: UserProp;
}

const AcceptInviteCard: FC<AcceptInviteCardProps> = ({ token, workspace, user }) => {
  const [acceptInvitation, { loading }] = useMutation(acceptWorkspaceInviteMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  const context = useUnsafeCurrentUserContext();
  const currentUser = context?.currentUser;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: user.email,
      password: "",
      confirmation: "",
      fullname: "",
      username: "",
    },
  });

  function invitationIsForAuthenticatedAccount() {
    return currentUser && currentUser.email === user.email;
  }

  function handleAcceptInvitePressed() {
    acceptInvitation({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          token: token,
        },
      },
    });
  }

  function onSubmitNewUserWithPassword(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    acceptInvitation({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          token: token,
          password: values.password,
          username: values.username,
          fullname: values.fullname,
        },
      },
    });
  }

  function onUpdateCompleted() {
    let message = "Wir leiten dich jetzt zur Login Seite weiter.";

    if (invitationIsForAuthenticatedAccount()) {
      // the same user is logged in

      message = "Wir leiten dich jetzt zur Workspace Seite weiter.";

      setTimeout(function () {
        window.location.replace(new URL(`${window.location.origin}/${workspace.workspaceUrl}`));
      }, 2000);
      return;
    } else if (currentUser && currentUser.email !== user.email) {
      // a different user is logged in
      setTimeout(function () {
        signout();
      }, 2000);
      return;
    } else {
      // no user is logged in
      setTimeout(function () {
        window.location.replace(new URL(`${window.location.origin}`));
      }, 2000);
    }

    toast({
      title: `Willkomen bei ${workspace.name}`,
      description: message,
      variant: "success",
    });
  }

  function onUpdateError() {
    toast({
      title: "Fehler beim Versenden der Einladung",
      description: "Es ist etwas schiefgegangen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
      variant: "error",
    });
  }

  return (
    <Card className="z-10 max-h-screen w-full max-w-xl overflow-hidden overflow-y-scroll border p-6 shadow-xl">
      <CardHeader>
        <div className="flex flex-col gap-4">
          <AvatarWorkspace workspace={workspace} className="h-16 w-16 text-2xl" />
          <h2 className="text-xl">{`Sie wurden dazu eingeladen dem Workspace ${workspace.name} beizutreten`}</h2>
          <p className="text-md font-light text-muted-foreground">
            Ark Climate ist eine moderne Software zur Umsetzung von Klimaschutz in fortschrittlichen Städten und
            Gemeinden.
          </p>
          <div className="w-full border-t border-solid" />
        </div>
      </CardHeader>
      <CardContent>
        {user.activated && (
          <div className="mb-10 mt-4 flex flex-col gap-4">
            <p className="mb-2 text-base font-light text-foreground">
              Um dem Workspace mit Ihrem Account <span className="font-medium">{user.email}</span> beizutreten
              akzeptieren Sie die Einladung
              {invitationIsForAuthenticatedAccount() ? "." : " und melden sich mit mit dem Account an."}
            </p>
            <Button
              onClick={() => handleAcceptInvitePressed()}
              disabled={loading}
              variant="default"
              className={`${
                loading ? "cursor-not-allowed bg-muted" : ""
              } h-10 w-48 items-center justify-center text-sm focus:outline-none`}
            >
              {loading ? <LoadingDots color="#808080" /> : <p>Einladung Annehmen</p>}
            </Button>
          </div>
        )}

        {!user.activated && (
          <div className="flex flex-col gap-1 ">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitNewUserWithPassword)} className={cn("space-y-4")}>
                <FormField
                  disabled={true}
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="space-y-0.5">
                      <FormLabel>E-Mail</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="fullname"
                  render={({ field }) => (
                    <FormItem className="space-y-0.5">
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Angela Merkel" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem className="space-y-0.5">
                      <FormLabel>Benutzername</FormLabel>
                      <FormControl>
                        <Input placeholder="Angela" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="space-y-0.5">
                      <FormLabel>Passwort</FormLabel>
                      <FormControl>
                        <Input type="password" {...field} />
                      </FormControl>
                      <FormMessage></FormMessage>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="confirmation"
                  render={({ field }) => (
                    <FormItem className="space-y-0.5">
                      <FormLabel>Passwort Bestätigung</FormLabel>
                      <FormControl>
                        <Input type="password" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex flex-row items-center justify-end pt-4">
                  <Button
                    type="submit"
                    disabled={loading}
                    className={`${
                      loading ? "cursor-not-allowed bg-muted" : ""
                    } h-10 w-48 items-center justify-center text-sm focus:outline-none`}
                  >
                    {loading ? <LoadingDots color="#808080" /> : <p>Einladung Annehmen</p>}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        )}

        {currentUser && currentUser.email !== user.email && (
          <div className="mt-6 w-full border-t border-solid pt-2">
            <p className="text-sm font-light text-foreground">
              Sie sind aktuell mit dem Account <span className="font-medium">{currentUser.email}</span> angemeldet. Wenn
              Sie diesen Account auch für den Workspace {workspace.name} nutzen wollen, bitten Sie einen Administrator
              Sie mit dieser E-mail Adresse einzuladen.
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default AcceptInviteCard;
