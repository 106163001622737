import { Card, CardContent, CardHeader, CardTitle } from "@/components/_ui/card";
import { posthogService } from "@/services/posthog.service";
import { BarDatum, ResponsiveBar } from "@nivo/bar";

interface BarChartProps {
  // In data visualization, a "datum" (plural: "data") refers to a single piece of information or a single point in a dataset.
  data: BarDatum[];
  navigateToClimateActionListView: (searchParams?: string | null | undefined) => void;
  title: string;
}

const theme = {
  axis: {
    domain: {
      line: {
        stroke: "#D9D9D9",
        strokeWidth: 1,
      },
    },
  },
};

const BAR_CHART_CLICKED = "BAR_CHART:CLICKED";

export function BarChart({ data, title, navigateToClimateActionListView }: BarChartProps) {
  const handleDatumClick = (datum: BarDatum) => {
    if (!datum.name) {
      console.warn("No name found in bar chart datum. Cannot navigate to new page.");
      return;
    }
    navigateToClimateActionListView(`phase=${encodeURIComponent(datum.name)}`);
    posthogService.capture(BAR_CHART_CLICKED, {
      phase: datum.name,
    });
  };

  return (
    <Card className="flex flex-col rounded-lg">
      <CardHeader className="pb-0">
        <CardTitle className="text-sm font-normal">{title}</CardTitle>
        <div className="mt-2 border-t border-gray-200"></div>
      </CardHeader>
      <CardContent className="flex-1 p-2 pb-3 pl-6 pr-10">
        <div className="relative mx-auto my-2 flex h-[180px] w-full items-center justify-center">
          <ResponsiveBar
            enableTotals={true}
            theme={theme}
            data={data}
            keys={["count"]}
            indexBy="phaseType"
            margin={{ bottom: 70, top: 20, right: 15 }}
            padding={0.65}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={({ data }) => String(data.color)}
            borderRadius={2}
            borderWidth={0}
            axisTop={null}
            axisRight={null}
            onClick={(event) => {
              const barDatum = data.find((datum) => datum.phaseType === event.data.phaseType);
              if (barDatum) {
                handleDatumClick(barDatum);
              }
            }}
            role="button"
            axisBottom={{
              tickSize: 0,
              legendPosition: "middle",
              renderTick: (tick) => {
                const item = data.find((datum) => datum.phaseType === tick.value);

                return (
                  <g
                    transform={`translate(${tick.x},${tick.y + 15})`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (item) {
                        handleDatumClick(item);
                      }
                    }}
                  >
                    <text
                      textAnchor="start"
                      dominantBaseline="middle"
                      transform="rotate(45) translate(5,0)"
                      style={{
                        fontSize: 10,
                        fill: "#6b7280",
                      }}
                    >
                      {item?.name || tick.value}
                    </text>
                  </g>
                );
              },
            }}
            axisLeft={{
              tickSize: 0,
              tickValues: [],
            }}
            enableGridY={false}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="#333333"
            ariaLabel="Maßnahmen nach Phasen"
            barAriaLabel={(e) => `${e.indexValue}: ${e.value} Maßnahmen`}
            animate={true}
            motionConfig="gentle"
            tooltip={({ data, value }) => (
              <div className="rounded bg-black/80 p-2 text-sm text-white">
                <p>
                  {data.name}: {value} Maßnahmen
                </p>
              </div>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
