import { FC, useState } from "react";

import { PriorityType } from "@/components/_domain/priority/priority-type";
import { Button } from "@/components/_ui/button";
import { useCheckPermission } from "@/hooks/api/common/usePermission";

import { Priority } from "../model/priority.schema";

import { PriorityFormCreate } from "./actions/priority-form-create";
import { PriorityTableRow } from "./priority-table-row";

export interface PriorityTableSectionProps {
  workspaceId: string;
  priorityType: PriorityType;
  priorityList: Priority[];
  className?: string;
}

export const PriorityTableSection: FC<PriorityTableSectionProps> = ({
  workspaceId,
  priorityType,
  priorityList,
  className,
}) => {
  const [showCreatePriorityForm, setShowCreatePriorityForm] = useState(false);

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspaceId,
    action: "manage",
  });

  function handleCreatePriorityButtonPressed() {
    setShowCreatePriorityForm(true);
  }

  function onFormCanceled() {
    setShowCreatePriorityForm(false);
  }

  function onFormSuccess() {
    setShowCreatePriorityForm(false);
  }

  return (
    <div className={className}>
      <div className="mb-2 flex w-full flex-row items-center justify-between">
        <div className="text-muted-foreground">{priorityType}</div>
        <div>
          {hasPermissionToManageWorkspace && (
            <Button
              size="icon"
              variant="ghost"
              className="text-base text-foreground/80"
              onClick={handleCreatePriorityButtonPressed}
            >
              +
            </Button>
          )}
        </div>
      </div>
      {priorityList.map((s) => (
        <PriorityTableRow
          key={s.id}
          workspaceId={workspaceId}
          priority={s}
          hideDeleteAction={priorityList.length < 2}
        />
      ))}
      {showCreatePriorityForm && (
        <PriorityFormCreate
          workspaceId={workspaceId}
          priorityType={priorityType}
          onCanceled={onFormCanceled}
          onSuccess={onFormSuccess}
        />
      )}
    </div>
  );
};

export default PriorityTableSection;
