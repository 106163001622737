import { useMemo } from "react";

import { getFeatureFlagListQueryDocument } from "@/graphql/common/feature-flag";
import { useQuery } from "@apollo/client";

interface UseFeatureFlagListProps {
  workspaceId: string;
  refetch?: boolean; // force a refetch from posthog (not the same as apollo-client cache)
}

export const useFeatureFlagList = ({ workspaceId, refetch = false }: UseFeatureFlagListProps) => {
  const query = useQuery(getFeatureFlagListQueryDocument, {
    variables: {
      getFeatureFlagListInput: {
        workspaceId,
        refetch,
      },
    },
    skip: !workspaceId,
  });

  const featureFlagList = useMemo(() => {
    const data = query.data;
    if (!data?.featureFlagList) {
      return [];
    }

    return data.featureFlagList.map((flag) => {
      try {
        const payload = flag.payload ? JSON.parse(flag.payload) : null;
        return {
          ...flag,
          payload,
        };
      } catch (_error) {
        return {
          ...flag,
          payload: null,
        };
      }
    });
  }, [query.data]);

  return {
    featureFlagList,
    refetch: query.refetch,
    loading: query.loading,
    error: query.error,
    query,
  };
};
