export const StatusList = {
  onTrack: "On Track",
  atRisk: "At Risk",
  offTrack: "Off Track",
  blocked: "Blocked",
} as const;

export const ALLOWED_STATUS_LIST = Object.values(StatusList);
export type StatusType = (typeof ALLOWED_STATUS_LIST)[number];

export const statusTypefromString = (str: string): StatusType | undefined => {
  if (str === StatusList.onTrack) {
    return StatusList.onTrack;
  }
  if (str === StatusList.atRisk) {
    return StatusList.atRisk;
  }
  if (str === StatusList.offTrack) {
    return StatusList.offTrack;
  }
  if (str === StatusList.blocked) {
    return StatusList.blocked;
  }
  return undefined;
};

export const sortStatus = (a?: string, b?: string): 0 | 1 | -1 => {
  const numberFromType = (s?: string) => {
    if (s === StatusList.offTrack) return 0;
    if (s === StatusList.atRisk) return 1;
    if (s === StatusList.onTrack) return 2;
    if (s === StatusList.blocked) return 3;
    return -1;
  };

  const numA = numberFromType(a);
  const numB = numberFromType(b);

  if (numA - numB > 0) {
    return 1;
  }
  if (numA - numB < 0) {
    return -1;
  }

  return 0;
};
