import { Workspace } from "@/components/_domain/workspace/models/workspace-model";

interface WorkspaceWithUrl extends Pick<Workspace, "workspaceUrl"> {}

export const makeWorkspaceUrl = ({ workspace, path }: { workspace: WorkspaceWithUrl; path: string }) => {
  return `/${workspace.workspaceUrl}/${path}`;
};

export const makeWorkspaceSettingsUrl = ({ workspace, path }: { workspace: WorkspaceWithUrl; path: string }) => {
  return `/${workspace.workspaceUrl}/settings/${path}`;
};
