import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_LABEL_CREATED = "MUTATION:LABEL:CREATED";
export const EVENT_LABEL_UPDATED = "MUTATION:LABEL:UPDATED";
export const EVENT_LABEL_DELETED = "MUTATION:LABEL:DELETED";

export const EVENT_LABEL_GROUP_CREATED = "MUTATION:LABEL_GROUP:CREATED";
export const EVENT_LABEL_GROUP_UPDATED = "MUTATION:LABEL_GROUP:UPDATED";
export const EVENT_LABEL_GROUP_DELETED = "MUTATION:LABEL_GROUP:DELETED";

export const EVENT_LABEL_INSTANCE_CREATED = "MUTATION:LABEL_INSTANCE:CREATED";
export const EVENT_LABEL_INSTANCE_UPDATED = "MUTATION:LABEL_INSTANCE:UPDATED";
export const EVENT_LABEL_INSTANCE_DELETED = "MUTATION:LABEL_INSTANCE:DELETED";

type LabelEventType =
  | typeof EVENT_LABEL_CREATED
  | typeof EVENT_LABEL_UPDATED
  | typeof EVENT_LABEL_DELETED
  | typeof EVENT_LABEL_GROUP_CREATED
  | typeof EVENT_LABEL_GROUP_UPDATED
  | typeof EVENT_LABEL_GROUP_DELETED
  | typeof EVENT_LABEL_INSTANCE_CREATED
  | typeof EVENT_LABEL_INSTANCE_UPDATED
  | typeof EVENT_LABEL_INSTANCE_DELETED;

export const trackLabelEvent = (event: LabelEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
