import { ClimateAction } from "@/components/_domain/climate-action/models";
import { getClimateActionListQueryDocument, getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { ApolloCache } from "@apollo/client";

const handleAddClimateActionToParent = (cache: ApolloCache<Record<string, unknown>>, climateAction: ClimateAction) => {
  if (!climateAction.parentId) {
    return;
  }

  const cachedQueryResult = cache.readQuery({
    query: getClimateActionQueryDocument,
    variables: { input: { id: climateAction.parentId } },
  });

  if (!cachedQueryResult) {
    return;
  }

  // Check if the child already exists in the parent's children array
  const childExists = cachedQueryResult.climateAction.children.some((child) => child.id === climateAction.id);

  // No need to update the cache if the child already exists
  if (childExists) {
    return;
  }

  cache.writeQuery({
    query: getClimateActionQueryDocument,
    variables: { input: { id: climateAction.parentId } },
    data: {
      climateAction: {
        ...cachedQueryResult.climateAction,
        children: [...cachedQueryResult.climateAction.children, climateAction],
      },
    },
  });
};

const handleRemoveClimateActionFromParent = (
  cache: ApolloCache<Record<string, unknown>>,
  climateAction: Pick<ClimateAction, "id" | "parentId" | "workspaceId">,
) => {
  if (!climateAction.parentId) {
    return;
  }

  const cachedQueryResult = cache.readQuery({
    query: getClimateActionQueryDocument,
    variables: { input: { id: climateAction.parentId } },
  });

  if (!cachedQueryResult) {
    return;
  }

  cache.writeQuery({
    query: getClimateActionQueryDocument,
    variables: { input: { id: climateAction.parentId } },
    data: {
      climateAction: {
        ...cachedQueryResult.climateAction,
        children: cachedQueryResult.climateAction.children.filter((c) => c.id !== climateAction.id),
      },
    },
  });
};

export const onClimateActionCreate = (cache: ApolloCache<Record<string, unknown>>, climateAction: ClimateAction) => {
  // make sure the parent is updated with the new child
  handleAddClimateActionToParent(cache, climateAction);

  const cachedQueryResult = cache.readQuery({
    query: getClimateActionListQueryDocument,
    variables: {
      input: {
        workspaceId: climateAction.workspaceId,
      },
    },
  });

  if (!cachedQueryResult) {
    return;
  }

  cache.writeQuery({
    query: getClimateActionListQueryDocument,
    variables: {
      input: {
        workspaceId: climateAction.workspaceId,
      },
    },
    data: {
      climateActionListForWorkspace: [...cachedQueryResult.climateActionListForWorkspace, climateAction],
    },
  });
};

export const onClimateActionDelete = (
  cache: ApolloCache<Record<string, unknown>>,
  climateAction: Pick<ClimateAction, "id" | "parentId" | "workspaceId">,
) => {
  const cachedQueryResult = cache.readQuery({
    query: getClimateActionListQueryDocument,
    variables: { input: { workspaceId: climateAction.workspaceId } },
  });

  if (!cachedQueryResult) {
    return;
  }

  // 1. First, make sure there are no orphaned children
  // ---------------------------------------------------------------------
  const orphanedChildren = cachedQueryResult.climateActionListForWorkspace.filter(
    (c) => c.parentId === climateAction.id,
  );

  for (const child of orphanedChildren) {
    cache.writeQuery({
      query: getClimateActionQueryDocument,
      variables: { input: { id: child.id } },
      data: {
        climateAction: {
          ...child,
          parentId: null,
        },
      },
    });
  }

  // 2. Make sure it's removed from the parent
  // ---------------------------------------------------------------------
  handleRemoveClimateActionFromParent(cache, climateAction);
};

export const onClimateActionUpdate = (cache: ApolloCache<Record<string, unknown>>, climateAction: ClimateAction) => {
  // Note: We don't know the "old" state of the climate-action, so we need to figure our what changed

  // 1. If the parentId is set, make sure the climate action is added to the parent
  //    (If this is already the case, this will be a no-op)
  // ---------------------------------------------------------------------
  if (climateAction.parentId) {
    handleAddClimateActionToParent(cache, climateAction);
  }

  // 2. Check whether there is an orphaned parent that has this climate-action
  //    as a child, event though it is not its parent.
  // ---------------------------------------------------------------------
  const cachedQueryResult = cache.readQuery({
    query: getClimateActionListQueryDocument,
    variables: {
      input: {
        workspaceId: climateAction.workspaceId,
      },
    },
  });

  if (!cachedQueryResult) {
    return;
  }

  const orphanedParentList = cachedQueryResult.climateActionListForWorkspace.filter((maybeParent) =>
    maybeParent.children.some((child) => child.id === climateAction.id),
  );

  for (const parent of orphanedParentList) {
    cache.writeQuery({
      query: getClimateActionQueryDocument,
      variables: { input: { id: parent.id } },
      data: {
        climateAction: {
          ...parent,
          children: parent.children.filter((c) => c.id !== climateAction.id),
        },
      },
    });
  }
};
