import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { isTopLevelClimateAction } from "@/lib/utils/isTopLevelClimateAction";
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  Table,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";

import { ClimateActionColumnConfiguration } from "../climate-action-list-overview/columns/column-definition-type";
import { ClimateAction, ClimateActionFragment } from "../models";

export interface ClimateActionListConfig {
  columnOrder?: ColumnOrderState;
  columnFilters?: ColumnFiltersState;
  columnVisibility?: VisibilityState;
  sorting?: SortingState;
  expanded?: ExpandedState;
  rowSelection?: RowSelectionState;
  globalFilter?: string;
}

interface ClimateActionListConfigWithColumnConfig extends ClimateActionListConfig {
  columnConfigurationList: ClimateActionColumnConfiguration[];
}

export interface ClimateActionListStateProviderProps {
  children: ReactNode;
  workspace: { id: string };
  user: { id: string };
  climateActionList: ClimateAction[];
  showChildren: boolean;
  enableExpanding: boolean;
  config: ClimateActionListConfigWithColumnConfig;
}
interface ClimateActionListStateType {
  config: {
    workspace: { id: string };
    user: { id: string };
    climateActionList: ClimateAction[];
    showChildren: boolean;
    enableExpanding: boolean;

    columnConfigurationList: ClimateActionColumnConfiguration[];
    defaultColumnOrderState?: ColumnOrderState;
    defaultColumnFiltersState?: ColumnFiltersState;
    defaultVisibilityState?: VisibilityState;
    defaultSortingState?: SortingState;
    defaultExpandedState?: ExpandedState;
    defaultRowSelectionState?: RowSelectionState;
    defaultGlobalFilterState?: string;
  };

  data: {
    climateActionList: ClimateAction[];
  };

  fn: {
    getConfigurationForColumn: (id: string) => ClimateActionColumnConfiguration | null;
    getDisplayValueForColumn: (id: string) => string;
    setGlobalFilter: (globalFilter: string) => void;
  };

  table: Table<ClimateAction>;
  columns: ColumnDef<ClimateAction>[];
  columnFilters: ColumnFiltersState;
  columnVisibility: VisibilityState;
  columnOrder: ColumnOrderState;
  sorting: SortingState;
  expandedState: ExpandedState;
  rowSelectionState: RowSelectionState;
  globalFilterState: string;

  setTableConfiguration: (config: ClimateActionListConfig) => void;
}

const ClimateActionListState = createContext<ClimateActionListStateType | undefined>(undefined);

// Create the provider component
export const ClimateActionListStateProvider: FC<ClimateActionListStateProviderProps> = ({ children, ...props }) => {
  const [sorting, setSorting] = useState<SortingState>(props.config.sorting ?? []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(props.config.columnFilters ?? []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(props.config.columnVisibility ?? {});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(props.config.columnOrder ?? []);
  const [expandedState, setExpandedState] = useState<ExpandedState>(props.config.expanded ?? {});
  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(props.config.rowSelection ?? {});
  const [globalFilterState, setGlobalFilterState] = useState<string>(props.config.globalFilter ?? "");

  const climateActionList = useMemo(() => {
    return props.climateActionList.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [props.climateActionList]);

  const topLevelClimateActionList = useMemo(() => {
    return climateActionList.slice().filter((o) => isTopLevelClimateAction(o, climateActionList));
  }, [climateActionList]);

  const enableExpanding = useMemo(() => {
    // if there is a filter active deactivate expanding
    if (columnFilters.length > 0) {
      return false;
    }
    if (globalFilterState.length > 0) {
      return false;
    }
    return props.enableExpanding;
  }, [props.enableExpanding, columnFilters, globalFilterState]);

  const showChildren = useMemo(() => {
    // if there is a filter active, show all children
    if (columnFilters.length > 0) {
      return true;
    }
    if (globalFilterState.length > 0) {
      return true;
    }
    return props.showChildren;
  }, [props.showChildren, columnFilters, globalFilterState]);

  const data = useMemo(() => {
    if (!showChildren) {
      return topLevelClimateActionList;
    }
    return climateActionList;
  }, [climateActionList, topLevelClimateActionList, showChildren]);

  const columns = useMemo(() => {
    return props.config.columnConfigurationList.map((c) => c.getColumnDef());
  }, [props.config.columnConfigurationList]);

  const getSubRowsForClimateAction = useCallback(
    ({ id }: ClimateActionFragment) => {
      return climateActionList.slice().filter((o) => o.parentId === id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [climateActionList, columnFilters],
  );

  const table = useReactTable({
    debugTable: false,
    data: data,
    columns: columns,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelectionState,
    onExpandedChange: setExpandedState,
    getRowCanExpand: (row) => {
      return enableExpanding && row.original.children.length > 0;
    },
    getSubRows: (climateAction) => {
      const climateActionRowChildList = getSubRowsForClimateAction({ id: climateAction.id });

      if (!climateActionRowChildList || climateActionRowChildList.length === 0) {
        return undefined;
      }

      return climateActionRowChildList;
    },

    onGlobalFilterChange: setGlobalFilterState,
    getColumnCanGlobalFilter: () => {
      return true;
    },
    globalFilterFn: (row, columnId, value, _addMeta) => {
      if (columnId === "climateAction_title") {
        return false;
      }

      if (!value || value.length === 0) {
        return true;
      }

      return row.original.title.toLowerCase().includes(value.toLowerCase());
    },

    state: {
      sorting,
      columnFilters,
      columnVisibility,
      columnOrder,
      rowSelection: rowSelectionState,
      expanded: expandedState,
      globalFilter: globalFilterState,
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  const getConfigurationForColumn = useCallback(
    (id: string) => {
      const conf = props.config.columnConfigurationList.find((c) => c.columnId === id);

      if (!conf) {
        return null;
      }

      return conf;
    },
    [props.config.columnConfigurationList],
  );

  const getDisplayValueForColumn = useCallback(
    (id: string): string => {
      const col = props.config.columnConfigurationList.find((c) => c.columnId === id);

      if (!col) {
        return id;
      }

      return col.getDisplayValue();
    },
    [props.config.columnConfigurationList],
  );

  const setTableConfiguration = (config: ClimateActionListConfig) => {
    if (config.columnOrder) {
      setColumnOrder(config.columnOrder);
    }
    if (config.columnFilters) {
      setColumnFilters(config.columnFilters);
    }
    if (config.columnVisibility) {
      setColumnVisibility(config.columnVisibility);
    }
    if (config.sorting) {
      setSorting(config.sorting);
    }
    if (config.expanded) {
      setExpandedState(config.expanded);
    }
    if (config.rowSelection) {
      setRowSelectionState(config.rowSelection);
    }
    if (config.globalFilter) {
      setGlobalFilterState(config.globalFilter);
    }
  };

  useEffect(() => {
    // if we filter, make sure we reset the expanded state
    if (columnFilters.length > 0) {
      setExpandedState({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters]);

  return (
    <ClimateActionListState.Provider
      value={{
        config: {
          ...props.config,
          workspace: props.workspace,
          user: props.user,
          climateActionList: props.climateActionList,
          showChildren: props.showChildren,
          enableExpanding: props.enableExpanding,
        },
        data: {
          climateActionList: data,
        },
        fn: {
          getConfigurationForColumn,
          getDisplayValueForColumn,
          setGlobalFilter: setGlobalFilterState,
        },

        table,
        columns,
        columnFilters,
        columnOrder,
        columnVisibility,
        sorting,
        expandedState,
        rowSelectionState,
        globalFilterState,
        setTableConfiguration,
      }}
    >
      {children}
    </ClimateActionListState.Provider>
  );
};

// Define the useClimateActionListState hook
export const useClimateActionListState = (): ClimateActionListStateType => {
  const context = useContext(ClimateActionListState);

  if (!context) {
    throw new Error("useClimateActionListState must be used within a ChildProvider");
  }

  return context;
};
