import { FC, PropsWithChildren, useState } from "react";
import { useNavigate } from "react-router-dom";

import IconChildClimateAction from "@/components/_icons/IconChildClimateAction";
import IconDots from "@/components/_icons/IconDots";
import IconParentClimateAction from "@/components/_icons/IconParentClimateAction";
import IconTrash from "@/components/_icons/IconTrash";
import { Button } from "@/components/_ui/button";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "@/components/_ui/menubar";
import { toast } from "@/components/_ui/use-toast";
import { DeleteConfirmation } from "@/components/delete-confirmation";
import { useDeleteClimateAction } from "@/hooks/api/common/useClimateAction/useDeleteClimateAction";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace";

import { ClimateActionSetParentDialog } from "./climate-action-set-parent-dialog";

interface ClimateActionEditDropdownProps extends PropsWithChildren {
  climateAction: {
    id: string;
    parentId?: string | null | undefined;
  };
  workspaceId: string;
  callbacks: {
    showCreateChildClimateAction: () => void;
  };
  teamId?: string | undefined;
}
export const ClimateActionEditDropdown: FC<ClimateActionEditDropdownProps> = ({
  climateAction,
  teamId,
  callbacks,
  workspaceId,
}) => {
  const navigate = useNavigate();
  const { workspace } = useWorkspaceGetById({ id: workspaceId });
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const { deleteClimateAction } = useDeleteClimateAction({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    teamId,
    workspaceId,
  });
  const [parentClimateActionDialogisOpen, setParentClimateActionDialogisOpen] = useState(false);

  function onUpdateCompleted() {
    toast({
      title: "Maßnahme gelöscht",
      description: "Wir haben die Maßnahme erfolgreich gelöscht.",
      variant: "success",
    });

    if (!workspace) {
      return;
    }

    navigate(`/${workspace.workspaceUrl}/teams/${teamId}`);
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler beim Löschen des Maßnahmens aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
      variant: "error",
    });
  }

  async function handleDeleteClimateAction() {
    deleteClimateAction({
      variables: {
        input: {
          id: climateAction.id,
        },
      },
    });
  }

  async function handleSetParentClimateAction() {
    setParentClimateActionDialogisOpen(true);
  }

  return (
    <>
      <Menubar className="shadow-none rounded-md">
        <MenubarMenu>
          <MenubarTrigger asChild>
            <Button variant="ghost" size="sm" className="select-none rounded-md">
              <IconDots />
            </Button>
          </MenubarTrigger>
          <MenubarContent className="rounded-md">
            <MenubarItem onClick={() => callbacks.showCreateChildClimateAction()}>
              <div className="flex flex-row items-center gap-1">
                <IconChildClimateAction className="mr-1 h-4 w-4 text-muted-foreground" />
                Teilmaßnahme hinzufügen
              </div>
            </MenubarItem>

            <MenubarItem onClick={() => handleSetParentClimateAction()}>
              <div className="flex flex-row items-center gap-1">
                <IconParentClimateAction className="mr-1 h-4 w-4 text-muted-foreground" />
                {!climateAction.parentId && <span>Übergeordnete Maßnahme setzen</span>}
                {climateAction.parentId && <span>Übergeordnete Maßnahme ändern</span>}
              </div>
            </MenubarItem>

            <MenubarSeparator />

            <MenubarItem
              onClick={() => {
                setDeleteConfirmationisOpen(true);
              }}
            >
              <div className="flex flex-row items-center gap-1">
                <IconTrash className="mr-2 h-3 w-3 text-muted-foreground" />
                Maßnahme löschen
              </div>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>

      {teamId && (
        // only allow setting parent climate action for climate actions that are part of a team
        <ClimateActionSetParentDialog
          open={parentClimateActionDialogisOpen}
          onOpenChange={setParentClimateActionDialogisOpen}
          climateAction={climateAction}
          workspaceId={workspaceId}
          teamId={teamId}
        />
      )}
      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={() => {
          handleDeleteClimateAction();
        }}
        title="Sind Sie sich sicher, dass Sie diese Maßnahme löschen möchten?"
        description="Diese Aktion kann nicht rückgängig gemacht werden. Die Maßnahme und alle damit verbundenen Fortschrittsberichte werden unwiderruflich gelöscht."
      />
    </>
  );
};
