import { FC, PropsWithChildren, useMemo } from "react";

import IconCircleGroup from "@/components/_icons/IconCircleGroup";
import { getLabelGroupQueryDocument } from "@/graphql/common/label-group";
import { useQuery } from "@apollo/client";

export interface LabelGroupItemProps extends PropsWithChildren {
  labelGroup: {
    id: string;
  };
}

export const LabelGroupItem: FC<LabelGroupItemProps> = ({ ...props }) => {
  const { data } = useQuery(getLabelGroupQueryDocument, {
    variables: { input: { id: props.labelGroup.id } },
  });

  const labelGroup = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.labelGroup;
  }, [data]);

  if (!labelGroup) {
    return <></>;
  }

  return (
    <div className="flex flex-row items-center gap-2 p-1.5 text-sm">
      <div className="flex w-6 flex-row items-center opacity-0 hover:opacity-100"></div>
      <div>
        <IconCircleGroup className="h-3.5 w-3.5" style={{ color: labelGroup.color }} />
      </div>
      <div className="grow">{labelGroup.title}</div>
    </div>
  );
};

export default LabelGroupItem;
