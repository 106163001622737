import { createContext, type FC, type PropsWithChildren, useContext } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common/useWorkspace/useTeamListForUserInWorkspace";

import { type Team } from "../models/team-model";

type CurrentTeamListContext = {
  currentTeamList: Team[];
  currentActiveTeamList: Team[];
};

const CurrentTeamListContext = createContext<CurrentTeamListContext | null>(null);

export interface CurrentTeamListContextProviderProps extends PropsWithChildren {
  userId: string;
  workspaceId: string;
  required?: boolean;
}

export const CurrentTeamListContextProvider: FC<CurrentTeamListContextProviderProps> = ({
  children,
  userId,
  workspaceId,
  required = true,
}) => {
  const { teamList, activeTeamList, loading, error } = useTeamListForUserInWorkspace({
    workspaceId: workspaceId,
    userId: userId,
  });

  if (loading) {
    return <Loading />;
  }

  if (error && required) {
    return <ErrorView />;
  }

  return (
    <CurrentTeamListContext.Provider value={{ currentTeamList: teamList, currentActiveTeamList: activeTeamList }}>
      {children}
    </CurrentTeamListContext.Provider>
  );
};

export const useCurrentTeamListContext = () => {
  const context = useContext(CurrentTeamListContext);

  if (!context) {
    throw new Error("useCurrentTeamListContext must be used within a CurrentTeamListContextProvider");
  }

  return context;
};

export const useSafeCurrentTeamListContext = () => {
  // May be used for DEBUGGING purposes
  return useContext(CurrentTeamListContext);
};
