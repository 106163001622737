import { FC, PropsWithChildren, useMemo } from "react";

import { ALLOWED_FOCUS_AREA_LIST } from "@/components/_domain/focusArea";
import { useGetFocusAreaList } from "@/hooks/api/common/useFocusArea";
import { groupBy } from "@/lib/utils";

import { FocusAreaTableSection } from "./focus-area-table-section";

export interface FocusAreaTablePropsWithChildren extends PropsWithChildren {
  workspace: {
    id: string;
  };
  className?: string;
}

export const FocusAreaTable: FC<FocusAreaTablePropsWithChildren> = ({ workspace }) => {
  const { focusAreaList } = useGetFocusAreaList({ workspaceId: workspace.id });

  const groupedFocusAreaList = useMemo(() => {
    return groupBy(focusAreaList, (focusArea) => focusArea.type);
  }, [focusAreaList]);

  if (!focusAreaList) {
    return <></>;
  }

  return (
    <>
      {ALLOWED_FOCUS_AREA_LIST.map((type) => {
        const focusAreaForType = groupedFocusAreaList.get(type) ?? [];
        return (
          <FocusAreaTableSection
            key={type}
            workspace={workspace}
            focusAreaType={type}
            focusAreaList={focusAreaForType}
            className="mb-4"
          />
        );
      })}
    </>
  );
};

export default FocusAreaTable;
