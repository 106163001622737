import { z } from "zod";

export const FilterByTextListPayloadSchema = z.object({
  type: z.literal("filter_by_text_list"),
  textList: z.array(z.string().nullable()),
  mode: z.union([z.literal("included"), z.literal("not_included")]),
});

export type FilterByTextListPayload = z.infer<typeof FilterByTextListPayloadSchema>;
export type FilterByTextListMode = FilterByTextListPayload["mode"];
export type FilterByTextList = FilterByTextListPayload["textList"];

export const filterByTextList = (payload: FilterByTextListPayload, text: string | null | undefined): boolean => {
  if (payload.mode === "included") {
    return payload.textList.includes(text ?? null);
  }

  if (payload.mode === "not_included") {
    return !payload.textList.includes(text ?? null);
  }

  // fall through; shouldn't happen
  return true;
};

export const filterByTextListWithUnknownPayload = (payload: unknown, text: string | null | undefined): boolean => {
  const safePayload = FilterByTextListPayloadSchema.safeParse(payload);
  if (!safePayload.success) {
    return true; // if we cannot apply the filter then don't filter at all
  }

  return filterByTextList(safePayload.data, text);
};
