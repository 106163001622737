import { useMemo } from "react";

import { checkPermissionListQueryDocument } from "@/graphql/common/permission";
import { useQuery } from "@apollo/client";

import { ActionType, PermissionCheck, ResourceType } from "./types";

interface UseCheckPermissionListProps {
  permissions: PermissionCheck[];
  enabled?: boolean;
}

export const useCheckPermissionList = ({ permissions, enabled = true }: UseCheckPermissionListProps) => {
  const { loading, error, data, refetch } = useQuery(checkPermissionListQueryDocument, {
    variables: {
      input: {
        permissions: permissions.map((p) => ({
          resource: p.resource,
          action: p.action,
          resourceId: p.resourceId || "",
        })),
      },
    },
    skip: permissions.length === 0 || !enabled,
  });

  const permissionRecord = useMemo(() => {
    const record: Partial<Record<ResourceType, Partial<Record<ActionType, boolean>>>> = {};

    if (data?.checkPermissionList) {
      data.checkPermissionList.forEach((result) => {
        // Extract resource and action from the id
        const [resource, action] = result.id.split("-");

        if (!record[resource as ResourceType]) {
          record[resource as ResourceType] = {};
        }

        record[resource as ResourceType]![action as ActionType] = result.hasPermission;
      });
    }

    return record;
  }, [data]);

  return {
    refetch,
    loading,
    error,
    permissionResultList: data?.checkPermissionList ?? [],
    permissionRecord,
  };
};
