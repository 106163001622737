import { FC, PropsWithChildren, ReactElement, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";

import { ALLOWED_INDICATOR_TYPES, IndicatorType, IndicatorTypeGermanNames } from "../../models/indicator-type.model";

import { IndicatorTypeSelectDropdownItem } from "./indicator-type-select-dropdown-item";

export interface IndicatorTypeSelectDropdownProps extends PropsWithChildren {
  defaultValue?: IndicatorType;
  onSelect?: (type: IndicatorType | null) => void;
  asChild?: boolean;
  isRequired?: boolean;
}

export const IndicatorTypeSelectDropdown: FC<IndicatorTypeSelectDropdownProps> = ({
  children,
  asChild,
  onSelect,
  isRequired = false,
  ...props
}) => {
  const [selectedType, setSelectedType] = useState<IndicatorType | null>(
    props.defaultValue ?? (isRequired ? ALLOWED_INDICATOR_TYPES[0] : null),
  );

  const comboboxOptions = useMemo(() => {
    const options: ComboboxOption[] = ALLOWED_INDICATOR_TYPES.map((type) => ({
      id: type,
      value: `${IndicatorTypeGermanNames[type]}`,
    }));

    if (!isRequired) {
      options.unshift({
        id: "none",
        value: "Nicht zugewiesen",
      });
    }

    return options;
  }, [isRequired]);

  const renderItem = (option: ComboboxOption): ReactElement => {
    const selected = option.id === (selectedType ?? "none");
    const type = option.id === "none" ? null : (option.id as IndicatorType);
    return <IndicatorTypeSelectDropdownItem type={type} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    const type = selectedOption.id === "none" ? null : (selectedOption.id as IndicatorType);
    setSelectedType(type);
    if (onSelect) {
      onSelect(type);
    }
  };

  if (!comboboxOptions.length) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      placeholder="Indikatorkategorie"
      side="bottom"
    >
      {children}
    </Combobox>
  );
};
