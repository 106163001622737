import { FC, useEffect, useMemo, useState } from "react";

import { ALLOWED_STATUS_LIST, StatusView } from "@/components/_domain/progress-snapshot";
import { Checkbox } from "@/components/_ui/checkbox";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { cn } from "@/lib/utils";

import { useClimateActionListState } from "../../../../hooks";
import { FilterByIdList, FilterByIdPayload, FilterByIdPayloadSchema } from "../../../filter-functions";

export interface FilterByStatusConfigurationViewProps {
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByStatusConfigurationView: FC<FilterByStatusConfigurationViewProps> = ({ onFinished, ...props }) => {
  const columnId = props.column.id;
  const { table, columnFilters } = useClimateActionListState();
  const statusList = ALLOWED_STATUS_LIST;

  const [selectedIds, setSelectedIds] = useState<FilterByIdList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(columnId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByIdPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedIds(filterValue.idList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedIds) {
      return;
    }

    if (selectedIds.length === 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_id",
      mode: filterValue?.mode ?? "included",
      idList: selectedIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const setFilter = (payload: FilterByIdPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleSelect = (statusId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== statusId);
    setSelectedIds([...updatedIdList, statusId]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (statusId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== statusId);

    setSelectedIds(updatedIdList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command>
      <CommandInput placeholder={"Status"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedIds && !!selectedIds.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <StatusView type={null} className="h-3.5 w-3.5 rounded-full" />
              </CommandItem>
            );
          })}

          {statusList.map((status) => {
            const isSelected = !!selectedIds && !!selectedIds.find((id) => id === status);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={status}
                value={status}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(status, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(status);
                  }}
                />
                <StatusView type={status} className="h-3.5 w-3.5" />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
