import { isValid, parse } from "date-fns";
import { de } from "date-fns/locale";
import { z } from "zod";

export const FilterByDatePayloadSchema = z.object({
  type: z.literal("filter_by_date"),
  date: z.string(),
  mode: z.union([z.literal("before"), z.literal("after")]),
});

export type FilterByDatePayload = z.infer<typeof FilterByDatePayloadSchema>;
export type FilterByDateMode = FilterByDatePayload["mode"];

const GermanDateInputSchema = z.string().refine(
  (value) => {
    const parsedDate = parse(value, "dd.MM.yyyy", new Date(), { locale: de });
    return isValid(parsedDate);
  },
  {
    message: "Invalid date format (dd.MM.yyyy)",
  },
);

export const validateGermanDateInput = (value: string) => {
  const result = GermanDateInputSchema.safeParse(value);
  if (!result.success) {
    return { success: false, error: result.error.errors[0].message };
  }
  return { success: true, data: result.data };
};

export const filterByDate = (payload: FilterByDatePayload, date: string | null | undefined): boolean => {
  if (!date) {
    return false;
  }

  const filterDate = new Date(payload.date);
  const targetDate = new Date(date);

  if (payload.mode === "before") {
    return targetDate < filterDate;
  }

  if (payload.mode === "after") {
    return targetDate > filterDate;
  }

  // fall through; shouldn't happen
  return true;
};

export const filterByDateWithUnknownPayload = (payload: unknown, date: string | null | undefined): boolean => {
  const safePayload = FilterByDatePayloadSchema.safeParse(payload);
  if (!safePayload.success) {
    return true; // if we cannot apply the filter then don't filter at all
  }

  return filterByDate(safePayload.data, date);
};
