import { useMemo } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { NotFoundView } from "@/components/not-found-view";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common/useWorkspace";

export default function Page() {
  const { currentUser } = useCurrentUserContext();

  const { teamId } = useParams();
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { activeTeamList } = useTeamListForUserInWorkspace({
    workspaceId: currentWorkspace.id,
    userId: currentUser?.id,
  });

  const nextTeam = useMemo(() => {
    if (activeTeamList.length === 0) {
      return null;
    }

    return activeTeamList[0];
  }, [activeTeamList]);

  if (!currentWorkspace.workspaceUrl) {
    return <NotFoundView />;
  }

  if (!teamId) {
    if (!nextTeam) {
      return <NotFoundView />;
    }

    return <Navigate to={`/${currentWorkspace.workspaceUrl}/settings/teams/${nextTeam.id}`} replace={true} />;
  }

  return <Outlet />;
}
