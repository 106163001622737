import { getClimateActionListQueryDocument, getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { updateTeamInstanceListForOwnerMutationDocument } from "@/graphql/common/team-instance";
import { EVENT_TEAM_INSTANCE_UPDATED, trackTeamEvent } from "@/hooks/api/common/useWorkspace/tracking";
import { useMutation } from "@apollo/client";

interface ClimateActionInput {
  id: string;
}

export interface UseUpdateTeamInstanceListByOwnerProps {
  onCompleted?: () => void;
  onError?: (e: Error) => void;
  teamIdList: string[];
  workspaceId: string;
  ownerId?: string; // Add ownerId to props to use for refetching the specific climate action
}

export const useUpdateTeamInstanceListByOwner = ({
  onCompleted,
  onError,
  workspaceId,
  ownerId,
}: UseUpdateTeamInstanceListByOwnerProps) => {
  const [updateTeamInstanceListByOwner] = useMutation(updateTeamInstanceListForOwnerMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const teamInstance = data?.updateTeamInstanceListForOwner;
      if (!teamInstance) {
        return;
      }
      trackTeamEvent(EVENT_TEAM_INSTANCE_UPDATED, teamInstance);
    },
    refetchQueries: [
      {
        query: getClimateActionListQueryDocument,
        variables: { input: { workspaceId: workspaceId } },
      },
      {
        query: getClimateActionQueryDocument,
        variables: { input: { id: ownerId } as ClimateActionInput },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updateTeamInstanceListByOwner,
  };
};
