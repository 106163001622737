import { useMemo } from "react";

import { workspaceMembershipListForWorkspaceQueryDocument } from "@/graphql/common/workspace-membership";
import { useQuery } from "@apollo/client";

export interface UseGetWorkspaceMembershipListProps {
  workspaceId?: string;
}

export const useWorkspaceMembershipList = (props: UseGetWorkspaceMembershipListProps) => {
  const queryResult = useQuery(workspaceMembershipListForWorkspaceQueryDocument, {
    variables: { input: props.workspaceId ?? "" },
    skip: !props.workspaceId,
  });

  const workspaceMembershipList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.workspaceMembershipListForWorkspace;
  }, [queryResult.data]);

  const activeWorkspaceMembershipList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.workspaceMembershipListForWorkspace.slice().filter((wsm) => {
      return wsm.status === "ACTIVE";
    });
  }, [queryResult.data]);

  const activeOrPendingWorkspaceMembershipList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.workspaceMembershipListForWorkspace.slice().filter((wsm) => {
      return wsm.status === "ACTIVE" || wsm.status === "PENDING";
    });
  }, [queryResult.data]);

  return {
    queryResult,
    loading: queryResult.loading,
    error: queryResult.error,
    workspaceMembershipList,
    activeWorkspaceMembershipList,
    activeOrPendingWorkspaceMembershipList,
  };
};
