import { FC } from "react";

import IconLabelTag from "@/components/_icons/IconLabelTag";
import { useGetLabelGroup } from "@/hooks/api/common/useLabel";
import { cn } from "@/lib/utils";

export interface FilterByLabelGroupDisplayViewProps {
  labelGroup: {
    id: string;
  };
  className?: string;
}

export const FilterByLabelGroupDisplayView: FC<FilterByLabelGroupDisplayViewProps> = ({ className, ...props }) => {
  const { labelGroup } = useGetLabelGroup(props.labelGroup);

  return (
    <>
      <IconLabelTag className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">{labelGroup?.title ?? ""}</span>
    </>
  );
};
