import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getLabelListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query GetLabelListForWorkspace($input: GetLabelsForWorkspaceInput!) {
    labelsForWorkspace(getLabelsForWorkspaceInput: $input) {
      id
      title
      color
      workspaceId
      labelGroupId
      instances {
        id
      }
    }
  }
`);

export const getLabelQueryDocument = graphql(/* GraphQL */ `
  query GetLabel($input: GetLabelInput!) {
    label(getLabelInput: $input) {
      id
      title
      color
      labelGroupId
      workspaceId
      instances {
        id
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createLabelMutationDocument = graphql(/* GraphQL */ `
  mutation createLabel($input: CreateLabelInput!) {
    createLabel(createLabelInput: $input) {
      id
      title
      color
      labelGroupId
      workspaceId
      instances {
        id
      }
    }
  }
`);

export const updateLabelMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateLabel($input: UpdateLabelInput!) {
    updateLabel(updateLabelInput: $input) {
      id
      title
      color
      labelGroupId
      workspaceId
      instances {
        id
      }
    }
  }
`);

export const deleteLabelMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteLabel($input: DeleteLabelInput!) {
    deleteLabel(deleteLabelInput: $input) {
      id
      title
      color
      labelGroupId
      workspaceId
      instances {
        id
      }
    }
  }
`);
