import { Outlet, useParams } from "react-router-dom";

import { CurrentFocusAreaContextProvider } from "@/components/_domain/focusArea/hooks/useCurrentFocusAreaContext";
import { NotFoundView } from "@/components/not-found-view";

export function FocusAreaLayout() {
  const { focusAreaId } = useParams();

  if (!focusAreaId) {
    return <NotFoundView />;
  }

  return (
    <CurrentFocusAreaContextProvider focusAreaId={focusAreaId}>
      <Outlet />
    </CurrentFocusAreaContextProvider>
  );
}
