import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getFocusAreaQueryDocument = graphql(/* GraphQL */ `
  query GetFocusArea($input: GetFocusAreaInput!) {
    focusArea(getFocusAreaInput: $input) {
      id
      workspaceId
      name
      color
      type
      assignedInstanceList {
        id
      }
    }
  }
`);

export const getFocusAreaListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query GetFocusAreaListForWorkspace($input: GetFocusAreaListForWorkspaceInput!) {
    focusAreaListForWorkspace(getFocusAreaListForWorkspaceInput: $input) {
      id
      workspaceId
      name
      color
      type
      assignedInstanceList {
        id
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createFocusAreaMutationDocument = graphql(/* GraphQL */ `
  mutation createFocusArea($input: CreateFocusAreaInput!) {
    createFocusArea(createFocusAreaInput: $input) {
      id
      workspaceId
      name
      color
      type
      assignedInstanceList {
        id
      }
    }
  }
`);

export const updateFocusAreaMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateFocusArea($input: UpdateFocusAreaInput!) {
    updateFocusArea(updateFocusAreaInput: $input) {
      id
      workspaceId
      name
      color
      type
      assignedInstanceList {
        id
      }
    }
  }
`);

export const deleteFocusAreaMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteFocusArea($input: DeleteFocusAreaInput!) {
    deleteFocusArea(deleteFocusAreaInput: $input) {
      id
      workspaceId
      name
      color
      type
      assignedInstanceList {
        id
      }
    }
  }
`);
