import { FC } from "react";
import { HiChartBar } from "react-icons/hi2";

import { cn } from "@/lib/utils";

export interface IndicatorListTitleViewProps {
  className?: string;
}

export const IndicatorListTitleView: FC<IndicatorListTitleViewProps> = ({ className }) => {
  return (
    <div
      className={cn([
        "my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm ",
        className,
      ])}
    >
      <span className="rounded bg-muted p-1">
        <HiChartBar className="h-3 w-3" />
      </span>
      <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
        Indikatoren
      </span>
    </div>
  );
};
