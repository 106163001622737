import { FC, useEffect, useRef, useState } from "react";

import { PhaseIcon } from "@/components/_domain/phase";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { ColorPicker } from "@/components/color-picker/color-picker";
import { useUpdatePhase } from "@/hooks/api/common/usePhase";

export interface PhaseFormEditProps {
  phase: {
    id: string;
    name: string;
    color: string;
    type: string;
  };
  className?: string;
  onSuccess?: () => void;
  onCanceled?: () => void;
}

export const PhaseFormEdit: FC<PhaseFormEditProps> = ({ phase, onSuccess, onCanceled }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [phaseName, setPhaseName] = useState(phase.name);
  const [phaseColor, setPhaseColor] = useState(phase.color);

  const { updatePhase } = useUpdatePhase({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleUpdatePhase() {
    if (phaseName === "" || phaseColor === "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Name und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await updatePhase({
      variables: {
        input: {
          id: phase.id,
          name: phaseName,
          color: phaseColor,
          type: phase.type,
        },
      },
    });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler bei der Aktualisierung der Phase aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className="flex w-full flex-row items-center justify-between gap-2 p-1">
      <div className="w-9">
        <ColorPicker
          asChild
          value={phaseColor}
          onChange={(color) => {
            setPhaseColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <PhaseIcon className="h-4 w-4" phase={{ type: phase.type, color: phaseColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={phaseName}
          onChange={(e) => setPhaseName(e.currentTarget.value)}
          placeholder="Name der Phase"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={phaseName === ""}
          onClick={handleUpdatePhase}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default PhaseFormEdit;
