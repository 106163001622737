import { type CreateSavedViewMutation } from "@/graphql/generated/graphql";
import { useMutation } from "@apollo/client";

import {
  createSavedViewMutationDocument,
  getSavedViewListForWorkspaceQueryDocument,
  getSavedViewsByUrlQueryDocument,
} from "./saved-view.gql";

export type CreatedSavedView = CreateSavedViewMutation["createSavedView"];

interface UseCreateSavedViewProps {
  onCompleted?: (data: CreatedSavedView) => void;
  onError?: (e: Error) => void;
}

export const useCreateSavedView = ({ onCompleted, onError }: UseCreateSavedViewProps = {}) => {
  const [createSavedViewMutation, { data, loading, error }] = useMutation(createSavedViewMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const createdSavedView = data?.createSavedView;

      if (!createdSavedView) {
        return;
      }

      // 1. Update all the saved views for the workspace
      // --------------------------------------------------------
      const cachedSavedViewForWorkspaceQuery = cache.readQuery({
        query: getSavedViewListForWorkspaceQueryDocument,
        variables: { input: { workspaceId: createdSavedView.workspaceId } },
      });

      if (cachedSavedViewForWorkspaceQuery) {
        cache.writeQuery({
          query: getSavedViewListForWorkspaceQueryDocument,
          variables: { input: { workspaceId: createdSavedView.workspaceId } },
          data: {
            savedViewListForWorkspace: [
              ...cachedSavedViewForWorkspaceQuery.savedViewListForWorkspace,
              data.createSavedView,
            ],
          },
        });
      }

      // 2. Then update all the saved views for the specific URL
      // --------------------------------------------------------
      const cachedSavedViewByUrlQuery = cache.readQuery({
        query: getSavedViewsByUrlQueryDocument,
        variables: { input: { url: createdSavedView.url, workspaceId: createdSavedView.workspaceId } },
      });

      if (cachedSavedViewByUrlQuery) {
        const updatedIsDefaultValues = cachedSavedViewByUrlQuery.savedViews.map((savedView) => {
          if (!createdSavedView.isDefault) {
            return savedView;
          }
          if (savedView.id === createdSavedView.id) {
            return savedView;
          }
          return { ...savedView, isDefault: false };
        });

        cache.writeQuery({
          query: getSavedViewsByUrlQueryDocument,
          variables: { input: { url: createdSavedView.url, workspaceId: createdSavedView.workspaceId } },
          data: { savedViews: [...updatedIsDefaultValues, data.createSavedView] },
        });
      }
    },
  });

  function onUpdateCompleted(data: CreateSavedViewMutation) {
    if (onCompleted) {
      onCompleted(data.createSavedView);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createSavedViewMutation,
    data,
    loading,
    error,
  };
};
