import { FC } from "react";

import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";

import { ClimateActionChildTable } from "./climate-action-child-table";
import { ClimateActionDescription } from "./climate-action-description";
interface ClimateActionDetailTabProps {
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
}

export const ClimateActionDetailTab: FC<ClimateActionDetailTabProps> = (props) => {
  const { climateAction } = useGetClimateAction({ id: props.climateAction.id });

  if (!climateAction) {
    return <></>;
  }

  return (
    <main className="relative place-items-stretch overflow-auto">
      <ClimateActionDescription {...props} />
      <ClimateActionChildTable {...props} />
      <hr className="mx-3 py-3" />
    </main>
  );
};
