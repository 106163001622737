import { useMemo } from "react";

import { useGetClimateActionListForWorkspace } from "./useGetClimateActionListForWorkspace";

export interface UseGetClimateActionListAssignedToUserProps {
  user: {
    id: string;
  };
  workspace: {
    id: string;
  };
}

export const useGetClimateActionListAssignedToUser = ({
  user,
  workspace,
}: UseGetClimateActionListAssignedToUserProps) => {
  const {
    climateActionList: climateActionListForWorkspace,
    loading,
    error,
  } = useGetClimateActionListForWorkspace({
    workspaceId: workspace.id,
  });

  const climateActionList = useMemo(() => {
    return climateActionListForWorkspace
      .slice()
      .filter((o) => o.assignee && o.assignee.userId === user.id)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [user, climateActionListForWorkspace]);

  const topLevelClimateActionList = useMemo(() => {
    return climateActionList
      .slice()
      .filter((o) => o.parentId === null)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [climateActionList]);

  return {
    climateActionList,
    topLevelClimateActionList,
    loading,
    error,
  };
};
