import { FC } from "react";
import { Users } from "lucide-react";

export const FilterByTeamDisplayView: FC = () => {
  return (
    <div className="flex items-center gap-2" data-testid="filter-by-team-display-view">
      <Users className="h-4 w-4" />
      <span>Nach Team filtern</span>
    </div>
  );
};
