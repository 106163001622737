import { FC, useState } from "react";

import IconBuildingFilled from "@/components/_icons/IconBuildingFilled";
import { Button } from "@/components/_ui/button";
import { Dialog, DialogContent } from "@/components/_ui/dialog";

import { WorkspaceCreateForm } from "../workspace-create-form";

export const WorkspaceCreateDialog: FC = () => {
  const [dialogisOpen, setDialogisOpen] = useState(false);

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  return (
    <>
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
        onClick={() => setDialogisOpen(true)}
      >
        <IconBuildingFilled className="mr-2" />
        Workspace Erstellen
      </Button>
      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange}>
        <DialogContent className="top-[1%] max-h-[99vh] max-w-xl translate-y-[0%] overflow-y-scroll md:top-[10%] md:max-h-[80vh]">
          <WorkspaceCreateForm
            triggerCloseDialog={() => {
              setDialogisOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
