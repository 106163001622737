import { createPhaseInstanceMutationDocument } from "@/graphql/common/phase-instance";
import { type CreatePhaseInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PHASE_INSTANCE_CREATED, trackPhaseEvent } from "./tracking";

export type CreatedPhaseInstance = CreatePhaseInstanceMutation["createPhaseInstance"];

export interface UseCreatePhaseInstanceProps {
  onCompleted?: (data: CreatedPhaseInstance) => void;
  onError?: (e: Error) => void;
}

export const useCreatePhaseInstance = ({ onCompleted, onError }: UseCreatePhaseInstanceProps = {}) => {
  const [createPhaseInstance] = useMutation(createPhaseInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const phaseInstance = data?.createPhaseInstance;
      if (!phaseInstance) {
        return;
      }

      onGenericEntityInstanceCreate({
        cache,
        createdEntityInstance: {
          ...phaseInstance,
          __parentId: phaseInstance.phaseId,
        },
        typename: "PhaseInstance",
      });
      trackPhaseEvent(EVENT_PHASE_INSTANCE_CREATED, data.createPhaseInstance);
    },
  });

  function onUpdateCompleted(data: CreatePhaseInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.createPhaseInstance);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createPhaseInstance,
  };
};
