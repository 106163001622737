import { type UpdateSavedViewMutation } from "@/graphql/generated/graphql";
import { useMutation } from "@apollo/client";

import { getSavedViewsByUrlQueryDocument, updateSavedViewMutationDocument } from "./saved-view.gql";

export type UpdatedSavedView = UpdateSavedViewMutation["updateSavedView"];

interface UseUpdateSavedViewProps {
  onCompleted?: (data: UpdatedSavedView) => void;
  onError?: (e: Error) => void;
}

export const useUpdateSavedView = ({ onCompleted, onError }: UseUpdateSavedViewProps = {}) => {
  const [updateSavedViewMutation, { data, loading, error }] = useMutation(updateSavedViewMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const updatedSavedView = data?.updateSavedView;

      if (!updatedSavedView) {
        return;
      }

      // 1. Update all the saved views for the specific URL if the view is default
      // --------------------------------------------------------
      if (!updatedSavedView.isDefault) {
        return;
      }

      const cachedSavedViewByUrlQuery = cache.readQuery({
        query: getSavedViewsByUrlQueryDocument,
        variables: { input: { url: updatedSavedView.url, workspaceId: updatedSavedView.workspaceId } },
      });

      if (!cachedSavedViewByUrlQuery) {
        return;
      }

      const updatedIsDefaultValues = cachedSavedViewByUrlQuery.savedViews.map((savedView) => {
        if (!updatedSavedView.isDefault) {
          return savedView;
        }
        if (savedView.id === updatedSavedView.id) {
          return savedView;
        }
        return { ...savedView, isDefault: false };
      });

      cache.writeQuery({
        query: getSavedViewsByUrlQueryDocument,
        variables: { input: { url: updatedSavedView.url, workspaceId: updatedSavedView.workspaceId } },
        data: { savedViews: updatedIsDefaultValues },
      });
    },
  });

  function onUpdateCompleted(data: UpdateSavedViewMutation) {
    if (onCompleted) {
      onCompleted(data.updateSavedView);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updateSavedViewMutation,
    data,
    loading,
    error,
  };
};
