import React from "react";

import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { FeatureFlagIDs, useFeatureFlag } from "@/hooks/api/common/useFeatureFlag";

interface FeatureFlagToggleProps {
  flag: FeatureFlagIDs;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export const FeatureFlagToggle: React.FC<FeatureFlagToggleProps> = ({ flag, children, fallback = null }) => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { isEnabled, loading } = useFeatureFlag({
    workspaceId: currentWorkspace?.id || "",
    id: flag,
  });

  if (loading) {
    return <></>;
  }

  if (!isEnabled) {
    return <>{fallback}</>; // default is null
  }

  return <>{children}</>;
};
