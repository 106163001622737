import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { getIndicatorQueryDocument } from "./indicator.gql";

export interface UseGetIndicatorProps {
  id?: string;
  enabled?: boolean;
}

export const useGetIndicator = ({ id, enabled = true }: UseGetIndicatorProps) => {
  const queryResult = useQuery(getIndicatorQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id || !enabled,
  });

  const indicator = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.indicator;
  }, [queryResult.data]);

  return {
    indicator,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
