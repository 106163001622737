import { useMemo } from "react";

import { getFocusAreaInstanceQueryDocument } from "@/graphql/common/focus-area-instance";
import { useQuery } from "@apollo/client";

export interface UseGetFocusAreaInstanceProps {
  id?: string;
  enabled?: boolean;
}

export const useGetFocusAreaInstance = ({ id, enabled = true }: UseGetFocusAreaInstanceProps) => {
  const queryResult = useQuery(getFocusAreaInstanceQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id || !enabled,
  });

  const focusAreaInstance = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.focusAreaInstance;
  }, [queryResult.data]);

  return {
    queryResult,
    focusAreaInstance,
  };
};
