import { FC } from "react";

import { FocusAreaViewEmpty } from "./FocusAreaViewEmpty";
import { FocusAreaView } from ".";

interface FocusAreaViewOptionalProps {
  focusArea?: {
    id: string;
  } | null;
  logoOnly?: boolean;
  className?: string;
}

export const FocusAreaViewOptional: FC<FocusAreaViewOptionalProps> = ({ focusArea, ...props }) => {
  if (!focusArea) {
    return <FocusAreaViewEmpty {...props} />;
  }

  return <FocusAreaView focusArea={focusArea} {...props} />;
};
