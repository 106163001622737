import { ClimateAction } from "@/components/_domain/climate-action/models";
import { ColumnDef } from "@tanstack/react-table";

import { filterByIdWithUnknownPayload } from "../../filter-functions";
import { ClimateActionListCellLabelGroupCell, ClimateActionListColumnHeader } from "../../index";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import {
  FilterByLabelGroupConfigurationView,
  FilterByLabelGroupDisplayView,
  FilterByLabelGroupFilterPaneView,
} from "./components";

interface LabelGroupColumnListDefinitionProps {
  workspaceId: string;
  startOrderValue: number;
  labelGroupList: { id: string; title: string }[];
}

export const LabelGroupColumnListDefinition = ({
  workspaceId,
  startOrderValue,
  labelGroupList,
}: LabelGroupColumnListDefinitionProps): ClimateActionColumnConfiguration[] => {
  const columns: ClimateActionColumnConfiguration[] = [];

  let i = 0;
  for (const labelGroup of labelGroupList) {
    i = i + 1;
    const orderValue = startOrderValue + i;
    const COLUMN_ID = `label_group_${labelGroup.id}`;

    const labelGroupColumn: ClimateActionColumnConfiguration = {
      columnId: COLUMN_ID,
      getDefaultVisibilityState: () => false,
      getDisplayValue: () => labelGroup.title,
      getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
      getColumnDef: (): ColumnDef<ClimateAction> => {
        return {
          id: COLUMN_ID,
          accessorKey: labelGroup.id, // key needed to enable sorting / filtering
          enableHiding: true,
          enableSorting: true,
          enableColumnFilter: true,
          sortingFn: (rowA, rowB) => {
            const labelsA = rowA.original.labelInstanceList
              .slice()
              .filter((li) => li.labelGroupId && li.labelGroupId === labelGroup.id)
              .flatMap((li) => {
                if (!li.label) {
                  return [];
                }
                return li.label.title;
              })
              .sort((a, b) => {
                return a.localeCompare(b);
              });
            const labelsB = rowB.original.labelInstanceList
              .slice()
              .filter((li) => li.labelGroupId && li.labelGroupId === labelGroup.id)
              .flatMap((li) => {
                if (!li.label) {
                  return [];
                }
                return li.label.title;
              })
              .sort((a, b) => {
                return a.localeCompare(b);
              });

            if (labelsA.length === 0) {
              return 1;
            }
            if (labelsB.length === 0) {
              return 1;
            }

            if (labelsA.length > 0 && labelsB.length === 0) {
              return 1;
            }
            if (labelsB.length > 0 && labelsA.length === 0) {
              return -1;
            }

            return labelsA[0].localeCompare(labelsB[0]);
          },
          filterFn: (row, _columnId, value, _addMeta) => {
            for (const labelInstance of row.original.labelInstanceList) {
              if (filterByIdWithUnknownPayload(value, labelInstance.labelId)) {
                return true;
              }
            }
            return false;
          },
          size: 1, // set to 1 instead to 0 for it to work in Safari
          header: ({ column }) => (
            <ClimateActionListColumnHeader column={column} title={""} className="px-1 text-xs font-normal" />
          ),
          cell: ({ row, column }) => <ClimateActionListCellLabelGroupCell row={row} column={column} />,
        };
      },
      getDisplayView: () => <FilterByLabelGroupDisplayView labelGroup={labelGroup} />,
      getConfigurationView: (onFinished: () => void) => (
        <FilterByLabelGroupConfigurationView
          workspace={{ id: workspaceId }}
          labelGroup={{ id: labelGroup.id }}
          column={{ id: COLUMN_ID }}
          onFinished={onFinished}
        />
      ),
      getConfigurationViewType: () => "popover",
      getFilterPaneView: () => (
        <FilterByLabelGroupFilterPaneView labelGroup={{ id: labelGroup.id }} column={{ id: COLUMN_ID }} />
      ),
    };

    columns.push(labelGroupColumn);
  }
  return columns;
};
