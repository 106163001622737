import { FC, useEffect, useState } from "react";

import { Cross2Icon } from "@radix-ui/react-icons";

import { FileItemUploader } from "./file-item-uploader";

export interface UploadResult {
  file: File;
  url: string;
  error?: Error | undefined;
}

export interface FileUploadAction {
  newFile: File;
  oldFileId?: string | undefined;
  conflictHandling: "REPLACE" | "CREATE";
}

export interface FileUploaderProps {
  owner: {
    id: string;
    ownerType: "CLIMATE_ACTION";
  };
  fileUploadList: FileUploadAction[];
  onFileUploadFinished?: (result: UploadResult) => void;
  onAllUploadsFinished?: (resultList: UploadResult[]) => void;
}

export const FileUploader: FC<FileUploaderProps> = ({ fileUploadList, ...props }) => {
  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    setHidden(false);
  }, [fileUploadList]);

  async function handleFileUploadFinished(result: UploadResult) {
    if (props.onFileUploadFinished) {
      props.onFileUploadFinished(result);
    }
  }

  if (!fileUploadList || fileUploadList.length === 0) {
    return <></>;
  }

  return (
    <div className="fixed -bottom-10 right-0 z-[99] p-4" style={{ bottom: hidden ? -999 : undefined }}>
      <div className="rounded border bg-background p-4 pb-10 shadow-xl">
        <div className="flex flex-row items-center justify-between pb-4">
          <div className="text-sm font-bold">Dokumente Hochladen</div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setHidden(true);
            }}
          >
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </div>
        </div>

        {fileUploadList.map((fileUploadAction) => {
          return (
            <FileItemUploader
              key={fileUploadAction.newFile.name}
              owner={props.owner}
              fileUploadAction={fileUploadAction}
              onFileUploadFinished={handleFileUploadFinished}
            />
          );
        })}
      </div>
    </div>
  );
};
