import { FC, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import IconUpload from "@/components/_icons/IconUpload";
import { Button } from "@/components/_ui/button";
import { Checkbox } from "@/components/_ui/checkbox";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";
import { Input } from "@/components/_ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/_ui/select";
import { Textarea } from "@/components/_ui/textarea";
import { toast } from "@/components/_ui/use-toast";
import { InputImageUpload, UploadUrl } from "@/components/input-image-upload";
import { createSignedUploadUrlMutationDocument } from "@/graphql/common/upload";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useWorkspaceCreate } from "@/hooks/api/superuser";
import { cn, textToEMailArray } from "@/lib/utils";
import { apolloClient } from "@/services/apollo.service/apollo.service";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Der Name muss mindestens 1 Zeichen lang sein.",
  }),
  workspaceUrl: z
    .string()
    .min(6, {
      message: "Der Workspace ID muss mindestens 6 Zeichen lang sein.",
    })
    .max(24, {
      message: "Der Workspace ID kann höchstens 24 Zeichen lang sein.",
    })
    .refine(
      (value) => /^[a-z0-9-]+$/.test(value),
      "Die Workspace ID darf nur aus kleinen Buchstabe (a-z) und dem Zeichen '-' bestehen.",
    ),
  template: z.string(),
  joinWorkspace: z.boolean().default(false).optional(),
  emailList: z
    .string()
    .default("")
    .superRefine((value, ctx) => {
      if (!value) {
        return; // nothing entered
      }

      const potentialEMails = textToEMailArray(value);
      if (potentialEMails.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          type: "array",
          inclusive: true,
          message: "Bitte geben Sie mindestens eine E-Mail Adresse ein.",
        });
      }

      const eMailSchema = z.string().email();
      const faultyEMails = [];
      for (const potentialEMail of potentialEMails) {
        const { success } = eMailSchema.safeParse(potentialEMail);
        if (!success) {
          faultyEMails.push(potentialEMail);
        }
      }

      if (faultyEMails.length === 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${faultyEMails[0]} ist keine gültige E-Mail Adresse.`,
        });
      }

      if (faultyEMails.length > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${faultyEMails.join(",")} sind keine gültigen E-Mail Adressen.`,
        });
      }
    }),
});

export interface WorkspaceCreateFormProps {
  triggerCloseDialog: () => void;
}

export const WorkspaceCreateForm: FC<WorkspaceCreateFormProps> = ({ triggerCloseDialog }) => {
  const { currentUser } = useCurrentUserContext();

  const [logo, setLogo] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      workspaceUrl: "",
      template: "EMPTY",
      joinWorkspace: false,
    },
  });
  const { createWorkspace, queryResult } = useWorkspaceCreate({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  const loading = useMemo(() => {
    return queryResult.loading;
  }, [queryResult]);

  function onUpdateCompleted(_workspace: { id: string } | undefined) {
    toast({
      title: "Workspace erstellt",
      description: "Der Workspace wurde erstellt.",
      variant: "success",
    });

    form.reset();
    triggerCloseDialog();
  }

  function onUpdateError(error: Error) {
    if (error.message === "WorkspaceUrl not available") {
      form.setError("workspaceUrl", { message: "Diese Workspace ID ist bereits reserviert oder bereits vergeben." });
      return;
    }

    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    let emailString = values.emailList ?? "";

    if (currentUser && values.joinWorkspace) {
      emailString = emailString.concat(`,${currentUser.email}`);
    }

    const emailList = textToEMailArray(emailString);

    await createWorkspace({
      variables: {
        input: {
          name: values.name,
          workspaceUrl: values.workspaceUrl,
          logo: logo ?? undefined,
          template: values.template ?? "EMPTY",
          userEmailList: emailList,
        },
      },
    });
  }

  async function getSignedUploadUrlForImage(): Promise<UploadUrl | null> {
    const { data } = await apolloClient.mutate({
      mutation: createSignedUploadUrlMutationDocument,
      fetchPolicy: "no-cache",
      variables: {
        input: {
          fileExtension: "png",
          type: "image/png",
        },
      },
    });

    if (!data) {
      return null;
    }

    return data.createSignedUploadUrl;
  }

  function handleOnImageUploadSuccess(file: UploadUrl) {
    const { permaUrl } = file;
    setLogo(permaUrl);
  }

  function handleOnImageError(_e: Error) {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={cn("space-y-6")}>
        <div className="grid w-full">
          {/* Header */}
          <div className="flex flex-row items-center gap-1">
            <div className="text-normal overflow-hidden truncate text-ellipsis whitespace-nowrap">Neuer Workspace</div>
          </div>
          {/* Main Part */}
          <div className="mx-auto mb-5 mt-3 flex w-full flex-col">
            <InputImageUpload
              getSignedUploadUrl={getSignedUploadUrlForImage}
              onSuccess={handleOnImageUploadSuccess}
              onError={handleOnImageError}
              className="rounded-xl"
            >
              <div className="flex h-[118px] w-[118px] flex-row items-center justify-center">
                {!logo && (
                  <div className="select-none">
                    <IconUpload className="h-6 w-6" />
                  </div>
                )}
                {logo && <img src={logo} alt="Workspace Logo" className="h-full w-full select-none" />}
              </div>
            </InputImageUpload>
          </div>

          <div className="mx-auto mb-5 mt-3 flex w-full flex-col gap-3">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Workspace Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      autoComplete="off"
                      className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                    />
                  </FormControl>
                  <FormMessage className="text-xs font-normal" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="workspaceUrl"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Workspace ID</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      autoComplete="off"
                      className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                    />
                  </FormControl>
                  <FormMessage className="text-xs font-normal" />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="template"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Vorlage</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={"EMPTY"}>Keine Vorlage</SelectItem>
                      <SelectItem value={"MINIMAL"}>Minimum – Vorlage mit minimalen Voreinstellungen</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage className="text-xs font-normal" />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="emailList"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>E-Mails (Workspace Administratoren)</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="email@beispiel.de, email2@beispiel.de, ..."
                      className={
                        loading
                          ? "disabled text-muted-forground pointer-events-none w-full cursor-wait resize-none"
                          : "w-full resize-none"
                      }
                      disabled={loading}
                      {...field}
                      autoComplete="off"
                      data-1p-ignore
                    />
                  </FormControl>
                  <FormDescription>
                    Sie können mehrere E-Mail Adressen eingeben. Bitten trennen Sie E-Mail Adressen mit einem Komma.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="joinWorkspace"
              render={({ field }) => (
                <FormItem className="shadow my-3 flex flex-row items-center gap-2">
                  <FormControl>
                    <Checkbox className="mt-2" checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel className="-mt-2">Selbst dem Workspace beitreten</FormLabel>
                </FormItem>
              )}
            />
          </div>
          {/* <div className="border-b"></div> */}
          <div className="flex flex-row items-center justify-end gap-4 pt-2">
            <Button type="submit" size="sm" disabled={loading}>
              Workspace erstellen
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};
