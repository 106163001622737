import { useMemo } from "react";

import { superuser_teamListForWorkspaceQueryDocument } from "@/graphql/superuser/queries/team.queries";
import { useQuery } from "@apollo/client";

export interface useTeamListForWorkspaceQueryProps {
  workspaceId: string;
}

export const useTeamListForWorkspaceQuery = (props: useTeamListForWorkspaceQueryProps) => {
  const queryResult = useQuery(superuser_teamListForWorkspaceQueryDocument, {
    variables: { input: props.workspaceId },
  });

  const teamList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.superuser_teamListForWorkspace;
  }, [queryResult.data]);

  return {
    queryResult,
    teamList,
  };
};
