import { useMemo } from "react";

import { PriorityList } from "@/components/_domain/priority/priority-type";
import { getPriorityListForWorkspaceQueryDocument } from "@/graphql/common/priority";
import { useQuery } from "@apollo/client";

export interface UseGetPriorityListProps {
  workspaceId?: string | undefined;
  enabled?: boolean;
}

export const useGetPriorityListForWorkspace = ({ workspaceId, enabled = true }: UseGetPriorityListProps) => {
  const queryResult = useQuery(getPriorityListForWorkspaceQueryDocument, {
    variables: { input: { workspaceId: workspaceId ?? "" } },
    skip: !workspaceId || !enabled,
  });

  const priorityList = useMemo(() => {
    const maybePriorityList = queryResult.data?.priorityListForWorkspace;
    if (!maybePriorityList) {
      return [];
    }

    return maybePriorityList.map((s) => {
      let order = 0;

      if (s.type === PriorityList.low) {
        order = 100;
      }
      if (s.type === PriorityList.medium) {
        order = 200;
      }
      if (s.type === PriorityList.high) {
        order = 300;
      }
      if (s.type === PriorityList.critical) {
        order = 400;
      }
      return {
        ...s,
        order,
      };
    });
  }, [queryResult.data]);

  return {
    priorityList,
    loading: queryResult.loading,
    error: queryResult.error,
  };
};
