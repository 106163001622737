import { FC } from "react";

import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";

import ClimateActionEditForm from "../../../climate-action-edit-form";

interface ClimateActionDescriptionProps {
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
}

export const ClimateActionDescription: FC<ClimateActionDescriptionProps> = (props) => {
  const { climateAction } = useGetClimateAction({ id: props.climateAction.id });
  if (!climateAction) {
    return <></>;
  }

  // Create a properly formatted climate action object for the form
  const formattedClimateAction = {
    id: climateAction.id,
    title: climateAction.title,
    properties: climateAction.properties,
    parentId: climateAction.parentId,
    // Provide an empty array as default if teamInstanceList doesn't exist
    teamInstanceList: climateAction.teamInstanceList as { id: string; teamId: string }[],
  };

  return <ClimateActionEditForm key={climateAction.id} climateActionFormData={formattedClimateAction} />;
};
