import { DocumentNode } from "graphql";

import { getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { ApolloCache } from "@apollo/client";

import { EntityInstanceTypename, EntityOwnerTypename, TEntityInstance } from "./_shared-generic-types";

interface OwnerQueryConfig {
  document: DocumentNode;
  key: string;
  key2: string | null;
  type: "single" | "list";
}

/**
 * Gets the query configuration for a specific owner type.
 * This can be extended to support different owner types as needed.
 *
 * @param ownerType - The type of the owner (e.g., "CLIMATE_ACTION")
 * @param entityInstanceTypename - The type of the entity instance (e.g., "PriorityInstance")
 * @returns Query configuration for the owner type or null if not supported
 */
export const getOwnerQueryConfig = ({
  ownerType,
  entityInstanceTypename,
}: {
  ownerType: EntityOwnerTypename;
  entityInstanceTypename: EntityInstanceTypename;
}): OwnerQueryConfig | null => {
  const ownerConfigMap: Record<EntityOwnerTypename, Record<EntityInstanceTypename, OwnerQueryConfig | null>> = {
    CLIMATE_ACTION: {
      PriorityInstance: {
        document: getClimateActionQueryDocument,
        key: "climateAction", // the key under which to access the owner data (e.g. data.climateAction)
        key2: "priorityInstance", // the key under which to access the instance data (e.g. data.climateAction.priorityInstance)
        type: "single",
      },
      PhaseInstance: {
        document: getClimateActionQueryDocument,
        key: "climateAction", // the key under which to access the owner data (e.g. data.climateAction)
        key2: "phaseInstance", // the key under which to access the instance data (e.g. data.climateAction.phaseInstance)
        type: "single",
      },
      FocusAreaInstance: {
        document: getClimateActionQueryDocument,
        key: "climateAction", // the key under which to access the owner data (e.g. data.climateAction)
        key2: "focusAreaInstance", // the key under which to access the instance data (e.g. data.climateAction.focusAreaInstance)
        type: "single",
      },
      LabelInstance: {
        document: getClimateActionQueryDocument,
        key: "climateAction", // the key under which to access the owner data (e.g. data.climateAction)
        key2: "labelInstanceList", // the key under which to access the instance data (e.g. data.climateAction.labelInstance)
        type: "list",
      },
      // Add more instance types for CLIMATE_ACTION here
    },
    // Add more owner types here
  };

  return ownerConfigMap[ownerType]?.[entityInstanceTypename] ?? null;
};

export type HandleAddInstanceToOwnerParams = {
  cache: ApolloCache<Record<string, unknown>>;
  instance: TEntityInstance;
  ownerId: string;
  ownerType: EntityOwnerTypename;
  entityInstanceTypename: EntityInstanceTypename;
};

/**
 * Generic function to update an owner's cache entry when an instance is created or updated.
 * Works with any type of instance (priority, label, etc.) that has an owner relationship.
 *
 * @param cache - The Apollo cache instance
 * @param instance - The instance data to set
 * @param ownerId - The ID of the owner
 * @param ownerType - The type of the owner (e.g., "CLIMATE_ACTION")
 * @param entityInstanceTypename - The type of the entity instance (e.g., "PriorityInstance")
 */
export const handleAddInstanceToOwner = ({
  cache,
  instance,
  ownerId,
  ownerType,
  entityInstanceTypename,
}: HandleAddInstanceToOwnerParams) => {
  const ownerQueryVariables = { input: { id: ownerId } };
  const ownerQueryConfig = getOwnerQueryConfig({ ownerType, entityInstanceTypename });
  if (!ownerQueryConfig || !ownerQueryConfig.key || !ownerQueryConfig.key2) {
    return;
  }

  // Read the current owner data from the cache
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cachedOwnerResponse: any = cache.readQuery({
    query: ownerQueryConfig.document,
    variables: ownerQueryVariables,
  });

  if (!cachedOwnerResponse?.[ownerQueryConfig.key]) {
    return;
  }

  let updatedValue: TEntityInstance[] | TEntityInstance = instance;
  if (
    ownerQueryConfig.type === "list" &&
    Array.isArray(cachedOwnerResponse[ownerQueryConfig.key][ownerQueryConfig.key2])
  ) {
    updatedValue = [...cachedOwnerResponse[ownerQueryConfig.key][ownerQueryConfig.key2], instance];
  }

  // Prepare the cache write configuration
  const writeQueryConfiguration = {
    query: ownerQueryConfig.document,
    variables: ownerQueryVariables,
    data: {
      [ownerQueryConfig.key]: {
        ...cachedOwnerResponse[ownerQueryConfig.key],
        [ownerQueryConfig.key2]: updatedValue,
      },
    },
  };

  // Write the updated data back to the cache
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache.writeQuery(writeQueryConfiguration as any);
};

export type HandleDeleteInstanceFromOwnerParams = {
  cache: ApolloCache<Record<string, unknown>>;
  instance: TEntityInstance;
  ownerId: string;
  ownerType: EntityOwnerTypename;
  entityInstanceTypename: EntityInstanceTypename;
};

/**
 * Generic function to clear an instance from an owner's cache entry when the instance is deleted.
 * Works with any type of instance (priority, label, etc.) that has an owner relationship.
 *
 * @param cache - The Apollo cache instance
 * @param ownerId - The ID of the owner
 * @param ownerType - The type of the owner (e.g., "CLIMATE_ACTION")
 * @param entityInstanceTypename - The type of the entity instance (e.g., "PriorityInstance")
 */
export const handleDeleteInstanceFromOwner = ({
  cache,
  instance,
  ownerId,
  ownerType,
  entityInstanceTypename,
}: HandleDeleteInstanceFromOwnerParams) => {
  const ownerQueryVariables = { input: { id: ownerId } };
  const ownerQueryConfig = getOwnerQueryConfig({ ownerType, entityInstanceTypename });
  if (!ownerQueryConfig || !ownerQueryConfig.key || !ownerQueryConfig.key2) {
    return;
  }

  // Read the current owner data from the cache
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cachedOwnerResponse: any = cache.readQuery({
    query: ownerQueryConfig.document,
    variables: ownerQueryVariables,
  });

  if (!cachedOwnerResponse?.[ownerQueryConfig.key]) {
    return;
  }

  // Make sure to work with both lists and single instances
  let updatedValue: TEntityInstance[] | null = null;
  if (
    ownerQueryConfig.type === "list" &&
    Array.isArray(cachedOwnerResponse[ownerQueryConfig.key][ownerQueryConfig.key2])
  ) {
    updatedValue = cachedOwnerResponse[ownerQueryConfig.key][ownerQueryConfig.key2]
      .slice()
      .filter((item: TEntityInstance) => item.id !== instance.id);
  }

  // Prepare the cache write configuration
  // We spread the existing owner data but set priorityInstance to null
  const writeQueryConfiguration = {
    query: ownerQueryConfig.document,
    variables: ownerQueryVariables,
    data: {
      [ownerQueryConfig.key]: {
        ...cachedOwnerResponse?.[ownerQueryConfig.key],
        [ownerQueryConfig.key2]: updatedValue, // Clear the priority instance reference
      },
    },
  };

  // Write the updated data back to the cache
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache.writeQuery(writeQueryConfiguration as any);
};
