import React from "react";
import ReactDOM from "react-dom/client";

import { AuthProvider } from "@/hooks/useAuth";
import Router from "@/routes/Router.tsx";
import { ApolloProvider } from "@apollo/client";

import { ThemeProvider } from "./hooks/useTheme";
import { apolloClient } from "./services/apollo.service/apollo.service";
import { posthogService } from "./services/posthog.service";

import "tailwindcss/tailwind.css";
import "inter-ui/inter.css";
import "./index.css";

import "@/lib/sentry";

posthogService.capture("APP_LOADED");

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <ApolloProvider client={apolloClient}>
          <Router />
        </ApolloProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
