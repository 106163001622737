import { FC } from "react";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import IconChevronDown from "@/components/_icons/IconChevronDown";
import IconChevronUp from "@/components/_icons/IconChevronUp";
import { Button } from "@/components/_ui/button";
import { Link } from "@/components/link";
import { ACTION_TYPES } from "@/hooks/api/common/usePermission/types";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace";
import { cn } from "@/lib/utils";
import { Row } from "@tanstack/react-table";

interface ClimateActionListCellTitleProps {
  row: Row<ClimateAction>;
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
  className?: string;
}

export const ClimateActionListCellTitle: FC<ClimateActionListCellTitleProps> = ({ row, className, ...props }) => {
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const climateAction = row.original;

  if (!workspace) {
    return <></>;
  }

  if (!climateAction) {
    return <></>;
  }

  return (
    <div
      className={cn([
        "relative flex h-full flex-row items-center justify-start gap-1",
        row.depth > 0 ? "font-normal" : "",
        className,
      ])}
      style={{ paddingLeft: `${row.depth * 1}rem`, minWidth: "20rem" }}
    >
      {/* {row.depth > 0 && (
        <div
          className={`absolute`}
          style={{ left: `${(row.depth - 1) * 1 + 0.15}rem`, top: "-1rem", bottom: "-0.5rem" }}
        >
          <div className="h-full border-l"></div>
        </div>
      )} */}
      <Link
        to={`/${workspace.workspaceUrl}/climate-actions/${climateAction.id}`}
        className={cn(["font-medium", className])}
        prefetchConfig={{
          prefetch: {
            permissionList: ACTION_TYPES.map((action) => {
              return {
                resource: "climateAction",
                action: action,
                resourceId: climateAction.id,
              };
            }),
          },
        }}
      >
        {climateAction.title !== "" ? climateAction.title : <span className="text-muted-foreground">Kein Titel</span>}
      </Link>
      {climateAction.children && climateAction.children.length > 0 && (
        <div className="ml-1.5">
          <span className="whitespace-nowrap text-xs font-light text-muted-foreground/60">
            {climateAction.children.length === 1 && <> {`·  ${climateAction.children.length} Teilmaßnahme  `}</>}
            {climateAction.children.length > 1 && <>{`·  ${climateAction.children.length} Teilmaßnahmen  `}</>}
          </span>
          {row.getCanExpand() && (
            <Button
              variant="ghost"
              size="icon"
              className="overflow-x-hidden font-light text-muted-foreground/60 ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0  focus-visible:ring-offset-0"
              {...{
                onClick: () => {
                  row.toggleExpanded();
                },
                style: { cursor: "pointer" },
              }}
            >
              {row.getIsExpanded() && <IconChevronUp className="h-2 w-2" />}
              {!row.getIsExpanded() && <IconChevronDown className="h-2 w-2" />}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
