import { ColumnDef } from "@tanstack/react-table";

import { ClimateActionFocusArea, ClimateActionPhase, ClimateActionStatus } from "./rows";
import { ClimateActionRow } from "./types";

export const columns: ColumnDef<ClimateActionRow>[] = [
  {
    id: "focusAreaName",
    accessorFn: (row) => row.focusArea.name,
    header: "Handlungsfeld",
    cell: ({ row }) => <ClimateActionFocusArea row={row} />,

    size: 1000,
  },
  {
    id: "phase",
    accessorFn: (row) => row.phase,
    header: "Phase",
    cell: ({ row }) => <ClimateActionPhase row={row} />,
    size: 200,
  },
  {
    id: "status",
    accessorFn: (row) => row.status,
    header: "Status",
    cell: ({ row }) => <ClimateActionStatus row={row} />,
    size: 200,
  },
];
