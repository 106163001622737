import { useState } from "react";

import { CurrentUser } from "@/components/_domain/user/models/current-user-model";
import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common/useWorkspace";
import { cn } from "@/lib/utils";

import { SidebarSettingsNoTeamCard } from "./sidebar-settings-no-team-card";
import { SidebarSettingsTeamNavElement } from "./sidebar-settings-team-nav-element";

export interface SidebarSettingsAllTeamsNavElementProps {
  workspace: Workspace;
  user: CurrentUser;
}

export const SidebarSettingsAllTeamsNavElement = ({
  workspace,
  user,
  ...props
}: SidebarSettingsAllTeamsNavElementProps) => {
  const [expanded, setExpanded] = useState(true);
  const { activeTeamList } = useTeamListForUserInWorkspace({
    workspaceId: workspace.id,
    userId: user.id,
  });

  return (
    <>
      <div className="relative mb-0.5 cursor-pointer rounded">
        <div
          {...props}
          className={cn(
            "my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm hover:bg-foreground/[3%] hover:text-accent-foreground",
          )}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <span className="pointer-events-none relative flex h-6 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0 text-xs text-muted-foreground">
            Meine Teams
          </span>
          {!expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 text-muted-foreground" />}
          {expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 rotate-90 text-muted-foreground" />}
        </div>
      </div>
      {expanded && (
        <>
          {activeTeamList.map((team, index) => {
            const shouldExpandByDefault = index === 0;
            return (
              <SidebarSettingsTeamNavElement
                key={team.id}
                workspace={workspace}
                team={team}
                expanded={shouldExpandByDefault}
              />
            );
          })}
          {activeTeamList.length === 0 && <SidebarSettingsNoTeamCard />}
        </>
      )}
    </>
  );
};
