import { FC, useMemo } from "react";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import { statusTypefromString, StatusView } from "@/components/_domain/progress-snapshot";
import { ProgressSnapshot } from "@/components/_domain/progress-snapshot/progress-snapshots.schema";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";
import { cn } from "@/lib/utils";
import { Row } from "@tanstack/react-table";

interface ClimateActionListStatusCellProps {
  row: Row<ClimateAction>;
  className?: string;
}

export const ClimateActionListStatusCell: FC<ClimateActionListStatusCellProps> = ({ row, className }) => {
  const progressSnapshotList: ProgressSnapshot[] = useMemo(() => {
    return row.original.progressSnapshotList.slice().sort((a, b) => {
      const dateA = new Date(a.snapshotDate);
      const dateB = new Date(b.snapshotDate);
      return dateB.getTime() - dateA.getTime();
    });
  }, [row.original.progressSnapshotList]);

  const mostRecentSnapshot: ProgressSnapshot | null = useMemo(() => {
    if (progressSnapshotList.length === 0) {
      return null;
    }
    return progressSnapshotList[0];
  }, [progressSnapshotList]);

  const status = useMemo(() => {
    if (!mostRecentSnapshot) {
      return null;
    }
    return statusTypefromString(mostRecentSnapshot.status);
  }, [mostRecentSnapshot]);

  if (!mostRecentSnapshot) {
    return <></>;
  }

  if (!status) {
    return <></>;
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className={cn(["m-auto w-min"], className)}>
          <StatusView type={status} logoOnly={true} className="h-4 w-4 justify-self-center" />
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="top" className="w-auto p-2">
        <StatusView type={status} logoOnly={false} className="" />
        <div className="pt-1 text-xs text-muted-foreground">
          {`Zuletzt aktualisiert ${formatDistanceToNow(new Date(mostRecentSnapshot.snapshotDate), {
            locale: de,
            addSuffix: true,
          })}`}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
