import { FC, useEffect, useRef, useState } from "react";

import { FocusAreaIcon } from "@/components/_domain/focusArea";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { ColorPicker } from "@/components/color-picker/color-picker";
import { useUpdateFocusArea } from "@/hooks/api/common/useFocusArea/useUpdateFocusArea";

export interface FocusAreaFormEditProps {
  focusArea: {
    id: string;
    name: string;
    color: string;
    type: string;
  };
  className?: string;
  onSuccess?: () => void;
  onCanceled?: () => void;
}

export const FocusAreaFormEdit: FC<FocusAreaFormEditProps> = ({ focusArea, onSuccess, onCanceled }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [focusAreaName, setFocusAreaName] = useState(focusArea.name);
  const [focusAreaColor, setFocusAreaColor] = useState(focusArea.color);

  const { updateFocusArea } = useUpdateFocusArea({
    focusAreaId: focusArea.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleUpdateFocusArea() {
    if (focusAreaName === "" || focusAreaColor === "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Name und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await updateFocusArea({
      variables: {
        input: {
          id: focusArea.id,
          name: focusAreaName,
          color: focusAreaColor,
          type: focusArea.type,
        },
      },
    });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler bei der Aktualisierung des Handlungsfeldes aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className="flex w-full flex-row items-center justify-between gap-2 p-1">
      <div className="w-9">
        <ColorPicker
          asChild
          value={focusAreaColor}
          onChange={(color) => {
            setFocusAreaColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <FocusAreaIcon className="h-4 w-4" focusArea={{ type: focusArea.type, color: focusAreaColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={focusAreaName}
          onChange={(e) => setFocusAreaName(e.currentTarget.value)}
          placeholder="Name des Handlungsfeldes"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={focusAreaName === ""}
          onClick={handleUpdateFocusArea}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default FocusAreaFormEdit;
