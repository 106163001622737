import { FC, useEffect, useRef, useState } from "react";

import IconCheckCircleFilled from "@/components/_icons/IconCheckCircleFilled";
import IconXCircleFilled from "@/components/_icons/IconXCircleFilled";
import { UploadUrl } from "@/components/input-image-upload";
import LoadingDots from "@/components/loading-dots";
import { createSignedUploadUrlMutationDocument } from "@/graphql/common/upload";
import { apolloClient } from "@/services/apollo.service";

interface UploadResult {
  file: File;
  url: string;
  error?: Error | undefined;
}

export interface FileStorageUploaderProps {
  className?: string;
  file: File;
  onFileUploadFinished?: (result: UploadResult) => void;
}

export const FileStorageUploader: FC<FileStorageUploaderProps> = ({ file, onFileUploadFinished }) => {
  const effectTriggeredRef = useRef(false); // make sure it only runs once

  const [loading, setLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!effectTriggeredRef.current) {
      effectTriggeredRef.current = true;
      startFileUpload(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getSignedUploadUrl(): Promise<UploadUrl | null> {
    const fileExtension = file.name.split(".").pop() ?? "";

    const { data } = await apolloClient.mutate({
      mutation: createSignedUploadUrlMutationDocument,
      fetchPolicy: "no-cache",
      variables: {
        input: {
          fileExtension: fileExtension,
          type: file.type,
        },
      },
    });

    if (!data) {
      return null;
    }

    return data.createSignedUploadUrl;
  }

  async function uploadFile(file: File, url: string) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url, true);
      xhr.onload = () => {
        const status = xhr.status;
        if (status === 200) {
          resolve(true);
        } else {
          reject(new Error("Upload failed."));
        }
      };
      xhr.onerror = () => {
        reject(new Error("Upload failed."));
      };
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.setRequestHeader("Cache-Control", "public, max-age=604800, immutable");
      xhr.send(file);
    });
  }

  async function startFileUpload(file: File) {
    if (!file) {
      return;
    }
    if (loading) {
      return;
    }
    if (completed) {
      return;
    }

    setLoading(true);

    const uploadUrl = await getSignedUploadUrl();

    if (!uploadUrl) {
      const error = new Error("Could not fetch a valid upload URL from server");
      setError(error);
      setLoading(false);
      setCompleted(true);

      if (onFileUploadFinished) {
        onFileUploadFinished({
          file: file,
          url: "",
          error: error,
        });
      }

      return;
    }

    const success = await uploadFile(file, uploadUrl.signedUrl);
    const error = success ? null : new Error("Could not upload file to storage");

    setError(error);
    setCompleted(true);
    setLoading(false);

    if (onFileUploadFinished) {
      onFileUploadFinished({
        file: file,
        url: uploadUrl.permaUrl, // this is the unsigned URL
        error: error ?? undefined,
      });
    }
  }

  return (
    <div className="w-max-64 flex w-64 flex-row items-start justify-between gap-4 py-2 pr-2 ">
      <div className="w-max-[12rem] shrink overflow-hidden text-ellipsis break-words text-xs">{file.name}</div>
      <div className="">
        {!completed && <LoadingDots className="self-start" />}
        {completed && error && <IconXCircleFilled className="toast-error-icon text-rose-500" />}
        {completed && !error && <IconCheckCircleFilled className="toast-success-icon text-green-500" />}
      </div>
    </div>
  );
};
