import { FC, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";
import { useGetPhaseListForWorkspace } from "@/hooks/api/common/usePhase";

import { PhaseSelectDropdownItem } from "./phase-select-dropdown-item";
export interface PhaseSelectDropdownProps extends PropsWithChildren {
  workspaceId: string;
  asChild?: boolean;
  selected?: {
    phaseId: string;
  } | null;
  onSelect?: (phase: { id: string } | null) => void;
  side?: "top" | "right" | "bottom" | "left";
}

export const PhaseSelectDropdown: FC<PhaseSelectDropdownProps> = ({
  workspaceId,
  children,
  asChild,
  side,
  onSelect,
  ...props
}) => {
  const [selectedPhase, setSelectedPhase] = useState(props.selected ? props.selected : null);
  const { phaseListForWorkspace } = useGetPhaseListForWorkspace({ workspaceId });

  const comboboxOptions = useMemo(() => {
    const phaseOptions = phaseListForWorkspace
      .slice()
      .sort((a, b) => {
        return a.order - b.order;
      })
      .flatMap((u) => {
        if (!u) {
          return [];
        }
        return {
          id: u.id,
          value: u.name,
        };
      });
    return [{ id: "-1", value: "Nicht zugewiesen" }].concat(phaseOptions);
  }, [phaseListForWorkspace]);

  const renderItem = (option: ComboboxOption): ReactElement => {
    const phase = phaseListForWorkspace.find((phase) => phase.id === option.id);

    const selected = (option.id === "-1" && selectedPhase === null) || selectedPhase?.phaseId === option.id;
    return <PhaseSelectDropdownItem phase={phase} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.id === "-1") {
      setSelectedPhase(null);
      if (onSelect) {
        onSelect(null);
      }
      return;
    }

    setSelectedPhase({ phaseId: selectedOption.id });
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  useEffect(() => {
    if (props.selected) {
      setSelectedPhase(props.selected);
    }
  }, [props.selected]);

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      side={side}
    >
      {children}
    </Combobox>
  );
};
