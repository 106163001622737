import { getFocusAreaListForWorkspaceQueryDocument } from "@/graphql/common/focus-area";
import { getLabelListForWorkspaceQueryDocument } from "@/graphql/common/label";
import { getLabelGroupQueryDocument } from "@/graphql/common/label-group";
import { getLabelGroupListForWorkspaceQueryDocument } from "@/graphql/common/label-group";
import { getPhaseListForWorkspaceQueryDocument } from "@/graphql/common/phase";
import { getPriorityListForWorkspaceQueryDocument } from "@/graphql/common/priority";
import { workspaceByIdQueryDocument } from "@/graphql/common/workspace";
import {
  GetFocusAreaListForWorkspaceInput,
  // FocusArea
  GetFocusAreaListForWorkspaceQuery,
  GetLabelGroupInput,
  // LabelGroup
  GetLabelGroupListForWorkspaceQuery,
  GetLabelGroupQuery,
  GetLabelGroupsForWorkspaceInput,
  // Label
  GetLabelListForWorkspaceQuery,
  GetLabelsForWorkspaceInput,
  GetPhaseListForWorkspaceInput,
  // Phase
  GetPhaseListForWorkspaceQuery,
  GetPriorityListForWorkspaceInput,
  // Priority
  GetPriorityListForWorkspaceQuery,
  Scalars,
  WorkspaceByIdQuery,
} from "@/graphql/generated/graphql";
import { ApolloCache } from "@apollo/client";

import {
  type EntityTypename,
  getEntityQueryConfig,
  isTEntity,
  type MaybeTEntity,
  type TEntity,
  type TEntityQueryResult,
} from "./_shared-generic-types";

/**
 * Returns a correctly typed query configuration object for cache operations.
 * This configuration is used to make the actual cache query/write for different entity types.
 *
 * @param entity - The entity being operated on (e.g., Priority)
 * @param document - The GraphQL query document to use (e.g., workspaceByIdQueryDocument or getPriorityListForWorkspaceQueryDocument)
 * @param variables - The variables to be passed to the specific query (e.g., { input: { workspaceId: entity.workspaceId } })
 * @param key - The key on the queryResult to access the list of entities (e.g., "priorityListForWorkspace")
 * @param key2 - An optional second key if needed for nested access (e.g., "priorityList" for data.workspaceById.priorityList)
 * @returns A configuration object for cache operations
 */
function getQueryConfigForType<T extends TEntity>(__typename: EntityTypename, entity: T) {
  switch (__typename) {
    case "Priority":
      return [
        getEntityQueryConfig<
          T,
          GetPriorityListForWorkspaceQuery, // TResult
          { input: GetPriorityListForWorkspaceInput }, // TVariables
          "priorityListForWorkspace", // K
          undefined // I (e.g, priority list is returned under "data.priorityListForWorkspace")
        >(
          __typename,
          entity,
          getPriorityListForWorkspaceQueryDocument,
          { input: { workspaceId: entity.workspaceId } },
          "priorityListForWorkspace",
          undefined,
        ),
        getEntityQueryConfig<
          T,
          WorkspaceByIdQuery, // TResult
          { input: Scalars["String"]["input"] }, // TVariables
          "workspaceById", // K
          "priorityList" // I (e.g, priority list is returned under "data.workspaceById.priorityList")
        >(
          __typename,
          entity,
          workspaceByIdQueryDocument,
          { input: entity.workspaceId },
          "workspaceById", // the key on the query to access the result
          "priorityList", // the key on the object to be written
        ),
      ];
    case "Phase":
      return [
        getEntityQueryConfig<
          T,
          GetPhaseListForWorkspaceQuery, // TResult
          { input: GetPhaseListForWorkspaceInput }, // TVariables
          "phaseListForWorkspace", // K
          undefined // I (e.g, priority list is returned under "data.priorityListForWorkspace")
        >(
          __typename,
          entity,
          getPhaseListForWorkspaceQueryDocument,
          { input: { workspaceId: entity.workspaceId } },
          "phaseListForWorkspace",
          undefined,
        ),
        getEntityQueryConfig<
          T,
          WorkspaceByIdQuery, // TResult
          { input: Scalars["String"]["input"] }, // TVariables
          "workspaceById", // K
          "phaseList" // I (e.g, priority list is returned under "data.workspaceById.priorityList")
        >(
          __typename,
          entity,
          workspaceByIdQueryDocument,
          { input: entity.workspaceId },
          "workspaceById", // the key on the query to access the result
          "phaseList", // the key on the object to be written
        ),
      ];
    case "FocusArea":
      return [
        getEntityQueryConfig<
          T,
          GetFocusAreaListForWorkspaceQuery, // TResult
          { input: GetFocusAreaListForWorkspaceInput }, // TVariables
          "focusAreaListForWorkspace", // K
          undefined // I (e.g, focus area list is returned under "data.focusAreaListForWorkspace")
        >(
          __typename,
          entity,
          getFocusAreaListForWorkspaceQueryDocument,
          { input: { workspaceId: entity.workspaceId } },
          "focusAreaListForWorkspace",
          undefined,
        ),
        getEntityQueryConfig<
          T,
          WorkspaceByIdQuery, // TResult
          { input: Scalars["String"]["input"] }, // TVariables
          "workspaceById", // K
          "focusAreaList" // I (e.g, focus area list is returned under "data.workspaceById.focusAreaList")
        >(
          __typename,
          entity,
          workspaceByIdQueryDocument,
          { input: entity.workspaceId },
          "workspaceById", // the key on the query to access the result
          "focusAreaList", // the key on the object to be written
        ),
      ];
    case "Label":
      return [
        getEntityQueryConfig<
          T,
          GetLabelListForWorkspaceQuery, // TResult
          { input: GetLabelsForWorkspaceInput }, // TVariables
          "labelsForWorkspace", // K
          undefined // I (e.g, label list is returned under "data.labelsForWorkspace")
        >(
          __typename,
          entity,
          getLabelListForWorkspaceQueryDocument,
          { input: { workspaceId: entity.workspaceId } },
          "labelsForWorkspace",
          undefined,
        ),
        getEntityQueryConfig<
          T,
          WorkspaceByIdQuery, // TResult
          { input: Scalars["String"]["input"] }, // TVariables
          "workspaceById", // K
          "labelList" // I (e.g, label list is returned under "data.workspaceById.labelList")
        >(
          __typename,
          entity,
          workspaceByIdQueryDocument,
          { input: entity.workspaceId },
          "workspaceById", // the key on the query to access the result
          "labelList", // the key on the object to be written
        ),
        // If the labelGroupId is set, we need to update the labelGroup query
        ...((entity as { labelGroupId?: string | null }).labelGroupId
          ? [
              getEntityQueryConfig<
                T,
                GetLabelGroupQuery, // TResult
                { input: GetLabelGroupInput }, // TVariables
                "labelGroup", // K
                "labels" // I (e.g, label list is returned under "data.workspaceById.labelList")
              >(
                __typename,
                entity,
                getLabelGroupQueryDocument,
                { input: { id: (entity as { labelGroupId?: string | null }).labelGroupId ?? "" } },
                "labelGroup", // the key on the query to access the result
                "labels", // the key on the object to be written
              ),
            ]
          : []),
      ];
    case "LabelGroup":
      return [
        getEntityQueryConfig<
          T,
          GetLabelGroupListForWorkspaceQuery, // TResult
          { input: GetLabelGroupsForWorkspaceInput }, // TVariables
          "labelGroupsForWorkspace", // K
          undefined // I (e.g, label group list is returned under "data.labelGroupListForWorkspace")
        >(
          __typename,
          entity,
          getLabelGroupListForWorkspaceQueryDocument,
          { input: { workspaceId: entity.workspaceId } },
          "labelGroupsForWorkspace",
          undefined,
        ),
        getEntityQueryConfig<
          T,
          WorkspaceByIdQuery, // TResult
          { input: Scalars["String"]["input"] }, // TVariables
          "workspaceById", // K
          "labelGroupList" // I (e.g, label group list is returned under "data.workspaceById.labelGroupList")
        >(
          __typename,
          entity,
          workspaceByIdQueryDocument,
          { input: entity.workspaceId },
          "workspaceById", // the key on the query to access the result
          "labelGroupList", // the key on the object to be written
        ),
      ];
  }
}

export type OnGenericCreateParams<T extends EntityTypename, E extends TEntity> = {
  cache: ApolloCache<Record<string, unknown>>;
  newEntity: E | MaybeTEntity;
  typename: T;
  warn?: boolean;
};

/**
 * Updates the cache when a priority is deleted.
 *
 * This function updates two potential cache locations:
 * 1. The direct priority list query cache
 * 2. The workspace's priority list field
 *
 * Since the priority list can be accessed either directly or through the workspace,
 * we need to update both cache entries to ensure consistency.
 *
 * @param cache The Apollo cache instance
 * @param deletedPriority The priority that was deleted
 */
export function onGenericEntityCreate<T extends EntityTypename, E extends TEntity>({
  cache,
  newEntity,
  typename,
  warn = true,
}: OnGenericCreateParams<T, E>) {
  if (!isTEntity(newEntity)) {
    if (warn) {
      console.warn(
        `(Apollo) [Cache update on create for '${typename}'}: object does not have the required properties`,
        newEntity,
      );
    }
    return;
  }

  const configs = getQueryConfigForType(newEntity.__typename, newEntity);

  if (!configs) {
    return;
  }

  // Loop over all query configs and update each one
  for (const config of configs) {
    if (!config) {
      continue;
    }

    const { key, key2, document, variables } = config;

    const entityListResult = cache.readQuery<TEntityQueryResult<E, typeof key, typeof key2>>({
      query: document,
      variables: variables,
    });

    if (entityListResult?.[key]) {
      if (key2) {
        const workspaceData = entityListResult[key] as { [key: string]: E[] };
        if (workspaceData[key2]) {
          const entityList = workspaceData[key2];

          // Prepare the cache write configuration
          const writeQueryConfiguration = {
            query: document,
            variables: variables,
            data: {
              [key]: {
                ...workspaceData,
                [key2]: [...entityList, newEntity],
              },
            },
          };

          // Since the TS compiler only knows the shape of the TEntity type we need to cast to any here
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          cache.writeQuery(writeQueryConfiguration as any);
        }
      } else {
        const entityList = entityListResult[key] as E[];

        // Prepare the cache write configuration
        const writeQueryConfiguration = {
          query: document,
          variables: variables,
          data: {
            [key]: [...entityList, newEntity],
          },
        };

        // Since the TS compiler only knows the shape of the TEntity type we need to cast to any here
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cache.writeQuery(writeQueryConfiguration as any);
      }
    }
  }
}
