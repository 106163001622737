import { FC, PropsWithChildren } from "react";

import { useCurrentUserContext } from "@/components/_domain/user/hooks/useCurrentUserContext";
import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";
import { Button } from "@/components/_ui/button";
import { Card, CardContent } from "@/components/_ui/card";
import { toast } from "@/components/_ui/use-toast";
import { NotFoundView } from "@/components/not-found-view";
import { rejoinWorkspaceMembershipMutationDocument } from "@/graphql/common/workspace-membership";
import { WorkspaceSettings } from "@/services/workspace.service";
import { useMutation } from "@apollo/client";

export interface WorkspaceSelectProps extends PropsWithChildren {
  text?: string;
}

const Page: FC<WorkspaceSelectProps> = () => {
  const { currentUser } = useCurrentUserContext();
  const [updateStatus] = useMutation(rejoinWorkspaceMembershipMutationDocument);

  async function handleJoinWorkspace(workspace: WorkspaceSettings | null | undefined) {
    if (!workspace || !currentUser) {
      toast({
        title: "Es ist ein Fehler aufgetreten",
        description:
          "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
      return;
    }

    await updateStatus({
      variables: {
        input: {
          workspaceId: workspace.id,
        },
      },
      onError: () => {
        toast({
          title: "Fehler",
          description:
            "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
          variant: "error",
        });
      },
      onCompleted: () => {
        window.location.assign(`${window.location.origin}/${workspace.workspaceUrl}`);
      },
    });
  }

  function handleSelectWorkspace(workspace: WorkspaceSettings | null | undefined) {
    if (!workspace) {
      toast({
        title: "Es ist ein Fehler aufgetreten",
        description:
          "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
      return;
    }

    window.location.assign(`${window.location.origin}/${workspace.workspaceUrl}`);
  }

  if (!currentUser) {
    return (
      <NotFoundView
        text={
          "Etwas scheint schiefgegangen zu sein. Es wurde kein aktiver Workspace für Ihren Account gefunden. Bitte kontaktieren Sie unseren Support."
        }
      />
    );
  }

  if (!currentUser.workspaceMembershipList) {
    return (
      <NotFoundView
        text={
          "Etwas scheint schiefgegangen zu sein. Es wurde kein aktiver Workspace für Ihren Account gefunden. Bitte kontaktieren Sie unseren Support."
        }
      />
    );
  }

  return (
    <main className="align-center relative flex h-screen select-none flex-col items-center justify-center overflow-auto">
      <h1 className="my-6 text-center text-xl">Sie haben Zugang zu folgenden Workspaces</h1>

      <Card className="z-10 w-full max-w-xl overflow-hidden overflow-y-scroll border p-10 shadow-xl">
        <CardContent>
          <div className="flex flex-col gap-4">
            {currentUser.workspaceMembershipList
              .slice()
              .sort((a) => (a.status === "ACTIVE" ? -1 : 0))
              .map((workspaceMembership) => {
                if (!workspaceMembership.workspace) {
                  return <></>;
                }

                return (
                  <div
                    key={`${workspaceMembership.userId}+${workspaceMembership.workspaceId}`}
                    className="align-start flex flex-row justify-between gap-4 border-b pb-4"
                  >
                    <div className="h-8 w-8">
                      <AvatarWorkspace workspace={workspaceMembership.workspace} className="h-8 w-8 text-lg" />
                    </div>
                    <div className="text-md flex grow">
                      <span>{workspaceMembership.workspace.name}</span>
                    </div>
                    <div>
                      {workspaceMembership.status === "LEFT" && (
                        <Button variant="outline" onClick={() => handleJoinWorkspace(workspaceMembership.workspace)}>
                          Beitreten
                        </Button>
                      )}
                      {workspaceMembership.status === "ACTIVE" && (
                        <Button variant="outline" onClick={() => handleSelectWorkspace(workspaceMembership.workspace)}>
                          Auswählen
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}

            <div className="mt-6 w-full">
              <p className="text-sm font-light text-foreground">
                Sie sind aktuell mit dem Account <span className="font-medium">{currentUser.email}</span> angemeldet.
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </main>
  );
};

export default Page;
