import * as z from "zod";

export const IconTypes = {
  emoji: "EMOJI",
  icon: "ICON",
} as const;

export const ALLOWED_ICON_TYPES = Object.values(IconTypes);
export type IconType = (typeof ALLOWED_ICON_TYPES)[number];

export const IconSchema = z.object({
  value: z.string(),
  color: z.string().nullable(),
  mode: z.union([z.literal(IconTypes.emoji), z.literal(IconTypes.icon)]),
});
export type Icon = z.infer<typeof IconSchema>;

export const iconTypefromString = (str: string): IconType | undefined => {
  if (str === IconTypes.emoji) {
    return IconTypes.emoji;
  }
  if (str === IconTypes.icon) {
    return IconTypes.icon;
  }
  return undefined;
};
