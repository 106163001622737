import { PhaseList } from "../../phase/phase-type";

export const NO_FOCUS_AREA = "Kein Handlungsfeld";
export const NO_FOCUS_AREA_TYPE = "Kein Handlungsfeld";
export const PHASE_NAME_MAP: Record<string, string> = {
  [PhaseList.backlog]: "Nicht gestartet",
  [PhaseList.unstarted]: "In Planung",
  [PhaseList.started]: "In Umsetzung",
  [PhaseList.completed]: "Abgeschlossen",
  [PhaseList.canceled]: "Abgebrochen",
};
