"use client";

import { forwardRef, type PropsWithChildren, useEffect, useMemo, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { HiCheck } from "react-icons/hi2";

import { Button, type ButtonProps } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import { useForwardedRef } from "@/hooks/useForwardRef";

import { PRESET_COLORS } from "./config";
import { isValidHexCode } from "./utils";

import "./color-picker.css";

export interface ColorPickerProps extends PropsWithChildren {
  asChild?: boolean;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const ColorPicker = forwardRef<HTMLInputElement, Omit<ButtonProps, "value" | "onChange" | "onBlur"> & ColorPickerProps>(
  ({ value, onChange, onBlur, asChild, children }, forwardedRef) => {
    const ref = useForwardedRef(forwardedRef);
    const [selectedColor, setSelectedColor] = useState<string | null>(null);
    const [open, setOpen] = useState(false);

    const [mode, setMode] = useState<"PRESET" | "HEX">("PRESET");

    const parsedValue = useMemo(() => {
      return value || "#FFFFFF";
    }, [value]);

    function handleColorSelected(color: string) {
      if (!isValidHexCode(color)) {
        // fail silently
        return;
      }
      setSelectedColor(color);
      setOpen(false);

      // notify parent component
      if (onChange) {
        onChange(color);
      }
    }

    useEffect(() => {
      if (!value) {
        return;
      }

      if (!PRESET_COLORS.indexOf(value)) {
        return;
      }

      setSelectedColor(value);
    }, [value]);

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger onBlur={onBlur} asChild={asChild}>
          {children}
        </PopoverTrigger>
        <PopoverContent align="start">
          {mode === "PRESET" && (
            <>
              <div className="flex h-4 flex-row items-center justify-between">
                {PRESET_COLORS.map((color) => {
                  return (
                    <div
                      key={color}
                      className="flex h-5 w-5 flex-row items-center justify-center"
                      onClick={() => handleColorSelected(color)}
                    >
                      <div
                        className={`flex h-4 w-4 cursor-pointer flex-row items-center justify-center rounded-full hover:h-5 hover:w-5 ${
                          selectedColor === color ? "h-5 w-5" : ""
                        }`}
                        style={{ backgroundColor: color }}
                      >
                        {selectedColor === color && <HiCheck className="h-2.5 w-2.5 select-none opacity-40" />}
                      </div>
                    </div>
                  );
                })}
                <div className="mx-2 h-full w-0 border-l border-solid border-border" />
                <Button
                  variant="ghost"
                  className="rounded-full border border-border"
                  size="icon"
                  onClick={() => setMode("HEX")}
                >
                  #
                </Button>
              </div>
            </>
          )}
          {mode === "HEX" && (
            <div className="color-picker-responsive flex w-full flex-col gap-2">
              <HexColorPicker color={parsedValue} onChange={onChange} className="w-full" />
              <div className="flex flex-row items-center gap-2">
                <Input
                  maxLength={7}
                  onChange={(e) => {
                    onChange(e?.currentTarget?.value);
                  }}
                  ref={ref}
                  value={parsedValue}
                />
                <Button
                  variant="ghost"
                  className="h-9 w-9 shrink-0 grow-0 rounded-full border border-border"
                  size="icon"
                  onClick={() => setMode("PRESET")}
                >
                  <div className="h-3 w-3 rounded-full" style={{ backgroundColor: parsedValue }} />
                </Button>
              </div>
            </div>
          )}
        </PopoverContent>
      </Popover>
    );
  },
);
ColorPicker.displayName = "ColorPicker";

export { ColorPicker };
