import { ClimateAction } from "@/components/_domain/climate-action/models";
import { sortPriority } from "@/components/_domain/priority";
import { ColumnDef } from "@tanstack/react-table";

import { ClimateActionListCellPriority, ClimateActionListColumnHeader } from "../..";
import { filterByTextListWithUnknownPayload } from "../../filter-functions";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import { FilterByPriorityConfigurationView, FilterByPriorityDisplayView, FilterByPriorityFilterPaneView } from ".";

interface PriorityColumnDefinitionProps {
  columnId: string;
  orderValue: number;
}

export const PriorityColumnDefinition = ({
  columnId,
  orderValue,
}: PriorityColumnDefinitionProps): ClimateActionColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Priorität",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<ClimateAction> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          return sortPriority(
            rowA.original.priorityInstance?.priority?.type,
            rowB.original.priorityInstance?.priority?.type,
          );
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          return filterByTextListWithUnknownPayload(value, row.original.priorityInstance?.priority?.name);
        },
        size: 1, // set to 1 instead to 0 for it to work in Safari
        header: ({ column }) => (
          <ClimateActionListColumnHeader column={column} title="" className={`px-1 text-xs font-normal`} />
        ),
        cell: ({ row }) => <ClimateActionListCellPriority row={row} />,
      };
    },
    getDisplayView: () => <FilterByPriorityDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByPriorityConfigurationView column={{ id: columnId }} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "popover",
    getFilterPaneView: () => <FilterByPriorityFilterPaneView column={{ id: columnId }} />,
  };
};
