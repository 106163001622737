import { FC, PropsWithChildren, useState } from "react";

import IconCircleSolid from "@/components/_icons/IconCircleSolid";
import IconDots from "@/components/_icons/IconDots";
import { Button } from "@/components/_ui/button";
import { getLabelQueryDocument } from "@/graphql/common/label";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { cn } from "@/lib/utils";
import { useQuery } from "@apollo/client";

import WorkspaceLabelActionButton from "./workspace-label-action-button";
import WorkspaceLabelEditForm from "./workspace-label-edit-form";

export interface WorkspaceLabelListItemPropsWithChildren extends PropsWithChildren {
  label: {
    id: string;
  };
  workspace: {
    id: string;
  };
  className?: string;
}

export const WorkspaceLabelListItem: FC<WorkspaceLabelListItemPropsWithChildren> = ({
  label,
  workspace,
  className,
}) => {
  const [editMode, setEditMode] = useState(false);

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspace.id,
    action: "manage",
  });
  const { data, loading } = useQuery(getLabelQueryDocument, {
    variables: { input: { id: label.id } },
  });

  if (loading || !data) {
    return <></>;
  }

  return (
    <div className={cn(["my-1 flex flex-row items-center justify-between rounded border p-1 pr-2", className])}>
      {!editMode && (
        <div className="flex w-full flex-row items-center justify-between">
          <div className="w-7"></div>
          <div className="mr-1 w-6">
            <IconCircleSolid className="mx-auto h-2.5 w-2.5" style={{ color: data.label.color }} />
          </div>
          <div className="grow">
            <span>{data.label.title}</span>
            {data.label.instances.length > 0 && (
              <span className="ml-1 text-muted-foreground/40">
                {` · ${data.label.instances.length} ${data.label.instances.length === 1 ? "Maßnahme" : "Maßnahmen"}`}
              </span>
            )}
          </div>
          <div>
            {hasPermissionToManageWorkspace && (
              <WorkspaceLabelActionButton
                label={label}
                workspace={workspace}
                handleEditLabel={() => setEditMode(true)}
                asChild
              >
                <Button variant="ghost" size="icon">
                  <IconDots />
                </Button>
              </WorkspaceLabelActionButton>
            )}
          </div>
        </div>
      )}
      {editMode && (
        <WorkspaceLabelEditForm
          label={data.label}
          onCanceled={() => {
            setEditMode(false);
          }}
          onSuccess={() => {
            setEditMode(false);
          }}
        />
      )}
    </div>
  );
};

export default WorkspaceLabelListItem;
