import { FC, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";
import { User } from "@/graphql/generated/graphql";

import { UserSelectDropdownItem } from "./user-select-dropdown-item";
export interface UserSelectDropdownProps extends PropsWithChildren {
  activeUsers: User[];
  asChild?: boolean;
  selected?: {
    id: string;
  } | null;
  side?: "top" | "right" | "bottom" | "left";
  onSelect?: (user: { id: string } | null) => void;
}

export const UserSelectDropdown: FC<UserSelectDropdownProps> = ({
  activeUsers,
  children,
  side,
  asChild,
  onSelect,
  ...props
}) => {
  const [selectedUser, setSelectedUser] = useState(props.selected ? props.selected : null);

  useEffect(() => {
    if (props.selected) {
      setSelectedUser(props.selected);
    }
  }, [props.selected]);

  // for faster retrieval
  const userMap = useMemo(() => {
    return new Map(activeUsers.map((obj) => [obj.id, obj]));
  }, [activeUsers]);

  const comboboxOptions = useMemo(() => {
    const userOptions = activeUsers
      .slice()
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        const _a = a.username ?? "";
        const _b = b.username ?? "";

        return _a.localeCompare(_b);
      })
      .flatMap((u) => {
        if (!u) {
          return [];
        }
        return {
          id: u.id,
          value: `${u?.username ?? ""} ; ${u?.fullname ?? ""} ; ${u?.email ?? ""}`,
        };
      });
    return [{ id: "-1", value: "Kein Verantwortlicher" }].concat(userOptions);
  }, [activeUsers]);

  const renderItem = (status: ComboboxOption): ReactElement => {
    const user = userMap.get(status.id) ?? undefined;
    const selected = (status.id === "-1" && selectedUser === null) || selectedUser?.id === status.id;
    return <UserSelectDropdownItem user={user} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.id === "-1") {
      setSelectedUser(null);
      if (onSelect) {
        onSelect(null);
      }
      return;
    }

    setSelectedUser(selectedOption);
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      side={side}
    >
      {children}
    </Combobox>
  );
};
