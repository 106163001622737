import { FC } from "react";
import { Check } from "lucide-react";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import { useCurrentUserContext } from "@/components/_domain/user/hooks/useCurrentUserContext";
import { Button } from "@/components/_ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/_ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import { getClimateActionListQueryDocument, getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { updateTeamInstanceListForOwnerMutationDocument } from "@/graphql/common/team-instance";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common/useWorkspace/useTeamListForUserInWorkspace";
import { cn } from "@/lib/utils";
import { useMutation } from "@apollo/client";
import { Row } from "@tanstack/react-table";

interface ClimateActionListTeamPropsCell {
  row: Row<ClimateAction>;
  className?: string;
}

const CLEAR_ALL_TEAM_ID = "clear-all";

export const ClimateActionListTeamCell: FC<ClimateActionListTeamPropsCell> = ({ row, className }) => {
  const { currentUser } = useCurrentUserContext();
  const teamInstances = row.original.teamInstanceList || [];
  const teams = teamInstances;

  const { teamList } = useTeamListForUserInWorkspace({
    workspaceId: row.original.workspaceId,
    userId: currentUser?.id,
  });

  // Create a direct mutation that we can customize with the right refetch queries
  const [updateTeamInstanceListMutation] = useMutation(updateTeamInstanceListForOwnerMutationDocument);

  const handleTeamSelect = async (teamId: string) => {
    let newTeamIds: string[];

    if (teamId === CLEAR_ALL_TEAM_ID) {
      // Clear all teams
      newTeamIds = [];
    } else {
      const currentTeamIds = teams.map((t) => t.teamId);

      if (currentTeamIds.includes(teamId)) {
        // Remove team if already selected
        newTeamIds = currentTeamIds.filter((id) => id !== teamId);
      } else {
        // Add team if not selected
        newTeamIds = [...currentTeamIds, teamId];
      }
    }

    await updateTeamInstanceListMutation({
      variables: {
        input: {
          ownerId: row.original.id,
          teamIdList: newTeamIds,
          ownerType: "CLIMATE_ACTION",
          workspaceId: row.original.workspaceId,
        },
      },
      refetchQueries: [
        {
          query: getClimateActionListQueryDocument,
          variables: {
            input: {
              workspaceId: row.original.workspaceId,
            },
          },
        },
        {
          query: getClimateActionQueryDocument,
          variables: { input: { id: row.original.id } },
        },
      ],
    });
  };

  const TeamSelector = () => (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8 justify-end">
          {teams.length === 0 ? (
            <span className="text-xs font-normal">Kein Team</span>
          ) : teams.length === 1 ? (
            <div className="flex items-center gap-2">
              <span className="mr-1">
                {teamList.find((t) => t.id === teams[0].teamId)?.logo ||
                  teamList.find((t) => t.id === teams[0].teamId)?.name?.charAt(0)}
              </span>
              <span className="text-xs font-normal">{teamList.find((t) => t.id === teams[0].teamId)?.name}</span>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <div className="flex -space-x-2">
                {teams.map((team) => {
                  const availableTeam = teamList.find((t) => t.id === team.teamId);
                  return availableTeam ? (
                    <span key={availableTeam.id} className="mr-1">
                      {availableTeam.logo || availableTeam.name.charAt(0)}
                    </span>
                  ) : null;
                })}
              </div>
              <span className="text-xs font-normal">({teams.length} Teams)</span>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start" data-testid="climate-action-list-team-cell-popover">
        <Command>
          <CommandInput placeholder="Team suchen..." />
          <CommandEmpty>Keine Teams gefunden</CommandEmpty>
          <CommandGroup>
            <CommandItem onSelect={() => handleTeamSelect(CLEAR_ALL_TEAM_ID)} className="flex items-center gap-2">
              <span className="truncate text-xs font-normal">Keine Teams</span>
              {teams.length === 0 && <Check className="ml-auto h-4 w-4" />}
            </CommandItem>
            {teamList.map((team) => {
              const isSelected = teams.some((t) => t.teamId === team.id);
              return (
                <CommandItem
                  key={team.id}
                  onSelect={() => handleTeamSelect(team.id)}
                  className="flex items-center gap-2"
                >
                  <span className="mr-1">{team.logo || team.name.charAt(0)}</span>
                  <span className="truncate text-xs font-normal">{team.name}</span>
                  {isSelected && <Check className="ml-auto h-4 w-4" />}
                </CommandItem>
              );
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );

  return (
    <div className={cn("flex items-center", className)}>
      <TeamSelector />
    </div>
  );
};
