import { FC, PropsWithChildren, ReactNode, useMemo, useState } from "react";

import { Input } from "@/components/_ui/input";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useWorkspaceMembershipList } from "@/hooks/api/common/useWorkspace";

import WorkspaceMemberInviteButton from "./workspace-member-invite-button";
import { WorkspaceMemberJoinButton } from "./workspace-member-join-button";
import WorkspaceMemberListItem from "./workspace-member-list-item";

export interface WorkspaceMemberListProps extends PropsWithChildren {
  workspace: {
    id: string;
  };
  className?: string;
}

export const WorkspaceMemberList: FC<WorkspaceMemberListProps> = ({ workspace }) => {
  const { currentUser, isSuperUser } = useCurrentUserContext();
  const [filterValue, setFilterValue] = useState<string>("");
  const { workspaceMembershipList } = useWorkspaceMembershipList({ workspaceId: workspace.id });
  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspace.id,
    action: "manage",
  });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    if (isSuperUser) {
      return true;
    }
    return hasPermissionToManageWorkspace;
  }, [currentUser, isSuperUser, hasPermissionToManageWorkspace]);

  const filteredWorkspaceMembershipList = useMemo(() => {
    if (filterValue === "") {
      return workspaceMembershipList;
    }

    return workspaceMembershipList.filter((wsm) => {
      const query = filterValue.toLocaleLowerCase();
      const user = wsm.user;

      if (!user) {
        return false;
      }

      if (user.email.toLocaleLowerCase().includes(query)) {
        return true;
      }

      if (user.fullname && user.fullname.toLocaleLowerCase().includes(query)) {
        return true;
      }

      if (user.username && user.username.toLocaleLowerCase().includes(query)) {
        return true;
      }

      false;
    });
  }, [workspaceMembershipList, filterValue]);

  function renderWorkspaceMemberList() {
    // inefficient, but for now we shouldn't deal with too many users
    let sortedListWorkspaceMemberships = filteredWorkspaceMembershipList
      .slice()
      .sort((a) => (a.role === "ADMIN" ? -1 : 1));
    sortedListWorkspaceMemberships = sortedListWorkspaceMemberships.slice().sort((a) => (a.status === "LEFT" ? -1 : 1));
    sortedListWorkspaceMemberships = sortedListWorkspaceMemberships
      .slice()
      .sort((a) => (a.status === "PENDING" ? -1 : 1));
    sortedListWorkspaceMemberships = sortedListWorkspaceMemberships
      .slice()
      .sort((a) => (a.status === "ACTIVE" ? -1 : 1));
    sortedListWorkspaceMemberships = sortedListWorkspaceMemberships
      .slice()
      .sort((a) => (a.userId === currentUser?.id ? -1 : 1));

    const initialAccumulator: ReactNode[] = [];
    const listItemsElements = sortedListWorkspaceMemberships.reduce((result, workspaceMembership) => {
      const user = workspaceMembership.user;
      if (!user) {
        return result; // faulty user, ignore it
      }

      result.push(
        <WorkspaceMemberListItem
          key={`${workspaceMembership.userId}+${workspaceMembership.workspaceId}`}
          workspaceMembership={workspaceMembership}
          user={user}
        />,
      );
      return result;
    }, initialAccumulator);

    if (listItemsElements.length === 0) {
      return (
        <div className="flex h-24 flex-row items-center justify-center rounded-md text-center text-xs">
          Kein Ergebnisse
        </div>
      );
    }

    return listItemsElements;
  }

  return (
    <div>
      <div>
        <div>
          <p className="font-light text-muted-foreground">
            Verwalten Sie die Nutzer, die Zugang zu Ihrem Workspace haben. Nutzer mit Administratoren Roll können
            weitere Nutzer einladen und Änderungen in den Einstellungen des Workspaces vornehmen.
          </p>
        </div>
        <div className="my-8"></div>
        {hasEditRights && (
          <div className="flex shrink grow flex-row items-center justify-between">
            <Input
              placeholder="Nutzer filtern..."
              value={filterValue}
              onChange={(event) => {
                return setFilterValue(event.currentTarget.value);
              }}
              className="h-8 w-[200px] lg:w-[350px]"
            />
            <div className="flex flex-row gap-6"></div>
            <div className="flex flex-row gap-2">
              <WorkspaceMemberJoinButton workspace={workspace} />
              <WorkspaceMemberInviteButton workspace={workspace} />
            </div>
          </div>
        )}
      </div>
      <div className="mt-10 border-t border-solid">{renderWorkspaceMemberList()}</div>
    </div>
  );
};

export default WorkspaceMemberList;
