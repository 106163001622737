import { cva } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { BoardItem, BoardItemConfig, DRAG_TYPE, ItemDragData } from "./board-types";

interface BoardCardProps<U extends BoardItem> {
  item: U;
  isOverlay?: boolean;
  renderItem: (config: BoardItemConfig<U>) => React.ReactNode;
}

export function BoardCard<U extends BoardItem>({ item, isOverlay, renderItem }: BoardCardProps<U>) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
    data: {
      type: DRAG_TYPE.ITEM,
      item,
    } satisfies ItemDragData<U>,
    attributes: {
      roleDescription: `Item: ${item.id}`,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? "grabbing" : "grab",
    boxShadow: "lch(0 0 0 / 0.022) 0px 3px 6px -2px, lch(0 0 0 / 0.044) 0px 1px 1px",
  };

  const variants = cva("", {
    variants: {
      dragging: {
        // The item being dragged out of the list
        over: "opacity-30 cursor-grabbing",
        // The item being dragged under the mouse
        overlay: "cursor-grabbing shadow-lg",
        default: "",
      },
    },
  });

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cn(
        "box-content transition-all ",
        variants({
          dragging: isOverlay ? "overlay" : isDragging ? "over" : "default",
        }),
      )}
      {...attributes}
      {...listeners}
    >
      {renderItem({
        data: item,
        isOverlay: Boolean(isOverlay),
        isOver: Boolean(!isOverlay) && Boolean(isDragging),
      })}
    </div>
  );
}
