import { FC, useMemo } from "react";

import { FormControl } from "@/components/_ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/_ui/select";

import { KEY_RESULT_TYPES } from "../models/key-result.form.schema";
import { KeyResultTypeIDs, keyResultTypes } from "../models/key-result-types";

export interface KeyResultTypeSelectProps {
  onValueChange?: (value: KEY_RESULT_TYPES) => void;
  defaultValue?: string;
}

export const KeyResultTypeSelect: FC<KeyResultTypeSelectProps> = ({ defaultValue, ...props }) => {
  const santitizedDefaultValue: KeyResultTypeIDs | undefined = useMemo(() => {
    for (const { id } of keyResultTypes) {
      if (id === defaultValue) {
        return id;
      }
    }
    return undefined;
  }, [defaultValue]);

  return (
    <Select defaultValue={santitizedDefaultValue} {...props}>
      <FormControl>
        <SelectTrigger>
          <SelectValue placeholder="" />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {keyResultTypes.map(({ id, displayValue }) => {
          return (
            <SelectItem key={id} value={id}>
              {displayValue}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};

export default KeyResultTypeSelect;
