import { IndicatorListTitleView } from "@/components/_domain/indicator/components/indicator-list-title-view";
import { IndicatorTitleView } from "@/components/_domain/indicator/components/indicator-title-view";
import { useCurrentIndicatorContext } from "@/components/_domain/indicator/hooks/useCurrentIndicatorContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { Link } from "@/components/link";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";

export const IndicatorPage = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentIndicator } = useCurrentIndicatorContext();

  return (
    <div className="flex h-full w-full flex-row">
      <div className="flex h-full max-h-full w-full flex-col overflow-y-hidden">
        <HeaderLayout>
          <div className="flex flex-row items-center gap-2">
            <Link to={`/${currentWorkspace.workspaceUrl}/indicators`}>
              <IndicatorListTitleView />
            </Link>
            <div>›</div>
            <Link to={`/${currentWorkspace.workspaceUrl}/indicators/${currentIndicator.id}`}>
              <IndicatorTitleView indicator={currentIndicator} />
            </Link>
            <FavoriteButton pageName={currentIndicator.title} />
          </div>
        </HeaderLayout>

        <div className="flex w-full flex-row flex-wrap items-center px-6 py-6 text-sm">
          {JSON.stringify(currentIndicator)}
        </div>
      </div>
    </div>
  );
};
