import { getFocusAreaQueryDocument } from "@/graphql/common/focus-area";
import { getLabelQueryDocument } from "@/graphql/common/label";
import { getLabelInstancesQueryDocument } from "@/graphql/common/label-instance";
import { getPhaseQueryDocument } from "@/graphql/common/phase";
import { getPriorityQueryDocument } from "@/graphql/common/priority";
import {
  GetFocusAreaInput,
  GetFocusAreaQuery,
  GetLabelInput,
  GetLabelInstancesInput,
  GetLabelInstancesQuery,
  GetLabelQuery,
  GetPhaseInput,
  GetPhaseQuery,
  GetPriorityInput,
  GetPriorityQuery,
} from "@/graphql/generated/graphql";
import { ApolloCache } from "@apollo/client";

import {
  type EntityInstanceTypename,
  getEntityInstanceQueryConfig,
  isTEntityInstance,
  type MaybeTEntityInstance,
  type TEntityInstance,
  type TEntityInstanceQueryResult,
} from "./_shared-generic-types";
import { handleDeleteInstanceFromOwner } from "./handle-generic-owner-update";

/**
 * Returns a query configuration for entity instances (e.g., PriorityInstance).
 * This configuration is used to update the cache when an instance is created, updated, or deleted.
 *
 * For example, when a PriorityInstance is created:
 * - The parent entity (Priority) needs to be updated to include the new instance
 * - The owner (e.g., ClimateAction) needs to be updated to reference the new instance
 *
 * @param __typename - The type of entity instance (e.g., "PriorityInstance")
 * @param entityInstance - The instance being operated on, containing required properties (id, parentId, ownerId, ownerType)
 * @returns A configuration object containing the parent query config for cache operations
 */
function getQueryConfigForType<T extends TEntityInstance>(__typename: EntityInstanceTypename, entityInstance: T) {
  switch (__typename) {
    case "PriorityInstance":
      return [
        getEntityInstanceQueryConfig<
          T,
          GetPriorityQuery, // TResult
          { input: GetPriorityInput }, // TVariables
          "priority", // K -> data for this query is under "data.priority"
          "instances" // I -> instances are returned under "data.priority.instances"
        >(
          __typename,
          entityInstance,
          getPriorityQueryDocument,
          { input: { id: entityInstance.__parentId } },
          "priority",
          "instances",
        ),
      ];
    case "PhaseInstance":
      return [
        getEntityInstanceQueryConfig<
          T,
          GetPhaseQuery, // TResult
          { input: GetPhaseInput }, // TVariables
          "phase", // K -> data for this query is under "data.phase"
          "instances" // I -> instances are returned under "data.phase.instances"
        >(
          __typename,
          entityInstance,
          getPhaseQueryDocument,
          { input: { id: entityInstance.__parentId } },
          "phase",
          "instances",
        ),
      ];
    case "FocusAreaInstance":
      return [
        getEntityInstanceQueryConfig<
          T,
          GetFocusAreaQuery, // TResult
          { input: GetFocusAreaInput }, // TVariables
          "focusArea", // K -> data for this query is under "data.focusArea"
          "instances" // I -> instances are returned under "data.focusArea.instances"
        >(
          __typename,
          entityInstance,
          getFocusAreaQueryDocument,
          { input: { id: entityInstance.__parentId } },
          "focusArea",
          "instances",
        ),
      ];
    case "LabelInstance":
      return [
        getEntityInstanceQueryConfig<
          T,
          GetLabelQuery, // TResult
          { input: GetLabelInput }, // TVariables
          "label", // K -> data for this query is under "data.label"
          "instances" // I -> instances are returned under "data.label.instances"
        >(
          __typename,
          entityInstance,
          getLabelQueryDocument,
          { input: { id: entityInstance.__parentId } },
          "label",
          "instances",
        ),
        getEntityInstanceQueryConfig<
          T,
          GetLabelInstancesQuery, // TResult
          { input: GetLabelInstancesInput }, // TVariables
          "labelInstances", // K -> data for this query is under "data.labelInstances"
          undefined // I -> instances are returned under "data.labelInstances"
        >(
          __typename,
          entityInstance,
          getLabelInstancesQueryDocument,
          { input: { ownerId: entityInstance.ownerId } },
          "labelInstances",
          undefined,
        ),
      ];
  }
}

export type OnGenericDeleteParams<T extends EntityInstanceTypename, E extends TEntityInstance> = {
  cache: ApolloCache<Record<string, unknown>>;
  deletedEntityInstance: E | MaybeTEntityInstance;
  typename: T;
  warn?: boolean;
};

/**
 * Updates the cache when a priority is deleted.
 *
 * This function updates two potential cache locations:
 * 1. The direct instance list on the query for the entity (e.g., for a priority instance, this is the priority.instances array)
 * 2. Clears the priority instance from the owner's reference
 *
 *
 * @param cache The Apollo cache instance
 * @param deletedPriorityInstance The priority instance that was deleted
 */
export function onGenericEntityInstanceDelete<T extends EntityInstanceTypename, E extends TEntityInstance>({
  cache,
  deletedEntityInstance,
  typename,
  warn = true,
}: OnGenericDeleteParams<T, E>) {
  if (!isTEntityInstance(deletedEntityInstance)) {
    if (warn) {
      console.warn(
        `(Apollo) [Cache update on delete for '${typename}'}: object does not have the required properties`,
        deletedEntityInstance,
      );
    }
    return;
  }

  const configs = getQueryConfigForType(deletedEntityInstance.__typename, deletedEntityInstance);

  if (!configs) {
    return;
  }

  // Loop over all query configs and update each one
  for (const config of configs) {
    if (!config) {
      continue;
    }

    const { key, key2, document, variables } = config;

    const entityResult = cache.readQuery<TEntityInstanceQueryResult<E, typeof key, typeof key2>>({
      query: document,
      variables: variables,
    });

    if (!entityResult?.[key]) {
      continue;
    }

    if (key2) {
      const entityData = entityResult[key] as { [key: string]: E[] };
      if (entityData[key2]) {
        const entityInstanceList = entityData[key2];

        // Prepare the cache write configuration
        const writeQueryConfiguration = {
          query: document,
          variables: variables,
          data: {
            [key]: {
              ...entityResult[key],
              [key2]: entityInstanceList.slice().filter((e) => e.id !== deletedEntityInstance.id),
            },
          },
        };

        // Since the TS compiler only knows the shape of the TEntity type we need to cast to any here
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cache.writeQuery(writeQueryConfiguration as any);
      }
    } else {
      const entityInstanceList = entityResult[key] as E[];

      // Prepare the cache write configuration
      const writeQueryConfiguration = {
        query: document,
        variables: variables,
        data: {
          [key]: entityInstanceList.slice().filter((e) => e.id !== deletedEntityInstance.id),
        },
      };

      // Since the TS compiler only knows the shape of the TEntity type we need to cast to any here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cache.writeQuery(writeQueryConfiguration as any);
    }
  }

  // Update the object that was the owner of that instance
  handleDeleteInstanceFromOwner({
    cache: cache,
    instance: deletedEntityInstance,
    ownerId: deletedEntityInstance.ownerId,
    ownerType: deletedEntityInstance.ownerType,
    entityInstanceTypename: typename,
  });
}
