import { type AsChildProps, Slot } from "@/components/slot.tsx";
import { useDeleteLabelInstance } from "@/hooks/api/common/useLabel/useDeleteLabelnstance";

type OwnerType = "CLIMATE_ACTION";

type RemoveLabelFromEntityActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  labelInstance: {
    id: string;
  };
  owner: {
    id: string;
    type: OwnerType;
  };
  onSuccess?: () => void;
  onError?: () => void;
};

export const RemoveLabelFromEntityAction = ({
  labelInstance,
  onSuccess,
  onError,
  asChild,
  ...props
}: RemoveLabelFromEntityActionProps) => {
  const Comp = asChild ? Slot : "button";

  const { deleteLabelInstance } = useDeleteLabelInstance({
    onCompleted: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      if (onError) {
        onError();
      }
    },
  });

  async function handleClick() {
    await deleteLabelInstance({
      variables: { input: { id: labelInstance.id } },
    });
  }

  return <Comp {...props} onClick={handleClick} />;
};
