import { graphql } from "@/graphql/generated/gql";

// ============== QUERIES ======================
export const getSavedViewsByUrlQueryDocument = graphql(`
  query GetSavedViewsByUrl($input: GetSavedViewsByUrlInput!) {
    savedViews(getSavedViewsByUrlInput: $input) {
      id
      userId
      workspaceId
      url
      name
      tableConfiguration
      isBaseView
      isDefault
    }
  }
`);

export const getSavedViewListForWorkspaceQueryDocument = graphql(`
  query GetSavedViewListForWorkspace($input: GetSavedViewsForWorkspaceInput!) {
    savedViewListForWorkspace(getSavedViewsForWorkspaceInput: $input) {
      id
      userId
      workspaceId
      url
      name
      tableConfiguration
      isBaseView
      isDefault
    }
  }
`);

// ============== MUTATIONS ======================
export const createSavedViewMutationDocument = graphql(`
  mutation CreateSavedView($input: CreateSavedViewInput!) {
    createSavedView(createSavedViewInput: $input) {
      id
      userId
      workspaceId
      url
      name
      tableConfiguration
      isBaseView
      isDefault
    }
  }
`);

export const updateSavedViewMutationDocument = graphql(`
  mutation UpdateSavedView($input: UpdateSavedViewInput!) {
    updateSavedView(updateSavedViewInput: $input) {
      id
      userId
      workspaceId
      url
      name
      tableConfiguration
      isBaseView
      isDefault
    }
  }
`);

export const deleteSavedViewMutationDocument = graphql(`
  mutation DeleteSavedView($input: DeleteSavedViewInput!) {
    deleteSavedView(input: $input) {
      id
      userId
      workspaceId
      url
      name
      tableConfiguration
      isBaseView
      isDefault
    }
  }
`);
