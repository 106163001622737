import { createContext, type FC, type PropsWithChildren, useContext } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useGetFocusArea } from "@/hooks/api/common/useFocusArea";

import { type FocusArea } from "../models/focus-area-schema";

type CurrentFocusAreaContext = {
  currentFocusArea: FocusArea;
};

const CurrentFocusAreaContext = createContext<CurrentFocusAreaContext | null>(null);

export interface CurrentFocusAreaContextProviderProps extends PropsWithChildren {
  focusAreaId: string;
}

export const CurrentFocusAreaContextProvider: FC<CurrentFocusAreaContextProviderProps> = ({
  children,
  focusAreaId,
}) => {
  const { focusArea, loading, error } = useGetFocusArea({ id: focusAreaId });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  if (!focusArea) {
    return <ErrorView />;
  }

  return (
    <CurrentFocusAreaContext.Provider value={{ currentFocusArea: focusArea }}>
      {children}
    </CurrentFocusAreaContext.Provider>
  );
};

export const useCurrentFocusAreaContext = () => {
  const context = useContext(CurrentFocusAreaContext);

  if (!context) {
    throw new Error("useCurrentFocusAreaContext must be used within a CurrentFocusAreaContextProvider");
  }

  return context;
};
