import { FC, PropsWithChildren } from "react";

import IconExclamationSolid from "@/components/_icons/IconExclamationSolid";

export interface ErrorViewProps extends PropsWithChildren {
  text?: string;
}

export const ErrorView: FC<ErrorViewProps> = ({ text, children }) => {
  return (
    <main className="align-center relative flex h-screen select-none justify-center overflow-auto">
      <div className="relative flex flex-col items-center self-center">
        <h4 className="relative flex grow items-stretch overflow-hidden align-middle text-sm">
          <IconExclamationSolid className="my-auto mr-2 h-3 w-3" />
          Fehler
        </h4>
        <p className="mt-4 text-center text-sm text-muted-foreground">
          {text
            ? text
            : "Etwas scheint schief gelaufen zu sein. Bitte laden Sie die Seite neu oder versuchen Sie es später erneut."}
        </p>
        {children && <div className="mt-4">{children}</div>}
      </div>
    </main>
  );
};
