import { deleteFocusAreaMutationDocument } from "@/graphql/common/focus-area";
import { type DeleteFocusAreaMutation } from "@/graphql/generated/graphql";
import { onGenericEntityDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_FOCUS_AREA_DELETED, trackFocusAreaEvent } from "./tracking";

export type DeletedFocusArea = DeleteFocusAreaMutation["deleteFocusArea"];

export interface UseDeleteFocusAreaProps {
  onCompleted?: (data: DeletedFocusArea) => void;
  onError?: (e: Error) => void;
}

export const useDeleteFocusArea = ({ onCompleted, onError }: UseDeleteFocusAreaProps) => {
  const [deleteFocusArea] = useMutation(deleteFocusAreaMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedFocusArea = data?.deleteFocusArea;
      if (!deletedFocusArea) {
        return;
      }

      onGenericEntityDelete({ cache, deletedEntity: deletedFocusArea, typename: "FocusArea" });
    },
  });

  function onUpdateCompleted(data: DeleteFocusAreaMutation) {
    if (onCompleted) {
      onCompleted(data.deleteFocusArea);
    }
    trackFocusAreaEvent(EVENT_FOCUS_AREA_DELETED, data.deleteFocusArea);
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteFocusArea,
  };
};
