import { FC, PropsWithChildren } from "react";

import IconDots from "@/components/_icons/IconDots";
import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { useToast } from "@/components/_ui/use-toast";
import {
  deleteWorkspaceInviteMutationDocument,
  leaveWorkspaceMembershipMutationDocument,
  resendWorkspaceInviteNotificationMutationDocument,
  updateWorkspaceMembershipRoleMutationDocument,
  updateWorkspaceMembershipStatusMutationDocument,
  workspaceMembershipListForWorkspaceQueryDocument,
} from "@/graphql/common/workspace-membership";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useWorkspaceMembershipDelete } from "@/hooks/api/superuser";
import { cn } from "@/lib/utils";
import { removeWorkspaceSettings } from "@/services/workspace.service";
import { useMutation } from "@apollo/client";

type WorkspaceMembershipPropType = {
  userId: string;
  workspaceId: string;
  status: string;
  role: string;
};

export interface WorkspaceMembershipActionButtonPropsWithChildren extends PropsWithChildren {
  workspaceMembership: WorkspaceMembershipPropType;
  disabled?: boolean;
  className?: string;
}
export const WorkspaceMembershipActionButton: FC<WorkspaceMembershipActionButtonPropsWithChildren> = ({
  className,
  disabled,
  workspaceMembership,
}) => {
  const { currentUser, isSuperUser } = useCurrentUserContext();
  const { toast } = useToast();

  const { deleteWorkspaceMembership } = useWorkspaceMembershipDelete({
    workspace: { id: workspaceMembership.workspaceId },
  });

  const [updateRole] = useMutation(updateWorkspaceMembershipRoleMutationDocument, {
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: workspaceMembershipListForWorkspaceQueryDocument,
        variables: {
          input: workspaceMembership.workspaceId,
        },
      },
    ],
  });

  const [updateStatus] = useMutation(updateWorkspaceMembershipStatusMutationDocument, {
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: workspaceMembershipListForWorkspaceQueryDocument,
        variables: {
          input: workspaceMembership.workspaceId,
        },
      },
    ],
  });

  const [leaveWorkspaceMembership] = useMutation(leaveWorkspaceMembershipMutationDocument, {
    onCompleted: () => {
      // We have lost access to this workspace. Let's do a complete reload
      removeWorkspaceSettings();
      window.location.replace(new URL(window.location.origin));
    },
    onError: () => {
      const isAdminAndSelf = currentUser?.id === workspaceMembership.userId && workspaceMembership.role === "ADMIN";

      const message = isAdminAndSelf
        ? "Leider ist ein Fehler aufgetreten. Stellen Sie sicher, dass Sie nicht der einzige Nutzer mit Admin Privilegien sind, bevor Sie den Workspace verlassen."
        : "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.";

      toast({
        title: "Fehler",
        description: message,
        variant: "error",
      });
    },
  });

  const [resendInvitationNotification] = useMutation(resendWorkspaceInviteNotificationMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Einladung gesendet",
        description: `Wir haben eine weitere Einladung gesendet.`,
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description: "Leider ist beim Versenden der Einladung ein Fehler aufgetreten.",
        variant: "error",
      });
    },
  });

  const [revokeInvitation] = useMutation(deleteWorkspaceInviteMutationDocument, {
    onError: () => {
      toast({
        title: "Fehler",
        description: "Leider ist beim Entfernen der Einladung ein Fehler aufgetreten.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: workspaceMembershipListForWorkspaceQueryDocument,
        variables: {
          input: workspaceMembership.workspaceId,
        },
      },
    ],
  });

  function renderDropdownMenuContent() {
    if (currentUser && currentUser.id === workspaceMembership.userId) {
      return renderDropdownContentForCurrentUser();
    }

    if (workspaceMembership.status === "ACTIVE") {
      const isAdmin = workspaceMembership.role === "ADMIN";
      return renderDropdownContentForActiveUser(isAdmin);
    }

    if (workspaceMembership.status === "SUSPENDED") {
      const isAdmin = workspaceMembership.role === "ADMIN";
      return renderDropdownContentSuspendedUser(isAdmin);
    }

    if (workspaceMembership.status === "LEFT") {
      const isAdmin = workspaceMembership.role === "ADMIN";
      return renderDropdownContentLeftUser(isAdmin);
    }

    if (workspaceMembership.status === "PENDING") {
      const isAdmin = workspaceMembership.role === "ADMIN";
      return renderDropdownContentPendingUser(isAdmin);
    }

    return <></>;
  }

  function renderDropdownContentForCurrentUser() {
    return <DropdownMenuItem onClick={() => handleLeaveWorkspace()}>Workspace verlassen</DropdownMenuItem>;
  }

  function renderDropdownContentForActiveUser(isAdmin: boolean) {
    return (
      <>
        <DropdownMenuItem onClick={() => updateStatusTo("SUSPENDED")}>Account deaktivieren</DropdownMenuItem>
        {isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>}
        {!isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>}
      </>
    );
  }

  function renderDropdownContentSuspendedUser(isAdmin: boolean) {
    return (
      <>
        <DropdownMenuItem onClick={() => updateStatusTo("ACTIVE")}>Account aktivieren</DropdownMenuItem>
        {isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>}
        {!isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>}
      </>
    );
  }

  function renderDropdownContentLeftUser(isAdmin: boolean) {
    return (
      <>
        <DropdownMenuItem onClick={() => updateStatusTo("SUSPENDED")}>Account deaktivieren</DropdownMenuItem>
        {isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>}
        {!isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>}
      </>
    );
  }

  function renderDropdownContentPendingUser(isAdmin: boolean) {
    return (
      <>
        <DropdownMenuItem onClick={() => handleResendNotification()}>E-mail erneut senden</DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleRevokeInvite()}>Einladung zurückziehen</DropdownMenuItem>
        {isSuperUser && (
          <>
            {isAdmin && <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>}
            {!isAdmin && (
              <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>
            )}
          </>
        )}
      </>
    );
  }

  function updateRoleTo(to: "ADMIN" | "USER") {
    updateRole({
      variables: {
        input: {
          userId: workspaceMembership.userId,
          workspaceId: workspaceMembership.workspaceId,
          role: to,
        },
      },
    });
  }

  function updateStatusTo(to: "ACTIVE" | "SUSPENDED") {
    updateStatus({
      variables: {
        input: {
          userId: workspaceMembership.userId,
          workspaceId: workspaceMembership.workspaceId,
          status: to,
        },
      },
    });
  }

  function handleLeaveWorkspace() {
    leaveWorkspaceMembership({
      variables: {
        input: {
          workspaceId: workspaceMembership.workspaceId,
        },
      },
    });
  }

  function handleResendNotification() {
    resendInvitationNotification({
      variables: {
        input: {
          userId: workspaceMembership.userId,
          workspaceId: workspaceMembership.workspaceId,
        },
      },
    });
  }

  function handleRevokeInvite() {
    revokeInvitation({
      variables: {
        input: {
          userId: workspaceMembership.userId,
          workspaceId: workspaceMembership.workspaceId,
        },
      },
    });
  }

  function handleDeleteMembership() {
    if (!isSuperUser) {
      return;
    }

    deleteWorkspaceMembership({
      variables: {
        input: {
          userId: workspaceMembership.userId,
          workspaceId: workspaceMembership.workspaceId,
        },
      },
    });
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className={cn("", className)} disabled={disabled}>
          <IconDots />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <>
          {renderDropdownMenuContent()}
          {isSuperUser && currentUser && currentUser.id === workspaceMembership.userId && (
            <>
              <DropdownMenuItem onClick={() => handleDeleteMembership()}>Mitgliedschaft löschen</DropdownMenuItem>
              {workspaceMembership.role === "ADMIN" && (
                <DropdownMenuItem onClick={() => updateRoleTo("USER")}>Adminrechte entziehen</DropdownMenuItem>
              )}
              {workspaceMembership.role !== "ADMIN" && (
                <DropdownMenuItem onClick={() => updateRoleTo("ADMIN")}>Adminrechte vergeben</DropdownMenuItem>
              )}
            </>
          )}
        </>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default WorkspaceMembershipActionButton;
