import { updateLabelMutationDocument } from "@/graphql/common/label";
import { getLabelGroupListForWorkspaceQueryDocument, getLabelGroupQueryDocument } from "@/graphql/common/label-group";
import { GetLabelGroupListForWorkspaceQuery, type UpdateLabelMutation } from "@/graphql/generated/graphql";
import { useMutation } from "@apollo/client";

import { EVENT_LABEL_UPDATED, trackLabelEvent } from "./tracking";

export type UpdatedLabel = UpdateLabelMutation["updateLabel"];

export interface UseUpdateLabelProps {
  onCompleted?: (data: UpdatedLabel) => void;
  onError?: (e: Error) => void;
}

export const useUpdateLabel = ({ onCompleted, onError }: UseUpdateLabelProps = {}) => {
  const [updateLabel] = useMutation(updateLabelMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const updatedLabel = data?.updateLabel;
      if (!updatedLabel) {
        return;
      }

      // Note: We only have to do this here because of the label - labelGroup relationship
      // Therefore, we didn't need to extract it out into a generic cache update function

      const labelGroupListQueryResult = cache.readQuery<GetLabelGroupListForWorkspaceQuery>({
        query: getLabelGroupListForWorkspaceQueryDocument,
        variables: { input: { workspaceId: updatedLabel.workspaceId } },
      });

      if (!labelGroupListQueryResult) {
        return;
      }

      const labelGroupList = labelGroupListQueryResult.labelGroupsForWorkspace;

      if (!labelGroupList) {
        return;
      }

      // 2. Now let's try to identify which entities the instance was removed from
      // -------------------------------------------------------------------
      const oldLabelGroup = labelGroupList.find(
        (labelGroup) => labelGroup.labels.find((label) => label.id === updatedLabel.id), // find the labelGroup that has the updated label in its labels array
      );

      if (oldLabelGroup) {
        const writeObject = {
          query: getLabelGroupQueryDocument,
          variables: { input: { id: oldLabelGroup.id } },
          data: {
            labelGroup: {
              ...oldLabelGroup,
              labels: oldLabelGroup.labels.slice().filter((l) => l.id !== updatedLabel.id),
            },
          },
        };
        cache.writeQuery(writeObject);
      }

      // 3. Now let's try to identify which entities the instance was added to
      // -------------------------------------------------------------------   const config = getInstanceQueryConfigForType(typename, newEntity);
      const newLabelGroup = labelGroupList.find((labelGroup) => labelGroup.id === updatedLabel.labelGroupId);

      if (newLabelGroup) {
        const writeObject = {
          query: getLabelGroupQueryDocument,
          variables: { input: { id: newLabelGroup.id } },
          data: {
            labelGroup: {
              ...newLabelGroup,
              labels: [...newLabelGroup.labels, updatedLabel],
            },
          },
        };
        cache.writeQuery(writeObject);
      }

      trackLabelEvent(EVENT_LABEL_UPDATED, updatedLabel);
    },
  });

  function onUpdateCompleted(data: UpdateLabelMutation) {
    if (onCompleted) {
      onCompleted(data.updateLabel);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }
  return {
    updateLabel,
  };
};
