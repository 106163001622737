import { useLocation, useSearchParams } from "react-router-dom";

import { ClimateActionListOverview } from "@/components/_domain/climate-action/climate-action-list-overview";
// Actual Page
import { ClimateActionListConfigurationView } from "@/components/_domain/climate-action/climate-action-list-overview/climate-action-list-configuration-pane-view";
import { ClimateActionListFilterView } from "@/components/_domain/climate-action/climate-action-list-overview/climate-action-list-filter-pane-view";
import { ClimateActionListProviderStackInitializer } from "@/components/_domain/climate-action/climate-action-list-overview/utils/climate-action-list-provider-stack-initializer";
import { useCurrentClimateActionListContext } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
// Page Context
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

import { useTableConfigurationWorkspaceClimateActionPage } from "./useTableConfigurationWorkspaceClimateActionPage";

export const ClimateActionListAllPage = () => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { currentUser } = useCurrentUserContext();
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentClimateActionList } = useCurrentClimateActionListContext();

  const defaultTableConfig = useTableConfigurationWorkspaceClimateActionPage({
    workspace: currentWorkspace,
  });

  return (
    <ClimateActionListProviderStackInitializer
      pathname={pathname}
      searchParams={searchParams}
      workspace={currentWorkspace}
      user={currentUser}
      climateActionList={currentClimateActionList}
      config={defaultTableConfig}
    >
      <div className="flex h-full max-h-full flex-col overflow-y-hidden">
        <ClimateActionListConfigurationView />
        <ClimateActionListFilterView />
        <ClimateActionListOverview />
      </div>
    </ClimateActionListProviderStackInitializer>
  );
};
