import { useCurrentClimateActionListContext } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { DashboardOverview } from "@/components/_domain/dashboard/dashboard-overview";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";

export const ClimateActionListDashboardPage = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentClimateActionList } = useCurrentClimateActionListContext();

  const navigateToUrl = `/${currentWorkspace.workspaceUrl}/climate-actions/all`;

  return (
    <DashboardOverview
      workspace={currentWorkspace}
      climateActionList={currentClimateActionList}
      navigateToUrl={navigateToUrl}
    />
  );
};
