import { useState } from "react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/_ui/card";
import { posthogService } from "@/services/posthog.service";
import { ResponsivePie } from "@nivo/pie";

interface PieChartProps {
  data: Record<string, number>;
  navigateToClimateActionListView: (searchParams?: string | null | undefined) => void;
}

interface TimeRangeKey {
  label: string;
  months: number;
}

export const VALID_KEYS: Record<string, TimeRangeKey> = {
  LESS_THAN_3_MONTHS: {
    label: "< 3 Monate",
    months: 3,
  },
  LESS_THAN_6_MONTHS: {
    label: "< 6 Monate",
    months: 6,
  },
  LESS_THAN_12_MONTHS: {
    label: "< 12 Monate",
    months: 12,
  },
  MORE_THAN_12_MONTHS: {
    label: "> 12 Monate",
    months: 12,
  },
};

const PIE_CHART_CLICKED = "PIE_CHART:CLICKED";

export const mapKeyToTimeRange = (key: string): string => {
  const timeRangeKey = Object.entries(VALID_KEYS).find(([_, value]) => value.label === key)?.[0];
  if (!timeRangeKey) return "";

  const cutOffDate = new Date();
  const months = VALID_KEYS[timeRangeKey].months;
  cutOffDate.setMonth(cutOffDate.getMonth() - months);
  if (key.includes(">")) {
    return `before:${cutOffDate.toISOString().split("T")[0]}`;
  }
  return `after:${cutOffDate.toISOString().split("T")[0]}`;
};

export const PieChart = ({ data, navigateToClimateActionListView }: PieChartProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);

  const handleSectionClick = (period: string) => {
    const mappedPeriod = mapKeyToTimeRange(period);
    navigateToClimateActionListView(`lastUpdated=${encodeURIComponent(mappedPeriod)}`);
    posthogService.capture(PIE_CHART_CLICKED, {
      lastUpdated: period,
    });
  };

  const chartData = [
    {
      id: VALID_KEYS.LESS_THAN_3_MONTHS.label,
      label: VALID_KEYS.LESS_THAN_3_MONTHS.label,
      value: data[VALID_KEYS.LESS_THAN_3_MONTHS.label] || 0,
      color: "#4F46E5",
    },
    {
      id: VALID_KEYS.LESS_THAN_6_MONTHS.label,
      label: VALID_KEYS.LESS_THAN_6_MONTHS.label,
      value: data[VALID_KEYS.LESS_THAN_6_MONTHS.label] || 0,
      color: "#E0E7FF",
    },
    {
      id: VALID_KEYS.LESS_THAN_12_MONTHS.label,
      label: VALID_KEYS.LESS_THAN_12_MONTHS.label,
      value: data[VALID_KEYS.LESS_THAN_12_MONTHS.label] || 0,
      color: "#818CF8",
    },
    {
      id: VALID_KEYS.MORE_THAN_12_MONTHS.label,
      label: VALID_KEYS.MORE_THAN_12_MONTHS.label,
      value: data[VALID_KEYS.MORE_THAN_12_MONTHS.label] || 0,
      color: "#A5B4FC",
    },
  ];

  return (
    <Card className="flex flex-col rounded-lg">
      <CardHeader className="pb-0">
        <CardTitle className="text-sm font-normal">Maßnahmen nach Aktualisierungsgrad</CardTitle>
        <div className="mt-2 border-t border-gray-200"></div>
      </CardHeader>
      <CardContent className="flex-1 p-0 pb-3">
        <div className="relative mx-auto my-2 flex h-[180px] w-full items-center justify-center fill-current">
          <ResponsivePie
            data={chartData}
            margin={{ top: 20, right: 150, bottom: 20, left: 20 }}
            innerRadius={0}
            padAngle={0}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            colors={{ datum: "data.color" }}
            borderWidth={0}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            activeId={activeId}
            role="button"
            onClick={(event) => {
              handleSectionClick(event.id as string);
            }}
            onMouseMove={({ id }) => setActiveId(id as string)}
            onMouseLeave={() => setActiveId(null)}
            tooltip={({ datum }) => (
              <div className="rounded bg-black/80 p-2 text-sm text-white">
                <p>
                  {datum.label}: {datum.value}
                </p>
              </div>
            )}
            legends={[
              {
                anchor: "right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 10,
                itemWidth: 73,
                itemHeight: 18,
                itemTextColor: "text-foreground",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                onMouseEnter: (datum) => {
                  setActiveId(datum.id as string);
                },
                onMouseLeave: () => {
                  setActiveId(null);
                },
                onClick: (event) => {
                  handleSectionClick(event.id as string);
                },
              },
            ]}
          />
        </div>
      </CardContent>
    </Card>
  );
};
