import { type DeleteSavedViewMutation } from "@/graphql/generated/graphql";
import { useMutation } from "@apollo/client";

import {
  deleteSavedViewMutationDocument,
  getSavedViewListForWorkspaceQueryDocument,
  getSavedViewsByUrlQueryDocument,
} from "./saved-view.gql";

export type DeletedSavedView = DeleteSavedViewMutation["deleteSavedView"];

interface UseDeleteSavedViewProps {
  onCompleted?: (data: DeletedSavedView) => void;
  onError?: (e: Error) => void;
}

export const useDeleteSavedView = ({ onCompleted, onError }: UseDeleteSavedViewProps = {}) => {
  const [deleteSavedViewMutation, { data, loading, error }] = useMutation(deleteSavedViewMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedSavedView = data?.deleteSavedView;

      if (!deletedSavedView) {
        return;
      }

      // 1. Update all the saved views for the workspace
      // --------------------------------------------------------
      const cachedSavedViewForWorkspaceQuery = cache.readQuery({
        query: getSavedViewListForWorkspaceQueryDocument,
        variables: { input: { workspaceId: deletedSavedView.workspaceId } },
      });

      if (cachedSavedViewForWorkspaceQuery?.savedViewListForWorkspace) {
        cache.writeQuery({
          query: getSavedViewListForWorkspaceQueryDocument,
          variables: { input: { workspaceId: deletedSavedView.workspaceId } },
          data: {
            savedViewListForWorkspace: cachedSavedViewForWorkspaceQuery.savedViewListForWorkspace
              .slice()
              .filter((savedView) => savedView.id !== deletedSavedView.id),
          },
        });
      }

      // 2. Then update all the saved views for the specific URL
      // --------------------------------------------------------
      const cachedSavedViewByUrlQuery = cache.readQuery({
        query: getSavedViewsByUrlQueryDocument,
        variables: { input: { url: deletedSavedView.url, workspaceId: deletedSavedView.workspaceId } },
      });

      if (cachedSavedViewByUrlQuery?.savedViews) {
        cache.writeQuery({
          query: getSavedViewsByUrlQueryDocument,
          variables: { input: { url: deletedSavedView.url, workspaceId: deletedSavedView.workspaceId } },
          data: {
            savedViews: cachedSavedViewByUrlQuery.savedViews
              .slice()
              .filter((savedView) => savedView.id !== deletedSavedView.id),
          },
        });
      }
    },
  });

  function onUpdateCompleted(data: DeleteSavedViewMutation) {
    if (onCompleted) {
      onCompleted(data.deleteSavedView);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteSavedViewMutation,
    data,
    loading,
    error,
  };
};
