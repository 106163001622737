import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getPhaseListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query GetPhaseListForWorkspace($input: GetPhaseListForWorkspaceInput!) {
    phaseListForWorkspace(getPhaseListForWorkspaceInput: $input) {
      id
      workspaceId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

export const getPhaseQueryDocument = graphql(/* GraphQL */ `
  query GetPhase($input: GetPhaseInput!) {
    phase(getPhaseInput: $input) {
      id
      workspaceId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createPhaseMutationDocument = graphql(/* GraphQL */ `
  mutation createPhase($input: CreatePhaseInput!) {
    createPhase(createPhaseInput: $input) {
      id
      workspaceId
      name
      color
      type
    }
  }
`);

export const updatePhaseMutationDocument = graphql(/* GraphQL */ `
  mutation UpdatePhase($input: UpdatePhaseInput!) {
    updatePhase(updatePhaseInput: $input) {
      id
      workspaceId
      name
      color
      type
    }
  }
`);

export const deletePhaseMutationDocument = graphql(/* GraphQL */ `
  mutation DeletePhase($input: DeletePhaseInput!) {
    deletePhase(deletePhaseInput: $input) {
      id
      workspaceId
      name
      color
      type
    }
  }
`);
