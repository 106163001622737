import { FC } from "react";

import IconCircleCanceled from "@/components/_icons/IconCircleCanceled";
import IconCircleChecked from "@/components/_icons/IconCircleChecked";
import IconCircleDashed from "@/components/_icons/IconCircleDashed";
import IconCircleFilledHalf from "@/components/_icons/IconCircleFilledHalf";
import IconCircleOutline from "@/components/_icons/IconCircleOutline";

import { PhaseList } from "./phase-type";

export interface PhaseIconProps {
  phase: {
    color: string;
    type: string;
  };
  className?: string;
}

export const PhaseIcon: FC<PhaseIconProps> = ({ phase, className }) => {
  if (phase.type === PhaseList.backlog) {
    return <IconCircleDashed className={className} style={{ color: phase.color }} />;
  }
  if (phase.type === PhaseList.unstarted) {
    return <IconCircleOutline className={className} style={{ color: phase.color }} />;
  }
  if (phase.type === PhaseList.started) {
    return <IconCircleFilledHalf className={className} style={{ color: phase.color }} />;
  }
  if (phase.type === PhaseList.completed) {
    return <IconCircleChecked className={className} style={{ color: phase.color }} />;
  }
  if (phase.type === PhaseList.canceled) {
    return <IconCircleCanceled className={className} style={{ color: phase.color }} />;
  }

  // return the "unstarted" icon by default
  return <IconCircleOutline className={className} style={{ color: phase.color }} />;
};

export default PhaseIcon;
