import { ClimateAction } from "@/components/_domain/climate-action/models";
import { sortPhase } from "@/components/_domain/phase/phase-type";
import { ColumnDef } from "@tanstack/react-table";

import { ClimateActionListColumnHeader, ClimateActionListPhaseCell } from "../..";
import { filterByTextListWithUnknownPayload } from "../../filter-functions";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import {
  FilterByPhaseConfigurationView,
  FilterByPhaseDisplayViewProps,
  FilterByPhaseFilterPaneView,
} from "./components";

interface PhaseColumnDefinitionProps {
  columnId: string;
  orderValue: number;
}

export const PhaseColumnDefinition = ({
  columnId,
  orderValue,
}: PhaseColumnDefinitionProps): ClimateActionColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Phase",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<ClimateAction> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          return sortPhase(rowA.original.phaseInstance?.phase?.type, rowB.original.phaseInstance?.phase?.type);
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          return filterByTextListWithUnknownPayload(value, row.original.phaseInstance?.phase?.name);
        },
        size: 1, // set to 1 instead to 0 for it to work in Safari
        header: ({ column }) => (
          <ClimateActionListColumnHeader column={column} title="" className={`px-1 text-xs font-normal`} />
        ),
        cell: ({ row }) => <ClimateActionListPhaseCell row={row} climateAction={row.original} />,
      };
    },

    // configure the specific ReactViews we need
    getDisplayView: () => <FilterByPhaseDisplayViewProps />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByPhaseConfigurationView column={{ id: columnId }} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "popover",
    getFilterPaneView: () => <FilterByPhaseFilterPaneView column={{ id: columnId }} />,
  };
};
