import * as z from "zod";

import { TaskStatus } from "../task-type";

export const taskCreateFormSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  status: z.nativeEnum(TaskStatus),
  position: z.number().default(0),
});

export type TaskCreateFormType = z.infer<typeof taskCreateFormSchema>;
