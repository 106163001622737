// Note: I wouldn't use enums in the future. Because of them we have to constantly cast tasks from the API to the local Task type
export enum TaskStatus {
  Backlog = "BACKLOG",
  Todo = "TODO",
  Doing = "DOING",
  Done = "DONE",
  Cancelled = "CANCELLED",
}

export const TaskStatusLabels = {
  [TaskStatus.Backlog]: "Backlog",
  [TaskStatus.Todo]: "Zu tun",
  [TaskStatus.Doing]: "In Arbeit",
  [TaskStatus.Done]: "Erledigt",
  [TaskStatus.Cancelled]: "Abgebrochen",
};

export type Task = {
  id: string;
  title: string;
  description?: string | null | undefined;
  status: TaskStatus;
  position: number;
  createdAt: string;
  updatedAt: string;
  userId: string;
};

export const sortTasksByStatus = (a?: string, b?: string): 0 | 1 | -1 => {
  const numberFromType = (status?: string) => {
    if (status === TaskStatus.Backlog) return 0;
    if (status === TaskStatus.Todo) return 1;
    if (status === TaskStatus.Doing) return 2;
    if (status === TaskStatus.Done) return 3;
    if (status === TaskStatus.Cancelled) return 4;
    return -1;
  };

  const numA = numberFromType(a);
  const numB = numberFromType(b);

  if (numA - numB > 0) {
    return 1;
  }
  if (numA - numB < 0) {
    return -1;
  }

  return 0;
};

export type NewTask = Omit<Task, "id" | "createdAt" | "updatedAt" | "userId"> & { description?: string | undefined };
