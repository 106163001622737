import { useMemo } from "react";

import { getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { useQuery } from "@apollo/client";

export interface UseGetClimateActionProps {
  id?: string;
  enabled?: boolean;
}

export const useGetClimateAction = ({ id, enabled = true }: UseGetClimateActionProps) => {
  const queryResult = useQuery(getClimateActionQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id || !enabled,
  });

  const climateAction = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    // Every time a climate action changes thus useMemo runs 6x times
    // TODO: Investigate why this is the case
    // console.log("Updated Climate Action", queryResult.data);

    return queryResult.data.climateAction;
  }, [queryResult.data]);

  return {
    queryResult,
    loading: queryResult.loading,
    error: queryResult.error,
    climateAction,
  };
};
