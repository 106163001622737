import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import IconUpload from "@/components/_icons/IconUpload";
import { cn } from "@/lib/utils";

const baseStyle = {};

const focusedStyle = {};

const acceptStyle = {};

const rejectStyle = {};

export interface InputFileProps extends PropsWithChildren {
  className?: string;
  readOnly?: boolean;
  open?: boolean;
  fileList?: File[];
  onOpenChange?: (open: boolean) => void;
  onChange: (acceptedFiles: File[]) => void;
}

export const InputFile: FC<InputFileProps> = ({ className, onChange, children, ...props }) => {
  const [readOnly] = useState(!!props.readOnly);
  const [fileList, setFileList] = useState<File[]>(props.fileList ?? []);

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    isFileDialogActive,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const style = useMemo(() => {
    return {
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };
  }, [isFocused, isDragAccept, isDragReject]);

  useEffect(() => {
    if (props.open) {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (props.onOpenChange) {
      props.onOpenChange(isFileDialogActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileDialogActive]);

  useEffect(() => {
    if (isFileDialogActive) {
      return;
    }
    setFileList(acceptedFiles);
  }, [acceptedFiles, isFileDialogActive]);

  useEffect(() => {
    onChange(fileList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  if (readOnly) {
    <div className={className} style={{ ...style }}>
      {children}
    </div>;
  }

  return (
    <div className={cn(["relative", className])} {...getRootProps({ style })}>
      <input {...getInputProps()}></input>
      {children}

      {isDragActive && (
        <div className="absolute inset-0 flex h-full w-full flex-row items-center justify-center gap-2 bg-background/80 text-center">
          <div className="">
            <IconUpload />
          </div>
          <div className="text-sm">Datei hochladen</div>
        </div>
      )}
    </div>
  );
};
