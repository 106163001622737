import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import * as z from "zod";

import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";
import { Badge } from "@/components/_ui/badge";
import { Button } from "@/components/_ui/button";
import { Switch } from "@/components/_ui/switch";
import { toast } from "@/components/_ui/use-toast";
import { RichTextEditor } from "@/components/rich-text-editor";
import { useCreateClimateAction } from "@/hooks/api/common/useClimateAction/useCreateClimateAction";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export interface ClimateActionCreateFormProps {
  className?: string;
  workspace: {
    id: string;
    name: string;
    workspaceUrl: string;
    profilePicture?: string;
  };
  team?: {
    id: string;
  };
  triggerCloseDialog: () => void;
}

export const ClimateActionCreateForm: FC<ClimateActionCreateFormProps> = ({ workspace, team, triggerCloseDialog }) => {
  const [loading, setLoading] = useState(false);
  const [removedFromDom, setRemovedFromDom] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });
  const { createClimateAction } = useCreateClimateAction({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });
  const [createMore, setCreateMore] = useState(false);

  async function onRichTextEditorChange(value: string, json: string) {
    if (value === "") {
      form.setValue("description", "");
    } else {
      form.setValue("description", json);
    }
  }

  async function handleCreateClimateAction() {
    if (loading) {
      return;
    }
    const { title, description } = form.getValues();

    if (!title || title.length < 1) {
      toast({
        title: "Fehler",
        description: "Der Name der Maßnahme darf nicht leer sein. Bitte geben Sie einen Namen ein.",
        variant: "error",
      });
      return;
    }

    setLoading(true);
    await createClimateAction({
      variables: {
        input: {
          workspaceId: workspace.id,
          title: title,
          properties: description,
          teamIdList: team?.id ? [team.id] : [],
        },
      },
    });
  }

  function onUpdateCompleted(climateActionFragment: { id: string } | undefined) {
    setLoading(false);
    const { title } = form.getValues();

    const url = `${workspace.workspaceUrl}/climate-actions/${climateActionFragment?.id}`;

    toast({
      title: "Maßnahme erstellt",
      description: (
        <div className="gap-21 flex flex-col">
          <span>{title} wurde erstellt</span>
          {!!climateActionFragment && <Link to={url}>Jetzt anzeigen</Link>}
        </div>
      ),
      variant: "success",
    });

    form.reset();
    forceRichTextEditorRerender();

    if (!createMore) {
      triggerCloseDialog();
    } else {
      form.setFocus("title");
    }
  }

  function onUpdateError() {
    setLoading(false);
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  function forceRichTextEditorRerender() {
    setRemovedFromDom(true);
    setTimeout(() => {
      setRemovedFromDom(false);
    }, 1);
  }

  return (
    <form>
      <div className="grid w-full">
        {/* Header */}
        <div className="flex flex-row items-center gap-1">
          <Badge variant="outline" className="py-2 opacity-40">
            <div className="flex flex-row items-center gap-1">
              <AvatarWorkspace workspace={workspace} className="h-5 w-5 text-xxs" />
              <span className="ml-1 overflow-hidden text-ellipsis font-normal">{workspace.name}</span>
            </div>
          </Badge>
          <span className="ml-1 mr-1">{"›"}</span>
          <div className="block w-32 overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs">
            Neue Maßnahme
          </div>
        </div>
        {/* Main Part */}
        <div className="mx-auto max-h-[70vh] w-full overflow-y-scroll md:max-h-[40vh]">
          <TextareaAutosize
            autoFocus
            id="title"
            defaultValue={"Neue Maßnahme"}
            placeholder="Name der Maßnahme"
            className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent px-3 py-0 text-lg focus:outline-none"
            {...form.register("title")}
          />
          {!removedFromDom && <RichTextEditor onChange={onRichTextEditorChange} hideFloatingMenu={false} />}
        </div>
        <div className="border-b"></div>
        <div className="flex flex-row items-center justify-end gap-4 pt-2">
          <div>
            <div className="flex items-center space-x-1 text-sm text-muted-foreground">
              <Switch id="airplane-mode" checked={createMore} onCheckedChange={setCreateMore} />
              <span>Mehr erstellen</span>
            </div>
          </div>
          <Button type="button" size="sm" onClick={() => handleCreateClimateAction()}>
            Maßnahme erstellen
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ClimateActionCreateForm;
