import { FC } from "react";

import { useGetPhase } from "@/hooks/api/common/usePhase";
import { cn } from "@/lib/utils";

import { PhaseIcon } from "../phase-icon";

import PhaseViewEmpty from "./phase-view-empty";

interface PhaseViewProps {
  phase: {
    id: string;
  } | null;
  logoOnly?: boolean;
  className?: string;
}

export const PhaseView: FC<PhaseViewProps> = ({ ...props }) => {
  const { phase } = useGetPhase({ id: props.phase?.id });

  if (!phase) {
    return <PhaseViewEmpty {...props} data-testid="phase-view-empty" />;
  }

  return (
    <div className="flex flex-row items-center gap-2" data-testid="phase-view">
      <PhaseIcon phase={phase} className={cn(["h-4 w-4", props.className])} />
      {!props.logoOnly && <div>{phase.name}</div>}
    </div>
  );
};

export default PhaseView;
