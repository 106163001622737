import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "@/components/_ui/button";
import { Checkbox } from "@/components/_ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { useWorkspaceDuplicate } from "@/hooks/api/superuser";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";

import { WorkspaceBadge } from "../workspace-badge";
import { WorkspaceSelectDropdown } from "../workspace-select-dropdown";

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Der Name muss mindestens 1 Zeichen lang sein.",
  }),
  workspace_duplicateMembers: z.boolean().default(false).optional(),
  workspace_duplicateFocusAreas: z.boolean().default(false).optional(),
  workspace_duplicateTeams: z.boolean().default(false).optional(),
  workspace_duplicatePriorityList: z.boolean().default(false).optional(),
  workspace_duplicatePhaseList: z.boolean().default(false).optional(),
  team_includeMembers: z.boolean().default(false).optional(),
  team_includeLabelSettings: z.boolean().default(false).optional(),
  team_includeClimateActions: z.boolean().default(false).optional(),
  climateAction_includeKeyResult: z.boolean().default(false).optional(),
  climateAction_includeProgressSnapshots: z.boolean().default(false).optional(),
  climateAction_includeLabelAssignment: z.boolean().default(false).optional(),
  climateAction_includeMemberAssignment: z.boolean().default(false).optional(),
});

export interface WorkspaceDuplicateFormProps {
  workspace?: {
    id: string;
  };
  triggerCloseDialog: () => void;
}

export const WorkspaceDuplicateForm: FC<WorkspaceDuplicateFormProps> = ({ triggerCloseDialog, ...props }) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(props.workspace ? props.workspace : null);

  const [teamSettingsDisabled, setTeamSettingsDisabled] = useState(false);
  const [teamMembersDisabled, setTeamMembersDisabled] = useState(false);
  const [climateActionsDisabled, setClimateActionsDisabled] = useState(false);
  const [progressSnapshotDisabled, setProgressSnapshotDisabled] = useState(false);
  const [membersAssignmentDisabled, setMembersAssignmentDisabled] = useState(false);
  const [labelAssignmentDisabled, setLabelAssignmentDisabled] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      workspace_duplicateMembers: false,
      workspace_duplicateTeams: true,
      workspace_duplicateFocusAreas: true,
      workspace_duplicatePriorityList: true,
      workspace_duplicatePhaseList: true,
      team_includeMembers: false,
      team_includeLabelSettings: true,
      team_includeClimateActions: true,
      climateAction_includeKeyResult: true,
      climateAction_includeProgressSnapshots: false,
      climateAction_includeLabelAssignment: true,
      climateAction_includeMemberAssignment: false,
    },
  });
  const { duplicateWorkspace, queryResult } = useWorkspaceDuplicate({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  useEffect(() => {
    formValuesChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace]);

  const loading = useMemo(() => {
    return queryResult.loading;
  }, [queryResult]);

  function formValuesChanged() {
    const formValues = form.getValues();

    const disabledTeamSettings = !formValues.workspace_duplicateTeams;
    const disabledTeamMembers = disabledTeamSettings || !formValues.workspace_duplicateMembers;
    const disabledClimateActions = disabledTeamSettings || !formValues.team_includeClimateActions;
    const disabledMemberAssignment = disabledClimateActions || !formValues.team_includeMembers;
    const disabledProgressSnapshots = disabledClimateActions || disabledMemberAssignment;
    const disableLabelAssignment = disabledClimateActions || !formValues.team_includeLabelSettings;

    setTeamSettingsDisabled(disabledTeamSettings);
    setTeamMembersDisabled(disabledTeamMembers);
    setClimateActionsDisabled(disabledClimateActions);
    setMembersAssignmentDisabled(disabledMemberAssignment);
    setProgressSnapshotDisabled(disabledProgressSnapshots);
    setLabelAssignmentDisabled(disableLabelAssignment);

    if (disabledTeamSettings) {
      form.setValue("team_includeMembers", false);
      form.setValue("team_includeLabelSettings", false);
      form.setValue("team_includeClimateActions", false);
      form.setValue("climateAction_includeKeyResult", false);
      form.setValue("climateAction_includeProgressSnapshots", false);
      form.setValue("climateAction_includeLabelAssignment", false);
      form.setValue("climateAction_includeMemberAssignment", false);
    }

    if (disabledTeamMembers) {
      form.setValue("team_includeMembers", false);
      form.setValue("climateAction_includeMemberAssignment", false);
      form.setValue("climateAction_includeProgressSnapshots", false);
    }

    if (disabledClimateActions) {
      form.setValue("climateAction_includeKeyResult", false);
      form.setValue("climateAction_includeProgressSnapshots", false);
      form.setValue("climateAction_includeLabelAssignment", false);
      form.setValue("climateAction_includeMemberAssignment", false);
    }

    if (disabledMemberAssignment) {
      form.setValue("climateAction_includeProgressSnapshots", false);
      form.setValue("climateAction_includeMemberAssignment", false);
    }

    if (disabledProgressSnapshots) {
      form.setValue("climateAction_includeProgressSnapshots", false);
    }

    if (disableLabelAssignment) {
      form.setValue("climateAction_includeLabelAssignment", false);
    }
  }

  function onUpdateCompleted(_workspace: { id: string } | undefined) {
    toast({
      title: "Workspace erstellt",
      description: "Der Workspace wurde erfolgreich dupliziert.",
      variant: "success",
    });

    form.reset();
    triggerCloseDialog();
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    if (!selectedWorkspace) {
      return;
    }

    await duplicateWorkspace({
      variables: {
        input: {
          workspaceId: selectedWorkspace.id,
          name: values.name,
          workspace_duplicateMembers: !!values.workspace_duplicateMembers,
          workspace_duplicateFocusAreas: !!values.workspace_duplicateFocusAreas,
          workspace_duplicateTeams: !!values.workspace_duplicateTeams,
          workspace_duplicatePriorityList: !!values.workspace_duplicatePriorityList,
          workspace_duplicatePhaseList: !!values.workspace_duplicatePhaseList,
          team_includeMembers: !!values.team_includeMembers,
          team_includeLabelSettings: !!values.team_includeLabelSettings,
          team_includeClimateActions: !!values.team_includeClimateActions,
          climateAction_includeKeyResult: !!values.climateAction_includeKeyResult,
          climateAction_includeProgressSnapshots: !!values.climateAction_includeProgressSnapshots,
          climateAction_includeLabelAssignment: !!values.climateAction_includeLabelAssignment,
          climateAction_includeMemberAssignment: !!values.climateAction_includeMemberAssignment,
        },
      },
    });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} onChange={formValuesChanged} className={cn("space-y-6")}>
        <div className="grid w-full">
          {/* Header */}
          <div className="flex flex-row items-center gap-1">
            <div className="text-normal overflow-hidden truncate text-ellipsis whitespace-nowrap">
              Workspace Duplizieren
            </div>
          </div>
          {/* Main Part */}

          <div className="mx-auto mb-5 mt-3 flex w-full flex-col space-y-2 rounded border p-4">
            <div className=" text-sm font-medium leading-none">Welchen Workspace wollen Sie duplizieren?</div>
            <WorkspaceSelectDropdown selected={selectedWorkspace} onSelect={setSelectedWorkspace} side="bottom" asChild>
              <Button
                variant="ghost"
                size="sm"
                className="w-full justify-start overflow-x-hidden border pl-0 font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
              >
                {!selectedWorkspace && <div className="pl-2 text-sm">Wählen Sie einen Workspace aus</div>}
                {selectedWorkspace && (
                  <>
                    <WorkspaceBadge
                      workspace={selectedWorkspace}
                      className="h-4 w-4 border-none text-xs opacity-100 outline-none"
                    />
                  </>
                )}
              </Button>
            </WorkspaceSelectDropdown>

            <div className={selectedWorkspace ? "" : "pointer-disabled pointer-events-none opacity-40"}>
              <div className="mx-auto mb-5 mt-3 flex w-full flex-col">
                <FormField
                  control={form.control}
                  name="workspace_duplicateMembers"
                  render={({ field }) => (
                    <FormItem className="shadow flex flex-row items-center gap-2">
                      <FormControl>
                        <Checkbox className="mt-2" checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel className="-mt-2">Workspace Mitglieder duplizieren</FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="workspace_duplicateFocusAreas"
                  render={({ field }) => (
                    <FormItem className="shadow flex flex-row items-center gap-2">
                      <FormControl>
                        <Checkbox className="mt-2" checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel className="-mt-2">Handlungsfelder duplizieren</FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="workspace_duplicatePriorityList"
                  render={({ field }) => (
                    <FormItem className="shadow flex flex-row items-center gap-2">
                      <FormControl>
                        <Checkbox className="mt-2" checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel className="-mt-2">Prioritäten duplizieren</FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="workspace_duplicatePhaseList"
                  render={({ field }) => (
                    <FormItem className="shadow flex flex-row items-center gap-2">
                      <FormControl>
                        <Checkbox className="mt-2" checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel className="-mt-2">Phasen duplizieren</FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="workspace_duplicateTeams"
                  render={({ field }) => (
                    <FormItem className="shadow flex flex-row items-center gap-2">
                      <FormControl>
                        <Checkbox className="mt-2" checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel className="-mt-2">Teams duplizieren</FormLabel>
                    </FormItem>
                  )}
                />
              </div>

              <div className="mx-auto mb-5 mt-3 flex w-full flex-col">
                <div className={teamSettingsDisabled ? "pointer-disabled pointer-events-none opacity-40" : ""}>
                  <div className="text-sm font-medium text-muted-foreground">Team Einstellungen</div>
                  <div className="text-xs text-muted-foreground">
                    Einstellungen werden nur angewandt, wenn Teams dupliziert werden.
                  </div>
                  <FormField
                    control={form.control}
                    name="team_includeMembers"
                    render={({ field }) => (
                      <FormItem className="shadow flex flex-row items-center gap-2">
                        <FormControl>
                          <Checkbox
                            className="mt-2"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={teamMembersDisabled}
                          />
                        </FormControl>
                        <FormLabel className="-mt-2">
                          <span>Team Mitglieder duplizieren</span>
                          {teamMembersDisabled && (
                            <span className="text-xs font-normal text-muted-foreground">
                              {" "}
                              (Workspace Mitglieder benötigt)
                            </span>
                          )}
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="team_includeLabelSettings"
                    render={({ field }) => (
                      <FormItem className="shadow flex flex-row items-center gap-2">
                        <FormControl>
                          <Checkbox
                            className="mt-2"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={teamSettingsDisabled}
                          />
                        </FormControl>
                        <FormLabel className="-mt-2">Kategorisierungseinstellungen duplizieren</FormLabel>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="team_includeClimateActions"
                    render={({ field }) => (
                      <FormItem className="shadow flex flex-row items-center gap-2">
                        <FormControl>
                          <Checkbox
                            className="mt-2"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={teamSettingsDisabled}
                          />
                        </FormControl>
                        <FormLabel className="-mt-2">Maßnahmen duplizieren</FormLabel>
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mx-auto mb-5 mt-3 flex w-full flex-col">
                <div className={climateActionsDisabled ? "pointer-disabled pointer-events-none opacity-40" : ""}>
                  <div className="text-sm font-medium text-muted-foreground">Maßnahme Einstellungen</div>
                  <div className="text-xs text-muted-foreground">
                    Einstellungen werden nur angewandt, wenn Teams und Maßnahmen dupliziert werden.
                  </div>
                  <FormField
                    control={form.control}
                    name="climateAction_includeKeyResult"
                    render={({ field }) => (
                      <FormItem className="shadow flex flex-row items-center gap-2">
                        <FormControl>
                          <Checkbox
                            className="mt-2"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={climateActionsDisabled}
                          />
                        </FormControl>
                        <FormLabel className="-mt-2">
                          <>Maßnahme Indikatoren duplizieren</>
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="climateAction_includeProgressSnapshots"
                    render={({ field }) => (
                      <FormItem className="shadow flex flex-row items-center gap-2">
                        <FormControl>
                          <Checkbox
                            className="mt-2"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={progressSnapshotDisabled}
                          />
                        </FormControl>
                        <FormLabel className="-mt-2">
                          <>Fortschrittsberichte duplizieren</>
                          {progressSnapshotDisabled && (
                            <span className="text-xs font-normal text-muted-foreground">
                              {" "}
                              (Team Mitglieder benötigt)
                            </span>
                          )}
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="climateAction_includeLabelAssignment"
                    render={({ field }) => (
                      <FormItem className="shadow flex flex-row items-center gap-2">
                        <FormControl>
                          <Checkbox
                            className="mt-2"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={labelAssignmentDisabled}
                          />
                        </FormControl>
                        <FormLabel className="-mt-2">
                          Kategorisierung duplizieren
                          {labelAssignmentDisabled && (
                            <span className="text-xs font-normal text-muted-foreground">
                              {" "}
                              (Kategorisierungseinstellungen benötigt)
                            </span>
                          )}
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="climateAction_includeMemberAssignment"
                    render={({ field }) => (
                      <FormItem className="shadow flex flex-row items-center gap-2">
                        <FormControl>
                          <Checkbox
                            className="mt-2"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={membersAssignmentDisabled}
                          />
                        </FormControl>
                        <FormLabel className="-mt-2">
                          Mitglieder Zuweisung duplizieren
                          {membersAssignmentDisabled && (
                            <span className="text-xs font-normal text-muted-foreground">
                              {" "}
                              (Team Mitglieder benötigt)
                            </span>
                          )}
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={selectedWorkspace ? "" : "pointer-disabled pointer-events-none opacity-40"}>
            <div className="mx-auto mb-5 mt-3 flex w-full flex-col gap-3">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New Workspace Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        autoComplete="off"
                        className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                      />
                    </FormControl>
                    <FormMessage className="text-xs font-normal" />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-end gap-4 pt-2">
            <Button type="submit" size="sm" disabled={loading || !selectedWorkspace}>
              Workspace duplizieren
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};
