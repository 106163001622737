import { FC } from "react";

import IconLabelTag from "@/components/_icons/IconLabelTag";
import { cn } from "@/lib/utils";

export interface FilterByLabelDisplayViewProps {
  className?: string;
}

export const FilterByLabelDisplayView: FC<FilterByLabelDisplayViewProps> = ({ className }) => {
  return (
    <>
      <IconLabelTag className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">Label</span>
    </>
  );
};
