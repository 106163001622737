import { FC } from "react";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import { PhaseSelectDropdown } from "@/components/_domain/phase";
import { PhaseView } from "@/components/_domain/phase/phase-view";
import { Button } from "@/components/_ui/button";
import { useCreatePhaseInstance, useDeletePhaseInstance, useUpdatePhaseInstance } from "@/hooks/api/common/usePhase";
import { cn } from "@/lib/utils";
import { Row } from "@tanstack/react-table";

interface ClimateActionListPhaseCellProps {
  row: Row<ClimateAction>;
  climateAction: {
    id: string;
  };
  className?: string;
}

export const ClimateActionListPhaseCell: FC<ClimateActionListPhaseCellProps> = ({ row, className, ...props }) => {
  const climateAction = row.original;
  const phaseInstance = row.original.phaseInstance;
  const { updatePhaseInstance } = useUpdatePhaseInstance(); // always prefer this one
  const { createPhaseInstance } = useCreatePhaseInstance();
  const { deletePhaseInstance } = useDeletePhaseInstance();

  function assignPhase(phase: { id: string }) {
    if (!climateAction) {
      return;
    }

    if (phaseInstance) {
      updatePhaseInstance({
        variables: {
          input: {
            id: phaseInstance.id,
            phaseId: phase.id,
          },
        },
      });
    } else {
      createPhaseInstance({
        variables: {
          input: {
            phaseId: phase.id,
            ownerId: props.climateAction.id,
            ownerType: "CLIMATE_ACTION",
            workspaceId: climateAction.workspaceId,
          },
        },
      });
    }
  }

  function removePhase() {
    if (!phaseInstance) {
      return;
    }
    deletePhaseInstance({
      variables: { input: { id: phaseInstance.id } },
    });
  }

  const handleOnSelect = (phase: { id: string } | null) => {
    if (!phase) {
      return removePhase();
    }
    assignPhase(phase);
  };

  if (!climateAction) {
    return <></>;
  }

  return (
    <PhaseSelectDropdown
      workspaceId={climateAction.workspaceId}
      onSelect={handleOnSelect}
      selected={phaseInstance}
      side="bottom"
      asChild
    >
      <Button
        variant="ghost"
        size="icon"
        className={cn([
          "overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0",
          className,
        ])}
      >
        <PhaseView
          phase={phaseInstance ? { id: phaseInstance.phaseId } : null}
          logoOnly={true}
          className={cn(["h-3 w-3", phaseInstance ? "" : "text-muted-foreground"])}
          // className="h-[0.95rem] w-[0.95rem] p-[0.05rem] group-hover:h-4 group-hover:w-4 group-hover:p-0"
        />
      </Button>
    </PhaseSelectDropdown>
  );
};
