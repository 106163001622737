import { type FC, type ReactNode, useState } from "react";
import { Plus } from "lucide-react";

import { Button } from "@/components/_ui/button";
import { Dialog, DialogContentWithoutClose } from "@/components/_ui/dialog";
import { DeleteConfirmation } from "@/components/delete-confirmation";
import { Cross2Icon } from "@radix-ui/react-icons";

import { TaskStatus } from "../task-type";

import { TaskCreateForm } from "./task-create-form";
import { TaskCreateFormType } from "./task-create-form.schema";
import { useCreateTaskProvider } from "./use-create-task-provider";

export interface TaskCreateDialogProps {
  initialStatus?: TaskStatus;
  onSubmission?: (task: TaskCreateFormType) => void; // triggers before the submission
  onSuccess?: (taskId: string) => void;
  trigger?: ReactNode;
  showTrigger?: boolean;
}

export const TaskCreateDialog: FC<TaskCreateDialogProps> = ({
  initialStatus,
  onSubmission,
  onSuccess,
  trigger,
  showTrigger = true,
}) => {
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const { hasActiveChanges, resetNewTaskForm, showModal, setShowModal, updateNewTask } = useCreateTaskProvider();

  // Set initial status when opening the dialog
  const handleOpenDialog = () => {
    if (initialStatus) {
      updateNewTask({ status: initialStatus });
    }
    setShowModal(true);
  };

  // Handle dialog close with unsaved changes
  const handleDialogChange = (open: boolean, reset?: boolean) => {
    if (!open && reset === true) {
      // Could add a confirmation dialog here if needed
      resetNewTaskForm();
    }
    setShowModal(open);
  };

  // Default trigger button
  const defaultTrigger = (
    <Button
      variant="ghost"
      size="xs"
      className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
      onClick={handleOpenDialog}
    >
      <Plus className="mr-2 h-3.5 w-3.5" />
      New Task
      {hasActiveChanges && <div className="ml-1 h-1.5 w-1.5 rounded-full bg-primary"></div>}
    </Button>
  );

  // Custom trigger with onClick handler
  const customTrigger = trigger ? (
    <>
      {" "}
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
        onClick={() => setShowModal(true)}
      >
        Neue Aufgabe
        {hasActiveChanges && <div className="ml-1 h-1.5 w-1.5 rounded-full bg-primary"></div>}
      </Button>{" "}
    </>
  ) : null;

  return (
    <>
      {showTrigger && (customTrigger || defaultTrigger)}

      <Dialog open={showModal} onOpenChange={handleDialogChange}>
        <DialogContentWithoutClose className="top-[1%] max-w-3xl translate-y-[0%] p-0 md:top-[20%]">
          <TaskCreateForm onSuccess={onSuccess} onSubmission={onSubmission} className="pt-4" />

          <div
            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:cursor-pointer hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500 dark:ring-offset-slate-950 dark:focus:ring-slate-300 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-400"
            onClick={() => {
              if (hasActiveChanges) {
                setDeleteConfirmationisOpen(true);
              } else {
                resetNewTaskForm();
                setShowModal(false);
              }
            }}
          >
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </div>
        </DialogContentWithoutClose>
      </Dialog>

      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={() => {
          resetNewTaskForm();
          setShowModal(false);
        }}
        title="Aufgabe verwerfen?"
        description="Sind Sie sich sicher, dass sie diese Aufgabe verwerfen möchten?"
        confirmButtonText="Verwerfen"
        cancelButtonText="Abbrechen"
      />
    </>
  );
};
