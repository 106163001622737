import { toast } from "@/components/_ui/use-toast.ts";
import { type AsChildProps, Slot } from "@/components/slot.tsx";
import { useUpdateLabel } from "@/hooks/api/common/useLabel";

type UpdateLabelActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
    title: string;
    color: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
};

export const UpdateLabelAction = ({ label, onSuccess, onError, asChild, ...props }: UpdateLabelActionProps) => {
  const Comp = asChild ? Slot : "button";

  const { updateLabel } = useUpdateLabel({
    onCompleted: () => {
      toast({
        title: "Label aktualisiert",
        description: `Wir haben die Label erfolgreich aktualisiert.`,
        variant: "success",
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler bei der Aktualisierung der Label aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
      if (onError) {
        onError();
      }
    },
  });

  async function handleClick() {
    await updateLabel({ variables: { input: { id: label.id, title: label.title, color: label.color } } });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default UpdateLabelAction;
