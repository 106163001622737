import { FC, useState } from "react";

import IconHeartbeat from "@/components/_icons/IconHeartbeat";
import { Button } from "@/components/_ui/button";
import { Dialog, DialogContentWithoutClose } from "@/components/_ui/dialog";
import DeleteConfirmation from "@/components/delete-confirmation";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace";
import { Cross2Icon } from "@radix-ui/react-icons";

import { useProgressUpdateUIStateState } from "../hooks/useCreateProgressUpdateUIState";

import { ProgressSnapshotCreateForm } from "./progress-snapshot-create-form";

export interface ProgressSnapshotDialogProps {
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
}

export const ProgressSnapshotDialog: FC<ProgressSnapshotDialogProps> = ({ ...props }) => {
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const { hasActiveChanges, resetNewPogressSnapshotForm, showModal, setShowModal } = useProgressUpdateUIStateState();
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const { climateAction } = useGetClimateAction({ id: props.climateAction.id });

  if (!workspace) {
    return <></>;
  }

  if (!climateAction) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
        onClick={() => setShowModal(true)}
      >
        <IconHeartbeat className="mr-2" />
        Fortschrittsbericht
        {hasActiveChanges && <div className="ml-1 h-1.5 w-1.5 rounded-full bg-primary"></div>}
      </Button>
      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContentWithoutClose className="top-[1%] max-w-3xl translate-y-[0%] md:top-[20%]">
          <ProgressSnapshotCreateForm
            workspace={workspace}
            climateAction={climateAction}
            keyResult={climateAction.keyResult}
            onSuccess={() => {
              setShowModal(false);
            }}
          />
          <div
            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:cursor-pointer hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500 dark:ring-offset-slate-950 dark:focus:ring-slate-300 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-400"
            onClick={() => {
              if (hasActiveChanges) {
                setDeleteConfirmationisOpen(true);
              } else {
                resetNewPogressSnapshotForm();
                setShowModal(false);
              }
            }}
          >
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </div>
        </DialogContentWithoutClose>
      </Dialog>
      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={() => {
          resetNewPogressSnapshotForm();
          setShowModal(false);
        }}
        title="Bericht verwerfen?"
        description="Sind Sie sich sicher, dass sie diesen Fortschrittsbericht verwerfen möchten?"
        confirmButtonText="Verwerfen"
        cancelButtonText="Abbrechen"
      />
    </>
  );
};

export default ProgressSnapshotDialog;
