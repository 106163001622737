import { type CreateIndicatorMutation } from "@/graphql/generated/graphql";
import { useMutation } from "@apollo/client";

import { createIndicatorMutationDocument, getIndicatorListQueryDocument } from "./indicator.gql";

export type CreatedIndicator = CreateIndicatorMutation["createIndicator"];

export interface UseCreateIndicatorProps {
  workspaceId: string;
  onCompleted?: (data: CreatedIndicator) => void;
  onError?: (e: Error) => void;
}

export const useCreateIndicator = ({ workspaceId, onCompleted, onError }: UseCreateIndicatorProps) => {
  const [createIndicator] = useMutation(createIndicatorMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getIndicatorListQueryDocument,
        variables: { input: { workspaceId } },
      },
    ],
  });

  function onUpdateCompleted(data: CreateIndicatorMutation) {
    if (onCompleted) {
      onCompleted(data.createIndicator);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createIndicator,
  };
};
