import { graphql } from "../generated/gql";

export const getFeatureFlagQueryDocument = graphql(/* GraphQL */ `
  query GetFeatureFlag($getFeatureFlagInput: GetFeatureFlagInput!) {
    featureFlag(getFeatureFlagInput: $getFeatureFlagInput) {
      id
      userId
      workspaceId
      enabled
      payload
    }
  }
`);

export const getFeatureFlagListQueryDocument = graphql(/* GraphQL */ `
  query GetFeatureFlagList($getFeatureFlagListInput: GetFeatureFlagListInput!) {
    featureFlagList(getFeatureFlagListInput: $getFeatureFlagListInput) {
      id
      userId
      workspaceId
      enabled
      payload
    }
  }
`);
