import { posthogService } from "@/services/posthog.service";
const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

export const EVENT_ASSIGNEE_CREATED = "MUTATION:ASSIGNEE:CREATED";
export const EVENT_ASSIGNEE_UPDATED = "MUTATION:ASSIGNEE:UPDATED";
export const EVENT_ASSIGNEE_DELETED = "MUTATION:ASSIGNEE:DELETED";

const trackingEnabled = VITE_ENVIRONMENT === "production";

type AssigneeEventType = typeof EVENT_ASSIGNEE_CREATED | typeof EVENT_ASSIGNEE_UPDATED | typeof EVENT_ASSIGNEE_DELETED;

export const trackAssigneeEvent = (event: AssigneeEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
