import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_PROGRESS_SNAPSHOT_CREATED = "MUTATION:PROGRESS_SNAPSHOT:CREATED";
export const EVENT_PROGRESS_SNAPSHOT_UPDATED = "MUTATION:PROGRESS_SNAPSHOT:UPDATED";
export const EVENT_PROGRESS_SNAPSHOT_DELETED = "MUTATION:PROGRESS_SNAPSHOT:DELETED";

type ProgressSnapshotEventType =
  | typeof EVENT_PROGRESS_SNAPSHOT_CREATED
  | typeof EVENT_PROGRESS_SNAPSHOT_UPDATED
  | typeof EVENT_PROGRESS_SNAPSHOT_DELETED;

export const trackProgressSnapshotEvent = (event: ProgressSnapshotEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
