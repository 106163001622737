import { useMemo } from "react";

import { numberFromFocusAreaType } from "@/components/_domain/focusArea/models/focus-area-type";
import { getFocusAreaListForWorkspaceQueryDocument } from "@/graphql/common/focus-area";
import { useQuery } from "@apollo/client";

export interface UseGetFocusAreaListProps {
  workspaceId?: string;
  enabled?: boolean;
}

export const useGetFocusAreaList = ({ workspaceId, enabled = true }: UseGetFocusAreaListProps) => {
  const queryResult = useQuery(getFocusAreaListForWorkspaceQueryDocument, {
    variables: { input: { workspaceId: workspaceId ?? "" } },
    skip: !workspaceId || !enabled,
  });

  const focusAreaList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.focusAreaListForWorkspace.map((s) => {
      return {
        ...s,
        order: numberFromFocusAreaType(s.type),
      };
    });
  }, [queryResult.data]);

  return {
    focusAreaList,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
