import { ClimateAction } from "@/components/_domain/climate-action/models";
import { ColumnDef } from "@tanstack/react-table";

import { filterByTextListWithUnknownPayload } from "../../filter-functions";
import { ClimateActionListCellLabelCell, ClimateActionListColumnHeader } from "../../index";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import { FilterByLabelConfigurationView, FilterByLabelDisplayView, FilterByLabelFilterPaneView } from "./components";

interface LabelListColumnDefinitionProps {
  workspaceId: string;
  columnId: string;
  orderValue: number;
  config?: {
    enableHiding?: boolean;
    enableSorting?: boolean;
    enableColumnFilter?: boolean;
    defaultVisibilityState?: boolean;
  };
}

export const LabelListColumnDefinition = ({
  workspaceId,
  columnId,
  orderValue,
  config,
}: LabelListColumnDefinitionProps): ClimateActionColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => config?.defaultVisibilityState ?? false,
    getDisplayValue: () => "Label",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<ClimateAction> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: config?.enableHiding ?? true,
        enableSorting: config?.enableSorting ?? true,
        enableColumnFilter: config?.enableColumnFilter ?? true,
        sortingFn: (rowA, rowB) => {
          const labelsA = rowA.original.labelInstanceList
            .slice()
            .filter((li) => !li.labelGroupId)
            .flatMap((li) => {
              if (!li.label) {
                return [];
              }
              return li.label.title;
            })
            .sort((a, b) => {
              return a.localeCompare(b);
            });
          const labelsB = rowB.original.labelInstanceList
            .slice()
            .filter((li) => !li.labelGroupId)
            .flatMap((li) => {
              if (!li.label) {
                return [];
              }
              return li.label.title;
            })
            .sort((a, b) => {
              return a.localeCompare(b);
            });

          if (labelsA.length === 0) {
            return 1;
          }
          if (labelsB.length === 0) {
            return 1;
          }

          if (labelsA.length > 0 && labelsB.length === 0) {
            return 1;
          }
          if (labelsB.length > 0 && labelsA.length === 0) {
            return -1;
          }

          return labelsA[0].localeCompare(labelsB[0]);
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          for (const labelInstance of row.original.labelInstanceList) {
            if (filterByTextListWithUnknownPayload(value, labelInstance?.label?.title)) {
              return true;
            }
          }
          return false;
        },
        size: 1, // set to 1 instead to 0 for it to work in Safari
        header: ({ column }) => (
          <ClimateActionListColumnHeader column={column} title="" className="px-1 text-xs font-normal" />
        ),
        cell: ({ row }) => <ClimateActionListCellLabelCell row={row} className="mx-2" />,
      };
    },
    getDisplayView: () => <FilterByLabelDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByLabelConfigurationView
        workspace={{ id: workspaceId }}
        column={{ id: columnId }}
        onFinished={onFinished}
      />
    ),
    getConfigurationViewType: () => "popover",
    getFilterPaneView: () => <FilterByLabelFilterPaneView column={{ id: columnId }} />,
  };
};
