import { useMemo } from "react";
import { Outlet } from "react-router-dom";

import { CurrentClimateActionListContextProvider } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { useCurrentFocusAreaContext } from "@/components/_domain/focusArea/hooks/useCurrentFocusAreaContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useGetClimateActionListForWorkspace } from "@/hooks/api/common/useClimateAction";

export const FocusAreaClimateActionListRootLayout = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentFocusArea } = useCurrentFocusAreaContext();

  const { climateActionList, loading, error } = useGetClimateActionListForWorkspace({
    workspaceId: currentWorkspace.id,
  });

  const filteredClimateActionList = useMemo(() => {
    if (climateActionList.length === 0) {
      return [];
    }

    return climateActionList.filter(
      (climateAction) => climateAction.focusAreaInstance?.focusAreaId === currentFocusArea.id,
    );
  }, [currentFocusArea, climateActionList]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  return (
    <CurrentClimateActionListContextProvider climateActionList={filteredClimateActionList}>
      <Outlet />
    </CurrentClimateActionListContextProvider>
  );
};
