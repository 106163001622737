import { columns, UnitsDatatable } from "@/components/_domain/unit/units-datatable";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { useCheckPermission } from "@/hooks/api/common/usePermission";
import { useGetUnitList } from "@/hooks/api/common/useUnit/useGetUnitList";

import UnitCreateDialog from "./unit-create-dialog";

export default function Page() {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const workspaceId = currentWorkspace.id;

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspaceId,
    action: "manage",
  });
  const { unitList } = useGetUnitList({ workspaceId });

  return (
    <main className="relative max-h-screen place-items-stretch overflow-auto">
      <HeaderLayout className="border-none"></HeaderLayout>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Einheiten</div>
              <div className="text-sm text-muted-foreground">Verwalten Sie Einheiten</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <div>
                <p className="font-light text-muted-foreground">
                  Verwalten Sie Einheiten, um den Fortschritt von Maßnahmenn zu messen.
                </p>
              </div>
              <div className="my-8"></div>
              <div className="mb-8 flex shrink grow flex-row items-center justify-end gap-3">
                <div className="flex flex-row gap-6"></div>
                <UnitCreateDialog workspaceId={workspaceId} />
              </div>

              <UnitsDatatable
                columns={hasPermissionToManageWorkspace ? columns : columns.filter((c) => c.id !== "actions")}
                data={unitList}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
