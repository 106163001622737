import React, { createContext, FC, ReactNode, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace";

import { TAB_DATA, TabDataKey } from "../climate-action-tabs";

interface ClimateActionUIContextType {
  showCreateChildClimateActionView: boolean;
  setShowCreateChildClimateActionView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTab: TabDataKey;
  setSelectedTab: (tabId: TabDataKey) => void;
}

const ClimateActionUIContext = createContext<ClimateActionUIContextType | undefined>(undefined);

// Define a type for the props that the provider will accept
interface ClimateActionUIContextProviderProps {
  children: ReactNode;
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
  selectedTab?: {
    id: string;
  };
}

// Create the provider component
export const ClimateActionUIContextProvider: FC<ClimateActionUIContextProviderProps> = ({ children, ...props }) => {
  const navigate = useNavigate();
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const { climateAction } = useGetClimateAction({ id: props.climateAction.id });

  const validTab = TAB_DATA.find((v) => props.selectedTab && v.id === props.selectedTab?.id);
  const [selectedTab, _setSelectedTab] = useState<TabDataKey>(validTab ? validTab.id : "details");

  const [showCreateChildClimateActionView, setShowCreateChildClimateActionView] = useState(false);

  const setSelectedTab = (tabId: TabDataKey) => {
    _setSelectedTab(tabId);

    if (!workspace) {
      return;
    }
    if (!climateAction) {
      return;
    }

    navigate(`/${workspace.workspaceUrl}/climate-actions/${climateAction.id}/${tabId}`);
  };

  return (
    <ClimateActionUIContext.Provider
      value={{
        showCreateChildClimateActionView,
        setShowCreateChildClimateActionView,
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </ClimateActionUIContext.Provider>
  );
};

// Define the useClimateActionUIState hook
export const useClimateActionUIState = (): ClimateActionUIContextType => {
  const context = useContext(ClimateActionUIContext);

  if (!context) {
    throw new Error("useClimateActionUIState must be used within a ChildProvider");
  }

  return context;
};
