import { updatePhaseInstanceMutationDocument } from "@/graphql/common/phase-instance";
import { type UpdatePhaseInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceUpdate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PHASE_INSTANCE_UPDATED, trackPhaseEvent } from "./tracking";

export type UpdatedPhaseInstance = UpdatePhaseInstanceMutation["updatePhaseInstance"];

export interface UseUpdatePhaseInstanceProps {
  onCompleted?: (data: UpdatedPhaseInstance) => void;
  onError?: (e: Error) => void;
}

export const useUpdatePhaseInstance = ({ onCompleted, onError }: UseUpdatePhaseInstanceProps = {}) => {
  const [updatePhaseInstance] = useMutation(updatePhaseInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const phaseInstance = data?.updatePhaseInstance;
      if (!phaseInstance) {
        return;
      }

      onGenericEntityInstanceUpdate({
        cache,
        updatedEntityInstance: {
          ...phaseInstance,
          __parentId: phaseInstance.phaseId,
        },
        typename: "PhaseInstance",
      });
      trackPhaseEvent(EVENT_PHASE_INSTANCE_UPDATED, phaseInstance);
    },
  });

  function onUpdateCompleted(data: UpdatePhaseInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.updatePhaseInstance);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updatePhaseInstance,
  };
};
