import { useCurrentClimateActionListContext } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { DashboardOverview } from "@/components/_domain/dashboard/dashboard-overview";
import { useCurrentFocusAreaContext } from "@/components/_domain/focusArea/hooks/useCurrentFocusAreaContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";

export const FocusAreaClimateActionListDashboardPage = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentFocusArea } = useCurrentFocusAreaContext();
  const { currentClimateActionList } = useCurrentClimateActionListContext();

  const navigateToUrl = `/${currentWorkspace.workspaceUrl}/focus-areas/${currentFocusArea.id}/climate-actions/all`;

  return (
    <DashboardOverview
      workspace={currentWorkspace}
      navigateToUrl={navigateToUrl}
      climateActionList={currentClimateActionList}
    />
  );
};
