import { useMemo } from "react";

import { getUnitQueryDocument } from "@/graphql/common/unit";
import { useQuery } from "@apollo/client";

export interface UseGetUnitProps {
  id?: string;
  enabled?: boolean;
}

export const useGetUnit = ({ id, enabled = true }: UseGetUnitProps) => {
  const queryResult = useQuery(getUnitQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id || !enabled,
  });

  const unit = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.unit;
  }, [queryResult.data]);

  return {
    queryResult,
    unit,
    loading: queryResult.loading,
    error: queryResult.error,
  };
};
