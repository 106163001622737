import { graphql } from "../generated/gql";

// ============== QUERIES ======================

export const getTeamInstanceQueryDocument = graphql(/* GraphQL */ `
  query GetTeamInstance($getTeamInstanceInput: GetTeamInstanceInput!) {
    teamInstance(getTeamInstanceInput: $getTeamInstanceInput) {
      id
      teamId
      ownerId
      ownerType
      workspaceId
    }
  }
`);

export const getTeamInstancebyOwnerQueryDocument = graphql(/* GraphQL */ `
  query GetTeamInstanceByOwner($getTeamInstanceByOwnerInput: GetTeamInstanceByOwnerInput!) {
    teamInstanceByOwner(getTeamInstanceByOwnerInput: $getTeamInstanceByOwnerInput) {
      id
      teamId
      ownerId
      ownerType
      workspaceId
    }
  }
`);

// ============== MUTATIONS ======================
export const createTeamInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation createTeamInstance($input: CreateTeamInstanceInput!) {
    createTeamInstance(createTeamInstanceInput: $input) {
      id
      teamId
      ownerId
      ownerType
      workspaceId
    }
  }
`);

export const updateTeamInstanceListForOwnerMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateTeamInstanceListForOwner($input: UpdateTeamInstanceListForOwnerInput!) {
    updateTeamInstanceListForOwner(updateTeamInstanceListForOwnerInput: $input) {
      id
      teamId
      ownerId
      ownerType
      workspaceId
    }
  }
`);

export const deleteTeamInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteTeamInstance($input: DeleteTeamInstanceInput!) {
    deleteTeamInstance(deleteTeamInstanceInput: $input) {
      id
      teamId
      ownerId
      ownerType
      workspaceId
    }
  }
`);
