import { forwardRef } from "react";
import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps } from "react-router-dom";

import { PrefetchConfig, PrefetchHandler } from "./preloader/prefetch-handler";

interface NavLinkProps extends RouterNavLinkProps {
  prefetchConfig?: Omit<PrefetchConfig, "to">;
}
/**
 * Custom NavLink component that extends React Router's NavLink with prefetching capability
 * @param props - Component props including standard NavLink props and prefetching options
 * @param ref - Forwarded ref from parent component
 */
export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ prefetchConfig, ...props }, ref) => {
  return (
    <PrefetchHandler prefetchConfig={{ ...prefetchConfig, to: props.to.toString() }}>
      <RouterNavLink ref={ref} {...props} />
    </PrefetchHandler>
  );
});

NavLink.displayName = "NavLink";
