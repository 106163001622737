import { FC } from "react";

import { cn } from "@/lib/utils";

import { IndicatorListItem } from "../models/indicator-model";

export interface IndicatorTitleViewProps {
  indicator: IndicatorListItem;
  className?: string;
}

export const IndicatorTitleView: FC<IndicatorTitleViewProps> = ({ indicator, className }) => {
  return (
    <div
      className={cn([
        "my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm ",
        className,
      ])}
    >
      <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
        {indicator.title}
      </span>
    </div>
  );
};
