import { FC } from "react";

import { PriorityViewEmpty } from "./priority-view-empty";
import { PriorityView } from ".";

interface PriorityViewOptionalProps {
  priority?: {
    id: string;
  } | null;
  logoOnly?: boolean;
  className?: string;
}

export const PriorityViewOptional: FC<PriorityViewOptionalProps> = ({ priority, ...props }) => {
  if (!priority) {
    return <PriorityViewEmpty {...props} />;
  }

  return <PriorityView priority={priority} {...props} />;
};

export default PriorityViewOptional;
