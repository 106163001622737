import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import * as z from "zod";

import { Button } from "@/components/_ui/button";
import { toast } from "@/components/_ui/use-toast";
import { RichTextEditor } from "@/components/rich-text-editor";
import { useCreateClimateAction } from "@/hooks/api/common/useClimateAction/useCreateClimateAction";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export interface ClimateActionCreateChildFormProps {
  className?: string;
  parentClimateAction: {
    id: string;
    teamInstanceList: {
      teamId: string;
    }[];
  };
  callbacks: {
    hideCreateChildClimateAction: () => void;
  };
  workspace: {
    id: string;
  };
}

export const ClimateActionCreateChildForm: FC<ClimateActionCreateChildFormProps> = ({
  workspace,
  parentClimateAction,
  callbacks,
}) => {
  const [loading, setLoading] = useState(false);

  const [removedFromDom, setRemovedFromDom] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const { createClimateAction } = useCreateClimateAction({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function onRichTextEditorChange(value: string, json: string) {
    if (value === "") {
      form.setValue("description", "");
    } else {
      form.setValue("description", json);
    }
  }

  async function handleCreateClimateAction() {
    if (loading) {
      return;
    }

    const { title, description } = form.getValues();

    if (!title || title.length < 1) {
      toast({
        title: "Fehler",
        description: "Der Name der Maßnahme darf nicht leer sein. Bitte geben Sie einen Namen ein.",
        variant: "error",
      });
      return;
    }

    setLoading(true);
    await createClimateAction({
      variables: {
        input: {
          title: title,
          properties: description,
          parentId: parentClimateAction.id,
          workspaceId: workspace.id,
          teamIdList: parentClimateAction.teamInstanceList.map((o) => o.teamId),
        },
      },
    });
  }

  function handleCancel() {
    callbacks.hideCreateChildClimateAction();
  }

  async function onUpdateCompleted() {
    setLoading(false);
    form.reset();
    forceRichTextEditorRerender();
  }

  async function onUpdateError() {
    setLoading(false);
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  function forceRichTextEditorRerender() {
    setRemovedFromDom(true);
    setTimeout(() => {
      setRemovedFromDom(false);
      form.setFocus("title");
    }, 1);
  }

  return (
    <form>
      <div className="shadow-md m-3 box-border grid w-[calc(100%-16px)] rounded border-t-[0.5px] p-2">
        {/* Header */}

        {/* Main Part */}
        <div className="mx-auto w-full">
          <TextareaAutosize
            autoFocus
            id="title"
            defaultValue={"Neue Maßnahme"}
            placeholder="Name der Maßnahme"
            className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent px-3 py-0 text-sm focus:outline-none"
            {...form.register("title")}
          />
          {!removedFromDom && (
            <RichTextEditor onChange={onRichTextEditorChange} hideFloatingMenu={false} className="min-h-[4rem]" />
          )}
        </div>
        <div className="border-b"></div>
        <div className="flex flex-row items-center justify-end gap-4 pt-2">
          <Button type="button" variant="ghost" size="sm" onClick={() => handleCancel()}>
            Abbrechen
          </Button>
          <Button
            type="button"
            variant="outline"
            size="sm"
            className="shadow-md"
            onClick={() => handleCreateClimateAction()}
          >
            Speichern
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ClimateActionCreateChildForm;
