import { type AsChildProps, Slot } from "@/components/slot.tsx";
import { useCreateLabelInstance } from "@/hooks/api/common/useLabel";

type OwnerType = "CLIMATE_ACTION";

type AddLabelToEntityActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
  };
  owner: {
    id: string;
    type: OwnerType;
  };
  workspace: {
    id: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
};

export const AddLabelToEntityAction = ({ label, owner, workspace, asChild, ...props }: AddLabelToEntityActionProps) => {
  const Comp = asChild ? Slot : "button";
  const { createLabelInstance } = useCreateLabelInstance();

  async function handleClick() {
    await createLabelInstance({
      variables: { input: { labelId: label.id, ownerId: owner.id, ownerType: owner.type, workspaceId: workspace.id } },
    });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default AddLabelToEntityAction;
