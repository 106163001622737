import { deleteFocusAreaInstanceMutationDocument } from "@/graphql/common/focus-area-instance";
import { type DeleteFocusAreaInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_FOCUS_AREA_INSTANCE_DELETED, trackFocusAreaEvent } from "./tracking";

export type DeletedFocusAreaInstance = DeleteFocusAreaInstanceMutation["deleteFocusAreaInstance"];

export interface UseDeleteFocusAreaInstanceProps {
  onCompleted?: (data: DeletedFocusAreaInstance) => void;
  onError?: (e: Error) => void;
}

export const useDeleteFocusAreaInstance = ({ onCompleted, onError }: UseDeleteFocusAreaInstanceProps = {}) => {
  const [deleteFocusAreaInstance] = useMutation(deleteFocusAreaInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedFocusAreaInstance = data?.deleteFocusAreaInstance;
      if (!deletedFocusAreaInstance) {
        return;
      }

      onGenericEntityInstanceDelete({
        cache,
        deletedEntityInstance: {
          ...deletedFocusAreaInstance,
          __parentId: deletedFocusAreaInstance.focusAreaId,
        },
        typename: "FocusAreaInstance",
      });

      trackFocusAreaEvent(EVENT_FOCUS_AREA_INSTANCE_DELETED, data.deleteFocusAreaInstance);
    },
  });

  function onUpdateCompleted(data: DeleteFocusAreaInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.deleteFocusAreaInstance);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteFocusAreaInstance,
  };
};
