import { useMemo } from "react";

import { getAssigneeByOwnerQueryDocument } from "@/graphql/common/assignee";
import { useQuery } from "@apollo/client";

export interface UseGetAssigneeByOwnerProps {
  ownerId: string;
}

export const useGetAssigneeByOwner = ({ ownerId }: UseGetAssigneeByOwnerProps) => {
  const queryResult = useQuery(getAssigneeByOwnerQueryDocument, {
    variables: { input: { ownerId } },
  });

  const assignee = useMemo(() => {
    const { data } = queryResult;
    if (!data) {
      return null;
    }

    return data.assigneeByOwner;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.data]);

  const assignedUser = useMemo(() => {
    if (!assignee) {
      return null;
    }
    if (!assignee.user) {
      return null;
    }
    return assignee.user;
  }, [assignee]);

  return {
    queryResult,
    assignee,
    assignedUser,
  };
};
