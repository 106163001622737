export const PRESET_COLORS = [
  "#95999F",
  "#6E7B8B",
  "#5E69D2",
  "#00B5ED",
  "#00B55C",
  "#E8B600",
  "#E57A00",
  "#F38E82",
  "#FB424B",
];
