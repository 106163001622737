import { useMemo } from "react";

import { TaskStatus } from "@/components/_domain/task";
import { getAllTasksQueryDocument } from "@/graphql/common/task";
import { Task } from "@/graphql/generated/graphql";
import { useQuery } from "@apollo/client";

export const useGetAllTasksQuery = (statusList?: string[]) => {
  const { data, loading, error } = useQuery(getAllTasksQueryDocument, {
    variables: { input: { statusList: statusList ?? [] } },
  });

  const tasks = useMemo(() => {
    if (!data) return [];
    return data.getAllTasks.map((task) => ({
      ...task,
      status: Object.values(TaskStatus).includes(task.status as TaskStatus)
        ? (task.status as TaskStatus)
        : TaskStatus.Todo, // Default status if the received status is not valid
    }));
  }, [data]);

  const tasksByStatus = useMemo(() => {
    return tasks.reduce(
      (acc, task) => {
        acc[task.status] = [...(acc[task.status] || []), task];
        return acc;
      },
      {} as Record<TaskStatus, Task[]>,
    );
  }, [tasks]);

  const maxPositionByStatus = useMemo(() => {
    return Object.keys(tasksByStatus).reduce(
      (acc, status) => {
        acc[status as TaskStatus] = tasksByStatus[status as TaskStatus].reduce(
          (max, task) => Math.max(max, task.position),
          0,
        );
        return acc;
      },
      {} as Record<TaskStatus, number>,
    );
  }, [tasksByStatus]);

  return {
    tasks,
    tasksByStatus,
    maxPositionByStatus,
    loading,
    error,
  };
};
