import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_PHASE_CREATED = "MUTATION:PHASE:CREATED";
export const EVENT_PHASE_UPDATED = "MUTATION:PHASE:UPDATED";
export const EVENT_PHASE_DELETED = "MUTATION:PHASE:DELETED";

export const EVENT_PHASE_INSTANCE_CREATED = "MUTATION:PHASE_INSTANCE:CREATED";
export const EVENT_PHASE_INSTANCE_UPDATED = "MUTATION:PHASE_INSTANCE:UPDATED";
export const EVENT_PHASE_INSTANCE_DELETED = "MUTATION:PHASE_INSTANCE:DELETED";

type PhaseEventType =
  | typeof EVENT_PHASE_CREATED
  | typeof EVENT_PHASE_UPDATED
  | typeof EVENT_PHASE_DELETED
  | typeof EVENT_PHASE_INSTANCE_CREATED
  | typeof EVENT_PHASE_INSTANCE_UPDATED
  | typeof EVENT_PHASE_INSTANCE_DELETED;

export const trackPhaseEvent = (event: PhaseEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
