import { FC, useState } from "react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { CalendarIcon } from "lucide-react";

import { Button } from "@/components/_ui/button";
import { Popover, PopoverPortalContent } from "@/components/_ui/popover";
import Datepicker from "@/components/datepicker/datepicker";
import { cn } from "@/lib/utils";
import { PopoverTrigger } from "@radix-ui/react-popover";

export interface InputDateSmallProps {
  onValueChange?: (value: string | null) => void;
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  modified?: boolean;
}

export const InputDateSmall: FC<InputDateSmallProps> = ({
  onValueChange,
  defaultValue,
  disabled,
  placeholder,
  minDate,
  maxDate,
  modified,
  className,
}) => {
  const [value, setValue] = useState<string | null>(defaultValue ? defaultValue : null);

  const handleDateSelected = (selectedDate: Date | null) => {
    if (!selectedDate) {
      return null;
    }

    const dateString = selectedDate.toISOString();
    setValue(dateString);
    if (onValueChange) {
      onValueChange(dateString);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={"ghost"}
          size="xs"
          className={cn(["rounded-md border text-left text-xs font-normal ", className ?? ""])}
        >
          <CalendarIcon className="ml-auto mr-1 h-3 w-3 opacity-50" />
          {value ? (
            format(value, "PPP", { locale: de })
          ) : (
            <span className="border text-muted-foreground">{placeholder}</span>
          )}
          {!!modified && <div className="ml-1 h-1.5 w-1.5 rounded-full bg-primary"></div>}
        </Button>
      </PopoverTrigger>
      <PopoverPortalContent className="w-auto p-0" align="start">
        <Datepicker
          onValueChange={handleDateSelected}
          defaultValue={defaultValue ? new Date(defaultValue) : undefined}
          minDate={minDate}
          maxDate={maxDate}
        />
      </PopoverPortalContent>
    </Popover>
  );
};

export default InputDateSmall;
