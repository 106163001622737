import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";

import { PhaseIcon } from "@/components/_domain/phase";
import { PhaseType } from "@/components/_domain/phase/phase-type";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { ColorPicker } from "@/components/color-picker/color-picker";
import { useCreatePhase } from "@/hooks/api/common/usePhase";
import { cn } from "@/lib/utils";

export interface PhaseFormCreateProps extends PropsWithChildren {
  workspaceId: string;
  phaseType: PhaseType;
  onSuccess?: () => void;
  onCanceled?: () => void;
  className?: string;
}

export const PhaseFormCreate: FC<PhaseFormCreateProps> = ({
  workspaceId,
  phaseType,
  onSuccess,
  onCanceled,
  className,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [newPhaseName, setNewPhaseName] = useState("");
  const [newPhaseColor, setNewPhaseColor] = useState("#95999F");

  const { createPhase } = useCreatePhase({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleCreateNewPhase() {
    if (newPhaseName === "" || newPhaseColor === "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Titel und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await createPhase({
      variables: {
        input: {
          workspaceId: workspaceId,
          name: newPhaseName,
          color: newPhaseColor,
          type: phaseType,
        },
      },
    });
  }

  function reset() {
    // do not reset the color; the most likely case is that users want to create several objects of the same color
    // setNewPhaseColor("#95999F")
    setNewPhaseName("");
    ref.current?.focus();
  }

  function onUpdateCompleted() {
    reset();
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      className={cn(["my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2", className])}
    >
      <div className="w-9">
        <ColorPicker
          asChild
          value={newPhaseColor}
          onChange={(color) => {
            setNewPhaseColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <PhaseIcon className="h-4 w-4" phase={{ type: phaseType, color: newPhaseColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={newPhaseName}
          onChange={(e) => setNewPhaseName(e.currentTarget.value)}
          placeholder="Name des Phases"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={newPhaseName === ""}
          onClick={handleCreateNewPhase}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default PhaseFormCreate;
