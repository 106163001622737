import { useMemo } from "react";

import { getLabelGroupListForWorkspaceQueryDocument } from "@/graphql/common/label-group";
import { useQuery } from "@apollo/client";

export interface UseGetLabelGroupListForWorkspaceProps {
  workspaceId?: string;
  enabled?: boolean;
}

export const useGetLabelGroupListForWorkspace = ({
  workspaceId,
  enabled = true,
}: UseGetLabelGroupListForWorkspaceProps) => {
  const queryResult = useQuery(getLabelGroupListForWorkspaceQueryDocument, {
    variables: { input: { workspaceId: workspaceId ?? "" } },
    skip: !enabled,
  });

  const labelGroupList = useMemo(() => {
    const { data } = queryResult;

    if (!data) {
      return [];
    }

    return data.labelGroupsForWorkspace.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [queryResult]);

  return {
    loading: queryResult.loading,
    error: queryResult.error,
    labelGroupList,
    queryResult,
  };
};
