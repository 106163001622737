import { FC, PropsWithChildren, useMemo } from "react";

import { ALLOWED_PRIORITY_LIST } from "@/components/_domain/priority/priority-type";
import { useGetPriorityListForWorkspace } from "@/hooks/api/common/usePriority";
import { groupBy } from "@/lib/utils";

import { PriorityTableSection } from "./priority-table-section";

export interface PriorityTablePropsWithChildren extends PropsWithChildren {
  workspaceId: string;
  className?: string;
}

export const PriorityTable: FC<PriorityTablePropsWithChildren> = ({ workspaceId }) => {
  const { priorityList } = useGetPriorityListForWorkspace({ workspaceId: workspaceId });

  const groupedPriorityList = useMemo(() => {
    return groupBy(priorityList, (priority) => priority.type);
  }, [priorityList]);

  if (!priorityList) {
    return <></>;
  }

  return (
    <>
      {ALLOWED_PRIORITY_LIST.map((type) => {
        const priorityForType = groupedPriorityList.get(type) ?? [];
        return (
          <PriorityTableSection
            key={type}
            workspaceId={workspaceId}
            priorityType={type}
            priorityList={priorityForType}
            className="mb-4"
          />
        );
      })}
    </>
  );
};

export default PriorityTable;
