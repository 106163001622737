import { FC, PropsWithChildren, ReactNode, useMemo, useState } from "react";

import { Input } from "@/components/_ui/input";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useGetTeam } from "@/hooks/api/common/useWorkspace/useGetTeam";
import { useTeamMembershipList } from "@/hooks/api/common/useWorkspace/useTeamMembershipList";

import { TeamMemberJoinButton } from "../team-member-join-button/team-member-join-button";

import TeamMemberInviteButton from "./components/team-member-invite-button";
import TeamMemberListItem from "./team-member-list-item";

export interface TeamMemberListPropsWithChildren extends PropsWithChildren {
  team: {
    id: string;
    workspaceId: string;
  };
  className?: string;
}

export const TeamMemberList: FC<TeamMemberListPropsWithChildren> = ({ ...props }) => {
  const { currentUser, isSuperUser } = useCurrentUserContext();
  const [filterValue, setFilterValue] = useState<string>("");

  const { team } = useGetTeam({ id: props.team.id });
  const { teamMembershipList } = useTeamMembershipList({ teamId: props.team.id });
  const { hasPermission: hasPermissionToManageTeam } = useCheckPermission({
    resource: "team",
    resourceId: props.team.id,
    action: "manage",
  });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    if (isSuperUser) {
      return true;
    }
    return hasPermissionToManageTeam;
  }, [currentUser, isSuperUser, hasPermissionToManageTeam]);

  const filteredTeamMembershipList = useMemo(() => {
    if (filterValue === "") {
      return teamMembershipList;
    }

    return teamMembershipList.filter((tm) => {
      const query = filterValue.toLocaleLowerCase();
      const user = tm.user;

      if (!user) {
        return false;
      }

      if (user.email.toLocaleLowerCase().includes(query)) {
        return true;
      }

      if (user.fullname && user.fullname.toLocaleLowerCase().includes(query)) {
        return true;
      }

      if (user.username && user.username.toLocaleLowerCase().includes(query)) {
        return true;
      }

      return false;
    });
  }, [teamMembershipList, filterValue]);

  const teamMembershipListItems = useMemo(() => {
    // inefficient, but for now we shouldn't deal with too many users
    let sortedListWorkspaceMemberships = filteredTeamMembershipList.slice().sort((a) => (a.role === "ADMIN" ? -1 : 1));
    sortedListWorkspaceMemberships = sortedListWorkspaceMemberships.slice().sort((a) => (a.status === "LEFT" ? -1 : 1));
    sortedListWorkspaceMemberships = sortedListWorkspaceMemberships
      .slice()
      .sort((a) => (a.status === "PENDING" ? -1 : 1));
    sortedListWorkspaceMemberships = sortedListWorkspaceMemberships
      .slice()
      .sort((a) => (a.status === "ACTIVE" ? -1 : 1));
    sortedListWorkspaceMemberships = sortedListWorkspaceMemberships
      .slice()
      .sort((a) => (a.userId === currentUser?.id ? -1 : 1));

    const initialAccumulator: ReactNode[] = [];
    const listItemsElements = sortedListWorkspaceMemberships.reduce((result, teamMembership) => {
      const user = teamMembership.user;
      if (!user) {
        return result; // faulty user, ignore it
      }

      result.push(
        <TeamMemberListItem
          key={`${teamMembership.id}`}
          team={{ id: teamMembership.teamId, workspaceId: props.team.workspaceId }}
          user={{ id: teamMembership.userId }}
        />,
      );
      return result;
    }, initialAccumulator);

    if (listItemsElements.length === 0) {
      return (
        <div className="flex h-24 flex-row items-center justify-center rounded-md text-center text-xs">
          Kein Ergebnisse
        </div>
      );
    }

    return listItemsElements;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTeamMembershipList]);

  if (!team) {
    return <></>;
  }

  return (
    <div>
      <div>
        <div>
          <p className="font-light text-muted-foreground">
            Verwalten Sie die Nutzer, die Zugang zu Ihrem Team
            <strong>{` ${team.name} `}</strong>
            haben. Nutzer mit Administratoren Rolle können weitere Nutzer einladen und Änderungen in den Einstellungen
            des Teams vornehmen.
          </p>
        </div>
        <div className="my-8"></div>
        {hasEditRights && (
          <div className="flex shrink grow flex-row items-center justify-between">
            <Input
              placeholder="Nutzer filtern..."
              value={filterValue}
              onChange={(event) => {
                setFilterValue(event.currentTarget.value);
              }}
              className="h-8 w-[200px] lg:w-[350px]"
            />
            <div className="flex flex-row gap-6"></div>
            <div className="flex flex-row gap-2">
              <TeamMemberJoinButton team={team} />
              <TeamMemberInviteButton team={team} />
            </div>
          </div>
        )}
      </div>
      <div className="mt-10 border-t border-solid">{teamMembershipListItems}</div>
    </div>
  );
};

export default TeamMemberList;
