import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_PRIORITY_CREATED = "MUTATION:PRIORITY:CREATED";
export const EVENT_PRIORITY_UPDATED = "MUTATION:PRIORITY:UPDATED";
export const EVENT_PRIORITY_DELETED = "MUTATION:PRIORITY:DELETED";

export const EVENT_PRIORITY_INSTANCE_CREATED = "MUTATION:PRIORITY_INSTANCE:CREATED";
export const EVENT_PRIORITY_INSTANCE_UPDATED = "MUTATION:PRIORITY_INSTANCE:UPDATED";
export const EVENT_PRIORITY_INSTANCE_DELETED = "MUTATION:PRIORITY_INSTANCE:DELETED";

type PriorityEventType =
  | typeof EVENT_PRIORITY_CREATED
  | typeof EVENT_PRIORITY_UPDATED
  | typeof EVENT_PRIORITY_DELETED
  | typeof EVENT_PRIORITY_INSTANCE_CREATED
  | typeof EVENT_PRIORITY_INSTANCE_UPDATED
  | typeof EVENT_PRIORITY_INSTANCE_DELETED;

export const trackPriorityEvent = (event: PriorityEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
