import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { SavedView } from "../../models/saved-views-model";

import { parseSavedView } from "./parse-filter-data-utils";
import { getSavedViewsByUrlQueryDocument } from "./saved-view.gql";

interface UseGetSavedViewForUrlProps {
  url: string;
  workspaceId: string;
  enabled?: boolean;
}

export const useGetSavedViewListForUrl = ({ url, workspaceId, enabled = true }: UseGetSavedViewForUrlProps) => {
  const queryResult = useQuery(getSavedViewsByUrlQueryDocument, {
    variables: { input: { url: url, workspaceId: workspaceId } },
    skip: !url || !workspaceId || !enabled,
  });

  const savedViews: SavedView[] = useMemo(() => {
    if (!queryResult.data?.savedViews) {
      return [];
    }

    return queryResult.data.savedViews.flatMap((savedView) => {
      const parsedSavedView = parseSavedView(savedView);
      if (!parsedSavedView) {
        return [];
      }
      return parsedSavedView;
    });
  }, [queryResult.data]);

  const defaultView: SavedView | undefined | null = useMemo(() => {
    const defaultView = savedViews.find((view) => view.isDefault);

    if (!defaultView) {
      return savedViews[0];
    }

    return defaultView;
  }, [savedViews]);

  return {
    savedViews,
    defaultView,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
