import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getUnitListQueryDocument = graphql(/* GraphQL */ `
  query GetUnitList($input: GetUnitListInput!) {
    unitList(getUnitListInput: $input) {
      id
      workspaceId
      title
      symbol
      keyResults {
        id
        climateActionId
      }
    }
  }
`);

export const getUnitQueryDocument = graphql(/* GraphQL */ `
  query GetUnit($input: GetUnitInput!) {
    unit(getUnitInput: $input) {
      id
      workspaceId
      title
      symbol
      keyResults {
        id
        climateActionId
      }
    }
  }
`);

// ============== MUTATIONS ======================

export const createUnitMutationDocument = graphql(/* GraphQL */ `
  mutation createUnit($input: CreateUnitInput!) {
    createUnit(createUnitInput: $input) {
      id
      workspaceId
      title
      symbol
      keyResults {
        id
        climateActionId
      }
    }
  }
`);

export const updateUnitMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateUnit($input: UpdateUnitInput!) {
    updateUnit(updateUnitInput: $input) {
      id
      workspaceId
      title
      symbol
      keyResults {
        id
        climateActionId
      }
    }
  }
`);

export const deleteUnitMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteUnit($input: DeleteUnitInput!) {
    deleteUnit(deleteUnitInput: $input) {
      id
      workspaceId
      title
      symbol
      keyResults {
        id
        climateActionId
      }
    }
  }
`);
