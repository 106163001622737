import { useCallback, useMemo } from "react";

import { getLabelInstancesQueryDocument } from "@/graphql/common/label-instance";
import { groupBy } from "@/lib/utils";
import { useQuery } from "@apollo/client";

export interface UseGetLabelInstanceListProps {
  ownerId?: string;
  enabled?: boolean;
}

export const useGetLabelInstanceList = ({ ownerId, enabled = true }: UseGetLabelInstanceListProps) => {
  const queryResult = useQuery(getLabelInstancesQueryDocument, {
    variables: { input: { ownerId: ownerId ?? "" } },
    skip: !ownerId || !enabled,
  });

  const labelInstanceList = useMemo(() => {
    const { data } = queryResult;
    if (!data) {
      return [];
    }

    return data.labelInstances.slice().sort((a, b) => {
      if (!a || !b || !a.label || !b.label) {
        return 0;
      }
      return a.label.title.localeCompare(b.label.title);
    });
  }, [queryResult]);

  const labelInstanceListGroupedByGroupId = useMemo(() => {
    return groupBy(labelInstanceList, (labelInstance) => labelInstance.label?.labelGroupId);
  }, [labelInstanceList]);

  const getLabelInstanceByGroupId = useCallback(
    (groupId: string) => {
      if (!labelInstanceListGroupedByGroupId) {
        return [];
      }

      const filteredLabelInstances = labelInstanceListGroupedByGroupId.get(groupId);

      if (!filteredLabelInstances) {
        return [];
      }
      return filteredLabelInstances;
    },
    [labelInstanceListGroupedByGroupId],
  );

  return {
    queryResult,
    labelInstanceList,
    labelInstanceListGroupedByGroupId,
    getLabelInstanceByGroupId,
  };
};
