import { Outlet, useParams } from "react-router-dom";

import { CurrentTeamContextProvider } from "@/components/_domain/team/hooks/useCurrentTeamContext";
import { NotFoundView } from "@/components/not-found-view";

export function TeamLayout() {
  const { teamId } = useParams();

  if (!teamId) {
    return <NotFoundView />;
  }

  return (
    <CurrentTeamContextProvider teamId={teamId}>
      <Outlet />
    </CurrentTeamContextProvider>
  );
}
