import { useEffect } from "react";
import { LexicalEditor } from "lexical";
import { useForm } from "react-hook-form";
import * as z from "zod";

import IconToolbarTrash from "@/components/_icons/IconToolbarTrash";
import { Button } from "@/components/_ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/_ui/dialog";
import { Form, FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/_ui/form";
import { Input } from "@/components/_ui/input";
// import { urlPattern } from "@sento/utilities"
// import { Button } from "@ui/elements"
// import { Input } from "@ui/forms"
// import { SimpleModal } from "@ui/overlays"
// import { Heading } from "@ui/typography"
// import { useTranslation } from "@ui/useTranslation"
import { zodResolver } from "@hookform/resolvers/zod";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";

import { sanitizeUrl } from "../../rich-text-editor-helper";

const formSchema = z.object({
  url: z.string().url({
    message: "Bitte geben Sie eine valide URL ein.",
  }),
});

type AddLinkDialogProps = {
  url: string | null;
  editor: LexicalEditor;
  open: boolean;
  onClose: () => void;
};

export const AddLinkDialog = ({ url, editor, open, onClose }: AddLinkDialogProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: "",
    },
  });

  useEffect(() => {
    if (open) {
      return;
    } // only set values if the dialog is not yet open
    form.setValue("url", url ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    handleAddLink(values.url);
    form.reset();
    onClose();
  }

  function handleCloseDialog() {
    form.reset({ url: undefined });
    form.clearErrors();
    onClose();
  }

  function handleAddLink(url: string) {
    editor.update(() => {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
        url: sanitizeUrl(url),
        title: sanitizeUrl(url),
      });
      handleCloseDialog();
    });
  }

  function handleClearLink() {
    editor.update(() => {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      handleCloseDialog();
    });
  }

  return (
    <Dialog open={open} onOpenChange={() => handleCloseDialog()}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Link bearbeiten</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="url"
              render={({ field }) => (
                <FormItem>
                  <FormDescription>Auf welche URL soll dieser Link verweisen?</FormDescription>
                  <FormControl>
                    <div className="flex flex-row items-center gap-2">
                      <Input {...field} />
                      <Button type="button" size="sm" className="px-3" disabled={!url} onClick={handleClearLink}>
                        <span className="sr-only">Copy</span>
                        <IconToolbarTrash className="h-3 w-3" />
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row justify-end">
              <Button type="button" variant="ghost" size="xs" className="text-xs" onClick={onClose}>
                Abbrechen
              </Button>
              <Button type="submit" variant="default" size="xs" className="text-xs" disabled={!form.formState.isValid}>
                Speichern
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
