import { toast } from "@/components/_ui/use-toast.ts";
import { type AsChildProps, Slot } from "@/components/slot.tsx";
import { useUpdateLabel } from "@/hooks/api/common/useLabel";

type AddLabelToGroupActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
    labelGroupId?: string | null;
  };
  labelGroup: {
    id: string;
    title: string;
  };
  workspace: {
    id: string;
  };
};

export const AddLabelToGroupAction = ({ label, labelGroup, asChild, ...props }: AddLabelToGroupActionProps) => {
  const Comp = asChild ? Slot : "button";

  const { updateLabel } = useUpdateLabel({
    onCompleted: () => {
      toast({
        title: "Label zu Gruppe hinzugefügt",
        description: `Wir haben die Label erfolgreich zur Gruppe ${labelGroup.title} hinzugefügt.`,
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler bei der Vergabe der Gruppe aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
    },
  });

  async function handleClick() {
    await updateLabel({ variables: { input: { id: label.id, labelGroupId: labelGroup.id } } });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default AddLabelToGroupAction;
