import { useCallback, useMemo } from "react";

import { teamMembershipsForTeamQueryDocument } from "@/components/_domain/team/hooks/useTeam/team-membership.gql";
import { useQuery } from "@apollo/client";

export interface UseGetTeamMembershipListProps {
  teamId: string;
}

export const useTeamMembershipList = (props: UseGetTeamMembershipListProps) => {
  const queryResult = useQuery(teamMembershipsForTeamQueryDocument, {
    variables: { input: props.teamId },
    skip: !props.teamId,
  });

  const teamMembershipList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.teamMembershipsForTeam;
  }, [queryResult.data]);

  const activeTeamMembershipList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.teamMembershipsForTeam.slice().filter((tm) => tm.status === "ACTIVE");
  }, [queryResult.data]);

  const getTeamMembershipForUser = useCallback(
    (userId: string) => {
      return teamMembershipList.find((tm) => tm.userId === userId);
    },
    [teamMembershipList],
  );

  const getTeamMembershipById = useCallback(
    (id: string) => {
      return teamMembershipList.find((tm) => tm.id === id);
    },
    [teamMembershipList],
  );

  return {
    queryResult,
    activeTeamMembershipList,
    teamMembershipList,
    getTeamMembershipForUser,
    getTeamMembershipById,
  };
};
