import { createContext, type FC, type PropsWithChildren, useContext } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";

import { type Indicator } from "../models/indicator-model";

import { useGetIndicator } from "./useIndicator/useGetIndicator";

type CurrentIndicatorContext = {
  currentIndicator: Indicator;
};

const CurrentIndicatorContext = createContext<CurrentIndicatorContext | null>(null);

export interface CurrentIndicatorContextProviderProps extends PropsWithChildren {
  indicatorId: string;
}

export const CurrentIndicatorContextProvider: FC<CurrentIndicatorContextProviderProps> = ({
  children,
  indicatorId,
}) => {
  const { indicator, loading, error } = useGetIndicator({ id: indicatorId });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  if (!indicator) {
    return <ErrorView />;
  }

  return (
    <CurrentIndicatorContext.Provider value={{ currentIndicator: indicator }}>
      {children}
    </CurrentIndicatorContext.Provider>
  );
};

export const useCurrentIndicatorContext = () => {
  const context = useContext(CurrentIndicatorContext);

  if (!context) {
    throw new Error("useCurrentIndicatorContext must be used within a CurrentIndicatorContextProvider");
  }

  return context;
};
