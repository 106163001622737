import { currentUserQueryDocument } from "@/graphql/common/user";
import { superuser_createWorkspaceMutationDocument, superuser_workspaceListQueryDocument } from "@/graphql/superuser";
import { useMutation } from "@apollo/client";

type WorkspaceFragment = {
  id: string;
};

export interface UseCreateWorkspaceProps {
  onCompleted?: (o: WorkspaceFragment | undefined) => void;
  onError?: (e: Error) => void;
}

export const useWorkspaceCreate = ({ onCompleted, onError }: UseCreateWorkspaceProps) => {
  const [createWorkspace, queryResult] = useMutation(superuser_createWorkspaceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: superuser_workspaceListQueryDocument,
      },
      {
        query: currentUserQueryDocument,
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.createWorkspace?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    createWorkspace,
  };
};
