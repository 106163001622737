import { useParams } from "react-router-dom";

import { TeamEditForm } from "@/components/_domain/team";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { NotFoundView } from "@/components/not-found-view";
import { PageHeader } from "@/components/page-header";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { useGetTeam } from "@/hooks/api/common/useWorkspace/useGetTeam";

import { TeamDeleteButton } from "./team-delete";

export default function Page() {
  const { teamId } = useParams();

  const { team } = useGetTeam({ id: teamId });

  const { hasPermission: hasPermissionToManageTeam } = useCheckPermission({
    resource: "team",
    resourceId: teamId,
    action: "manage",
  });

  if (!team) {
    return <NotFoundView />;
  }

  return (
    <main className="relative place-items-stretch overflow-auto">
      <HeaderLayout className="border-none"></HeaderLayout>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <PageHeader title={team.name} subtitle="Verwalten Sie Ihr Team" />

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <TeamEditForm team={team} />
            </div>

            {hasPermissionToManageTeam && (
              <div>
                <div className="my-12 w-full border-t border-solid" />
                <h2 className="mb-4 text-lg">Team Löschen</h2>
                <p className="mb-4 text-sm text-muted-foreground">
                  Wenn Sie den Team und alle damit assozierten Daten löschen wollen, können Sie das hier tun.
                </p>
                <TeamDeleteButton team={team} />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
