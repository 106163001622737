import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getLabelGroupListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query GetLabelGroupListForWorkspace($input: GetLabelGroupsForWorkspaceInput!) {
    labelGroupsForWorkspace(getLabelGroupsForWorkspaceInput: $input) {
      id
      title
      color
      allowMultiSelect
      workspaceId
      labels {
        id
        title
        color
        labelGroupId
        workspaceId
      }
    }
  }
`);

export const getLabelGroupQueryDocument = graphql(/* GraphQL */ `
  query GetLabelGroup($input: GetLabelGroupInput!) {
    labelGroup(getLabelGroupInput: $input) {
      id
      title
      color
      allowMultiSelect
      workspaceId
      labels {
        id
        title
        color
        labelGroupId
        workspaceId
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createLabelGroupMutationDocument = graphql(/* GraphQL */ `
  mutation createLabelGroup($input: CreateLabelGroupInput!) {
    createLabelGroup(createLabelGroupInput: $input) {
      id
      title
      color
      allowMultiSelect
      workspaceId
      labels {
        id
        title
        color
        labelGroupId
        workspaceId
      }
    }
  }
`);

export const updateLabelGroupMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateLabelGroup($input: UpdateLabelGroupInput!) {
    updateLabelGroup(updateLabelGroupInput: $input) {
      id
      title
      color
      allowMultiSelect
      workspaceId
      labels {
        id
        title
        color
        labelGroupId
        workspaceId
      }
    }
  }
`);

export const deleteLabelGroupMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteLabelGroup($input: DeleteLabelGroupInput!) {
    deleteLabelGroup(deleteLabelGroupInput: $input) {
      id
      title
      color
      allowMultiSelect
      workspaceId
      labels {
        id
        title
        color
        labelGroupId
        workspaceId
      }
    }
  }
`);
