import {
  getAssigneeByOwnerQueryDocument,
  getAssigneeQueryDocument,
  updateAssigneeMutationDocument,
} from "@/graphql/common/assignee";
import { useMutation } from "@apollo/client";

import { EVENT_ASSIGNEE_UPDATED, trackAssigneeEvent } from "./tracking";

export interface UseUpdateAssigneeProps {
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useUpdateAssignee = ({ onCompleted, onError }: UseUpdateAssigneeProps) => {
  const [updateAssignee] = useMutation(updateAssigneeMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const assignee = data?.updateAssignee;
      if (!assignee) {
        return;
      }
      cache.writeQuery({
        query: getAssigneeQueryDocument,
        variables: {
          input: { id: assignee.id },
        },
        data: {
          assignee: assignee,
        },
      });
      cache.writeQuery({
        query: getAssigneeByOwnerQueryDocument,
        variables: {
          input: { ownerId: assignee.ownerId },
        },
        data: {
          assigneeByOwner: assignee,
        },
      });
      trackAssigneeEvent(EVENT_ASSIGNEE_UPDATED, assignee);
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updateAssignee,
  };
};
