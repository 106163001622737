import { FC, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";
import { useGetPriorityListForWorkspace } from "@/hooks/api/common/usePriority";

import { PrioritySelectDropdownItem } from "./priority-select-dropdown-item";

export interface PrioritySelectDropdownProps extends PropsWithChildren {
  workspaceId: string;
  asChild?: boolean;
  selected?: {
    priorityId: string;
  } | null;
  onSelect?: (priority: { id: string } | null) => void;
  side?: "top" | "right" | "bottom" | "left";
}

export const PrioritySelectDropdown: FC<PrioritySelectDropdownProps> = ({
  workspaceId,
  children,
  side,
  asChild,
  onSelect,
  ...props
}) => {
  const [selectedPriority, setSelectedPriority] = useState(props.selected ? props.selected : null);
  const { priorityList } = useGetPriorityListForWorkspace({ workspaceId: workspaceId });

  const comboboxOptions = useMemo(() => {
    const priorityOptions = priorityList
      .slice()
      .sort((a, b) => {
        return a.order - b.order;
      })
      .flatMap((u) => {
        if (!u) {
          return [];
        }
        return {
          id: u.id,
          value: u.name,
        };
      });
    return [{ id: "-1", value: "Nicht zugewiesen" }].concat(priorityOptions);
  }, [priorityList]);

  const renderItem = (option: ComboboxOption): ReactElement => {
    const priority = priorityList.find((s) => s.id === option.id);

    const selected = (option.id === "-1" && selectedPriority === null) || selectedPriority?.priorityId === option.id;
    return <PrioritySelectDropdownItem priority={priority} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.id === "-1") {
      setSelectedPriority(null);
      if (onSelect) {
        onSelect(null);
      }
      return;
    }

    setSelectedPriority({ priorityId: selectedOption.id });
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  useEffect(() => {
    if (props.selected) {
      setSelectedPriority(props.selected);
    }
  }, [props.selected]);

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      side={side}
    >
      {children}
    </Combobox>
  );
};
