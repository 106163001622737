import { useEffect } from "react";

import { toast } from "@/components/_ui/use-toast.ts";
import { type AsChildProps, Slot } from "@/components/slot.tsx";
import { useDeleteLabel } from "@/hooks/api/common/useLabel/useDeleteLabel";

type DeletLabelActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
    labelGroupId?: string | null;
  };
  workspace: {
    id: string;
  };
  forceDelete?: boolean;
};

export const DeletLabelAction = ({ label, asChild, forceDelete, ...props }: DeletLabelActionProps) => {
  const Comp = asChild ? Slot : "button";

  const { deleteLabel } = useDeleteLabel({
    onCompleted: () => {
      toast({
        title: "Label gelöscht",
        description: "Wir haben die Label erfolgreich gelöscht.",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler beim Löschen der Label aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (forceDelete) {
      triggerDeleteLabel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceDelete]);

  async function triggerDeleteLabel() {
    await deleteLabel({ variables: { input: { id: label.id } } });
  }

  function handleClick() {
    triggerDeleteLabel();
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default DeletLabelAction;
