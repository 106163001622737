import { useMemo } from "react";

import { getLabelQueryDocument } from "@/graphql/common/label";
import { useQuery } from "@apollo/client";

export interface UseGetLabelProps {
  id?: string | null;
  enabled?: boolean;
}

export const useGetLabel = ({ id, enabled = true }: UseGetLabelProps) => {
  const queryResult = useQuery(getLabelQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id || !enabled,
  });

  const label = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.label;
  }, [queryResult.data]);

  return {
    label,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
