import { currentUserQueryDocument } from "@/graphql/common/user";
import { workspaceMembershipListForWorkspaceQueryDocument } from "@/graphql/common/workspace-membership";
import { Superuser_CreateWorkspaceMembershipMutation } from "@/graphql/generated/graphql";
import { superuser_workspaceListQueryDocument } from "@/graphql/superuser";
import { superuser_createWorkspaceMembershipMutationDocument } from "@/graphql/superuser/mutations/workspace-membership.mutations";
import { useMutation } from "@apollo/client";

export type CreatedWorkspaceMembership =
  Superuser_CreateWorkspaceMembershipMutation["superuser_createWorkspaceMembership"];

export interface UseCreateWorkspaceMembershipProps {
  workspace: {
    id: string;
  };
  onCompleted?: (o: CreatedWorkspaceMembership | undefined) => void;
  onError?: (e: Error) => void;
}

export const useWorkspaceMembershipCreate = ({ onCompleted, onError, ...props }: UseCreateWorkspaceMembershipProps) => {
  const [createWorkspaceMembership, queryResult] = useMutation(superuser_createWorkspaceMembershipMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: workspaceMembershipListForWorkspaceQueryDocument,
        variables: {
          input: props.workspace.id,
        },
      },
      {
        query: superuser_workspaceListQueryDocument,
      },
      {
        query: currentUserQueryDocument,
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const createdWorkspaceMembership = o?.superuser_createWorkspaceMembership as CreatedWorkspaceMembership;

    if (onCompleted) {
      onCompleted(createdWorkspaceMembership ?? undefined);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    createWorkspaceMembership,
  };
};
