import { graphql } from "../../generated/gql";

export const superuser_createWorkspaceMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_createWorkspace($input: WorkspaceCreateInput!) {
    superuser_createWorkspace(workspaceCreateInput: $input) {
      id
      name
      workspaceUrl
      logo
      createdAt
      updatedAt
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
      phaseList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
      unitList {
        id
        workspaceId
        title
        symbol
        keyResults {
          id
          climateActionId
        }
      }
    }
  }
`);

export const superuser_duplicateWorkspaceMutationDocument = graphql(/* GraphQL */ `
  mutation superuser_duplicateWorkspace($input: WorkspaceDuplicateInput!) {
    superuser_duplicateWorkspace(workspaceDuplicateInput: $input) {
      id
      name
      workspaceUrl
      logo
      createdAt
      updatedAt
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
      priorityList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
      phaseList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
    }
  }
`);
