import { createClimateActionMutationDocument } from "@/graphql/common/climate-action";
import { useMutation } from "@apollo/client";

import { onClimateActionCreate } from "./_utils";
import { EVENT_CLIMATE_ACTION_CREATED, trackClimateActionEvent } from "./tracking";

type ClimateActionFragment = {
  id: string;
};

export interface UseCreateClimateActionProps {
  onCompleted?: (o: ClimateActionFragment | undefined) => void;
  onError?: (e: Error) => void;
}

export const useCreateClimateAction = ({ onCompleted, onError }: UseCreateClimateActionProps) => {
  const [createClimateAction] = useMutation(createClimateActionMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const climateAction = data?.createClimateAction;
      if (!climateAction) {
        return;
      }

      // Update the cache with the new climate action
      onClimateActionCreate(cache, climateAction);

      trackClimateActionEvent(EVENT_CLIMATE_ACTION_CREATED, {
        id: climateAction.id,
        title: climateAction.title,
        workspaceId: climateAction.workspaceId,
      });
    },
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.createClimateAction?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createClimateAction,
  };
};
