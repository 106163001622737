import { cn } from "@/lib/utils";
import { Column } from "@tanstack/react-table";

interface ClimateActionListColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function ClimateActionListColumnHeader<TData, TValue>({
  title,
  className,
}: ClimateActionListColumnHeaderProps<TData, TValue>) {
  return <div className={cn(className)}>{title}</div>;
}
