import { useMemo } from "react";

import { PhaseList } from "@/components/_domain/phase/phase-type";
import { getPhaseListForWorkspaceQueryDocument } from "@/graphql/common/phase";
import { useQuery } from "@apollo/client";

export interface UseGetPhaseListForWorkspaceProps {
  workspaceId: string;
  enabled?: boolean;
}

export const useGetPhaseListForWorkspace = ({ workspaceId, enabled = true }: UseGetPhaseListForWorkspaceProps) => {
  const queryResult = useQuery(getPhaseListForWorkspaceQueryDocument, {
    variables: { input: { workspaceId } },
    skip: !workspaceId || !enabled,
  });

  const phaseListForWorkspace = useMemo(() => {
    const maybePhaseList = queryResult.data?.phaseListForWorkspace;
    if (!maybePhaseList) {
      return [];
    }

    return maybePhaseList.map((p) => {
      let order = 0;

      if (p.type === PhaseList.backlog) {
        order = 100;
      }
      if (p.type === PhaseList.unstarted) {
        order = 200;
      }
      if (p.type === PhaseList.started) {
        order = 300;
      }
      if (p.type === PhaseList.completed) {
        order = 400;
      }
      if (p.type === PhaseList.canceled) {
        order = 500;
      }
      return {
        ...p,
        order,
      };
    });
  }, [queryResult.data]);

  return {
    phaseListForWorkspace,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
