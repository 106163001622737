import { useLocation, useSearchParams } from "react-router-dom";

import { ClimateActionCreateDialog } from "@/components/_domain/climate-action/climate-action-create-dialog/climate-action-create-dialog";
import { ClimateActionListOverview } from "@/components/_domain/climate-action/climate-action-list-overview";
import { ClimateActionListConfigurationView } from "@/components/_domain/climate-action/climate-action-list-overview/climate-action-list-configuration-pane-view";
import { ClimateActionListFilterView } from "@/components/_domain/climate-action/climate-action-list-overview/climate-action-list-filter-pane-view";
import { ClimateActionListProviderStackInitializer } from "@/components/_domain/climate-action/climate-action-list-overview/utils/climate-action-list-provider-stack-initializer";
import { useCurrentClimateActionListContext } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { useCurrentTeamContext } from "@/components/_domain/team/hooks/useCurrentTeamContext";
import { makeTeamUrl } from "@/components/_domain/team/lib/make-team-url";
import { TeamView } from "@/components/_domain/team/team-view";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
// Actual Page
import { HeaderLayout } from "@/components/_layout/header-layout";
import { Link } from "@/components/link";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";
// Page Context
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

// Page Instrumentalization (Providers, Table Configuration, etc.)
import { useTableConfigurationForTeamPage } from "./useTableConfigurationForTeamPage";

export const TeamClimateActionListPage = () => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { currentUser } = useCurrentUserContext();
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentTeam } = useCurrentTeamContext();
  const { currentClimateActionList } = useCurrentClimateActionListContext();

  // the initial default table configuration
  const tableConfiguration = useTableConfigurationForTeamPage({
    workspace: currentWorkspace,
  });

  return (
    <ClimateActionListProviderStackInitializer
      pathname={pathname}
      searchParams={searchParams}
      workspace={currentWorkspace}
      user={currentUser}
      climateActionList={currentClimateActionList}
      config={tableConfiguration}
    >
      <div className="flex h-full max-h-full flex-col overflow-y-hidden">
        <HeaderLayout>
          <div className="flex flex-row items-center gap-2">
            <Link to={makeTeamUrl({ workspace: currentWorkspace, team: currentTeam, path: "" })}>
              <TeamView team={currentTeam} />
            </Link>
            <div>›</div>
            <div className="flex max-w-[8rem] flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
              Maßnahmen
            </div>
            <FavoriteButton pageName="Maßnahmen" />
          </div>
          <div className="flex flex-row items-center gap-2">
            <ClimateActionCreateDialog workspace={currentWorkspace} team={currentTeam} />
          </div>
        </HeaderLayout>

        <ClimateActionListConfigurationView
          config={{
            quicksearch: true,
            filterButton: true,
            displayOptionsButton: true,
            savedViewsMenu: true,
          }}
        />
        <ClimateActionListFilterView />
        <ClimateActionListOverview />
      </div>
    </ClimateActionListProviderStackInitializer>
  );
};
