import { type FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";

import { useClimateActionListState } from "@/components/_domain/climate-action/hooks";

import { useGlobalSavedViewContext } from "../../../saved-view/hooks/useGlobalSavedViewContext";
import { TableConfiguration } from "../../../saved-view/models/saved-views-model";

const DEBUG_SAVED_VIEW_SYNC = false;

const debugLog = (...args: unknown[]) => {
  if (DEBUG_SAVED_VIEW_SYNC) {
    console.log(...args);
  }
};

const isSameTableConfiguration = (a: TableConfiguration, b: TableConfiguration) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

interface SavedViewTableSyncCoordinatorProps extends PropsWithChildren {
  pathname: string;
  workspaceId: string;
}

// The purpose of this component is to synchronize the table state with the saved view state.
// It does so by listening to changes in the table state and the selected view in the saved view context.
// There are several pitfals in the render cycle, that are caused by the different components making changes to either the table state or the saved view state.
export const SavedViewTableSyncCoordinator: FC<SavedViewTableSyncCoordinatorProps> = ({
  children,
  pathname,
  workspaceId,
}) => {
  const {
    columnFilters,
    columnVisibility,
    columnOrder,
    expandedState,
    sorting,
    rowSelectionState,
    globalFilterState,
    setTableConfiguration,
  } = useClimateActionListState(); // the state of the climate state
  const { getSavedViewState, setCurrentView, createSavedView } = useGlobalSavedViewContext(); // the state of the saved view context

  const mountedRef = useRef(false);
  const [autoCreateSavedViewHasFired, setAutoCreateSavedViewHasFired] = useState(false);

  const currentSavedViewState = useMemo(() => {
    return getSavedViewState(pathname);
  }, [pathname, getSavedViewState]);

  const currentTableState = useMemo(() => {
    return {
      climateActions: {
        columnFilters: columnFilters,
        columnVisibility: columnVisibility,
        columnOrder: columnOrder,
        sorting: sorting,
        expanded: expandedState,
        rowSelection: rowSelectionState,
        globalFilter: globalFilterState,
      },
    };
  }, [columnFilters, columnVisibility, columnOrder, sorting, expandedState, rowSelectionState, globalFilterState]);

  // Applies a configuration to the current view in the saved view context
  const applyConfigurationCurrentView = (tableConfiguration: TableConfiguration) => {
    const state = getSavedViewState(pathname);

    setCurrentView(pathname, {
      ...state.currentView,
      tableConfiguration: tableConfiguration,
    });
  };

  // Applies a configuration to the table state
  const applyConfigurationToTable = (tableConfiguration: TableConfiguration) => {
    const { columnFilters, columnVisibility, columnOrder, sorting, expanded, rowSelection, globalFilter } =
      tableConfiguration.climateActions ?? {};
    setTableConfiguration({
      columnFilters: columnFilters ?? [],
      columnVisibility: columnVisibility ?? {},
      columnOrder: columnOrder ?? [],
      sorting: sorting ?? [],
      expanded: expanded ?? {},
      rowSelection: rowSelection ?? {},
      globalFilter: globalFilter ?? "",
    });
  };

  useEffect(() => {
    // If this view is loaded for the first time and no views exist,
    // create a new saved view with default values
    if (!mountedRef.current) {
      // to avoid auto creationg two views on first render in **dev mode**
      mountedRef.current = true;
      return;
    }

    if (autoCreateSavedViewHasFired) {
      return;
    }
    if (!currentSavedViewState.__fullyLoadedFromContextProvider) {
      return;
    }

    if (currentSavedViewState.loading) {
      return;
    }
    if (currentSavedViewState.savedViews.length > 0) {
      return;
    }

    if (autoCreateSavedViewHasFired) {
      return;
    }

    setAutoCreateSavedViewHasFired(true);

    createSavedView(pathname, {
      name: "Default",
      tableConfiguration: currentTableState,
      url: pathname,
      workspaceId: workspaceId,
      isDefault: true,
      isBaseView: true,
    });

    // only run this effect when the saved view state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSavedViewState]);

  useEffect(() => {
    // Runs everytime the **table state changes** (i.e, the use makes changes to the table)

    debugLog("[6.2] SavedViewTableSyncCoordinator (useEffect) – tableState changed");

    const state = getSavedViewState(pathname);
    // 1. Check whether the state of the saved view and the table state are in sync
    //    - we compare against the "currentView" of the saved view context
    if (isSameTableConfiguration(state.currentView.tableConfiguration, currentTableState)) {
      debugLog("[6.2.1] SavedViewTableSyncCoordinator (useEffect) – EARLY RETURN");
      return;
    }

    // 2. If they are not in sync, update the saved view
    debugLog("[6.2.2] SavedViewTableSyncCoordinator (useEffect) – applyConfigurationCurrentView", currentTableState);
    applyConfigurationCurrentView(currentTableState);

    // We only want to run this effect when the table state changes and NOT when the current view changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTableState]);

  useEffect(() => {
    // Runs everytime the **selected view** changes (i.e, the selects a saved view and applies it)
    const { selectedView, currentView } = currentSavedViewState;

    debugLog("[6.1] SavedViewTableSyncCoordinator (useEffect) – selectedView changed");

    // 1. In this case we ALWAYS want to apply the new savedView to the table
    const tableConfiguration = currentSavedViewState.selectedView.tableConfiguration ?? {};
    debugLog("[6.1.1] SavedViewTableSyncCoordinator (useEffect) – applyConfigurationToTable", tableConfiguration);
    debugLog("[6.1.1.1] applyConfigurationToTable Current state", currentSavedViewState);
    debugLog("[6.1.1.2] applyConfigurationToTable actualTableState", currentTableState);

    if (isSameTableConfiguration(tableConfiguration, currentTableState)) {
      debugLog("[6.1.1.3] applyConfigurationToTable – Early Return bc sameConfiguration");
      return;
    }

    // We only want to propagate the changes, if a user has set a selected view.
    // This is the case when currentView and selectedView have the same tableConfiguration.
    // Otherwise, the user has made some manual changes to the table, which we don't want to override.
    const currentViewIsDirty = !isSameTableConfiguration(
      selectedView.tableConfiguration,
      currentView.tableConfiguration,
    );
    if (currentViewIsDirty) {
      debugLog("[6.1.1.4] applyConfigurationToTable – Early Return bc selected != current");
      return;
    }
    debugLog("[6.1.1.5] applyConfigurationToTable");
    applyConfigurationToTable(tableConfiguration);

    // We only want to run this effect **selected view changes**
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSavedViewState.selectedView]);

  debugLog("[6] SavedViewTableSyncCoordinator – children rendered");
  return <>{children}</>;
};
