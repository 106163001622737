import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from "react";

import { TaskStatus } from "..";

import { TaskCreateFormType } from "./task-create-form.schema";

const defaultValue: TaskCreateFormType = {
  title: "",
  description: "",
  status: TaskStatus.Backlog,
  position: 0,
};

interface CreateTaskContextType {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  newTaskForm: TaskCreateFormType;
  hasActiveChanges: boolean;
  updateNewTask: (taskCreateFormFragment: Partial<TaskCreateFormType>) => void;
  resetNewTaskForm: () => void;
}

const CreateTaskContext = createContext<CreateTaskContextType | undefined>(undefined);

// Define a type for the props that the provider will accept
interface CreateTaskContextProviderProps {
  children: ReactNode;
}

// Create the provider component
export const CreateTaskContextProvider: FC<CreateTaskContextProviderProps> = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [newTaskForm, setNewTaskForm] = useState<TaskCreateFormType>(defaultValue);

  const resetNewTaskForm = () => {
    setNewTaskForm(defaultValue);
  };

  const updateNewTask = useCallback(
    (taskCreateFormFragment: Partial<TaskCreateFormType>) => {
      const newValues = {
        ...newTaskForm,
        ...taskCreateFormFragment,
      };
      setNewTaskForm(newValues);
    },
    [newTaskForm],
  );

  const hasActiveChanges = useMemo(() => {
    if (defaultValue.title !== newTaskForm.title) {
      return true;
    }
    if (defaultValue.description !== newTaskForm.description) {
      return true;
    }
    if (defaultValue.status !== newTaskForm.status) {
      return true;
    }
    if (defaultValue.position !== newTaskForm.position) {
      return true;
    }

    return false;
  }, [newTaskForm]);

  return (
    <CreateTaskContext.Provider
      value={{
        showModal,
        setShowModal,
        newTaskForm,
        hasActiveChanges,
        resetNewTaskForm,
        updateNewTask,
      }}
    >
      {children}
    </CreateTaskContext.Provider>
  );
};

// Define the useCreateTaskProvider hook
export const useCreateTaskProvider = (): CreateTaskContextType => {
  const context = useContext(CreateTaskContext);

  if (!context) {
    throw new Error("useCreateTaskProvider must be used within a CreateTaskContextProvider");
  }

  return context;
};
