import { toast } from "@/components/_ui/use-toast.ts";
import { type AsChildProps, Slot } from "@/components/slot.tsx";
import { deleteUnitMutationDocument, getUnitListQueryDocument } from "@/graphql/common/unit";
import { useMutation } from "@apollo/client";

type UnitDeleteActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  unit: {
    id: string;
    workspaceId: string;
  };
};

export const UnitDeleteAction = ({ unit, asChild, ...props }: UnitDeleteActionProps) => {
  const Comp = asChild ? Slot : "button";

  const [deleteUnit] = useMutation(deleteUnitMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Einheit gelöscht",
        description: "Wir haben die Einheit erfolgreich gelöscht.",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler beim Löschen der Einheit aufgetreten. Stellen Sie sicher, dass keine Maßnahme diese Einheit verwendet bevor Sie sie löschen.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: getUnitListQueryDocument,
        variables: { input: { workspaceId: unit.workspaceId } },
      },
    ],
  });

  async function handleClick() {
    await deleteUnit({ variables: { input: { id: unit.id } } });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default UnitDeleteAction;
