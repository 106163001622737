import { FC, PropsWithChildren, useMemo } from "react";

import { Avatar, AvatarImage } from "@/components/_ui/avatar";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { cn } from "@/lib/utils";

import { UserType } from "./user-type";

export interface AvatarPropsWithChildren extends PropsWithChildren {
  user?: UserType;
  className?: string;
}

export const AvatarUser: FC<AvatarPropsWithChildren> = ({ className, user }) => {
  const { currentUser } = useCurrentUserContext();

  const selectedUser = useMemo(() => {
    if (!user) {
      return currentUser;
    }

    return user;
  }, [user, currentUser]);

  const fallbackInitials = useMemo(() => {
    if (!selectedUser) {
      return "";
    }

    if (selectedUser.fullname) {
      return String(selectedUser.fullname[0]).toUpperCase();
    }

    if (selectedUser.username) {
      return String(selectedUser.username[0]).toUpperCase();
    }

    return String(selectedUser.email[0]).toUpperCase();
  }, [selectedUser]);

  if (!selectedUser) {
    return <></>;
  }
  return (
    <Avatar className={cn("", className)}>
      {selectedUser.profilePicture && <AvatarImage src={selectedUser.profilePicture} />}
      {!selectedUser.profilePicture && (
        <div
          className="flex aspect-square items-center justify-center overflow-hidden rounded-full text-white"
          style={{ backgroundColor: "rgb(245 161 0)" }}
        >
          {fallbackInitials}
        </div>
      )}
    </Avatar>
  );
};

export default AvatarUser;
