import React, { createContext, FC, ReactNode, useContext, useState } from "react";

interface ClimateActionCreateHandlerContextType {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClimateActionCreateHandlerContext = createContext<ClimateActionCreateHandlerContextType | undefined>(undefined);

// Define a type for the props that the provider will accept
interface ClimateActionCreateHandlerProviderProps {
  children: ReactNode;
}

// Create the provider component
export const ClimateActionCreateHandlerProvider: FC<ClimateActionCreateHandlerProviderProps> = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <ClimateActionCreateHandlerContext.Provider
      value={{
        showModal,
        setShowModal,
      }}
    >
      {children}
    </ClimateActionCreateHandlerContext.Provider>
  );
};

// Define the useClimateActionCreateHandler hook
export const useClimateActionCreateHandler = (): ClimateActionCreateHandlerContextType => {
  const context = useContext(ClimateActionCreateHandlerContext);

  if (!context) {
    throw new Error("useClimateActionCreateHandler must be used within a ChildProvider");
  }

  return context;
};
