import { createPriorityMutationDocument } from "@/graphql/common/priority";
import { type CreatePriorityMutation } from "@/graphql/generated/graphql";
import { onGenericEntityCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PRIORITY_CREATED, trackPriorityEvent } from "./tracking";

export type CreatedPriority = CreatePriorityMutation["createPriority"];

export interface UseCreatePriorityProps {
  onCompleted?: (data: CreatedPriority) => void;
  onError?: (e: Error) => void;
}

export const useCreatePriority = ({ onCompleted, onError }: UseCreatePriorityProps) => {
  const [createPriority] = useMutation(createPriorityMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const newPriority = data?.createPriority;
      if (!newPriority) {
        return;
      }

      onGenericEntityCreate({ cache, newEntity: newPriority, typename: "Priority" });
      trackPriorityEvent(EVENT_PRIORITY_CREATED, newPriority);
    },
  });

  function onUpdateCompleted(data: CreatePriorityMutation) {
    if (onCompleted) {
      onCompleted(data.createPriority);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createPriority,
  };
};
