import { ClimateAction } from "@/components/_domain/climate-action/models";
import { compareDates } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";

import { ClimateActionListLastUpdatedCell } from "../../climate-action-list-main-view/climate-action-simple-list-view/cells/climate-action-list-last-updated-cell";
import { ClimateActionListColumnHeader } from "../../climate-action-list-main-view/climate-action-simple-list-view/headers/climate-action-list-column-header";
import { filterByDateWithUnknownPayload } from "../../filter-functions/filter-by-date";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import { FilterByLastUpdatedConfigurationView } from "./components/filter-by-last-updated-configuration-view";
import { FilterByLastUpdatedDisplayView } from "./components/filter-by-last-updated-display-view";
import { FilterByLastUpdatedFilterPaneView } from "./components/filter-by-last-updated-filter-pane-view";

interface LastUpdatedColumnDefinitionProps {
  columnId: string;
  orderValue: number;
  workspaceId: string;
}

export const LastUpdatedColumnDefinition = ({
  columnId,
  orderValue,
}: LastUpdatedColumnDefinitionProps): ClimateActionColumnConfiguration => {
  return {
    columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Zuletzt aktualisiert",
    getOrderValue: () => orderValue,
    getColumnDef: (): ColumnDef<ClimateAction> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: (row) => {
          const lastProgressSnapshot = row.progressSnapshotList?.[row.progressSnapshotList.length - 1];
          return lastProgressSnapshot?.snapshotDate;
        },
        sortingFn: (rowA, rowB) => {
          const lastProgressSnapshotA =
            rowA.original.progressSnapshotList?.[rowA.original.progressSnapshotList.length - 1];
          const lastProgressSnapshotB =
            rowB.original.progressSnapshotList?.[rowB.original.progressSnapshotList.length - 1];
          return compareDates(lastProgressSnapshotA?.snapshotDate, lastProgressSnapshotB?.snapshotDate);
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          const lastProgressSnapshot =
            row.original.progressSnapshotList?.[row.original.progressSnapshotList.length - 1];
          return filterByDateWithUnknownPayload(value, lastProgressSnapshot?.snapshotDate);
        },
        size: 1,
        header: ({ column }) => (
          <ClimateActionListColumnHeader column={column} title="" className={`px-1 text-xs font-normal`} />
        ),
        cell: ({ row }) => <ClimateActionListLastUpdatedCell row={row} />,
      };
    },

    getDisplayView: () => <FilterByLastUpdatedDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByLastUpdatedConfigurationView columnId={columnId} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "modal",
    getFilterPaneView: () => <FilterByLastUpdatedFilterPaneView columnId={columnId} />,
  };
};
