import { useCallback, useEffect, useMemo, useState } from "react";

import { KanbanBoard } from "@/components/kanban-board";
import { useUpdateTaskListMutation } from "@/hooks/api/common/useTask/useUpdateTaskList";

import { Task, TaskStatus } from "../task-type";

import { DEFAULT_COLUMNS } from "./task-board-columns";
import { TaskColumn, TaskColumnConfig, TaskItem, TaskItemConfig } from "./task-board-types";
import { TaskCard } from "./task-card";
import { TaskColumnFooter } from "./task-column-footer";
import { TaskColumnHeader } from "./task-column-header";

interface TaskBoardProps {
  tasks: Task[];
}
export function TaskBoard(props: TaskBoardProps) {
  const [columns, setColumns] = useState<TaskColumn[]>([...DEFAULT_COLUMNS]);

  const [localTaskItemList, setLocalTaskItemList] = useState<TaskItem[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { updateTaskList } = useUpdateTaskListMutation();

  const localTaskListByStatus = useMemo(() => {
    return localTaskItemList.reduce((acc: Record<string, Task[]>, task: Task) => {
      acc[task.status] = [...(acc[task.status] || []), task];
      return acc;
    }, {});
  }, [localTaskItemList]);

  useEffect(() => {
    // update the local task list whenever the taskList is refetched, expect when updates are in progress

    if (isDirty) {
      return;
    }

    if (props.tasks) {
      setLocalTaskItemList(
        props.tasks
          .slice()
          .map((t) => {
            return { ...t, columnId: t.status };
          })
          .sort((a, b) => a.position - b.position),
      );
    }
  }, [props.tasks, isDirty]);

  useEffect(() => {
    // if a task was inserted into a column, the position of existing tasks
    // in that column might have changed. We want to update these tasks.
    if (!isDirty) {
      return;
    }

    type TaskUpdate = { id: string; status?: string | undefined; position?: number | undefined };

    const _bulkUpdateTasks = async (tasksMarkedForUpdate: TaskUpdate[]) => {
      const result = await updateTaskList({
        variables: {
          input: {
            taskList: tasksMarkedForUpdate,
          },
        },
      });

      if (result.errors) {
        console.error("Error updating tasks:", result.errors);
        setIsDirty(false);
      } else {
        setIsDirty(false);
      }
    };

    const tasksMarkedForUpdate: TaskUpdate[] = [];

    for (const status of Object.keys(localTaskListByStatus)) {
      const localTasksForStatus = localTaskListByStatus[status];

      for (const localTask of localTasksForStatus) {
        const taskFromAPI = props.tasks?.find((t) => t.id === localTask.id);

        if (!taskFromAPI) {
          continue;
        }

        const hasNewPosition = localTask.position !== taskFromAPI.position;
        // position might stay the same if a task is moved between columns
        const hasNewStatus = localTask.status !== taskFromAPI.status;

        if (hasNewPosition || hasNewStatus) {
          tasksMarkedForUpdate.push({
            id: localTask.id,
            status: hasNewStatus ? localTask.status : undefined,
            position: hasNewPosition ? localTask.position : undefined,
          });
        }
      }
    }

    if (tasksMarkedForUpdate.length === 0) {
      setIsDirty(false);
      return;
    }

    _bulkUpdateTasks(tasksMarkedForUpdate);
  }, [isDirty, localTaskListByStatus, updateTaskList, props.tasks]);

  const handleSetTasks = useCallback((items: TaskItem[]) => {
    setLocalTaskItemList(items.map((item) => ({ ...item, status: item.columnId as TaskStatus })));
  }, []);

  const renderItem = useCallback((item: TaskItemConfig) => {
    return <TaskCard task={item.data} isOverlay={item.isOverlay} isOver={item.isOver} />;
  }, []);

  const renderColumnHeader = useCallback((config: TaskColumnConfig) => {
    return <TaskColumnHeader column={config.column} />;
  }, []);

  const renderColumnFooter = useCallback((config: TaskColumnConfig) => {
    return <TaskColumnFooter column={config.column} />;
  }, []);

  return (
    <>
      <KanbanBoard<TaskItem>
        columns={columns}
        items={localTaskItemList}
        setColumns={setColumns}
        setItems={handleSetTasks}
        setIsDirty={setIsDirty}
        renderItem={renderItem}
        renderColumnHeader={renderColumnHeader}
        renderColumnFooter={renderColumnFooter}
      />
    </>
  );
}
