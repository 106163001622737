import { FC } from "react";

import { Badge } from "@/components/_ui/badge";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace";
import { cn } from "@/lib/utils";

import { AvatarWorkspace } from ".";

export interface WorkspaceBadgeProps {
  workspace: {
    id: string;
  };
  className?: string;
}
export const WorkspaceBadge: FC<WorkspaceBadgeProps> = ({ ...props }) => {
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });

  if (!workspace) {
    return <></>;
  }

  return (
    <Badge variant="outline" className={cn(["py-2 opacity-40", props.className])}>
      <div className="flex flex-row items-center gap-1">
        <AvatarWorkspace workspace={workspace} className="h-5 w-5 text-xxs" />
        <span className="ml-1 overflow-hidden text-ellipsis font-normal">{workspace.name}</span>
      </div>
    </Badge>
  );
};
