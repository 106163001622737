import { useCallback } from "react";

import { currentUserQueryDocument } from "@/graphql/common/user";
import { renameUserFavoriteMutationDocument } from "@/graphql/common/user-favorite";
import { useMutation } from "@apollo/client";

import { EVENT_USER_FAVORITE_UPDATED, trackUserFavoriteEvent } from "./tracking";

export interface UseRenameUserFavoriteProps {
  id: string;
  pageName: string;
}

export const useRenameUserFavorite = () => {
  const [renameFavoriteMutation] = useMutation(renameUserFavoriteMutationDocument, {
    update: (_cache, { data }) => {
      const userFavorite = data?.renameUserFavorite;
      if (!userFavorite) {
        return;
      }
      trackUserFavoriteEvent(EVENT_USER_FAVORITE_UPDATED, {
        userFavoriteId: userFavorite.id,
      });
    },
  });

  // Todo: refactor (no unneeded useCallback, update cach instead of refetchQueries)
  const renameFavorite = useCallback(
    async ({ id, pageName }: UseRenameUserFavoriteProps) => {
      await renameFavoriteMutation({
        variables: {
          userFavoriteRenameInput: {
            id,
            pageName,
          },
        },
        refetchQueries: [currentUserQueryDocument],
      });
    },
    [renameFavoriteMutation],
  );

  return {
    renameFavorite,
    mutation: renameFavoriteMutation,
  };
};
