import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const fileQueryDocument = graphql(/* GraphQL */ `
  query file($input: FileGetInput!) {
    file(fileGetInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

export const fileListForOwnerQueryDocument = graphql(/* GraphQL */ `
  query fileListForOwner($input: FileListGetByOwnerInput!) {
    fileListForOwner(fileListGetByOwnerInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

// ============== MUTATIONS ======================
export const createFileMutationDocument = graphql(/* GraphQL */ `
  mutation createFile($input: FileCreateInput!) {
    createFile(fileCreateInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

export const updateFileMutationDocument = graphql(/* GraphQL */ `
  mutation updateFile($input: FileUpdateInput!) {
    updateFile(fileUpdateInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

export const replaceFileMutationDocument = graphql(/* GraphQL */ `
  mutation replaceFile($input: FileReplaceInput!) {
    replaceFile(fileReplaceInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);

export const deleteFileMutationDocument = graphql(/* GraphQL */ `
  mutation deleteFile($input: FileDeleteInput!) {
    deleteFile(fileDeleteInput: $input) {
      id
      ownerId
      ownerType
      name
      size
      createdAt
      updatedAt
    }
  }
`);
