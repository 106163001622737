import { FC } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/_ui/avatar";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace";
import { cn } from "@/lib/utils";

export interface AvatarWorkspaceProps {
  workspace: {
    id: string;
  };
  className?: string;
}
export const AvatarWorkspace: FC<AvatarWorkspaceProps> = ({ className, ...props }) => {
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });

  if (!workspace) {
    return <></>;
  }

  return (
    <Avatar className={cn("rounded-md", className)}>
      {workspace.logo && <AvatarImage src={workspace.logo} />}
      <AvatarFallback className="rounded-md text-white" style={{ backgroundColor: "rgb(245 161 0)" }}>
        {workspace.name.slice(0, 2).toUpperCase()}
      </AvatarFallback>
    </Avatar>
  );
};

export default AvatarWorkspace;
