import { useEffect } from "react";

import { toast } from "@/components/_ui/use-toast.ts";
import { type AsChildProps, Slot } from "@/components/slot.tsx";
import { useDeleteLabelGroup } from "@/hooks/api/common/useLabel/useDeleteLabelGroup";

type DeletLabelGroupActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  labelGroup: {
    id: string;
  };
  workspace: {
    id: string;
  };
  forceDelete?: boolean;
};

export const DeletLabelGroupAction = ({ labelGroup, asChild, forceDelete, ...props }: DeletLabelGroupActionProps) => {
  const Comp = asChild ? Slot : "button";

  const { deleteLabelGroup } = useDeleteLabelGroup({
    onCompleted: () => {
      toast({
        title: "Label gelöscht",
        description: "Wir haben das Label erfolgreich gelöscht.",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description: "Leider ist ein Fehler beim Löschen des Labels aufgetreten.",
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (forceDelete) {
      triggerDeleteLabel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceDelete]);

  async function triggerDeleteLabel() {
    await deleteLabelGroup({ variables: { input: { id: labelGroup.id } } });
  }

  function handleClick() {
    triggerDeleteLabel();
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default DeletLabelGroupAction;
