import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getFocusAreaInstanceQueryDocument = graphql(/* GraphQL */ `
  query GetFocusAreaInstance($input: GetFocusAreaInstanceInput!) {
    focusAreaInstance(getFocusAreaInstanceInput: $input) {
      id
      focusAreaId
      ownerId
      ownerType
      focusArea {
        id
        type
        name
        color
        workspaceId
      }
    }
  }
`);

export const getFocusAreaInstancebyOwnerQueryDocument = graphql(/* GraphQL */ `
  query GetFocusAreaInstanceByOwner($input: GetFocusAreaInstanceByOwnerInput!) {
    focusAreaInstanceByOwner(getFocusAreaInstanceByOwnerInput: $input) {
      id
      focusAreaId
      ownerId
      ownerType
      focusArea {
        id
        type
        name
        color
        workspaceId
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createFocusAreaInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation createFocusAreaInstance($input: CreateFocusAreaInstanceInput!) {
    createFocusAreaInstance(createFocusAreaInstanceInput: $input) {
      id
      workspaceId
      focusAreaId
      ownerId
      ownerType
      focusArea {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);

export const updateFocusAreaInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation updateFocusAreaInstance($input: UpdateFocusAreaInstanceInput!) {
    updateFocusAreaInstance(updateFocusAreaInstanceInput: $input) {
      id
      workspaceId
      focusAreaId
      ownerId
      ownerType
      focusArea {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);

export const deleteFocusAreaInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation deleteFocusAreaInstance($input: DeleteFocusAreaInstanceInput!) {
    deleteFocusAreaInstance(deleteFocusAreaInstanceInput: $input) {
      id
      workspaceId
      focusAreaId
      ownerId
      ownerType
      focusArea {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);
