import { EditorState } from "lexical";

import { cn } from "@/lib/utils";
import { TRANSFORMERS } from "@lexical/markdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";

import { FloatingMenuPlugin } from "./plugins/floating-menu-plugin";
import { ParagraphPlaceholderPlugin } from "./plugins/parapgraph-placeholder-plugin";
import RichTextToolbar from "./rich-text-toolbar/RichTextToolbar";
import {
  AutoLinkPlugin,
  ClickableLinkPlugin,
  CustomRichTextPlugin,
  LinkPlugin,
  VariableInterpolationsPlugin,
} from "./plugins";
import { richTextNodes } from "./rich-text-nodes";
import { theme } from "./rich-text-theme";

export type NotificationInterpolationOption = {
  key: string;
};

interface EditorProps {
  availableInterpolations?: NotificationInterpolationOption[];
  onChange?: (value: string, json: string) => void;
  initialValue?: string;
  hideFloatingMenu?: boolean;
  readOnly?: boolean;
  className?: string;
  placeholder?: string;
}

export const RichTextEditor = ({
  onChange,
  initialValue,
  readOnly,
  hideFloatingMenu,
  className,
  placeholder,
}: EditorProps) => {
  const handleOnChange = (editorState: EditorState) => {
    editorState.read(() => {
      const json = editorState.toJSON();
      const plainText = editorState._nodeMap.get("root")?.__cachedText ?? "";

      if (onChange) {
        onChange(plainText, JSON.stringify(json));
      }
    });
  };

  const editorConfig = {
    theme,
    editorState: initialValue,
    onError(error: unknown) {
      console.warn("editor error", error);
    },
    nodes: richTextNodes,
    namespace: "ark-monitoring",
    editable: !readOnly,
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="relative rounded-sm">
        <CustomRichTextPlugin
          contentEditable={
            <ContentEditable
              className={cn([
                "overflow-x-auto overflow-y-auto p-3 outline-none dark:caret-white",
                readOnly ? "" : "min-h-[8rem]",
                className ? className : "",
              ])}
              aria-labelledby="rich-text-editor"
            />
          }
          placeholder={
            <div className="pointer-events-none absolute left-0 top-0 min-h-[8rem] select-none p-3 text-sm  text-muted-foreground">
              {placeholder ?? "Geben Sie eine Beschreibung ein ..."}
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoLinkPlugin />
        <ClickableLinkPlugin />
        <LinkPlugin />
        <VariableInterpolationsPlugin />
        <ListPlugin />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <OnChangePlugin onChange={handleOnChange} />
        {!readOnly && !hideFloatingMenu && (
          <FloatingMenuPlugin
            // This component represents the rendered menu. You can include any formatting
            // options. FloatingMenuPlugin takes care of rendering it at the right position.
            MenuComponent={RichTextToolbar}
            // optional – Define to which HTML element the menu gets appended to
            element={document.body}
          />
        )}
        {!readOnly && !hideFloatingMenu && (
          <ParagraphPlaceholderPlugin
            placeholder={placeholder ?? "Drücken Sie 'Tab' um das Menü zu öffnen"}
            hideOnEmptyEditor
          />
        )}
      </div>
    </LexicalComposer>
  );
};
