import { FC, PropsWithChildren, useMemo } from "react";

import { useGetLabelGroupListForWorkspace } from "@/hooks/api/common/useLabel";

import WorkspaceLabelGroupListItem from "./workspace-label-group-list-item";

export interface WorkspaceLabelGroupListPropsWithChildren extends PropsWithChildren {
  workspace: {
    id: string;
  };
  className?: string;
}

export const WorkspaceLabelGroupList: FC<WorkspaceLabelGroupListPropsWithChildren> = ({ workspace }) => {
  const { labelGroupList } = useGetLabelGroupListForWorkspace({ workspaceId: workspace.id });

  const sortedLabelGrouplist = useMemo(() => {
    return labelGroupList.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [labelGroupList]);

  return (
    <>
      {sortedLabelGrouplist.map((group) => (
        <WorkspaceLabelGroupListItem key={group.id} labelGroup={group} workspace={workspace} />
      ))}
    </>
  );
};

export default WorkspaceLabelGroupList;
