import { FC } from "react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/_ui/alert-dialog";
import { AlertDialogProps } from "@radix-ui/react-alert-dialog";

export interface DeleteConfirmationProps extends AlertDialogProps {
  title?: string;
  description?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const DeleteConfirmation: FC<DeleteConfirmationProps> = (props) => {
  const title = props.title ?? "Sind Sie sich sicher?";
  const description =
    props.description ?? "Diese Aktion kann nicht rückgängig gemacht werden. Die Daten werden unwiderruflich gelöscht.";
  const handleCancelClicked = props.onCancel;
  const handleConfirmClicked = props.onConfirm;

  return (
    <>
      <AlertDialog {...props}>
        <AlertDialogContent className="top-[1%] translate-y-[0%] md:top-[30%]">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-left text-base font-medium">{title}</AlertDialogTitle>
            <AlertDialogDescription className="text-left">{description}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-2 flex flex-row items-center justify-end gap-2">
            <AlertDialogCancel className="m-0 h-9 rounded-md px-2" onClick={handleCancelClicked}>
              {props.cancelButtonText ?? "Abbrechen"}
            </AlertDialogCancel>
            <AlertDialogAction className="m-0 h-9 rounded-md px-2" onClick={handleConfirmClicked}>
              {props.confirmButtonText ?? "Löschen"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DeleteConfirmation;
