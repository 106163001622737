import { useCallback } from "react";

import { currentUserQueryDocument } from "@/graphql/common/user";
import { createUserFavoriteMutationDocument } from "@/graphql/common/user-favorite";
import { useMutation } from "@apollo/client";

import { EVENT_USER_FAVORITE_CREATED, trackUserFavoriteEvent } from "./tracking";

export interface UseCreateUserFavoriteProps {
  pageUrl: string;
  pageName: string;
}

export const useCreateUserFavorite = () => {
  const [createFavoriteMutation] = useMutation(createUserFavoriteMutationDocument, {
    update: (_cache, { data }) => {
      const userFavorite = data?.createUserFavorite;
      if (!userFavorite) {
        return;
      }
      trackUserFavoriteEvent(EVENT_USER_FAVORITE_CREATED, {
        userFavoriteId: userFavorite.id,
      });
    },
  });

  // Todo: refactor (no unneeded useCallback, update cach instead of refetchQueries)
  const createFavorite = useCallback(
    async ({ pageUrl, pageName }: UseCreateUserFavoriteProps) => {
      await createFavoriteMutation({
        variables: {
          userFavoriteCreateInput: {
            pageUrl,
            pageName,
          },
        },
        refetchQueries: [currentUserQueryDocument],
      });
    },
    [createFavoriteMutation],
  );

  return {
    createFavorite,
    mutation: createFavoriteMutation,
  };
};
