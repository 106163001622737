import * as z from "zod";

export const FocusAreaSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  name: z.string(),
  color: z.string(),
  type: z.string(),
});

export type FocusArea = z.infer<typeof FocusAreaSchema>;
