import { FC } from "react";

import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import IconLinkCopy from "@/components/_icons/IconLinkCopy";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { Sheet, SheetMainContent, SheetTopContent } from "@/components/_layout/sheet";
import { Button } from "@/components/_ui/button";
import { toast } from "@/components/_ui/use-toast";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

import { ClimateActionAssigneeView } from "./assignee/climate-action-assignee-view";
import { ClimateActionPhaseView } from "./phase/climate-action-phase-view";
import { ClimateActionPriorityView } from "./priority/climate-action-priority-view";
import { ClimateActionTeamInstanceView } from "./team-instance/climate-action-team-instance-view";
import { ClimateActionFocusAreaView } from "./focusArea";
import { ClimateActionKeyResultView } from "./key-result";
import { ClimateActionLabelView } from "./label";

interface ClimateActionSheetProps {
  climateAction: {
    id: string;
  };
}
export const ClimateActionSheet: FC<ClimateActionSheetProps> = (props) => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentUser } = useCurrentUserContext();
  const { climateAction } = useGetClimateAction({ id: props.climateAction.id });

  async function handleCopyClimateActionLink() {
    if (!climateAction) {
      return;
    }

    const url = `${location.origin}/${currentWorkspace.workspaceUrl}/climate-actions/${climateAction.id}`;
    await navigator.clipboard.writeText(url);

    if ((await navigator.clipboard.readText()) !== url) {
      toast({
        title: "Fehler",
        description: "Leider ist ein Fehler aufgetreten. Der Link konnte nicht kopiert werden",
        variant: "error",
      });
    }
    toast({
      description: "Der Link zu dieser Maßnahme wurde erfolgreich kopiert",
      variant: "default",
    });
  }

  if (!currentUser) {
    return <></>;
  }

  return (
    <Sheet userCanResize={true}>
      {/* Top Section of the Sheet */}
      <SheetTopContent>
        <HeaderLayout className="w-full pr-12">
          <div className="flex flex-row items-center">
            <Button variant="ghost" size="icon" onClick={handleCopyClimateActionLink}>
              <IconLinkCopy className="text-muted-foreground" />
            </Button>
          </div>
          <div className="flex flex-row items-center"></div>
        </HeaderLayout>
      </SheetTopContent>

      {/* Middle Section of the Sheet */}
      {/* Note: offset for calc(): 56px top, 13px padding */}
      <SheetMainContent className="max-h-[calc(100vh-69px)] overflow-y-scroll">
        {climateAction && (
          <>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ClimateActionPhaseView climateAction={climateAction} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ClimateActionPriorityView climateAction={climateAction} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ClimateActionAssigneeView climateAction={climateAction} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ClimateActionTeamInstanceView ownerId={climateAction.id} workspaceId={currentWorkspace.id} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ClimateActionKeyResultView climateAction={climateAction} workspace={currentWorkspace} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ClimateActionFocusAreaView climateAction={climateAction} workspace={currentWorkspace} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ClimateActionLabelView workspace={currentWorkspace} climateAction={climateAction} />
            </div>
          </>
        )}
      </SheetMainContent>
    </Sheet>
  );
};

export default ClimateActionSheet;
