import { useMemo } from "react";
import { Plus } from "lucide-react";

import { Button } from "@/components/_ui/button";
import { useBoardState } from "@/components/kanban-board";

import { useCreateTaskProvider } from "../task-create-dialog";
import { TaskStatus } from "../task-type";

import { TaskColumn, TaskItem } from "./task-board-types";
interface TaskColumnHeaderProps {
  column: TaskColumn;
}

export function TaskColumnHeader({ column }: TaskColumnHeaderProps) {
  const { setShowModal: setShowCreateTaskModal, updateNewTask } = useCreateTaskProvider();
  const { items } = useBoardState<TaskItem>();

  const tasksInColumn = useMemo(() => {
    return items.filter((c) => c.columnId === column.id) || [];
  }, [items, column.id]);

  const taskStatus = useMemo(() => {
    if (Object.values(TaskStatus).includes(column.id as TaskStatus)) {
      return column.id as TaskStatus;
    }
    return null;
  }, [column.id]);

  return (
    <div className="flex">
      <div className="flex flex-1 flex-row gap-2 px-2 pb-4 text-sm ">
        <div>{column.title}</div>
        <div className="text-muted-foreground">{tasksInColumn.length}</div>
      </div>

      <Button
        variant="ghost"
        size="icon"
        onClick={() => {
          if (taskStatus) {
            updateNewTask({ status: taskStatus });
            setShowCreateTaskModal(true);
          } else {
            console.error("Task status is not valid");
          }
        }}
        data-testid="new-task-column-button"
        aria-label={`Neue Aufgabe zur Spalte ${column.title} hinzufügen`}
        title={`Neue Aufgabe zur Spalte ${column.title} hinzufügen`}
      >
        <Plus className="h-4 w-4" />
      </Button>
    </div>
  );
}
