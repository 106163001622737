import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getClimateActionListQueryDocument = graphql(/* GraphQL */ `
  query GetClimateActionListForWorkspace($input: GetClimateActionListForWorkspaceInput!) {
    climateActionListForWorkspace(getClimateActionListForWorkspaceInput: $input) {
      id
      title
      properties
      parentId
      workspaceId
      parent {
        id
        title
        properties
        parentId
      }
      children {
        id
        title
        properties
        parentId
      }
      keyResult {
        id
        climateActionId
        unitId
        title
        description
        initialValue
        targetValue
        currentValue
        startDate
        targetDate
        type
        properties
        unit {
          id
          title
          symbol
        }
      }
      progressSnapshotList {
        id
        climateActionId
        keyResultId
        userId
        value
        status
        description
        snapshotDate
        properties
      }
      assignee {
        id
        userId
        teamId
        ownerId
        ownerType
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
      priorityInstance {
        id
        priorityId
        ownerId
        ownerType
        workspaceId
        priority {
          id
          workspaceId
          name
          color
          type
        }
      }
      phaseInstance {
        id
        phaseId
        ownerId
        ownerType
        workspaceId
        phase {
          id
          workspaceId
          name
          color
          type
        }
      }
      labelInstanceList {
        id
        labelId
        labelGroupId
        ownerId
        ownerType
        workspaceId
        label {
          id
          title
          color
          workspaceId
          labelGroupId
        }
      }
      focusAreaInstance {
        id
        focusAreaId
        ownerId
        ownerType
        focusArea {
          id
          type
          name
          color
          workspaceId
        }
      }
      teamInstanceList {
        id
        teamId
        ownerId
        ownerType
        workspaceId
      }
    }
  }
`);

export const getClimateActionQueryDocument = graphql(/* GraphQL */ `
  query GetClimateAction($input: GetClimateActionInput!) {
    climateAction(getClimateActionInput: $input) {
      id
      title
      properties
      parentId
      workspaceId
      parent {
        id
        title
        properties
        parentId
      }
      children {
        id
        title
        properties
        parentId
      }
      keyResult {
        id
        climateActionId
        unitId
        title
        description
        initialValue
        targetValue
        currentValue
        startDate
        targetDate
        type
        properties
        unit {
          id
          title
          symbol
        }
      }
      progressSnapshotList {
        id
        climateActionId
        keyResultId
        userId
        value
        status
        description
        snapshotDate
        properties
      }
      assignee {
        id
        userId
        teamId
        ownerId
        ownerType
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
      priorityInstance {
        id
        priorityId
        ownerId
        ownerType
        workspaceId
        priority {
          id
          workspaceId
          name
          color
          type
        }
      }
      phaseInstance {
        id
        phaseId
        ownerId
        ownerType
        workspaceId
        phase {
          id
          workspaceId
          name
          color
          type
        }
      }
      labelInstanceList {
        id
        labelId
        labelGroupId
        ownerId
        ownerType
        workspaceId
        label {
          id
          title
          color
          workspaceId
          labelGroupId
        }
      }
      focusAreaInstance {
        id
        focusAreaId
        ownerId
        ownerType
        focusArea {
          id
          type
          name
          color
          workspaceId
        }
      }
      teamInstanceList {
        id
        teamId
        ownerId
        ownerType
        workspaceId
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createClimateActionMutationDocument = graphql(/* GraphQL */ `
  mutation createClimateAction($input: CreateClimateActionInput!) {
    createClimateAction(createClimateActionInput: $input) {
      id
      title
      properties
      parentId
      workspaceId
      parent {
        id
        title
        properties
        parentId
      }
      children {
        id
        title
        properties
        parentId
      }
      keyResult {
        id
        climateActionId
        unitId
        title
        description
        initialValue
        targetValue
        currentValue
        startDate
        targetDate
        type
        properties
        unit {
          id
          title
          symbol
        }
      }
      progressSnapshotList {
        id
        climateActionId
        keyResultId
        userId
        value
        status
        description
        snapshotDate
        properties
      }
      assignee {
        id
        userId
        teamId
        ownerId
        ownerType
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
      priorityInstance {
        id
        priorityId
        ownerId
        ownerType
        workspaceId
        priority {
          id
          workspaceId
          name
          color
          type
        }
      }
      phaseInstance {
        id
        phaseId
        ownerId
        ownerType
        workspaceId
        phase {
          id
          workspaceId
          name
          color
          type
        }
      }
      labelInstanceList {
        id
        labelId
        labelGroupId
        ownerId
        ownerType
        workspaceId
        label {
          id
          title
          color
          workspaceId
          labelGroupId
        }
      }
      focusAreaInstance {
        id
        focusAreaId
        ownerId
        ownerType
        focusArea {
          id
          type
          name
          color
          workspaceId
        }
      }
      teamInstanceList {
        id
        teamId
        ownerId
        ownerType
        workspaceId
      }
    }
  }
`);

export const updateClimateActionMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateClimateAction($input: UpdateClimateActionInput!) {
    updateClimateAction(updateClimateActionInput: $input) {
      id
      title
      properties
      parentId
      workspaceId
      parent {
        id
        title
        properties
        parentId
      }
      children {
        id
        title
        properties
        parentId
      }
      keyResult {
        id
        climateActionId
        unitId
        title
        description
        initialValue
        targetValue
        currentValue
        startDate
        targetDate
        type
        properties
        unit {
          id
          title
          symbol
        }
      }
      progressSnapshotList {
        id
        climateActionId
        keyResultId
        userId
        value
        status
        description
        snapshotDate
        properties
      }
      assignee {
        id
        userId
        teamId
        ownerId
        ownerType
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
      priorityInstance {
        id
        priorityId
        ownerId
        ownerType
        workspaceId
        priority {
          id
          workspaceId
          name
          color
          type
        }
      }
      phaseInstance {
        id
        phaseId
        ownerId
        ownerType
        workspaceId
        phase {
          id
          workspaceId
          name
          color
          type
        }
      }
      labelInstanceList {
        id
        labelId
        labelGroupId
        ownerId
        ownerType
        workspaceId
        label {
          id
          title
          color
          workspaceId
          labelGroupId
        }
      }
      focusAreaInstance {
        id
        focusAreaId
        ownerId
        ownerType
        focusArea {
          id
          type
          name
          color
          workspaceId
        }
      }
      teamInstanceList {
        id
        teamId
        ownerId
        ownerType
        workspaceId
      }
    }
  }
`);

export const deleteClimateActionMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteClimateAction($input: DeleteClimateActionInput!) {
    deleteClimateAction(deleteClimateActionInput: $input) {
      id
      title
      properties
      parentId
      workspaceId
    }
  }
`);
