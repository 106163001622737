import { deleteLabelMutationDocument } from "@/graphql/common/label";
import { type DeleteLabelMutation } from "@/graphql/generated/graphql";
import { onGenericEntityDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_LABEL_DELETED, trackLabelEvent } from "./tracking";
export type DeletedLabel = DeleteLabelMutation["deleteLabel"];

export interface UseDeleteLabelProps {
  onCompleted?: (data: DeletedLabel) => void;
  onError?: (e: Error) => void;
}

export const useDeleteLabel = ({ onCompleted, onError }: UseDeleteLabelProps) => {
  const [deleteLabel] = useMutation(deleteLabelMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedLabel = data?.deleteLabel;
      if (!deletedLabel) {
        return;
      }

      onGenericEntityDelete({ cache, deletedEntity: deletedLabel, typename: "Label" });

      trackLabelEvent(EVENT_LABEL_DELETED, deletedLabel);
    },
  });

  function onUpdateCompleted(data: DeleteLabelMutation) {
    if (onCompleted) {
      onCompleted(data.deleteLabel);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteLabel,
  };
};
