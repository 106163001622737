import { FC, useEffect, useMemo, useState } from "react";

import { useClimateActionListState } from "@/components/_domain/climate-action/hooks";
import { TeamIcon } from "@/components/_domain/team/team-icon";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { Checkbox } from "@/components/_ui/checkbox";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common/useWorkspace";
import { cn } from "@/lib/utils";

import { FilterByIdList, FilterByIdPayload, FilterByIdPayloadSchema } from "../../../filter-functions";

interface FilterByTeamConfigurationViewProps {
  column: { id: string };
  onFinished: () => void;
}

export const FilterByTeamConfigurationView: FC<FilterByTeamConfigurationViewProps> = ({ onFinished, ...props }) => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentUser } = useCurrentUserContext();
  const { teamList } = useTeamListForUserInWorkspace({
    workspaceId: currentWorkspace.id,
    userId: currentUser?.id,
  });
  const availableTeams = useMemo(() => {
    return teamList.filter((team) =>
      team.teamMembershipList.some((membership) => membership.userId === currentUser?.id),
    );
  }, [teamList, currentUser?.id]);

  const { table, columnFilters } = useClimateActionListState();

  const [selectedIds, setSelectedIds] = useState<FilterByIdList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(props.column.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByIdPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedIds(filterValue.idList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedIds) {
      return;
    }

    if (selectedIds.length === 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_id",
      mode: filterValue?.mode ?? "included",
      idList: selectedIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const setFilter = (payload: FilterByIdPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleSelect = (teamId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    let updatedIdList: (string | null)[] = [];
    if (teamId !== null && !selectedIdList.includes(teamId)) {
      updatedIdList = [...selectedIdList, teamId];
    } else if (teamId === null && !selectedIdList.includes(null)) {
      updatedIdList = [...selectedIdList, null];
    }
    setSelectedIds(updatedIdList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (teamId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== teamId);

    setSelectedIds(updatedIdList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command data-testid="filter-by-team-configuration-view">
      <CommandInput placeholder={"Team"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedIds && !!selectedIds.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <div className="flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-xs">
                  <span className=" rounded  p-1">
                    <TeamIcon team={{ id: "null" }} />
                  </span>
                  <span className="pointer-events-none relative flex cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
                    Nicht zugewiesen
                  </span>
                </div>
              </CommandItem>
            );
          })}

          {availableTeams.map((team) => {
            const isSelected = !!selectedIds && !!selectedIds.find((id) => id === team.id);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={team.id}
                value={team.id}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(team.id, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(team.id);
                  }}
                />
                <div className="flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-xs">
                  <span className="rounded p-1">
                    <TeamIcon team={team} />
                  </span>
                  <span className="pointer-events-none relative flex cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
                    {team.name}
                  </span>
                </div>
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
