import { posthogService } from "@/services/posthog.service";
const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_FILE_CREATED = "MUTATION:FILE:CREATED";
export const EVENT_FILE_UPDATED = "MUTATION:FILE:UPDATED";
export const EVENT_FILE_DELETED = "MUTATION:FILE:DELETED";
export const EVENT_FILE_REPLACED = "MUTATION:FILE:REPLACED";

type FileEventType =
  | typeof EVENT_FILE_CREATED
  | typeof EVENT_FILE_UPDATED
  | typeof EVENT_FILE_DELETED
  | typeof EVENT_FILE_REPLACED;

export const trackFileEvent = (event: FileEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
