import { FC } from "react";
import { Link } from "react-router-dom";

interface ClimateActionChildListItemProps {
  climateAction: {
    id: string;
    title: string;
  } | null;
}

export const ClimateActionChildListItem: FC<ClimateActionChildListItemProps> = ({ climateAction }) => {
  if (!climateAction) {
    return <></>;
  }

  return (
    <Link key={climateAction.id} to={`../${climateAction.id}`}>
      <div className="flex cursor-pointer select-none flex-row items-center rounded-md px-2 py-2 text-xs hover:bg-muted">
        <span>{climateAction.title}</span>
      </div>
    </Link>
  );
};

export default ClimateActionChildListItem;
