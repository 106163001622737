import { FC } from "react";

import IconCheckmark from "@/components/_icons/IconCheckmark";

import { IndicatorType } from "../../models/indicator-type.model";
import { IndicatorTypeView } from "../indicator-type-view";

export interface IndicatorTypeSelectDropdownItemProps {
  type: IndicatorType | null;
  selected?: boolean;
}

export const IndicatorTypeSelectDropdownItem: FC<IndicatorTypeSelectDropdownItemProps> = ({ type, selected }) => {
  return (
    <div
      className="m-1 flex w-full cursor-pointer flex-row items-center justify-between p-1 text-xs"
      role="option"
      aria-selected={selected}
    >
      <IndicatorTypeView type={type} />
      {selected && <IconCheckmark className="h-3 w-3 shrink-0 text-foreground/60" />}
    </div>
  );
};
