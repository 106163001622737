import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { makeTeamSettingsUrl, makeTeamUrl } from "@/components/_domain/team/lib/make-team-url";
import { Team } from "@/components/_domain/team/models/team-model";
import { TeamIcon } from "@/components/_domain/team/team-icon";
import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";
import IconDots from "@/components/_icons/IconDots";
import IconInvisible from "@/components/_icons/IconInvisible";
import { SidebarNavElement } from "@/components/_layout/sidebar";
import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Link } from "@/components/link";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { cn } from "@/lib/utils";

export interface SidebarAppTeamNavElementProps {
  workspace: Workspace;
  team: Team;
  expanded?: boolean | undefined;
}

export const SidebarAppTeamNavElement = ({ workspace, team, ...props }: SidebarAppTeamNavElementProps) => {
  const [expanded, setExpanded] = useState(props.expanded !== undefined ? props.expanded : false);
  const [showDropdown, setShowDropdown] = useState(false);

  const { teamId } = useParams();

  const { hasPermission: hasPermissionToManageTeam } = useCheckPermission({
    resource: "team",
    resourceId: team.id,
    action: "manage",
  });

  useEffect(() => {
    if (teamId === team.id) {
      setExpanded(true);
    }
  }, [team, teamId]);

  if (!team) {
    return <></>;
  }

  return (
    <div className="group ">
      <div className="relative cursor-pointer rounded">
        <div
          className={cn([
            "my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm hover:bg-foreground/[3%] hover:text-accent-foreground",
            showDropdown ? "bg-foreground/[3%]  text-accent-foreground" : "",
          ])}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <div className="rounded p-1">
            <TeamIcon team={team} />
          </div>
          <div className="flex min-w-0">
            <div className="pointer-events-none truncate pb-0 pl-1.5 pr-0.5 pt-0 text-sm leading-8">{team.name}</div>
          </div>
          <div>
            {!expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 text-foreground" />}
            {expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 rotate-90 text-foreground" />}
          </div>
          <div className="grow"></div>
          <div
            className={cn(["justify-self-end opacity-0 group-hover:opacity-100", showDropdown ? "opacity-100" : ""])}
          >
            <DropdownMenu open={showDropdown} onOpenChange={setShowDropdown}>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-muted-foreground hover:bg-transparent hover:text-accent-foreground"
                >
                  <IconDots className="h-3 w-3" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <Link to={makeTeamSettingsUrl({ workspace, team, path: "general" })}>
                  <DropdownMenuItem className="cursor-pointer">Team Einstellungen</DropdownMenuItem>
                </Link>
                {!hasPermissionToManageTeam && (
                  <Link to={makeTeamSettingsUrl({ workspace, team, path: "members" })}>
                    <DropdownMenuItem className="cursor-pointer">Mitglieder ansehen</DropdownMenuItem>
                  </Link>
                )}

                {hasPermissionToManageTeam && (
                  <>
                    <DropdownMenuSeparator />
                    <Link to={makeTeamSettingsUrl({ workspace, team, path: "members" })}>
                      <DropdownMenuItem className="cursor-pointer">Mitglieder verwalten</DropdownMenuItem>
                    </Link>
                    <Link to={makeTeamSettingsUrl({ workspace, team, path: "labels" })}>
                      <DropdownMenuItem className="cursor-pointer">Label verwalten</DropdownMenuItem>
                    </Link>
                    <Link to={makeTeamSettingsUrl({ workspace, team, path: "phase" })}>
                      <DropdownMenuItem className="cursor-pointer">Phasen verwalten</DropdownMenuItem>
                    </Link>
                    <Link to={makeTeamSettingsUrl({ workspace, team, path: "priority" })}>
                      <DropdownMenuItem className="cursor-pointer">Prioritäten verwalten</DropdownMenuItem>
                    </Link>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      {expanded && (
        <SidebarNavElement to={makeTeamUrl({ workspace, team, path: "climate-actions" })}>
          <IconInvisible className="ml-4 mr-2 h-2.5 w-2.5" />
          Maßnahmen
        </SidebarNavElement>
      )}
    </div>
  );
};
