import { FC, useMemo } from "react";

import IconPlus from "@/components/_icons/IconPlus";
import { Button } from "@/components/_ui/button";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";

import { useClimateActionUIState } from "../../../hooks/useClimateActionUIState";

import ClimateActionChildListItem from "./climate-action-child-list-item";
type ClimateActionProps = {
  id: string;
};

interface ClimateActionChildListProps {
  climateAction: ClimateActionProps;
}

export const ClimateActionChildList: FC<ClimateActionChildListProps> = (props) => {
  const { climateAction } = useGetClimateAction({ id: props.climateAction.id });
  const { setShowCreateChildClimateActionView } = useClimateActionUIState();

  function showCreateChildClimateAction() {
    setShowCreateChildClimateActionView(true);
  }

  const childrenClimateActions = useMemo(() => {
    if (!climateAction) {
      return [];
    }
    if (!climateAction.children) {
      return [];
    }

    return climateAction.children.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [climateAction]);

  // if (!(childrenClimateActions.length > 0)) {
  //   return <></>;
  // }

  return (
    <>
      <hr className="mx-3 py-3" />
      <div>
        <div className="mx-3 mb-1 flex flex-row items-center justify-between text-sm font-medium text-muted-foreground">
          <span>Teilmaßnahmen</span>
          <span>
            <Button variant="ghost" size="xs" className="h-7 w-7" onClick={() => showCreateChildClimateAction()}>
              <IconPlus />
            </Button>
          </span>
        </div>
        <hr className="mx-3 py-3" />
        <div className="mx-3">
          <div className="flex flex-col">
            {childrenClimateActions.map((climateAction) => {
              return <ClimateActionChildListItem key={climateAction.id} climateAction={climateAction} />;
            })}
            {childrenClimateActions.length === 0 && (
              <div className="flex h-24 flex-row flex-wrap items-center justify-center text-xs text-muted-foreground">
                Keine Teilmaßnahmen
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClimateActionChildList;
