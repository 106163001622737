import { IndicatorListTitleView } from "@/components/_domain/indicator/components/indicator-list-title-view";
import { IndicatorTitleView } from "@/components/_domain/indicator/components/indicator-title-view";
import { useCurrentIndicatorListContext } from "@/components/_domain/indicator/hooks/useCurrentIndicatorListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { Link } from "@/components/link";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";

import { IndicatorCreateDialog } from "../components/indicator-create-dialog";

const PAGE_NAME = "Indikatoren";

export const IndicatorListPage = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentIndicatorList } = useCurrentIndicatorListContext();

  return (
    <div className="flex h-full w-full flex-row">
      <div className="flex h-full max-h-full w-full flex-col overflow-y-hidden">
        <HeaderLayout>
          <div className="flex flex-row items-center gap-2">
            <Link to={`/${currentWorkspace.workspaceUrl}/indicators`}>
              <IndicatorListTitleView />
            </Link>
            <FavoriteButton pageName={PAGE_NAME} />
          </div>
          <div className="flex flex-row items-center gap-2">
            <IndicatorCreateDialog workspace={currentWorkspace} />
          </div>
        </HeaderLayout>

        <div className="flex w-full flex-row flex-wrap items-center px-6 py-6 text-sm">
          {currentIndicatorList.map((indicator) => {
            return (
              <div key={indicator.id}>
                <IndicatorTitleView indicator={indicator} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
