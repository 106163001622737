import { ClimateActionListView } from "./climate-action-list-main-view/climate-action-simple-list-view";

export const ClimateActionListOverview = () => {
  return (
    <div className="overflow-y-scroll pb-24" data-testid={"climate-action-list-overview"}>
      <div className="relative flex grow items-stretch">
        <ClimateActionListView />
      </div>
    </div>
  );
};
