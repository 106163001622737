import React, { useEffect, useState } from "react";

import { Input } from "@/components//_ui/input";
import { Label } from "@/components//_ui/label";
import { Button } from "@/components/_ui/button";
import { Switch } from "@/components/_ui/switch";
import { toast } from "@/components/_ui/use-toast";

import { useGlobalSavedViewContext } from "../hooks/useGlobalSavedViewContext";
import { SavedView } from "../models/saved-views-model";

interface SavedViewEditFormProps {
  initialData: SavedView;
  onError?: (error: Error) => void;
  onSuccess?: (data: SavedView) => void;
}

export const SavedViewEditForm: React.FC<SavedViewEditFormProps> = ({ initialData, ...props }) => {
  const [name, setName] = useState(initialData.name);
  const [isDefault, setIsDefault] = useState(initialData.isDefault);

  const { updateSavedView, pathname } = useGlobalSavedViewContext();

  // Make sure form is reset when initialData changes
  useEffect(() => {
    setName(initialData.name);
    setIsDefault(initialData.isDefault);
  }, [initialData]);

  const handleUpdateSavedView = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent form from submitting and refreshing the page

    const updatedView = await updateSavedView(pathname, initialData.id, {
      name: name,
      isDefault: isDefault,
      // note: We don not want to update the tableConfiguration here, just the name and isDefault
    });
    toast({
      title: "Erfolg",
      description: "Ansicht aktualisiert",
      variant: "success",
    });

    if (!updatedView) {
      return;
    }

    props.onSuccess?.(updatedView);
  };

  return (
    <form onSubmit={handleUpdateSavedView} className="space-y-4 p-4">
      <div className="space-y-2">
        <Label htmlFor="name" className="text-sm font-medium">
          Name
        </Label>
        <Input
          id="name"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
          className="w-full"
          placeholder="Geben Sie einen Namen für die Ansicht ein"
        />
      </div>

      <div className="flex items-center gap-2">
        <Switch
          checked={isDefault}
          onCheckedChange={setIsDefault}
          className="relative h-5 w-10 cursor-pointer rounded-full bg-muted transition-colors data-[state=checked]:bg-primary"
        ></Switch>
        <Label className="text-sm font-medium">Als Standardansicht festlegen</Label>
      </div>

      <div className="border-t pt-4">
        <div className="flex justify-end gap-2">
          <Button type="submit" size="xs" disabled={!name} className="px-4">
            Aktualisieren
          </Button>
        </div>
      </div>
    </form>
  );
};
