import { useMemo } from "react";

import { checkPermissionQueryDocument } from "@/graphql/common/permission";
import { useQuery } from "@apollo/client";

import { ActionType, ResourceType } from "./types";

interface UseCheckPermissionProps {
  resource: ResourceType;
  action: ActionType;
  resourceId?: string;
  enabled?: boolean;
}

export const useCheckPermission = ({ resource, action, resourceId, enabled = true }: UseCheckPermissionProps) => {
  const { loading, error, data, refetch } = useQuery(checkPermissionQueryDocument, {
    variables: {
      input: {
        resource,
        action,
        resourceId: resourceId || "",
      },
    },
    skip: !resourceId || !enabled,
  });

  const hasPermission = useMemo(() => {
    return data?.checkPermission.hasPermission || false;
  }, [data]);

  return {
    refetch,
    loading,
    error,
    hasPermission: hasPermission,
  };
};
