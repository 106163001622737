import { useCallback, useMemo } from "react";

import { useCurrentUserContext } from "../../../../components/_domain/user/hooks/useCurrentUserContext";

export const useGetUserFavorites = () => {
  const { currentUser } = useCurrentUserContext();

  const favorites = useMemo(() => {
    return currentUser?.favorites || [];
  }, [currentUser?.favorites]);

  const getFavoriteByPageUrl = useCallback(
    ({ pageUrl }: { pageUrl: string }) => {
      return favorites.find((favorite) => favorite.pageUrl === pageUrl);
    },
    [favorites],
  );

  const isFavorite = useCallback(
    ({ pageUrl }: { pageUrl: string }) => {
      return favorites.some((favorite) => favorite.pageUrl === pageUrl);
    },
    [favorites],
  );

  return {
    favorites,
    getFavoriteByPageUrl,
    isFavorite,
  };
};
