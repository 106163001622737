import { FC, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/_ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Cross2Icon } from "@radix-ui/react-icons";

import { useClimateActionListState } from "../../../../hooks";
import { FilterByTextMode, FilterByTextPayload, FilterByTextPayloadSchema } from "../../../filter-functions";

import { FilterByTitleConfigurationView, FilterByTitleDisplayView } from ".";

export interface FilterByTitleFilterPaneViewProps {
  column: {
    id: string;
  };
}

export const FilterByTitleFilterPaneView: FC<FilterByTitleFilterPaneViewProps> = ({ ...props }) => {
  const { table, columnFilters } = useClimateActionListState();
  const [showDialog, setShowDialog] = useState(false);

  const column = useMemo(() => {
    return table.getColumn(props.column.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const filter = useMemo(() => {
    if (!column) {
      return null;
    }
    if (!column.getCanFilter()) {
      return null;
    }
    const filterValue = column.getFilterValue();
    if (!filterValue) {
      return null;
    }
    return filterValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  const filterByTextFilter = useMemo(() => {
    if (!filter) {
      return null;
    }

    const safePayload = FilterByTextPayloadSchema.safeParse(filter);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [filter]);

  const filterModes: FilterByTextMode[] = useMemo(() => {
    if (!filterByTextFilter) {
      return [];
    }
    return ["included", "not_included"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const setFilter = (payload: FilterByTextPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const setFilterMode = (filterMode: FilterByTextMode) => {
    if (!filterByTextFilter) {
      return;
    }
    setFilter({
      ...filterByTextFilter,
      mode: filterMode,
    });
  };

  if (!column) {
    return <></>;
  }

  if (!filter) {
    return <></>;
  }

  if (filterByTextFilter) {
    return (
      <div className="flex flex-row flex-nowrap items-center gap-0 whitespace-nowrap rounded-md border p-0 text-xs font-light">
        <div className="flex flex-row items-center gap-1 pl-1 text-xs">
          <FilterByTitleDisplayView className="h-3 w-3" />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
            >
              {filterByTextFilter.mode === "included" && <>enthält</>}
              {filterByTextFilter.mode === "not_included" && <>enthält nicht</>}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filterModes.map((mode) => {
              return (
                <DropdownMenuItem
                  key={mode}
                  onClick={() => {
                    setFilterMode(mode);
                  }}
                >
                  {mode === "included" && <>enthält</>}
                  {mode === "not_included" && <>enthält nicht</>}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>

        <Dialog open={showDialog} onOpenChange={setShowDialog}>
          <DialogTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0  focus-visible:ring-offset-0"
            >
              {filterByTextFilter.query}
            </Button>
          </DialogTrigger>
          <DialogContent>
            <FilterByTitleConfigurationView
              column={column}
              onFinished={() => {
                setShowDialog(false);
              }}
            />
          </DialogContent>
        </Dialog>
        <Button
          variant="ghost"
          size="xs"
          className="h-6 w-full justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          onClick={() => {
            clearFilter();
          }}
        >
          <Cross2Icon className="h-3.5 w-3.5 text-foreground/60" />
        </Button>
      </div>
    );
  }

  return <></>;
};
