import { differenceInMonths, format } from "date-fns";
import { de } from "date-fns/locale";

export type DateFormatter = (d: Date) => string;
export type ValueFormatter = (v: number) => string;

export const makeDateFormatter = (start: Date, end: Date): DateFormatter => {
  let formatString = "yyyy-MM-dd";

  const diffInMonths = Math.abs(differenceInMonths(end, start));

  if (diffInMonths > 48) {
    formatString = "MMM yyyy";
  }

  if (diffInMonths <= 48 && diffInMonths > 12) {
    formatString = "MMM yyyy";
  }

  if (diffInMonths <= 12) {
    formatString = "PPP";
  }

  const dateFormatter = (d: Date): string => {
    return format(d, formatString, { locale: de });
  };

  return dateFormatter;
};

export const makeValueFormatter = (
  intialValue: number,
  targetValue: number,
  symbol: string | undefined,
): ValueFormatter => {
  const fractionDigits = Math.abs(targetValue - intialValue) > 50 ? 0 : 2;
  const valueFormatter = (value: number): string => {
    return `${value.toFixed(fractionDigits)}${symbol ?? ""}`;
  };

  return valueFormatter;
};

export const getDateRangeAtFraction = (startDate: Date, targetDate: Date, fraction: number): Date => {
  return new Date(startDate.getTime() + (targetDate.getTime() - startDate.getTime()) * fraction);
};
