import { type FC, type PropsWithChildren } from "react";

import { Dialog, DialogContent, DialogDescription, DialogTitle } from "@/components/_ui/dialog";

interface SaveViewModalProps extends PropsWithChildren {
  title: string;
  description: string;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const SaveViewModal: FC<SaveViewModalProps> = ({ open, onOpenChange, title, description, children }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="shadow-lg fixed left-1/2 top-1/2 w-[400px] -translate-x-1/2 -translate-y-1/2 rounded-lg border bg-background">
        <div className="border-b p-4">
          <DialogTitle className="text-lg font-semibold text-foreground">{title}</DialogTitle>
          <DialogDescription className="mt-1 text-sm text-muted-foreground">{description}</DialogDescription>
        </div>

        {children}
      </DialogContent>
    </Dialog>
  );
};
