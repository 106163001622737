import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getPriorityQueryDocument = graphql(/* GraphQL */ `
  query GetPriority($input: GetPriorityInput!) {
    priority(getPriorityInput: $input) {
      id
      workspaceId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

export const getPriorityListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query GetPriorityListForWorkspace($input: GetPriorityListForWorkspaceInput!) {
    priorityListForWorkspace(getPriorityListForWorkspaceInput: $input) {
      id
      workspaceId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createPriorityMutationDocument = graphql(/* GraphQL */ `
  mutation createPriority($input: CreatePriorityInput!) {
    createPriority(createPriorityInput: $input) {
      id
      workspaceId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

export const updatePriorityMutationDocument = graphql(/* GraphQL */ `
  mutation UpdatePriority($input: UpdatePriorityInput!) {
    updatePriority(updatePriorityInput: $input) {
      id
      workspaceId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);

export const deletePriorityMutationDocument = graphql(/* GraphQL */ `
  mutation DeletePriority($input: DeletePriorityInput!) {
    deletePriority(deletePriorityInput: $input) {
      id
      workspaceId
      name
      color
      type
      instances {
        id
      }
    }
  }
`);
