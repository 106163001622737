import { useMemo } from "react";

import { ApplyFiltersFromSearchParamsToTable } from "@/components/_domain/climate-action/climate-action-list-overview/utils/apply-filters-from-search-params-to-table";
import { SavedViewTableSyncCoordinator } from "@/components/_domain/climate-action/climate-action-list-overview/utils/saved-view-table-state-synchronizer";
import {
  ClimateActionListStateProvider,
  type ClimateActionListStateProviderProps,
} from "@/components/_domain/climate-action/hooks";
import { SavedViewLoader } from "@/components/_domain/saved-view/components/SavedViewLoader";
import { useGlobalSavedViewContext } from "@/components/_domain/saved-view/hooks/useGlobalSavedViewContext";

const DEBUG_SAVED_VIEW_SYNC = false;

const debugLog = (...args: unknown[]) => {
  if (DEBUG_SAVED_VIEW_SYNC) {
    debugLog(...args);
  }
};

interface ClimateActionListProviderStackInitializerProps
  extends Omit<ClimateActionListStateProviderProps, "showChildren" | "enableExpanding"> {
  pathname: string;
  searchParams: URLSearchParams;
  showChildren?: boolean;
  enableExpanding?: boolean;
}

const InnerComponent = ({
  pathname,
  searchParams,
  children,
  workspace,
  user,
  climateActionList,
  showChildren = false,
  enableExpanding = true,
  config,
}: ClimateActionListProviderStackInitializerProps) => {
  // Get the initial table configuration
  const tableConfiguration = config;

  // Get the saved view state for the current pathname
  const { getSavedViewState } = useGlobalSavedViewContext();
  const { currentView } = getSavedViewState(pathname);

  // Compute the view-specific table configuration
  const viewSpecificTableConfig = useMemo(() => {
    // We update the table configuration with the current View initialized in the GlobalSavedViewsContext
    // – This will always be set at this point in time
    // - Also, if the user has previously applied filters and left the page, the will be reflected in the currentView

    return {
      columnConfigurationList: tableConfiguration.columnConfigurationList,
      ...currentView.tableConfiguration.climateActions,
    };
    // We only want to run this once when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  debugLog("[4] ClimateActionListProviderStackInitializer – children rendered");

  return (
    <ClimateActionListStateProvider
      workspace={workspace}
      user={user}
      climateActionList={climateActionList}
      showChildren={showChildren}
      enableExpanding={enableExpanding}
      config={viewSpecificTableConfig}
    >
      <SavedViewTableSyncCoordinator pathname={pathname} workspaceId={workspace.id}>
        <ApplyFiltersFromSearchParamsToTable searchParams={searchParams}>
          {children}
        </ApplyFiltersFromSearchParamsToTable>
      </SavedViewTableSyncCoordinator>
    </ClimateActionListStateProvider>
  );
};

export const ClimateActionListProviderStackInitializer = (props: ClimateActionListProviderStackInitializerProps) => {
  return (
    <SavedViewLoader pathname={props.pathname}>
      <InnerComponent {...props} />
    </SavedViewLoader>
  );
};
