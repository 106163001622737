import { createLabelMutationDocument } from "@/graphql/common/label";
import { type CreateLabelMutation } from "@/graphql/generated/graphql";
import { onGenericEntityCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_LABEL_CREATED, trackLabelEvent } from "./tracking";

export type CreatedLabel = CreateLabelMutation["createLabel"];

export interface UseCreateLabelProps {
  onCompleted?: (data: CreatedLabel) => void;
  onError?: (e: Error) => void;
}

export const useCreateLabel = ({ onCompleted, onError }: UseCreateLabelProps = {}) => {
  const [createLabel] = useMutation(createLabelMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const newLabel = data?.createLabel;
      if (!newLabel) {
        return;
      }

      onGenericEntityCreate({ cache, newEntity: newLabel, typename: "Label" });

      trackLabelEvent(EVENT_LABEL_CREATED, newLabel);
    },
  });

  function onUpdateCompleted(data: CreateLabelMutation) {
    if (onCompleted) {
      onCompleted(data.createLabel);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createLabel,
  };
};
