import { graphql } from "@/graphql/generated/gql";

// ============== QUERIES ======================
export const teamByIdQueryDocument = graphql(/* GraphQL */ `
  query teamById($input: String!) {
    teamById(teamId: $input) {
      id
      workspaceId
      name
      visibility
      logo
      logoType
      color
      teamMembershipList {
        id
        userId
        teamId
        role
        status
      }
    }
  }
`);

export const teamsForUserInWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query teamsForUserInWorkspaceQuery($input: String!) {
    teamsForUserInWorkspace(workspaceId: $input) {
      id
      workspaceId
      name
      visibility
      logo
      logoType
      color
      teamMembershipList {
        id
        userId
        teamId
        role
        status
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
    }
  }
`);

export const teamDeletionCodeQueryDocument = graphql(/* GraphQL */ `
  query requestTeamDeletionCodeQuery($input: UpdateTeamInput!) {
    requestTeamDeletionCode(updateTeamInput: $input) {
      success
      email
      message
    }
  }
`);

// ============== MUTATIONS ======================
export const createTeamMutationDocument = graphql(/* GraphQL */ `
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(createTeamInput: $input) {
      id
      workspaceId
      name
      visibility
      logo
      logoType
      color
      teamMembershipList {
        id
        userId
        teamId
        role
        status
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
    }
  }
`);

export const updateTeamMutationDocument = graphql(/* GraphQL */ `
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(updateTeamInput: $input) {
      id
      workspaceId
      name
      visibility
      logo
      logoType
      color
      teamMembershipList {
        id
        userId
        teamId
        role
        status
      }
    }
  }
`);

export const removeTeamMutationDocument = graphql(/* GraphQL */ `
  mutation removeTeam($input: String!) {
    removeTeam(token: $input) {
      id
      workspaceId
      name
      visibility
      logo
      logoType
      color
      teamMembershipList {
        id
        userId
        teamId
        role
        status
      }
    }
  }
`);
