import { useMemo } from "react";

import { getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { getKeyResultQueryDocument } from "@/graphql/common/key-result";
import {
  deleteProgressSnapshotMutationDocument,
  getProgressSnapshotsForKeyResultQueryDocument,
  getProgressSnapshotsQueryDocument,
} from "@/graphql/common/progress-snapshot";
import { useMutation } from "@apollo/client";

import { EVENT_PROGRESS_SNAPSHOT_DELETED, trackProgressSnapshotEvent } from "./tracking";

type ClimateActionFragment = {
  id: string;
};

export interface UseDeleteProgressSnapshotProps {
  climateActionId: string;
  keyResultId?: string;
  onCompleted?: (o: ClimateActionFragment | undefined) => void;
  onError?: (e: Error) => void;
}

export const useDeleteProgressSnapshot = ({
  climateActionId,
  keyResultId,
  onCompleted,
  onError,
}: UseDeleteProgressSnapshotProps) => {
  const refetchQueries = useMemo(() => {
    if (keyResultId) {
      return [
        {
          query: getProgressSnapshotsQueryDocument,
          variables: { input: { climateActionId: climateActionId } },
        },
        {
          query: getProgressSnapshotsForKeyResultQueryDocument,
          variables: { input: { keyResultId: keyResultId } },
        },
        {
          query: getKeyResultQueryDocument,
          variables: { input: { id: keyResultId } },
        },
        {
          query: getClimateActionQueryDocument,
          variables: { input: { id: climateActionId } },
        },
      ];
    }

    return [
      {
        query: getProgressSnapshotsQueryDocument,
        variables: { input: { climateActionId: climateActionId } },
      },
      {
        query: getClimateActionQueryDocument,
        variables: { input: { id: climateActionId } },
      },
    ];
  }, [climateActionId, keyResultId]);

  const [deleteProgressSnapshot, queryResult] = useMutation(deleteProgressSnapshotMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
    update: (_cache, { data }) => {
      const progressSnapshot = data?.deleteProgressSnapshot;
      if (!progressSnapshot) {
        return;
      }
      trackProgressSnapshotEvent(EVENT_PROGRESS_SNAPSHOT_DELETED, progressSnapshot);
    },
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.deleteProgressSnapshot?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    deleteProgressSnapshot,
  };
};
