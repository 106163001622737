import { ReactNode } from "react";

import { cn } from "@/lib/utils";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  className?: string;
}

export const PageHeader = ({ title, subtitle, children, className }: PageHeaderProps) => {
  return (
    <div className={cn("flex flex-row items-start justify-between", className)}>
      <div className="flex flex-col gap-1.5">
        <div className="flex flex-row items-center gap-2">
          <div className="text-2xl">{title}</div>
        </div>
        {subtitle && <div className="text-sm text-muted-foreground">{subtitle}</div>}
      </div>
      {children && <div className="flex flex-row items-center gap-2">{children}</div>}
    </div>
  );
};
