import { updateClimateActionMutationDocument } from "@/graphql/common/climate-action";
import { type UpdateClimateActionMutation } from "@/graphql/generated/graphql";
import { useMutation } from "@apollo/client";

import { onClimateActionUpdate } from "./_utils";
import { EVENT_CLIMATE_ACTION_UPDATED, trackClimateActionEvent } from "./tracking";

export type UpdatedClimateAction = UpdateClimateActionMutation["updateClimateAction"];

export interface UseUpdateClimateActionProps {
  onCompleted?: (o: UpdatedClimateAction | undefined) => void;
  onError?: (e: Error) => void;
}

export const useUpdateClimateAction = ({ onCompleted, onError }: UseUpdateClimateActionProps) => {
  const [updateClimateAction] = useMutation(updateClimateActionMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const climateAction = data?.updateClimateAction;
      if (!climateAction) {
        return;
      }

      // Update the cache with the new climate action
      onClimateActionUpdate(cache, climateAction);

      trackClimateActionEvent(EVENT_CLIMATE_ACTION_UPDATED, {
        id: climateAction.id,
        title: climateAction.title,
        workspaceId: climateAction.workspaceId,
      });
    },
  });

  // eslint-disable-next-line
  function onUpdateCompleted(data: UpdateClimateActionMutation) {
    if (onCompleted) {
      onCompleted(data.updateClimateAction);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updateClimateAction,
  };
};
