import { FC, useMemo, useState } from "react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { Calendar } from "lucide-react";

import { useClimateActionListState } from "@/components/_domain/climate-action/hooks";
import { Button } from "@/components/_ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/_ui/dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

import { FilterByDatePayloadSchema } from "../../../filter-functions/filter-by-date";

import { FilterByLastUpdatedConfigurationView } from "./filter-by-last-updated-configuration-view";

export interface FilterByLastUpdatedFilterPaneViewProps {
  columnId: string;
}

export const FilterByLastUpdatedFilterPaneView: FC<FilterByLastUpdatedFilterPaneViewProps> = ({ columnId }) => {
  const { table, columnFilters } = useClimateActionListState();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const column = useMemo(() => {
    return table.getColumn(columnId);
  }, [table, columnId]);

  const filter = useMemo(() => {
    if (!column) {
      return null;
    }
    if (!column.getCanFilter()) {
      return null;
    }
    const filterValue = column.getFilterValue();
    if (!filterValue) {
      return null;
    }
    return filterValue;

    // add column filters to deps to trigger re-render when column filters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  const filterByDateFilter = useMemo(() => {
    if (!filter) {
      return null;
    }

    const safePayload = FilterByDatePayloadSchema.safeParse(filter);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [filter]);

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  if (!column) {
    return <></>;
  }

  if (!filter) {
    return <></>;
  }

  if (filterByDateFilter) {
    return (
      <div className="flex flex-row flex-nowrap items-center gap-0 whitespace-nowrap rounded-md border p-0 text-xs font-light">
        <div className="flex flex-row items-center gap-1 pl-1 text-xs" data-testid="last-updated-filter-pane-view">
          <Calendar className="h-3.5 w-3.5 text-foreground/60" />
          <span className="text-xs">Zuletzt aktualisiert</span>
        </div>

        <div className="pl-1 text-xs">
          {filterByDateFilter.mode === "before" && <>vor dem</>}
          {filterByDateFilter.mode === "after" && <>nach dem</>}
        </div>

        <Dialog open={showDialog} onOpenChange={setShowDialog}>
          <DialogTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0  focus-visible:ring-offset-0"
            >
              {`${format(new Date(filterByDateFilter.date), "dd.MM.yyyy", {
                locale: de,
              })}`}
            </Button>
          </DialogTrigger>
          <DialogContent>
            <FilterByLastUpdatedConfigurationView
              columnId={columnId}
              onFinished={() => {
                setShowDialog(false);
              }}
            />
          </DialogContent>
        </Dialog>
        <Button
          variant="ghost"
          size="xs"
          className="h-6 w-full justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          onClick={() => {
            clearFilter();
          }}
        >
          <Cross2Icon className="h-3.5 w-3.5 text-foreground/60" />
        </Button>
      </div>
    );
  }

  return <></>;
};
