import { deletePriorityMutationDocument } from "@/graphql/common/priority";
import { type DeletePriorityMutation } from "@/graphql/generated/graphql";
import { onGenericEntityDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PRIORITY_DELETED, trackPriorityEvent } from "./tracking";
export type DeletedPriority = DeletePriorityMutation["deletePriority"];

export interface UseDeletePriorityProps {
  onCompleted?: (data: DeletedPriority) => void;
  onError?: (e: Error) => void;
}

export const useDeletePriority = ({ onCompleted, onError }: UseDeletePriorityProps) => {
  const [deletePriority] = useMutation(deletePriorityMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedPriority = data?.deletePriority;
      if (!deletedPriority) {
        return;
      }

      onGenericEntityDelete({ cache, deletedEntity: deletedPriority, typename: "Priority" });
      trackPriorityEvent(EVENT_PRIORITY_DELETED, deletedPriority);
    },
  });

  function onUpdateCompleted(data: DeletePriorityMutation) {
    if (onCompleted) {
      onCompleted(data.deletePriority);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deletePriority,
  };
};
