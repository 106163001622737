import { FC } from "react";

import { LabelSelectDropdown } from "@/components/_domain/labels";
import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import IconPlus from "@/components/_icons/IconPlus";
import { Button } from "@/components/_ui/button";
import { useGetLabelGroupListForWorkspace } from "@/hooks/api/common/useLabel";
import { useGetLabelInstanceList } from "@/hooks/api/common/useLabel";

import { ClimateActionLabelGroupInstanceView } from "./climate-action-label-group-instance-view";
import { ClimateActionLabelInstanceView } from "./climate-action-label-instance-view";

interface ClimateActionLabelViewProps {
  climateAction: {
    id: string;
  };
  workspace: Workspace;
}

export const ClimateActionLabelView: FC<ClimateActionLabelViewProps> = ({ climateAction, workspace }) => {
  const { labelInstanceList } = useGetLabelInstanceList({ ownerId: climateAction.id });
  const { labelGroupList } = useGetLabelGroupListForWorkspace({
    workspaceId: workspace.id,
  });

  if (!labelInstanceList) {
    return <></>;
  }

  return (
    <>
      <div className="mb-5 flex flex-row items-start gap-2">
        <div className="mt-2 w-24 shrink-0 font-medium">Label</div>
        <div className="grow">
          <LabelSelectDropdown owner={{ id: climateAction.id, type: "CLIMATE_ACTION" }} workspace={workspace} asChild>
            <Button variant="ghost" size="sm" className="font-light">
              <IconPlus className="mr-2 h-2 w-2" />
              Label hinzufügen
            </Button>
          </LabelSelectDropdown>
        </div>
      </div>

      {labelInstanceList.length > 0 && (
        <>
          <ClimateActionLabelInstanceView
            workspace={workspace}
            owner={{ id: climateAction.id, type: "CLIMATE_ACTION" }}
          />
          {labelGroupList.map((labelGroup) => (
            <ClimateActionLabelGroupInstanceView
              key={labelGroup.id}
              workspace={workspace}
              owner={{ id: climateAction.id, type: "CLIMATE_ACTION" }}
              labelGroup={labelGroup}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ClimateActionLabelView;
