import { TaskBoard } from "@/components/_domain/task";
import { CreateTaskContextProvider } from "@/components/_domain/task/task-create-dialog";
import { TaskCreateDialog } from "@/components/_domain/task/task-create-dialog";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { ErrorView } from "@/components/error-view";
import { FavoriteButton } from "@/components/favorite-button";
import { useGetAllTasksQuery } from "@/hooks/api/common/useTask/useGetTaskList";

const InnerTasksPage = () => {
  const { tasks, loading, error } = useGetAllTasksQuery();

  // make sure tasks are available before rendering the page
  if (loading) {
    return <></>;
  }

  if (error) {
    return (
      <ErrorView>
        <code className="font-mono text-sm">{error.message}</code>
      </ErrorView>
    );
  }

  return (
    <div className="h-full w-full" data-testid="topnav">
      <HeaderLayout>
        <div className="flex flex-row items-center gap-2">
          <div
            className="flex max-w-[8rem] flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap"
            data-testid="tasks-title"
          >
            Aufgaben
          </div>
          <FavoriteButton pageName="Aufgaben" />
        </div>
        <div className="flex flex-row items-center gap-2">
          <TaskCreateDialog showTrigger={true} />
        </div>
      </HeaderLayout>

      <div className="py-4">
        <TaskBoard tasks={tasks} />
      </div>
    </div>
  );
};

export default function TasksPage() {
  return (
    <CreateTaskContextProvider>
      <InnerTasksPage />
    </CreateTaskContextProvider>
  );
}
