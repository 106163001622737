import { useMemo } from "react";

import { workspaceByIdQueryDocument } from "@/graphql/common/workspace";
import { useQuery } from "@apollo/client";

export interface UseGetWorkspaceProps {
  id?: string;
  enabled?: boolean;
}

export const useWorkspaceGetById = ({ id, enabled = true }: UseGetWorkspaceProps) => {
  const queryResult = useQuery(workspaceByIdQueryDocument, {
    variables: { input: id ?? "" },
    skip: !id || !enabled,
  });

  const workspace = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.workspaceById;
  }, [queryResult.data]);

  return {
    queryResult,
    workspace,
  };
};
