import React, { useMemo, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

import { Button } from "@/components/_ui/button";
import { toast } from "@/components/_ui/use-toast";
import { DeleteConfirmation } from "@/components/delete-confirmation";
import { RichTextEditor } from "@/components/rich-text-editor";
import { useDeleteTaskMutation } from "@/hooks/api/common/useTask/useDeleteTask";
import { useUpdateTaskMutation } from "@/hooks/api/common/useTask/useUpdateTask";

import { TaskStatusSelect } from "./task-status-select";
import { Task, TaskStatus } from "./task-type";

export interface TaskEditFormProps {
  task: Task;
  onSuccess?: () => void;
  onDelete?: () => void;
  className?: string;
}

export const TaskEditForm: React.FC<TaskEditFormProps> = ({ task, onSuccess, onDelete, className }) => {
  const [loading, setLoading] = useState(false);
  const [editedTask, setEditedTask] = useState<Task>(task);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { updateTask } = useUpdateTaskMutation();
  const { deleteTask } = useDeleteTaskMutation();

  const initialEditorState = useMemo(() => {
    if (!editedTask.description || editedTask.description === "") {
      return undefined;
    }
    return editedTask.description;
  }, [editedTask.description]);

  const hasChanges = useMemo(() => {
    return (
      task.title !== editedTask.title ||
      task.description !== editedTask.description ||
      task.status !== editedTask.status
    );
  }, [task, editedTask]);

  async function onRichTextEditorChange(_value: string, json: string) {
    setEditedTask({
      ...editedTask,
      description: json === "" ? "" : json,
    });
  }

  async function handleSaveTask() {
    if (loading) return;

    if (!editedTask.title.trim()) {
      toast({
        title: "Titel ist erforderlich",
        description: "Bitte geben Sie einen Titel für die Aufgabe ein.",
        variant: "destructive",
      });
      return;
    }

    setLoading(true);

    try {
      await updateTask({
        variables: {
          input: {
            id: editedTask.id,
            title: editedTask.title,
            description: editedTask.description,
            status: editedTask.status,
          },
        },
      });

      toast({
        title: "Aufgabe aktualisiert",
        description: "Die Aufgabe wurde erfolgreich aktualisiert.",
        variant: "success",
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Beim Aktualisieren der Aufgabe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteTask() {
    if (loading) return;

    setLoading(true);

    try {
      await deleteTask({
        variables: {
          input: { id: task.id },
        },
      });

      toast({
        title: "Aufgabe gelöscht",
        description: "Die Aufgabe wurde erfolgreich gelöscht.",
        variant: "success",
      });

      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Beim Löschen der Aufgabe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
      setShowDeleteConfirmation(false);
    }
  }

  return (
    <>
      <form className={className}>
        <div className="grid w-full">
          {/* Main Part */}
          <div className="mx-auto max-h-[70vh] w-full overflow-y-scroll pl-3 md:max-h-[40vh]">
            <TextareaAutosize
              autoFocus
              id="title"
              value={editedTask.title}
              onChange={(e) => setEditedTask({ ...editedTask, title: e.target.value })}
              placeholder="Aufgabentitel"
              className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent px-6 py-3 pl-3 text-lg focus:outline-none"
            />

            <RichTextEditor
              onChange={onRichTextEditorChange}
              hideFloatingMenu={false}
              placeholder="Beschreibung der Aufgabe..."
              initialValue={initialEditorState}
            />

            <div className="flex flex-row items-center gap-2 px-3 py-2">
              <TaskStatusSelect
                value={editedTask.status}
                onValueChange={(value: TaskStatus) => setEditedTask({ ...editedTask, status: value })}
              />
            </div>
          </div>

          <div className="border-b"></div>
          <div className="flex flex-row items-center justify-end gap-4 px-6 py-4 ">
            <Button
              type="button"
              size="xs"
              variant="outline"
              className="border-red-200 text-red-500 hover:bg-red-50"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              Delete
            </Button>

            <div className="flex items-center gap-2 ">
              <Button type="button" size="xs" onClick={handleSaveTask} disabled={loading || !hasChanges}>
                {loading ? "Speichert..." : "Aufgabe speichern"}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <DeleteConfirmation
        open={showDeleteConfirmation}
        onOpenChange={setShowDeleteConfirmation}
        title="Aufgabe löschen"
        description="Diese Aktion kann nicht rückgängig gemacht werden. Dadurch wird die Aufgabe dauerhaft gelöscht."
        onConfirm={handleDeleteTask}
        confirmButtonText="Löschen"
        cancelButtonText="Abbrechen"
      />
    </>
  );
};
