import {
  teamByIdQueryDocument,
  teamsForUserInWorkspaceQueryDocument,
} from "@/components/_domain/team/hooks/useTeam/team.gql";
import { teamMembershipsForTeamQueryDocument } from "@/components/_domain/team/hooks/useTeam/team-membership.gql";
import { Superuser_DeleteTeamMembershipMutation } from "@/graphql/generated/graphql";
import { superuser_teamListForWorkspaceQueryDocument } from "@/graphql/superuser";
import { superuser_deleteTeamMembershipMutationDocument } from "@/graphql/superuser/mutations/team-membership.mutations";
import { useMutation } from "@apollo/client";

export type DeletedTeamMembership = Superuser_DeleteTeamMembershipMutation["superuser_deleteTeamMembership"];

export interface UseDeleteTeamMembershipProps {
  team: {
    id: string;
    workspaceId: string;
  };
  onCompleted?: (o: DeletedTeamMembership | undefined) => void;
  onError?: (e: Error) => void;
}

export const useTeamMembershipDelete = ({ onCompleted, onError, ...props }: UseDeleteTeamMembershipProps) => {
  const [deleteTeamMembership, queryResult] = useMutation(superuser_deleteTeamMembershipMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
      {
        query: teamByIdQueryDocument,
        variables: { input: props.team.id },
      },
      {
        query: teamsForUserInWorkspaceQueryDocument,
        variables: { input: props.team.workspaceId },
      },
      {
        query: superuser_teamListForWorkspaceQueryDocument,
        variables: { input: props.team.workspaceId },
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const deletedTeamMembership = o?.superuser_deleteTeamMembership as DeletedTeamMembership;

    if (onCompleted) {
      onCompleted(deletedTeamMembership ?? undefined);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    deleteTeamMembership,
  };
};
