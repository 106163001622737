import { Outlet } from "react-router-dom";

import { CurrentClimateActionListContextProvider } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useGetClimateActionListForWorkspace } from "@/hooks/api/common/useClimateAction";

export const ClimateActionListRootLayout = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  const { climateActionList, loading, error } = useGetClimateActionListForWorkspace({
    workspaceId: currentWorkspace.id,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  return (
    <CurrentClimateActionListContextProvider climateActionList={climateActionList}>
      <Outlet />
    </CurrentClimateActionListContextProvider>
  );
};
