import { deletePhaseInstanceMutationDocument } from "@/graphql/common/phase-instance";
import { type DeletePhaseInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PHASE_INSTANCE_DELETED, trackPhaseEvent } from "./tracking";

export type DeletedPhaseInstance = DeletePhaseInstanceMutation["deletePhaseInstance"];

export interface UseDeletePhaseInstanceProps {
  onCompleted?: (data: DeletedPhaseInstance) => void;
  onError?: (e: Error) => void;
}

export const useDeletePhaseInstance = ({ onCompleted, onError }: UseDeletePhaseInstanceProps = {}) => {
  const [deletePhaseInstance] = useMutation(deletePhaseInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedPhaseInstance = data?.deletePhaseInstance;
      if (!deletedPhaseInstance) {
        return;
      }

      onGenericEntityInstanceDelete({
        cache,
        deletedEntityInstance: {
          ...deletedPhaseInstance,
          __parentId: deletedPhaseInstance.phaseId,
        },
        typename: "PhaseInstance",
      });
      trackPhaseEvent(EVENT_PHASE_INSTANCE_DELETED, deletedPhaseInstance);
    },
  });

  function onUpdateCompleted(data: DeletePhaseInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.deletePhaseInstance);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deletePhaseInstance,
  };
};
