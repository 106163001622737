import {
  updateWorkspaceMutationDocument,
  workspaceByIdQueryDocument,
  workspaceByUrlQueryDocument,
} from "@/graphql/common/workspace";
import { UpdateWorkspaceMutation } from "@/graphql/generated/graphql";
import { EVENT_WORKSPACE_UPDATED, trackWorkspaceEvent } from "@/hooks/api/common/useWorkspace/tracking";
import { useMutation } from "@apollo/client";

export type UpdatedWorkspace = UpdateWorkspaceMutation["updateWorkspace"];

export interface UseUpdateWorkspaceProps {
  workspace: {
    id: string;
    workspaceUrl: string;
  };
  onCompleted?: (o: UpdatedWorkspace | undefined) => void;
  onError?: (e: Error) => void;
}

export const useWorkspaceUpdate = ({ onCompleted, onError, workspace }: UseUpdateWorkspaceProps) => {
  const [updateWorkspace, queryResult] = useMutation(updateWorkspaceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const workspace = data?.updateWorkspace;
      if (!workspace) {
        return;
      }
      trackWorkspaceEvent(EVENT_WORKSPACE_UPDATED, workspace);
    },
    refetchQueries: [
      {
        query: workspaceByUrlQueryDocument,
        variables: {
          input: workspace.workspaceUrl,
        },
      },
      {
        query: workspaceByIdQueryDocument,
        variables: {
          input: workspace.id,
        },
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const updatedWorkspace = o?.updateWorkspace as UpdatedWorkspace;

    if (onCompleted) {
      onCompleted(updatedWorkspace ?? undefined);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    updateWorkspace,
  };
};
