import { FaDatabase } from "react-icons/fa6";
import { Navigate, Outlet } from "react-router-dom";

import { ClimateActionCreateDialog } from "@/components/_domain/climate-action/climate-action-create-dialog";
// Page Context
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
// Actual Page
import IconAllItems from "@/components/_icons/IconAllItems";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { SidebarNavElement } from "@/components/_layout/sidebar/sidebar-nav-element";
import { FeatureFlagToggle } from "@/components/feature-flag-toggle";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";
import { useCheckPermission } from "@/hooks/api/common/usePermission";

const InnerLayout = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  return (
    <div className="flex h-full max-h-full flex-col overflow-y-hidden">
      <HeaderLayout>
        <div className="flex w-full flex-row items-center justify-between  gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="flex max-w-[8rem] flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
              Maßnahmenkatalog
            </div>
            <FavoriteButton pageName="Maßnahmen" />
            <FeatureFlagToggle flag={"enable-climate-action-dashboard"}>
              <SidebarNavElement
                to={`/${currentWorkspace.workspaceUrl}/climate-actions/dashboard`}
                className="flex flex-row items-center [&>span]:h-6 [&>span]:gap-2 [&>span]:border [&>span]:p-2"
                end
              >
                <IconAllItems />
                Dashboard
              </SidebarNavElement>
            </FeatureFlagToggle>
            <SidebarNavElement
              to={`/${currentWorkspace.workspaceUrl}/climate-actions/all`}
              className="flex flex-row items-center [&>span]:h-6 [&>span]:gap-2 [&>span]:border [&>span]:p-2"
              end
            >
              <FaDatabase />
              Maßnahmen
            </SidebarNavElement>
          </div>
          <div className="flex flex-row items-center gap-2">
            <ClimateActionCreateDialog workspace={currentWorkspace} />
          </div>
        </div>
      </HeaderLayout>
      <Outlet />
    </div>
  );
};

export const ClimateActionListLayout = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: currentWorkspace.id,
    action: "manage",
  });

  if (!hasPermissionToManageWorkspace) {
    return <Navigate to="/" />;
  }

  return <InnerLayout />;
};
