import { getFocusAreaQueryDocument, updateFocusAreaMutationDocument } from "@/graphql/common/focus-area";
import { useMutation } from "@apollo/client";

import { EVENT_FOCUS_AREA_UPDATED, trackFocusAreaEvent } from "./tracking";

export interface UseUpdateFocusAreaProps {
  focusAreaId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useUpdateFocusArea = ({ focusAreaId, onCompleted, onError }: UseUpdateFocusAreaProps) => {
  const [updateFocusArea] = useMutation(updateFocusAreaMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getFocusAreaQueryDocument,
        variables: { input: { focusAreaId: focusAreaId } },
      },
    ],
    update: (_cache, { data }) => {
      const updatedFocusArea = data?.updateFocusArea;
      if (!updatedFocusArea) {
        return;
      }
      trackFocusAreaEvent(EVENT_FOCUS_AREA_UPDATED, updatedFocusArea);
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }
  return {
    updateFocusArea,
  };
};
