import { FC } from "react";

import { PriorityIcon } from "@/components/_domain/priority/priority-icon";
import { useGetPriority } from "@/hooks/api/common/usePriority";
import { cn } from "@/lib/utils";

import { PriorityViewEmpty } from "./priority-view-empty";

interface PriorityViewProps {
  priority: {
    id: string;
  } | null;
  logoOnly?: boolean;
  className?: string;
}

export const PriorityView: FC<PriorityViewProps> = ({ ...props }) => {
  const { priority } = useGetPriority({ id: props.priority?.id });

  if (!priority) {
    return <PriorityViewEmpty {...props} />;
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <PriorityIcon priority={priority} className={cn(["h-4 w-4", props.className])} />
      {!props.logoOnly && <div>{priority.name}</div>}
    </div>
  );
};

export default PriorityView;
