import { type FC } from "react";

import IconCompose from "@/components/_icons/IconCompose";
import { Button } from "@/components/_ui/button";
import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { useGetTeam } from "@/hooks/api/common/useWorkspace/useGetTeam";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace/useWorkspaceGetById";

import { useClimateActionCreateHandler } from "../hooks/useCreateClimateActionHandler";

import ClimateActionCreateForm from "./climate-action-create-form";

export interface ClimateActionCreateDialogProps {
  workspace: {
    id: string;
  };
  team?: {
    id: string;
  };
}

export const ClimateActionCreateDialog: FC<ClimateActionCreateDialogProps> = ({ ...props }) => {
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const { team } = useGetTeam({ id: props.team?.id });
  const { showModal, setShowModal } = useClimateActionCreateHandler();

  function onDialogOpenChange(open: boolean) {
    setShowModal(open);
  }

  if (!workspace) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
        onClick={() => setShowModal(true)}
      >
        <IconCompose className="mr-2" />
        Neue Maßnahme
      </Button>
      <Dialog open={showModal} onOpenChange={onDialogOpenChange}>
        <DialogContent className="top-[1%] max-w-3xl translate-y-[0%] md:top-[20%]">
          <ClimateActionCreateForm
            workspace={workspace}
            team={team ?? undefined}
            triggerCloseDialog={() => {
              onDialogOpenChange(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
