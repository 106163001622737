import { useMemo } from "react";

import { deleteAssigneeMutationDocument, getAssigneeByOwnerQueryDocument } from "@/graphql/common/assignee";
import { getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { useMutation } from "@apollo/client";

import { EVENT_ASSIGNEE_DELETED, trackAssigneeEvent } from "./tracking";

export interface UseDeleteAssigneeProps {
  ownerId: string;
  ownerType: "CLIMATE_ACTION";
  oldUserId?: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useDeleteAssignee = ({ ownerId, ownerType, oldUserId, onCompleted, onError }: UseDeleteAssigneeProps) => {
  const ownerQueryDocument = useMemo(() => {
    if (ownerType === "CLIMATE_ACTION") {
      return {
        query: getClimateActionQueryDocument,
        variables: { input: { id: ownerId } },
      };
    }
    return null;
  }, [ownerId, ownerType]);

  const refetchQueries = useMemo(() => {
    const refetchQueries = [];

    refetchQueries.push({
      query: getAssigneeByOwnerQueryDocument,
      variables: { input: { ownerId } },
    });

    if (ownerQueryDocument) {
      refetchQueries.push(ownerQueryDocument);
    }

    return refetchQueries;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerId, oldUserId, ownerQueryDocument]);

  const [deleteAssignee] = useMutation(deleteAssigneeMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
    update: (_cache, { data }) => {
      const assignee = data?.deleteAssignee;
      if (!assignee) {
        return;
      }
      trackAssigneeEvent(EVENT_ASSIGNEE_DELETED, assignee);
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteAssignee,
  };
};
