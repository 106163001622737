import { FC } from "react";

import { useGetTeam } from "@/hooks/api/common/useWorkspace/useGetTeam";
import { cn } from "@/lib/utils";

import { TeamIcon } from "./team-icon";

export interface TeamViewProps {
  team: {
    id: string;
  };
  className?: string;
}

export const TeamView: FC<TeamViewProps> = ({ ...props }) => {
  const { team } = useGetTeam({ id: props.team.id });
  if (!team) {
    return <></>;
  }

  return (
    <div
      className={cn([
        "my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm ",
        props.className,
      ])}
    >
      <span className=" rounded bg-muted p-1">
        <TeamIcon team={team} />
      </span>
      <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
        {team.name}
      </span>
    </div>
  );
};
