import { useEffect, useMemo } from "react";

import { teamsForUserInWorkspaceQueryDocument } from "@/components/_domain/team/hooks/useTeam/team.gql";
import { teamMembershipsForTeamQueryDocument } from "@/components/_domain/team/hooks/useTeam/team-membership.gql";
import { apolloClient } from "@/services/apollo.service/apollo.service";
import { useQuery } from "@apollo/client";

export interface UseGetTeamListProps {
  workspaceId?: string;
  userId?: string;
  shouldUpdateCache?: boolean;
  enabled?: boolean;
}

export const useTeamListForUserInWorkspace = ({ enabled = true, ...props }: UseGetTeamListProps) => {
  const queryResult = useQuery(teamsForUserInWorkspaceQueryDocument, {
    variables: { input: props.workspaceId ?? "" },
    skip: !props.workspaceId || !props.userId || !enabled,
  });

  const teamList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.teamsForUserInWorkspace;
  }, [queryResult.data]);

  const activeTeamList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.teamsForUserInWorkspace.filter((t) => {
      return t.teamMembershipList.some((tm) => tm.status === "ACTIVE" && tm.userId === props.userId);
    });
  }, [queryResult.data, props.userId]);

  useEffect(() => {
    if (!props.shouldUpdateCache) {
      return;
    }

    for (const team of teamList) {
      if (team.teamMembershipList) {
        apolloClient.cache.writeQuery({
          query: teamMembershipsForTeamQueryDocument,
          variables: { input: team.id },
          data: {
            teamMembershipsForTeam: team.teamMembershipList,
          },
        });
      }
    }
  }, [teamList, props.shouldUpdateCache]);

  return {
    teamList,
    activeTeamList,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
