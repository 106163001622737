import { useMemo } from "react";

import { teamByIdQueryDocument } from "@/components/_domain/team/hooks/useTeam/team.gql";
import { useQuery } from "@apollo/client";

export interface UseGetTeamProps {
  id?: string;
  enabled?: boolean;
}

export const useGetTeam = ({ enabled = true, ...props }: UseGetTeamProps) => {
  const queryResult = useQuery(teamByIdQueryDocument, {
    variables: { input: props.id ?? "" },
    skip: !props.id || !enabled,
  });

  const team = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }
    return queryResult.data.teamById;
  }, [queryResult.data]);

  return {
    team,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
