import { graphql } from "../generated/gql";

export const createSignedUploadUrlMutationDocument = graphql(/* GraphQL */ `
  mutation createSignedUploadUrlMutation($input: SignedUploadUrlCreateInput!) {
    createSignedUploadUrl(signedUploadUrlCreateInput: $input) {
      permaUrl
      signedUrl
      type
    }
  }
`);
