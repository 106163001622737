import { FC, useMemo } from "react";
import {
  HiMiniMinus,
  HiOutlineBuildingOffice2,
  HiOutlineCurrencyEuro,
  HiOutlineGlobeAlt,
  HiOutlineScale,
  HiOutlineTruck,
  HiOutlineUserGroup,
  HiOutlineWrenchScrewdriver,
} from "react-icons/hi2";

import { IndicatorType, IndicatorTypeGermanNames } from "../models/indicator-type.model";

interface IndicatorTypeViewProps {
  type?: IndicatorType | null;
  modified?: boolean;
  logoOnly?: boolean;
  className?: string;
}

export const IndicatorTypeView: FC<IndicatorTypeViewProps> = ({ type, modified, ...props }) => {
  const Icon = useMemo(() => {
    if (!type) return HiMiniMinus;
    switch (type) {
      case "Environment":
        return HiOutlineGlobeAlt;
      case "People":
        return HiOutlineUserGroup;
      case "Economy":
        return HiOutlineCurrencyEuro;
      case "Infrastructure":
        return HiOutlineWrenchScrewdriver;
      case "Buildings":
        return HiOutlineBuildingOffice2;
      case "Governance":
        return HiOutlineScale;
      case "Mobility":
        return HiOutlineTruck;
      default:
        return HiMiniMinus;
    }
  }, [type]);

  const displayText = useMemo(() => {
    if (!type) return "Nicht zugewiesen";
    return IndicatorTypeGermanNames[type];
  }, [type]);

  return (
    <div className="flex flex-row items-center gap-2">
      {Icon && <Icon className={props.className} />}
      {!props.logoOnly && (
        <>
          <span className="text-sm">{displayText}</span>
          {modified && <div className="ml-1 h-1.5 w-1.5 rounded-full bg-muted-foreground"></div>}
        </>
      )}
    </div>
  );
};
