import { FC } from "react";

import IconPlus from "@/components/_icons/IconPlus";
import { Button } from "@/components/_ui/button";

import { ClimateActionFilterSelect } from "../climate-action-filter-select";

export interface AddAdditionalFilterButtonProps {}

export const AddAdditionalFilterButton: FC<AddAdditionalFilterButtonProps> = () => {
  return (
    <ClimateActionFilterSelect side="bottom" asChild={true}>
      <Button
        variant="ghost"
        size="xs"
        className="h-6 w-full justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
      >
        <IconPlus className="h-3.5 w-3.5 text-foreground/60" />
      </Button>
    </ClimateActionFilterSelect>
  );
};
