import { Navigate } from "react-router-dom";

import { FocusAreaClimateActionListAllPage } from "./:focusAreaId/climate-actions/(list)/focus-area-climate-action-list.all.page";
import { FocusAreaClimateActionListDashboardPage } from "./:focusAreaId/climate-actions/(list)/focus-area-climate-action-list.dashboard.page";
import { FocusAreaClimateActionListLayout } from "./:focusAreaId/climate-actions/(list)/focus-area-climate-action-list.layout";
import { FocusAreaClimateActionListRootLayout } from "./:focusAreaId/climate-actions/focus-area-climate-action-list-root.layout";
import { FocusAreaLayout } from "./:focusAreaId/focus-area.layout";
import { FocusAreaListLayout } from "./focus-area-list.layout";
import { FocusAreaListPage } from "./focus-area-list.page";

export const focusAreaRoutes = [
  {
    path: "focus-areas",
    element: <FocusAreaListLayout />, // inits currentFocusAreaListContext
    children: [
      { path: "", element: <FocusAreaListPage /> },
      {
        path: ":focusAreaId",
        element: <FocusAreaLayout />,
        children: [
          { path: "", element: <Navigate to="climate-actions" replace /> },
          {
            path: "climate-actions",
            element: <FocusAreaClimateActionListRootLayout />, // inits currentClimateActionListContext
            children: [
              {
                path: "",
                element: <FocusAreaClimateActionListLayout />, // shared header with tabs
                children: [
                  { path: "", element: <Navigate to="all" replace /> },
                  { path: "all", element: <FocusAreaClimateActionListAllPage /> },
                  { path: "dashboard", element: <FocusAreaClimateActionListDashboardPage /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
