import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  teamVisibilityTypefromString,
  VisibilityList,
  VisibilityType,
} from "@/components/_domain/team/team-visibility.schema";
import IconTeams from "@/components/_icons/IconTeams";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/_ui/select";
import { useToast } from "@/components/_ui/use-toast";
import { IconPicker } from "@/components/icon-picker";
import { Icon } from "@/components/icon-picker/icon.schema";
import { IconView } from "@/components/icon-picker/icon-view";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace";
import { useTeamCreate } from "@/hooks/api/common/useWorkspace/useTeamCreate";
import { useTeamCreateBySuperuser } from "@/hooks/api/superuser/useWorkspace/useTeamCreateBySuperuser";
import { cn } from "@/lib/utils";

export interface TeamCreateFormProps {
  workspace: {
    id: string;
  };
  triggerCloseDialog?: () => void;
  shouldRedirect?: boolean;
  isSuperuser?: boolean;
  className?: string;
}
export const TeamCreateForm: FC<TeamCreateFormProps> = ({ className, ...props }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const createTeamHook = props.isSuperuser ? useTeamCreateBySuperuser : useTeamCreate;
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const { createTeam } = createTeamHook({
    workspaceId: props.workspace.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  const [name, setName] = useState("");
  const [icon, setIcon] = useState<Icon | null>(null);
  const [visibility, setVisibility] = useState<VisibilityType>(VisibilityList.public);

  // 2. Define a submit handler.
  function onSubmit() {
    let logo = {};

    if (icon) {
      logo = {
        logoType: icon.mode,
        logo: icon.value,
        color: icon.color,
      };
    }

    createTeam({
      variables: {
        input: {
          name: name,
          workspaceId: props.workspace.id,
          visibility: visibility,
          ...logo,
        },
      },
    });
  }

  async function onUpdateCompleted(team: { id: string } | undefined) {
    toast({
      title: "Gespeichert",
      description: "Das Team wurde erfolgreich erstellt.",
      variant: "success",
    });

    if (!workspace) {
      return;
    }
    if (!team) {
      return;
    }
    if (props.shouldRedirect) {
      const redirectUrl = `/${workspace.workspaceUrl}/settings/teams/${team.id}`;
      setTimeout(() => {
        navigate(redirectUrl, {
          replace: true,
        });
      }, 1000);
    }
    if (props.triggerCloseDialog) {
      props.triggerCloseDialog();
    }
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <div className={cn("flex flex-col gap-4", className)}>
      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium">Team Icon und Name</div>
        <div className="flex flex-row items-center gap-2">
          <IconPicker asChild onChange={setIcon}>
            <Button variant="secondary" size="icon" className="h-9 w-10">
              {icon && <IconView icon={icon} />}
              {!icon && <IconTeams className="text-foreground/40" />}
            </Button>
          </IconPicker>
          <Input
            type="text"
            placeholder="zB. Nachhaltigkeit"
            value={name}
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
            className="without-ring"
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium">Sichtbarkeit</div>
        <div>
          <Select
            defaultValue={visibility}
            onValueChange={(value) => {
              const visibility = teamVisibilityTypefromString(value);

              if (!visibility) {
                return;
              }

              setVisibility(visibility);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Sichtbarkeit" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={VisibilityList.public}>Öffentlich</SelectItem>
              <SelectItem value={VisibilityList.private}>Privat</SelectItem>
              <SelectItem value={VisibilityList.hidden}>Versteckt</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <Button
        type="submit"
        className="flex h-10 w-32 items-center justify-center rounded-md border text-sm transition-all focus:outline-none"
        onClick={onSubmit}
      >
        Erstellen
      </Button>
    </div>
  );
};
