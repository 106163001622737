import { FC, PropsWithChildren, ReactElement, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";

import { ALLOWED_STATUS_LIST, StatusList, StatusType } from "../status-types";

import { StatusSelectDropdownItem } from "./status-select-dropdown-item";

export interface StatusSelectDropdownProps extends PropsWithChildren {
  defaultValue?: StatusType;
  onSelect?: (status: StatusType) => void;
  asChild?: boolean;
}

export const StatusSelectDropdown: FC<StatusSelectDropdownProps> = ({ children, asChild, onSelect, ...props }) => {
  const [selectedStatus, setSelectedStatus] = useState<StatusType>(props.defaultValue ?? StatusList.onTrack);

  const comboboxOptions = useMemo(() => {
    return ALLOWED_STATUS_LIST.slice().flatMap((u) => {
      if (!u) {
        return [];
      }
      return {
        id: u,
        value: u as StatusType,
      };
    });
  }, []);

  const renderItem = (option: ComboboxOption): ReactElement => {
    const selected = selectedStatus === option.id;
    return <StatusSelectDropdownItem type={option.value as StatusType} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    setSelectedStatus(selectedOption.value as StatusType);
    if (onSelect) {
      onSelect(selectedOption.value as StatusType);
    }
  };

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      placeholder="Status"
      side="bottom"
    >
      {children}
    </Combobox>
  );
};
