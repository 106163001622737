import { createContext, ReactNode, useContext, useEffect, useState } from "react";

import { useInterval } from "@/hooks/api/_util/useInterval";
import { isAuthenticated, refreshAccessToken, signout } from "@/services/auth.service";

type Props = {
  children?: ReactNode;
};

type AuthContextType = {
  authenticated: boolean;
  setAuthenticated: (newState: boolean) => void;
};

const initialValue = {
  authenticated: false,
  setAuthenticated: () => {},
};

export const AuthContext = createContext<AuthContextType>(initialValue);

export const AuthProvider = ({ children }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useInterval(
    () => {
      // make sure that the user is logged out once the token expires
      if (authenticated) {
        dispatchRefreshAccessToken();
      }
    },
    // Delay in milliseconds or null to stop it
    60000, // one minute
  );

  useEffect(() => {
    intializeAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function intializeAuthState() {
    if (initialized) {
      return;
    }

    if (!isAuthenticated()) {
      setInitialized(true);
      return;
    }

    await dispatchRefreshAccessToken();

    setInitialized(true);
  }

  async function dispatchRefreshAccessToken() {
    try {
      await refreshAccessToken();
      // if we manage to refresh the access token we can assume that we are logged in
      setAuthenticated(true);
    } catch (e) {
      // if we fail here, we assume that we are logged out
      // signout();
    }
  }

  function handleSetAuthenticated(value: boolean) {
    if (!value) {
      signout();
    }
    setAuthenticated(value);
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated: handleSetAuthenticated,
      }}
    >
      {initialized && children}
    </AuthContext.Provider>
  );
};

// Define the useCurrentWorkspace hook
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return context;
};
