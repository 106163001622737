import { useMemo } from "react";

import { getFeatureFlagQueryDocument } from "@/graphql/common/feature-flag";
import { useQuery } from "@apollo/client";

import { FeatureFlagIDs } from "./config";

interface UseFeatureFlagProps {
  workspaceId: string;
  id: FeatureFlagIDs;
  refetch?: boolean; // force a refetch from posthog (not the same as apollo-client cache)
}

export const useFeatureFlag = ({ workspaceId, id, refetch = false }: UseFeatureFlagProps) => {
  const query = useQuery(getFeatureFlagQueryDocument, {
    variables: {
      getFeatureFlagInput: {
        workspaceId,
        id,
        refetch,
      },
    },
    skip: !workspaceId || !id,
  });

  const featureFlag = useMemo(() => {
    const data = query.data;
    if (!data?.featureFlag) {
      return null;
    }

    try {
      const payload: unknown = JSON.parse(data.featureFlag.payload || "{}");

      return {
        ...data.featureFlag,
        payload,
      };
    } catch (_error) {
      return {
        ...data.featureFlag,
        payload: null,
      };
    }
  }, [query.data]);

  const isEnabled = useMemo(() => {
    return featureFlag?.enabled || false;
  }, [featureFlag]);

  return {
    featureFlag,
    isEnabled,
    refetch: query.refetch,
    loading: query.loading,
    error: query.error,
    query,
  };
};
