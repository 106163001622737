import { useCallback } from "react";

import { WorkspaceCreateDialog } from "@/components/_domain/workspace/workspace-create-dialog";
import { columns, WorkspaceDatatable } from "@/components/_domain/workspace/workspace-datatable";
import { WorkspaceDuplicateDialog } from "@/components/_domain/workspace/workspace-duplicate-dialog";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { Link } from "@/components/link";
import { useGetWorkspaceList } from "@/hooks/api/superuser/useWorkspace";

export default function Page() {
  const { workspaceList } = useGetWorkspaceList();

  const makeAdminUrl = useCallback((path: string) => {
    return `/admin/${path}`;
  }, []);

  if (!workspaceList) {
    return <></>;
  }

  return (
    <div className="flex h-full max-h-full flex-col overflow-y-hidden">
      <HeaderLayout>
        <div className="flex flex-row items-center gap-2">
          <Link to={makeAdminUrl("")}>
            <div className="my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm ">
              <span className="rounded p-1">⚠️</span>
              <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
                Admin Panel
              </span>
            </div>
          </Link>
          <div>›</div>
          <Link to={makeAdminUrl("")}>
            <div className="flex flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
              Workspace Management
            </div>
          </Link>
        </div>
        <div className="flex flex-row items-center gap-2">
          <WorkspaceDuplicateDialog />
          <WorkspaceCreateDialog />
        </div>
      </HeaderLayout>

      <div className="px-0">
        <WorkspaceDatatable columns={columns} data={workspaceList} />
      </div>
    </div>
  );
}
