import { z } from "zod";

export const FilterByTextPayloadSchema = z.object({
  type: z.literal("filter_by_text"),
  query: z.string(),
  mode: z.union([z.literal("included"), z.literal("not_included")]),
});

export type FilterByTextPayload = z.infer<typeof FilterByTextPayloadSchema>;
export type FilterByTextMode = FilterByTextPayload["mode"];

export const filterByText = (payload: FilterByTextPayload, text: string | null | undefined): boolean => {
  if (payload.mode === "included") {
    if (!text) {
      return false;
    }
    return text.includes(payload.query);
  }

  if (payload.mode === "not_included") {
    if (!text) {
      return true;
    }
    return !text.includes(payload.query);
  }

  // fall through; shouldn't happen
  return true;
};

export const filterByTextWithUnknownPayload = (payload: unknown, text: string | null | undefined): boolean => {
  // const safePayload = FilterByIdPayloadSchema.parse(payload);

  // return filterById(safePayload, id);
  const safePayload = FilterByTextPayloadSchema.safeParse(payload);
  if (!safePayload.success) {
    return true; // if we cannot apply the filter then don't filter at all
  }

  return filterByText(safePayload.data, text);
};
