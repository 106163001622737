export const VisibilityList = {
  public: "PUBLIC",
  private: "PRIVATE",
  hidden: "HIDDEN",
} as const;

export const ALLOWED_TEAM_VISIBILITY_LIST = Object.values(VisibilityList);
export type VisibilityType = (typeof ALLOWED_TEAM_VISIBILITY_LIST)[number];

export const teamVisibilityTypefromString = (str: string): VisibilityType | undefined => {
  if (str === VisibilityList.public) {
    return VisibilityList.public;
  }
  if (str === VisibilityList.private) {
    return VisibilityList.private;
  }
  if (str === VisibilityList.hidden) {
    return VisibilityList.hidden;
  }

  return undefined;
};
