import { createLabelInstanceMutationDocument } from "@/graphql/common/label-instance";
import { type CreateLabelInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_LABEL_INSTANCE_CREATED, trackLabelEvent } from "./tracking";

export type CreatedLabelInstance = CreateLabelInstanceMutation["createLabelInstance"];

export interface UseCreateLabelInstanceProps {
  onCompleted?: (data: CreatedLabelInstance) => void;
  onError?: (e: Error) => void;
}

export const useCreateLabelInstance = ({ onCompleted, onError }: UseCreateLabelInstanceProps = {}) => {
  const [createLabelInstance] = useMutation(createLabelInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const labelInstance = data?.createLabelInstance;
      if (!labelInstance) {
        return;
      }

      onGenericEntityInstanceCreate({
        cache,
        createdEntityInstance: {
          ...labelInstance,
          __parentId: labelInstance.labelId,
        },
        typename: "LabelInstance",
      });

      trackLabelEvent(EVENT_LABEL_INSTANCE_CREATED, labelInstance);
    },
  });

  function onUpdateCompleted(data: CreateLabelInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.createLabelInstance);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createLabelInstance,
  };
};
