import { ClimateAction } from "@/components/_domain/climate-action/models";
import { ColumnDef } from "@tanstack/react-table";

import { ClimateActionListTeamCell } from "../../climate-action-list-main-view/climate-action-simple-list-view/cells/climate-action-list-team-cell";
import { FilterByIdPayloadSchema } from "../../filter-functions";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import { FilterByTeamConfigurationView, FilterByTeamDisplayView, FilterByTeamFilterPaneView } from "./components";

interface TeamColumnDefinitionProps {
  columnId: string;
  orderValue: number;
}

export const TeamColumnDefinition = ({
  columnId,
  orderValue,
}: TeamColumnDefinitionProps): ClimateActionColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true, // Hidden by default
    getDisplayValue: () => "Team",
    getOrderValue: () => orderValue,
    getColumnDef: (): ColumnDef<ClimateAction> => {
      return {
        id: columnId,
        accessorKey: "teamId",
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          const teamA = rowA.original.teamInstanceList?.[0]?.teamId || "workspace";
          const teamB = rowB.original.teamInstanceList?.[0]?.teamId || "workspace";
          return teamA.localeCompare(teamB);
        },
        filterFn: (row, _columnId, filterValue) => {
          if (!filterValue) return true;
          const safePayload = FilterByIdPayloadSchema.safeParse(filterValue);
          if (!safePayload.success) return true;

          const { mode, idList } = safePayload.data;

          // Check if the climate action has any teams that match the filter
          const teamIds = row.original.teamInstanceList.map((tca) => tca.teamId);

          // Handle the case where we're filtering for null (unassigned) teams
          if (idList.includes(null)) {
            if (mode === "included") {
              // If teamIds is empty, this is an unassigned climate action
              if (teamIds.length === 0) return true;
            } else {
              // For not_included mode, we want to exclude unassigned climate actions
              if (teamIds.length === 0) return false;
            }
          }

          if (mode === "included") {
            // Return true if ANY of the climate action's teams are in the filter list
            return idList.some((filterId) => filterId !== null && teamIds.includes(filterId));
          }

          // For "not_included" mode, return true if NONE of the climate action's teams are in the filter list
          return !idList.some((filterId) => filterId !== null && teamIds.includes(filterId));
        },
        header: () => <div>Team</div>, // No header since it's hidden
        cell: ({ row }) => <ClimateActionListTeamCell row={row} />,
      };
    },
    getDisplayView: () => <FilterByTeamDisplayView />,
    getConfigurationView: (onFinished) => (
      <FilterByTeamConfigurationView column={{ id: columnId }} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "popover",
    getFilterPaneView: () => <FilterByTeamFilterPaneView column={{ id: columnId }} />,
  };
};
