import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { CopyPlus } from "lucide-react";

import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import { Dialog, DialogContent } from "@/components/_ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { getLabelListForWorkspaceQueryDocument } from "@/graphql/common/label";
import { getLabelGroupListForWorkspaceQueryDocument } from "@/graphql/common/label-group";
import { getLabelInstancesQueryDocument } from "@/graphql/common/label-instance";
import {
  type CreatedLabel,
  useCreateLabelInstance,
  useGetLabelGroupListForWorkspace,
  useGetLabelInstanceList,
  useGetLabelListForWorkspace,
} from "@/hooks/api/common/useLabel";
import { useCheckPermission } from "@/hooks/api/common/usePermission";
import { WorkspaceLabelCreateForm } from "@/routes/settings/workspace/labels/label/workspace-label-create-form";

import AddLabelToEntityAction from "./actions/add-label-to-entity.action";
import { RemoveLabelFromEntityAction } from "./actions/remove-label-from-entity";
import LabelGroupItem from "./label-group-item";
import LabelItem from "./label-item";

export interface LabelSelectDropdownProps extends PropsWithChildren {
  owner: {
    id: string;
    type: "CLIMATE_ACTION";
  };
  workspace: Workspace;
  asChild?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const LabelSelectDropdown: FC<LabelSelectDropdownProps> = ({
  owner,
  workspace,
  asChild,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [showDialogForNewWorkspaceLabelInGroup, setShowDialogForNewWorkspaceLabelInGroup] = useState<
    { id: string } | undefined
  >(undefined);
  const { labelList } = useGetLabelListForWorkspace({
    workspaceId: workspace.id,
  });
  const { labelGroupList } = useGetLabelGroupListForWorkspace({
    workspaceId: workspace.id,
  });
  const { labelInstanceList } = useGetLabelInstanceList({ ownerId: owner.id });
  const { createLabelInstance } = useCreateLabelInstance();

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspace.id,
    action: "manage",
  });

  const filteredLabelList = useMemo(() => {
    return labelList
      .slice()
      .filter((l) => !l.labelGroupId)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [labelList]);

  useEffect(() => {
    const newOpenValue = props.open ? true : false;
    const valueHasChanged = newOpenValue !== open;

    if (!valueHasChanged) {
      return;
    }

    setOpen(newOpenValue);
    if (props.onOpenChange) {
      props.onOpenChange(newOpenValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  function getLabelInstance(labelId: string) {
    if (!labelInstanceList) {
      return undefined;
    }
    return labelInstanceList.find((li) => li.labelId === labelId);
  }

  function renderMenuItemForLabel(label: { id: string }) {
    const labelInstance = getLabelInstance(label.id);

    if (!labelInstance) {
      return (
        <AddLabelToEntityAction key={label.id} label={label} owner={owner} workspace={workspace} asChild>
          <DropdownMenuItem
            key={label.id}
            className="cursor-pointer p-0 text-foreground/60 hover:bg-accent hover:text-accent-foreground"
          >
            <LabelItem label={label} owner={owner} workspace={workspace} />
          </DropdownMenuItem>
        </AddLabelToEntityAction>
      );
    }

    return (
      <RemoveLabelFromEntityAction key={label.id} labelInstance={labelInstance} owner={owner} asChild>
        <DropdownMenuItem
          key={label.id}
          className="cursor-pointer p-0 text-foreground/60 hover:bg-accent hover:text-accent-foreground"
        >
          <LabelItem labelInstance={labelInstance} label={label} owner={owner} workspace={workspace} />
        </DropdownMenuItem>
      </RemoveLabelFromEntityAction>
    );
  }

  if (!labelInstanceList || !filteredLabelList || !labelGroupList) {
    return <></>;
  }

  function renderMenuItemForNewLabel(labelGroup: { id: string; workspaceId?: string | null | undefined }) {
    if (!labelGroup.workspaceId && !hasPermissionToManageWorkspace) {
      return <></>;
    }

    return (
      <DropdownMenuItem
        onClickCapture={() => {
          setShowDialogForNewWorkspaceLabelInGroup(labelGroup);
        }}
        className="cursor-pointer p-0 text-foreground/60 hover:bg-accent hover:text-accent-foreground"
      >
        <div className="group mx-0.5 mb-0.5 flex w-full flex-row items-center gap-2 rounded p-2 text-sm">
          <div className="w- flex flex-row items-center gap-2">
            <CopyPlus className={"h-3.5 w-3.5"} />
            <div>Neu erstellen</div>
          </div>
        </div>
      </DropdownMenuItem>
    );
  }

  async function handleCreateLabelInstance(newLabel: CreatedLabel) {
    if (!newLabel) {
      return;
    }
    try {
      await createLabelInstance({
        variables: {
          input: {
            labelId: newLabel.id,
            ownerId: owner.id,
            ownerType: owner.type,
            workspaceId: workspace.id,
          },
        },
        refetchQueries: [
          {
            query: getLabelInstancesQueryDocument,
            variables: { input: { ownerId: owner.id } },
          },
          {
            query: getLabelGroupListForWorkspaceQueryDocument,
            variables: { input: { workspaceId: workspace.id } },
          },
          {
            query: getLabelListForWorkspaceQueryDocument,
            variables: { input: { workspaceId: workspace.id } },
          },
        ],
      });
    } catch (e) {
      console.error("Error creating label instance", e);
    }
    setShowDialogForNewWorkspaceLabelInGroup(undefined);
  }

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
        <DropdownMenuTrigger asChild={asChild} disabled={labelGroupList.length === 0 && filteredLabelList.length === 0}>
          {children}
        </DropdownMenuTrigger>
        <DropdownMenuContent side="left" sticky="always">
          {/* Show the label groups first */}
          {labelGroupList.map((group) => {
            return (
              <DropdownMenuSub key={group.id}>
                <DropdownMenuSubTrigger className="cursor-pointer p-0 text-foreground/60 hover:bg-accent hover:text-accent-foreground">
                  <LabelGroupItem labelGroup={group} />
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    {renderMenuItemForNewLabel(group)}
                    {group.labels
                      .slice()
                      .sort((a, b) => {
                        const titleA = a?.title ?? "";
                        const titleB = b?.title ?? "";

                        return titleA.localeCompare(titleB);
                      })
                      .map((label) => (label ? renderMenuItemForLabel(label) : <></>))}
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            );
          })}
          {/* Show the labels without groups at the bottom */}
          {filteredLabelList.map((label) => renderMenuItemForLabel(label))}
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog
        open={!!showDialogForNewWorkspaceLabelInGroup}
        onOpenChange={(open) => {
          if (!open) {
            setShowDialogForNewWorkspaceLabelInGroup(undefined);
          }
        }}
        modal={true}
      >
        <DialogContent>
          <div className="my-4">
            <div className="text-lg font-medium">Neue Bezeichnung erstellen</div>
            {showDialogForNewWorkspaceLabelInGroup?.id && (
              <WorkspaceLabelCreateForm
                workspace={workspace}
                labelGroupId={showDialogForNewWorkspaceLabelInGroup?.id}
                onSuccess={handleCreateLabelInstance}
                onCanceled={() => {
                  setShowDialogForNewWorkspaceLabelInGroup(undefined);
                }}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LabelSelectDropdown;
