import { useMemo } from "react";
import { createPortal } from "react-dom";

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";

import { BoardColumnView, BoardContainer } from "./board-column";
import { BoardStateProvider, useBoardAnnouncements, useBoardState } from "./board-state-provider";
import { BoardColumn, BoardColumnConfig, BoardItem, BoardItemConfig } from "./board-types";
import { coordinateGetter } from "./keyboard-preset";

interface KanbanBoardProps<U extends BoardItem> {
  columns: BoardColumn<U>[];
  items: U[];

  setColumns: (columns: BoardColumn<U>[] | ((prevColumns: BoardColumn<U>[]) => BoardColumn<U>[])) => void;
  setItems: (items: U[]) => void;
  setIsDirty: (isDirty: boolean) => void;

  renderItem: (item: BoardItemConfig<U>) => React.ReactNode;
  renderColumnHeader: (config: BoardColumnConfig<U>) => React.ReactNode;
  renderColumnFooter: (config: BoardColumnConfig<U>) => React.ReactNode;
}

export function KanbanBoard<U extends BoardItem>(props: KanbanBoardProps<U>) {
  return (
    <BoardStateProvider<U>
      columns={props.columns}
      items={props.items}
      setColumns={props.setColumns}
      setItems={props.setItems}
      setIsDirty={props.setIsDirty}
    >
      <KanbanBoardContent<U>
        renderItem={props.renderItem}
        renderColumnHeader={props.renderColumnHeader}
        renderColumnFooter={props.renderColumnFooter}
      />
    </BoardStateProvider>
  );
}

interface KanbanBoardContentProps<U extends BoardItem> {
  renderItem: (item: BoardItemConfig<U>) => React.ReactNode;
  renderColumnHeader: (config: BoardColumnConfig<U>) => React.ReactNode;
  renderColumnFooter: (config: BoardColumnConfig<U>) => React.ReactNode;
}

function KanbanBoardContent<U extends BoardItem>({
  renderItem,
  renderColumnHeader,
  renderColumnFooter,
}: KanbanBoardContentProps<U>) {
  const { columns, items, activeColumn, activeItem, onDragStart, onDragEnd, onDragOver } = useBoardState<U>();

  const announcements = useBoardAnnouncements<U>();
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  // Create mouse and touch sensors
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 20,
      tolerance: 100,
    },
  });

  const touchSensor = useSensor(TouchSensor);

  // Create keyboard sensor
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: coordinateGetter,
  });

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  return (
    <DndContext
      accessibility={{
        announcements,
      }}
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <BoardContainer>
        <SortableContext items={columnsId}>
          {columns.map((col) => {
            return (
              <BoardColumnView
                key={col.id}
                column={col}
                items={items.filter((item) => item.columnId === col.id)}
                renderItem={renderItem}
                renderColumnHeader={renderColumnHeader}
                renderColumnFooter={renderColumnFooter}
              />
            );
          })}
        </SortableContext>
      </BoardContainer>

      {"document" in window &&
        createPortal(
          <DragOverlay>
            {activeColumn && (
              <BoardColumnView
                isOverlay
                column={activeColumn}
                items={items.filter((item) => item.columnId === activeColumn?.id)}
                renderItem={renderItem}
                renderColumnHeader={renderColumnHeader}
                renderColumnFooter={renderColumnFooter}
              />
            )}
            {activeItem &&
              renderItem({
                data: activeItem,
                isOverlay: true,
                isOver: false,
              })}
          </DragOverlay>,
          document.body,
        )}
    </DndContext>
  );
}
