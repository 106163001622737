import { updateFocusAreaInstanceMutationDocument } from "@/graphql/common/focus-area-instance";
import { type UpdateFocusAreaInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceUpdate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_FOCUS_AREA_INSTANCE_UPDATED, trackFocusAreaEvent } from "./tracking";

export type UpdatedFocusAreaInstance = UpdateFocusAreaInstanceMutation["updateFocusAreaInstance"];

export interface UseUpdateFocusAreaInstanceProps {
  onCompleted?: (data: UpdatedFocusAreaInstance) => void;
  onError?: (e: Error) => void;
}

export const useUpdateFocusAreaInstance = ({ onCompleted, onError }: UseUpdateFocusAreaInstanceProps = {}) => {
  const [updateFocusAreaInstance] = useMutation(updateFocusAreaInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const focusAreaInstance = data?.updateFocusAreaInstance;
      if (!focusAreaInstance) {
        return;
      }

      onGenericEntityInstanceUpdate({
        cache,
        updatedEntityInstance: {
          ...focusAreaInstance,
          __parentId: focusAreaInstance.focusAreaId,
        },
        typename: "FocusAreaInstance",
      });

      trackFocusAreaEvent(EVENT_FOCUS_AREA_INSTANCE_UPDATED, data.updateFocusAreaInstance);
    },
  });

  function onUpdateCompleted(data: UpdateFocusAreaInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.updateFocusAreaInstance);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updateFocusAreaInstance,
  };
};
