import { updatePhaseMutationDocument } from "@/graphql/common/phase";
import { type UpdatePhaseMutation } from "@/graphql/generated/graphql";
import { useMutation } from "@apollo/client";

import { EVENT_PHASE_UPDATED, trackPhaseEvent } from "./tracking";

export type UpdatedPhase = UpdatePhaseMutation["updatePhase"];

export interface UseUpdatePhaseProps {
  onCompleted?: (data: UpdatedPhase) => void;
  onError?: (e: Error) => void;
}

export const useUpdatePhase = ({ onCompleted, onError }: UseUpdatePhaseProps) => {
  const [updatePhase] = useMutation(updatePhaseMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const updatedPhase = data?.updatePhase;
      if (!updatedPhase) {
        return;
      }

      trackPhaseEvent(EVENT_PHASE_UPDATED, updatedPhase);
    },
  });

  function onUpdateCompleted(data: UpdatePhaseMutation) {
    if (onCompleted) {
      onCompleted(data.updatePhase);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }
  return {
    updatePhase,
  };
};
