import { FC, useState } from "react";

import IconDots from "@/components/_icons/IconDots";
import IconHeartbeat from "@/components/_icons/IconHeartbeat";
import IconPen from "@/components/_icons/IconPen";
import IconTrash from "@/components/_icons/IconTrash";
import { Button } from "@/components/_ui/button";
import { Dialog, DialogContent } from "@/components/_ui/dialog";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "@/components/_ui/menubar";
import { toast } from "@/components/_ui/use-toast";
import DeleteConfirmation from "@/components/delete-confirmation";
import { getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { deleteKeyResultMutationDocument } from "@/graphql/common/key-result";
import { useMutation } from "@apollo/client";

import { useProgressUpdateUIStateState } from "../../hooks/useCreateProgressUpdateUIState";

import KeyResultCreateForm from "./forms/key-result-create-form";
import KeyResultEditForm from "./forms/key-result-edit-form";
import KeyResultProgressView from "./key-result-progress-view";

interface ClimateActionKeyResultViewProps {
  climateAction: {
    id: string;
    keyResult?: {
      id: string;
    } | null;
  };
  workspace: {
    id: string;
    name: string;
    workspaceUrl: string;
    logo?: string | null;
  };
}

export const ClimateActionKeyResultView: FC<ClimateActionKeyResultViewProps> = ({ climateAction, workspace }) => {
  const [createKeyResultDialogisOpen, setCreateKeyResultDialogisOpen] = useState(false);
  const [updateKeyResultDialogisOpen, setUpdateKeyResultDialogisOpen] = useState(false);

  const { setShowModal: setCreateProgressSnapshotDialogisOpen } = useProgressUpdateUIStateState();

  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);

  const [deleteKeyResult] = useMutation(deleteKeyResultMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getClimateActionQueryDocument,
        variables: { input: { id: climateAction.id } },
      },
    ],
  });

  // eslint-disable-next-line
  async function onUpdateCompleted(data: any) {
    const { id, title } = data.deleteKeyResult;

    if (id && title) {
      toast({
        title: "Erfolgsindikator gelöscht",
        description: (
          <div className="gap-21 flex flex-col">
            <span>Der Erfolgsindikator wurde gelöscht.</span>
          </div>
        ),
        variant: "success",
      });
    }
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  const handleDeleteKeyResult = async () => {
    if (!climateAction.keyResult) {
      return;
    }

    await deleteKeyResult({
      variables: {
        input: {
          id: climateAction.keyResult.id,
        },
      },
    });
  };

  return (
    <>
      <div className="mb-5 flex flex-col items-start gap-2">
        <div className="flex w-full flex-row items-start justify-between gap-2">
          <div className="mt-2 w-24 shrink-0 font-medium">Erfolgsindikator</div>
          {climateAction.keyResult && (
            <div>
              <Menubar className="shadow-none rounded-md">
                <MenubarMenu>
                  <MenubarTrigger asChild>
                    <Button variant="ghost" size="sm" className="select-none rounded-md">
                      <IconDots />
                    </Button>
                  </MenubarTrigger>
                  <MenubarContent className="rounded-md">
                    <MenubarItem onClick={() => setCreateProgressSnapshotDialogisOpen(true)}>
                      <div className="flex flex-row items-center gap-1">
                        <IconHeartbeat className="mr-1 h-3 w-3 text-muted-foreground" />
                        Fortschrittsbericht erstellen
                      </div>
                    </MenubarItem>
                    <MenubarItem onClick={() => setUpdateKeyResultDialogisOpen(true)}>
                      <div className="flex flex-row items-center gap-1">
                        <IconPen className="mr-1 h-4 w-4 text-muted-foreground" />
                        Bearbeiten
                      </div>
                    </MenubarItem>

                    <MenubarSeparator />

                    {/* <MenubarItem onClick={handleDeleteClimateAction}> */}
                    <MenubarItem
                      onClick={() => {
                        setDeleteConfirmationisOpen(true);
                      }}
                    >
                      <div className="flex flex-row items-center gap-1">
                        <IconTrash className="mr-2 h-3 w-3 text-muted-foreground" />
                        Löschen
                      </div>
                    </MenubarItem>
                  </MenubarContent>
                </MenubarMenu>
              </Menubar>
            </div>
          )}
        </div>
        <div className="w-full shrink-0 font-medium">
          {!climateAction.keyResult && (
            <div
              onClick={() => setCreateKeyResultDialogisOpen(true)}
              className="border-text-muted no-select mt-2 flex h-32 w-full cursor-pointer flex-row items-center justify-center rounded-lg border-2 border-dashed hover:bg-muted/60"
            >
              <span className="text-muted-foreground/60">Kein Erfolgsindikator angelegt</span>
            </div>
          )}
          <div className="w-full rounded-lg">
            {climateAction.keyResult && <KeyResultProgressView keyResult={climateAction.keyResult} />}
          </div>
        </div>
      </div>

      <Dialog open={createKeyResultDialogisOpen} onOpenChange={setCreateKeyResultDialogisOpen}>
        <DialogContent
          className="create-key-result-dialog top-[1%] max-h-[80%] max-w-xl translate-y-[0%] overflow-y-scroll md:top-[10%]"
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          {!climateAction.keyResult && (
            <KeyResultCreateForm
              climateAction={climateAction}
              workspace={workspace}
              triggerCloseDialog={() => {
                setCreateKeyResultDialogisOpen(false);
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={updateKeyResultDialogisOpen} onOpenChange={setUpdateKeyResultDialogisOpen}>
        <DialogContent
          className="update-key-result-dialog top-[1%] max-h-[80%] max-w-xl translate-y-[0%] overflow-y-scroll md:top-[10%]"
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          {climateAction.keyResult && ( // Todo: add KeyResultEdit and KeyResultUpdate Forms
            <KeyResultEditForm
              climateAction={climateAction}
              keyResult={climateAction.keyResult}
              workspace={workspace}
              triggerCloseDialog={() => {
                setUpdateKeyResultDialogisOpen(false);
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={updateKeyResultDialogisOpen} onOpenChange={setUpdateKeyResultDialogisOpen}>
        <DialogContent
          className="create-snapshot-dialog top-[1%] max-h-[80%] max-w-xl translate-y-[0%] overflow-y-scroll md:top-[10%]"
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          {climateAction.keyResult && ( // Todo: add KeyResultEdit and KeyResultUpdate Forms
            <KeyResultEditForm
              climateAction={climateAction}
              keyResult={climateAction.keyResult}
              workspace={workspace}
              triggerCloseDialog={() => {
                setUpdateKeyResultDialogisOpen(false);
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={() => {
          handleDeleteKeyResult();
        }}
        title="Sind Sie sich sicher, dass Sie den Erfolgsindikator löschen möchten?"
        description="Diese Aktion kann nicht rückgängig gemacht werden. Der Erfolgsindikator und alle damit verbundenen Fortschrittsmessnungen werden unwiderruflich gelöscht."
      />
    </>
  );
};

export default ClimateActionKeyResultView;
