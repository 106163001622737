import { useCallback, useMemo } from "react";

import { useGetClimateActionListForWorkspace } from "./useGetClimateActionListForWorkspace";

export interface UseGetClimateActionListForTeamProps {
  teamList: { id: string }[];
  workspaceId: string;
}

export const useGetClimateActionListForTeams = ({ teamList, workspaceId }: UseGetClimateActionListForTeamProps) => {
  const {
    climateActionList: climateActionListForWorkspace,
    topLevelClimateActionList: topLevelClimateActionListForWorkspace,
    loading: loadingForWorkspace,
    error: errorForWorkspace,
  } = useGetClimateActionListForWorkspace({
    workspaceId,
  });

  const climateActionList = useMemo(() => {
    return climateActionListForWorkspace
      .slice()
      .filter((c) => {
        return c.teamInstanceList.find((ti) => teamList.find((t) => t.id === ti.teamId));
      })
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [climateActionListForWorkspace, teamList]);

  const topLevelClimateActionList = useMemo(() => {
    return topLevelClimateActionListForWorkspace
      .slice()
      .filter((c) => {
        return c.teamInstanceList.find((ti) => teamList.find((t) => t.id === ti.teamId));
      })
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [topLevelClimateActionListForWorkspace, teamList]);

  const getClimateActionsListForParent = useCallback(
    (parentId: string) => {
      return climateActionList
        .slice()
        .filter((o) => o.parentId === parentId)
        .sort((a, b) => {
          if (!a || !b) {
            return 0;
          }
          return a.title.localeCompare(b.title);
        });
    },
    [climateActionList],
  );

  const getClimateActionsListForUser = useCallback(
    (userId: string) => {
      return climateActionList
        .slice()
        .filter((o) => o.assignee && o.assignee.userId === userId)
        .sort((a, b) => {
          if (!a || !b) {
            return 0;
          }
          return a.title.localeCompare(b.title);
        });
    },
    [climateActionList],
  );

  return {
    climateActionList,
    topLevelClimateActionList,
    getClimateActionsListForParent,
    getClimateActionsListForUser,
    loading: loadingForWorkspace,
    error: errorForWorkspace,
  };
};
