import { Outlet } from "react-router-dom";

import { CurrentTeamListContextProvider } from "@/components/_domain/team/hooks/useCurrentTeamListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

export const TeamListLayout = () => {
  const { currentUser } = useCurrentUserContext();
  const { currentWorkspace } = useCurrentWorkspaceContext();

  return (
    <CurrentTeamListContextProvider workspaceId={currentWorkspace.id} userId={currentUser.id}>
      <Outlet />
    </CurrentTeamListContextProvider>
  );
};
