import { getFocusAreaListForWorkspaceQueryDocument, getFocusAreaQueryDocument } from "@/graphql/common/focus-area";
import { getPhaseListForWorkspaceQueryDocument, getPhaseQueryDocument } from "@/graphql/common/phase";
import { getPriorityListForWorkspaceQueryDocument, getPriorityQueryDocument } from "@/graphql/common/priority";
import { workspaceByIdQueryDocument } from "@/graphql/common/workspace";
import {
  GetFocusAreaInput,
  GetFocusAreaListForWorkspaceInput,
  GetFocusAreaListForWorkspaceQuery,
  GetFocusAreaQuery,
  GetPhaseInput,
  GetPhaseListForWorkspaceInput,
  GetPhaseListForWorkspaceQuery,
  GetPhaseQuery,
  GetPriorityInput,
  GetPriorityListForWorkspaceInput,
  GetPriorityListForWorkspaceQuery,
  GetPriorityQuery,
  Scalars,
  WorkspaceByIdQuery,
} from "@/graphql/generated/graphql";
import { ApolloCache } from "@apollo/client";

import {
  type EntityInstanceTypename,
  getEntityInstanceQueryConfig,
  getEntityQueryConfig,
  isTEntityInstance,
  type MaybeTEntityInstance,
  TEntity,
  type TEntityInstance,
  TEntityQueryResult,
  TEntityWithInstances,
} from "./_shared-generic-types";

/**
 * Returns a correctly typed query configuration object for cache operations.
 * This configuration is used to make the actual cache query/write for different entity types.
 *
 * The two queries returned here are used to find a list of entity to post-hoc identify
 * from which list the newly assigned instance was removed.
 * (We don't have access to the old state, but can still query the out-of-sync entity.instaneList objects)
 *
 * @param entity - The entity being operated on (e.g., Priority)
 * @param document - The GraphQL query document to use (e.g., workspaceByIdQueryDocument or getPriorityListForWorkspaceQueryDocument)
 * @param variables - The variables to be passed to the specific query (e.g., { input: { workspaceId: entity.workspaceId } })
 * @param key - The key on the queryResult to access the list of entities (e.g., "priorityListForWorkspace")
 * @param key2 - An optional second key if needed for nested access (e.g., "priorityList" for data.workspaceById.priorityList)
 * @returns A configuration object for cache operations
 */
function getEntityListQueryConfigForType<T extends TEntityInstance>(
  __typename: EntityInstanceTypename,
  entityInstance: T,
) {
  switch (__typename) {
    case "PriorityInstance":
      return [
        getEntityQueryConfig<
          T,
          GetPriorityListForWorkspaceQuery, // TResult
          { input: GetPriorityListForWorkspaceInput }, // TVariables
          "priorityListForWorkspace", // K
          undefined // I (e.g, priority list is returned under "data.priorityListForWorkspace")
        >(
          "Priority", // for __typename = "PriorityInstance" we want to find a list of Priority objects
          entityInstance,
          getPriorityListForWorkspaceQueryDocument,
          { input: { workspaceId: entityInstance.workspaceId } },
          "priorityListForWorkspace",
          undefined,
        ),
        getEntityQueryConfig<
          T,
          WorkspaceByIdQuery, // TResult
          { input: Scalars["String"]["input"] }, // TVariables
          "workspaceById", // K
          "priorityList" // I (e.g, priority list is returned under "data.workspaceById.priorityList")
        >(
          "Priority", // for __typename = "PriorityInstance" we want to find a list of Priority objects
          entityInstance,
          workspaceByIdQueryDocument,
          { input: entityInstance.workspaceId },
          "workspaceById", // the key on the query to access the result
          "priorityList", // the key on the object to be writte
        ),
      ];
    case "PhaseInstance":
      return [
        getEntityQueryConfig<
          T,
          GetPhaseListForWorkspaceQuery, // TResult
          { input: GetPhaseListForWorkspaceInput }, // TVariables
          "phaseListForWorkspace", // K
          undefined // I (e.g, priority list is returned under "data.priorityListForWorkspace")
        >(
          "Phase", // for __typename = "PhaseInstance" we want to find a list of Phase objects
          entityInstance,
          getPhaseListForWorkspaceQueryDocument,
          { input: { workspaceId: entityInstance.workspaceId } },
          "phaseListForWorkspace",
          undefined,
        ),
        getEntityQueryConfig<
          T,
          WorkspaceByIdQuery, // TResult
          { input: Scalars["String"]["input"] }, // TVariables
          "workspaceById", // K
          "phaseList" // I (e.g, priority list is returned under "data.workspaceById.priorityList")
        >(
          "Phase", // for __typename = "PhaseInstance" we want to find a list of Phase objects
          entityInstance,
          workspaceByIdQueryDocument,
          { input: entityInstance.workspaceId },
          "workspaceById", // the key on the query to access the result
          "phaseList", // the key on the object to be writte
        ),
      ];
    case "FocusAreaInstance":
      return [
        getEntityQueryConfig<
          T,
          GetFocusAreaListForWorkspaceQuery, // TResult
          { input: GetFocusAreaListForWorkspaceInput }, // TVariables
          "focusAreaListForWorkspace", // K
          undefined // I (e.g, focus area list is returned under "data.focusAreaListForWorkspace")
        >(
          "FocusArea", // for __typename = "FocusAreaInstance" we want to find a list of FocusArea objects
          entityInstance,
          getFocusAreaListForWorkspaceQueryDocument,
          { input: { workspaceId: entityInstance.workspaceId } },
          "focusAreaListForWorkspace",
          undefined,
        ),
        getEntityQueryConfig<
          T,
          WorkspaceByIdQuery, // TResult
          { input: Scalars["String"]["input"] }, // TVariables
          "workspaceById", // K
          "focusAreaList" // I (e.g, focus area list is returned under "data.workspaceById.focusAreaList")
        >(
          "FocusArea", // for __typename = "FocusAreaInstance" we want to find a list of FocusArea objects
          entityInstance,
          workspaceByIdQueryDocument,
          { input: entityInstance.workspaceId },
          "workspaceById", // the key on the query to access the result
          "focusAreaList", // the key on the object to be written
        ),
      ];
  }
}

/**
 * Takes a TEntity as inpurt (effectively either oldEntity or newEntity here) and returns a configuration object
 * that can be used to update the entities instances list.
 *
 * @param __typename - The type of entity instance (e.g., "PriorityInstance")
 * @param entity - The entity the instance should be added to or removed from
 * @returns A configuration object containing the parent query config for cache operations
 */
function getInstanceQueryConfigForType<T extends TEntity>(__typename: EntityInstanceTypename, entityInstance: T) {
  switch (__typename) {
    case "PriorityInstance":
      return getEntityInstanceQueryConfig<
        T,
        GetPriorityQuery, // TResult
        { input: GetPriorityInput }, // TVariables
        "priority", // K -> data for this query is under "data.priority"
        "instances" // I -> instances are returned under "data.priority.instances"
      >(
        __typename,
        entityInstance,
        getPriorityQueryDocument,
        { input: { id: entityInstance.id } },
        "priority",
        "instances",
      );
    case "PhaseInstance":
      return getEntityInstanceQueryConfig<
        T,
        GetPhaseQuery, // TResult
        { input: GetPhaseInput }, // TVariables
        "phase", // K -> data for this query is under "data.phase"
        "instances" // I -> instances are returned under "data.phase.instances"
      >(__typename, entityInstance, getPhaseQueryDocument, { input: { id: entityInstance.id } }, "phase", "instances");
    case "FocusAreaInstance":
      return getEntityInstanceQueryConfig<
        T,
        GetFocusAreaQuery, // TResult
        { input: GetFocusAreaInput }, // TVariables
        "focusArea", // K -> data for this query is under "data.focusArea"
        "instances" // I -> instances are returned under "data.focusArea.instances"
      >(
        __typename,
        entityInstance,
        getFocusAreaQueryDocument,
        { input: { id: entityInstance.id } },
        "focusArea",
        "instances",
      );
  }
}

/**
 * Attempts to get the priority list from cache, trying two sources:
 * 1. First tries the direct priority list query cache
 * 2. If that fails, tries to get it from the workspace cache
 *
 * @param cache The Apollo cache instance
 * @param entityInstance The entity instance to query for
 * @param typename The typename of the entity instance
 * @returns The entity list or null if neither cache has the data
 */
function getEntityListFromCache<T extends TEntityInstance, E extends TEntityWithInstances>(
  cache: ApolloCache<Record<string, unknown>>,
  entityInstance: T,
  typename: EntityInstanceTypename,
): E[] | null {
  const config = getEntityListQueryConfigForType(typename, entityInstance);

  if (!config) {
    return null;
  }

  // now loop over all keys in the config object (might b 1-n depending on the entity instance type)
  for (const queryConfig of config) {
    if (!queryConfig) {
      continue;
    }

    const { key, key2, document, variables } = queryConfig;

    // Note: We need to check for key here first, otherwise the typscript compiler will throw an error
    //       because key2 might be undefined. Not the most readbable way to do this, but it works for now.
    if (key2) {
      const entityListResult = cache.readQuery<TEntityQueryResult<E, typeof key, typeof key2>>({
        query: document,
        variables: variables,
      });

      if (entityListResult?.[key]) {
        const workspaceData = entityListResult[key] as { [key: string]: E[] };
        if (key2 && workspaceData[key2]) {
          return workspaceData[key2];
        }
      }
    } else {
      const entityListResult = cache.readQuery<TEntityQueryResult<E, typeof key, undefined>>({
        query: document,
        variables: variables,
      });

      if (entityListResult?.[key]) {
        return entityListResult[key];
      }
    }
    // continue with next query config
  }

  // if we get here, we didn't find the entity list in the cache
  return null;
}

export type OnGenericUpdateParams<T extends EntityInstanceTypename, E extends TEntityInstance> = {
  cache: ApolloCache<Record<string, unknown>>;
  updatedEntityInstance: E | MaybeTEntityInstance;
  typename: T;
  warn?: boolean;
};

/**
 * Updates the cache when a priority is updated.
 *
 * This function updates two potential cache locations:
 * 1. The direct instance list on the query for the entity (e.g., for a priority instance, this is the priority.instances array)
 * 2. Clears the priority instance from the owner's reference
 *
 *
 * @param cache The Apollo cache instance
 * @param updatedPriorityInstance The priority instance that was updated
 */
export function onGenericEntityInstanceUpdate<T extends EntityInstanceTypename, E extends TEntityInstance>({
  cache,
  updatedEntityInstance,
  typename,
  warn = true,
}: OnGenericUpdateParams<T, E>) {
  if (!isTEntityInstance(updatedEntityInstance)) {
    if (warn) {
      console.warn(
        `(Apollo) [Cache update on delete for '${typename}'}: object does not have the required properties`,
        updatedEntityInstance,
      );
    }
    return;
  }

  // 1. Try to obtain a list of entities from the cache to understand which enitity the
  //    instance belonged to (old state).
  // -------------------------------------------------------------------
  const entityList = getEntityListFromCache(cache, updatedEntityInstance, typename);

  if (!entityList) {
    return;
  }

  // 2. Now let's try to identify which entities the instance was removed from
  // -------------------------------------------------------------------
  const oldEntity = entityList.find((entity) =>
    entity.instances.find((instance) => instance.id === updatedEntityInstance.id),
  );

  if (oldEntity) {
    const config = getInstanceQueryConfigForType(typename, oldEntity);

    if (config) {
      const writeObject = {
        query: config.document,
        variables: config.variables,
        data: {
          // For Example (below): data = { priorityListForWorkspace: { priorityList: Priority[], ...rest } }
          [config.key]: {
            ...oldEntity,
            // For Example (below): priorityListForWorkspace: Priority[]
            [config.key2]: oldEntity.instances.slice().filter((e) => e.id !== updatedEntityInstance.id),
          },
        },
      };
      // Since the TS compiler only knows the shape of the TEntity type we need to cast to any here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cache.writeQuery(writeObject as any);
    }
  }

  // 3. Now let's try to identify which entities the instance was added to
  // -------------------------------------------------------------------
  const newEntity = entityList.find((entity) => entity.id === updatedEntityInstance.__parentId);

  if (newEntity) {
    const config = getInstanceQueryConfigForType(typename, newEntity);

    if (config) {
      const writeObject = {
        query: config.document,
        variables: config.variables,
        data: {
          // For Example (below): data = { priorityListForWorkspace: { priorityList: Priority[], ...rest } }
          [config.key]: {
            ...newEntity,
            // For Example (below): priorityListForWorkspace: Priority[]
            [config.key2]: [...newEntity.instances, updatedEntityInstance],
          },
        },
      };
      // Since the TS compiler only knows the shape of the TEntity type we need to cast to any here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cache.writeQuery(writeObject as any);
    }
  }
}
