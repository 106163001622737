import { useMemo } from "react";

import { workspaceByUrlQueryDocument } from "@/graphql/common/workspace";
import { useQuery } from "@apollo/client";

export interface UseGetWorkspaceByUrlProps {
  workspaceUrl?: string;
  enabled?: boolean;
}

export const useWorkspaceGetByUrl = ({ workspaceUrl, enabled = true }: UseGetWorkspaceByUrlProps) => {
  const queryResult = useQuery(workspaceByUrlQueryDocument, {
    variables: { input: workspaceUrl ?? "" },
    skip: !workspaceUrl || !enabled,
  });

  const workspace = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.workspaceByUrl;
  }, [queryResult.data]);

  return {
    workspace,
    loading: queryResult.loading,
    error: queryResult.error,
    refetch: queryResult.refetch,
    queryResult,
  };
};
