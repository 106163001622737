import { IndicatorLayout } from "./:indicatorId/indicator.layout";
import { IndicatorPage } from "./:indicatorId/indicator.page";
import { IndicatorListLayout } from "./indicator-list.layout";
import { IndicatorListPage } from "./indicator-list.page";

// Note: nestes routes defined such that Layout always renders for all children
export const indicatorRoutes = [
  {
    path: "indicators",
    element: <IndicatorListLayout />,
    children: [
      { path: "", element: <IndicatorListPage /> },
      {
        path: ":indicatorId",
        element: <IndicatorLayout />,
        children: [{ path: "", element: <IndicatorPage /> }],
      },
    ],
  },
];
