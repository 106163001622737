import { FC, useCallback, useRef, useState } from "react";
import { isMacOs } from "react-device-detect";
import { useHotkeys } from "react-hotkeys-hook";

import IconXCircleFilled from "@/components/_icons/IconXCircleFilled";
import { Input } from "@/components/_ui/input";

import { useClimateActionListState } from "../../hooks";

export interface ClimateActionListQuickSearchProps {}

export const ClimateActionListQuickSearch: FC<ClimateActionListQuickSearchProps> = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const { globalFilterState, fn } = useClimateActionListState();
  const [inputValue, setInputValue] = useState(globalFilterState);

  const placeholderText = `Suchen (${isMacOs ? "⌘" : "Ctrl"} + F)`;

  const focusInput = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  useHotkeys("mod+f", focusInput, { preventDefault: true });

  const debouncedSetGlobalFilter = useCallback(
    (value: string) => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }

      debounceTimerRef.current = setTimeout(() => {
        fn.setGlobalFilter(value);
        debounceTimerRef.current = null;
      }, 150);
    },
    [fn],
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setInputValue(newValue);
    debouncedSetGlobalFilter(newValue);
  };

  const clearInput = () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    setInputValue("");
    fn.setGlobalFilter("");
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="group relative">
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={handleOnChange}
          placeholder={placeholderText}
          className="h-6 w-[200px] rounded-md border"
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              e.preventDefault();
              clearInput();
            }
          }}
        />
        {inputValue !== "" && (
          <div
            className="invisible absolute right-2 top-0 translate-y-1/2 rounded-full hover:cursor-pointer group-hover:visible"
            onClick={clearInput}
          >
            <IconXCircleFilled className="h-3 w-3 text-foreground/60" />
          </div>
        )}
      </div>
    </div>
  );
};
