import { FC, useMemo, useState } from "react";

import { PhaseView } from "@/components/_domain/phase";
import IconCircleDashed from "@/components/_icons/IconCircleDashed";
import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import { useGetPhaseListForWorkspace } from "@/hooks/api/common/usePhase";
import { cn } from "@/lib/utils";
import { Cross2Icon } from "@radix-ui/react-icons";

import { useClimateActionListState } from "../../../../hooks";
import {
  FilterByTextListMode,
  FilterByTextListPayload,
  FilterByTextListPayloadSchema,
} from "../../../filter-functions";

import { FilterByPhaseConfigurationView } from "./filter-by-phase-configuration-view";

export interface FilterByPhaseFilterPaneViewProps {
  column: {
    id: string;
  };
}

export const FilterByPhaseFilterPaneView: FC<FilterByPhaseFilterPaneViewProps> = ({ ...props }) => {
  const { table, columnFilters, config } = useClimateActionListState();
  const [showSelectItemPopover, setShowSelectItemPopover] = useState(false);

  const { phaseListForWorkspace } = useGetPhaseListForWorkspace({
    workspaceId: config.workspace.id,
  });

  const column = useMemo(() => {
    return table.getColumn(props.column.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const filter = useMemo(() => {
    if (!column) {
      return null;
    }
    if (!column.getCanFilter()) {
      return null;
    }
    const filterValue = column.getFilterValue();
    if (!filterValue) {
      return null;
    }
    return filterValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  const filterByTextListFilter = useMemo(() => {
    if (!filter) {
      return null;
    }

    const safePayload = FilterByTextListPayloadSchema.safeParse(filter);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [filter]);

  const filterModes: FilterByTextListMode[] = useMemo(() => {
    if (!filterByTextListFilter) {
      return [];
    }
    return ["included", "not_included"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const phaseListForActiveFilter = useMemo(() => {
    if (!filterByTextListFilter) {
      return [];
    }

    return filterByTextListFilter.textList.flatMap((name) => {
      const phase = phaseListForWorkspace.find((phase) => phase.name === name);

      if (!phase) {
        return [];
      }
      return phase;
    });
  }, [filterByTextListFilter, phaseListForWorkspace]);

  const setFilter = (payload: FilterByTextListPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const setFilterMode = (filterMode: FilterByTextListMode) => {
    if (!filterByTextListFilter) {
      return;
    }
    setFilter({
      ...filterByTextListFilter,
      mode: filterMode,
    });
  };

  if (!column) {
    return <></>;
  }

  if (!filter) {
    return <></>;
  }

  if (filterByTextListFilter) {
    return (
      <div className="flex flex-row flex-nowrap items-center gap-0 whitespace-nowrap rounded-md border p-0 text-xs font-light">
        <div className="flex flex-row items-center gap-1 pl-1 text-xs" data-testid="phase-filter-pane-view">
          <IconCircleDashed className="h-3.5 w-3.5 text-foreground/60" />
          <span className="text-xs">Phase</span>
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
            >
              {filterByTextListFilter.mode === "included" && filterByTextListFilter.textList.length === 1 && <>ist</>}
              {filterByTextListFilter.mode === "included" && filterByTextListFilter.textList.length !== 1 && (
                <>ist Teil von</>
              )}
              {filterByTextListFilter.mode === "not_included" && filterByTextListFilter.textList.length === 1 && (
                <>ist nicht</>
              )}
              {filterByTextListFilter.mode === "not_included" && filterByTextListFilter.textList.length !== 1 && (
                <>ist nicht Teil von</>
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filterModes.map((mode) => {
              return (
                <DropdownMenuItem
                  key={mode}
                  onClick={() => {
                    setFilterMode(mode);
                  }}
                >
                  {mode === "included" && filterByTextListFilter.textList.length === 1 && <>ist</>}
                  {mode === "included" && filterByTextListFilter.textList.length !== 1 && <>ist Teil von</>}
                  {mode === "not_included" && filterByTextListFilter.textList.length === 1 && <>ist nicht</>}
                  {mode === "not_included" && filterByTextListFilter.textList.length !== 1 && <>ist nicht Teil von</>}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>

        <Popover open={showSelectItemPopover} onOpenChange={setShowSelectItemPopover}>
          <PopoverTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0  focus-visible:ring-offset-0"
            >
              {filterByTextListFilter.textList.length > 1 && (
                <>
                  <div className="mr-1 flex flex-row items-center">
                    {phaseListForActiveFilter.map((phase) => {
                      return (
                        <PhaseView
                          key={phase.id}
                          phase={phase}
                          logoOnly={true}
                          className={cn([
                            " -m-0.5 h-2.5 w-2.5 bg-background",
                            phase.id ? "border border-background" : "rounded-full border ",
                          ])}
                        />
                      );
                    })}
                  </div>
                  {`${filterByTextListFilter.textList.length} Werten`}
                </>
              )}
              {filterByTextListFilter.textList.length === 1 && (
                <>
                  {phaseListForActiveFilter.length === 0 && (
                    <>
                      <PhaseView phase={null} className="h-3 w-3" data-testid="phase-filter-pane-view-empty" />
                    </>
                  )}
                  {phaseListForActiveFilter.length > 0 && (
                    <>
                      {phaseListForActiveFilter.map((phase) => {
                        return <PhaseView key={phase.id} phase={phase} className="h-3 w-3" />;
                      })}
                    </>
                  )}
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0" side={"bottom"} align={"start"}>
            <FilterByPhaseConfigurationView
              column={column}
              onFinished={() => {
                setShowSelectItemPopover(false);
              }}
            />
          </PopoverContent>
        </Popover>
        <Button
          variant="ghost"
          size="xs"
          className="h-6 w-full justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          onClick={() => {
            clearFilter();
          }}
        >
          <Cross2Icon className="h-3.5 w-3.5 text-foreground/60" />
        </Button>
      </div>
    );
  }

  return <></>;
};
