import { useMemo } from "react";

import { ProgressSnapshot } from "@/components/_domain/progress-snapshot/progress-snapshots.schema";

import { useGetClimateAction } from "../useClimateAction/useGetClimateAction";

export interface UseGetProgressSnapshotListProps {
  climateActionId?: string;
}

export const useGetProgressSnapshotList = ({ climateActionId }: UseGetProgressSnapshotListProps) => {
  const { climateAction, queryResult } = useGetClimateAction({ id: climateActionId }); // caching optimization

  const progressSnapshotList: ProgressSnapshot[] = useMemo(() => {
    if (!climateAction) {
      return [];
    }
    return climateAction.progressSnapshotList.slice().sort((a, b) => {
      const dateA = new Date(a.snapshotDate);
      const dateB = new Date(b.snapshotDate);

      // Todo: sort them by createdA, if they where created on the same day
      return dateB.getTime() - dateA.getTime();
    });
  }, [climateAction]);

  const mostRecentSnapshot: ProgressSnapshot | null = useMemo(() => {
    if (progressSnapshotList.length === 0) {
      return null;
    }
    return progressSnapshotList[0];
  }, [progressSnapshotList]);

  return {
    queryResult,
    progressSnapshotList,
    mostRecentSnapshot,
  };
};
