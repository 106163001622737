import { deleteLabelInstanceMutationDocument } from "@/graphql/common/label-instance";
import { type DeleteLabelInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_LABEL_INSTANCE_DELETED, trackLabelEvent } from "./tracking";

export type DeletedLabelInstance = DeleteLabelInstanceMutation["deleteLabelInstance"];

export interface UseDeleteLabelInstanceProps {
  onCompleted?: (data: DeletedLabelInstance) => void;
  onError?: (e: Error) => void;
}

export const useDeleteLabelInstance = ({ onCompleted, onError }: UseDeleteLabelInstanceProps = {}) => {
  const [deleteLabelInstance] = useMutation(deleteLabelInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedLabelInstance = data?.deleteLabelInstance;
      if (!deletedLabelInstance) {
        return;
      }

      onGenericEntityInstanceDelete({
        cache,
        deletedEntityInstance: {
          ...deletedLabelInstance,
          __parentId: deletedLabelInstance.labelId,
        },
        typename: "LabelInstance",
      });

      trackLabelEvent(EVENT_LABEL_INSTANCE_DELETED, deletedLabelInstance);
    },
  });

  function onUpdateCompleted(data: DeleteLabelInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.deleteLabelInstance);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteLabelInstance,
  };
};
