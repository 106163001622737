import { Navigate } from "react-router-dom";

import { useCurrentFocusAreaListContext } from "@/components/_domain/focusArea/hooks/useCurrentFocusAreaListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";

export function FocusAreaListPage() {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentFocusAreaList } = useCurrentFocusAreaListContext();

  // If we land here, either redirect to the first focus-area or, if no focus-area exist, redirect to the workspace root
  if (currentFocusAreaList.length === 0) {
    return <Navigate to={`/${currentWorkspace.workspaceUrl}`} replace={true} />;
  }

  return <Navigate to={`/${currentWorkspace.workspaceUrl}/focus-areas/${currentFocusAreaList[0].id}`} replace={true} />;
}
