import { FC, useEffect, useMemo, useState } from "react";

import { TeamIcon } from "@/components/_domain/team";
import { Button } from "@/components/_ui/button";
import { Checkbox } from "@/components/_ui/checkbox";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import { toast } from "@/components/_ui/use-toast";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common/useWorkspace";
import { useUpdateTeamInstanceListByOwner } from "@/hooks/api/common/useWorkspace/useTeamInstance/useUpdateTeamInstanceListByOwner";
import { cn } from "@/lib/utils";

interface ClimateActionTeamInstanceViewProps {
  ownerId: string;
  workspaceId: string;
}

export const ClimateActionTeamInstanceView: FC<ClimateActionTeamInstanceViewProps> = ({ ownerId, workspaceId }) => {
  const { climateAction } = useGetClimateAction({ id: ownerId });
  const { currentUser } = useCurrentUserContext();
  const { teamList } = useTeamListForUserInWorkspace({
    workspaceId,
    userId: currentUser?.id,
  });
  const { hasPermission: hasPermissionToManageClimateAction } = useCheckPermission({
    resource: "climateAction",
    resourceId: ownerId,
    action: "manage",
  });

  const [initialized, setInitialized] = useState<boolean>(false);
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const availableTeams = useMemo(() => {
    return teamList.filter((team) =>
      team.teamMembershipList.some((membership) => membership.userId === currentUser?.id),
    );
  }, [teamList, currentUser?.id]);

  const { updateTeamInstanceListByOwner } = useUpdateTeamInstanceListByOwner({
    teamIdList: selectedTeamIds,
    workspaceId,
    ownerId,
    onError: () => {
      toast({
        title: "Fehler",
        description: "Teams konnten nicht aktualisiert werden",
        variant: "error",
      });
    },
  });

  // Initialize selected teams from climate action
  useEffect(() => {
    if (climateAction?.teamInstanceList && !initialized) {
      const teamIds = climateAction.teamInstanceList.map((ti) => ti.teamId);
      setSelectedTeamIds(teamIds);
      setInitialized(true);
    }
  }, [climateAction, initialized]);

  const handleSelect = async (teamId: string) => {
    if (!hasPermissionToManageClimateAction) {
      return;
    }

    const isInOldTeamList = selectedTeamIds.includes(teamId);

    let updatedTeamIdList: string[] = [];

    if (isInOldTeamList) {
      updatedTeamIdList = selectedTeamIds.filter((id) => id !== teamId);
    } else {
      updatedTeamIdList = [...selectedTeamIds, teamId];
    }

    updateTeamInstanceListByOwner({
      variables: {
        input: {
          teamIdList: updatedTeamIdList,
          ownerId,
          ownerType: "CLIMATE_ACTION",
          workspaceId,
        },
      },
    });

    setSelectedTeamIds(updatedTeamIdList);
  };

  const clearTeams = () => {
    if (!hasPermissionToManageClimateAction) return;
    setSelectedTeamIds([]);
  };

  const getSelectedTeamNames = () => {
    if (selectedTeamIds.length === 0) {
      return "Keine Teams zugewiesen";
    }

    if (selectedTeamIds.length === 1) {
      const team = availableTeams.find((t) => t.id === selectedTeamIds[0]);
      return team ? team.name : "1 Team";
    }

    return `${selectedTeamIds.length} Teams`;
  };

  if (!climateAction) {
    return <></>;
  }

  return (
    <div className="mb-5 flex w-full flex-row items-start gap-2 overflow-x-hidden">
      <div className="mt-2 w-24 shrink-0 font-medium">Teams</div>
      <div className="grow">
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild disabled={!hasPermissionToManageClimateAction}>
            <Button
              variant="ghost"
              size="sm"
              className="w-full justify-start overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
            >
              <div className="flex items-center">
                {selectedTeamIds.length > 0 && (
                  <div className="mr-2 flex flex-row items-center">
                    {selectedTeamIds.slice(0, 3).map((teamId) => {
                      const team = availableTeams.find((t) => t.id === teamId);
                      return team ? (
                        <span key={teamId} className="mr-1">
                          <TeamIcon team={team} className="h-4 w-4" />
                        </span>
                      ) : null;
                    })}
                    {selectedTeamIds.length > 3 && <span>+{selectedTeamIds.length - 3}</span>}
                  </div>
                )}
                {getSelectedTeamNames()}
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[250px] p-0" side="bottom" align="start">
            <Command data-testid="filter-by-team-configuration-view">
              <CommandInput placeholder="Team" />
              <CommandList>
                <CommandGroup>
                  {/* Unassigned option */}
                  <CommandItem
                    key="null"
                    value="Nicht zugewiesen"
                    className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                    onSelect={() => {
                      if (hasPermissionToManageClimateAction) {
                        clearTeams();
                        setIsOpen(false);
                      }
                    }}
                  >
                    <Checkbox
                      className={cn([
                        "rounded-md border border-gray-300 group-hover:opacity-100",
                        selectedTeamIds.length === 0 ? "" : "opacity-0",
                      ])}
                      checked={selectedTeamIds.length === 0}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (hasPermissionToManageClimateAction) {
                          clearTeams();
                        }
                      }}
                    />
                    <div className="flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-xs">
                      <span className="rounded p-1">
                        <TeamIcon team={{ id: "null" }} />
                      </span>
                      <span className="pointer-events-none relative flex cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
                        Nicht zugewiesen
                      </span>
                    </div>
                  </CommandItem>

                  {availableTeams.map((team) => {
                    const isSelected = selectedTeamIds.includes(team.id);

                    return (
                      <CommandItem
                        key={team.id}
                        value={team.name}
                        className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                        onSelect={() => {
                          if (hasPermissionToManageClimateAction) {
                            handleSelect(team.id);
                          }
                        }}
                      >
                        <Checkbox
                          className={cn([
                            "rounded-md border border-gray-300 group-hover:opacity-100",
                            isSelected ? "" : " opacity-0",
                          ])}
                          checked={isSelected}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (hasPermissionToManageClimateAction) {
                              handleSelect(team.id);
                            }
                          }}
                        />
                        <div className="flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-xs">
                          <span className="rounded p-1">
                            <TeamIcon team={team} />
                          </span>
                          <span className="pointer-events-none relative flex cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
                            {team.name}
                          </span>
                        </div>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default ClimateActionTeamInstanceView;
