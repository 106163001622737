import { FC, useState } from "react";

import { PhaseIcon } from "@/components/_domain/phase";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import IconPen from "@/components/_icons/IconPen";
import { Button } from "@/components/_ui/button";
import { useCheckPermission } from "@/hooks/api/common/usePermission";
import { useGetPhase } from "@/hooks/api/common/usePhase";

import { PhaseButtonDelete } from "./actions/phase-button-delete";
import { PhaseFormEdit } from "./actions/phase-form-edit";
import { InstanceCountLabel } from "./instance-count-label";

export interface PhaseTableRowProps {
  phase: {
    id: string;
  };
  hideDeleteAction?: boolean;
}

export const PhaseTableRow: FC<PhaseTableRowProps> = ({ hideDeleteAction, ...props }) => {
  const [showEditMode, setShowEditMode] = useState(false);
  const { phase } = useGetPhase({ id: props.phase.id });

  const { currentWorkspace } = useCurrentWorkspaceContext();

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: currentWorkspace.id,
    action: "manage",
  });

  function handleEditPhaseButtonPressed() {
    setShowEditMode(true);
  }

  function onSuccess() {
    setShowEditMode(false);
  }

  function onCanceled() {
    setShowEditMode(false);
  }

  if (!phase) {
    return <></>;
  }

  if (showEditMode) {
    return (
      <div className="my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2">
        <PhaseFormEdit phase={phase} onSuccess={onSuccess} onCanceled={onCanceled} />
      </div>
    );
  }

  return (
    <div key={phase.id} className="mb-1 rounded border">
      <div className="m-2 flex flex-row items-center gap-2">
        <div>
          <PhaseIcon phase={{ type: phase.type, color: phase.color }} />
        </div>
        <div>{phase.name}</div>
        <InstanceCountLabel instances={phase.instances} className="text-muted-foreground" />
        <div className="grow"></div>
        {hasPermissionToManageWorkspace && (
          <>
            <div>
              <Button
                variant="ghost"
                size="icon"
                className="text-muted-foreground hover:text-foreground/80"
                onClick={handleEditPhaseButtonPressed}
              >
                <IconPen className="h-3 w-3" />
              </Button>
            </div>
            {!hideDeleteAction && <PhaseButtonDelete workspaceId={currentWorkspace.id} phase={phase} />}
          </>
        )}
      </div>
    </div>
  );
};

export default PhaseTableRow;
