import { FC, useMemo } from "react";

import { CheckboxMultiple, Item } from "@/components/checkbox-multiple";

import { Milestone } from "./milestone.schema";

export interface MilestoneCheckboxProps {
  onValueChange?: (value: Milestone[]) => void;
  defaultValue?: Milestone[];
}

export const MilestoneCheckbox: FC<MilestoneCheckboxProps> = ({ onValueChange, defaultValue }) => {
  const defaultItems = useMemo(() => {
    if (!defaultValue) {
      return undefined;
    }

    const items = defaultValue.map(({ id, ...m }) => {
      return {
        _id: id,
        ...m,
      };
    });

    return items;
  }, [defaultValue]);

  const handleValueChange = (items: Item[]) => {
    if (onValueChange) {
      const milestones = items.map(({ _id, ...i }) => {
        return {
          id: _id,
          ...i,
        };
      });
      onValueChange(milestones);
    }
  };

  return <CheckboxMultiple onValueChange={handleValueChange} defaultValue={defaultItems} />;
};

export default MilestoneCheckbox;
