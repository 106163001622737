import { useEffect, useMemo, useState } from "react";
import { PiPottedPlantLight } from "react-icons/pi";
import { useLocation, useParams } from "react-router-dom";

import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useGetTeam } from "@/hooks/api/common/useWorkspace/useGetTeam";
import { useTeamMembershipList } from "@/hooks/api/common/useWorkspace/useTeamMembershipList";
import { useWorkspaceGetByUrl } from "@/hooks/api/common/useWorkspace/useWorkspaceGetByUrl";

import IconChevronDown from "./_icons/IconChevronDown";
import IconChevronUp from "./_icons/IconChevronUp";

// This is just a simple component to not have to always look at the DB
// It will show in dev mode by default.
// In not dev mode or if you close it you can open it again by calling console.log("lumos") in the console
// It's not super reliable in some places of the app and could be a provider instead to keep track of current team for example
export function DebugInfo() {
  // const [permission, setPermission] = useState<string>("Loading...");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { currentUser, isSuperUser } = useCurrentUserContext();

  // Extract workspace and team information from URL
  const { workspaceUrl } = useParams();
  const location = useLocation();

  // Extract workspaceId from URL path if it's not in params
  const workspaceIdFromPath = useMemo(() => {
    const pathParts = location.pathname.split("/");
    const WORKSPACE_INDEX = 1;
    return pathParts[WORKSPACE_INDEX];
  }, [location.pathname]);

  const effectiveWorkspaceUrl = workspaceUrl || workspaceIdFromPath;

  // Extract teamId from URL path if it's not in params
  const teamIdFromPath = useMemo(() => {
    const pathParts = location.pathname.split("/");
    const teamIndex = pathParts.findIndex((part) => part === "teams");
    return teamIndex >= 0 && teamIndex < pathParts.length - 1 ? pathParts[teamIndex + 1] : undefined;
  }, [location.pathname]);

  const teamId = useParams().teamId || teamIdFromPath;

  // Setup console command to show debug panel
  useEffect(() => {
    // Check if debug mode is stored in localStorage
    const storedDebugMode = localStorage.getItem("ark_debug_mode") === "true";
    setIsVisible(process.env.NODE_ENV === "development" || storedDebugMode);

    // Define the console command to show debug panel
    const originalConsoleLog = console.log;
    console.log = function (...args) {
      if (args.length === 1 && args[0] === "lumos") {
        setIsVisible(true);
        localStorage.setItem("ark_debug_mode", "true");
        console.log("✨ Debug panel activated! ✨");
        return;
      }
      originalConsoleLog.apply(console, args);
    };

    // Cleanup function to restore original console.log
    return () => {
      console.log = originalConsoleLog;
    };
  }, []);

  // Function to hide the debug panel
  const hideDebugPanel = () => {
    setIsVisible(false);
    localStorage.removeItem("ark_debug_mode");
  };

  // Get workspace data using the URL
  const { workspace } = useWorkspaceGetByUrl({ workspaceUrl: effectiveWorkspaceUrl ?? "" });
  const { team } = useGetTeam({ id: teamId ?? undefined });
  const { teamMembershipList } = useTeamMembershipList({
    teamId: teamId ?? "",
  });

  const isWorkspaceAdmin = useMemo(() => {
    if (!workspace) {
      return false;
    }

    const currentUserWorkspaceMembership = currentUser?.workspaceMembershipList?.find(
      (membership) => membership.workspaceId === workspace.id,
    );

    return currentUserWorkspaceMembership?.role === "ADMIN";
  }, [currentUser, workspace]);

  const isTeamAdmin = useMemo(() => {
    if (!team || !currentUser) {
      return false;
    }

    const currentUserTeamMembership = teamMembershipList?.find((membership) => membership.userId === currentUser.id);

    return currentUserTeamMembership?.role === "ADMIN";
  }, [currentUser, team, teamMembershipList]);

  // TODO: Add a way to get permissions
  // useEffect(() => {
  // Fetch the user's permission
  // This is a placeholder - replace with your actual permission fetching logic
  // const fetchPermission = async () => {
  //   try {
  //     // Replace this with your actual API call or state access
  //     // For example: const response = await fetch('/api/user/permission');
  //     // const data = await response.json();
  //     // setPermission(data.permission);

  //     // Placeholder - replace with real implementation
  //     setPermission("workspace:user"); // Example value
  //   } catch (error) {
  //     setPermission("Error fetching permission");
  //     console.error("Failed to fetch permission:", error);
  //   }
  // };

  // }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-3 right-3 z-[9999] flex flex-col rounded-md border border-border bg-background p-2 text-xs text-foreground">
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <strong>
          <PiPottedPlantLight />
        </strong>
        <div style={{ display: "flex", gap: "4px" }}>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
              padding: "2px 6px",
              fontSize: "10px",
            }}
          >
            {isExpanded ? <IconChevronUp className="h-2 w-2" /> : <IconChevronDown className="h-2 w-2" />}
          </button>
          <button
            onClick={hideDebugPanel}
            style={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
              padding: "2px 6px",
              fontSize: "10px",
              color: "#999",
            }}
            title="Close debug panel"
          >
            ✕
          </button>
        </div>
      </div>
      {isExpanded && (
        <div style={{ marginTop: "4px" }}>
          Super Admin: {isSuperUser ? "Yes" : "No"}
          <br />
          Workspace Admin: {isWorkspaceAdmin ? "Yes" : "No"}
          <br />
          Team Admin: {isTeamAdmin ? "Yes" : "No"}
          <br />
          Team ID: {teamId || "None"}
          <br />
          Workspace URL: {effectiveWorkspaceUrl || "None"}
        </div>
      )}
    </div>
  );
}
