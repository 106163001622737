import { FC } from "react";

import IconUpload from "@/components/_icons/IconUpload";
import { useToast } from "@/components/_ui/use-toast";
import { InputImageUpload, UploadUrl } from "@/components/input-image-upload";
import { createSignedUploadUrlMutationDocument } from "@/graphql/common/upload";
import {
  currentUserQueryDocument,
  updateUserProfileDocument,
  userInWorkspaceQueryDocument,
} from "@/graphql/common/user";
import { cn } from "@/lib/utils";
import { apolloClient } from "@/services/apollo.service/apollo.service";
import { useMutation } from "@apollo/client";

export interface ProfilePictureProps {
  id: string;
  workspaceId: string;
  profilePicture: string | null | undefined;
  className?: string;
}

export const ProfilePicture: FC<ProfilePictureProps> = ({ id, workspaceId, profilePicture, className }) => {
  const [updateProfile] = useMutation(updateUserProfileDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: currentUserQueryDocument,
      },
      {
        query: userInWorkspaceQueryDocument,
        variables: {
          input: {
            workspaceId: workspaceId,
            userId: id,
          },
        },
      },
    ],
  });

  const { toast } = useToast();

  async function onUpdateCompleted() {
    toast({
      title: "Gespeichert",
      description: "Dein Profil wurde erfolgreich aktualisert.",
      variant: "success",
    });
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  async function getSignedUploadUrlForImage(): Promise<UploadUrl | null> {
    const { data } = await apolloClient.mutate({
      mutation: createSignedUploadUrlMutationDocument,
      fetchPolicy: "no-cache",
      variables: {
        input: {
          fileExtension: "png",
          type: "image/png",
        },
      },
    });

    if (!data) {
      return null;
    }

    return data.createSignedUploadUrl;
  }

  function handleOnImageUploadSuccess(file: UploadUrl) {
    const { permaUrl } = file;

    updateProfile({
      variables: {
        input: {
          id: id,
          profilePicture: permaUrl,
        },
      },
    });
  }

  function handleOnImageError(_e: Error) {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <InputImageUpload
      getSignedUploadUrl={getSignedUploadUrlForImage}
      onSuccess={handleOnImageUploadSuccess}
      onError={handleOnImageError}
      className={cn([" overflow-hidden rounded-full", className])}
    >
      <div className="flex h-[118px] w-[118px] flex-row items-center justify-center  overflow-hidden rounded-full">
        {!profilePicture && (
          <div className="select-none">
            <IconUpload className="h-6 w-6" />
          </div>
        )}
        {profilePicture && <img src={profilePicture} alt="Workspace Logo" className="h-full w-full select-none" />}
      </div>
    </InputImageUpload>
  );
};

export default ProfilePicture;
