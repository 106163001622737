import { updatePriorityMutationDocument } from "@/graphql/common/priority";
import { useMutation } from "@apollo/client";

import { EVENT_PRIORITY_UPDATED, trackPriorityEvent } from "./tracking";

export interface UseUpdatePriorityProps {
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useUpdatePriority = ({ onCompleted, onError }: UseUpdatePriorityProps) => {
  const [updatePriority] = useMutation(updatePriorityMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const updatedPriority = data?.updatePriority;
      if (!updatedPriority) {
        return;
      }

      trackPriorityEvent(EVENT_PRIORITY_UPDATED, updatedPriority);
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }
  return {
    updatePriority,
  };
};
