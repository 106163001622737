import { createPhaseMutationDocument } from "@/graphql/common/phase";
import { type CreatePhaseMutation } from "@/graphql/generated/graphql";
import { onGenericEntityCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PHASE_CREATED, trackPhaseEvent } from "./tracking";

export type CreatedPhase = CreatePhaseMutation["createPhase"];

export interface UseCreatePhaseProps {
  onCompleted?: (data: CreatedPhase) => void;
  onError?: (e: Error) => void;
}

export const useCreatePhase = ({ onCompleted, onError }: UseCreatePhaseProps) => {
  const [createPhase] = useMutation(createPhaseMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const newPhase = data?.createPhase;
      if (!newPhase) {
        return;
      }

      onGenericEntityCreate({ cache, newEntity: newPhase, typename: "Phase" });
      trackPhaseEvent(EVENT_PHASE_CREATED, data.createPhase);
    },
  });

  function onUpdateCompleted(data: CreatePhaseMutation) {
    if (onCompleted) {
      onCompleted(data.createPhase);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createPhase,
  };
};
