import { useMemo } from "react";

import { getUnitListQueryDocument } from "@/graphql/common/unit";
import { useQuery } from "@apollo/client";

export interface UseGetUnitListProps {
  workspaceId?: string;
  enabled?: boolean;
}

export const useGetUnitList = ({ workspaceId, enabled = true }: UseGetUnitListProps) => {
  const queryResult = useQuery(getUnitListQueryDocument, {
    variables: { input: { workspaceId: workspaceId ?? "" } },
    skip: !enabled,
  });

  const unitList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.unitList;
  }, [queryResult.data]);

  return {
    queryResult,
    unitList,
    loading: queryResult.loading,
    error: queryResult.error,
  };
};
