import { TaskStatus, TaskStatusLabels } from "../task-type";

import { TaskColumn } from "./task-board-types";

export const DEFAULT_COLUMNS: readonly TaskColumn[] = [
  {
    id: TaskStatus.Backlog,
    title: TaskStatusLabels[TaskStatus.Backlog],
  },
  {
    id: TaskStatus.Todo,
    title: TaskStatusLabels[TaskStatus.Todo],
  },
  {
    id: TaskStatus.Doing,
    title: TaskStatusLabels[TaskStatus.Doing],
  },
  {
    id: TaskStatus.Done,
    title: TaskStatusLabels[TaskStatus.Done],
  },
  {
    id: TaskStatus.Cancelled,
    title: TaskStatusLabels[TaskStatus.Cancelled],
  },
] as const;
