import { FC, PropsWithChildren, useMemo } from "react";

import { Avatar, AvatarImage } from "@/components/_ui/avatar";
import { Badge } from "@/components/_ui/badge";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";
import { useUserInWorkspace } from "@/hooks/api/common/useUser";
import { cn } from "@/lib/utils";

export interface UserHoverProps extends PropsWithChildren {
  className?: string;
  workspace: {
    id: string;
  };
  user: {
    id: string;
  };
  type: "avatar" | "username" | "avatar+username";
}

export const UserHover: FC<UserHoverProps> = ({ className, type, ...props }) => {
  const { user, getWorkspaceMembershipForWorkspace } = useUserInWorkspace({
    workspaceId: props.workspace.id,
    userId: props.user.id,
  });

  const workspaceMembership = useMemo(() => {
    return getWorkspaceMembershipForWorkspace(props.workspace.id);
  }, [props.workspace, getWorkspaceMembershipForWorkspace]);

  const isPendingWorkspaceMembership = useMemo(() => {
    if (!workspaceMembership) {
      return false;
    }

    return workspaceMembership.status === "PENDING";
  }, [workspaceMembership]);

  const fallbackInitials = useMemo(() => {
    if (!user) {
      return "";
    }

    if (!user.username) {
      return String(user.email[0]).toUpperCase();
    }

    return String(user.username[0]).toUpperCase();
  }, [user]);

  if (!user) {
    return <></>;
  }

  return (
    <HoverCard>
      <HoverCardTrigger
        className={cn(["flex flex-row items-center gap-1", isPendingWorkspaceMembership ? "grayscale" : ""])}
      >
        {type === "username" && <span className={className}>{user.username ?? user.email}</span>}
        {type === "avatar" && (
          <Avatar className={cn(["h-5 w-5 border border-background", className])}>
            {user.profilePicture && <AvatarImage src={user.profilePicture} />}
            {!user.profilePicture && (
              <div
                className="flex aspect-square items-center justify-center overflow-hidden rounded-full text-white"
                style={{ backgroundColor: "rgb(245 161 0)" }}
              >
                {fallbackInitials}
              </div>
            )}
          </Avatar>
        )}

        {type === "avatar+username" && (
          <>
            <Avatar className={cn(["h-5 w-5 border border-background", className])}>
              {user.profilePicture && <AvatarImage src={user.profilePicture} />}
              {!user.profilePicture && (
                <div
                  className="flex aspect-square items-center justify-center overflow-hidden rounded-full text-white"
                  style={{ backgroundColor: "rgb(245 161 0)" }}
                >
                  {fallbackInitials}
                </div>
              )}
            </Avatar>
            <span className={className}>{user.username ?? user.email}</span>
          </>
        )}
      </HoverCardTrigger>
      <HoverCardContent side="top" align="start" className="w-auto p-3">
        <div className="flex flex-row items-start gap-2 text-sm">
          <Avatar className="h-8 w-8 border border-background">
            {user.profilePicture && <AvatarImage src={user.profilePicture} />}
            {!user.profilePicture && (
              <div
                className="flex aspect-square items-center justify-center overflow-hidden rounded-full text-white"
                style={{ backgroundColor: "rgb(245 161 0)" }}
              >
                {fallbackInitials}
              </div>
            )}
          </Avatar>
          <div className="flex flex-col">
            {user.fullname && user.username && (
              <div>
                <span>{`${user.fullname}`}</span>
                <span className="pl-1 text-muted-foreground">{`(${user.username})`}</span>
              </div>
            )}
            <div>
              <a className="text-muted-foreground" href={`mailto:${user.email}`}>
                {user.email}
              </a>
            </div>
            {isPendingWorkspaceMembership && (
              <div className="mt-2">
                <Badge className="text-xs font-normal" variant="secondary">
                  Einladung ausstehend
                </Badge>
              </div>
            )}
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
