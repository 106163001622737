import { Card, CardContent, CardHeader, CardTitle } from "@/components/_ui/card";
import { ResponsivePie } from "@nivo/pie";

interface GaugeChartProps {
  remainingSectionConfig: {
    label: string;
    value: number;
    color: string;
  };
  completedSectionConfig: {
    label: string;
    value: number;
    color: string;
  };
  text: string;
  title: string;
}

export function GaugeChart({ remainingSectionConfig, completedSectionConfig, text, title }: GaugeChartProps) {
  const totalValue = remainingSectionConfig.value + completedSectionConfig.value;

  const data = [
    {
      ...completedSectionConfig,
      id: "completed",
    },
    {
      ...remainingSectionConfig,
      id: "remaining",
    },
  ];

  const TextElement = (layerProps: { centerX: number; centerY: number }) => {
    const { centerX, centerY } = layerProps;

    return (
      <>
        <text
          x={centerX}
          y={centerY - 50}
          textAnchor="middle"
          dominantBaseline="central"
          className="fill-foreground text-3xl font-bold"
        >
          {completedSectionConfig.value}/{totalValue}
        </text>
        <text
          x={centerX}
          y={centerY - 20}
          textAnchor="middle"
          dominantBaseline="central"
          className="fill-foreground text-sm"
        >
          {text}
        </text>
      </>
    );
  };

  return (
    <Card className="flex flex-col rounded-lg">
      <CardHeader className="pb-0">
        <CardTitle className="text-sm font-normal">{title}</CardTitle>
        <div className="mt-2 border-t border-gray-200"></div>
      </CardHeader>
      <CardContent className="flex-1 p-0 pb-3">
        <div className="relative mx-auto my-2 flex h-[180px] w-full items-center justify-center">
          <ResponsivePie
            data={data}
            margin={{ top: 20, right: 10, bottom: 20, left: 10 }}
            innerRadius={0.85}
            padAngle={0}
            cornerRadius={20}
            activeOuterRadiusOffset={8}
            colors={{ datum: "data.color" }}
            borderWidth={0}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            isInteractive={true}
            startAngle={-90}
            endAngle={90}
            layers={["arcs", "legends", TextElement]}
            animate={true}
            motionConfig="gentle"
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
            ]}
            tooltip={({ datum }) => (
              <div className="rounded bg-black/80 p-2 text-sm text-white">
                <p>
                  {datum.label}: {datum.value}
                </p>
              </div>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
