import { createLabelGroupMutationDocument } from "@/graphql/common/label-group";
import { type CreateLabelGroupMutation } from "@/graphql/generated/graphql";
import { onGenericEntityCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_LABEL_GROUP_CREATED, trackLabelEvent } from "./tracking";

export type CreatedLabelGroup = CreateLabelGroupMutation["createLabelGroup"];

export interface UseCreateLabelGroupProps {
  onCompleted?: (data: CreatedLabelGroup) => void;
  onError?: (e: Error) => void;
}

export const useCreateLabelGroup = ({ onCompleted, onError }: UseCreateLabelGroupProps = {}) => {
  const [createLabelGroup] = useMutation(createLabelGroupMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const newLabelGroup = data?.createLabelGroup;
      if (!newLabelGroup) {
        return;
      }

      onGenericEntityCreate({ cache, newEntity: newLabelGroup, typename: "LabelGroup" });

      trackLabelEvent(EVENT_LABEL_GROUP_CREATED, newLabelGroup);
    },
  });

  function onUpdateCompleted(data: CreateLabelGroupMutation) {
    if (onCompleted) {
      onCompleted(data.createLabelGroup);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createLabelGroup,
  };
};
