import { FC } from "react";

import { ClimateActionEventFeed } from "./climate-action-event-feed";

interface ClimateActionActivityFeedTabProps {
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
}

export const ClimateActionActivityFeedTab: FC<ClimateActionActivityFeedTabProps> = (props) => {
  return (
    <main className="relative place-items-stretch overflow-auto">
      <div>Aktivität</div>

      <div>
        <ClimateActionEventFeed {...props} />
      </div>
    </main>
  );
};
