import { FC, useEffect, useMemo, useState } from "react";

import { PhaseView } from "@/components/_domain/phase";
import { Checkbox } from "@/components/_ui/checkbox";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { useGetPhaseListForWorkspace } from "@/hooks/api/common/usePhase";
import { cn } from "@/lib/utils";

import { useClimateActionListState } from "../../../../hooks";
import {
  FilterByTextList,
  FilterByTextListPayload,
  FilterByTextListPayloadSchema,
} from "../../../filter-functions/filter-by-text-list";

export interface FilterByPhaseConfigurationViewProps {
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByPhaseConfigurationView: FC<FilterByPhaseConfigurationViewProps> = ({ onFinished, ...props }) => {
  const columnId = props.column.id;
  const { table, columnFilters, config } = useClimateActionListState();
  const { phaseListForWorkspace } = useGetPhaseListForWorkspace({
    workspaceId: config.workspace.id,
  });

  const [selectedPhaseNameList, setSelectedPhaseNameList] = useState<FilterByTextList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(columnId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByTextListPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedPhaseNameList(filterValue.textList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedPhaseNameList) {
      return;
    }
    if (selectedPhaseNameList.length === 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_text_list",
      mode: filterValue?.mode ?? "included",
      textList: selectedPhaseNameList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhaseNameList]);

  const setFilter = (payload: FilterByTextListPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleSelect = (phaseName: string | null, shouldFinish?: boolean) => {
    const phaseIdList = selectedPhaseNameList ?? [];
    const updatedSelectedPhaseIds = phaseIdList.slice().filter((name) => name !== phaseName);
    setSelectedPhaseNameList([...updatedSelectedPhaseIds, phaseName]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (phaseName: string | null, shouldFinish?: boolean) => {
    const phaseIdList = selectedPhaseNameList ?? [];
    const updatedSelectedPhaseIds = phaseIdList.slice().filter((id) => id !== phaseName);
    setSelectedPhaseNameList(updatedSelectedPhaseIds);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command>
      <CommandInput placeholder={"Phase"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedPhaseNameList && !!selectedPhaseNameList.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <PhaseView phase={null} className="h-3.5 w-3.5 rounded-full" />
              </CommandItem>
            );
          })}

          {phaseListForWorkspace.map((phase) => {
            const isSelected = !!selectedPhaseNameList && !!selectedPhaseNameList.find((name) => name === phase.name);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={phase.id}
                value={phase.name}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(phase.name, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(phase.name);
                  }}
                />
                <PhaseView phase={phase} className="h-3.5 w-3.5" />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
