import { createPriorityInstanceMutationDocument } from "@/graphql/common/priority-instance";
import { type CreatePriorityInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PRIORITY_INSTANCE_CREATED, trackPriorityEvent } from "./tracking";

export type CreatedPriorityInstance = CreatePriorityInstanceMutation["createPriorityInstance"];

export interface UseCreatePriorityInstanceProps {
  onCompleted?: (data: CreatedPriorityInstance) => void;
  onError?: (e: Error) => void;
}

export const useCreatePriorityInstance = ({ onCompleted, onError }: UseCreatePriorityInstanceProps = {}) => {
  const [createPriorityInstance] = useMutation(createPriorityInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const priorityInstance = data?.createPriorityInstance;
      if (!priorityInstance) {
        return;
      }

      onGenericEntityInstanceCreate({
        cache,
        createdEntityInstance: {
          ...priorityInstance,
          __parentId: priorityInstance.priorityId,
        },
        typename: "PriorityInstance",
      });
      trackPriorityEvent(EVENT_PRIORITY_INSTANCE_CREATED, priorityInstance);
    },
  });

  function onUpdateCompleted(data: CreatePriorityInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.createPriorityInstance);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createPriorityInstance,
  };
};
