import { createTaskMutationDocument, getAllTasksQueryDocument } from "@/graphql/common/task";
import { useMutation } from "@apollo/client";

import { EVENT_TASK_CREATED, trackTaskEvent } from "./tracking";

export const useCreateTaskMutation = () => {
  const [createTask] = useMutation(createTaskMutationDocument, {
    refetchQueries: [
      {
        query: getAllTasksQueryDocument,
        variables: { input: { statusList: [] } },
      },
    ],
    update: (_cache, { data }) => {
      const task = data?.createTask;
      if (!task) {
        return;
      }
      trackTaskEvent(EVENT_TASK_CREATED, task);
    },
  });
  return {
    createTask,
  };
};
