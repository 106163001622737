import { FC, useMemo } from "react";

import { Button } from "@/components/_ui/button";
import { Combobox, ComboboxOption } from "@/components/combobox";
import { cn } from "@/lib/utils";

import { TaskStatus, TaskStatusLabels } from "./task-type";

interface TaskStatusSelectProps {
  value: TaskStatus;
  onValueChange: (value: TaskStatus) => void;
}

const STATUS_OPTIONS = [
  { value: TaskStatus.Backlog, label: TaskStatusLabels[TaskStatus.Backlog] },
  { value: TaskStatus.Todo, label: TaskStatusLabels[TaskStatus.Todo] },
  { value: TaskStatus.Doing, label: TaskStatusLabels[TaskStatus.Doing] },
  { value: TaskStatus.Done, label: TaskStatusLabels[TaskStatus.Done] },
  { value: TaskStatus.Cancelled, label: TaskStatusLabels[TaskStatus.Cancelled] },
];

export const TaskStatusSelect: FC<TaskStatusSelectProps> = ({ value, onValueChange }) => {
  const selectedStatus = STATUS_OPTIONS.find((status) => status.value === value) || STATUS_OPTIONS[0];

  // Convert STATUS_OPTIONS to ComboboxOption format
  const comboboxOptions = useMemo(() => {
    return STATUS_OPTIONS.map((status) => ({
      id: status.value,
      value: status.label,
    }));
  }, []);

  // Render each status option in the dropdown
  const renderStatusItem = (option: ComboboxOption) => {
    const isSelected = option.id === value;
    return (
      <div
        className={cn(
          "flex w-full cursor-pointer items-center px-3 py-2 text-sm hover:bg-muted/50",
          isSelected && "bg-muted",
        )}
      >
        <div className="flex items-center gap-2">
          <StatusIndicator status={option.id as TaskStatus} />
          <span>{option.value}</span>
        </div>
      </div>
    );
  };

  // Handle status selection
  const handleStatusSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) return;
    onValueChange(selectedOption.id as TaskStatus);
  };

  return (
    <Combobox
      options={comboboxOptions}
      renderItem={renderStatusItem}
      onSelect={handleStatusSelected}
      placeholder="Search status..."
      side="bottom"
      align="start"
      asChild
    >
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm flex h-auto min-w-[120px] items-center justify-between rounded-md border px-3 py-1 text-left text-xs font-normal"
      >
        <div className="flex items-center gap-2">
          <StatusIndicator status={value} />
          <span>{selectedStatus.label}</span>
        </div>
        <svg
          width="12"
          height="12"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ml-2 h-4 w-4 opacity-50"
        >
          <path
            d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.26618 11.9026 7.38064 11.95 7.49999 11.95C7.61933 11.95 7.73379 11.9026 7.81819 11.8182L10.0682 9.56819Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
          ></path>
        </svg>
      </Button>
    </Combobox>
  );
};

// Status indicator component to show a colored dot based on status
const StatusIndicator: FC<{ status: TaskStatus }> = ({ status }) => {
  const getStatusColor = () => {
    switch (status) {
      case TaskStatus.Backlog:
        return "bg-gray-400";
      case TaskStatus.Todo:
        return "bg-gray-400";
      case TaskStatus.Doing:
        return "bg-gray-400";
      case TaskStatus.Done:
        return "bg-gray-400";
      case TaskStatus.Cancelled:
        return "bg-gray-400";
      default:
        return "bg-gray-400";
    }
  };

  return <div className={cn("h-2 w-2 rounded-full", getStatusColor())} />;
};
