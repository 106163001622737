import { deleteTaskMutationDocument, getAllTasksQueryDocument } from "@/graphql/common/task";
import { useMutation } from "@apollo/client";

import { EVENT_TASK_DELETED, trackTaskEvent } from "./tracking";

export const useDeleteTaskMutation = () => {
  const [deleteTask] = useMutation(deleteTaskMutationDocument, {
    refetchQueries: [
      {
        query: getAllTasksQueryDocument,
        variables: { input: { statusList: [] } },
      },
    ],

    update: (_cache, { data }) => {
      const task = data?.deleteTask;
      if (!task) {
        return;
      }
      trackTaskEvent(EVENT_TASK_DELETED, task);
    },
  });
  return {
    deleteTask,
  };
};
