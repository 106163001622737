import { FC, useMemo } from "react";

import IconBuildingFilled from "@/components/_icons/IconBuildingFilled";
import IconCheckmark from "@/components/_icons/IconCheckmark";

import AvatarWorkspace from "../avatar-workspace";

export interface WorkspaceSelectDropdownItemProps {
  workspace?: {
    id: string;
    name: string;
    workspaceUrl: string;
    logo?: string | null | undefined;
  };
  selected?: boolean;
}

export const WorkspaceSelectDropdownItem: FC<WorkspaceSelectDropdownItemProps> = ({ workspace, selected }) => {
  const displayValue = useMemo(() => {
    if (!workspace) {
      return "nicht zugewiesen";
    }

    return workspace.name;
  }, [workspace]);

  return (
    <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
      {!workspace && <IconBuildingFilled className="mr-2 h-5 w-5 shrink-0 text-foreground/60" />}
      {workspace && <AvatarWorkspace workspace={workspace} className="mr-2 h-5 w-5" />}
      <div className="grow">{displayValue}</div>
      <div className="mr-2 w-3">
        {selected && (
          <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
        )}
      </div>
    </div>
  );
};
