import { Outlet } from "react-router-dom";

import { CurrentClimateActionListContextProvider } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useGetClimateActionListAssignedToUser } from "@/hooks/api/common/useClimateAction";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

export const MyClimateActionListLayout = () => {
  const { currentUser } = useCurrentUserContext();
  const { currentWorkspace } = useCurrentWorkspaceContext();

  const { climateActionList, loading, error } = useGetClimateActionListAssignedToUser({
    user: currentUser ?? { id: "" },
    workspace: currentWorkspace,
  });
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  return (
    <CurrentClimateActionListContextProvider climateActionList={climateActionList}>
      <Outlet />
    </CurrentClimateActionListContextProvider>
  );
};
