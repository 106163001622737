import { FC } from "react";

import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { NotFoundView } from "@/components/not-found-view";
import { PageHeader } from "@/components/page-header";

import { FocusAreaTable } from "./focus-area-table";

const title = "Handlungsfelder";
const subtitle = "Verwalten Sie Handlungsfelder in Ihrem Workspace.";
const description =
  "Gruppieren Sie Ihre Klimaschutzmaßnahmen, indem Sie ihnen ein Handlungsfeld zuweisen. So können Sie sie besser organisieren und filtern. Die hier erstellten Handlungsfelder stehen allen Mitgliedern Ihres Workspaces zur Verfügung.";

export const LocalizedPage: FC<{
  title: string;
  subtitle: string;
  description: string;
}> = ({ title, subtitle, description }) => {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  if (!currentWorkspace) {
    return <NotFoundView />;
  }

  return (
    <main className="relative max-h-screen place-items-stretch overflow-auto">
      <HeaderLayout className="border-none"></HeaderLayout>

      <div className="overflow-y-scroll pb-12" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <PageHeader title={title} subtitle={subtitle} />

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <div>
                <div>
                  <p className="font-light text-muted-foreground">{description}</p>
                  <div className="my-8" />
                  <FocusAreaTable workspace={currentWorkspace} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default function Page() {
  return <LocalizedPage title={title} subtitle={subtitle} description={description} />;
}
