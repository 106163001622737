import { FC } from "react";

import IconCircleDashed from "@/components/_icons/IconCircleDashed";

export interface FilterByPhaseDisplayViewProps {}

export const FilterByPhaseDisplayViewProps: FC<FilterByPhaseDisplayViewProps> = () => {
  return (
    <>
      <IconCircleDashed className="h-4 w-4 text-foreground/60" />
      <span className="text-xs">Phase</span>
    </>
  );
};
