import { useMemo } from "react";

import { getClimateActionListQueryDocument } from "@/graphql/common/climate-action";
import { useQuery } from "@apollo/client";

export interface UseGetClimateActionListForWorkspaceProps {
  workspaceId?: string;
  enabled?: boolean;
  shouldUpdateCache?: boolean;
}

export const useGetClimateActionListForWorkspace = ({
  workspaceId,
  enabled = true,
}: UseGetClimateActionListForWorkspaceProps) => {
  const queryResult = useQuery(getClimateActionListQueryDocument, {
    variables: { input: { workspaceId: workspaceId ?? "" } },
    skip: !workspaceId || !enabled,
  });

  const climateActionList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.climateActionListForWorkspace;
  }, [queryResult.data]);

  const topLevelClimateActionList = useMemo(() => {
    return climateActionList
      .slice()
      .filter((o) => o.parentId === null)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.title.localeCompare(b.title);
      });
  }, [climateActionList]);

  return {
    climateActionList,
    topLevelClimateActionList,
    queryResult,
    loading: queryResult.loading,
    error: queryResult.error,
  };
};
