import { FC } from "react";

export interface InstanceCountLabelProps {
  instances: Array<unknown>;
  className?: string;
}

export const InstanceCountLabel: FC<InstanceCountLabelProps> = ({ className, instances }) => {
  if (!instances.length) {
    return <></>;
  }

  if (instances.length === 1) {
    return <div className={className}>{`${instances.length} Maßnahme`}</div>;
  }

  return <div className={className}>{`${instances.length} Maßnahmen`}</div>;
};

export default InstanceCountLabel;
