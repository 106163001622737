import { getFocusAreaQueryDocument } from "@/graphql/common/focus-area";
import { getLabelQueryDocument } from "@/graphql/common/label";
import { getPhaseQueryDocument } from "@/graphql/common/phase";
import { getPriorityQueryDocument } from "@/graphql/common/priority";
import {
  GetFocusAreaInput,
  GetFocusAreaQuery,
  GetLabelInput,
  GetLabelQuery,
  GetPhaseInput,
  GetPhaseQuery,
  GetPriorityInput,
  GetPriorityQuery,
} from "@/graphql/generated/graphql";
import { ApolloCache } from "@apollo/client";

import {
  type EntityInstanceTypename,
  getEntityInstanceQueryConfig,
  isTEntityInstance,
  type MaybeTEntityInstance,
  type TEntityInstance,
  type TEntityInstanceQueryResult,
} from "./_shared-generic-types";
import { handleAddInstanceToOwner } from "./handle-generic-owner-update";

/**TEntityWithInstances
 * Returns a query configuration for entity instances (e.g., PriorityInstance).
 * This configuration is used to update the cache when an instance is created, updated, or deleted.
 *
 * For example, when a PriorityInstance is created:
 * - The parent entity (Priority) needs to be updated to include the new instance
 * - The owner (e.g., ClimateAction) needs to be updated to reference the new instance
 *
 * @param __typename - The type of entity instance (e.g., "PriorityInstance")
 * @param entityInstance - The instance being operated on, containing required properties (id, parentId, ownerId, ownerType)
 * @returns A configuration object containing the parent query config for cache operations
 */
function getQueryConfigForType<T extends TEntityInstance>(__typename: EntityInstanceTypename, entityInstance: T) {
  switch (__typename) {
    case "PriorityInstance":
      return [
        getEntityInstanceQueryConfig<
          T,
          GetPriorityQuery, // TResult
          { input: GetPriorityInput }, // TVariables
          "priority", // K -> data for this query is under "data.priority"
          "instances" // I -> instances are returned under "data.priority.instances"
        >(
          __typename,
          entityInstance,
          getPriorityQueryDocument,
          { input: { id: entityInstance.__parentId } },
          "priority",
          "instances",
        ),
      ];
    case "PhaseInstance":
      return [
        getEntityInstanceQueryConfig<
          T,
          GetPhaseQuery, // TResult
          { input: GetPhaseInput }, // TVariables
          "phase", // K -> data for this query is under "data.phase"
          "instances" // I -> instances are returned under "data.phase.instances"
        >(
          __typename,
          entityInstance,
          getPhaseQueryDocument,
          { input: { id: entityInstance.__parentId } },
          "phase",
          "instances",
        ),
      ];
    case "FocusAreaInstance":
      return [
        getEntityInstanceQueryConfig<
          T,
          GetFocusAreaQuery, // TResult
          { input: GetFocusAreaInput }, // TVariables
          "focusArea", // K -> data for this query is under "data.focusArea"
          "instances" // I -> instances are returned under "data.focusArea.instances"
        >(
          __typename,
          entityInstance,
          getFocusAreaQueryDocument,
          { input: { id: entityInstance.__parentId } },
          "focusArea",
          "instances",
        ),
      ];
    case "LabelInstance":
      return [
        getEntityInstanceQueryConfig<
          T,
          GetLabelQuery, // TResult
          { input: GetLabelInput }, // TVariables
          "label", // K -> data for this query is under "data.label"
          "instances" // I -> instances are returned under "data.label.instances"
        >(
          __typename,
          entityInstance,
          getLabelQueryDocument,
          { input: { id: entityInstance.__parentId } },
          "label",
          "instances",
        ),
      ];
  }
}

export type OnGenericCreateParams<T extends EntityInstanceTypename, E extends TEntityInstance> = {
  cache: ApolloCache<Record<string, unknown>>;
  createdEntityInstance: E | MaybeTEntityInstance;
  typename: T;
  warn?: boolean;
};

/**
 * Updates the cache when a priority is created.
 *
 * This function updates two potential cache locations:
 * 1. The direct instance list on the query for the entity (e.g., for a priority instance, this is the priority.instances array)
 * 2. Clears the priority instance from the owner's reference
 *
 *
 * @param cache The Apollo cache instance
 * @param createdPriorityInstance The priority instance that was created
 */
export function onGenericEntityInstanceCreate<T extends EntityInstanceTypename, E extends TEntityInstance>({
  cache,
  createdEntityInstance,
  typename,
  warn = true,
}: OnGenericCreateParams<T, E>) {
  if (!isTEntityInstance(createdEntityInstance)) {
    if (warn) {
      console.warn(
        `(Apollo) [Cache update on delete for '${typename}'}: object does not have the required properties`,
        createdEntityInstance,
      );
    }
    return;
  }

  const configs = getQueryConfigForType(createdEntityInstance.__typename, createdEntityInstance);

  if (!configs) {
    return;
  }

  // Loop over all query configs and update each one
  for (const config of configs) {
    if (!config) {
      continue;
    }

    const { key, key2, document, variables } = config;

    const entityResult = cache.readQuery<TEntityInstanceQueryResult<E, typeof key, typeof key2>>({
      query: document,
      variables: variables,
    });

    if (entityResult?.[key] && entityResult[key]?.[key2]) {
      const entityInstanceList = entityResult[key]?.[key2];

      // Prepare the cache write configuration
      const writeQueryConfiguration = {
        query: document,
        variables: variables,
        data: {
          [key]: {
            ...entityResult[key],
            [key2]: [...(entityInstanceList || []), createdEntityInstance],
          },
        },
      };

      // Since the TS compiler only knows the shape of the TEntity type we need to cast to any here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cache.writeQuery(writeQueryConfiguration as any);
    }
  }

  // Update the object that was the owner of that instance
  handleAddInstanceToOwner({
    cache: cache,
    instance: createdEntityInstance,
    ownerId: createdEntityInstance.ownerId,
    ownerType: createdEntityInstance.ownerType,
    entityInstanceTypename: typename,
  });
}
