import { createContext, type FC, type PropsWithChildren, useContext, useEffect, useMemo } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { currentUserQueryDocument } from "@/graphql/common/user";
import { posthogService } from "@/services/posthog.service";
import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { CurrentUser } from "../models/current-user-model";

const useCurrentUser = () => {
  const { data, loading, error, refetch } = useQuery(currentUserQueryDocument);

  const currentUser = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.currentUser;
  }, [data]);

  const isSuperUser = useMemo(() => {
    if (!data) {
      return null;
    }

    return !!data.currentUser.superUser;
  }, [data]);

  return {
    loading,
    error,
    currentUser,
    isSuperUser,
    refetch,
  };
};

export type CurrentUserContext = {
  currentUser: CurrentUser;
  isSuperUser: boolean;
};

const CurrentUserContext = createContext<CurrentUserContext | null>(null);

export interface CurrentUserContextProviderProps extends PropsWithChildren {}

export const CurrentUserContextProvider: FC<CurrentUserContextProviderProps> = ({ children }) => {
  const { currentUser, loading, error } = useCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    Sentry.setUser({ email: currentUser.email });
    posthogService.identify(
      currentUser.id, // Replace 'distinct_id' with your user's unique identifier
      { email: currentUser.email, name: currentUser.fullname }, // optional: set additional user properties
    );
  }, [currentUser]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  if (!currentUser) {
    return <ErrorView />;
  }

  return (
    <CurrentUserContext.Provider value={{ currentUser, isSuperUser: currentUser.superUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUserContext = (): CurrentUserContext => {
  const context = useContext(CurrentUserContext);

  if (!context) {
    throw new Error("useCurrentUserContext must be used within a CurrentUserContextProvider");
  }

  return context;
};

/**
 *
 * **Important:** Generally, you should not use this hook, but the `useCurrentUserContext` hook.
 *
 * This returns a nullable value, so you need to handle the case where the context is not available.
 *
 */
export const useUnsafeCurrentUserContext = (): CurrentUserContext | null => {
  return useContext(CurrentUserContext);
};
