import { createFocusAreaInstanceMutationDocument } from "@/graphql/common/focus-area-instance";
import { type CreateFocusAreaInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_FOCUS_AREA_INSTANCE_CREATED, trackFocusAreaEvent } from "./tracking";

export type CreatedFocusAreaInstance = CreateFocusAreaInstanceMutation["createFocusAreaInstance"];

export interface UseCreateFocusAreaInstanceProps {
  onCompleted?: (data: CreatedFocusAreaInstance) => void;
  onError?: (e: Error) => void;
}

export const useCreateFocusAreaInstance = ({ onCompleted, onError }: UseCreateFocusAreaInstanceProps = {}) => {
  const [createFocusAreaInstance] = useMutation(createFocusAreaInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const focusAreaInstance = data?.createFocusAreaInstance;
      if (!focusAreaInstance) {
        return;
      }

      onGenericEntityInstanceCreate({
        cache,
        createdEntityInstance: {
          ...focusAreaInstance,
          __parentId: focusAreaInstance.focusAreaId,
        },
        typename: "FocusAreaInstance",
      });

      trackFocusAreaEvent(EVENT_FOCUS_AREA_INSTANCE_CREATED, data.createFocusAreaInstance);
    },
  });

  function onUpdateCompleted(data: CreateFocusAreaInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.createFocusAreaInstance);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createFocusAreaInstance,
  };
};
