import { FC } from "react";

import IconUser from "@/components/_icons/IconUser";
import { cn } from "@/lib/utils";

export interface FilterByAssigneeDisplayViewProps {
  className?: string;
}

export const FilterByAssigneeDisplayView: FC<FilterByAssigneeDisplayViewProps> = ({ className }) => {
  return (
    <>
      <IconUser className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">Verantwortlicher</span>
    </>
  );
};
