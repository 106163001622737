import { FC } from "react";

import IconCheckmark from "@/components/_icons/IconCheckmark";

export interface UnitSelectDropdownItemProps {
  title: string;
  symbol?: string;
  selected?: boolean;
}

export const UnitSelectDropdownItem: FC<UnitSelectDropdownItemProps> = ({ title, symbol, selected }) => {
  return (
    <div
      className="m-1 flex w-full cursor-pointer flex-row items-center justify-between p-1 text-xs"
      role="option"
      aria-selected={selected}
    >
      <div className="flex items-center gap-2">
        <span>{title}</span>
        {symbol && <span className="text-muted-foreground">({symbol})</span>}
      </div>
      {selected && <IconCheckmark className="h-3 w-3 shrink-0 text-foreground/60" />}
    </div>
  );
};
