import { FC, useMemo } from "react";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";
import { cn } from "@/lib/utils";
import { KeyResultProgressView } from "@/routes/workspace/climate-actions/:climateActionId/climate-action-sheet/key-result/key-result-progress-view";
import { Row } from "@tanstack/react-table";

interface ClimateActionListCellProgressProps {
  row: Row<ClimateAction>;
  className?: string;
}

export const ClimateActionListCellProgress: FC<ClimateActionListCellProgressProps> = ({ row, className }) => {
  const keyResult = row.original.keyResult;

  const progress = useMemo(() => {
    if (!keyResult) {
      return 0;
    }

    if (keyResult.currentValue === keyResult.initialValue) {
      return 0;
    }

    if (keyResult.currentValue === keyResult.targetValue) {
      return 1;
    }

    const divisor = keyResult.initialValue - keyResult.targetValue;

    if (divisor === 0) {
      return 0;
    }

    return (keyResult.initialValue - keyResult.currentValue) / divisor;
  }, [keyResult]);

  if (!keyResult) {
    return <></>;
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className={cn(["text-xs"], className)}>
          {Number(progress).toLocaleString(undefined, { style: "percent", minimumFractionDigits: 2 })}
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="top" className="w-auto p-2">
        <div className="w-80">
          <KeyResultProgressView keyResult={keyResult} />
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
