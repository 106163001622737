import { FC, useMemo } from "react";

import { useGetKeyResult } from "@/hooks/api/common/useKeyResult";
import { useGetProgressSnapshot } from "@/hooks/api/common/useProgressSnapshot";

interface MilestoneValueUpdateProps {
  progressSnapshot: {
    id: string;
  };
}

// Todo: right now this component doesn't really show the diff to the previous progress snapshot
export const MilestoneValueUpdate: FC<MilestoneValueUpdateProps> = ({ ...props }) => {
  const { milestoneProgressSnapshot } = useGetProgressSnapshot({
    id: props.progressSnapshot.id,
  });
  // const { progressSnapshotList } = milestoneProgressSnapshot
  //   ? useGetProgressSnapshotList({
  //       climateActionId: milestoneProgressSnapshot.climateActionId,
  //     })
  //   : { progressSnapshotList: [] };

  const { keyResult } = useGetKeyResult({ id: milestoneProgressSnapshot?.keyResultId ?? undefined });

  const value = useMemo(() => {
    if (!milestoneProgressSnapshot) {
      return null;
    }
    return milestoneProgressSnapshot.value;
  }, [milestoneProgressSnapshot]);

  // const previousProgressSnapshot = useMemo(() => {
  //   if (!milestoneProgressSnapshot) {
  //     return null;
  //   }

  //   let ignore = true;
  //   for (const ps of progressSnapshotList) {
  //     // they come sorted; pick the progressSnapshot right after the current one
  //     if (ps.id === milestoneProgressSnapshot.id) {
  //       ignore = false;
  //       continue;
  //     }

  //     if (ignore) {
  //       continue;
  //     }

  //     if (ps.value !== undefined && ps.value !== null) {
  //       return ps;
  //     }
  //   }
  //   return null;
  // }, [milestoneProgressSnapshot, progressSnapshotList]);

  // const { milestoneProgressSnapshot: prevMilestoneProgressSnapshot } = previousProgressSnapshot
  //   ? useGetProgressSnapshot({
  //       id: previousProgressSnapshot.id,
  //     })
  //   : { milestoneProgressSnapshot: undefined };

  if (!milestoneProgressSnapshot) {
    return <></>;
  }

  if (!keyResult) {
    return <></>;
  }

  if (!value) {
    return <></>;
  }

  const completedMilestones = milestoneProgressSnapshot.properties.milestones.filter((ms) => ms.completed);
  const openMilestones = milestoneProgressSnapshot.properties.milestones.filter((ms) => !ms.completed);

  return (
    <>
      <span>den Indikator</span>
      <span className="text-bold text-foreground">{keyResult.title}</span>
      <span>aktualisiert.</span>
      {completedMilestones.length > 0 && (
        <>
          {completedMilestones.length === 1 && <span>Der Meilenstein</span>}
          {completedMilestones.length > 1 && <span>Die Meilensteine</span>}
          {completedMilestones.slice(0, -1).map((ms) => {
            return (
              <span key={ms.id} className="text-bold text-foreground">
                {ms.value},
              </span>
            );
          })}
          {completedMilestones.length > 1 && <span>und</span>}
          {completedMilestones.slice(-1).map((ms) => {
            return (
              <span key={ms.id} className="text-bold text-foreground">
                {ms.value},
              </span>
            );
          })}
          {completedMilestones.length === 1 && <span> ist als abgeschlossen markiert.</span>}
          {completedMilestones.length > 1 && <span> sind als abgeschlossen markiert.</span>}
        </>
      )}
      {openMilestones.length > 0 && (
        <>
          {openMilestones.length === 1 && <span>Der Meilenstein</span>}
          {openMilestones.length > 1 && <span>Die Meilensteine</span>}
          {openMilestones.slice(0, -1).map((ms) => {
            return (
              <span key={ms.id} className="text-bold text-foreground">
                {ms.value},
              </span>
            );
          })}
          {openMilestones.length > 1 && <span>und</span>}
          {openMilestones.slice(-1).map((ms) => {
            return (
              <span key={ms.id} className="text-bold text-foreground">
                {ms.value},
              </span>
            );
          })}
          {openMilestones.length === 1 && <span> ist als nicht abgeschlossen markiert.</span>}
          {openMilestones.length > 1 && <span> sind als nicht abgeschlossen markiert.</span>}
        </>
      )}
    </>
  );
};
