import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";

import { DragCancelEvent, DragEndEvent, DragOverEvent, DragStartEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { BoardColumn, BoardItem, ColumnDragData, DRAG_TYPE, ItemDragData } from "./board-types";
import { getDraggingItemData, hasDraggableData } from "./board-utils";

interface BoardStateContextType<T extends BoardItem> {
  // State
  columns: BoardColumn<T>[];
  items: T[];
  activeColumn: BoardColumn<T> | null;
  activeItem: T | null;
  isDragging: boolean;

  // State setters
  setColumns: (columns: BoardColumn<T>[] | ((prevColumns: BoardColumn<T>[]) => BoardColumn<T>[])) => void;
  setItems: (items: T[]) => void;
  setIsDirty: (isDirty: boolean) => void;

  // Event handlers
  onDragStart: (event: DragStartEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  onDragOver: (event: DragOverEvent) => void;
}

// Create a context with a default value of null
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BoardStateContext = createContext<BoardStateContextType<any> | null>(null);

interface BoardStateProviderProps<T extends BoardItem> {
  columns: BoardColumn<T>[];
  items: T[];
  setColumns: (columns: BoardColumn<T>[] | ((prevColumns: BoardColumn<T>[]) => BoardColumn<T>[])) => void;
  setItems: (items: T[]) => void;
  setIsDirty: (isDirty: boolean) => void;
  children: React.ReactNode;
}

export function BoardStateProvider<T extends BoardItem>({
  columns,
  items,
  setColumns,
  setItems,
  setIsDirty,
  children,
}: BoardStateProviderProps<T>) {
  // Tracks the original column ID of an item being dragged
  const sourceColumnId = useRef<string | null>(null);

  // Debounce timer reference
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Active elements being dragged
  const [activeColumn, setActiveColumn] = useState<BoardColumn<T> | null>(null);
  const [activeItem, setActiveItem] = useState<T | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  // Debounced version of setItems
  const debouncedSetItems = useCallback(
    (newItems: T[]) => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }

      debounceTimerRef.current = setTimeout(() => {
        setItems(newItems);
        debounceTimerRef.current = null;
      }, 50); // 50ms debounce time
    },
    [setItems],
  );

  /**
   * Handles the start of a drag operation
   * Sets the active element (column or item) based on the drag type
   */
  function onDragStart(event: DragStartEvent) {
    // Set dragging state to true
    setIsDragging(true);

    // Check if the dragged element has valid data
    if (!hasDraggableData(event.active)) return;

    const draggedElementData = event.active.data.current;

    // Handle column dragging
    if (draggedElementData?.type === DRAG_TYPE.COLUMN) {
      setActiveColumn(draggedElementData.column);
      return;
    }

    // Handle item dragging
    if (draggedElementData?.type === DRAG_TYPE.ITEM) {
      setActiveItem(draggedElementData.item as T);
      // Store the original column ID for later reference
      sourceColumnId.current = draggedElementData.item.columnId || null;
      return;
    }
  }

  /**
   * Handles the end of a drag operation
   * Reorders columns or moves items between columns based on the drag result
   */
  function onDragEnd(event: DragEndEvent) {
    // Reset active elements and dragging state
    setActiveColumn(null);
    setActiveItem(null);
    setIsDragging(false);

    const { active, over } = event;

    // Exit if no valid drag data
    if (!hasDraggableData(active)) {
      return;
    }

    const draggedElementData = active.data.current;

    // Mark as dirty if an item was dragged (for saving changes)
    const isItemDragged = draggedElementData?.type === DRAG_TYPE.ITEM;
    if (isItemDragged) {
      // The arrayMove function only updates the state of the DND library
      // We need to update the position property of all items in the same column,
      // before notifying the parent that the items have been updated.
      const itemsWithUpdatedPositions: T[] = [];
      for (const column of columns) {
        const itemsInColumn = items.filter((item) => item.columnId === column.id);
        for (const [index, item] of itemsInColumn.entries()) {
          item.position = index;
          itemsWithUpdatedPositions.push(item);
        }
      }

      setItems(itemsWithUpdatedPositions);
      setIsDirty(true);
    }

    // Exit if no drop target
    if (!over) {
      return;
    }

    const draggedId = active.id;
    const dropTargetId = over.id;

    // No change if dropped on itself
    if (draggedId === dropTargetId) {
      return;
    }

    // Handle column reordering
    const isColumnDragged = draggedElementData?.type === DRAG_TYPE.COLUMN;
    if (isColumnDragged) {
      const reorderColumns = (prevColumns: BoardColumn<T>[]) => {
        const draggedColumnIndex = prevColumns.findIndex((col) => col.id === draggedId);
        const dropTargetColumnIndex = prevColumns.findIndex((col) => col.id === dropTargetId);
        return arrayMove(prevColumns, draggedColumnIndex, dropTargetColumnIndex);
      };

      setColumns(reorderColumns);
    }

    // Reset the source column reference
    sourceColumnId.current = null;
  }

  /**
   * Handles dragging over potential drop targets
   * Updates item positions and column assignments in real-time during drag
   */
  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;

    // Exit if no drop target
    if (!over) return;

    const draggedId = active.id;
    const dropTargetId = over.id;

    // No change if hovering over itself
    if (draggedId === dropTargetId) return;

    // Exit if either element doesn't have valid drag data
    if (!hasDraggableData(active) || !hasDraggableData(over)) return;

    const draggedElementData = active.data.current;
    const dropTargetData = over.data.current;

    const isItemDragged = draggedElementData?.type === DRAG_TYPE.ITEM;
    // Only handle item dragging
    if (!isItemDragged) {
      return;
    }

    // Case 1: Dragging an item over another item
    const isOverItem = dropTargetData?.type === DRAG_TYPE.ITEM;

    if (isOverItem) {
      const itemsCopy = [...items];
      const draggedItemIndex = itemsCopy.findIndex((item) => item.id === draggedId);
      const draggedItem = itemsCopy[draggedItemIndex];

      if (!draggedItem) {
        return;
      }

      const dropTargetItemIndex = itemsCopy.findIndex((item) => item.id === dropTargetId);
      const dropTargetItem = itemsCopy[dropTargetItemIndex];

      if (!dropTargetItem) {
        return;
      }

      // 1. update the column ID of the dragged item
      draggedItem.columnId = dropTargetItem.columnId;

      // 2. Reorder the items
      // NOTE: arrayMove only updates order of items in the array, not the position property. We have to do this ourselves once the drop ends.
      const reorderedItems = arrayMove(itemsCopy, draggedItemIndex, dropTargetItemIndex);
      debouncedSetItems(reorderedItems);
    }

    // Case 2: Dragging an item over a column
    const isOverColumn = dropTargetData?.type === DRAG_TYPE.COLUMN;
    if (isOverColumn) {
      const itemsCopy = [...items];
      const draggedItemIndex = itemsCopy.findIndex((item) => item.id === draggedId);
      const draggedItem = itemsCopy[draggedItemIndex];

      // 1. Update the item's column ID to the target column
      draggedItem.columnId = String(dropTargetId);
      // Maintain the item's position in the array but with updated column ID
      // setItems(arrayMove(itemsCopy, draggedItemIndex, draggedItemIndex));

      // 2. Insert the new item at the top of the target column
      // NOTE: arrayMove only updates order of items in the array, not the position property. We have to do this ourselves once the drop ends.
      const reorderedItems = arrayMove(itemsCopy, draggedItemIndex, 0);
      setItems(reorderedItems);
    }
  }

  const value = {
    // State
    columns,
    items,
    activeColumn,
    activeItem,
    isDragging,

    // State setters
    setColumns,
    setItems,
    setIsDirty,

    // Event handlers
    onDragStart,
    onDragEnd,
    onDragOver,
  };

  return <BoardStateContext.Provider value={value}>{children}</BoardStateContext.Provider>;
}

// Custom hook to use the board state
export function useBoardState<T extends BoardItem>() {
  const context = useContext(BoardStateContext);
  if (!context) {
    throw new Error("useBoardState must be used within a BoardStateProvider");
  }
  return context as BoardStateContextType<T>;
}

// The useBoardAnnouncements hook generates descriptive text announcements that
// help users with screen readers understand what's happening during drag-and-drop
// interactions. It provides verbal feedback for four key drag-and-drop events.
export function useBoardAnnouncements<T extends BoardItem>() {
  const { columns, items } = useBoardState<T>();
  const sourceColumnId = useRef<string | null>(null);
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  return {
    /**
     * Announces when an element starts being dragged
     */
    onDragStart({ active }: DragStartEvent) {
      if (!hasDraggableData(active)) return;

      const dragData = active.data.current as ItemDragData<T> | ColumnDragData<T>;

      // Announce column dragging
      if (dragData?.type === DRAG_TYPE.COLUMN) {
        const startColumnIdx = columnsId.findIndex((id) => id === active.id);
        const startColumn = columns[startColumnIdx];
        return `Picked up Column ${startColumn?.title} at position: ${startColumnIdx + 1} of ${columnsId.length}`;
      }
      // Announce item dragging
      else if (dragData?.type === DRAG_TYPE.ITEM) {
        sourceColumnId.current = dragData.item.columnId || null;
        const { itemsInColumn, itemPosition, column } = getDraggingItemData(
          items,
          columns,
          active.id,
          dragData.item.columnId || "",
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const itemTitle = (dragData.item as any)?.title || (dragData.item as any)?.name || dragData.item.id;
        return `Picked up Item ${itemTitle} at position: ${itemPosition + 1} of ${
          itemsInColumn.length
        } in column ${column?.title}`;
      }
    },

    /**
     * Announces when an element is dragged over a potential drop target
     */
    onDragOver({ active, over }: DragOverEvent) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) return;

      const activeDragData = active.data.current as ItemDragData<T> | ColumnDragData<T>;
      const overDragData = over.data.current as ItemDragData<T> | ColumnDragData<T>;

      // Announce column over column
      if (activeDragData?.type === DRAG_TYPE.COLUMN && overDragData?.type === DRAG_TYPE.COLUMN) {
        const overColumnIdx = columnsId.findIndex((id) => id === over.id);
        return `Column ${activeDragData.column.title} was moved over ${overDragData.column.title} at position ${
          overColumnIdx + 1
        } of ${columnsId.length}`;
      }
      // Announce item over item
      else if (activeDragData?.type === DRAG_TYPE.ITEM && overDragData?.type === DRAG_TYPE.ITEM) {
        const { itemsInColumn, itemPosition, column } = getDraggingItemData(
          items,
          columns,
          over.id,
          overDragData.item.columnId || "",
        );

        // Different announcement if moving between columns

        const itemTitle =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (activeDragData.item as any)?.title || (activeDragData.item as any)?.name || activeDragData.item.id;

        if (overDragData.item.columnId !== sourceColumnId.current) {
          return `Item ${itemTitle} was moved over column ${column?.title} in position ${itemPosition} of ${itemsInColumn.length}`;
        }

        // Same column announcement
        return `Item was moved over position ${itemPosition + 1} of ${itemsInColumn.length} in column ${column?.title}`;
      }
    },

    /**
     * Announces when a drag operation completes
     */
    onDragEnd({ active, over }: DragEndEvent) {
      if (!hasDraggableData(active) || !hasDraggableData(over)) {
        sourceColumnId.current = null;
        return;
      }

      const activeDragData = active.data.current as ItemDragData<T> | ColumnDragData<T>;
      const overDragData = over.data.current as ItemDragData<T> | ColumnDragData<T>;

      // Announce column drop
      if (activeDragData?.type === DRAG_TYPE.COLUMN && overDragData?.type === DRAG_TYPE.COLUMN) {
        const overColumnPosition = columnsId.findIndex((id) => id === over.id);

        return `Column ${activeDragData.column.title} was dropped into position ${overColumnPosition + 1} of ${
          columnsId.length
        }`;
      }
      // Announce item drop
      else if (activeDragData?.type === DRAG_TYPE.ITEM && overDragData?.type === DRAG_TYPE.ITEM) {
        const { itemsInColumn, itemPosition, column } = getDraggingItemData(
          items,
          columns,
          over.id,
          overDragData.item.columnId || "",
        );

        // Different announcement if moved between columns
        if (overDragData.item.columnId !== sourceColumnId.current) {
          return `Item was dropped into column ${column?.title} in position ${itemPosition + 1} of ${
            itemsInColumn.length
          }`;
        }

        // Same column announcement
        return `Item was dropped into position ${itemPosition + 1} of ${
          itemsInColumn.length
        } in column ${column?.title}`;
      }

      sourceColumnId.current = null;
    },

    /**
     * Announces when a drag operation is cancelled
     */
    onDragCancel({ active }: DragCancelEvent) {
      sourceColumnId.current = null;
      if (!hasDraggableData(active)) return;
      return `Dragging ${active.data.current?.type} cancelled.`;
    },
  };
}
