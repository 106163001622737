import type { CommandPayloadType, LexicalEditor } from "lexical";
import {
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$isGridSelection,
  GridSelection,
  PASTE_COMMAND,
  RangeSelection,
} from "lexical";

import { $insertDataTransferForRichText } from "@lexical/clipboard";
import { $insertGeneratedNodes } from "@lexical/clipboard";
import { $generateNodesFromDOM } from "@lexical/html";

function removeStyles(el: HTMLElement) {
  el.removeAttribute("style");

  if (el.childNodes.length === 0) {
    return;
  }

  for (const child of el.childNodes) {
    // only apply this to elements
    if (child.nodeType === 1 && child instanceof HTMLElement) {
      removeStyles(child as HTMLElement);
    }
  }
}

function $insertDataTransferForRichTextWithCleanedStyleProperties(
  dataTransfer: DataTransfer,
  selection: RangeSelection | GridSelection,
  editor: LexicalEditor,
): void {
  // if it is html, let's handle it ourselves first
  const htmlString = dataTransfer.getData("text/html");
  if (htmlString) {
    try {
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, "text/html");
      const body = dom.querySelector("body");
      if (body) {
        removeStyles(body);
      }
      const nodes = $generateNodesFromDOM(editor, dom);
      return $insertGeneratedNodes(editor, nodes, selection);
    } catch {
      // Fail silently.
    }
  }

  // otherwise lets pass it through
  return $insertDataTransferForRichText(dataTransfer, selection, editor);
}

export function onPasteForRichText(event: CommandPayloadType<typeof PASTE_COMMAND>, editor: LexicalEditor): void {
  event.preventDefault();
  editor.update(
    () => {
      const selection = $getSelection();
      const clipboardData = event instanceof InputEvent || event instanceof KeyboardEvent ? null : event.clipboardData;
      if (clipboardData !== null && ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection))) {
        $insertDataTransferForRichTextWithCleanedStyleProperties(clipboardData, selection, editor);
      }
    },
    {
      tag: "paste",
    },
  );
}
