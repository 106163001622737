import { FC, useState } from "react";

import { Button } from "@/components/_ui/button";
import { cn } from "@/lib/utils";

import { IndicatorType } from "../../../models/indicator-type.model";
import { IndicatorTypeSelectDropdown } from "../../indicator-type-select-dropdown";
import { IndicatorTypeView } from "../../indicator-type-view";

export interface IndicatorFormTypeInputProps {
  value?: IndicatorType;
  onValueChange?: (value: IndicatorType | null) => void;
}

export const IndicatorFormTypeInput: FC<IndicatorFormTypeInputProps> = ({ onValueChange, ...props }) => {
  const [value, setValue] = useState<IndicatorType | null>(props.value ?? null);

  const handleValueChanged = (value: IndicatorType | null) => {
    setValue(value);

    if (!onValueChange) {
      return;
    }
    // notify the parent component about the change
    onValueChange(value);
  };

  return (
    <IndicatorTypeSelectDropdown defaultValue={value ?? undefined} onSelect={handleValueChanged} asChild>
      <Button
        variant="ghost"
        size="xs"
        className={cn([
          "shadow-sm rounded-md border text-left text-xs font-normal",
          !value ? "text-foreground/40" : "",
        ])}
      >
        <IndicatorTypeView type={value} />
      </Button>
    </IndicatorTypeSelectDropdown>
  );
};
