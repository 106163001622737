import { useEffect, useMemo, useState } from "react";

import { teamMembershipsForTeamQueryDocument } from "@/components/_domain/team/hooks/useTeam/team-membership.gql";
import { TeamMembership } from "@/graphql/generated/graphql";
import { apolloClient } from "@/services/apollo.service";

const fetchTeamMembershipList = async (teamIdList: string[]) => {
  const fetchPromises = teamIdList.map((teamId) =>
    apolloClient.query({
      query: teamMembershipsForTeamQueryDocument,
      variables: { input: teamId },
    }),
  );
  const result = await Promise.all(fetchPromises);
  return result.flatMap((r) => r.data?.teamMembershipsForTeam ?? []);
};

export const useActiveUsersForTeamList = (teamIdList: string[]) => {
  const [teamMembershipList, setTeamMembershipList] = useState<TeamMembership[]>([]);

  useEffect(() => {
    if (!teamMembershipList.length) {
      fetchTeamMembershipList(teamIdList).then((data) => {
        setTeamMembershipList(data as TeamMembership[]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeTeamMembershipList = useMemo(() => {
    if (!teamMembershipList) {
      return [];
    }
    return teamMembershipList.slice().filter((tm) => tm.status === "ACTIVE");
  }, [teamMembershipList]);

  const activeUsers = useMemo(() => {
    const users = activeTeamMembershipList.flatMap((u) => {
      if (!u.user) {
        return [];
      }
      return u.user;
    });
    // We use a map to avoid duplicates
    return Array.from(new Map(users.map((obj) => [obj.id, obj])).values());
  }, [activeTeamMembershipList]);

  return { activeUsers };
};
