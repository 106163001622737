import { FC, PropsWithChildren, useMemo } from "react";

import { Button } from "@/components/_ui/button";
import LoadingDots from "@/components/loading-dots";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useGetTeam } from "@/hooks/api/common/useWorkspace/useGetTeam";
import { useTeamMembershipCreate } from "@/hooks/api/superuser";
import { cn } from "@/lib/utils";

export interface TeamMemberJoinButtonProps extends PropsWithChildren {
  team: {
    id: string;
    workspaceId: string;
  };
  className?: string;
}

export const TeamMemberJoinButton: FC<TeamMemberJoinButtonProps> = ({ className, ...props }) => {
  const { currentUser, isSuperUser } = useCurrentUserContext();
  const { team } = useGetTeam({ id: props.team.id });
  const { createTeamMembership, queryResult } = useTeamMembershipCreate({
    team: props.team,
  });

  const isTeamMember = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    if (!team) {
      return false;
    }

    const tsm = team.teamMembershipList.find((tsm) => tsm.userId === currentUser?.id);

    return !!tsm;
  }, [currentUser, team]);

  const loading = useMemo(() => {
    return queryResult.loading;
  }, [queryResult]);

  function handleButtonPressed() {
    if (!currentUser) {
      return;
    }

    if (loading) {
      return;
    }

    createTeamMembership({
      variables: {
        input: {
          teamId: props.team.id,
          userId: currentUser.id,
          role: "ADMIN",
        },
      },
    });
  }

  if (!isSuperUser) {
    return <></>;
  }

  if (isTeamMember) {
    return <></>;
  }

  return (
    <Button variant="ghost" size="xs" onClick={handleButtonPressed} className={cn(["border", className])}>
      {loading && (
        <span className="cursor-wait">
          <LoadingDots />
        </span>
      )}
      {!loading && <span>Beitreten</span>}
    </Button>
  );
};
