import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_TEAM_CREATED = "MUTATION:TEAM:CREATED";
export const EVENT_TEAM_UPDATED = "MUTATION:TEAM:UPDATED";
export const EVENT_TEAM_DELETED = "MUTATION:TEAM:DELETED";

export const EVENT_TEAM_INVITE_CREATED = "MUTATION:TEAM_INVITE:CREATED";
export const EVENT_TEAM_INVITE_DELETED = "MUTATION:TEAM_INVITE:DELETED";
export const EVENT_TEAM_INVITE_ACCEPTED = "MUTATION:TEAM_INVITE:ACCEPTED";
export const EVENT_TEAM_JOIN = "MUTATION:TEAM_INVITE:JOIN";

export const EVENT_TEAM_INSTANCE_UPDATED = "MUTATION:TEAM:INVITE:CREATED";

export const EVENT_WORKSPACE_UPDATED = "MUTATION:WORKSPACE:UPDATED";
export const EVENT_WORKSPACE_INVITE_CREATED = "MUTATION:WORKSPACE_INVITE:CREATED";
export const EVENT_WORKSPACE_INVITE_DELETED = "MUTATION:WORKSPACE_INVITE:DELETED";
export const EVENT_WORKSPACE_INVITE_ACCEPTED = "MUTATION:WORKSPACE_INVITE:ACCEPTED";

type TeamEventType =
  | typeof EVENT_TEAM_CREATED
  | typeof EVENT_TEAM_UPDATED
  | typeof EVENT_TEAM_DELETED
  | typeof EVENT_TEAM_INVITE_CREATED
  | typeof EVENT_TEAM_INVITE_DELETED
  | typeof EVENT_TEAM_INVITE_ACCEPTED
  | typeof EVENT_TEAM_JOIN
  | typeof EVENT_TEAM_INSTANCE_UPDATED;

type WorkspaceEventType =
  | typeof EVENT_WORKSPACE_UPDATED
  | typeof EVENT_WORKSPACE_INVITE_CREATED
  | typeof EVENT_WORKSPACE_INVITE_DELETED
  | typeof EVENT_WORKSPACE_INVITE_ACCEPTED;

export const trackTeamEvent = (event: TeamEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};

export const trackWorkspaceEvent = (event: WorkspaceEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
