import { useMemo } from "react";

import { getPriorityInstanceQueryDocument } from "@/graphql/common/priority-instance";
import { useQuery } from "@apollo/client";

export interface UseGetPriorityInstanceProps {
  id?: string;
  enabled?: boolean;
}

export const useGetPriorityInstance = ({ id, enabled = true }: UseGetPriorityInstanceProps) => {
  const queryResult = useQuery(getPriorityInstanceQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id || !enabled,
  });

  const priorityInstance = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.priorityInstance;
  }, [queryResult.data]);

  return {
    priorityInstance,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
