import { createContext, type FC, type PropsWithChildren, useContext } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";

import { type IndicatorListItem } from "../models/indicator-model";

import { useGetIndicatorList } from "./useIndicator/useGetIndicatorList";

type CurrentIndicatorListContext = {
  currentIndicatorList: IndicatorListItem[];
};

const CurrentIndicatorListContext = createContext<CurrentIndicatorListContext | null>(null);

export interface CurrentIndicatorListContextProviderProps extends PropsWithChildren {
  workspaceId: string;
  required?: boolean;
}

export const CurrentIndicatorListContextProvider: FC<CurrentIndicatorListContextProviderProps> = ({
  children,
  workspaceId,
  required = true,
}) => {
  const { indicatorList, loading, error } = useGetIndicatorList({ workspaceId });

  if (loading) {
    return <Loading />;
  }

  if (error && required) {
    return <ErrorView />;
  }

  return (
    <CurrentIndicatorListContext.Provider value={{ currentIndicatorList: indicatorList }}>
      {children}
    </CurrentIndicatorListContext.Provider>
  );
};

export const useCurrentIndicatorListContext = () => {
  const context = useContext(CurrentIndicatorListContext);

  if (!context) {
    throw new Error("useCurrentIndicatorListContext must be used within a CurrentIndicatorListContextProvider");
  }

  return context;
};
