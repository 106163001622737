import { LucideProps } from "lucide-react";

const Icon = ({ ...props }: LucideProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8.8C1 7.11984 1 6.27976 1.32698 5.63803C1.6146 5.07354 2.07354 4.6146 2.63803 4.32698C3.27976 4 4.11984 4 5.8 4H7.2C8.88016 4 9.72024 4 10.362 4.32698C10.9265 4.6146 11.3854 5.07354 11.673 5.63803C12 6.27976 12 7.11984 12 8.8V10.2C12 11.8802 12 12.7202 11.673 13.362C11.3854 13.9265 10.9265 14.3854 10.362 14.673C9.72024 15 8.88016 15 7.2 15H5.8C4.11984 15 3.27976 15 2.63803 14.673C2.07354 14.3854 1.6146 13.9265 1.32698 13.362C1 12.7202 1 11.8802 1 10.2V8.8ZM4 7.5C4 7.22386 4.22386 7 4.5 7H8.5C8.77614 7 9 7.22386 9 7.5C9 7.77614 8.77614 8 8.5 8H4.5C4.22386 8 4 7.77614 4 7.5ZM4.5 9C4.22386 9 4 9.22386 4 9.5C4 9.77614 4.22386 10 4.5 10H8.5C8.77614 10 9 9.77614 9 9.5C9 9.22386 8.77614 9 8.5 9H4.5ZM4 11.5C4 11.2239 4.22386 11 4.5 11H8.5C8.77614 11 9 11.2239 9 11.5C9 11.7761 8.77614 12 8.5 12H4.5C4.22386 12 4 11.7761 4 11.5Z"
        fill="#5F5F60"
      />
      <path
        d="M5.29969 2.5C4.96902 2.5 4.67088 2.5 4.40039 2.50249C4.68982 2.00006 5.11934 1.59122 5.63793 1.32698C6.27967 1 7.11975 1 8.79991 1H10.1999C11.8801 1 12.7201 1 13.3619 1.32698C13.9264 1.6146 14.3853 2.07354 14.6729 2.63803C14.9999 3.27976 14.9999 4.11984 14.9999 5.8V7.2C14.9999 8.88016 14.9999 9.72024 14.6729 10.362C14.4086 10.8806 13.9997 11.3102 13.4972 11.5996C13.4997 11.3291 13.4997 11.0308 13.4997 10.7V8.9C13.4997 6.65979 13.4997 5.53969 13.0637 4.68404C12.6802 3.93139 12.0683 3.31947 11.3157 2.93597C10.46 2.5 9.3399 2.5 7.09969 2.5H5.29969Z"
        fill="#5F5F60"
      />
    </svg>
  );
};

export default Icon;
