import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_TASK_CREATED = "MUTATION:TASK:CREATED";
export const EVENT_TASK_UPDATED = "MUTATION:TASK:UPDATED";
export const EVENT_TASK_DELETED = "MUTATION:TASK:DELETED";
export const EVENT_TASK_LIST_UPDATED = "MUTATION:TASK:UPDATED_MANY";

type TaskEventType =
  | typeof EVENT_TASK_CREATED
  | typeof EVENT_TASK_UPDATED
  | typeof EVENT_TASK_DELETED
  | typeof EVENT_TASK_LIST_UPDATED;

export const trackTaskEvent = (event: TaskEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
