import { useMemo, useState } from "react";
import { ListTodo } from "lucide-react";

import { CurrentUser } from "@/components/_domain/user/models/current-user-model";
import { makeWorkspaceSettingsUrl, makeWorkspaceUrl } from "@/components/_domain/workspace/lib/make-workspace-url";
import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import IconCheckLine from "@/components/_icons/IconCheckLine";
import IconMyClimateActions from "@/components/_icons/IconMyClimateActions";
import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { ButtonHelp } from "@/components/button-help";
import { ButtonTheme } from "@/components/button-theme";
import { Link } from "@/components/link";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import WorkspaceSelect from "@/routes/WorkspaceSelect";
import { signout } from "@/services/auth.service";
import { MagnifyingGlassPlusIcon, PlusIcon } from "@heroicons/react/24/solid";

import { TeamBrowseAllDialog } from "../../_domain/team/team-browse-all-dialog";
import { AvatarUser } from "../../_domain/user/avatar-user";
import { AvatarWorkspace } from "../../_domain/workspace/avatar-workspace";
import { Dialog, DialogContent } from "../../_ui/dialog";
import { Sidebar, SidebarBottomContent, SidebarMainContent, SidebarNavElement, SidebarTopContent } from "../sidebar";

import { SidebarAppAllTeamsNavElement } from "./components/sidebar-app-all-teams-nav-element";
import { SidebarAppFavorites } from "./components/sidebar-app-favorites";
import { SidebarAppWorkspaceElement } from "./components/sidebar-app-workspace-element";

export interface SidebarAppProps {
  workspace: Workspace;
  user: CurrentUser;
}

export const SidebarApp = ({ workspace, user }: SidebarAppProps) => {
  const [dialogisOpen, setDialogisOpen] = useState(false);
  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspace.id,
    action: "manage",
  });

  const workspaceMembershipList = useMemo(() => {
    if (!user?.workspaceMembershipList) {
      return [];
    }
    return user.workspaceMembershipList;
  }, [user]);

  function handleSignout() {
    signout();
  }

  function hasHiddenWorkspaces() {
    return workspaceMembershipList.filter((w) => w.status === "LEFT").length > 0;
  }

  function handleSelectWorkspace(workspaceUrl?: string) {
    if (!workspaceUrl) {
      return;
    }
    window.location.replace(new URL(`${window.location.origin}/${workspaceUrl}`));
  }

  function handleOnDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  return (
    <Sidebar>
      {/* Top Section of the Sidebar */}
      <SidebarTopContent>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="items-left flex h-9 flex-initial select-none flex-row justify-start gap-2 px-2 text-left text-xs hover:bg-foreground/[3%]"
              variant="ghost"
              size="xs"
              style={{ maxWidth: "150px" }}
            >
              <AvatarWorkspace workspace={workspace} className="h-6 w-6" />
              <span className="max-w-min overflow-hidden text-ellipsis">{workspace.name}</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="max-h-[80vh] !overflow-y-auto">
            {user && (
              <DropdownMenuItem asChild disabled>
                <Link to="" target="_blank">
                  {user.email}
                </Link>
              </DropdownMenuItem>
            )}
            {workspaceMembershipList
              .filter((w) => w.status === "ACTIVE")
              .map((workspaceMembership) => {
                if (!workspaceMembership.workspace) {
                  return <></>;
                }

                return (
                  <DropdownMenuItem
                    className="cursor-pointer"
                    key={workspaceMembership.workspaceId}
                    onClick={() => handleSelectWorkspace(workspaceMembership.workspace?.workspaceUrl)}
                  >
                    <div>
                      <span
                        className="items-left flex flex-initial select-none flex-row items-center justify-start gap-2 pl-4 text-left text-xs"
                        style={{ width: "180px" }}
                      >
                        <AvatarWorkspace className="text-2xs h-6 w-6" workspace={workspaceMembership.workspace} />
                        <span className="overflow-hidden text-ellipsis">{workspaceMembership.workspace.name}</span>
                        <span className="">
                          {workspaceMembership.workspaceId === workspace.id && <IconCheckLine className="ml-4" />}
                        </span>
                      </span>
                    </div>
                  </DropdownMenuItem>
                );
              })}
            {hasHiddenWorkspaces() && (
              <DropdownMenuItem className="cursor-pointer" onClick={() => setDialogisOpen(true)}>
                <span className="ml-4 cursor-pointer text-xxs">Alle Anzeigen</span>
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <Link to={makeWorkspaceSettingsUrl({ workspace, path: "workspace/general" })}>
                Workspace Einstellungen
              </Link>
            </DropdownMenuItem>
            {hasPermissionToManageWorkspace && (
              <DropdownMenuItem>
                <Link to={makeWorkspaceSettingsUrl({ workspace, path: "workspace/member" })}>Mitglieder verwalten</Link>
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => {
                handleSignout();
              }}
            >
              Log Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="flex flex-initial grow flex-row text-xs" style={{ minWidth: "8px" }}></div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="flex h-9 flex-initial select-none flex-row items-center gap-2 px-2 text-xs hover:bg-foreground/[3%]"
              variant="ghost"
              size="xs"
            >
              <AvatarUser />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {user.superUser && (
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link to={`/admin`}>Admin Panel</Link>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem className="cursor-pointer" asChild>
              <Link to={makeWorkspaceSettingsUrl({ workspace, path: "account/profile" })}>Einstellungen</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link to="https://ark-climate.productlane.com/changelog" target="_blank">
                Change Log
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild disabled>
              <Link to="">Dokumentation</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => {
                handleSignout();
              }}
            >
              Log Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        {hasHiddenWorkspaces() && (
          <Dialog open={dialogisOpen} onOpenChange={handleOnDialogOpenChange}>
            <DialogContent className="h-screen w-screen max-w-none">
              <WorkspaceSelect />
            </DialogContent>
          </Dialog>
        )}
      </SidebarTopContent>

      {/* Middle Section of the Sidebar */}
      <SidebarMainContent>
        <SidebarNavElement to={makeWorkspaceUrl({ workspace, path: "my-climate-actions" })}>
          <IconMyClimateActions className="mr-2 h-3 w-3" />
          Meine Maßnahmen
        </SidebarNavElement>
        <SidebarNavElement to={makeWorkspaceUrl({ workspace, path: "my-tasks" })}>
          <ListTodo className="mr-2 h-3 w-3" />
          Meine Aufgaben
        </SidebarNavElement>

        {hasPermissionToManageWorkspace && (
          <>
            <div className="mt-4" />
            <SidebarAppWorkspaceElement />
          </>
        )}
        <div className="mt-4" />

        <SidebarAppFavorites />
        <div className="mt-4" />

        {user && (
          <>
            <SidebarAppAllTeamsNavElement workspace={workspace} user={user} />

            <div className="mt-4" />

            <TeamBrowseAllDialog asChild={true} workspaceId={workspace.id} userId={user.id} className="w-full">
              <div className="margin-1 hover: relative cursor-pointer rounded">
                <div className="select-none text-sm">
                  <div className="relative flex h-8 items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0 hover:bg-foreground/[3%] hover:text-accent-foreground ">
                    <MagnifyingGlassPlusIcon className="mr-2 h-3.5 w-3.5" />
                    Team beitreten
                  </div>
                </div>
              </div>
            </TeamBrowseAllDialog>

            <div className="mt-1" />

            <SidebarNavElement to={makeWorkspaceSettingsUrl({ workspace, path: "new-team" })}>
              <PlusIcon className="mr-2 h-3.5 w-3.5" />
              Team hinzufügen
            </SidebarNavElement>
          </>
        )}
      </SidebarMainContent>

      {/* Bottom Section of the Sidebar */}
      <SidebarBottomContent>
        <ButtonHelp />
        <ButtonTheme />
      </SidebarBottomContent>
    </Sidebar>
  );
};
