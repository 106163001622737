import { Navigate } from "react-router-dom";

import { useCurrentTeamListContext } from "@/components/_domain/team/hooks/useCurrentTeamListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";

export function TeamListPage() {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentActiveTeamList } = useCurrentTeamListContext();

  // If we land here, either redirect to the first team or, if no teams exist, redirect to the workspace root

  if (currentActiveTeamList.length === 0) {
    return <Navigate to={`/${currentWorkspace.workspaceUrl}`} replace={true} />;
  }

  return <Navigate to={`/${currentWorkspace.workspaceUrl}/teams/${currentActiveTeamList[0].id}`} replace={true} />;
}
