import { useState } from "react";
import { Leaf } from "lucide-react";
import { HiChartBar } from "react-icons/hi2";

import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";
import { SidebarNavElement } from "@/components/_layout/sidebar";
import { FeatureFlagToggle } from "@/components/feature-flag-toggle";
import { cn } from "@/lib/utils";

export const SidebarAppWorkspaceElement = () => {
  const [expanded, setExpanded] = useState(true);

  const { currentWorkspace } = useCurrentWorkspaceContext();

  function createWorkspaceUrl(path: string) {
    return `/${currentWorkspace.workspaceUrl}/${path}`;
  }

  return (
    <>
      <div className="relative mb-0.5 cursor-pointer rounded" data-testid="sidebar-app-workspace-element">
        <div
          className={cn(
            "my-0.5 flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm hover:bg-foreground/[3%] hover:text-accent-foreground",
          )}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <span className="pointer-events-none relative flex h-6 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0 text-xs text-muted-foreground">
            {currentWorkspace.name}
          </span>
          {!expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 text-muted-foreground" />}
          {expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 rotate-90 text-muted-foreground" />}
        </div>
      </div>
      {expanded && (
        <>
          <SidebarNavElement to={createWorkspaceUrl("climate-actions/all")}>
            <Leaf className="mr-2 h-3 w-3" />
            Maßnahmenkatalog
          </SidebarNavElement>

          <FeatureFlagToggle flag={"enable_indicator_page"}>
            <SidebarNavElement to={createWorkspaceUrl("indicators")}>
              <HiChartBar className="mr-2 h-3 w-3" />
              Indikatoren
            </SidebarNavElement>
          </FeatureFlagToggle>
        </>
      )}
    </>
  );
};
