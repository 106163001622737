import { createContext, type FC, type PropsWithChildren, useContext } from "react";

import { type ClimateAction } from "../models/climateAction";

type CurrentClimateActionListContext = {
  currentClimateActionList: ClimateAction[];
};

const CurrentClimateActionListContext = createContext<CurrentClimateActionListContext | null>(null);

export interface CurrentClimateActionListContextProviderProps extends PropsWithChildren {
  climateActionList: ClimateAction[];
}

export const CurrentClimateActionListContextProvider: FC<CurrentClimateActionListContextProviderProps> = ({
  children,
  climateActionList,
}) => {
  return (
    <CurrentClimateActionListContext.Provider value={{ currentClimateActionList: climateActionList }}>
      {children}
    </CurrentClimateActionListContext.Provider>
  );
};

export const useCurrentClimateActionListContext = () => {
  const context = useContext(CurrentClimateActionListContext);

  if (!context) {
    throw new Error("useCurrentClimateActionListContext must be used within a CurrentClimateActionListContextProvider");
  }

  return context;
};
