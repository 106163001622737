import { FC, useEffect, useState } from "react";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import { AvatarUser, UserSelectDropdown } from "@/components/_domain/user";
import IconUserUnassigned from "@/components/_icons/IconUserUnassigned";
import { Button } from "@/components/_ui/button";
import { useCreateAssignee, useDeleteAssignee, useUpdateAssignee } from "@/hooks/api/common/useAssignee";
import { useActiveUsersForTeamList } from "@/hooks/api/common/useWorkspace/useActiveUsersForTeamList";
import { cn } from "@/lib/utils";
import { Row } from "@tanstack/react-table";
interface ClimateActionListAssigneeCellProps {
  row: Row<ClimateAction>;
  className?: string;
}

export const ClimateActionListAssigneeCell: FC<ClimateActionListAssigneeCellProps> = ({ row, className }) => {
  const climateAction = row.original;
  const assignee = row.original.assignee;
  const assignedUser = row.original?.assignee?.user;

  const [initialized, setInitialized] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(assignee?.userId ?? null);

  const { updateAssignee } = useUpdateAssignee({}); // always prefer this one
  const { createAssignee } = useCreateAssignee({
    ownerId: climateAction.id,
    ownerType: "CLIMATE_ACTION",
    newUserId: userId ?? undefined,
    oldUserId: assignee?.userId ?? undefined,
  });
  const { deleteAssignee } = useDeleteAssignee({
    ownerId: climateAction.id,
    ownerType: "CLIMATE_ACTION",
    oldUserId: assignee?.userId ?? undefined,
  });

  const teamIds = climateAction.teamInstanceList.map((ti) => ti.teamId);
  const { activeUsers } = useActiveUsersForTeamList(teamIds);

  function assignClimateActionToUser() {
    if (!climateAction) {
      return;
    }

    if (!userId) {
      return;
    }

    if (assignee) {
      updateAssignee({
        variables: {
          input: {
            id: assignee.id,
            userId: userId,
          },
        },
      });
    } else {
      createAssignee({
        variables: {
          input: {
            userId: userId,
            ownerId: climateAction.id,
            ownerType: "CLIMATE_ACTION",
            teamId: climateAction.teamInstanceList[0]?.teamId ?? "",
          },
        },
      });
    }
  }

  function removeAssignedUserFromClimateAction() {
    if (!assignee) {
      return;
    }
    deleteAssignee({
      variables: { input: { id: assignee.id } },
    });
  }

  const handleOnSelect = (user: { id: string } | null) => {
    setInitialized(true);
    setUserId(user ? user.id : null);
  };

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!userId) {
      removeAssignedUserFromClimateAction();
    } else {
      assignClimateActionToUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <UserSelectDropdown
      activeUsers={activeUsers}
      onSelect={handleOnSelect}
      selected={assignedUser}
      side="bottom"
      asChild
      data-testid="climate-action-assignee-dropdown"
    >
      <Button
        variant="ghost"
        size="icon"
        className={cn([
          "group overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0",
          className,
        ])}
        data-testid="climate-action-assignee-dropdown-button"
      >
        {!assignedUser && (
          <>
            <IconUserUnassigned className="h-[1.125rem] w-[1.125rem] text-muted-foreground/50 group-hover:text-muted-foreground" />
          </>
        )}
        {assignedUser && (
          <>
            <AvatarUser user={assignedUser} className="h-[1.125rem] w-[1.125rem] text-xxs" />
          </>
        )}
      </Button>
    </UserSelectDropdown>
  );
};
