import { Outlet } from "react-router-dom";

import { CurrentClimateActionListContextProvider } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { useCurrentTeamContext } from "@/components/_domain/team/hooks/useCurrentTeamContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useGetClimateActionListForTeams } from "@/hooks/api/common/useClimateAction";

export const TeamClimateActionListLayout = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentTeam } = useCurrentTeamContext();

  const { climateActionList, loading, error } = useGetClimateActionListForTeams({
    teamList: [{ id: currentTeam.id }],
    workspaceId: currentWorkspace.id,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  return (
    <CurrentClimateActionListContextProvider climateActionList={climateActionList}>
      <Outlet />
    </CurrentClimateActionListContextProvider>
  );
};
