import { FC, PropsWithChildren, useMemo } from "react";

import { Button } from "@/components/_ui/button";
import LoadingDots from "@/components/loading-dots";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useWorkspaceGetById } from "@/hooks/api/common/useWorkspace";
import { useWorkspaceMembershipCreate } from "@/hooks/api/superuser";
import { cn } from "@/lib/utils";

export interface WorkspaceMemberJoinButtonProps extends PropsWithChildren {
  workspace: {
    id: string;
  };
  className?: string;
}

export const WorkspaceMemberJoinButton: FC<WorkspaceMemberJoinButtonProps> = ({ className, ...props }) => {
  const { currentUser, isSuperUser } = useCurrentUserContext();
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });
  const { createWorkspaceMembership, queryResult } = useWorkspaceMembershipCreate({
    workspace: { id: props.workspace.id },
  });

  const isWorkspaceMember = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    if (!workspace) {
      return false;
    }

    const wsm = workspace.workspaceMembershipList.find((wsm) => wsm.userId === currentUser?.id);
    return !!wsm;
  }, [currentUser, workspace]);

  const loading = useMemo(() => {
    return queryResult.loading;
  }, [queryResult]);

  function handleButtonPressed() {
    if (!currentUser) {
      return;
    }

    if (loading) {
      return;
    }

    createWorkspaceMembership({
      variables: {
        input: {
          workspaceId: props.workspace.id,
          userId: currentUser.id,
          role: "ADMIN",
        },
      },
    });
  }

  if (!isSuperUser) {
    return <></>;
  }

  if (isWorkspaceMember) {
    return <></>;
  }

  return (
    <Button variant="ghost" size="xs" onClick={handleButtonPressed} className={cn(["border", className])}>
      {loading && (
        <span className="cursor-wait">
          <LoadingDots />
        </span>
      )}
      {!loading && <span>Beitreten</span>}
    </Button>
  );
};
