import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getPriorityInstanceQueryDocument = graphql(/* GraphQL */ `
  query GetPriorityInstance($input: GetPriorityInstanceInput!) {
    priorityInstance(getPriorityInstanceInput: $input) {
      id
      priorityId
      ownerId
      ownerType
      workspaceId
    }
  }
`);

export const getPriorityInstancebyOwnerQueryDocument = graphql(/* GraphQL */ `
  query GetPriorityInstanceByOwner($input: GetPriorityInstanceByOwnerInput!) {
    priorityInstanceByOwner(getPriorityInstanceByOwnerInput: $input) {
      id
      priorityId
      ownerId
      ownerType
      workspaceId
    }
  }
`);

// ============== MUTATIONS ======================
export const createPriorityInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation createPriorityInstance($input: CreatePriorityInstanceInput!) {
    createPriorityInstance(createPriorityInstanceInput: $input) {
      id
      workspaceId
      priorityId
      ownerId
      ownerType
      priority {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);

export const updatePriorityInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation updatePriorityInstance($input: UpdatePriorityInstanceInput!) {
    updatePriorityInstance(updatePriorityInstanceInput: $input) {
      id
      workspaceId
      priorityId
      ownerId
      ownerType
      priority {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);

export const deletePriorityInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation DeletePriorityInstance($input: DeletePriorityInstanceInput!) {
    deletePriorityInstance(deletePriorityInstanceInput: $input) {
      id
      workspaceId
      priorityId
      ownerId
      ownerType
      priority {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);
