import { fileListForOwnerQueryDocument, fileQueryDocument, updateFileMutationDocument } from "@/graphql/common/file";
import { useMutation } from "@apollo/client";

export interface UseUpdateFileProps {
  id: string;
  ownerId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useUpdateFile = ({ id, ownerId, onCompleted, onError }: UseUpdateFileProps) => {
  const [updateFile, queryResult] = useMutation(updateFileMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: fileListForOwnerQueryDocument,
        variables: { input: { ownerId: ownerId } },
      },
      {
        query: fileQueryDocument,
        variables: { input: { id: id } },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updateFile,
    queryResult,
    loading: queryResult.loading,
  };
};
