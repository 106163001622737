import { graphql } from "../generated/gql";

export const checkPermissionQueryDocument = graphql(/* GraphQL */ `
  query checkPermission($input: CheckPermissionInput!) {
    checkPermission(checkPermissionInput: $input) {
      id
      hasPermission
    }
  }
`);

export const checkPermissionListQueryDocument = graphql(/* GraphQL */ `
  query checkPermissionList($input: CheckPermissionsInput!) {
    checkPermissionList(checkPermissionsInput: $input) {
      id
      hasPermission
    }
  }
`);
