import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { WorkspaceBadge } from "@/components/_domain/workspace";
import { Button } from "@/components/_ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";
import { Input } from "@/components/_ui/input";
import { Switch } from "@/components/_ui/switch";
import { toast } from "@/components/_ui/use-toast";
import { createUnitMutationDocument, getUnitListQueryDocument } from "@/graphql/common/unit";
import { cn } from "@/lib/utils";
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z.object({
  title: z.string().min(1, "Der Name der Einheit darf nicht leer sein."),
  symbol: z
    .string()
    .min(1, "Das Symbol muss mindestens aus einem Zeichen bestehen.")
    .max(10, "Das Symbol darf maximal zehn Zeichen lang sein."),
});

export interface UnitCreateFormProps {
  className?: string;
  workspaceId: string;
  triggerCloseDialog: () => void;
}

export const UnitCreateForm: FC<UnitCreateFormProps> = ({ triggerCloseDialog, workspaceId }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      symbol: "",
    },
  });
  const [createUnit] = useMutation(createUnitMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getUnitListQueryDocument,
        variables: { input: { workspaceId } },
      },
    ],
  });
  const [createMore, setCreateMore] = useState(false);

  // eslint-disable-next-line
  async function onUpdateCompleted(createdUnit: any) {
    const { id, title, symbol } = createdUnit.createUnit;

    if (id && title && symbol) {
      toast({
        title: "Einheit erstellt",
        description: (
          <div className="gap-21 flex flex-col">
            <span>
              Einheit {title} ({symbol}) wurde erstellt
            </span>
          </div>
        ),
        variant: "success",
      });
    }

    form.reset();

    if (!createMore) {
      triggerCloseDialog();
    } else {
      form.setFocus("title");
    }
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  async function onSubmit(values: z.infer<typeof formSchema>) {
    await createUnit({
      variables: {
        input: {
          workspaceId,
          title: values.title,
          symbol: values.symbol,
        },
      },
    });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={cn("space-y-6")}>
        <div className="grid w-full">
          {/* Header */}
          <div className="flex flex-row items-center gap-1">
            {workspaceId && <WorkspaceBadge workspace={{ id: workspaceId }} />}
            <span className="ml-1 mr-1">{"›"}</span>
            <div className="block w-32 overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs">
              Neue Einheit
            </div>
          </div>
          {/* Main Part */}
          <div className="mx-auto mb-5 mt-3 flex w-full flex-col">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name der Einheit</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Name der Einheit (z.B. 'Kilometer')"
                      autoComplete="off"
                      className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                    />
                  </FormControl>
                  <FormMessage className="px-2" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="symbol"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Symbol</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Symbol für die Einheit (z.B. 'km')"
                      autoComplete="off"
                      className="focus-visible:ring-slate-600 dark:focus-visible:ring-slate-600"
                    />
                  </FormControl>
                  <FormMessage className="px-2" />
                </FormItem>
              )}
            />
          </div>
          <div className="flex flex-row items-center justify-end gap-4 pt-2">
            <div>
              <div className="flex items-center space-x-1 text-sm text-muted-foreground">
                <Switch id="airplane-mode" checked={createMore} onCheckedChange={setCreateMore} />
                <span>Mehr erstellen</span>
              </div>
            </div>
            <Button type="submit" size="sm">
              Einheit erstellen
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default UnitCreateForm;
