import { createFocusAreaMutationDocument } from "@/graphql/common/focus-area";
import { type CreateFocusAreaMutation } from "@/graphql/generated/graphql";
import { onGenericEntityCreate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_FOCUS_AREA_CREATED, trackFocusAreaEvent } from "./tracking";

export type CreatedFocusArea = CreateFocusAreaMutation["createFocusArea"];

export interface UseCreateFocusAreaProps {
  onCompleted?: (data: CreatedFocusArea) => void;
  onError?: (e: Error) => void;
}

export const useCreateFocusArea = ({ onCompleted, onError }: UseCreateFocusAreaProps) => {
  const [createFocusArea] = useMutation(createFocusAreaMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const newFocusArea = data?.createFocusArea;
      if (!newFocusArea) {
        return;
      }

      onGenericEntityCreate({ cache, newEntity: newFocusArea, typename: "FocusArea" });

      trackFocusAreaEvent(EVENT_FOCUS_AREA_CREATED, newFocusArea);
    },
  });

  function onUpdateCompleted(data: CreateFocusAreaMutation) {
    if (onCompleted) {
      onCompleted(data.createFocusArea);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createFocusArea,
  };
};
