import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { getIndicatorListQueryDocument } from "./indicator.gql";

export interface UseGetIndicatorListProps {
  workspaceId?: string;
  enabled?: boolean;
}

export const useGetIndicatorList = ({ workspaceId, enabled = true }: UseGetIndicatorListProps) => {
  const queryResult = useQuery(getIndicatorListQueryDocument, {
    variables: { input: { workspaceId: workspaceId ?? "" } },
    skip: !workspaceId || !enabled,
  });

  const indicatorList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.indicatorList;
  }, [queryResult.data]);

  return {
    indicatorList,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
