import { FC } from "react";

import { FocusAreaSelectDropdown, FocusAreaViewOptional } from "@/components/_domain/focusArea";
import { Button } from "@/components/_ui/button";
import { toast } from "@/components/_ui/use-toast";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";
import {
  useCreateFocusAreaInstance,
  useDeleteFocusAreaInstance,
  useGetFocusAreaInstance,
  useUpdateFocusAreaInstance,
} from "@/hooks/api/common/useFocusArea";

interface ClimateActionFocusAreaViewProps {
  climateAction: {
    id: string;
  };
  // Todo: Once lifted up, the workspaceid should be retrieved directly from the climateAction
  workspace: {
    id: string;
  };
}

export const ClimateActionFocusAreaView: FC<ClimateActionFocusAreaViewProps> = ({ ...props }) => {
  const { climateAction } = useGetClimateAction(props.climateAction);
  const { focusAreaInstance } = useGetFocusAreaInstance({ id: climateAction?.focusAreaInstance?.id });
  const { updateFocusAreaInstance } = useUpdateFocusAreaInstance(); // always prefer this one
  const { createFocusAreaInstance } = useCreateFocusAreaInstance();
  const { deleteFocusAreaInstance } = useDeleteFocusAreaInstance();

  function assignFocusArea(focusArea: { id: string }) {
    if (!climateAction) {
      return;
    }

    if (focusAreaInstance) {
      updateFocusAreaInstance({
        variables: {
          input: {
            id: focusAreaInstance.id,
            focusAreaId: focusArea.id,
          },
        },
        onError: () => {
          toast({
            title: "Fehler",
            description: "Handlungsfeld konnte nicht aktualisiert werden",
            variant: "error",
          });
        },
      });
    } else {
      createFocusAreaInstance({
        variables: {
          input: {
            focusAreaId: focusArea.id,
            ownerId: props.climateAction.id,
            ownerType: "CLIMATE_ACTION",
          },
        },
        onError: () => {
          toast({
            title: "Fehler",
            description: "Handlungsfeld konnte nicht erstellt werden",
            variant: "error",
          });
        },
      });
    }
  }

  function removeFocusArea() {
    if (!focusAreaInstance) {
      return;
    }
    deleteFocusAreaInstance({
      variables: { input: { id: focusAreaInstance.id } },
      onError: () => {
        toast({
          title: "Fehler",
          description: "Handlungsfeld konnte nicht gelöscht werden",
          variant: "error",
        });
      },
    });
  }

  const handleOnFocusAreaSelect = (focusArea: { id: string } | null) => {
    if (!focusArea) {
      return removeFocusArea();
    }
    assignFocusArea(focusArea);
  };

  if (!climateAction) {
    return <></>;
  }

  return (
    <div className="mb-5 flex w-full flex-row items-start gap-2 overflow-x-hidden">
      <div className="mt-2 w-24 shrink-0 font-medium">Handlungsfeld</div>
      <div className="grow">
        <FocusAreaSelectDropdown
          workspace={{ id: props.workspace.id }}
          onSelect={handleOnFocusAreaSelect}
          selected={focusAreaInstance}
          asChild
        >
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          >
            <FocusAreaViewOptional focusArea={focusAreaInstance ? { id: focusAreaInstance.focusAreaId } : null} />
          </Button>
        </FocusAreaSelectDropdown>
      </div>
    </div>
  );
};

export default ClimateActionFocusAreaView;
