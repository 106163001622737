import { useCallback } from "react";
import { Star } from "lucide-react";
import { useLocation } from "react-router-dom";

import { Button } from "@/components/_ui/button";
import { useCreateUserFavorite, useDeleteUserFavorite, useGetUserFavorites } from "@/hooks/api/common/useUserFavorites";
import { cn } from "@/lib/utils";

interface FavoriteButtonProps {
  pageName: string;
  className?: string;
}

export const FavoriteButton = ({ pageName, className }: FavoriteButtonProps) => {
  const location = useLocation();

  const { isFavorite, getFavoriteByPageUrl } = useGetUserFavorites();
  const { createFavorite } = useCreateUserFavorite();
  const { deleteFavorite } = useDeleteUserFavorite();

  const pageUrl = location.pathname;
  const isCurrentPageFavorite = isFavorite({ pageUrl });

  const handleClick = useCallback(async () => {
    if (isCurrentPageFavorite) {
      const favorite = getFavoriteByPageUrl({ pageUrl });
      if (favorite) {
        await deleteFavorite({ id: favorite.id });
      }
    } else {
      await createFavorite({ pageUrl, pageName });
    }
  }, [isCurrentPageFavorite, pageUrl, pageName, createFavorite, deleteFavorite, getFavoriteByPageUrl]);

  return (
    <Button
      variant="ghost"
      size="icon"
      className={cn("hover:bg-accent hover:text-accent-foreground", className)}
      onClick={handleClick}
      data-testid="favorite-button"
    >
      <Star
        className={cn("h-4 w-4", {
          "fill-current text-yellow-500": isCurrentPageFavorite,
        })}
      />
    </Button>
  );
};
