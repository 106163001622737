import { useMemo } from "react";

import { getKeyResultQueryDocument } from "@/graphql/common/key-result";
import { useQuery } from "@apollo/client";

import {
  KeyResult,
  MilestoneKeyResult,
  MilestoneKeyResultSchema,
  QuantitativeKeyResult,
  QuantitativeKeyResultSchema,
} from "../../../../routes/workspace/climate-actions/:climateActionId/climate-action-sheet/key-result/models/key-result.schema";

export interface UseGetKeyResultProps {
  id?: string;
}

export const useGetKeyResult = ({ id }: UseGetKeyResultProps) => {
  const queryResult = useQuery(getKeyResultQueryDocument, { variables: { input: { id: id ?? "" } }, skip: !id });

  const keyResult: KeyResult | null = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }
    return queryResult.data.keyResult;
  }, [queryResult.data]);

  const quantitativeKeyResult: QuantitativeKeyResult | null = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    const parsedQuantitativeKeyResult = QuantitativeKeyResultSchema.safeParse(queryResult.data.keyResult);
    if (parsedQuantitativeKeyResult.success) {
      return parsedQuantitativeKeyResult.data;
    }

    return null;
  }, [queryResult.data]);

  const milestoneKeyResult: MilestoneKeyResult | null = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    const parsedMilestoneKeyResult = MilestoneKeyResultSchema.safeParse(queryResult.data.keyResult);
    if (parsedMilestoneKeyResult.success) {
      return parsedMilestoneKeyResult.data;
    }

    return null;
  }, [queryResult.data]);

  return {
    queryResult,
    keyResult,
    quantitativeKeyResult,
    milestoneKeyResult,
  };
};
