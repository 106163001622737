import { createContext, type FC, type PropsWithChildren, useContext } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";

import { type ClimateAction } from "../models/climateAction";

type CurrentClimateActionContext = {
  currentClimateAction: ClimateAction;
};

const CurrentClimateActionContext = createContext<CurrentClimateActionContext | null>(null);

export interface CurrentClimateActionContextProviderProps extends PropsWithChildren {
  climateActionId: string;
}

export const CurrentClimateActionContextProvider: FC<CurrentClimateActionContextProviderProps> = ({
  children,
  climateActionId,
}) => {
  const { climateAction, loading, error } = useGetClimateAction({ id: climateActionId });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  if (!climateAction) {
    return <ErrorView />;
  }

  return (
    <CurrentClimateActionContext.Provider value={{ currentClimateAction: climateAction }}>
      {children}
    </CurrentClimateActionContext.Provider>
  );
};

export const useCurrentClimateActionContext = () => {
  const context = useContext(CurrentClimateActionContext);

  if (!context) {
    throw new Error("useCurrentClimateActionContext must be used within a CurrentClimateActionContextProvider");
  }

  return context;
};

// This is an unsafe hook that returns the **CurrentClimateActionContext | null**
//
// - Generally it is better to use the **useCurrentClimateActionContext** hook
// - Only use this hook if cannot be sure that the currentClimateActionContext is available
export const useUnsafeCurrentClimateActionContext = (): CurrentClimateActionContext | null => {
  return useContext(CurrentClimateActionContext);
};
