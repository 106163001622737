import { useMemo } from "react";

import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { WorkspaceDeleteDialogWithEmailButton } from "@/components/_domain/workspace/workspace-delete-dialog";
import { WorkspaceEditForm } from "@/components/_domain/workspace/workspace-edit-form";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

export default function Page() {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  const { currentUser, isSuperUser } = useCurrentUserContext();
  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: currentWorkspace.id,
    action: "manage",
  });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    if (isSuperUser) {
      return true;
    }
    return hasPermissionToManageWorkspace;
  }, [currentUser, isSuperUser, hasPermissionToManageWorkspace]);

  return (
    <main className="relative place-items-stretch overflow-auto">
      <HeaderLayout className="border-none"></HeaderLayout>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Workspace</div>
              <div className="text-sm text-muted-foreground">Verwalten Sie Ihren Workspace</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <WorkspaceEditForm workspace={currentWorkspace} readOnly={!hasEditRights} />
            </div>

            {hasEditRights && (
              <div>
                <div className="my-12 w-full border-t border-solid" />
                <h2 className="mb-4 text-lg">Workspace Löschen</h2>
                <p className="mb-4 text-sm text-muted-foreground">
                  Wenn Sie den Workspace und alle damit assozierten Daten löschen wollen, können Sie das hier tun.
                </p>
                <WorkspaceDeleteDialogWithEmailButton workspace={currentWorkspace} redirectAfterDelete={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
