import { FC, useEffect, useMemo, useState } from "react";
import { FaRulerHorizontal } from "react-icons/fa";

import { UnitSelectDropdown } from "@/components/_domain/unit/unit-select-dropdown/unit-select-dropdown";
import { Button } from "@/components/_ui/button";
import { useGetUnitList } from "@/hooks/api/common/useUnit";
import { cn } from "@/lib/utils";

export interface IndicatorFormUnitInputProps {
  workspaceId: string;
  value?: string; // the unitId
  onValueChange?: (value: string | null) => void; // the unitId
}

export const IndicatorFormUnitInput: FC<IndicatorFormUnitInputProps> = ({ workspaceId, onValueChange, ...props }) => {
  const [value, setValue] = useState<string | null>(props.value ?? null);

  const { unitList } = useGetUnitList({ workspaceId });

  const selectedUnit = useMemo(() => {
    if (!value) {
      return undefined;
    }
    return unitList.find((unit) => unit.id === value);
  }, [value, unitList]);

  useEffect(() => {
    if (props.value !== undefined && props.value !== value) {
      setValue(props.value);
    }
  }, [props.value, value]);

  const handleValueChanged = (unitId: string | null) => {
    setValue(unitId);

    if (!onValueChange) {
      return;
    }

    onValueChange(unitId);
  };

  return (
    <UnitSelectDropdown
      workspaceId={workspaceId}
      defaultValue={value ?? undefined}
      onSelect={handleValueChanged}
      asChild
    >
      <Button
        variant="ghost"
        size="xs"
        className={cn([
          "shadow-sm rounded-md border text-left text-xs font-normal",
          !value ? "text-foreground/40" : "",
        ])}
      >
        <div className="flex items-center gap-2">
          <FaRulerHorizontal className="h-4 w-4 text-foreground/60" />
          {selectedUnit ? (
            <span>
              {selectedUnit.title} {selectedUnit.symbol && `(${selectedUnit.symbol})`}
            </span>
          ) : (
            <span>Einheit</span>
          )}
        </div>
      </Button>
    </UnitSelectDropdown>
  );
};
