import { TeamCreateForm } from "@/components/_domain/team/team-create-form/team-create-form";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { HeaderLayout } from "@/components/_layout/header-layout";

export default function Page() {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  return (
    <main className="relative place-items-stretch overflow-auto">
      <HeaderLayout className="border-none"></HeaderLayout>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Neues Team erstellen</div>
              <div className="text-sm text-muted-foreground">Erstellen Sie ein neues Team.</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <TeamCreateForm workspace={currentWorkspace} shouldRedirect={true} className="px-px" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
