import { useParams } from "react-router-dom";

import { TeamMemberList } from "@/components/_domain/team/team-member-list";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { NotFoundView } from "@/components/not-found-view";
import { PageHeader } from "@/components/page-header";
import { useGetTeam } from "@/hooks/api/common/useWorkspace/useGetTeam";

export default function Page() {
  const { teamId } = useParams();
  const { team } = useGetTeam({ id: teamId });

  if (!teamId) {
    return <NotFoundView />;
  }

  if (!team) {
    return <NotFoundView />;
  }

  return (
    <main className="relative place-items-stretch overflow-auto">
      <HeaderLayout className="border-none"></HeaderLayout>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <PageHeader title={team.name} subtitle="Verwalten Sie wer Zugang zu Ihrem Team hat" />

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <TeamMemberList team={team} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
