import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { makeTeamSettingsUrl } from "@/components/_domain/team/lib/make-team-url";
import { Team } from "@/components/_domain/team/models/team-model";
import { TeamIcon } from "@/components/_domain/team/team-icon";
import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";
import IconInvisible from "@/components/_icons/IconInvisible";
import { SidebarNavElement } from "@/components/_layout/sidebar";
import { cn } from "@/lib/utils";

export interface SidebarSettingsTeamNavElementProps {
  workspace: Workspace;
  team: Team;
  expanded?: boolean | undefined;
}

export const SidebarSettingsTeamNavElement = ({ workspace, team, ...props }: SidebarSettingsTeamNavElementProps) => {
  const [expanded, setExpanded] = useState(props.expanded !== undefined ? props.expanded : false);

  const { teamId } = useParams();

  useEffect(() => {
    if (teamId === team.id) {
      setExpanded(true);
    }
  }, [teamId, team]);

  if (!team) {
    return <></>;
  }

  return (
    <>
      <div className="relative m-1 cursor-pointer rounded ">
        <div
          className={cn(
            "flex select-none flex-row items-center overflow-hidden text-ellipsis rounded text-sm hover:bg-foreground/[3%] hover:text-accent-foreground",
          )}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <span className="rounded pl-1">
            <TeamIcon team={team} />
          </span>
          <div className="flex min-w-0" role="button" aria-label={`Team ${team.name}`}>
            <div className="pointer-events-none h-8 items-center truncate rounded pb-0 pl-1.5 pr-0.5 pt-0 text-sm leading-8">
              {team.name}
            </div>
          </div>
          {!expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 text-foreground" />}
          {expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 rotate-90 text-foreground" />}
        </div>
      </div>
      {expanded && (
        <>
          <SidebarNavElement to={makeTeamSettingsUrl({ workspace, team, path: "general" })}>
            <IconInvisible className="mr-2 h-3 w-3" />
            Allgemein
          </SidebarNavElement>

          <SidebarNavElement to={makeTeamSettingsUrl({ workspace, team, path: "members" })}>
            <IconInvisible className="mr-2 h-3 w-3" />
            Mitglieder
          </SidebarNavElement>
        </>
      )}
    </>
  );
};
