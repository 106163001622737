import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_FOCUS_AREA_CREATED = "MUTATION:FOCUS_AREA:CREATED";
export const EVENT_FOCUS_AREA_UPDATED = "MUTATION:FOCUS_AREA:UPDATED";
export const EVENT_FOCUS_AREA_DELETED = "MUTATION:FOCUS_AREA:DELETED";

export const EVENT_FOCUS_AREA_INSTANCE_CREATED = "MUTATION:FOCUS_AREA_INSTANCE:CREATED";
export const EVENT_FOCUS_AREA_INSTANCE_UPDATED = "MUTATION:FOCUS_AREA_INSTANCE:UPDATED";
export const EVENT_FOCUS_AREA_INSTANCE_DELETED = "MUTATION:FOCUS_AREA_INSTANCE:DELETED";

type FocusAreaEventType =
  | typeof EVENT_FOCUS_AREA_CREATED
  | typeof EVENT_FOCUS_AREA_UPDATED
  | typeof EVENT_FOCUS_AREA_DELETED
  | typeof EVENT_FOCUS_AREA_INSTANCE_CREATED
  | typeof EVENT_FOCUS_AREA_INSTANCE_UPDATED
  | typeof EVENT_FOCUS_AREA_INSTANCE_DELETED;

export const trackFocusAreaEvent = (event: FocusAreaEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
