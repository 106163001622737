import { FC, useState } from "react";

import { PriorityIcon } from "@/components/_domain/priority";
import IconPen from "@/components/_icons/IconPen";
import { Button } from "@/components/_ui/button";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { useGetPriority } from "@/hooks/api/common/usePriority";

import { PriorityButtonDelete } from "./actions/priority-button-delete";
import { PriorityFormEdit } from "./actions/priority-form-edit";
import { InstanceCountLabel } from "./instance-count-label";

export interface PriorityTableRowProps {
  workspaceId: string;
  priority: {
    id: string;
  };
  hideDeleteAction?: boolean;
}

export const PriorityTableRow: FC<PriorityTableRowProps> = ({ hideDeleteAction, ...props }) => {
  const [showEditMode, setShowEditMode] = useState(false);

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: props.workspaceId,
    action: "manage",
  });
  const { priority } = useGetPriority({ id: props.priority.id });

  function handleEditPriorityButtonPressed() {
    setShowEditMode(true);
  }

  function onSuccess() {
    setShowEditMode(false);
  }

  function onCanceled() {
    setShowEditMode(false);
  }

  if (!priority) {
    return <></>;
  }

  if (showEditMode) {
    return (
      <div className="my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2">
        <PriorityFormEdit priority={priority} onSuccess={onSuccess} onCanceled={onCanceled} />
      </div>
    );
  }

  return (
    <div key={priority.id} className="mb-1 rounded border">
      <div className="m-2 flex flex-row items-center gap-2">
        <div>
          <PriorityIcon priority={{ type: priority.type, color: priority.color }} />
        </div>
        <div>{priority.name}</div>
        <InstanceCountLabel instances={priority.instances} className="text-muted-foreground" />
        <div className="grow"></div>
        {hasPermissionToManageWorkspace && (
          <>
            <div>
              <Button
                variant="ghost"
                size="icon"
                className="text-muted-foreground hover:text-foreground/80"
                onClick={handleEditPriorityButtonPressed}
              >
                <IconPen className="h-3 w-3" />
              </Button>
            </div>
            {!hideDeleteAction && <PriorityButtonDelete workspaceId={props.workspaceId} priority={priority} />}
          </>
        )}
      </div>
    </div>
  );
};

export default PriorityTableRow;
