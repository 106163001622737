import { useState } from "react";

import { AvatarUser } from "@/components/_domain/user/avatar-user";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { CardContent, CardHeader } from "@/components/_ui/card";
import { RichTextEditor } from "@/components/rich-text-editor";
import { useUserInWorkspace } from "@/hooks/api/common/useUser";
import { cn } from "@/lib/utils";

import { TaskEditDialog } from "../task-edit-dialog";
import { Task } from "../task-type";

interface TaskCardProps {
  task: Task;
  isOverlay?: boolean;
  isOver?: boolean;
}

export function TaskCard({ task, isOverlay }: TaskCardProps) {
  const [open, setOpen] = useState(false);

  const { currentWorkspace } = useCurrentWorkspaceContext();

  const { user, loading } = useUserInWorkspace({
    workspaceId: currentWorkspace.id,
    userId: task.userId,
  });

  if (loading) {
    return <></>;
  }

  return (
    <>
      <div
        className={cn([
          "task-card-content group relative cursor-pointer rounded-lg border-[0.5px] border-foreground/[8%] bg-card transition-all",
          isOverlay && "cursor-grabbing bg-card ",
        ])}
        style={{
          boxShadow: "lch(0 0 0 / 0.022) 0px 3px 6px -2px, lch(0 0 0 / 0.044) 0px 1px 1px",
        }}
        onClick={() => setOpen(true)}
        tabIndex={0}
        role="button"
        aria-label={`Edit task: ${task.title}`}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !open) {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          }
        }}
      >
        <div className={cn(["p-3", isOverlay ? "" : "hover:bg-accent/20"])}>
          <CardHeader className="relative flex flex-row items-center justify-between gap-2 border-secondary px-1 py-1">
            <div className="m-0 text-sm font-medium">{task.title}</div>
            <div>{user && <AvatarUser user={user} className="h-5 w-5 shrink-0 text-xs" />}</div>
          </CardHeader>
          <CardContent className="max-h-[4rem] overflow-hidden whitespace-pre-wrap py-3 pl-1 pr-3 text-left">
            {task.description && (
              <RichTextEditor
                key={task.description}
                readOnly={true}
                placeholder={"Geben Sie eine Beschreibung ein ..."}
                initialValue={task.description || undefined}
                className="max-h-[8rem] w-full truncate p-0"
              />
            )}
          </CardContent>
          {/* 
          {!isOverlay && (
            <div className="absolute bottom-1 right-1 hidden select-none rounded-lg bg-foreground p-1 text-xs text-background group-focus-visible:block">
              Enter um zu bearbeiten
            </div>
          )} */}
        </div>
      </div>

      <div
        onKeyDown={(event: React.KeyboardEvent) => {
          if (!open) {
            return;
          }

          // Note: We want to avoid that keystrokes trigger the
          // accessibility mode of the kanban board below, while
          // the TaskEditModal is open.

          // 1. Allow propagration down the tree (i.e, to the components in the TaskEditDialog)
          // Allow this: event.preventDefault();

          // 2. Stop event from propagating up the tree (i.e, to the KanbanBoard and its KeyboardListeners)
          event.stopPropagation();
        }}
      >
        <TaskEditDialog task={task} open={open} onOpenChange={setOpen} />
      </div>
    </>
  );
}
