// Adopted from https://github.com/facebook/lexical/blob/0f7c78b24dec932c9e1629623bedaf4485eacb08/packages/lexical-react/src/shared/useRichTextSetup.ts#L16
// At version https://github.com/facebook/lexical/commit/8cba075fd9b135545dbfa4d8831e47284045e134

import type { LexicalEditor } from "lexical";

import { mergeRegister } from "@lexical/utils";

import useLayoutEffect from "./shared/useLayoutEffect";
import { customRegisterRichText } from "./registerCustomRichText";

export function useRichTextSetup(editor: LexicalEditor): void {
  useLayoutEffect(() => {
    return mergeRegister(customRegisterRichText(editor));
  }, [editor]);
}
