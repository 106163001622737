import { useMemo } from "react";

import {
  MilestoneProgressSnapshot,
  MilestoneProgressSnapshotSchema,
  ProgressSnapshot,
  QuantitativeProgressSnapshot,
  QuantitativeProgressSnapshotSchema,
} from "@/components/_domain/progress-snapshot/progress-snapshots.schema";
import { getProgressSnapshotQueryDocument } from "@/graphql/common/progress-snapshot";
import { useQuery } from "@apollo/client";

export interface UseGetProgressSnapshotProps {
  id: string;
}

export const useGetProgressSnapshot = ({ id }: UseGetProgressSnapshotProps) => {
  const queryResult = useQuery(getProgressSnapshotQueryDocument, { variables: { input: { id: id } } });

  const progressSnapshot: ProgressSnapshot | null = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }
    return queryResult.data.progressSnapshot;
  }, [queryResult.data]);

  const quantitativeProgressSnapshot: QuantitativeProgressSnapshot | null = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }
    const parsedQuantitativeProgressSnapshot = QuantitativeProgressSnapshotSchema.safeParse(
      queryResult.data.progressSnapshot,
    );
    if (parsedQuantitativeProgressSnapshot.success) {
      return parsedQuantitativeProgressSnapshot.data;
    }

    return null;
  }, [queryResult.data]);

  const milestoneProgressSnapshot: MilestoneProgressSnapshot | null = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    const parsedMilestoneProgressSnapshot = MilestoneProgressSnapshotSchema.safeParse(
      queryResult.data.progressSnapshot,
    );
    if (parsedMilestoneProgressSnapshot.success) {
      return parsedMilestoneProgressSnapshot.data;
    }

    return null;
  }, [queryResult.data]);

  return {
    queryResult,
    progressSnapshot,
    quantitativeProgressSnapshot,
    milestoneProgressSnapshot,
  };
};
