import { FC } from "react";

import IconHeartbeat from "@/components/_icons/IconHeartbeat";
import { cn } from "@/lib/utils";

export interface FilterByStatusDisplayViewProps {
  className?: string;
}

export const FilterByStatusDisplayView: FC<FilterByStatusDisplayViewProps> = ({ className }) => {
  return (
    <>
      <IconHeartbeat className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">Status</span>
    </>
  );
};
