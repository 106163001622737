import { z } from "zod";

export const IndicatorTypeSchema = z.enum([
  "Environment",
  "People",
  "Economy",
  "Infrastructure",
  "Buildings",
  "Governance",
  "Mobility",
]);
export type IndicatorType = z.infer<typeof IndicatorTypeSchema>;

export const IndicatorTypeKeySchema = z.enum([
  "environment",
  "people",
  "economy",
  "infrastructure",
  "buildings",
  "governance",
  "mobility",
]);
export type IndicatorTypeKey = z.infer<typeof IndicatorTypeKeySchema>;

export const IndicatorTypes = {
  environment: "Environment",
  people: "People",
  economy: "Economy",
  infrastructure: "Infrastructure",
  buildings: "Buildings",
  governance: "Governance",
  mobility: "Mobility",
} as const;

export const IndicatorTypeGermanNames: Record<IndicatorType, string> = {
  Environment: "Umwelt",
  People: "Menschen",
  Economy: "Wirtschaft",
  Infrastructure: "Infrastruktur",
  Buildings: "Gebäude",
  Governance: "Governance",
  Mobility: "Mobilität",
} as const;

export const ALLOWED_INDICATOR_TYPES = Object.values(IndicatorTypes);

export const indicatorTypeFromString = (str: string): IndicatorType | undefined => {
  const key = str.toLowerCase() as IndicatorTypeKey;
  return IndicatorTypes[key] || undefined;
};
