import { FC, useEffect, useMemo, useState } from "react";
import { format, parse } from "date-fns";
import { de } from "date-fns/locale";

import { useClimateActionListState } from "@/components/_domain/climate-action/hooks/useClimateActionListState";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/_ui/select";

import {
  FilterByDatePayload,
  FilterByDatePayloadSchema,
  validateGermanDateInput,
} from "../../../filter-functions/filter-by-date";

export interface FilterByLastUpdatedConfigurationViewProps {
  columnId: string;
  onFinished: () => void;
}

export const FilterByLastUpdatedConfigurationView: FC<FilterByLastUpdatedConfigurationViewProps> = ({
  onFinished,
  columnId,
}) => {
  const { table, columnFilters } = useClimateActionListState();

  const [dateInput, setDateInput] = useState<string>("");
  const [selectedMode, setSelectedMode] = useState<"before" | "after">("before");

  const column = useMemo(() => {
    return table.getColumn(columnId);
  }, [table, columnId]);

  const existingFilterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();
    if (!filterValue) {
      return null;
    }

    const safePayload = FilterByDatePayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [column]);

  useEffect(() => {
    if (!existingFilterValue) {
      return;
    }
    const formattedDate = format(new Date(existingFilterValue.date), "dd.MM.yyyy", {
      locale: de,
    });
    setDateInput(formattedDate);
    setSelectedMode(existingFilterValue.mode);
  }, [existingFilterValue]);

  const setFilter = (payload: FilterByDatePayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleCancelClicked = () => {
    onFinished();
  };

  const handleApplyClicked = () => {
    if (dateInput === "") {
      clearFilter();
      return;
    }
    const parsedDate = parse(dateInput, "dd.MM.yyyy", new Date(), { locale: de });
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date input:", dateInput);
      return;
    }

    const validatedDate = validateGermanDateInput(dateInput);
    if (!validatedDate.success) {
      console.error("Invalid date input:", dateInput);
      return;
    }

    if (!validatedDate.data) {
      console.error("Invalid date input:", dateInput);
      return;
    }

    setFilter({
      type: "filter_by_date",
      mode: selectedMode,
      date: parsedDate.toString(),
    });
    onFinished();
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="mb-4 mr-3 text-sm">Zuletzt aktualisiert</div>
        <div className="mb-4 text-sm">
          <Select value={selectedMode} onValueChange={(value: "before" | "after") => setSelectedMode(value)}>
            <SelectTrigger>
              <SelectValue placeholder={selectedMode === "before" ? "vor dem" : "nach dem"} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="before">vor dem</SelectItem>
              <SelectItem value="after">nach dem</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="mb-4">
        <Input
          value={dateInput}
          onChange={(e) => {
            setDateInput(e.currentTarget.value);
          }}
          autoFocus
          placeholder={existingFilterValue?.date ?? "Tippen Sie hier ein Datum ein ..."}
          className="ring-offset-backgroundtransition-colors inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-normal focus:outline-none focus:ring-0 focus-visible:outline-none  focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleApplyClicked();
            }
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-end gap-2">
        <Button
          size="xs"
          variant="outline"
          onClick={() => {
            handleCancelClicked();
          }}
        >
          Abbrechen
        </Button>
        <Button
          size="xs"
          variant="default"
          onClick={() => {
            handleApplyClicked();
          }}
        >
          Anwenden
        </Button>
      </div>
    </div>
  );
};
