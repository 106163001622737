import { getLabelListForWorkspaceQueryDocument } from "@/graphql/common/label";
import { deleteLabelGroupMutationDocument } from "@/graphql/common/label-group";
import { getLabelInstanceQueryDocument } from "@/graphql/common/label-instance";
import {
  type DeleteLabelGroupMutation,
  GetLabelInstanceQuery,
  GetLabelListForWorkspaceQuery,
} from "@/graphql/generated/graphql";
import { onGenericEntityDelete, onGenericEntityInstanceDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_LABEL_GROUP_DELETED, trackLabelEvent } from "./tracking";

export type DeletedLabelGroup = DeleteLabelGroupMutation["deleteLabelGroup"];

export interface UseDeleteLabelGroupProps {
  onCompleted?: (data: DeletedLabelGroup) => void;
  onError?: (e: Error) => void;
}

export const useDeleteLabelGroup = ({ onCompleted, onError }: UseDeleteLabelGroupProps) => {
  const [deleteLabelGroup] = useMutation(deleteLabelGroupMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedLabelGroup = data?.deleteLabelGroup;
      if (!deletedLabelGroup) {
        return;
      }
      // Update labelGroupList for workspace
      onGenericEntityDelete({ cache, deletedEntity: deletedLabelGroup, typename: "LabelGroup" });

      // Note: This deletion has a cascading effect on all labels and label instances that are associated with this label group
      // Let's remove them from the cache as well

      const labelListForWorkspaceQueryResult = cache.readQuery<GetLabelListForWorkspaceQuery>({
        query: getLabelListForWorkspaceQueryDocument,
        variables: { input: { workspaceId: deletedLabelGroup.workspaceId } },
      });

      if (!labelListForWorkspaceQueryResult) {
        return;
      }

      const labelList = labelListForWorkspaceQueryResult.labelsForWorkspace;

      if (!labelList) {
        return;
      }

      const affectedLabelList = labelList.filter((label) => label.labelGroupId === deletedLabelGroup.id);

      if (affectedLabelList.length === 0) {
        return;
      }

      for (const label of affectedLabelList) {
        // delete the affected labels
        onGenericEntityDelete({ cache, deletedEntity: label, typename: "Label" });

        // and all the affected label instances
        for (const instance of label.instances) {
          const labelInstanceQueryResult = cache.readQuery<GetLabelInstanceQuery>({
            query: getLabelInstanceQueryDocument,
            variables: { input: { id: instance.id } },
          });

          if (!labelInstanceQueryResult) {
            continue;
          }

          onGenericEntityInstanceDelete({
            cache,
            deletedEntityInstance: {
              ...labelInstanceQueryResult.labelInstance,
              __parentId: label.id,
            },
            typename: "LabelInstance",
          });
        }
      }

      trackLabelEvent(EVENT_LABEL_GROUP_DELETED, deletedLabelGroup);
    },
  });

  function onUpdateCompleted(data: DeleteLabelGroupMutation) {
    if (onCompleted) {
      onCompleted(data.deleteLabelGroup);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteLabelGroup,
  };
};
