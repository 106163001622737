import { FC, useState } from "react";

import { FocusAreaList } from "@/components/_domain/focusArea";
import { FocusArea, FocusAreaType } from "@/components/_domain/focusArea";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { Button } from "@/components/_ui/button";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";

import { FocusAreaFormCreate } from "./actions/focus-area-form-create";
import { FocusAreaTableRow } from "./focus-area-table-row";

const FOCUS_AREA_DISPLAY_NAMES: Record<string, string> = {
  [FocusAreaList.energy]: "Energiewirtschaft",
  [FocusAreaList.buildings]: "Gebäude",
  [FocusAreaList.transport]: "Verkehr",
  [FocusAreaList.industry]: "Industrielle Prozesse und Produktanwendung",
  [FocusAreaList.waste]: "Abfallwirtschaft",
  [FocusAreaList.agriculture]: "Landnutzung und Forstwirtschaft",
  [FocusAreaList.city]: "Klimaneutrale Stadtverwaltung",
  [FocusAreaList.supporting]: "Unterstützende Maßnahmen",
};

export interface FocusAreaTableSectionProps {
  workspace: {
    id: string;
  };
  focusAreaType: FocusAreaType;
  focusAreaList: FocusArea[];
  className?: string;
}

export const FocusAreaTableSection: FC<FocusAreaTableSectionProps> = ({ focusAreaType, focusAreaList, className }) => {
  const [showCreateFocusAreaForm, setShowCreateFocusAreaForm] = useState(false);
  const { currentWorkspace } = useCurrentWorkspaceContext();

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: currentWorkspace.id,
    action: "manage",
  });

  const focusAreaDisplayName = FOCUS_AREA_DISPLAY_NAMES[focusAreaType];

  function handleCreateFocusAreaButtonPressed() {
    setShowCreateFocusAreaForm(true);
  }

  function onFormCanceled() {
    setShowCreateFocusAreaForm(false);
  }

  function onFormSuccess() {
    setShowCreateFocusAreaForm(false);
  }

  if (!currentWorkspace) {
    return <></>;
  }

  if (!focusAreaDisplayName) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className="mb-2 flex w-full flex-row items-center justify-between">
        <div className="text-muted-foreground">{focusAreaDisplayName}</div>
        <div>
          {hasPermissionToManageWorkspace && (
            <Button
              size="icon"
              variant="ghost"
              className="text-base text-foreground/80"
              onClick={handleCreateFocusAreaButtonPressed}
            >
              +
            </Button>
          )}
        </div>
      </div>
      {focusAreaList.map((s) => (
        <FocusAreaTableRow
          key={s.id}
          focusArea={s}
          workspace={currentWorkspace}
          hideDeleteAction={false} //always allow deletion of focus areas
        />
      ))}
      {showCreateFocusAreaForm && (
        <FocusAreaFormCreate
          workspace={currentWorkspace}
          focusAreaType={focusAreaType}
          onCanceled={onFormCanceled}
          onSuccess={onFormSuccess}
        />
      )}
    </div>
  );
};

export default FocusAreaTableSection;
