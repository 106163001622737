import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const currentUserQueryDocument = graphql(/* GraphQL */ `
  query currentUser {
    currentUser {
      id
      email
      fullname
      username
      profilePicture
      superUser
      favorites {
        id
        pageUrl
        pageName
      }
      workspaceMembershipList {
        userId
        workspaceId
        role
        status
        workspace {
          id
          name
          workspaceUrl
          logo
        }
      }
    }
  }
`);

export const userInWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query userInWorkspace($input: UserGetInput!) {
    userInWorkspace(userGetInput: $input) {
      id
      email
      fullname
      username
      profilePicture
      workspaceMembershipList {
        userId
        workspaceId
        role
        status
        workspace {
          id
          name
          workspaceUrl
          logo
        }
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const updateUserProfileDocument = graphql(/* GraphQL */ `
  mutation updateUser($input: UserUpdateInput!) {
    updateUser(userUpdateInput: $input) {
      id
      email
      fullname
      username
      createdAt
      updatedAt
    }
  }
`);

export const updateUserPasswordDocument = graphql(/* GraphQL */ `
  mutation updatePassword($input: PasswordUpdateInput!) {
    updatePassword(passwordUpdateInput: $input) {
      id
      email
      fullname
      username
      createdAt
      updatedAt
    }
  }
`);
