import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { cn } from "@/lib/utils";

import { useLayout } from "../useLayout";

import { SidebarIcon } from "./sidebar-icon";

interface SidebarProps extends PropsWithChildren {}

export const Sidebar: FC<SidebarProps> = ({ children }) => {
  const defaultWidth = 268;
  const minWidth = 220;
  const maxWidth = 360;
  const breakpoint = 1024;
  const sidebarRef = useRef<HTMLElement>(null);

  // Initialize state based on window width to prevent flickering
  const initialScreenWidth = typeof window !== "undefined" ? window.innerWidth : 0;
  const initialIsHidden = initialScreenWidth < breakpoint;

  const [isHidden, setIsHidden] = useState(initialIsHidden);
  const [isHover, setIsHover] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(initialIsHidden ? 0 : defaultWidth);
  const [screenWidth, setScreenWidth] = useState(initialScreenWidth);
  const { setSidebarState, insetApplication, offsetTop } = useLayout();

  const sidebarCanBeFixed = useMemo(() => {
    return screenWidth >= breakpoint;
  }, [screenWidth]);

  const sidebarStyle = useMemo(() => {
    // sidebar is hidden and should not be visible
    if (isHidden && !isHover) {
      return {
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: sidebarWidth,
        left: -defaultWidth,
        top: 64,
        height: "calc(100dvh - 74px)",
        borderRadius: "0.25rem",
        overflow: "hidden",
        transition: "0.155s cubic-bezier(.36,-0.01,0,.77)",
        zIndex: 10,
      };
    }
    // sidebar is hidden and should show as overlay
    if (isHidden && isHover) {
      return {
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: defaultWidth,
        left: 8,
        top: 64,
        height: "calc(100dvh - 74px)",
        borderRadius: "0.25rem",
        overflow: "hidden",
        transition: "0.155s cubic-bezier(.36,-0.01,0,.77)",
        zIndex: 13, // make sure it is above the backdrop and the active corner overlay
      };
    }

    // sidebar is permanently visible
    return {
      minWidth: minWidth,
      maxWidth: maxWidth,
      width: sidebarWidth,
      left: 0,
      top: 0,
      transition: "0s cubic-bezier(.36,-0.01,0,.77)",
      zIndex: 10,
    };
  }, [isHidden, isHover, sidebarWidth]);

  function hideSidebar() {
    setIsHidden(true);
    setIsHover(false);
    setSidebarWidth(0);
  }

  function showSidebar() {
    setIsHidden(false);
    setIsHover(false);
    setSidebarWidth(defaultWidth);
  }

  function showSidebarOverlay() {
    setIsHover(true);
  }

  function hideSidebarOverlay() {
    setIsHover(false);
  }

  const startResizing = useCallback(() => {
    if (isHidden) {
      // if the sidebar is hidden, we don't want to start resizing
      return;
    }
    setIsResizing(true);
  }, [isHidden]);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (event: MouseEvent) => {
      if (!isResizing) {
        return;
      }
      if (!sidebarRef.current) {
        return;
      }

      const translatedWidth = event.clientX - sidebarRef.current.getBoundingClientRect().left;

      // Hide sidebar if we are getting close to the edge
      if (translatedWidth < 50) {
        setIsResizing(false);
        hideSidebar();
        return;
      }

      // otherwise just minimize until minWidth
      const sideBarWidth = Math.min(maxWidth, Math.max(minWidth, translatedWidth));
      setSidebarWidth(sideBarWidth);
    },
    [isResizing],
  );

  const handleWindowResize = () => {
    const { innerWidth: width } = window;

    if (width < breakpoint) {
      hideSidebar();
    }
    setScreenWidth(width);
  };

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    handleWindowResize(); // call initially on first component load
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSidebarState({
      sidebarWidth: sidebarWidth,
      isFixed: !isHidden,
      isVisible: !isHidden || isHover,
    });
  }, [setSidebarState, sidebarWidth, isHidden, isHover]);

  return (
    <div>
      <div style={{ width: isHidden ? 0 : sidebarWidth }}></div>
      {/* Overlay across the whole screen when the sidebar temporarily shown */}
      {/* ------------------------------------------------------------------ */}
      {isHidden && isHover && (
        <div
          className="backdrop-blur-sm fixed bottom-0 left-0 right-0 top-0 bg-foreground/50"
          style={{ zIndex: 11 }}
          onMouseEnter={hideSidebarOverlay}
        ></div>
      )}
      {/* ------------------------------------------------------------------ */}

      {/* The actual sidebar and its content */}
      {/* ------------------------------------------------------------------ */}
      <aside
        ref={sidebarRef}
        onMouseDown={(e) => e.preventDefault()}
        className={`fixed right-0  h-screen ${isHover ? "shadow-lg border border-solid" : ""} `}
        style={sidebarStyle}
      >
        <nav
          className={cn([
            "relative flex h-full min-h-screen shrink-0 grow flex-col",
            isHover ? "bg-background" : "bg-canvas",
          ])}
          style={{
            minHeight: isHidden ? `calc(100dvh - 64px - 12px - ${offsetTop}px)` : "100dvh",
            paddingTop: `${insetApplication + offsetTop}px`,
          }}
        >
          {children}
        </nav>
        {/*  The handle with which the sidebar can be resized */}
        {/* ------ */}
        {/*
           The outer div defines whether the the handle is shown on hover. 
           - It is larger than the handle itself, to make it easier to see when hovering close to it. 
           - While resizing it make it even larger, to account vor drag velocity and don't change the cursor, if the user overshoots.  

           The inner div is the actual handle you see, It is smaller and a div (not border) to we can set a corner radius.
        */}
        <div
          onMouseDown={startResizing}
          onClick={hideSidebar}
          className={cn([
            "group absolute flex w-[12px] cursor-col-resize resize-x flex-col items-center bg-transparent ",
            isResizing ? "w-[64px] cursor-col-resize" : "",
            isHidden ? "cursor-pointer" : "",
          ])}
          style={{
            right: isResizing ? "-32px" : "-6px",
            top: `${isHidden && isHover ? 0 : insetApplication + offsetTop + 1}px`,
            bottom: `${isHidden && isHover ? 0 : insetApplication + 1}px`,
          }}
        >
          <div
            className={cn([
              "h-full w-[2px] rounded-xl bg-transparent group-hover:bg-muted-foreground",
              isResizing ? "bg-muted-foreground" : "",
              isHidden && isHover ? " w-[4px]" : "",
            ])}
          ></div>
        </div>
        {/* ------ */}
      </aside>
      {/* ------------------------------------------------------------------ */}

      {/* The icon to open or close the sidebar in the headerm depending on different sidebar states */}
      {/* ------------------------------------------------------------------ */}

      {/*  1. The sidebar is currently hidden and it could be fixed. */}
      {/* ------ */}
      {/* 
          - This is the case when the user has actively hidden the sidebar on a large screen 
          - We want to show an icon in the header.
          - When the user [hovers] over the icon, the sidebar is shown as overlay.
          - When the user [clicks] on the icon, it is fixed again.  [<-- this behavior is different from the second case]
      */}
      {isHidden && sidebarCanBeFixed && (
        <div
          className={cn([
            `fixed left-0 ml-4 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center md:ml-6`,
          ])}
          style={{ top: `${insetApplication + offsetTop}px`, zIndex: 13 }}
          onMouseEnter={showSidebarOverlay}
          onClick={showSidebar}
        >
          <SidebarIcon className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {/* ------ */}

      {/*  2. The sidebar is currently hidden and it cannot not be fixed. */}
      {/* ------ */}
      {/* 
          - This is the case when the user is on a small screen. The user cannot fix the sidebar.
          - We want to show an icon in the header.
          - When the user [hovers] over the icon, the sidebar is shown as overlay.
          - When the user [clicks] the sidebar is closed again [<-- this behavior is different from the first case]
      */}
      {isHidden && !sidebarCanBeFixed && (
        <div
          className={`fixed left-0 top-0 ml-4 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center md:ml-6`}
          onClick={() => (!isHover ? showSidebarOverlay() : hideSidebarOverlay())}
          style={{ top: `${insetApplication + offsetTop}px`, zIndex: 13 }}
        >
          <SidebarIcon className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {/* ------------------------------------------------------------------ */}

      {/* An invisible overlay at the edge of the screen (if the sidebar is hidden). If the mouse of the user enters it, the sidebar is being shown as overlay. */}
      {/* ------------------------------------------------------------------ */}
      {isHidden && (
        <div
          className={`fixed bottom-0 left-0 top-0 flex h-full flex-col justify-center`}
          style={{ width: isHover ? defaultWidth + 24 : 10, zIndex: 12 }} // +24 some extra in case the user slightly overshoots the edge
          onMouseEnter={showSidebarOverlay}
        />
      )}
      {/* ------------------------------------------------------------------ */}
    </div>
  );
};

export default Sidebar;
