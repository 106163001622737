import { z } from "zod";

// zodObjectIdentity is a helper because zod evaluates .unknown() as optional, but the value is not optional.
// https://github.com/colinhacks/zod/issues/1628#issuecomment-1336678982
export type ZodObjectIdentity<T extends z.ZodRawShape, Side extends "_input" | "_output"> = {
  [K in keyof T]: T[K][Side];
};

export function zodObjectIdentity<T extends z.ZodRawShape>(
  t: T,
): z.ZodObject<T, "strip", z.ZodTypeAny, ZodObjectIdentity<T, "_output">, ZodObjectIdentity<T, "_input">> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return z.object(t);
}

export const tableConfigurationSchema = z.object({
  climateActions: z
    .object({
      columnFilters: z.array(
        zodObjectIdentity({
          id: z.string(),
          value: z.unknown(),
        }),
      ),
      columnVisibility: z.record(z.boolean()),
      columnOrder: z.array(z.string()),
      sorting: z.array(
        z.object({
          id: z.string(),
          desc: z.boolean(),
        }),
      ),
      expanded: z.union([z.literal(true), z.record(z.boolean())]),
      rowSelection: z.record(z.boolean()),
      globalFilter: z.string(),
    })
    .optional(),
});

export const savedViewSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  url: z.string(),
  name: z.string(),
  tableConfiguration: tableConfigurationSchema,
  isDefault: z.boolean(),
  isBaseView: z.boolean().optional(),
  userId: z.string().optional().nullable(),
});

export const savedViewInputSchema = savedViewSchema.omit({ id: true, userId: true }).extend({
  isDefault: z.boolean().default(false),
});

export type TableConfiguration = z.infer<typeof tableConfigurationSchema>;
export type SavedView = z.infer<typeof savedViewSchema>;
export type SavedViewInput = z.infer<typeof savedViewInputSchema>;
