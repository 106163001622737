import { FC, useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import * as z from "zod";

import { toast } from "@/components/_ui/use-toast";
import { RichTextEditor } from "@/components/rich-text-editor";
import { useUpdateClimateAction } from "@/hooks/api/common/useClimateAction/useUpdateClimateAction";
import { zodResolver } from "@hookform/resolvers/zod";

enum SyncState {
  SAVING = "speichert ...",
  SAVED = "gespeichert",
  UNSAVED_CANGES = "ungesicherte Änderungen",
}

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export interface ClimateActionEditFormProps {
  className?: string;
  climateActionFormData: {
    id: string;
    title: string;
    properties?: string | null | undefined;
    parentId?: string | null | undefined;
    teamInstanceList: {
      id: string;
      teamId: string;
    }[];
  };
}
export const ClimateActionEditForm: FC<ClimateActionEditFormProps> = ({ climateActionFormData }) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [syncState, setSyncState] = useState<SyncState | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: climateActionFormData.title,
      description: climateActionFormData.properties ?? "",
    },
  });

  const { updateClimateAction } = useUpdateClimateAction({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveChanges = useCallback(
    debounce(() => {
      saveChanges();
    }, 1500),
    [],
  );

  const initialEditorState = useMemo(() => {
    if (!climateActionFormData.properties) {
      return undefined;
    }
    if (climateActionFormData.properties === "") {
      return undefined;
    }
    return climateActionFormData.properties;
  }, [climateActionFormData.properties]);

  async function handleFormChange() {
    setDirty(true);
    setSyncState(SyncState.UNSAVED_CANGES);
    debouncedSaveChanges();
  }

  async function onRichTextEditorChange(value: string, json: string) {
    if (value === "") {
      form.setValue("description", "");
    } else {
      form.setValue("description", json);
    }
    handleFormChange();
  }

  async function saveChanges() {
    setDirty(false);
    setSyncState(SyncState.SAVING);
    await updateClimateAction({
      variables: {
        input: {
          id: climateActionFormData.id,
          title: form.getValues("title"),
          properties: form.getValues("description"),
          teamIdList: climateActionFormData.teamInstanceList.map((tca) => tca.teamId),
        },
      },
    });
  }

  async function onUpdateCompleted() {
    if (!dirty) {
      setSyncState(SyncState.SAVED);
    }
  }

  async function onUpdateError() {
    if (!dirty) {
      setSyncState(SyncState.UNSAVED_CANGES);
    }
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <form onChange={handleFormChange}>
      <div className="grid w-full gap-10">
        <div className="mx-auto w-full">
          <TextareaAutosize
            autoFocus
            id="title"
            defaultValue={"Neue Maßnahme"}
            placeholder="Name der Maßnahme"
            className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent p-3 text-xl focus:outline-none"
            {...form.register("title")}
          />
          <RichTextEditor onChange={onRichTextEditorChange} initialValue={initialEditorState} />
          <div className="ml-4 flex flex-row justify-start italic">
            <div className="text-xxs text-muted-foreground">{syncState ?? " "}</div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ClimateActionEditForm;
