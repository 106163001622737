import { FaDatabase } from "react-icons/fa6";
import { Outlet } from "react-router-dom";

import FocusAreaView from "@/components/_domain/focusArea/FocusAreaView/FocusAreaView";
import { useCurrentFocusAreaContext } from "@/components/_domain/focusArea/hooks/useCurrentFocusAreaContext";
// Page Context
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import IconAllItems from "@/components/_icons/IconAllItems";
// Actual Page
import { HeaderLayout } from "@/components/_layout/header-layout";
import { SidebarNavElement } from "@/components/_layout/sidebar/sidebar-nav-element";
import { FeatureFlagToggle } from "@/components/feature-flag-toggle";
import { Link } from "@/components/link";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";

export const FocusAreaClimateActionListLayout = () => {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentFocusArea } = useCurrentFocusAreaContext();

  return (
    <div className="flex h-full max-h-full flex-col overflow-y-hidden">
      <HeaderLayout>
        <div className="flex w-full flex-row items-center justify-between  gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="flex max-w-[8rem] flex-row items-center gap-2 overflow-hidden truncate text-ellipsis whitespace-nowrap">
              <Link to={`/${currentWorkspace.workspaceUrl}/focus-areas/${currentFocusArea.id}`}>
                <FocusAreaView focusArea={currentFocusArea} logoOnly={false} />
              </Link>
              <FavoriteButton pageName="Meine Maßnahmen" />
            </div>
            <FeatureFlagToggle flag={"enable-climate-action-dashboard"}>
              <SidebarNavElement
                to={`/${currentWorkspace.workspaceUrl}/focus-areas/${currentFocusArea.id}/climate-actions/dashboard`}
                className="flex flex-row items-center [&>span]:h-6 [&>span]:gap-2 [&>span]:border [&>span]:p-2"
                end
              >
                <IconAllItems />
                Dashboard
              </SidebarNavElement>
            </FeatureFlagToggle>
            <SidebarNavElement
              to={`/${currentWorkspace.workspaceUrl}/focus-areas/${currentFocusArea.id}/climate-actions/all`}
              className="flex flex-row items-center [&>span]:h-6 [&>span]:gap-2 [&>span]:border [&>span]:p-2"
              end
            >
              <FaDatabase />
              Maßnahmen
            </SidebarNavElement>
          </div>
        </div>
      </HeaderLayout>
      <Outlet />
    </div>
  );
};
