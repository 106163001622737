import { ClimateAction } from "@/components/_domain/climate-action/models";
import { sortFocusArea } from "@/components/_domain/focusArea";
import { ColumnDef } from "@tanstack/react-table";

import { ClimateActionListCellFocusArea, ClimateActionListColumnHeader } from "../..";
import { filterByTextListWithUnknownPayload } from "../../filter-functions";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import { FilterByFocusAreaConfigurationView, FilterByFocusAreaDisplayView, FilterByFocusAreaFilterPaneView } from ".";

interface FocusAreaColumnDefinitionProps {
  workspaceId: string;
  columnId: string;
  orderValue: number;
  config: {
    hideText: boolean;
  };
}

export const FocusAreaColumnDefinition = ({
  workspaceId,
  columnId,
  orderValue,
  config,
}: FocusAreaColumnDefinitionProps): ClimateActionColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Handlungsfelder",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<ClimateAction> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          return sortFocusArea(
            rowA.original.focusAreaInstance?.focusArea?.type,
            rowB.original.focusAreaInstance?.focusArea?.type,
          );
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          return filterByTextListWithUnknownPayload(value, row.original.focusAreaInstance?.focusArea?.name);
        },
        size: 1, // set to 1 instead to 0 for it to work in Safari
        header: ({ column }) => (
          <ClimateActionListColumnHeader column={column} title="" className={`px-1 text-xs font-normal`} />
        ),
        cell: ({ row }) => (
          <ClimateActionListCellFocusArea
            row={row}
            workspace={{ id: workspaceId }}
            config={{ hideText: config.hideText }}
          />
        ),
      };
    },
    getDisplayView: () => <FilterByFocusAreaDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByFocusAreaConfigurationView column={{ id: columnId }} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "popover",
    getFilterPaneView: () => <FilterByFocusAreaFilterPaneView column={{ id: columnId }} />,
  };
};
