// Define a base interface that requires an id property
export interface Identifiable {
  id: string;
}

export interface ColumnIdentifiable {
  columnId?: string | null | undefined;
}

export interface Orderable {
  position: number;
}

export interface BoardItem extends Identifiable, ColumnIdentifiable, Orderable {}

/**
 * Board column interface with a generic type parameter.
 * _T is not directly used in the interface but enforces type relationships
 * when columns and items are used together in other components.
 * This ensures type safety when items are associated with columns.
 */
export interface BoardColumn<_T extends BoardItem> {
  id: string;
  title: string;
}

// Use the Identifiable interface as a constraint for generic types
export type BoardItemConfig<T extends BoardItem> = {
  data: T;
  isOverlay: boolean; // state when the card is lifted and dragged
  isOver: boolean; // state when the preview is rendered in a new columns while being dragged
};

export type BoardColumnConfig<T extends BoardItem> = {
  column: BoardColumn<T>;
  isHover: boolean;
};

// Drag data type constants
export const DRAG_TYPE = {
  COLUMN: "Column",
  ITEM: "Item",
} as const;

// Type for drag data types
export type DragDataType = (typeof DRAG_TYPE)[keyof typeof DRAG_TYPE];

// Type guard to check if a value is a valid drag data type
export function isDragDataType(value: unknown): value is DragDataType {
  return typeof value === "string" && Object.values(DRAG_TYPE).includes(value as DragDataType);
}

export type DragDataTypeColumn = typeof DRAG_TYPE.COLUMN;
export type DragDataTypeItem = typeof DRAG_TYPE.ITEM;

export interface ColumnDragData<T extends BoardItem> {
  type: DragDataTypeColumn;
  column: BoardColumn<T>;
}

export interface ItemDragData<T extends BoardItem> {
  type: DragDataTypeItem;
  item: T;
}

// Union type for all drag data
export type DraggableData<T extends BoardItem> = ColumnDragData<T> | ItemDragData<T>;
