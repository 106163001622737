export const PhaseList = {
  backlog: "Backlog",
  unstarted: "Unstarted",
  started: "Started",
  completed: "Completed",
  canceled: "Canceled",
} as const;

export const ALLOWED_PHASE_LIST = Object.values(PhaseList);
export type PhaseType = (typeof ALLOWED_PHASE_LIST)[number];

export const sortPhase = (a?: string, b?: string): 0 | 1 | -1 => {
  const numberFromType = (p?: string) => {
    if (p === PhaseList.backlog) return 0;
    if (p === PhaseList.unstarted) return 1;
    if (p === PhaseList.started) return 2;
    if (p === PhaseList.completed) return 3;
    if (p === PhaseList.canceled) return 4;
    return -1;
  };

  const numA = numberFromType(a);
  const numB = numberFromType(b);

  if (numA - numB > 0) {
    return 1;
  }
  if (numA - numB < 0) {
    return -1;
  }

  return 0;
};
