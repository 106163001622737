import { useMemo } from "react";

import { getLabelGroupQueryDocument } from "@/graphql/common/label-group";
import { useQuery } from "@apollo/client";

export interface UseGetLabelGroupProps {
  id?: string;
  enabled?: boolean;
}

export const useGetLabelGroup = ({ id, enabled = true }: UseGetLabelGroupProps) => {
  const queryResult = useQuery(getLabelGroupQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id || !enabled,
  });

  const labelGroup = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.labelGroup;
  }, [queryResult.data]);

  return {
    loading: queryResult.loading,
    error: queryResult.error,
    labelGroup,
    queryResult,
  };
};
