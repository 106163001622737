import { FC } from "react";

import { PhaseViewEmpty } from "./phase-view-empty";
import { PhaseView } from ".";

interface PhaseViewOptionalProps {
  phase?: {
    id: string;
  } | null;
  logoOnly?: boolean;
  className?: string;
}

export const PhaseViewOptional: FC<PhaseViewOptionalProps> = ({ phase, ...props }) => {
  if (!phase) {
    return <PhaseViewEmpty {...props} />;
  }

  return <PhaseView phase={phase} {...props} />;
};

export default PhaseViewOptional;
