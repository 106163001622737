import { type TypedDocumentNode } from "@apollo/client";

// Note: These are the only typesnames for which we have a configured configuration (see getQueryConfigForType)
// – If you add a new typename make sure to update the getQueryConfigForType function in the specific on-generic-entity-[action].ts files

export const EntityTypenNameList = ["Priority", "Phase", "FocusArea", "Label", "LabelGroup"] as const;
export type EntityTypename = (typeof EntityTypenNameList)[number];
export const EntityInstanceTypenameList = [
  "PriorityInstance",
  "PhaseInstance",
  "FocusAreaInstance",
  "LabelInstance",
] as const;
export type EntityInstanceTypename = (typeof EntityInstanceTypenameList)[number];
export const EntityOwnerTypenameList = ["CLIMATE_ACTION"] as const;
export type EntityOwnerTypename = (typeof EntityOwnerTypenameList)[number];

export interface TEntity {
  __typename: EntityTypename;
  id: string;
  workspaceId: string;
}

export interface MaybeTEntity extends Omit<TEntity, "__typename"> {
  __typename?: string;
}

export interface TEntityInstance {
  __typename: EntityInstanceTypename;
  __parentId: string; // the id of the parent entity (e.g. priorityId for a priority instance or workspaceId for a workspace instance)
  id: string;
  workspaceId: string;
  ownerId: string;
  ownerType: EntityOwnerTypename;
}

export interface MaybeTEntityInstance extends Omit<TEntityInstance, "__typename" | "ownerType"> {
  __typename?: string;
  ownerType: string;
}

export interface TEntityWithInstances extends TEntity {
  instances: TEntityInstance[];
}

/**
 * Type guard to check if an object is a TEntity with a specific typename
 * @param obj The object to check
 * @param typename Optional specific typename to check for
 * @returns True if the object is a TEntity (with the specified typename if provided)
 */
export function isTEntity(obj: MaybeTEntity): obj is TEntity {
  // Check if the object has the required properties
  if (typeof obj.__typename !== "string" || typeof obj.id !== "string" || typeof obj.workspaceId !== "string") {
    return false;
  }

  // If a specific typename was provided, check if it matches
  if (!EntityTypenNameList.includes(obj.__typename as EntityTypename)) {
    return false;
  }

  // Check if the typename is one of the allowed values
  return true;
}

/**
 * Type guard to check if an object is a TEntity with a specific typename
 * @param obj The object to check
 * @param typename Optional specific typename to check for
 * @returns True if the object is a TEntity (with the specified typename if provided)
 */
export function isTEntityInstance(obj: MaybeTEntityInstance): obj is TEntityInstance {
  // Check if the object has the required properties
  if (
    typeof obj.__typename !== "string" ||
    typeof obj.id !== "string" ||
    typeof obj.__parentId !== "string" ||
    typeof obj.ownerType !== "string" ||
    typeof obj.ownerId !== "string" ||
    typeof obj.workspaceId !== "string"
  ) {
    return false;
  }

  // If a specific typename was provided, check if it matches
  if (!EntityInstanceTypenameList.includes(obj.__typename as EntityInstanceTypename)) {
    return false;
  }

  // If a specific typename was provided, check if it matches
  if (!EntityOwnerTypenameList.includes(obj.ownerType as EntityOwnerTypename)) {
    return false;
  }

  // Check if the typename is one of the allowed values
  return true;
}

type TResult = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type TVariables = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

/**
 * Returns a correctly typed query configuration object for cache operations.
 * This configuration is used to make the actual cache query/write for different entity types.
 *
 * @param __typename - The type of entity being operated on (e.g., "Priority")
 * @param entity - The entity instance with required properties (id, workspaceId)
 * @param document - The GraphQL query document to use (e.g., workspaceByIdQueryDocument)
 * @param variables - The variables to be passed to the specific query (e.g., { input: { workspaceId: entity.workspaceId } })
 * @param key - The key on the queryResult to access the list of entities (e.g., "priorityListForWorkspace" for a result of data.priorityListForWorkspace)
 * @param key2 - An optional second key if needed for nested access (e.g., "priorityList" for data.workspaceById.priorityList for a result of data.workspaceById.priorityList [first key also needs to be set])
 * @returns A configuration object for cache operations or null if typename is not supported
 */
export function getEntityQueryConfig<
  T extends TEntity | TEntityInstance,
  R extends TResult,
  V extends TVariables,
  K extends string,
  I extends string | undefined,
>(
  __typename: EntityTypename,
  entity: T,
  document: TypedDocumentNode<R, V>,
  variables: V,
  key: K,
  key2: I,
): TEntityQueryConfig<R, V, K, I> | null {
  switch (__typename) {
    default:
      return {
        entity: entity,
        document: document,
        variables: variables,
        key: key,
        key2: key2,
      };
  }
}

export interface TEntityQueryConfig<
  R extends TResult,
  V extends TVariables,
  K extends string = string,
  I extends string | undefined = string | undefined,
> {
  entity: TEntity | TEntityInstance;
  document: TypedDocumentNode<R, V>;
  variables: V;
  key: K; // key on the query to access TEntity[]  (e.g. data.priorityListForWorkspace)
  key2: I; // second order key to access TEntity[]  (e.g. data.workspaceById.priorityList)
}

// Conditional type that changes shape based on whether instanceKey is defined
export type TEntityQueryResult<T extends TEntity, K extends string, I extends string | undefined> = I extends string
  ? { [P in K]: { [Q in I]: T[] } } // Nested structure when instanceKey is provided   (e.g. data.workspaceById.priorityList)
  : { [P in K]: T[] }; // (e.g. data.priorityListForWorkspace)

export interface TEntityInstanceQueryConfig<
  R extends TResult,
  V extends TVariables,
  K extends string = string,
  I extends string | undefined = string | undefined,
> {
  entity: TEntity | TEntityInstance;
  document: TypedDocumentNode<R, V>;
  variables: V;
  key: K; // key on the query to access TEntity[]  (e.g. data.priorityListForWorkspace)
  key2: I; // second order key to access TEntity[]  (e.g. data.workspaceById.priorityList)
}

// Conditional type that changes shape based on whether instanceKey is defined
export type TEntityInstanceQueryResult<
  T extends TEntity | TEntityInstance,
  K extends string,
  I extends string | undefined,
> = I extends string
  ? { [P in K]: { [Q in I]: T[] } } // Nested structure when instanceKey is provided   (e.g. data.workspaceById.priorityList)
  : { [P in K]: T[] }; // (e.g. data.priorityListForWorkspace)

export function getEntityInstanceQueryConfig<
  T extends TEntity | TEntityInstance,
  R extends TResult,
  V extends TVariables,
  K extends string,
  I extends string | undefined,
>(
  __typename: EntityInstanceTypename,
  entity: T,
  document: TypedDocumentNode<R, V>,
  variables: V,
  key: K,
  key2: I,
): TEntityInstanceQueryConfig<R, V, K, I> | null {
  switch (__typename) {
    default:
      return {
        entity: entity,
        document: document,
        variables: variables,
        key: key,
        key2: key2,
      };
  }
}
