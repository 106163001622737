import { FC, PropsWithChildren } from "react";

import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import { Button } from "@/components/_ui/button";

import { WorkspaceDeleteDialogWithEmail } from "./workspace-delete-dialog-email";

export interface WorkspaceDeleteDialogWithEmailButton extends PropsWithChildren {
  workspace: Workspace;
  className?: string;
  redirectAfterDelete?: boolean;
}

export const WorkspaceDeleteDialogWithEmailButton: FC<WorkspaceDeleteDialogWithEmailButton> = ({
  className,
  workspace,
  redirectAfterDelete,
}) => {
  return (
    <WorkspaceDeleteDialogWithEmail
      className={className}
      workspace={workspace}
      asChild={true}
      redirectAfterDelete={redirectAfterDelete}
    >
      <Button variant="destructive">Workspace löschen</Button>
    </WorkspaceDeleteDialogWithEmail>
  );
};
