import { FC } from "react";

interface ClimateActionOverviewTabProps {
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
}

export const ClimateActionOverviewTab: FC<ClimateActionOverviewTabProps> = () => {
  return <main className="relative place-items-stretch overflow-auto">Overview</main>;
};
