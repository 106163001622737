import { FC } from "react";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import { LabelView } from "@/components/_domain/labels";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";
import { cn } from "@/lib/utils";
import { Row } from "@tanstack/react-table";

interface ClimateActionListCellLabelCellProps {
  row: Row<ClimateAction>;
  className?: string;
}

export const ClimateActionListCellLabelCell: FC<ClimateActionListCellLabelCellProps> = ({ row, className }) => {
  const labelInstanceList = row.original.labelInstanceList;
  const filteredlabelInstanceList = labelInstanceList.filter((li) => !li.labelGroupId);

  if (!filteredlabelInstanceList.length) {
    return <></>;
  }

  if (filteredlabelInstanceList.length === 1) {
    return (
      <div className="w-min rounded-full border-[0.5px] bg-background px-1.5 py-0.5">
        <LabelView label={{ id: filteredlabelInstanceList[0].labelId }} />
      </div>
    );
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div
          className={cn(
            [
              "flex w-min shrink-0 flex-row items-center gap-1 rounded-full border-[0.5px] bg-background px-1.5 py-0.5 ",
            ],
            className,
          )}
        >
          <div className="flex flex-row items-center ">
            {filteredlabelInstanceList.map((labelInstance) => {
              return (
                <LabelView
                  key={labelInstance.id}
                  label={{ id: labelInstance.labelId }}
                  logoOnly={true}
                  className="-m-0.5 rounded-full border-[0.5px] border-background"
                />
              );
            })}
          </div>
          <div
            className="shrink-0 pr-1"
            style={{ marginRight: `${filteredlabelInstanceList.length * 0.125}rem` }}
          >{`${filteredlabelInstanceList.length} Label`}</div>
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="top" align="start" className="w-auto p-3">
        <div className="flex w-min shrink-0 flex-col items-start gap-2 text-sm">
          {filteredlabelInstanceList.map((labelInstance) => {
            return (
              <div key={labelInstance.id} className="rounded-full border-[0.5px] bg-background px-1.5 py-0.5">
                <LabelView label={{ id: labelInstance.labelId }} />
              </div>
            );
          })}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
