import { FC, useState } from "react";

import { FocusAreaIcon } from "@/components/_domain/focusArea";
import IconPen from "@/components/_icons/IconPen";
import { Button } from "@/components/_ui/button";
import { useGetFocusArea } from "@/hooks/api/common/useFocusArea";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";

import { FocusAreaButtonDelete } from "./actions/focus-area-button-delete";
import { FocusAreaFormEdit } from "./actions/focus-area-form-edit";
import { InstanceCountLabel } from "./instance-count-label";

export interface FocusAreaTableRowProps {
  workspace: {
    id: string;
  };
  focusArea: {
    id: string;
  };
  hideDeleteAction?: boolean;
}

export const FocusAreaTableRow: FC<FocusAreaTableRowProps> = ({ hideDeleteAction, workspace, ...props }) => {
  const [showEditMode, setShowEditMode] = useState(false);

  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspace.id,
    action: "manage",
  });
  const { focusArea } = useGetFocusArea({ id: props.focusArea.id });

  function handleEditFocusAreaButtonPressed() {
    setShowEditMode(true);
  }

  function onSuccess() {
    setShowEditMode(false);
  }

  function onCanceled() {
    setShowEditMode(false);
  }

  if (!focusArea) {
    return <></>;
  }

  if (showEditMode) {
    return (
      <div className="my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2">
        <FocusAreaFormEdit focusArea={focusArea} onSuccess={onSuccess} onCanceled={onCanceled} />
      </div>
    );
  }

  return (
    <div key={focusArea.id} className="mb-1 rounded border">
      <div className="m-2 flex flex-row items-center gap-2">
        <div>
          <FocusAreaIcon focusArea={{ type: focusArea.type, color: focusArea.color }} />
        </div>
        <div>{focusArea.name}</div>
        <InstanceCountLabel instances={focusArea.assignedInstanceList} className="text-muted-foreground" />
        <div className="grow"></div>
        {hasPermissionToManageWorkspace && (
          <>
            <div>
              <Button
                variant="ghost"
                size="icon"
                className="text-muted-foreground hover:text-foreground/80"
                onClick={handleEditFocusAreaButtonPressed}
                aria-label="Handlungsfeld bearbeiten"
              >
                <IconPen className="h-3 w-3" />
              </Button>
            </div>
            {!hideDeleteAction && <FocusAreaButtonDelete workspace={workspace} focusArea={focusArea} />}
          </>
        )}
      </div>
    </div>
  );
};

export default FocusAreaTableRow;
