import { FC } from "react";

import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";

import { ProgressSnapshotEventList } from "./progress-snapshot-event-list";
interface ClimateActionEventFeedProps {
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
}

export const ClimateActionEventFeed: FC<ClimateActionEventFeedProps> = (props) => {
  const { climateAction } = useGetClimateAction({ id: props.climateAction.id });

  if (!climateAction) {
    return <></>;
  }

  return (
    <div className="relative flex flex-col items-start overflow-hidden pt-6">
      <ProgressSnapshotEventList {...props} climateAction={climateAction} />
    </div>
  );
};
