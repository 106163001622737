import { FC, ReactNode, useMemo, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import * as z from "zod";

import { type Workspace } from "@/components/_domain/workspace/models/workspace-model";
import { Button } from "@/components/_ui/button";
import { Switch } from "@/components/_ui/switch";
import { toast } from "@/components/_ui/use-toast";
import { RichTextEditor } from "@/components/rich-text-editor";

import { useCreateIndicator } from "../../hooks/useIndicator/useCreateIndicator";
import { type IndicatorType, IndicatorTypeSchema } from "../../models/indicator-type.model";

import { IndicatorHeader } from "./components/indicator-form-header";
import { IndicatorFormTypeInput } from "./components/indicator-form-type-input";
import { IndicatorFormUnitInput } from "./components/indicator-form-unit-input";
import {
  IndicatorCreateContextProvider,
  useIndicatorCreateContext,
  useUnsafeIndicatorCreateContext,
} from "./hooks/useIndicatorCreateContext";

const indicatorCreateFormSchema = z.object({
  title: z.string().min(1, "Name des Indikators"),
  type: z
    .string()
    .min(1, "Kategorie")
    .refine(
      (val): val is IndicatorType => Object.values(IndicatorTypeSchema.enum).includes(val as IndicatorType),
      "Bitte wählen Sie eine gültige Kategorie aus der Liste",
    ),
  description: z.string().optional(),
  unitId: z.string().min(1, "Einheit"),
  workspaceId: z.string().min(1, "Workspace ID"),
});

function formatValidationErrors(error: z.ZodError): ReactNode {
  const typeError = error.errors.find((err) => err.path.includes("type") && err.message.includes("gültige Kategorie"));
  const typeEmpty = error.errors.find((err) => err.path.includes("type") && !err.message.includes("gültige Kategorie"));

  const otherErrors = error.errors.filter((err) => !err.path.includes("type"));

  // Sammle die Feldnamen für die normale Validierung
  const missingFields = otherErrors.map((err) => err.message);
  if (typeEmpty) {
    missingFields.push(typeEmpty.message);
  }

  let message: ReactNode;

  // Create the base message
  if (missingFields.length === 0) {
    message = "Bitte überprüfen Sie Ihre Eingaben.";
  } else if (missingFields.length === 1) {
    message = `Bitte geben Sie ${missingFields[0]} an.`;
  } else {
    const lastField = missingFields[missingFields.length - 1];
    message = `Bitte geben Sie folgende Felder an: ${missingFields.slice(0, -1).join(", ")} und ${lastField}.`;
  }

  // If the there is a type error and the type is not in the missing fields, add the type error to the message
  if (typeError && !missingFields.includes("Kategorie")) {
    return (
      <div className="flex flex-col gap-2">
        <div>{message}</div>
        <div>{typeError.message}</div>
      </div>
    );
  }

  return message;
}

export type IndicatorCreateForm = z.infer<typeof indicatorCreateFormSchema>;

export interface IndicatorCreateFormProps {
  className?: string;
  workspace: Workspace;
  onSuccess: () => void;
}

export const InnerIndicatorCreateForm: FC<IndicatorCreateFormProps> = ({ workspace, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [removedFromDom, setRemovedFromDom] = useState(false);
  const [createMore, setCreateMore] = useState(false);
  const { newIndicatorForm, resetNewIndicatorForm, updateNewIndicator } = useIndicatorCreateContext();

  const { createIndicator } = useCreateIndicator({
    workspaceId: workspace.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  const initialEditorState = useMemo(() => {
    if (!newIndicatorForm.description) {
      return undefined;
    }
    if (newIndicatorForm.description === "") {
      return undefined;
    }
    return newIndicatorForm.description;
  }, [newIndicatorForm]);

  async function onRichTextEditorChange(value: string, json: string) {
    if (value === "") {
      updateNewIndicator({ description: "" });
    } else {
      updateNewIndicator({ description: json });
    }
  }

  async function handleCreateIndicator() {
    if (loading) {
      return;
    }

    const result = indicatorCreateFormSchema.safeParse({
      ...newIndicatorForm,
      unitId: newIndicatorForm.unitId || "",
    });

    if (!result.success) {
      toast({
        title: "Fehlende Angaben",
        description: formatValidationErrors(result.error),
        variant: "error",
      });
      return;
    }

    setLoading(true);
    await createIndicator({
      variables: {
        input: result.data,
      },
    });
  }

  async function onUpdateCompleted() {
    setLoading(false);
    toast({
      title: "Indikator erstellt",
      description: (
        <div className="flex flex-col gap-2">
          <div>
            <span>Der Indikator</span>
            <span className="font-medium">{` ${newIndicatorForm.title} `}</span>
            <span>wurde erstellt.</span>
          </div>
        </div>
      ),
      variant: "success",
    });

    if (createMore) {
      resetNewIndicatorForm({ title: true, description: true, type: false, unitId: false });
      const titleInput = document.querySelector('textarea[name="title"]') as HTMLTextAreaElement;
      if (titleInput) {
        titleInput.focus();
      }
    } else {
      resetNewIndicatorForm();
      onSuccess();
    }

    forceRichTextEditorRerender();
  }

  async function onUpdateError() {
    setLoading(false);
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  function forceRichTextEditorRerender() {
    // generate a new key to force the text editor to rerender
    setRemovedFromDom((prev) => !prev);
  }

  return (
    <form>
      <div className="grid w-full py-4">
        <IndicatorHeader workspace={workspace} />
        <div className="mx-auto max-h-[70vh] w-full overflow-y-scroll pl-1 pr-3 md:max-h-[40vh]">
          <TextareaAutosize
            autoFocus
            name="title"
            placeholder="Name des Indikators"
            value={newIndicatorForm.title}
            className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent px-3 py-0 text-lg focus:outline-none"
            onChange={(e) => updateNewIndicator({ title: e.target.value })}
          />
          <RichTextEditor
            key={`rich-text-editor-${removedFromDom}`} // This is a workaround to force the text editor to rerender when the form content changes
            hideFloatingMenu={false}
            placeholder="Beschreiben Sie den Indikator..."
            initialValue={initialEditorState}
            onChange={onRichTextEditorChange}
          />
        </div>
        <div className="flex flex-row items-center gap-2 px-3 py-2">
          <IndicatorFormTypeInput
            value={newIndicatorForm.type as IndicatorType}
            onValueChange={(value: IndicatorType | null) => {
              updateNewIndicator({ type: value ?? "" });
            }}
          />
          <IndicatorFormUnitInput
            value={newIndicatorForm?.unitId}
            onValueChange={(value: string | null) => {
              updateNewIndicator({ unitId: value ?? "" });
            }}
            workspaceId={workspace.id}
          />
        </div>
        <div className="border-b"></div>
        <div className="flex flex-row items-center justify-end gap-4 px-3 pt-2">
          <div>
            <div className="flex items-center space-x-1 text-sm text-muted-foreground">
              <Switch id="create-more" size="sm" checked={createMore} onCheckedChange={setCreateMore} />
              <span>Mehr erstellen</span>
            </div>
          </div>
          <Button type="button" size="xs" onClick={handleCreateIndicator} disabled={loading}>
            {loading ? "Wird erstellt..." : "Indikator erstellen"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export const IndicatorCreateForm: FC<IndicatorCreateFormProps> = ({ workspace, onSuccess }) => {
  // is "unsafe" because it returns a nullable value. We use this to optionally initialize the context
  // at a higher point in the component tree
  const indicatorCreateContext = useUnsafeIndicatorCreateContext();

  if (!indicatorCreateContext) {
    return (
      <IndicatorCreateContextProvider workspaceId={workspace.id}>
        <InnerIndicatorCreateForm workspace={workspace} onSuccess={onSuccess} />
      </IndicatorCreateContextProvider>
    );
  }

  return <InnerIndicatorCreateForm workspace={workspace} onSuccess={onSuccess} />;
};
