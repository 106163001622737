import { FC, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";
import { useGetFocusAreaList } from "@/hooks/api/common/useFocusArea";

import { FocusAreaSelectDropdownItem } from "./FocusAreaSelectDropdownItem";

const INVALID_ID = "-1";

export interface FocusAreaSelectDropdownProps extends PropsWithChildren {
  workspace: {
    id: string;
  };
  asChild?: boolean;
  selected?: {
    focusAreaId: string;
  } | null;
  onSelect?: (focusArea: { id: string } | null) => void;
  side?: "top" | "right" | "bottom" | "left";
}

export const FocusAreaSelectDropdown: FC<FocusAreaSelectDropdownProps> = ({
  workspace,
  children,
  side,
  asChild,
  onSelect,
  ...props
}) => {
  const [selectedFocusArea, setSelectedFocusArea] = useState(props.selected ? props.selected : null);
  const { focusAreaList } = useGetFocusAreaList({ workspaceId: workspace.id });

  const comboboxOptions = useMemo(() => {
    const focusAreaOptions = focusAreaList
      .slice()
      .sort((a, b) => {
        return a.order - b.order;
      })
      .flatMap((u) => {
        if (!u) {
          return [];
        }
        return {
          id: u.id,
          value: u.name,
        };
      });
    return [{ id: INVALID_ID, value: "Kein Handlungsfeld" }].concat(focusAreaOptions);
  }, [focusAreaList]);

  const renderItem = (option: ComboboxOption): ReactElement => {
    const focusArea = focusAreaList.find((s) => s.id === option.id);

    const selected =
      (option.id === INVALID_ID && selectedFocusArea === null) || selectedFocusArea?.focusAreaId === option.id;
    return <FocusAreaSelectDropdownItem focusArea={focusArea} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.id === INVALID_ID) {
      setSelectedFocusArea(null);
      if (onSelect) {
        onSelect(null);
      }
      return;
    }

    setSelectedFocusArea({ focusAreaId: selectedOption.id });
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  useEffect(() => {
    if (props.selected) {
      setSelectedFocusArea(props.selected);
    }
  }, [props.selected]);

  if (!comboboxOptions) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      side={side}
    >
      {children}
    </Combobox>
  );
};
