import { FC } from "react";

import IconDashesHorizontal from "@/components/_icons/IconDashesHorizontal";
import { cn } from "@/lib/utils";

interface PhaseViewEmptyProps {
  logoOnly?: boolean;
  className?: string;
}

export const PhaseViewEmpty: FC<PhaseViewEmptyProps> = (props) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <IconDashesHorizontal className={cn(["h-4 w-4", props.className])} />
      {!props.logoOnly && <div>Nicht zugewiesen</div>}
    </div>
  );
};

export default PhaseViewEmpty;
