import { FC, useCallback, useContext, useMemo, useState } from "react";

import { ProgressSnapshot } from "@/components/_domain/progress-snapshot/progress-snapshots.schema";
import IconSquare from "@/components/_icons/IconSquare";
import IconSquareCheck from "@/components/_icons/IconSquareCheck";
import { useDate } from "@/hooks/useDate";
import { SelectedThemeAppearance, ThemeContext } from "@/hooks/useTheme";
import { cn } from "@/lib/utils";
import { Glyph as CustomGlyph } from "@visx/glyph";
import { Group } from "@visx/group";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { scaleLinear } from "@visx/scale";
import { AreaClosed, Circle, LinePath } from "@visx/shape";
import { Tooltip, TooltipWithBounds, useTooltip, useTooltipInPortal } from "@visx/tooltip";
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedLineSeries,
  XYChart,
} from "@visx/xychart";

import { MilestoneKeyResult } from "../models/key-result.schema";

import { themeDark, themeLight } from "./chart-theme";
import { getDateRangeAtFraction, makeDateFormatter } from "./chart-utils";

export type TooltipProps = {
  width: number;
  height: number;
  showControls?: boolean;
};

interface TooltipData {
  position: {
    x: number;
    y: number;
  };
  date: Date;
  value: number;
}

interface Row {
  x: Date;
  y: number;
}

interface Point {
  x: number;
  y: number;
}

interface KeyResultChartMilestoneProps {
  keyResult: MilestoneKeyResult;
  progressSnapshots: ProgressSnapshot[];
}

interface KeyResultChartMilestonePropsInternal extends KeyResultChartMilestoneProps {
  parentSizeState: {
    width: number;
    height: number;
    top: number;
    left: number;
  };
}

export const KeyResultChartMilestone: FC<KeyResultChartMilestoneProps> = (props) => {
  return (
    <ParentSize>
      {(parentSizeState) => {
        return <KeyResultChartMilestoneInternal parentSizeState={parentSizeState} {...props} />;
      }}
    </ParentSize>
  );
};

const _margin = {
  top: 25,
  right: 10,
  bottom: 30,
  left: 5,
};

export const KeyResultChartMilestoneInternal: FC<KeyResultChartMilestonePropsInternal> = ({
  keyResult,
  progressSnapshots,
  parentSizeState,
}) => {
  const { TODAY } = useDate();
  const { selectedThemeAppearance } = useContext(ThemeContext);
  const [tooltipShouldDetectBounds] = useState(true);
  const [renderTooltipInPortal] = useState(false);
  const [tooltipIsShown, setTooltipIsShown] = useState(false);
  const [pointerCoordinate, setPointerCoordinate] = useState<Point>({ x: 0, y: 0 });

  const configuration = useMemo(() => {
    const startDate = new Date(keyResult.startDate);
    const targetDate = new Date(keyResult.targetDate);

    const divisor = keyResult.targetValue - keyResult.initialValue;
    const progress = divisor === 0 ? 0 : (keyResult.currentValue - keyResult.initialValue) / divisor;

    const yMin = Math.min(keyResult.initialValue, keyResult.targetValue); //keyResult.initialValue < keyResult.targetValue ? keyResult.initialValue : keyResult.targetValue;
    const yMax = Math.max(keyResult.initialValue, keyResult.targetValue); //keyResult.initialValue < keyResult.targetValue ? keyResult.targetValue : keyResult.initialValue;

    const xMin = startDate;
    const xMax = targetDate;

    const tickValuesCalculatorX = () => {
      return [xMin, xMax];
    };

    const milestoneCoordinates = keyResult.properties.milestones.map((m, i) => {
      const progress = (i + 1) / keyResult.properties.milestones.length; // [0,1]

      const xValue = getDateRangeAtFraction(startDate, targetDate, progress);
      const yValue = i + 1;

      return { x: xValue, y: yValue, milestone: m };
    });

    const progressCoordinates = progressSnapshots
      .slice()
      .sort((a, b) => {
        const dateA = new Date(a.snapshotDate);
        const dateB = new Date(b.snapshotDate);
        return dateA.getTime() - dateB.getTime();
      })
      .flatMap((ps) => {
        if (ps.value === undefined || ps.value === null) {
          return [];
        }
        const xValue = new Date(ps.snapshotDate);
        const yValue = ps.value;

        return [{ x: xValue, y: yValue, progressSnapshot: ps }];
      });

    return {
      position: {
        width: parentSizeState.width,
        height: parentSizeState.height,
        parent: {
          width: parentSizeState.width,
          height: parentSizeState.height,
          left: parentSizeState.left,
          top: parentSizeState.top,
        },
        margin: _margin,
        inner: {
          width: Math.max(0, parentSizeState.width - _margin.left - _margin.right),
          height: Math.max(0, parentSizeState.height - _margin.top - _margin.bottom),
        },
      },
      axis: {
        x: {
          min: xMin,
          max: xMax,
          ticks: tickValuesCalculatorX(),
        },
        y: {
          min: yMin,
          max: yMax,
        },
      },
      data: {
        initial: { x: startDate, y: keyResult.initialValue },
        target: { x: targetDate, y: keyResult.targetValue },
        current: { x: TODAY < targetDate ? TODAY : targetDate, y: keyResult.currentValue },
        predicted: [
          { x: startDate, y: keyResult.initialValue },
          { x: targetDate, y: keyResult.targetValue },
        ],
        completed: [
          { x: startDate, y: keyResult.initialValue },
          { x: getDateRangeAtFraction(startDate, targetDate, progress), y: keyResult.currentValue },
        ],
        lower80: [
          { x: startDate, y: keyResult.initialValue },
          { x: targetDate, y: (keyResult.targetValue - keyResult.initialValue) * 0.8 + keyResult.initialValue },
        ],
        upper80: [
          { x: startDate, y: keyResult.initialValue },
          { x: targetDate, y: (keyResult.targetValue - keyResult.initialValue) * 1.2 + keyResult.initialValue },
        ],
        milestones: milestoneCoordinates,
        progressSnapshots: progressCoordinates,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyResult, parentSizeState]);

  const xScale = scaleLinear({
    range: [0, configuration.position.inner.width],
    domain: [configuration.axis.x.min, configuration.axis.x.max],
    zero: false,
  });

  const yScale = scaleLinear({
    range: [0, configuration.position.inner.height],
    domain: [configuration.axis.y.min, configuration.axis.y.max],
    zero: false,
  });

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: tooltipShouldDetectBounds,
  });

  const {
    showTooltip,
    tooltipOpen,
    hideTooltip,
    tooltipData,
    tooltipLeft = 0,
  } = useTooltip<TooltipData>({
    // initial tooltip state
    tooltipOpen: tooltipIsShown,
    tooltipLeft: parentSizeState.width / 3,
    tooltipData: {
      position: {
        x: 0,
        y: 0,
      },
      date: configuration.axis.x.min,
      value: configuration.axis.y.min,
    },
  });

  // event handlers
  const handlePointerMove = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      // coordinates should be relative to the container in which Tooltip is rendered
      const pointerX = ("clientX" in event ? event.clientX : 0) - containerBounds.left;
      const pointerY = ("clientY" in event ? event.clientY : 0) - containerBounds.top;

      const minX = configuration.position.margin.left;
      const maxX = configuration.position.width - configuration.position.margin.right;

      const isOutOfBoundsLeft = pointerX < minX;
      const isOutOfBoundsRight = pointerX > maxX;

      const chartPositionX = isOutOfBoundsLeft ? minX : isOutOfBoundsRight ? maxX : pointerX;
      const chartPositionY = pointerY;

      // Note: to calculate the right value for a X position on the chart we need
      // to interpolate the pixel value relative to the pixel dimensions of the chart.
      //
      // Only the relative position on the xAxis should matter, not the mouse pointers y positions
      const relativeChartPositionX = (chartPositionX - minX) / configuration.position.inner.width; // [0,1]

      const dateForChartX = new Date(xScale.invert(chartPositionX - minX));

      const isDescending = configuration.data.initial.y > configuration.data.target.y;
      const multiplier = isDescending ? 1 - relativeChartPositionX : relativeChartPositionX;
      const valueForChartX = yScale.invert(configuration.position.inner.height * multiplier);

      const toolTipdata = {
        position: {
          x: chartPositionX,
          y: chartPositionY,
        },
        date: dateForChartX,
        value: valueForChartX,
      };

      setTooltipIsShown(true);

      setPointerCoordinate({
        x: configuration.position.margin.left + xScale(dateForChartX),
        y: configuration.position.inner.height + configuration.position.margin.top - yScale(valueForChartX),
      });

      showTooltip({
        tooltipLeft: chartPositionX,
        tooltipData: toolTipdata,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showTooltip, tooltipShouldDetectBounds, containerBounds],
  );

  const handlePointerLeave = useCallback(() => {
    hideTooltip();
    setTooltipIsShown(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TooltipComponent = renderTooltipInPortal
    ? TooltipInPortal
    : tooltipShouldDetectBounds
      ? TooltipWithBounds
      : Tooltip;

  const chartTheme = useMemo(() => {
    const isDarkMode = selectedThemeAppearance === SelectedThemeAppearance.dark;
    return isDarkMode ? themeDark : themeLight;
  }, [selectedThemeAppearance]);

  const formatDate = useMemo(() => {
    return makeDateFormatter(new Date(keyResult.startDate), new Date(keyResult.targetDate));
  }, [keyResult]);

  const accessors = {
    xAccessor: (d: Row) => d?.x,
    yAccessor: (d: Row) => d?.y,
  };

  return (
    <div
      className="relative"
      style={{ width: configuration.position.width, height: configuration.position.height }}
      ref={containerRef}
      onPointerMove={handlePointerMove}
      onPointerLeave={handlePointerLeave}
    >
      {tooltipOpen && (
        <>
          <div
            className="crosshair vertical absolute left-0 top-0 h-full w-[1px] border-[0.5px]"
            style={{ transform: `translateX(${tooltipLeft - 0.5}px)` }}
          />
          <TooltipComponent
            key={Math.random()} // needed for bounds to update correctly
            left={tooltipLeft}
            top={0}
            className="shadow-none absolute m-0 rounded-md border p-0 text-sm"
            style={{ backgroundColor: "transparent" }}
          >
            {tooltipData && (
              <div
                className={cn([
                  "flex flex-col bg-popover p-2 text-xxs font-light dark:text-white/80 ",
                  selectedThemeAppearance === SelectedThemeAppearance.dark ? "" : "",
                ])}
              >
                <div className="font-bold">Milestones:</div>
                {keyResult.properties.milestones.map((m) => {
                  return (
                    <div key={m.id} className="flex flex-row items-center gap-1">
                      {!m.completed && <IconSquare width={10} height={10} />}
                      {m.completed && <IconSquareCheck width={10} height={10} />}
                      <span>{m.value}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </TooltipComponent>
        </>
      )}

      <XYChart
        height={parentSizeState.height}
        width={parentSizeState.width}
        margin={configuration.position.margin}
        theme={chartTheme.xyChartTheme}
        xScale={{
          type: "linear",
          domain: [configuration.axis.x.min, configuration.axis.x.max],
          zero: false,
        }}
        yScale={{
          type: "linear",
          domain: [configuration.axis.y.min, configuration.axis.y.max],
          zero: false,
        }}
      >
        {/* <ChartBackground /> */}

        {/* RENDER THE 80% INTERVAL  */}
        <Group
          top={configuration.position.margin.top}
          left={configuration.position.margin.left}
          height={configuration.position.inner.height}
          width={configuration.position.inner.width}
          overflow={"hidden"}
        >
          <AreaClosed
            data={[
              configuration.data.initial,
              configuration.data.upper80[1],
              configuration.data.target,
              configuration.data.initial,
            ]}
            x={(d) => xScale(d.x) ?? 0}
            y={(d) => configuration.position.inner.height - yScale(d.y)}
            yScale={yScale}
            strokeWidth={0}
            fill={chartTheme.progressInterval.fill}
          />
          <AreaClosed
            data={[
              configuration.data.initial,
              configuration.data.lower80[1],
              configuration.data.target,
              configuration.data.initial,
            ]}
            x={(d) => xScale(d.x)}
            y={(d) => configuration.position.inner.height - yScale(d.y)}
            yScale={yScale}
            strokeWidth={0}
            fill={chartTheme.progressInterval.fill}
          />
        </Group>

        {/* RENDER THE LINEARLY PROJECTED PROGRESS  */}
        <AnimatedLineSeries
          style={{ strokeWidth: 1, strokeDasharray: 5 }}
          dataKey="predicted"
          data={configuration.data.predicted}
          {...accessors}
        />

        {/* <AnimatedLineSeries
          style={{ strokeDasharray: "8,8", strokeWidth: 1 }}
          dataKey="lower80"
          data={configuration.data.lower80}
          {...accessors}
        />
        <AnimatedLineSeries
          style={{ strokeDasharray: "8,8", strokeWidth: 1 }}
          dataKey="upper80"
          data={configuration.data.upper80}
          {...accessors}
        /> */}

        {/* Make sure that the 80% lines do not overflow in a descending chart */}
        {/* <rect
          x={configuration.position.margin.left}
          y={configuration.position.inner.height + configuration.position.margin.top}
          width={configuration.position.inner.width}
          height={configuration.position.margin.bottom}
          fill={chartTheme.xyChartTheme.backgroundColor}
          fillOpacity={1.0}
        /> */}

        {/* RENDER THE X and Y AXIS  */}
        <AnimatedAxis
          orientation="bottom"
          strokeWidth={1}
          tickValues={configuration.axis.x.ticks}
          tickLabelProps={(_: Date, index: number) => {
            const textAnchor =
              index === 0 ? "start" : index === configuration.axis.x.ticks.length - 1 ? "end" : "middle";
            return {
              fontSize: 8,
              textAnchor: textAnchor,
            };
          }}
          tickFormat={(value) => {
            const d = new Date(value);
            return formatDate(d);
          }}
        />
        <AnimatedAxis
          orientation="left"
          strokeWidth={0}
          tickLength={0}
          tickClassName="hidden"
          numTicks={2}
          tickLabelProps={() => ({
            fontSize: 8,
            textAnchor: "end",
          })}
          tickFormat={(value) => {
            return `${Number(value).toFixed()}${keyResult.unit ? keyResult.unit.symbol : ""}`;
          }}
        />

        {configuration.data.milestones.map(({ x, y, milestone }) => {
          return (
            <CustomGlyph
              key={milestone.id}
              className="milestone-dot"
              left={configuration.position.margin.left + xScale(x)}
              top={configuration.position.inner.height + configuration.position.margin.top - yScale(y)}
            >
              {milestone.completed && (
                <>
                  {/* <circle r={4} strokeWidth={0} fill={"#009000"} /> */}
                  <circle
                    r={4}
                    strokeWidth={0}
                    stroke={chartTheme.xyChartTheme.backgroundColor}
                    fill={chartTheme.xyChartTheme.colors[0]}
                  />
                  <text fontSize={4.5} fill={"#FFFFFF"} textAnchor="middle" dy="0.35em">
                    {"✔︎"}
                  </text>
                </>
              )}
              {!milestone.completed && (
                <circle
                  r={3}
                  strokeWidth={1}
                  stroke={chartTheme.xyChartTheme.colors[0]}
                  fill={chartTheme.xyChartTheme.backgroundColor}
                />
              )}
            </CustomGlyph>
          );
        })}

        {/* RENDER THE HISTORICAL PROGRESS  */}
        {configuration.data.progressSnapshots.length > 0 && (
          <Group top={configuration.position.margin.top} left={configuration.position.margin.left}>
            {configuration.data.progressSnapshots.map(({ x, y, progressSnapshot }) => {
              return (
                <Circle
                  key={`circle-progress-${progressSnapshot.id}`}
                  className="data-progress-dot"
                  cx={xScale(x)}
                  cy={configuration.position.inner.height - yScale(y)}
                  r={3}
                  fill={chartTheme.progressSnapshot.point.fill}
                  stroke={chartTheme.xyChartTheme.colors[0]}
                  strokeWidth={0}
                />
              );
            })}

            <LinePath
              data={[
                {
                  x: configuration.data.initial.x,
                  y: configuration.data.initial.y,
                },
                ...configuration.data.progressSnapshots,
              ]}
              x={(d) => xScale(d.x) ?? 0}
              y={(d) => configuration.position.inner.height - yScale(d.y)}
              stroke={chartTheme.progressSnapshot.curve.stroke}
              shapeRendering="geometricPrecision"
            />
          </Group>
        )}

        {/* RENDER THE CURRENT VALUE  */}
        {/* 
        <Circle
          key={`data-current-value`}
          className="data-dot"
          cx={configuration.position.margin.left + xScale(configuration.data.current.x)}
          cy={
            configuration.position.inner.height +
            configuration.position.margin.top -
            yScale(configuration.data.current.y)
          }
          r={3}
          fill={"#FF0000"}
        /> */}

        {/* RENDER THE THE TOOLTIP  */}
        {tooltipIsShown && (
          <Circle
            key={`circle-pointer`}
            className="data-dot"
            cx={pointerCoordinate.x}
            cy={pointerCoordinate.y}
            r={3}
            fill={chartTheme.xyChartTheme.backgroundColor}
            stroke={chartTheme.xyChartTheme.colors[0]}
            strokeWidth={1}
          />
        )}
      </XYChart>
    </div>
  );
};

export default KeyResultChartMilestone;
