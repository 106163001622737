import { Outlet, useParams } from "react-router-dom";

import {
  CurrentWorkspaceContextProvider,
  useCurrentWorkspaceContext,
} from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { BasePageLayout } from "@/components/_layout/base-page-layout";
import SidebarSettings from "@/components/_layout/sidebar-settings/sidebar-settings";
import { NotFoundView } from "@/components/not-found-view";
// load all essential data for the workspace
import { PreLoader } from "@/components/preloader";
import { ProductlaneWidget } from "@/components/productlane-widget";
// Make sure all child components have access to the workspace object
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

const InnerLayout = () => {
  const { currentUser } = useCurrentUserContext();
  const { currentWorkspace } = useCurrentWorkspaceContext();

  return (
    <>
      <PreLoader workspaceId={currentWorkspace.id} userId={currentUser.id}>
        <BasePageLayout
          renderSidebar={() => <SidebarSettings workspace={currentWorkspace} />}
          renderContent={() => <Outlet />}
        />
      </PreLoader>
      <ProductlaneWidget />
    </>
  );
};

export function SettingsRootLayout() {
  const { workspaceUrl } = useParams();
  const { currentUser } = useCurrentUserContext();

  if (!workspaceUrl) {
    return <NotFoundView />;
  }

  return (
    <CurrentWorkspaceContextProvider workspaceUrl={workspaceUrl} userId={currentUser.id}>
      <InnerLayout />;
    </CurrentWorkspaceContextProvider>
  );
}
