import { FC, PropsWithChildren, ReactElement, useMemo } from "react";

import { Combobox, ComboboxOption } from "@/components/combobox";
import { useGetUnitList } from "@/hooks/api/common/useUnit";

import { UnitSelectDropdownItem } from "./unit-select-dropdown-item";

export interface UnitSelectDropdownProps extends PropsWithChildren {
  workspaceId: string;
  defaultValue?: string | null;
  onSelect?: (unitId: string | null) => void;
  asChild?: boolean;
  isRequired?: boolean;
}

export const UnitSelectDropdown: FC<UnitSelectDropdownProps> = ({
  children,
  asChild,
  onSelect,
  workspaceId,
  defaultValue,
  isRequired = false,
}) => {
  const { unitList } = useGetUnitList({ workspaceId });

  const comboboxOptions = useMemo(() => {
    const options = unitList.map((unit) => ({
      id: unit.id,
      value: `${unit.title} ${unit.symbol ? `(${unit.symbol})` : ""}`,
    }));

    if (!isRequired) {
      options.unshift({
        id: "none",
        value: "Keine Einheit",
      });
    }

    return options;
  }, [unitList, isRequired]);

  const renderItem = (option: ComboboxOption): ReactElement => {
    if (option.id === "none") {
      return <UnitSelectDropdownItem title="Keine Einheit" selected={defaultValue === null} />;
    }

    const unit = unitList.find((u) => u.id === option.id);
    if (!unit) {
      return <></>;
    }
    const selected = defaultValue === option.id;
    return <UnitSelectDropdownItem title={unit.title} symbol={unit.symbol} selected={selected} />;
  };

  const handleUserSelected = (selectedOption: ComboboxOption | null) => {
    if (!selectedOption || !onSelect) {
      return;
    }

    if (selectedOption.id === "none") {
      onSelect(null);
      return;
    }

    onSelect(selectedOption.id);
  };

  if (!comboboxOptions.length) {
    return <></>;
  }

  return (
    <Combobox
      asChild={asChild}
      options={comboboxOptions}
      renderItem={renderItem}
      onSelect={handleUserSelected}
      placeholder="Einheit"
      side="bottom"
      align="start"
    >
      {children}
    </Combobox>
  );
};
