import { FC } from "react";

import { PhaseSelectDropdown, PhaseViewOptional } from "@/components/_domain/phase";
import { Button } from "@/components/_ui/button";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";
import { useUpdatePhaseInstance } from "@/hooks/api/common/usePhase";
import { useCreatePhaseInstance } from "@/hooks/api/common/usePhase/useCreatePhaseInstance";
import { useDeletePhaseInstance } from "@/hooks/api/common/usePhase/useDeletePhaseInstance";
import { useGetPhaseInstance } from "@/hooks/api/common/usePhase/useGetPhaseInstance";

interface ClimateActionPhaseViewProps {
  climateAction: {
    id: string;
  };
}

export const ClimateActionPhaseView: FC<ClimateActionPhaseViewProps> = ({ ...props }) => {
  const { climateAction } = useGetClimateAction(props.climateAction);
  const { phaseInstance } = useGetPhaseInstance({ id: climateAction?.phaseInstance?.id });
  const { updatePhaseInstance } = useUpdatePhaseInstance(); // always prefer this one
  const { createPhaseInstance } = useCreatePhaseInstance();
  const { deletePhaseInstance } = useDeletePhaseInstance();

  function assignPhase(phase: { id: string }) {
    if (!climateAction) {
      return;
    }

    if (phaseInstance) {
      updatePhaseInstance({
        variables: {
          input: {
            id: phaseInstance.id,
            phaseId: phase.id,
          },
        },
      });
    } else {
      createPhaseInstance({
        variables: {
          input: {
            phaseId: phase.id,
            ownerId: props.climateAction.id,
            ownerType: "CLIMATE_ACTION",
            workspaceId: climateAction.workspaceId,
          },
        },
      });
    }
  }

  function removePhase() {
    if (!phaseInstance) {
      return;
    }
    deletePhaseInstance({
      variables: { input: { id: phaseInstance.id } },
    });
  }

  const handleOnPhaseSelect = (phase: { id: string } | null) => {
    if (!phase) {
      return removePhase();
    }
    assignPhase(phase);
  };

  if (!climateAction) {
    return <></>;
  }

  return (
    <div className="mb-5 flex w-full flex-row items-start gap-2 overflow-x-hidden">
      <div className="mt-2 w-24 shrink-0 font-medium">Phase</div>
      <div className="grow">
        <PhaseSelectDropdown
          workspaceId={climateAction.workspaceId}
          onSelect={handleOnPhaseSelect}
          selected={phaseInstance}
          asChild
        >
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          >
            <PhaseViewOptional phase={phaseInstance ? { id: phaseInstance.phaseId } : null} />
          </Button>
        </PhaseSelectDropdown>
      </div>
    </div>
  );
};

export default ClimateActionPhaseView;
