import { createAPIError } from "./errors.service";
import { getAccessToken, removeAccessToken, setAccessToken } from "./storage.service";
import { removeWorkspaceSettings } from "./workspace.service";

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;
export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN";

// Application Monitoring
import {
  checkPasswordResetTokenMutationDocument,
  loginMutationDocument,
  refreshAccessTokenMutationDocument,
  requestPasswordResetLinkMutationDocument,
  updatePasswordWithTokenMutationDocument,
} from "@/graphql/common/auth";
import * as Sentry from "@sentry/react";

import { apolloClient } from "./apollo.service";
import { posthogService } from "./posthog.service";

export const isAuthenticated = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    Sentry.setUser(null);
    return false;
  }
  return true;
};

export const signout = () => {
  removeWorkspaceSettings();
  removeAccessToken();
  Sentry.setUser(null);
  posthogService.reset();
  window.location.replace(new URL(`${window.location.origin}/`));
};

export const login = async (email: string, password: string) => {
  const { data } = await apolloClient.mutate({
    mutation: loginMutationDocument,
    fetchPolicy: "no-cache",
    variables: {
      input: {
        email: email,
        password: password,
      },
    },
  });

  if (!data) {
    throw createAPIError({
      statusCode: 401,
      message: "The server did not respond with an access token",
    });
  }

  // login successful
  setAccessToken(data.login.token);
};

export const requestPasswordResetLink = async (email: string) => {
  const { data } = await apolloClient.mutate({
    mutation: requestPasswordResetLinkMutationDocument,
    fetchPolicy: "no-cache",
    variables: {
      input: {
        email: email,
      },
    },
  });

  if (!data) {
    throw createAPIError({
      statusCode: 401,
      message: "The server did not respond with an access token",
    });
  }
};

type PasswordResetTokenMetadatResponseType = {
  success: boolean;
  email: string;
  message: string;
};

export const isValidPasswordResetToken = async (token: string) => {
  const { data } = await apolloClient.mutate({
    mutation: checkPasswordResetTokenMutationDocument,
    fetchPolicy: "no-cache",
    variables: {
      input: {
        token: token,
      },
    },
  });

  if (!data) {
    throw createAPIError({
      statusCode: 401,
      message: "The server did not respond with an access token",
    });
  }

  return {
    ...data.checkPasswordResetToken,
  } as PasswordResetTokenMetadatResponseType;
};

export const resetPasswordWithToken = async (token: string, password: string) => {
  const { data } = await apolloClient.mutate({
    mutation: updatePasswordWithTokenMutationDocument,
    fetchPolicy: "no-cache",
    variables: {
      input: {
        token: token,
        password: password,
      },
    },
  });

  if (!data) {
    throw createAPIError({
      statusCode: 401,
      message: "The server did not respond with an access token",
    });
  }
};

export const refreshAccessToken = async () => {
  const { data } = await apolloClient.mutate({
    mutation: refreshAccessTokenMutationDocument,
    fetchPolicy: "no-cache",
  });

  if (!data) {
    throw createAPIError({
      statusCode: 401,
      message: "The server did not respond with an access token",
    });
  }

  // login successful
  setAccessToken(data.refresh.token);
};
