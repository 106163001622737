import { ReactElement } from "react";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$isGridSelection,
  FORMAT_TEXT_COMMAND,
  RangeSelection,
} from "lexical";

import IconToolbarBold from "@/components/_icons/IconToolbarBold";
import IconToolbarCode from "@/components/_icons/IconToolbarCode";
import IconToolbarItalic from "@/components/_icons/IconToolbarItalic";
import IconToolbarLink from "@/components/_icons/IconToolbarLink";
import IconToolbarListOrdered from "@/components/_icons/IconToolbarListOrdered";
import IconToolbarListUnordered from "@/components/_icons/IconToolbarListUnordered";
import IconToolbarParagraph from "@/components/_icons/IconToolbarParagraph";
import IconToolbarStrikethrough from "@/components/_icons/IconToolbarStrikethrough";
import IconToolbarUnderline from "@/components/_icons/IconToolbarUnderline";
import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListType,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";

export type Editor = ReturnType<typeof useLexicalComposerContext>[0];

export type ToolbarCommandType = {
  label: string;
  element: ReactElement;
  isActive: (selection: RangeSelection) => boolean;
  execute: (editor: Editor, payload?: unknown) => void;
};

export const FORMAT_TEXT_BOLD: ToolbarCommandType = {
  label: "fett",
  element: <IconToolbarBold className="h-2.5 w-3 text-xs" />,
  isActive: (selection) => {
    return selection.hasFormat("bold") ?? false;
  },
  execute: (editor) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
  },
};

export const FORMAT_TEXT_ITALIC: ToolbarCommandType = {
  label: "kursiv",
  element: <IconToolbarItalic className="h-2.5 w-3 text-xs" />,
  isActive: (selection) => {
    return selection.hasFormat("italic") ?? false;
  },
  execute: (editor) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
  },
};

export const FORMAT_TEXT_UNDERLINE: ToolbarCommandType = {
  label: "unterstrichen",
  element: <IconToolbarUnderline className="h-2.5 w-3 text-xs" />,
  isActive: (selection) => {
    return selection.hasFormat("underline") ?? false;
  },
  execute: (editor) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
  },
};

export const FORMAT_TEXT_STRIKETHROUGH: ToolbarCommandType = {
  label: "durchgestrichen",
  element: <IconToolbarStrikethrough className="h-2.5 w-3 text-xs" />,
  isActive: (selection) => {
    return selection.hasFormat("strikethrough") ?? false;
  },
  execute: (editor) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
  },
};

export const FORMAT_TEXT_CODE: ToolbarCommandType = {
  label: "code",
  element: <IconToolbarCode className="h-2.5 w-3 text-xs" />,
  isActive: (selection) => {
    return selection.hasFormat("code") ?? false;
  },
  execute: (editor) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
  },
};

export const FORMAT_BLOCK_PARAGRAPH: ToolbarCommandType = {
  label: "paragraph",
  element: (
    <span className="flex flex-row items-center text-xs">
      <IconToolbarParagraph className="mr-2 h-3 w-3" />
      Text
    </span>
  ),
  isActive: () => false,
  execute: (editor) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  },
};

type HeadingTagType = "h1" | "h2" | "h3";

const _formatHeading = (editor: Editor, headingSize: HeadingTagType) => {
  editor.update(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
      $setBlocksType(selection, () => $createHeadingNode(headingSize));
    }
  });
};

export const FORMAT_BLOCK_HEADING_H1: ToolbarCommandType = {
  label: "h1",
  element: (
    <span className="flex flex-row items-center text-xs">
      <span className="mr-2 flex h-3 w-3 items-center font-mono text-xs">H1</span>Überschrift 1
    </span>
  ),
  isActive: () => false,
  execute: (editor) => {
    _formatHeading(editor, "h1");
  },
};

export const FORMAT_BLOCK_HEADING_H2: ToolbarCommandType = {
  label: "h2",
  element: (
    <span className="flex flex-row items-center text-xs">
      <span className="mr-2 flex h-3 w-3 items-center font-mono text-xs">H2</span>Überschrift 2
    </span>
  ),
  isActive: () => false,
  execute: (editor) => {
    _formatHeading(editor, "h2");
  },
};

export const FORMAT_BLOCK_HEADING_H3: ToolbarCommandType = {
  label: "h3",
  element: (
    <span className="flex flex-row items-center text-xs">
      <span className="mr-2 flex h-3 w-3 items-center font-mono text-xs">H3</span>Überschrift 3
    </span>
  ),
  isActive: () => false,
  execute: (editor) => {
    _formatHeading(editor, "h3");
  },
};

type ListTagType = ListType;

const _formatList = (editor: Editor, type: ListTagType) => {
  editor.update(() => {
    if (type === "bullet") {
      return editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    }
    if (type === "number") {
      return editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    }
    if (type === "check") {
      return editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    }
  });
};

export const FORMAT_BLOCK_LIST_UNORDERED: ToolbarCommandType = {
  label: "bullet",
  element: (
    <span className="flex flex-row items-center text-xs">
      <IconToolbarListUnordered className="mr-2 h-3 w-3" />
      Stichpunkte
    </span>
  ),
  isActive: () => false,
  execute: (editor) => {
    _formatList(editor, "bullet");
  },
};

export const FORMAT_BLOCK_LIST_ORDERED: ToolbarCommandType = {
  label: "number",
  element: (
    <span className="flex flex-row items-center text-xs">
      <IconToolbarListOrdered className="mr-2 h-3 w-3" />
      Aufzählung
    </span>
  ),
  isActive: () => false,
  execute: (editor) => {
    _formatList(editor, "number");
  },
};

export const FORMAT_BLOCK_LIST_CHECK: ToolbarCommandType = {
  label: "check",
  element: <span>Check</span>,
  isActive: () => false,
  execute: (editor) => {
    _formatList(editor, "check");
  },
};

export const INSERT_LINK: ToolbarCommandType = {
  label: "link",
  element: (
    <span className="flex flex-row items-center text-xs">
      <IconToolbarLink className="mr-1 h-2 w-3" />
      Link
    </span>
  ),
  isActive: () => false,
  execute: () => {
    // implemented in ToolbarButtonLink
  },
};
