import { deletePhaseMutationDocument } from "@/graphql/common/phase";
import { type DeletePhaseMutation } from "@/graphql/generated/graphql";
import { onGenericEntityDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PHASE_DELETED, trackPhaseEvent } from "./tracking";

export type DeletedPhase = DeletePhaseMutation["deletePhase"];

export interface UseDeletePhaseProps {
  onCompleted?: (data: DeletedPhase) => void;
  onError?: (e: Error) => void;
}

export const useDeletePhase = ({ onCompleted, onError }: UseDeletePhaseProps) => {
  const [deletePhase] = useMutation(deletePhaseMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedPhase = data?.deletePhase;
      if (!deletedPhase) {
        return;
      }

      onGenericEntityDelete({ cache, deletedEntity: deletedPhase, typename: "Phase" });
      trackPhaseEvent(EVENT_PHASE_DELETED, deletedPhase);
    },
  });

  function onUpdateCompleted(data: DeletePhaseMutation) {
    if (onCompleted) {
      onCompleted(data.deletePhase);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deletePhase,
  };
};
