import { fileListForOwnerQueryDocument, fileQueryDocument, replaceFileMutationDocument } from "@/graphql/common/file";
import { useMutation } from "@apollo/client";

export interface UseReplaceFileProps {
  id: string;
  ownerId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useReplaceFile = ({ id, ownerId, onCompleted, onError }: UseReplaceFileProps) => {
  const [replaceFile, queryResult] = useMutation(replaceFileMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: fileListForOwnerQueryDocument,
        variables: { input: { ownerId: ownerId } },
      },
      {
        query: fileQueryDocument,
        variables: { input: { id: id } },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    replaceFile,
    queryResult,
    loading: queryResult.loading,
  };
};
