import { FC, PropsWithChildren, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "@/components/_ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";
import { Input } from "@/components/_ui/input";
import LoadingDots from "@/components/loading-dots";
import { resetPasswordWithToken } from "@/services/auth.service";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z
  .object({
    newpassword: z.string().min(8, {
      message: "Das neue Passwort muss mindestens 8 Zeichen lang sein.",
    }),
    confirmation: z.string(),
  })
  .refine((data) => data.newpassword === data.confirmation, {
    message: "Die Passwörter stimmen nicht überein.",
    path: ["confirmation"],
  });

export interface PasswordResetFormProps extends PropsWithChildren {
  token: string;
}

const PasswordResetForm: FC<PasswordResetFormProps> = ({ token }) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      newpassword: "",
      confirmation: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    setLoading(true);

    try {
      await resetPasswordWithToken(token, values.newpassword);
      window.location.replace(new URL(`${window.location.origin}/`));
    } catch (e) {
      form.setError("confirmation", {
        message: "Etwas ist schief gegangen. Aktualisieren Sie bitte die Seite und versuchen Sie es erneut.",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 px-8 py-8">
        <FormField
          control={form.control}
          name="newpassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Neues Passwort</FormLabel>
              <FormDescription>Bitte geben Sie Ihr neues Passwort ein.</FormDescription>
              <FormControl>
                <Input type="password" {...field} autoComplete="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmation"
          render={({ field }) => (
            <FormItem>
              <FormDescription>Bitte bestätigen Sie Ihr neues Passwort.</FormDescription>
              <FormControl>
                <Input type="password" {...field} autoComplete="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-row items-center justify-start">
          <Button
            type="submit"
            disabled={loading}
            className={`${
              loading ? "cursor-not-allowed bg-muted" : "bg-primary text-primary-foreground hover:bg-primary/90"
            } flex h-10 w-32 items-center justify-center rounded-md border text-sm transition-all focus:outline-none`}
          >
            {loading ? <LoadingDots color="#808080" /> : <p>Speichern</p>}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default PasswordResetForm;
