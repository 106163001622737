import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getPhaseInstanceQueryDocument = graphql(/* GraphQL */ `
  query GetPhaseInstance($input: GetPhaseInstanceInput!) {
    phaseInstance(getPhaseInstanceInput: $input) {
      id
      phaseId
      ownerId
      ownerType
      workspaceId
      phase {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);

export const getPhaseInstancebyOwnerQueryDocument = graphql(/* GraphQL */ `
  query GetPhaseInstanceByOwner($input: GetPhaseInstanceByOwnerInput!) {
    phaseInstanceByOwner(getPhaseInstanceByOwnerInput: $input) {
      id
      phaseId
      ownerId
      ownerType
      workspaceId
      phase {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createPhaseInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation createPhaseInstance($input: CreatePhaseInstanceInput!) {
    createPhaseInstance(createPhaseInstanceInput: $input) {
      id
      workspaceId
      phaseId
      ownerId
      ownerType
      phase {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);

export const updatePhaseInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation updatePhaseInstance($input: UpdatePhaseInstanceInput!) {
    updatePhaseInstance(updatePhaseInstanceInput: $input) {
      id
      workspaceId
      phaseId
      ownerId
      ownerType
      phase {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);

export const deletePhaseInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation DeletePhaseInstance($input: DeletePhaseInstanceInput!) {
    deletePhaseInstance(deletePhaseInstanceInput: $input) {
      id
      workspaceId
      phaseId
      ownerId
      ownerType
      phase {
        id
        workspaceId
        name
        color
        type
      }
    }
  }
`);
