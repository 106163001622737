import { updatePriorityInstanceMutationDocument } from "@/graphql/common/priority-instance";
import { type UpdatePriorityInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceUpdate } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PRIORITY_INSTANCE_UPDATED, trackPriorityEvent } from "./tracking";

export type UpdatedPriorityInstance = UpdatePriorityInstanceMutation["updatePriorityInstance"];

export interface UseUpdatePriorityInstanceProps {
  onCompleted?: (data: UpdatedPriorityInstance) => void;
  onError?: (e: Error) => void;
}

export const useUpdatePriorityInstance = ({ onCompleted, onError }: UseUpdatePriorityInstanceProps = {}) => {
  const [updatePriorityInstance] = useMutation(updatePriorityInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const priorityInstance = data?.updatePriorityInstance;
      if (!priorityInstance) {
        return;
      }

      onGenericEntityInstanceUpdate({
        cache,
        updatedEntityInstance: {
          ...priorityInstance,
          __parentId: priorityInstance.priorityId,
        },
        typename: "PriorityInstance",
      });
      trackPriorityEvent(EVENT_PRIORITY_INSTANCE_UPDATED, priorityInstance);
    },
  });

  function onUpdateCompleted(data: UpdatePriorityInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.updatePriorityInstance);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updatePriorityInstance,
  };
};
