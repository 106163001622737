import { Link } from "react-router-dom";

import AuthPageLayout from "../AuthPageLayout";

import PasswordForgottenForm from "./password-forgotten-form";

export default function Page() {
  return (
    <AuthPageLayout>
      <div className="z-10 w-full max-w-md overflow-hidden rounded-2xl shadow-xl">
        <div className="flex flex-col items-center justify-center space-y-3 px-4 py-6 pt-8 text-center sm:px-16">
          <h3 className="text-xl font-semibold">Passwort vergessen?</h3>
          <p className="text-sm ">
            Geben Sie Ihre E-Mail Adresse ein. Sie erhalten eine E-Mail mit Anweisungen, um Ihr Passwort zurückzusetzen.
          </p>
        </div>

        <PasswordForgottenForm />

        <p className="mb-8 text-center text-sm">
          Erinnern Sie sich an Ihre Login Daten?{" "}
          <Link to="/register" className="font-semibold">
            Melden Sie sich an.
          </Link>{" "}
        </p>
      </div>
    </AuthPageLayout>
  );
}
