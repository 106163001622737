import { Active, DataRef, Over, UniqueIdentifier } from "@dnd-kit/core";

import { BoardColumn, BoardItem, DRAG_TYPE, DraggableData } from "./board-types";

export function hasDraggableData<AO extends Active | Over, T extends BoardItem>(
  entry: AO | null | undefined,
): entry is AO & {
  data: DataRef<DraggableData<T>>;
} {
  if (!entry) {
    return false;
  }

  const data = entry.data.current;

  // Generic check for Column or Item
  if (data?.type === DRAG_TYPE.COLUMN || data?.type === DRAG_TYPE.ITEM) {
    return true;
  }

  return false;
}

// Update the getDraggingTaskData function to be more generic
export function getDraggingItemData<T extends BoardItem>(
  items: T[],
  columns: BoardColumn<T>[],
  itemId: UniqueIdentifier,
  columnId: string,
) {
  const itemIdString = String(itemId);

  const column = columns.find((col) => col.id === columnId);
  const itemsInColumn = items.filter((item) => item.columnId === column?.id);
  const itemPosition = itemsInColumn.findIndex((item) => item.id === itemIdString);

  return {
    itemsInColumn,
    itemPosition,
    column,
  };
}
