import { FC, useMemo, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

import { Button } from "@/components/_ui/button";
import { Switch } from "@/components/_ui/switch";
import { toast } from "@/components/_ui/use-toast";
import { RichTextEditor } from "@/components/rich-text-editor";
import { useCreateTaskMutation } from "@/hooks/api/common/useTask/useCreateTask";

import { TaskStatusSelect } from "../task-status-select";

import { TaskCreateFormType } from "./task-create-form.schema";
import { useCreateTaskProvider } from "./use-create-task-provider";

export interface TaskCreateFormProps {
  onSubmission?: (task: TaskCreateFormType) => void; // triggers before the submission
  onSuccess?: (taskId: string) => void;
  className?: string;
}

export const TaskCreateForm: FC<TaskCreateFormProps> = ({ onSuccess, onSubmission, className }) => {
  const [loading, setLoading] = useState(false);
  const [createMore, setCreateMore] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const { newTaskForm, updateNewTask, resetNewTaskForm, setShowModal, hasActiveChanges } = useCreateTaskProvider();

  const { createTask } = useCreateTaskMutation();

  const initialEditorState = useMemo(() => {
    if (!newTaskForm.description || newTaskForm.description === "") {
      return undefined;
    }
    return newTaskForm.description;
  }, [newTaskForm.description]);

  async function onRichTextEditorChange(_value: string, json: string) {
    updateNewTask({
      description: json === "" ? "" : json,
    });
  }

  async function handleCreateTask() {
    if (loading) return;

    if (onSubmission) {
      onSubmission(newTaskForm);
    }

    if (!newTaskForm.title.trim()) {
      toast({
        title: "Titel ist erforderlich",
        description: "Bitte geben Sie einen Titel für die Aufgabe ein.",
        variant: "destructive",
      });
      return;
    }

    setLoading(true);

    try {
      const task = await createTask({
        variables: {
          input: {
            title: newTaskForm.title,
            description: newTaskForm.description,
            status: newTaskForm.status,
            position: 0,
          },
        },
      });

      if (!task.data?.createTask.id) {
        return;
      }

      const taskId = task.data?.createTask.id;

      if (onSuccess) {
        onSuccess(taskId);
      }

      if (!createMore) {
        resetNewTaskForm();
        setShowModal(false);
      } else {
        // Reset form but keep modal open
        resetNewTaskForm();
        forceRichTextEditorRerender();
        // Focus on title field
        setTimeout(() => {
          const titleElement = document.getElementById("title");
          if (titleElement) {
            titleElement.focus();
          }
        }, 10);
      }
    } catch (error) {
      onUpdateError();
    } finally {
      setLoading(false);
    }
  }

  function forceRichTextEditorRerender() {
    setEditorKey(editorKey + 1); // Use a key state to force re-render
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description: "Beim Aktualisieren der Aufgabe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      variant: "destructive",
    });
  }

  return (
    <form className={className}>
      <div className="grid w-full">
        {/* Main Part */}
        <div className="mx-auto max-h-[70vh] w-full overflow-y-scroll pl-3 md:max-h-[40vh]">
          <TextareaAutosize
            autoFocus
            id="title"
            value={newTaskForm.title}
            onChange={(e) => updateNewTask({ title: e.target.value })}
            placeholder="Aufgabentitel"
            className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent py-0 pl-3 pr-6 text-lg focus:outline-none"
          />

          <RichTextEditor
            key={editorKey}
            onChange={onRichTextEditorChange}
            hideFloatingMenu={false}
            placeholder="Beschreibung der Aufgabe..."
            initialValue={initialEditorState}
          />

          <div className="flex flex-row items-center gap-2 px-3 py-2">
            <TaskStatusSelect value={newTaskForm.status} onValueChange={(value) => updateNewTask({ status: value })} />
          </div>
        </div>

        <div className="border-b"></div>
        <div className="flex flex-row items-center justify-end gap-4 px-6  pb-4 pt-4">
          <div>
            <div className="flex items-center space-x-1 text-sm text-muted-foreground">
              <Switch id="create-more" checked={createMore} onCheckedChange={setCreateMore} />
              <span>Mehr erstellen</span>
            </div>
          </div>

          <Button
            type="button"
            size="xs"
            onClick={handleCreateTask}
            disabled={loading || !hasActiveChanges}
            aria-busy={loading}
            aria-label={loading ? "Speichert..." : "Aufgabe erstellen"}
          >
            {loading ? "Speichert..." : "Aufgabe erstellen"}
          </Button>
        </div>
      </div>
    </form>
  );
};
