import { useMemo } from "react";

import { useGetSavedViewListForWorkspace } from "@/components/_domain/saved-view/hooks/useSavedView/useGetSavedViewListForWorkspace";
import { useGetClimateActionListForWorkspace } from "@/hooks/api/common/useClimateAction";
import { useFeatureFlagList } from "@/hooks/api/common/useFeatureFlag";
import { useCheckPermissionList } from "@/hooks/api/common/usePermission";
import { ACTION_TYPES, type ActionType } from "@/hooks/api/common/usePermission/types";
import { useTeamListForUserInWorkspace, useWorkspaceMembershipList } from "@/hooks/api/common/useWorkspace";

interface UsePreLoaderProps {
  workspaceId: string;
  userId: string;
}

export const usePreLoader = ({ workspaceId, userId }: UsePreLoaderProps) => {
  // load data for all teams
  const teamQueryResults = useTeamListForUserInWorkspace({
    workspaceId,
    userId,
    shouldUpdateCache: true,
  });
  const userQueryResults = useWorkspaceMembershipList({ workspaceId });

  const climateActionListForWorkspaceQueryResults = useGetClimateActionListForWorkspace({
    workspaceId,
  });

  const useCheckPermissionListQueryResults = useCheckPermissionList({
    permissions: ACTION_TYPES.map((action) => {
      return {
        resource: "workspace",
        action: action as ActionType,
        resourceId: workspaceId,
      };
    }),
  });

  const useCheckPermissionListForTeamListQueryResults = useCheckPermissionList({
    permissions: teamQueryResults.activeTeamList.flatMap((team) => {
      return ACTION_TYPES.map((action) => ({
        resource: "team",
        action: action as ActionType,
        resourceId: team.id,
      }));
    }),
  });

  const featureFlagListQueryResults = useFeatureFlagList({
    workspaceId,
  });

  void useGetSavedViewListForWorkspace({
    workspaceId,
  });

  const loading = useMemo(() => {
    return (
      teamQueryResults.loading ||
      userQueryResults.loading ||
      climateActionListForWorkspaceQueryResults.loading ||
      useCheckPermissionListQueryResults.loading ||
      useCheckPermissionListForTeamListQueryResults.loading ||
      featureFlagListQueryResults.loading
    );
  }, [
    teamQueryResults,
    userQueryResults,
    climateActionListForWorkspaceQueryResults,
    useCheckPermissionListQueryResults,
    useCheckPermissionListForTeamListQueryResults,
    featureFlagListQueryResults,
  ]);

  return {
    loading,
  };
};
