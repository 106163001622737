import { FC } from "react";
import { format, formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";
import { cn } from "@/lib/utils";
import { Row } from "@tanstack/react-table";

interface ClimateActionListLastUpdatedCellProps {
  row: Row<ClimateAction>;
  className?: string;
}

export const ClimateActionListLastUpdatedCell: FC<ClimateActionListLastUpdatedCellProps> = ({ row, className }) => {
  const climateAction = row.original;
  const lastProgressSnapshot = climateAction.progressSnapshotList?.[climateAction.progressSnapshotList.length - 1];
  const lastUpdatedDate = lastProgressSnapshot?.snapshotDate;

  if (!lastUpdatedDate) {
    return <></>;
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className={cn(["select-text whitespace-nowrap text-xs"], className)}>{`${format(
          new Date(lastUpdatedDate),
          "dd.MM.yyyy",
          {
            locale: de,
          },
        )}`}</div>
      </HoverCardTrigger>
      <HoverCardContent side="top" className="w-auto p-2">
        <div className="pt-1 text-xs text-muted-foreground">
          {`Zuletzt aktualisiert: ${formatDistanceToNow(new Date(lastUpdatedDate), { addSuffix: true, locale: de })}`}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
