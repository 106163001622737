import { createContext, ReactNode, useContext, useMemo, useState } from "react";

import { useScreenSize } from "@/hooks/useScreenSize";

export interface SidebarState {
  sidebarWidth: number;
  isFixed: boolean;
  isVisible: boolean;
}

type Props = {
  offsetTop?: number | undefined | null; // offset from the top of the in px
  children?: ReactNode;
};

type LayoutContextType = {
  sidebarState: SidebarState | null;
  setSidebarState: (newState: SidebarState) => void;
  insetApplication: number;
  offsetTop: number;
  setOffsetTop: (newOffsetTop: number) => void;
};

const initialValue = {
  sidebarState: null,
  setSidebarState: () => {},
  insetApplication: 8,
  offsetTop: 0,
  setOffsetTop: () => {},
};

export const LayoutContext = createContext<LayoutContextType>(initialValue);

export const LayoutProvider = ({ children, ...props }: Props) => {
  const [sidebarState, setSidebarState] = useState<SidebarState | null>(null);
  const [offsetTop, setOffsetTop] = useState<number>(props.offsetTop ?? 0);

  const { width } = useScreenSize();

  const insetApplication = useMemo(() => {
    if (width < 768) {
      return 0;
    }
    return 8;
  }, [width]);

  return (
    <LayoutContext.Provider
      value={{
        sidebarState,
        setSidebarState,
        insetApplication,
        offsetTop,
        setOffsetTop,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

// Define the useLayout hook
export const useLayout = (): LayoutContextType => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error("useLayout must be used within LayoutContexProvider");
  }

  return context;
};
