import { Plus } from "lucide-react";

import { Button } from "@/components/_ui/button";

import { useCreateTaskProvider } from "../task-create-dialog";
import { TaskStatus } from "../task-type";

import { TaskColumn } from "./task-board-types";

interface TaskColumnFooterProps {
  column: TaskColumn;
}

export function TaskColumnFooter({ column }: TaskColumnFooterProps) {
  const { setShowModal: setShowCreateTaskModal, updateNewTask } = useCreateTaskProvider();

  return (
    <Button
      variant="outline"
      className="hidden border-[0.5px] opacity-0 transition-all duration-300 ease-in-out group-hover:flex group-hover:opacity-100"
      size="xs"
      onClick={() => {
        updateNewTask({ status: column.id as TaskStatus });
        setShowCreateTaskModal(true);
      }}
      data-testid="new-task-column-footer-button"
    >
      <Plus className="h-3.5 w-3.5" />
    </Button>
  );
}
