import { FC, PropsWithChildren, useMemo } from "react";

import { ALLOWED_PHASE_LIST } from "@/components/_domain/phase/phase-type";
import { useGetPhaseListForWorkspace } from "@/hooks/api/common/usePhase/useGetPhaseListForWorkspace";
import { groupBy } from "@/lib/utils";

import PhaseTableSection from "./phase-table-section";

export interface PhaseTablePropsWithChildren extends PropsWithChildren {
  workspaceId: string;
  className?: string;
}

export const PhaseTable: FC<PhaseTablePropsWithChildren> = ({ workspaceId }) => {
  const { phaseListForWorkspace } = useGetPhaseListForWorkspace({ workspaceId });

  const groupedPhaseList = useMemo(() => {
    return groupBy(phaseListForWorkspace, (phase) => phase.type);
  }, [phaseListForWorkspace]);

  if (!phaseListForWorkspace) {
    return <></>;
  }

  return (
    <>
      {ALLOWED_PHASE_LIST.map((type) => {
        const phaseListForWorkspaceForType = groupedPhaseList.get(type) ?? [];
        return (
          <PhaseTableSection
            key={type}
            workspaceId={workspaceId}
            phaseType={type}
            phaseList={phaseListForWorkspaceForType}
            className="mb-4"
          />
        );
      })}
    </>
  );
};

export default PhaseTable;
