export const RESOURCE_TYPES = ["climateAction", "task", "workspace", "team"] as const;
export type ResourceType = (typeof RESOURCE_TYPES)[number];

export const ACTION_TYPES = ["view", "view_public", "write", "manage", "delete"] as const;
export type ActionType = (typeof ACTION_TYPES)[number];

export interface PermissionCheck {
  resource: ResourceType;
  action: ActionType;
  resourceId: string;
}
