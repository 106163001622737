import { FC, useCallback, useMemo, useState } from "react";

import { StatusSelectDropdown } from "@/components/_domain/progress-snapshot/status-select-dropdown";
import { StatusList, StatusType } from "@/components/_domain/progress-snapshot/status-types";
import StatusView from "@/components/_domain/progress-snapshot/status-view";
import { Button } from "@/components/_ui/button";
import { useGetProgressSnapshotList } from "@/hooks/api/common/useProgressSnapshot";

export interface ProgressSnapshotStatusInputInputProps {
  climateAction: {
    id: string;
  };
  value?: StatusType;
  onValueChange?: (value: StatusType | undefined) => void;
}

export const ProgressSnapshotStatusInput: FC<ProgressSnapshotStatusInputInputProps> = ({ onValueChange, ...props }) => {
  const [value, setValue] = useState<StatusType | undefined>(props.value);
  const { mostRecentSnapshot } = useGetProgressSnapshotList({ climateActionId: props.climateAction.id });

  const fieldWasModified = useCallback(() => {
    if (!mostRecentSnapshot && value === undefined) {
      return false;
    }

    if (!mostRecentSnapshot && value !== undefined) {
      return true;
    }

    if (mostRecentSnapshot && value === undefined) {
      return false;
    }

    return mostRecentSnapshot?.status !== value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleValueChanged = (value: StatusType) => {
    setValue(value);

    // notify the parent component about the change
    if (!onValueChange) {
      return;
    }
    if (!mostRecentSnapshot) {
      onValueChange(value);
      return;
    }

    if (mostRecentSnapshot.status !== value) {
      onValueChange(value);
      return;
    }

    onValueChange(undefined);
  };

  const defaultValue: StatusType = useMemo(() => {
    if (!mostRecentSnapshot) {
      return StatusList.onTrack;
    }
    if (mostRecentSnapshot.status === StatusList.offTrack) {
      return StatusList.offTrack;
    }
    if (mostRecentSnapshot.status === StatusList.atRisk) {
      return StatusList.atRisk;
    }
    return StatusList.onTrack;
  }, [mostRecentSnapshot]);

  const displayValue = useMemo(() => {
    if (value) {
      return value;
    }
    return defaultValue;
  }, [defaultValue, value]);

  return (
    <StatusSelectDropdown defaultValue={defaultValue} onSelect={handleValueChanged} asChild>
      <Button variant={"ghost"} size="xs" className="shadow-sm rounded-md border text-left text-xs font-normal">
        <StatusView type={displayValue} modified={fieldWasModified()} />
      </Button>
    </StatusSelectDropdown>
  );
};

export default ProgressSnapshotStatusInput;
