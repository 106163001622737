import { FC, PropsWithChildren } from "react";

import { cn } from "@/lib/utils";

import { useLayout } from "./useLayout";

export interface HeaderPropsWithChildren extends PropsWithChildren {
  className?: string;
}

export const HeaderLayout: FC<HeaderPropsWithChildren> = ({ children, className }) => {
  const { sidebarState } = useLayout();

  return (
    <header
      className={cn(
        "max-w-screen relative flex h-[56px] shrink-0 items-center justify-between gap-2 border-b-[0.5px] px-6 text-sm",
        sidebarState?.isFixed ? "pl-6" : "pl-12 md:pl-14",
        className,
      )}
    >
      {children}
    </header>
  );
};
