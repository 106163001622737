import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getProgressSnapshotQueryDocument = graphql(/* GraphQL */ `
  query GetProgressSnapshot($input: GetProgressSnapshotInput!) {
    progressSnapshot(getProgressSnapshotInput: $input) {
      id
      climateActionId
      keyResultId
      userId
      value
      status
      description
      snapshotDate
      properties
    }
  }
`);

export const getProgressSnapshotsQueryDocument = graphql(/* GraphQL */ `
  query GetProgressSnapshots($input: GetProgressSnapshotsInput!) {
    progressSnapshots(getProgressSnapshotsInput: $input) {
      id
      climateActionId
      keyResultId
      userId
      value
      status
      description
      snapshotDate
      properties
    }
  }
`);

export const getProgressSnapshotsForKeyResultQueryDocument = graphql(/* GraphQL */ `
  query GetProgressSnapshotsForKeyResults($input: GetProgressSnapshotsForKeyResultInput!) {
    progressSnapshotsForKeyResult(getProgressSnapshotsForKeyResultInput: $input) {
      id
      climateActionId
      keyResultId
      userId
      value
      status
      description
      snapshotDate
      properties
    }
  }
`);

// ============== MUTATIONS ======================
export const createProgressSnapshotMutationDocument = graphql(/* GraphQL */ `
  mutation createProgressSnapshot($input: CreateProgressSnapshotInput!) {
    createProgressSnapshot(createProgressSnapshotInput: $input) {
      id
      keyResultId
      userId
      value
      status
      description
      snapshotDate
      properties
    }
  }
`);

export const deleteProgressSnapshotMutationDocument = graphql(/* GraphQL */ `
  mutation deleteProgressSnapshot($input: DeleteProgressSnapshotInput!) {
    deleteProgressSnapshot(deleteProgressSnapshotInput: $input) {
      id
      keyResultId
      userId
      value
      status
      description
      snapshotDate
      properties
    }
  }
`);
