import { FC } from "react";

import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";

import { ProgressSnapshotEventListItem } from "./progress-snapshot-event-list-item";

interface ProgressSnapshotEventListProps {
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
}

export const ProgressSnapshotEventList: FC<ProgressSnapshotEventListProps> = (props) => {
  const { climateAction } = useGetClimateAction(props.climateAction);

  if (!climateAction) {
    return <></>;
  }

  if (climateAction.progressSnapshotList.length < 1) {
    return <div>Es liegen noch keine Einträge vor.</div>;
  }

  return (
    <div className="flex w-full max-w-screen-md	flex-col gap-4">
      <div className="absolute -bottom-8 left-3 top-8 w-[1px] bg-border" />
      {climateAction.progressSnapshotList.map((ps) => {
        return <ProgressSnapshotEventListItem key={ps.id} workspace={props.workspace} progressSnapshot={ps} />;
      })}
    </div>
  );
};
