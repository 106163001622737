import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getLabelInstancesQueryDocument = graphql(/* GraphQL */ `
  query GetLabelInstances($input: GetLabelInstancesInput!) {
    labelInstances(getLabelInstancesInput: $input) {
      id
      labelId
      labelGroupId
      ownerId
      ownerType
      workspaceId
      label {
        id
        title
        color
        workspaceId
        labelGroupId
      }
    }
  }
`);

export const getLabelInstanceQueryDocument = graphql(/* GraphQL */ `
  query GetLabelInstance($input: GetLabelInstanceInput!) {
    labelInstance(getLabelInstanceInput: $input) {
      id
      labelId
      labelGroupId
      ownerId
      ownerType
      workspaceId
      label {
        id
        title
        color
        workspaceId
        labelGroupId
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createLabelInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation createLabelInstance($input: CreateLabelInstanceInput!) {
    createLabelInstance(createLabelInstanceInput: $input) {
      id
      labelId
      labelGroupId
      ownerId
      ownerType
      workspaceId
      label {
        id
        title
        color
        workspaceId
        labelGroupId
      }
    }
  }
`);

export const deleteLabelInstanceMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteLabelInstance($input: DeleteLabelInstanceInput!) {
    deleteLabelInstance(deleteLabelInstanceInput: $input) {
      id
      labelId
      labelGroupId
      ownerId
      ownerType
      workspaceId
      label {
        id
        title
        color
        workspaceId
        labelGroupId
      }
    }
  }
`);
