import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const EVENT_USER_FAVORITE_CREATED = "MUTATION:USER_FAVORITE:CREATED";
export const EVENT_USER_FAVORITE_UPDATED = "MUTATION:USER_FAVORITE:UPDATED";
export const EVENT_USER_FAVORITE_DELETED = "MUTATION:USER_FAVORITE:DELETED";

type UserFavoriteEventType =
  | typeof EVENT_USER_FAVORITE_CREATED
  | typeof EVENT_USER_FAVORITE_UPDATED
  | typeof EVENT_USER_FAVORITE_DELETED;

export const trackUserFavoriteEvent = (event: UserFavoriteEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
