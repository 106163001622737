import { useMemo } from "react";

import {
  AssigneeColumnDefinition,
  ClimateActionTitleColumnDefinition,
  FocusAreaColumnDefinition,
  LabelGroupColumnListDefinition,
  LabelListColumnDefinition,
  PhaseColumnDefinition,
  PriorityColumnDefinition,
  ProgressColumnDefinition,
  StartDateColumnDefinition,
  StatusColumnDefinition,
  TargetDateColumnDefinition,
} from "@/components/_domain/climate-action/climate-action-list-overview/columns";
import { ClimateActionColumnConfiguration } from "@/components/_domain/climate-action/climate-action-list-overview/columns/column-definition-type";
import { LastUpdatedColumnDefinition } from "@/components/_domain/climate-action/climate-action-list-overview/columns/last-updated-column/last-updated-column";
import { DEFAULT_EXPANDED_STATE } from "@/components/_domain/saved-view/models/defaultExpandedState";
import { useGetLabelGroupListForWorkspace } from "@/hooks/api/common/useLabel";
import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";

interface UseTableConfigurationFocusAreaPageProps {
  workspace: {
    id: string;
  };
}

export const useTableConfigurationFocusAreaPage = ({ workspace }: UseTableConfigurationFocusAreaPageProps) => {
  const { labelGroupList } = useGetLabelGroupListForWorkspace({
    workspaceId: workspace.id,
  });
  const columnConfigurationList: ClimateActionColumnConfiguration[] = useMemo(() => {
    return [
      PriorityColumnDefinition({ columnId: "priority", orderValue: 101 }),
      PhaseColumnDefinition({ columnId: "phase", orderValue: 102 }),
      ClimateActionTitleColumnDefinition({
        workspaceId: workspace.id,
        columnId: "climateAction_title",
        orderValue: 103,
      }),
      FocusAreaColumnDefinition({
        workspaceId: workspace.id,
        columnId: "focus_area",
        orderValue: 201,
        config: { hideText: false },
      }),
      AssigneeColumnDefinition({ workspaceId: workspace.id, columnId: "assignee", orderValue: 301 }),
      StartDateColumnDefinition({
        workspaceId: workspace.id,
        columnId: "start_date",
        orderValue: 302,
      }),
      TargetDateColumnDefinition({
        workspaceId: workspace.id,
        columnId: "target_date",
        orderValue: 303,
      }),
      StatusColumnDefinition({ workspaceId: workspace.id, columnId: "status", orderValue: 304 }),
      ProgressColumnDefinition({ workspaceId: workspace.id, columnId: "progress", orderValue: 305 }),
      LastUpdatedColumnDefinition({ workspaceId: workspace.id, columnId: "last_updated", orderValue: 306 }),
      LabelListColumnDefinition({
        workspaceId: workspace.id,
        columnId: "label_list",
        orderValue: 202,
      }),
      ...LabelGroupColumnListDefinition({
        workspaceId: workspace.id,
        startOrderValue: 203,
        labelGroupList: labelGroupList,
      }),
    ];
  }, [workspace, labelGroupList]);

  const defaultVisibilityState = useMemo(() => {
    const visibilityState: VisibilityState = {};
    for (const { columnId, getDefaultVisibilityState } of columnConfigurationList) {
      visibilityState[columnId] = getDefaultVisibilityState();
    }
    return visibilityState;
  }, [columnConfigurationList]);

  const defaultColumnOrderState: ColumnOrderState = useMemo(() => {
    return columnConfigurationList
      .slice()
      .sort((a, b) => {
        const diff = a.getOrderValue() - b.getOrderValue();

        if (diff > 0) {
          return 1;
        }
        if (diff < 0) {
          return -1;
        }
        return 0;
      })
      .map((c) => c.columnId);
  }, [columnConfigurationList]);

  return {
    columnConfigurationList,
    defaultVisibilityState,
    defaultColumnOrderState,
    defaultExpandedState: DEFAULT_EXPANDED_STATE,
  };
};
