import { graphql } from "@/graphql/generated/gql";

// ============== QUERIES ======================
export const getIndicatorQueryDocument = graphql(/* GraphQL */ `
  query GetIndicator($input: GetIndicatorInput!) {
    indicator(getIndicatorInput: $input) {
      id
      workspaceId
      title
      description
      type
      unitId
      createdAt
      updatedAt
      values {
        id
        indicatorId
        value
        date
        comment
        createdAt
        updatedAt
      }
      valueCount
      latestValue {
        id
        indicatorId
        value
        date
        comment
        createdAt
        updatedAt
      }
    }
  }
`);

export const getIndicatorListQueryDocument = graphql(/* GraphQL */ `
  query GetIndicatorList($input: GetIndicatorListInput!) {
    indicatorList(getIndicatorListInput: $input) {
      id
      workspaceId
      title
      description
      type
      unitId
      createdAt
      updatedAt
      valueCount
      latestValue {
        id
        indicatorId
        value
        date
        comment
        createdAt
        updatedAt
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const createIndicatorMutationDocument = graphql(/* GraphQL */ `
  mutation CreateIndicator($input: CreateIndicatorInput!) {
    createIndicator(createIndicatorInput: $input) {
      id
      workspaceId
      title
      description
      type
      unitId
      createdAt
      updatedAt
      values {
        id
        indicatorId
        value
        date
        comment
        createdAt
        updatedAt
      }
      valueCount
      latestValue {
        id
        indicatorId
        value
        date
        comment
        createdAt
        updatedAt
      }
    }
  }
`);

export const updateIndicatorMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateIndicator($input: UpdateIndicatorInput!) {
    updateIndicator(updateIndicatorInput: $input) {
      id
      workspaceId
      title
      description
      type
      unitId
      createdAt
      updatedAt
      values {
        id
        indicatorId
        value
        date
        comment
        createdAt
        updatedAt
      }
      valueCount
      latestValue {
        id
        indicatorId
        value
        date
        comment
        createdAt
        updatedAt
      }
    }
  }
`);

export const deleteIndicatorMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteIndicator($input: DeleteIndicatorInput!) {
    deleteIndicator(deleteIndicatorInput: $input) {
      id
      workspaceId
      title
      description
      type
      unitId
      createdAt
      updatedAt
    }
  }
`);

// ============== INDICATOR VALUE MUTATIONS ======================
export const createIndicatorValueMutationDocument = graphql(/* GraphQL */ `
  mutation CreateIndicatorValue($input: CreateIndicatorValueInput!) {
    createIndicatorValue(createIndicatorValueInput: $input) {
      id
      indicatorId
      value
      date
      comment
      createdAt
      updatedAt
    }
  }
`);

export const updateIndicatorValueMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateIndicatorValue($input: UpdateIndicatorValueInput!) {
    updateIndicatorValue(updateIndicatorValueInput: $input) {
      id
      indicatorId
      value
      date
      comment
      createdAt
      updatedAt
    }
  }
`);

export const deleteIndicatorValueMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteIndicatorValue($input: DeleteIndicatorValueInput!) {
    deleteIndicatorValue(deleteIndicatorValueInput: $input) {
      id
      indicatorId
      value
      date
      comment
      createdAt
      updatedAt
    }
  }
`);
