import { getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { workspaceByIdQueryDocument } from "@/graphql/common/workspace";
import { Label, LabelInstance } from "@/graphql/generated/graphql";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { getAccessToken } from "../storage.service";

import { mergeArraysByField } from "./_utils";

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;

const httpLink = createHttpLink({
  uri: `${API_ENDPOINT}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      // ------------------------------------------------------------
      // Queries (keys are equivalent to the name of a query)
      // – we can specify custom read policies (how to check the cache for the existing data)
      // - for individual objects identified by their __typename and id we can
      //   specify custom read policies (how to check the cache for the existing data
      //   and how to merge the data)
      // - for lists (e.g., focusAreaListForWorkspace) we can specify custom merge
      //   policies. If we want to fetch them in another query, we need to actively
      //   query the cache on the client side on the object where we want to get
      //   the list from.
      // ------------------------------------------------------------
      Query: {
        fields: {
          featureFlag: {
            read(featureFlag, { args, toReference }) {
              if (featureFlag) {
                // There's already a item associated with this query, so return it
                return featureFlag;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "FeatureFlag",
                id: args?.getFeatureFlagInput?.id,
              });
            },
          },
          checkPermission: {
            read(checkPermission, { args, toReference }) {
              if (checkPermission) {
                // There's already a item associated with this query, so return it
                return checkPermission;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "PermissionResult",
                id: `${args?.checkPermissionInput?.resource}-${args?.checkPermissionInput?.action}-${args?.checkPermissionInput?.resourceId}`,
              });
            },
          },
          savedViews: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
          },
          workspaceById: {
            read(workspace, { args, toReference }) {
              if (workspace) {
                return workspace;
              }
              return toReference({ __typename: "Workspace", id: args?.workspaceId });
            },
          },
          teamById: {
            read(team, { args, toReference }) {
              if (team) {
                return team;
              }
              return toReference({ __typename: "Team", id: args?.teamId });
            },
          },
          priority: {
            read(priority, { args, toReference }) {
              if (priority) {
                // There's already a item associated with this query, so return it
                return priority;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "Priority",
                id: args?.getPriorityInput?.id,
              });
            },
          },
          priorityInstance: {
            read(priorityInstance, { args, toReference }) {
              if (priorityInstance) {
                return priorityInstance;
              }
              return toReference({ __typename: "PriorityInstance", id: args?.getPriorityInstanceInput?.id });
            },
          },
          priorityInstanceByOwner: {
            read(priorityInstanceByOwner, { args, cache }) {
              if (priorityInstanceByOwner) {
                return priorityInstanceByOwner;
              }

              const climateAction = cache.readQuery({
                query: getClimateActionQueryDocument,
                variables: { input: { id: args?.getPriorityInstanceByOwnerInput?.ownerId } },
              });

              if (climateAction) {
                return climateAction.climateAction.priorityInstance;
              }

              return undefined;
            },
          },
          priorityListForWorkspace: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
            read(priorityListForWorkspace, { args, cache }) {
              if (priorityListForWorkspace) {
                return priorityListForWorkspace;
              }

              const workspace = cache.readQuery({
                query: workspaceByIdQueryDocument,
                variables: { input: args?.getPriorityListForWorkspaceInput?.workspaceId },
              });

              if (!workspace) {
                return null;
              }

              return workspace.workspaceById.priorityList;
            },
          },
          phase: {
            read(phase, { args, toReference }) {
              if (phase) {
                // There's already a item associated with this query, so return it
                return phase;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "Phase",
                id: args?.getPhaseInput?.id,
              });
            },
          },
          phaseInstance: {
            read(phaseInstance, { args, toReference }) {
              if (phaseInstance) {
                return phaseInstance;
              }
              return toReference({ __typename: "PhaseInstance", id: args?.getPhaseInstanceInput?.id });
            },
          },
          phaseInstanceByOwner: {
            read(phaseInstanceByOwner, { args, cache }) {
              if (phaseInstanceByOwner) {
                return phaseInstanceByOwner;
              }

              const climateAction = cache.readQuery({
                query: getClimateActionQueryDocument,
                variables: { input: { id: args?.getPhaseInstanceByOwnerInput?.ownerId } },
              });

              if (climateAction) {
                return climateAction.climateAction.phaseInstance;
              }

              return undefined;
            },
          },
          phaseListForWorkspace: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
            read(phaseListForWorkspace, { args, cache }) {
              if (phaseListForWorkspace) {
                return phaseListForWorkspace;
              }

              const workspace = cache.readQuery({
                query: workspaceByIdQueryDocument,
                variables: { input: args?.getPhaseListForWorkspaceInput?.workspaceId },
              });

              if (!workspace) {
                return null;
              }

              return workspace.workspaceById.phaseList;
            },
          },
          focusArea: {
            read(focusArea, { args, toReference }) {
              if (focusArea) {
                // There's already a item associated with this query, so return it
                return focusArea;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "FocusArea",
                id: args?.getFocusAreaInput?.id,
              });
            },
          },
          focusAreaInstance: {
            read(focusAreaInstance, { args, toReference }) {
              if (focusAreaInstance) {
                return focusAreaInstance;
              }
              return toReference({ __typename: "FocusAreaInstance", id: args?.getFocusAreaInstanceInput?.id });
            },
          },
          focusAreaListForWorkspace: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
            read(focusAreaListForWorkspace, { args, cache }) {
              if (focusAreaListForWorkspace) {
                return focusAreaListForWorkspace;
              }

              const workspace = cache.readQuery({
                query: workspaceByIdQueryDocument,
                variables: { input: args?.getFocusAreaListForWorkspaceInput?.workspaceId },
              });

              if (!workspace) {
                return null;
              }

              return workspace.workspaceById.focusAreaList;
            },
          },
          unit: {
            read(unit, { args, toReference }) {
              if (unit) {
                // There's already a item associated with this query, so return it
                return unit;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "Unit",
                id: args?.getUnitInput?.id,
              });
            },
          },
          unitList: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
            read(unitList, { args, cache }) {
              if (unitList) {
                return unitList;
              }

              const workspace = cache.readQuery({
                query: workspaceByIdQueryDocument,
                variables: { input: args?.getUnitListInput?.workspaceId },
              });

              if (!workspace) {
                return null;
              }

              return workspace.workspaceById.unitList;
            },
          },
          labelGroup: {
            read(labelGroup, { args, toReference }) {
              if (labelGroup) {
                // There's already a item associated with this query, so return it
                return labelGroup;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "LabelGroup",
                id: args?.getLabelGroupInput?.id,
              });
            },
          },
          label: {
            read(label, { args, toReference }) {
              if (label) {
                // There's already a item associated with this query, so return it
                return label;
              }
              // No item associated with this query, but there still
              // might be one elsewhere in the cache. Check!
              return toReference({
                __typename: "Label",
                id: args?.getLabelInput?.id,
              });
            },
          },
          labelInstance: {
            read(labelInstance, { args, toReference }) {
              if (labelInstance) {
                return labelInstance;
              }
              return toReference({ __typename: "LabelInstance", id: args?.getLabelInstanceInput?.id });
            },
          },
          labelsForWorkspace: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
            read(labelsForWorkspace, { args, cache }) {
              if (labelsForWorkspace) {
                return labelsForWorkspace;
              }

              const workspace = cache.readQuery({
                query: workspaceByIdQueryDocument,
                variables: { input: args?.getLabelsForWorkspaceInput?.workspaceId },
              });

              if (!workspace) {
                return null;
              }

              return workspace.workspaceById.labelList;
            },
          },
          labelGroupsForWorkspace: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
            read(labelGroupsForWorkspace, { args, cache }) {
              if (labelGroupsForWorkspace) {
                return labelGroupsForWorkspace;
              }

              const workspace = cache.readQuery({
                query: workspaceByIdQueryDocument,
                variables: { input: args?.getLabelGroupsForWorkspaceInput?.workspaceId },
              });

              if (!workspace) {
                return null;
              }

              return workspace.workspaceById.labelGroupList;
            },
          },
          // plural (defines merge function)
          labelInstances: {
            merge(_existing: LabelInstance[], incoming: LabelInstance[]) {
              return incoming;
            },
            read(labelInstances, { args, cache }) {
              if (labelInstances) {
                return labelInstances;
              }

              // TODO – this might change, once we have more than just climate actions
              const climateAction = cache.readQuery({
                query: getClimateActionQueryDocument,
                variables: { input: { id: args?.getLabelInstancesInput?.ownerId } },
              });

              if (!climateAction) {
                return undefined;
              }

              return climateAction.climateAction.labelInstanceList;
            },
          },
          assigneeByOwner: {
            read(assigneeByOwner, { args, cache }) {
              if (assigneeByOwner) {
                return assigneeByOwner;
              }

              // TODO – this might change, once we have more than just climate actions
              const climateAction = cache.readQuery({
                query: getClimateActionQueryDocument,
                variables: { input: { id: args?.getAssigneesByOwnerInput?.ownerId } },
              });

              if (!climateAction) {
                return undefined;
              }

              return climateAction.climateAction.assignee;
            },
          },
          climateAction: {
            read(climateAction, { args, toReference }) {
              if (climateAction) {
                return climateAction;
              }
              return toReference({ __typename: "ClimateAction", id: args?.getClimateActionInput?.id });
            },
          },
          keyResult: {
            read(keyResult, { args, toReference }) {
              if (keyResult) {
                return keyResult;
              }
              return toReference({ __typename: "KeyResult", id: args?.getKeyResultInput?.id });
            },
          },
          progressSnapshot: {
            read(progressSnapshot, { args, toReference }) {
              if (progressSnapshot) {
                return progressSnapshot;
              }
              return toReference({ __typename: "ProgressSnapshot", id: args?.getProgressSnapshotInput?.id });
            },
          },
          climateActionListForWorkspace: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          labelsForTeam: {
            merge(existing: Label[], incoming: Label[], { readField, mergeObjects }) {
              const merged = mergeArraysByField(existing, incoming, "id", readField, mergeObjects);
              return merged;
            },
          },
        },
      },
      Priority: {
        fields: {
          instances: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing = [], incoming: []) {
              // always prefer the incoming data
              return incoming;
            },
          },
        },
      },
      Phase: {
        fields: {
          instances: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing = [], incoming: []) {
              // always prefer the incoming data
              return incoming;
            },
          },
        },
      },
      Unit: {
        fields: {
          keyResults: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
          },
        },
      },
      FocusArea: {
        fields: {
          assignedInstanceList: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
          },
        },
      },
      Label: {
        fields: {
          instances: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
          },
        },
      },
      LabelGroup: {
        fields: {
          labels: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
          },
        },
      },
      Workspace: {
        fields: {
          priorityList: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing = [], incoming: []) {
              // always prefer the incoming data
              return incoming;
            },
          },
          phaseList: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing = [], incoming: []) {
              // always prefer the incoming data
              return incoming;
            },
          },
          unitList: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing = [], incoming: []) {
              // always prefer the incoming data
              return incoming;
            },
          },
          focusAreaList: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing = [], incoming: []) {
              // always prefer the incoming data
              return incoming;
            },
          },
          labelGroupList: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing = [], incoming: []) {
              // always prefer the incoming data
              return incoming;
            },
          },
          labelList: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing = [], incoming: []) {
              // always prefer the incoming data
              return incoming;
            },
          },
        },
      },
      ClimateAction: {
        fields: {
          labelInstanceList: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
          },
          children: {
            merge(_existing = [], incoming: []) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});
