import { FC } from "react";

import IconCheckmark from "@/components/_icons/IconCheckmark";

import { StatusType } from "../status-types";
import { StatusView } from "../status-view";

export interface StatusSelectDropdownItemProps {
  type: StatusType;
  selected?: boolean;
}

export const StatusSelectDropdownItem: FC<StatusSelectDropdownItemProps> = ({ type, selected }) => {
  return (
    <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
      <div className="grow">
        <StatusView type={type} />
      </div>
      <div className="mr-2 w-3">
        {selected && (
          <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
        )}
      </div>
    </div>
  );
};
