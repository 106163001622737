import { useMemo } from "react";

import { ProgressSnapshot } from "@/components/_domain/progress-snapshot/progress-snapshots.schema";
import { getProgressSnapshotsForKeyResultQueryDocument } from "@/graphql/common/progress-snapshot";
import { useQuery } from "@apollo/client";

export interface UseGetProgressSnapshotListForKeyResultProps {
  keyResultId: string;
}

export const useGetProgressSnapshotListForKeyResult = ({
  keyResultId,
}: UseGetProgressSnapshotListForKeyResultProps) => {
  const queryResult = useQuery(getProgressSnapshotsForKeyResultQueryDocument, {
    variables: { input: { keyResultId: keyResultId } },
  });

  const progressSnapshotList: ProgressSnapshot[] = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.progressSnapshotsForKeyResult.slice().sort((a, b) => {
      const dateA = new Date(a.snapshotDate);
      const dateB = new Date(b.snapshotDate);

      return dateB.getTime() - dateA.getTime();
    });
  }, [queryResult.data]);

  return {
    queryResult,
    progressSnapshotList,
  };
};
