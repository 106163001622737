import { ReactNode } from "react";

import { usePreLoader } from "@/hooks/api/common/_usePreloader";

interface PreLoaderProps {
  workspaceId: string;
  userId: string;
  children: ReactNode;
}

export const PreLoader = ({ workspaceId, userId, children }: PreLoaderProps) => {
  const { loading } = usePreLoader({ workspaceId, userId });

  if (loading) {
    return <></>;
  }

  return <>{children}</>;
};
