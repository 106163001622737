import { Table, TableBody, TableCell, TableRow } from "@/components/_ui/table";

import { useClimateActionListState } from "../../../hooks";

import { ClimateActionListViewRow } from "./climate-action-list-view-row";

export function ClimateActionListView() {
  const { table, columns } = useClimateActionListState();

  const rows = table.getRowModel().rows;

  return (
    <Table>
      <TableBody className="border-b">
        {rows?.length > 0 && (
          <>
            {rows.map((row, i) => (
              <ClimateActionListViewRow key={i} row={row} />
            ))}
          </>
        )}
        {(!rows || rows.length === 0) && (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              Keine Ergebnisse gefunden.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
