import { FC } from "react";
import { FaCow } from "react-icons/fa6";
import { HiBeaker, HiBolt, HiBuildingLibrary, HiBuildingOffice2, HiMegaphone, HiTrash, HiTruck } from "react-icons/hi2";

import IconDashesHorizontal from "@/components/_icons/IconDashesHorizontal";

import { FocusAreaList } from "./models/focus-area-type";

const FOCUS_AREA_ICONS: Record<string, React.ComponentType<{ className?: string; style: { color: string } }>> = {
  [FocusAreaList.energy]: HiBolt,
  [FocusAreaList.buildings]: HiBuildingOffice2,
  [FocusAreaList.transport]: HiTruck,
  [FocusAreaList.industry]: HiBeaker,
  [FocusAreaList.waste]: HiTrash,
  [FocusAreaList.agriculture]: FaCow,
  [FocusAreaList.city]: HiBuildingLibrary,
  [FocusAreaList.supporting]: HiMegaphone,
};

export interface FocusAreaIconProps {
  focusArea?: {
    color: string;
    type: string;
  } | null;
  className?: string;
}

export const FocusAreaIcon: FC<FocusAreaIconProps> = ({ focusArea, className }) => {
  if (!focusArea) {
    return <IconDashesHorizontal className={className} />;
  }

  const Icon = FOCUS_AREA_ICONS[focusArea.type] ?? IconDashesHorizontal;

  return <Icon className={className} style={{ color: focusArea.color }} />;
};
