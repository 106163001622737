import { FC } from "react";

import { useGetKeyResult } from "@/hooks/api/common/useKeyResult";

import {
  MILESTONE_KEY_RESULT_TYPE,
  QUANTITATIVE_KEY_RESULT_TYPE,
} from "../../../../climate-action-sheet/key-result/models/key-result.form.schema";

import { ProgressSnapshotValueMilestoneInput } from "./progress-snapshot-value-milestone-input";
import { ProgressSnapshotValueQuantInput } from "./progress-snapshot-value-quant-input";

export interface ProgressSnapshotValueInputProps {
  keyResult?: {
    id: string;
  };
  value?: {
    value: number;
    properties: object;
  };
  onValueChange?: (
    value:
      | {
          value: number;
          properties: object;
        }
      | undefined,
  ) => void;
}

export const ProgressSnapshotValueInput: FC<ProgressSnapshotValueInputProps> = ({ value, onValueChange, ...props }) => {
  const { keyResult } = useGetKeyResult({ id: props.keyResult?.id });

  if (keyResult?.type === QUANTITATIVE_KEY_RESULT_TYPE) {
    return <ProgressSnapshotValueQuantInput keyResult={keyResult} value={value} onValueChange={onValueChange} />;
  }

  if (keyResult?.type === MILESTONE_KEY_RESULT_TYPE) {
    return <ProgressSnapshotValueMilestoneInput keyResult={keyResult} value={value} onValueChange={onValueChange} />;
  }
  return <></>;
};

export default ProgressSnapshotValueInput;
