import { type FC, type PropsWithChildren } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";

import { useGlobalSavedViewContext } from "../hooks/useGlobalSavedViewContext";

interface SavedViewLoaderProps extends PropsWithChildren {
  pathname: string;
  showErrorView?: boolean;
}
// Loads the saved views for the given pathname und unlocks the page
export const SavedViewLoader: FC<SavedViewLoaderProps> = ({ children, pathname, showErrorView = false }) => {
  const { getSavedViewState } = useGlobalSavedViewContext();
  const { loading } = getSavedViewState(pathname);

  // Wait for the saved views to be loaded before rendering the page
  if (loading) {
    return <Loading />;
  }

  // If configured, show an error view if the saved views are not available
  if (showErrorView) {
    return <ErrorView />;
  }

  // Make sure that the saved views are available before rendering the page
  return children;
};
