import { Outlet, useParams } from "react-router-dom";

import { CurrentClimateActionContextProvider } from "@/components/_domain/climate-action/hooks/useCurrentClimateActionContext";
import { NotFoundView } from "@/components/not-found-view";

export const ClimateActionLayout = () => {
  const { climateActionId } = useParams();

  if (!climateActionId) {
    return <NotFoundView />;
  }

  return (
    <CurrentClimateActionContextProvider climateActionId={climateActionId}>
      <Outlet />
    </CurrentClimateActionContextProvider>
  );
};
