import { FC } from "react";
import { Link } from "react-router-dom";

import IconChevronRight from "@/components/_icons/IconChevronRight";
import IconChildClimateAction from "@/components/_icons/IconChildClimateAction";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/_ui/dropdown-menu";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";
interface ClimateActionParentLabelProps {
  climateAction: {
    id: string;
  };
}

export const ClimateActionParentLabel: FC<ClimateActionParentLabelProps> = ({ ...props }) => {
  const { climateAction } = useGetClimateAction(props.climateAction);

  if (!climateAction) {
    return <></>;
  }

  return (
    <div className="flex flex-row text-sm text-muted-foreground">
      <div className="align-start shadow-md flex flex-row items-center rounded border">
        <Link to={`../${climateAction.id}`}>
          <div className="mx-2 my-1 hover:text-foreground">{climateAction.title}</div>
        </Link>
        <div className="h-3/5 w-0 border-l" />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="m-1 flex cursor-pointer select-none flex-row items-center gap-1 px-1 text-xs hover:text-foreground">
              <span>{climateAction.children.length}</span>
              <IconChildClimateAction className="h-3 w-3" />
              <IconChevronRight className="h-2.5 w-2.5" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" alignOffset={-4} side="right" sideOffset={8}>
            <div className="flex grow flex-col items-start gap-1 text-xs">
              {climateAction.children
                .slice()
                .sort((a, b) => (!a || !b ? 0 : a.title.localeCompare(b.title)))
                .map((o) => {
                  if (!o) {
                    return <></>;
                  }
                  return (
                    <Link key={o.id} to={`../${o.id}`} className="w-full">
                      <div className="cursor-pointer rounded p-1 hover:bg-muted">{o.title}</div>
                    </Link>
                  );
                })}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
