import { Navigate, useLocation } from "react-router-dom";

import { DebugInfo } from "@/components/debug-info";
import { CurrentUserContextProvider } from "@/hooks/api/common/useUser";

export const ProtectedRoute = ({ isAuthenticated, children }: { isAuthenticated: boolean; children: JSX.Element }) => {
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`} replace />;
  }

  // ensure all routes within a protected route have access to the current user
  return (
    <CurrentUserContextProvider>
      <>
        {children}
        <DebugInfo />
      </>
    </CurrentUserContextProvider>
  );
};
