import { FC } from "react";

import { SavedViewsMenu } from "@/components/_domain/saved-view/components/SavedViewsMenu";

import { ClimateActionListQuickSearch } from "../climate-action-list-quick-search";

import { ClimateActionListDisplayOptionsButton } from "./climate-action-list-display-options-button";
import { ClimateActionListFilterButton } from "./climate-action-list-filter-button";

export interface ClimateActionListConfigurationViewProps {
  config?: {
    quicksearch?: boolean;
    filterButton?: boolean;
    displayOptionsButton?: boolean;
    savedViewsMenu?: boolean;
  };
}

export const ClimateActionListConfigurationView: FC<ClimateActionListConfigurationViewProps> = ({
  config = {
    quicksearch: true,
    filterButton: true,
    displayOptionsButton: true,
    savedViewsMenu: true,
  },
}) => {
  return (
    <div
      className="max-w-screen relative flex h-[45px] shrink-0 items-center justify-between gap-2 border-b border-solid px-6 text-sm"
      data-testid="climate-action-list-configuration-view"
    >
      <div className="flex flex-row items-center">{config.quicksearch && <ClimateActionListQuickSearch />}</div>
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center gap-2">{config.savedViewsMenu && <SavedViewsMenu />}</div>
        <div className="flex flex-row items-center gap-2">
          {config.filterButton && <ClimateActionListFilterButton />}
        </div>
        <div className="flex flex-row items-center gap-2">
          {config.displayOptionsButton && <ClimateActionListDisplayOptionsButton />}
        </div>
      </div>
    </div>
  );
};
