import { FC, PropsWithChildren, useState } from "react";

import IconTrash from "@/components/_icons/IconTrash";
import { Button } from "@/components/_ui/button";
import { toast } from "@/components/_ui/use-toast";
import DeleteConfirmation from "@/components/delete-confirmation";
import { useDeleteFocusArea } from "@/hooks/api/common/useFocusArea";

export interface FocusAreaButtonDeleteProps extends PropsWithChildren {
  workspace: {
    id: string;
  };
  focusArea: {
    id: string;
    assignedInstanceList: Array<unknown>;
  };
  onSuccess?: () => void;
}

export const FocusAreaButtonDelete: FC<FocusAreaButtonDeleteProps> = ({ focusArea, onSuccess }) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const { deleteFocusArea } = useDeleteFocusArea({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  function handleDeleteButtonPressed() {
    if (focusArea.assignedInstanceList.length > 0) {
      const s = focusArea.assignedInstanceList.length === 1 ? "ist" : "sind";
      const t = focusArea.assignedInstanceList.length === 1 ? "Objekt" : "Objekten";
      toast({
        title: "Fehler",
        description: `Dem Handlungsfeld ${s} ${focusArea.assignedInstanceList.length} ${t} zugewiesen. Bitten entfernen Sie alle zugewiesenen Objekten von dem Handlugsfeld bevor Sie es löschen. `,
        variant: "error",
      });
      return;
    }

    setIsDeleteConfirmationOpen(true);
  }

  async function handleDeleteFocusAreaConfirmed() {
    await deleteFocusArea({ variables: { input: { id: focusArea.id } } });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="text-muted-foreground/80 hover:text-foreground/80"
        onClick={handleDeleteButtonPressed}
      >
        <IconTrash className="h-2.5 w-2.5" />
      </Button>
      <DeleteConfirmation
        open={isDeleteConfirmationOpen}
        onOpenChange={setIsDeleteConfirmationOpen}
        onConfirm={handleDeleteFocusAreaConfirmed}
        title="Sind Sie sich sicher, dass Sie dieses Handlungsfeld löschen möchten?"
        description="Diese Aktion kann nicht rückgängig gemacht werden. Das Handlungsfeld wird unwideruflich gelöscht."
      />
    </>
  );
};

export default FocusAreaButtonDelete;
