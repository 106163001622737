import { FC } from "react";

import IconDashesHorizontal from "@/components/_icons/IconDashesHorizontal";
import IconPriorityHigh from "@/components/_icons/IconPriorityHigh";
import IconPriorityLow from "@/components/_icons/IconPriorityLow";
import IconPriorityMedium from "@/components/_icons/IconPriorityMedium";
import IconPriorityUrgent from "@/components/_icons/IconPriorityUrgent";

import { PriorityList } from "./priority-type";

export interface PriorityIconProps {
  priority?: {
    color: string;
    type: string;
  } | null;
  className?: string;
}

export const PriorityIcon: FC<PriorityIconProps> = ({ priority, className }) => {
  if (!priority) {
    return <IconDashesHorizontal className={className} />;
  }

  if (priority.type === PriorityList.low) {
    return <IconPriorityLow className={className} style={{ color: priority.color }} />;
  }
  if (priority.type === PriorityList.medium) {
    return <IconPriorityMedium className={className} style={{ color: priority.color }} />;
  }
  if (priority.type === PriorityList.high) {
    return <IconPriorityHigh className={className} style={{ color: priority.color }} />;
  }
  if (priority.type === PriorityList.critical) {
    return <IconPriorityUrgent className={className} style={{ color: priority.color }} />;
  }

  return <IconDashesHorizontal className={className} style={{ color: priority.color }} />;
};

export default PriorityIcon;
