export const FocusAreaList = {
  energy: "Energy",
  buildings: "Buildings",
  transport: "Transport",
  waste: "Waste",
  industry: "Industry",
  agriculture: "Agriculture",
  city: "City-Specific",
  supporting: "Supporting",
} as const;

export const ALLOWED_FOCUS_AREA_LIST = Object.values(FocusAreaList);
export type FocusAreaType = (typeof ALLOWED_FOCUS_AREA_LIST)[number];

export const numberFromFocusAreaType = (p?: string) => {
  if (p === FocusAreaList.energy) return 160;
  if (p === FocusAreaList.buildings) return 150;
  if (p === FocusAreaList.transport) return 140;
  if (p === FocusAreaList.waste) return 130;
  if (p === FocusAreaList.industry) return 120;
  if (p === FocusAreaList.agriculture) return 110;

  if (p === FocusAreaList.city) return 20;

  if (p === FocusAreaList.supporting) return 10;
  return -1;
};

export const sortFocusArea = (a?: string, b?: string): 0 | 1 | -1 => {
  const numA = numberFromFocusAreaType(a);
  const numB = numberFromFocusAreaType(b);

  if (numA - numB > 0) {
    return 1;
  }
  if (numA - numB < 0) {
    return -1;
  }

  return 0;
};
