import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { getTheme, setTheme } from "@/services/theme.service";

type Props = {
  children?: ReactNode;
};

export enum ThemeAppearance {
  light = "light",
  dark = "dark",
  auto = "auto",
}

export enum SelectedThemeAppearance {
  light = "light",
  dark = "dark",
}

type ThemeContextType = {
  themeAppearance: ThemeAppearance;
  selectedThemeAppearance: SelectedThemeAppearance;
  setThemeAppearance: (newState: ThemeAppearance) => void;
};

const initialValue = {
  themeAppearance: ThemeAppearance.auto,
  selectedThemeAppearance: SelectedThemeAppearance.light,
  setThemeAppearance: () => {},
};

export const ThemeContext = createContext<ThemeContextType>(initialValue);

export const ThemeProvider = ({ children }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [themeAppearance, setThemeAppearance] = useState(ThemeAppearance.auto);
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined,
  );

  useEffect(() => {
    intializeThemeState();

    if (initialized) {
      writeThemeStateToStorage();
      addThemeClassToBodyTag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeAppearance]);

  const selectedThemeAppearance = useMemo(() => {
    if (themeAppearance === ThemeAppearance.dark) {
      return SelectedThemeAppearance.dark;
    }
    if (themeAppearance === ThemeAppearance.light) {
      return SelectedThemeAppearance.light;
    }
    if (themeAppearance === ThemeAppearance.auto) {
      return systemPrefersDark ? SelectedThemeAppearance.dark : SelectedThemeAppearance.light;
    }
    return SelectedThemeAppearance.light;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeAppearance]);

  async function intializeThemeState() {
    if (initialized) {
      return;
    }

    let theme: ThemeAppearance = ThemeAppearance[getTheme() as keyof typeof ThemeAppearance];

    if (!theme) {
      theme = ThemeAppearance.auto;
      setTheme(theme);
    }

    setThemeAppearance(theme);
    addThemeClassToBodyTag();
    setInitialized(true);
  }

  function writeThemeStateToStorage() {
    setTheme(themeAppearance);
  }

  function addThemeClassToBodyTag() {
    // Note: We need to manually set the className on the body tag, since dropdowns are injected there (thus outside of the ThemeProvider component)
    const newTheme = getThemeClassName();
    if (newTheme === ThemeAppearance.dark) {
      document.body.classList.add(ThemeAppearance.dark);
      document.body.classList.remove(ThemeAppearance.light);
    } else if (newTheme === ThemeAppearance.light) {
      document.body.classList.add(ThemeAppearance.light);
      document.body.classList.remove(ThemeAppearance.dark);
    }
  }

  function getSystemTheme(): ThemeAppearance {
    if (systemPrefersDark) {
      return ThemeAppearance.dark;
    }
    return ThemeAppearance.light;
  }

  function getThemeClassName() {
    if (themeAppearance === ThemeAppearance.auto) {
      return getSystemTheme();
    }
    return themeAppearance;
  }

  return (
    <ThemeContext.Provider
      value={{
        themeAppearance,
        selectedThemeAppearance,
        setThemeAppearance,
      }}
    >
      <div className={`${initialized ? getThemeClassName() : "hidden"}`}>{children}</div>
    </ThemeContext.Provider>
  );
};

// Define the useTheme hook
export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return context;
};

export default ThemeProvider;
