import { FC, PropsWithChildren, useState } from "react";

import IconTrash from "@/components/_icons/IconTrash";
import { Button } from "@/components/_ui/button";
import { toast } from "@/components/_ui/use-toast";
import DeleteConfirmation from "@/components/delete-confirmation";
import { useDeletePhase } from "@/hooks/api/common/usePhase";

export interface PhaseButtonDeleteProps extends PropsWithChildren {
  workspaceId: string;
  phase: {
    id: string;
    instances: Array<unknown>;
  };
  onSuccess?: () => void;
}

export const PhaseButtonDelete: FC<PhaseButtonDeleteProps> = ({ phase, onSuccess }) => {
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const { deletePhase } = useDeletePhase({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  function handleDeleteButtonPressed() {
    if (phase.instances.length > 0) {
      const s = phase.instances.length === 1 ? "ist" : "sind";
      const t = phase.instances.length === 1 ? "Objekt" : "Objekten";
      toast({
        title: "Fehler",
        description: `Die Phase ${s} ${phase.instances.length} ${t} zugewiesen. Bitten entfernen Sie die Phase von den zugewiesenen Objekten bevor Sie sie löschen. `,
        variant: "error",
      });
      return;
    }

    setDeleteConfirmationisOpen(true);
  }

  async function handleDeletePhaseConfirmed() {
    await deletePhase({ variables: { input: { id: phase.id } } });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="text-muted-foreground/80 hover:text-foreground/80"
        onClick={handleDeleteButtonPressed}
      >
        <IconTrash className="h-2.5 w-2.5" />
      </Button>
      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={handleDeletePhaseConfirmed}
        title="Sind Sie sich sicher, dass Sie diese Phase löschen möchten?"
        description="Diese Aktion kann nicht rückgängig gemacht werden. Die Phase wird unwideruflich gelöscht."
      />
    </>
  );
};

export default PhaseButtonDelete;
