import { apolloClient } from "@/services/apollo.service/apollo.service";

import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { SavedView } from "../../models/saved-views-model";
import { groupBy } from "@/lib/utils";

import { parseSavedView } from "./parse-filter-data-utils";
import { getSavedViewListForWorkspaceQueryDocument, getSavedViewsByUrlQueryDocument } from "./saved-view.gql";

interface UseGetSavedViewListForWorkspaceProps {
  workspaceId: string;
  enabled?: boolean;
}

export const useGetSavedViewListForWorkspace = ({
  workspaceId,
  enabled = true,
}: UseGetSavedViewListForWorkspaceProps) => {
  const queryResult = useQuery(getSavedViewListForWorkspaceQueryDocument, {
    variables: { input: { workspaceId: workspaceId } },
    skip: !enabled,
    onCompleted: (data) => {
      if (!data.savedViewListForWorkspace) {
        return;
      }

      // 1. Update the cache with the individual "by url queries" so they are instantly found
      // ------------------------------------------------------------------------------------
      const groupedSavedViews = groupBy(data.savedViewListForWorkspace, (savedView) => savedView.url);
      for (const [url, savedViews] of groupedSavedViews.entries()) {
        if (!savedViews) {
          continue;
        }
        const writeQuery = {
          query: getSavedViewsByUrlQueryDocument,
          variables: {
            input: { url: url, workspaceId: workspaceId },
          },
          data: { savedViews: savedViews },
        };

        apolloClient.cache.writeQuery(writeQuery);
      }
    },
  });

  const savedViews: SavedView[] = useMemo(() => {
    if (!queryResult.data?.savedViewListForWorkspace) {
      return [];
    }

    return queryResult.data.savedViewListForWorkspace.flatMap((savedView) => {
      const parsedSavedView = parseSavedView(savedView);
      if (!parsedSavedView) {
        return [];
      }
      return parsedSavedView;
    });
  }, [queryResult.data]);

  return {
    savedViews,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
