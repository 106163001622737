import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType, useRoutes } from "react-router-dom";

import * as Sentry from "@sentry/react";

const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;
const SENTRY_ENDPOINT = import.meta.env.VITE_SENTRY_ENDPOINT;

const SENTRY_CONFIG = {
  development: {
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
  staging: {
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
  production: {
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
};

function getEnvironment() {
  const env = import.meta.env.VITE_ENVIRONMENT;

  if (env === "development") {
    return env;
  }

  if (env === "staging") {
    return env;
  }

  return "production";
}

function getConfig() {
  if (ENVIRONMENT === "development") {
    return SENTRY_CONFIG.development;
  }

  if (ENVIRONMENT === "staging") {
    return SENTRY_CONFIG.staging;
  }

  return SENTRY_CONFIG.production;
}

const ENVIRONMENT = getEnvironment();
const CONFIG = getConfig();

// do not init sentry in developmen mode
if (ENVIRONMENT !== "development") {
  Sentry.init({
    dsn: SENTRY_ENDPOINT,
    environment: ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "127.0.0.1", API_ENDPOINT],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
      new Sentry.BrowserProfilingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: CONFIG.tracesSampleRate, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: CONFIG.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: CONFIG.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);
