import { useMemo } from "react";

import { ClimateAction } from "@/components/_domain/climate-action/models/climateAction";
import { NO_FOCUS_AREA, NO_FOCUS_AREA_TYPE } from "@/components/_domain/dashboard/dashboard-table/constants";
import { ClimateActionRow } from "@/components/_domain/dashboard/dashboard-table/types";
import { sortFocusArea } from "@/components/_domain/focusArea/models/focus-area-type";
import { PhaseList, PhaseType } from "@/components/_domain/phase/phase-type";
import { StatusList, StatusType } from "@/components/_domain/progress-snapshot/status-types";
import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import { VALID_KEYS } from "@/components/charts/pie-chart";

const DEFAULT_PROGRESS_BY_DATE = {
  [VALID_KEYS.LESS_THAN_3_MONTHS.label]: 0,
  [VALID_KEYS.LESS_THAN_6_MONTHS.label]: 0,
  [VALID_KEYS.LESS_THAN_12_MONTHS.label]: 0,
  [VALID_KEYS.MORE_THAN_12_MONTHS.label]: 0,
};

const getClimateActionStatsPerFocusArea = ({
  acc,
  focusAreaId = NO_FOCUS_AREA,
  focusAreaName = NO_FOCUS_AREA,
  focusAreaType = NO_FOCUS_AREA_TYPE,
  phaseType = PhaseList.unstarted,
  status = StatusList.blocked,
  workspace,
}: {
  acc: Record<string, ClimateActionRow>;
  focusAreaId: string;
  focusAreaName: string;
  focusAreaType: string;
  phaseType: PhaseType;
  status: StatusType;
  workspace: Workspace;
}) => {
  const existingStats = acc[focusAreaId] || {
    workspace,
    focusArea: {
      id: focusAreaId,
      name: focusAreaName,
      type: focusAreaType,
    },
    phase: {
      [PhaseList.unstarted]: 0,
      [PhaseList.started]: 0,
      [PhaseList.completed]: 0,
    },
    status: {
      [StatusList.onTrack]: 0,
      [StatusList.atRisk]: 0,
      [StatusList.offTrack]: 0,
      [StatusList.blocked]: 0,
    },
  };
  return {
    ...acc,
    [focusAreaId]: {
      ...existingStats,
      phase: {
        ...existingStats.phase,
        [phaseType]: (existingStats.phase[phaseType] || 0) + 1,
      },
      status: {
        ...existingStats.status,
        [status]: (existingStats.status[status] || 0) + 1,
      },
    },
  };
};

export interface UseClimateActionDashboardStatsProps {
  workspace: Workspace;
  climateActionList: ClimateAction[];
}

export const useClimateActionDashboardStats = ({
  workspace,
  climateActionList,
}: UseClimateActionDashboardStatsProps) => {
  const climateActionPerPhaseList = useMemo(() => {
    if (!climateActionList) {
      return {};
    }

    return climateActionList?.reduce(
      (acc, climateAction) => {
        const phase = climateAction.phaseInstance?.phase;
        if (phase) {
          if (!acc[phase.type]) {
            acc[phase.type] = {
              count: 0,
              name: phase.name,
            };
          }
          acc[phase.type].count++;
        }
        return acc;
      },
      {} as Record<string, { count: number; name: string }>,
    );
  }, [climateActionList]);

  const completedClimateActionCount = useMemo(() => {
    return climateActionList?.filter((climateAction) => climateAction.phaseInstance?.phase?.type === "Completed")
      .length;
  }, [climateActionList]);

  const climateActionPerProgressSnapshotByDate = useMemo(() => {
    return climateActionList?.reduce(
      (acc, climateAction) => {
        const lastProgressSnapshot =
          climateAction.progressSnapshotList?.[climateAction.progressSnapshotList.length - 1];
        const progressSnapshotDate = lastProgressSnapshot?.snapshotDate;
        if (progressSnapshotDate) {
          const date = new Date(progressSnapshotDate);
          const monthDifferenceInMonths =
            (new Date().getFullYear() - date.getFullYear()) * 12 + (new Date().getMonth() - date.getMonth());
          if (monthDifferenceInMonths < 3) {
            acc[VALID_KEYS.LESS_THAN_3_MONTHS.label] += 1;
          } else if (monthDifferenceInMonths < 6) {
            acc[VALID_KEYS.LESS_THAN_6_MONTHS.label] += 1;
          } else if (monthDifferenceInMonths < 12) {
            acc[VALID_KEYS.LESS_THAN_12_MONTHS.label] += 1;
          } else {
            acc[VALID_KEYS.MORE_THAN_12_MONTHS.label] += 1;
          }
        } else {
          acc[VALID_KEYS.MORE_THAN_12_MONTHS.label] += 1;
        }
        return acc;
      },
      {
        ...DEFAULT_PROGRESS_BY_DATE,
      } as Record<string, number>,
    );
  }, [climateActionList]);

  const climateActionPerFocusArea = useMemo(() => {
    const statsMap = climateActionList?.reduce(
      (acc, climateAction) => {
        const focusAreaId = climateAction.focusAreaInstance?.focusArea?.id;
        const focusAreaName = climateAction.focusAreaInstance?.focusArea?.name;
        const focusAreaType = climateAction.focusAreaInstance?.focusArea?.type;
        const lastProgressSnapshot =
          climateAction.progressSnapshotList?.[climateAction.progressSnapshotList.length - 1];

        const phaseType = (climateAction.phaseInstance?.phase?.type ?? "No Phase") as PhaseType;
        const status = (lastProgressSnapshot?.status ?? "No Status") as StatusType;

        return getClimateActionStatsPerFocusArea({
          acc,
          focusAreaId: focusAreaId ?? NO_FOCUS_AREA,
          focusAreaName: focusAreaName ?? NO_FOCUS_AREA,
          focusAreaType: focusAreaType ?? NO_FOCUS_AREA_TYPE,
          phaseType,
          status,
          workspace,
        });
      },
      {} as Record<string, ClimateActionRow>,
    );

    // Transform the map into an array
    const list = statsMap ? Object.values(statsMap) : [];

    return list.slice().sort((a, b) => {
      const aFocusAreaType = a.focusArea?.type ?? NO_FOCUS_AREA_TYPE;
      const bFocusAreaType = b.focusArea?.type ?? NO_FOCUS_AREA_TYPE;
      return sortFocusArea(bFocusAreaType, aFocusAreaType);
    });
  }, [climateActionList, workspace]);

  return {
    climateActionPerProgressSnapshotByDate: climateActionPerProgressSnapshotByDate ?? DEFAULT_PROGRESS_BY_DATE,
    climateActionTotal: climateActionList?.length,
    completedClimateActionCount,
    climateActionPerFocusArea,
    climateActionPerPhaseList,
  };
};
