import { z } from "zod";

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const unitSchema = z.object({
  id: z.string(),
  workspaceId: z.string(),
  title: z.string(),
  symbol: z.string(),
  keyResults: z.array(z.object({ id: z.string() })),
});

export type Unit = z.infer<typeof unitSchema>;
