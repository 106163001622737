import { FC } from "react";

import {
  ClimateActionActivityFeedTab,
  ClimateActionDetailTab,
  ClimateActionDocumentsTab,
  ClimateActionOverviewTab,
  TabDataKey,
} from ".";

interface ClimateActionTabViewProps {
  workspace: {
    id: string;
  };
  climateAction: {
    id: string;
  };
  tabId: TabDataKey | undefined;
}

export const ClimateActionTabView: FC<ClimateActionTabViewProps> = ({ workspace, climateAction, tabId }) => {
  if (tabId === "overview") {
    return <ClimateActionOverviewTab workspace={workspace} climateAction={climateAction} />;
  }
  if (tabId === "details") {
    return <ClimateActionDetailTab workspace={workspace} climateAction={climateAction} />;
  }
  if (tabId === "activity") {
    return <ClimateActionActivityFeedTab workspace={workspace} climateAction={climateAction} />;
  }
  if (tabId === "documents") {
    return <ClimateActionDocumentsTab workspace={workspace} climateAction={climateAction} />;
  }

  return <ClimateActionOverviewTab workspace={workspace} climateAction={climateAction} />;
};
