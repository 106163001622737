import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const workspaceByUrlQueryDocument = graphql(/* GraphQL */ `
  query workspaceByUrl($input: String!) {
    workspaceByUrl(workspaceUrl: $input) {
      id
      name
      workspaceUrl
      logo
      updatedAt
      createdAt
      phaseList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
      priorityList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
      unitList {
        id
        workspaceId
        title
        symbol
        keyResults {
          id
          climateActionId
        }
      }
      focusAreaList {
        id
        workspaceId
        name
        color
        type
        assignedInstanceList {
          id
        }
      }
      labelList {
        id
        title
        color
        labelGroupId
        workspaceId
        instances {
          id
        }
      }
      labelGroupList {
        id
        title
        color
        allowMultiSelect
        workspaceId
        labels {
          id
          title
          color
          labelGroupId
        }
      }
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);

export const workspaceByIdQueryDocument = graphql(/* GraphQL */ `
  query workspaceById($input: String!) {
    workspaceById(workspaceId: $input) {
      id
      name
      workspaceUrl
      logo
      updatedAt
      createdAt
      phaseList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
      priorityList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
      unitList {
        id
        workspaceId
        title
        symbol
        keyResults {
          id
          climateActionId
        }
      }
      focusAreaList {
        id
        workspaceId
        name
        color
        type
        assignedInstanceList {
          id
        }
      }
      labelList {
        id
        title
        color
        workspaceId
        labelGroupId
        instances {
          id
        }
      }
      labelGroupList {
        id
        title
        color
        allowMultiSelect
        workspaceId
        labels {
          id
          title
          color
          workspaceId
          labelGroupId
        }
      }
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);

// ============== MUTATIONS ======================
export const updateWorkspaceMutationDocument = graphql(/* GraphQL */ `
  mutation updateWorkspace($input: WorkspaceUpdateInput!) {
    updateWorkspace(workspaceUpdateInput: $input) {
      id
      name
      workspaceUrl
      logo
      updatedAt
      createdAt
      phaseList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
      priorityList {
        id
        workspaceId
        name
        color
        type
        instances {
          id
        }
      }
      unitList {
        id
        workspaceId
        title
        symbol
        keyResults {
          id
          climateActionId
        }
      }
      focusAreaList {
        id
        workspaceId
        name
        color
        type
        assignedInstanceList {
          id
        }
      }
      labelList {
        id
        title
        color
        labelGroupId
        workspaceId
        instances {
          id
        }
      }
      labelGroupList {
        id
        title
        color
        allowMultiSelect
        workspaceId
        labels {
          id
          title
          color
          labelGroupId
        }
      }
      workspaceMembershipList {
        id
        userId
        workspaceId
        role
        status
        updatedAt
        createdAt
      }
    }
  }
`);

export const deleteWorkspaceMutationDocument = graphql(/* GraphQL */ `
  mutation deleteWorkspace($input: WorkspaceDeleteInput!) {
    deleteWorkspace(workspaceDeleteInput: $input) {
      id
      name
      workspaceUrl
      logo
      createdAt
      updatedAt
    }
  }
`);

export const sendWorkspaceDeleteCodeEmailMutationDocument = graphql(/* GraphQL */ `
  mutation sendWorkspaceDeleteCodeEmail($input: WorkspaceDeleteCodeCreateInput!) {
    sendWorkspaceDeleteCodeEmail(workspaceDeleteCodeCreateInput: $input) {
      success
      email
      message
    }
  }
`);
