import { FC, PropsWithChildren } from "react";

import AvatarUser from "@/components/_domain/user/avatar-user";
import { Badge } from "@/components/_ui/badge";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { cn } from "@/lib/utils";

import WorkspaceMembershipActionButton from "./workspace-member-action-button";

type WorkspaceMembershipPropType = {
  userId: string;
  workspaceId: string;
  status: string;
  role: string;
};

type UserPropTyp = {
  id: string;
  email: string;
  username?: string | null | undefined;
  fullname?: string | null | undefined;
  profilePicture?: string | null | undefined;
};

export interface WorkspaceMemberListItemPropsWithChildren extends PropsWithChildren {
  workspaceMembership: WorkspaceMembershipPropType;
  user: UserPropTyp;
  className?: string;
}

export const WorkspaceMemberListItem: FC<WorkspaceMemberListItemPropsWithChildren> = ({
  workspaceMembership,
  user,
}) => {
  const { currentUser, isSuperUser } = useCurrentUserContext();
  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspaceMembership.workspaceId,
    action: "manage",
  });

  const shouldShowEditButton = isSuperUser || hasPermissionToManageWorkspace || user.id === currentUser?.id;

  return (
    <div className="flex h-14 min-h-0 flex-row items-center justify-between gap-2 border-b border-solid py-2">
      <div
        className={cn(
          "flex h-min flex-row items-center gap-4",
          workspaceMembership.status === "ACTIVE" ? "" : "grayscale",
        )}
      >
        <a>
          <AvatarUser user={user} />
        </a>
        <div
          className={cn(
            "flex flex-col items-start text-sm",
            workspaceMembership.status === "LEFT" ? "line-through" : "",
            workspaceMembership.status === "SUSPENDED" ? "line-through" : "",
          )}
        >
          {user.fullname && <span className="w-56 font-medium text-foreground">{user.fullname}</span>}
          <span className="w-56 font-light text-muted-foreground">{user.email}</span>
        </div>
      </div>
      <div className="flex flex-row justify-start gap-4 overflow-hidden text-sm font-light" style={{ width: "250px" }}>
        <span>
          {workspaceMembership.status === "ACTIVE" &&
            workspaceMembership.role.charAt(0).toUpperCase() + workspaceMembership.role.slice(1).toLowerCase()}
          {workspaceMembership.status === "PENDING" && (
            <>
              <span className="mr-2">
                {workspaceMembership.role.charAt(0).toUpperCase() + workspaceMembership.role.slice(1).toLowerCase()}
              </span>
              <Badge className="text-xs font-normal" variant="secondary">
                ausstehend
              </Badge>
            </>
          )}
          {workspaceMembership.status === "SUSPENDED" && <span className="text-muted-foreground">deaktiviert</span>}
          {workspaceMembership.status === "LEFT" && <span className="text-muted-foreground">ausgetreten</span>}
        </span>
      </div>
      <div className="flex flex-row items-end gap-4 text-sm">
        <WorkspaceMembershipActionButton
          className={shouldShowEditButton ? "" : "invisible"}
          workspaceMembership={workspaceMembership}
        />
      </div>
    </div>
  );
};

export default WorkspaceMemberListItem;
