import { FC } from "react";

import { PhaseViewOptional } from "@/components/_domain/phase";
import IconCheckmark from "@/components/_icons/IconCheckmark";

export interface PhaseSelectDropdownItemProps {
  phase?: {
    id: string;
  } | null;
  selected?: boolean;
}

export const PhaseSelectDropdownItem: FC<PhaseSelectDropdownItemProps> = ({ phase, selected }) => {
  return (
    <div className="m-1 flex w-full cursor-pointer flex-row items-center gap-2 p-1 text-xs">
      <div className="grow">
        <PhaseViewOptional phase={phase} />
      </div>
      <div className="mr-2 w-3">
        {selected && (
          <IconCheckmark className="mr-2 h-3 w-3 shrink-0 self-end justify-self-center text-foreground/60" />
        )}
      </div>
    </div>
  );
};
