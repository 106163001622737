import { teamByIdQueryDocument, updateTeamMutationDocument } from "@/components/_domain/team/hooks/useTeam/team.gql";
import { EVENT_TEAM_UPDATED, trackTeamEvent } from "@/hooks/api/common/useWorkspace/tracking";
import { useMutation } from "@apollo/client";

type TeamFragment = {
  id: string;
};

export interface UseUpdateTeamProps {
  teamId: string;
  onCompleted?: (o: TeamFragment | undefined) => void;
  onError?: (e: Error) => void;
}

export const useTeamUpdate = ({ teamId, onCompleted, onError }: UseUpdateTeamProps) => {
  const [updateTeam] = useMutation(updateTeamMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const team = data?.updateTeam;
      if (!team) {
        return;
      }
      trackTeamEvent(EVENT_TEAM_UPDATED, team);
    },
    refetchQueries: [
      {
        query: teamByIdQueryDocument,
        variables: { id: teamId },
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.updateTeam?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    updateTeam,
  };
};
