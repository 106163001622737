import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
} from "lexical";
import { $applyNodeReplacement, TextNode } from "lexical";

export interface VariableInterpolationPayload {
  variableName: string;
  key?: NodeKey;
}

export type SerializedInterpolationNode = Spread<
  {
    variableName: string;
  },
  SerializedTextNode
>;

export const INTERPOLATION_VARIABLE_REGEX = "{{\\s*([a-zA-Z_]\\w*(\\.[a-zA-Z_]\\w*)*)\\s*}}";

const matchInterpolationVariable = (text: string) => {
  const regex = `^${INTERPOLATION_VARIABLE_REGEX}$`;
  const match = text.match(regex);
  if (match) {
    return match[1];
  }
  return null;
};

const convertInterpolationElement = (domNode: HTMLElement): null | DOMConversionOutput => {
  if (!domNode.textContent) return null;
  const variableName = matchInterpolationVariable(domNode.textContent);
  if (variableName) {
    const node = $createVariableInterpolationNode({ variableName });
    return { node };
  }
  return null;
};

export class VariableInterpolationNode extends TextNode {
  __variableName: string;

  static getType(): string {
    return "variableInterpolation";
  }

  static clone(node: VariableInterpolationNode): VariableInterpolationNode {
    return new VariableInterpolationNode(node.__variableName);
  }

  getVariableName(): string {
    return this.__variableName;
  }

  static importJSON(serializedNode: SerializedInterpolationNode): TextNode {
    const node = $createVariableInterpolationNode({
      variableName: serializedNode.variableName,
    });
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedInterpolationNode {
    return {
      version: 1,
      type: "variableInterpolation",
      detail: this.getDetail(),
      format: this.getFormat(),
      mode: this.getMode(),
      style: this.getStyle(),
      text: this.getTextContent(),
      variableName: this.getVariableName(),
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.textContent = this.__text;
    element.style.cssText = this.__style;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: () => {
        return {
          conversion: convertInterpolationElement,
          priority: 1,
        };
      },
    };
  }

  constructor(variableName: string, key?: NodeKey) {
    super(`{{ ${variableName} }}`, key);
    this.__variableName = variableName;
  }

  createDOM(): HTMLElement {
    const span = document.createElement("span");
    span.textContent = this.__variableName;
    span.className = "rounded-md bg-gray-200 dark:bg-gray-800 px-1.5 py-0.5";
    return span;
  }

  updateDOM(): false {
    return false;
  }

  isSimpleText() {
    return false;
  }
  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
  isToken(): boolean {
    return true;
  }
  isTextEntity(): boolean {
    return false;
  }

  isUnmergeable(): boolean {
    return true;
  }
}

export const $createVariableInterpolationNode = ({
  variableName,
  key,
}: VariableInterpolationPayload): VariableInterpolationNode => {
  const node = new VariableInterpolationNode(variableName, key);
  return $applyNodeReplacement(node);
};

export const $isVariableInterpolationNode = (
  node: LexicalNode | null | undefined,
): node is VariableInterpolationNode => {
  return node instanceof VariableInterpolationNode;
};
