import { FC } from "react";

import { cn } from "@/lib/utils";

import { LayoutProvider, useLayout } from "./useLayout";
export interface BasePageLayoutProps {
  renderSidebar: () => React.ReactNode;
  renderContent: () => React.ReactNode;
  offsetTop?: number | null | undefined;
}
const Inner: FC<BasePageLayoutProps> = ({ renderContent, renderSidebar }) => {
  const { sidebarState, insetApplication, offsetTop } = useLayout();

  return (
    <div className="flex h-full min-h-screen w-full flex-row overflow-hidden bg-canvas text-foreground">
      {renderSidebar()}

      <div
        className={cn([
          "shadow-sm m-0 flex min-w-0 shrink grow basis-0 flex-col rounded-md border-[0.5px] border-border bg-background md:my-2 md:mr-2 ",
          sidebarState?.isFixed ? "" : "md:ml-2",
        ])}
        style={{
          maxHeight: `calc(100vh - ${insetApplication * 2 + offsetTop}px)`,
          marginTop: `${offsetTop + insetApplication}px`,
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
};

export const BasePageLayout: FC<BasePageLayoutProps> = ({ renderContent, renderSidebar, offsetTop }) => {
  return (
    <LayoutProvider offsetTop={offsetTop ?? 0}>
      <Inner renderContent={renderContent} renderSidebar={renderSidebar} />
    </LayoutProvider>
  );
};
