export const useDate = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return {
    TODAY: today,
  };
};

export const useDateTime = () => {
  return {
    NOW: new Date(),
  };
};
