import { getAllTasksQueryDocument, updateTaskListMutationDocument } from "@/graphql/common/task";
import { useMutation } from "@apollo/client";

import { EVENT_TASK_LIST_UPDATED, trackTaskEvent } from "./tracking";

export const useUpdateTaskListMutation = () => {
  const [updateTaskList] = useMutation(updateTaskListMutationDocument, {
    refetchQueries: [
      {
        query: getAllTasksQueryDocument,
        variables: { input: { statusList: [] } },
      },
    ],
    update: (_cache, { data }) => {
      const taskList = data?.updateTaskList;
      if (!taskList) {
        return;
      }
      trackTaskEvent(EVENT_TASK_LIST_UPDATED, {
        taskIdList: data.updateTaskList.map((task) => task.id),
      });
    },
  });
  return {
    updateTaskList,
  };
};
