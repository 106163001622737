import { toast } from "@/components/_ui/use-toast.ts";
import { type AsChildProps, Slot } from "@/components/slot.tsx";
import { useUpdateLabel } from "@/hooks/api/common/useLabel";

type RemoveLabelFromGroupActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
    labelGroupId?: string | null;
  };
  workspace: {
    id: string;
  };
};

export const RemoveLabelFromGroupAction = ({ label, asChild, ...props }: RemoveLabelFromGroupActionProps) => {
  const Comp = asChild ? Slot : "button";

  const { updateLabel } = useUpdateLabel({
    onCompleted: () => {
      toast({
        title: "Label aus Gruppe entfernt",
        description: `Wir haben die Label erfolgreich aus der Gruppe entfernt.`,
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler beim Entfernen der Gruppe aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
    },
  });

  async function handleClick() {
    await updateLabel({ variables: { input: { id: label.id, labelGroupId: null } } });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default RemoveLabelFromGroupAction;
