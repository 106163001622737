import * as z from "zod";

import { MilestoneSchema } from "@/components/_domain/milestone";
import { stringToJSONSchema } from "@/lib/utils/jsonSchema";

export const UnitSchema = z.object({
  id: z.string(),
  title: z.string(),
  symbol: z.string(),
});
export type Unit = z.infer<typeof UnitSchema>;

export const KeyResultSchema = z.object({
  id: z.string(),
  climateActionId: z.string(),
  unitId: z.string().optional().nullable(),
  type: z.string(),
  title: z.string(),
  description: z.string().optional().nullable(),
  initialValue: z.number(),
  targetValue: z.number(),
  currentValue: z.number(),
  startDate: z.string(),
  targetDate: z.string(),
  unit: UnitSchema.optional().nullable(),
});
export type KeyResult = z.infer<typeof KeyResultSchema>;

export const QuantitativeKeyResultSchema = KeyResultSchema.extend({
  type: z.literal("QUANTITATIVE_KEY_RESULT"),
});
export type QuantitativeKeyResult = z.infer<typeof QuantitativeKeyResultSchema>;

export const MilestoneKeyResultSchema = KeyResultSchema.extend({
  type: z.literal("MILESTONE_KEY_RESULT"),
  properties: stringToJSONSchema.pipe(
    z.object({
      milestones: z.array(MilestoneSchema),
    }),
  ),
});
export type MilestoneKeyResult = z.infer<typeof MilestoneKeyResultSchema>;
