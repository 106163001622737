import { FC, useState } from "react";
import { z } from "zod";

import { Checkbox } from "@/components/_ui/checkbox";

const schema = z.object({
  items: z.array(
    z.object({
      _id: z.string(),
      index: z.number(),
      value: z.string(),
      completed: z.boolean(),
    }),
  ),
});

export type Item = z.infer<typeof schema>["items"][number];

export interface CheckboxMultipleProps {
  onValueChange?: (value: Item[]) => void;
  defaultValue?: Item[];
}

export const CheckboxMultiple: FC<CheckboxMultipleProps> = ({ onValueChange, defaultValue }) => {
  const [items, setItems] = useState<Item[]>(() => (defaultValue ? defaultValue : []));

  const handleCheckChanged = (index: number, newValue: boolean) => {
    const _items = [...items];
    _items[index].completed = newValue;
    setItems(_items);

    if (onValueChange) {
      onValueChange([..._items]);
    }
  };

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div key={item._id} className="mb-1 flex flex-row items-center gap-2">
            <Checkbox
              className="rounded-md border border-gray-300"
              defaultChecked={item.completed}
              onCheckedChange={(e) => {
                if (typeof e !== "boolean") {
                  handleCheckChanged(index, false);
                } else {
                  handleCheckChanged(index, e);
                }
              }}
            />
            <span className="text-xs">{item.value}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxMultiple;
