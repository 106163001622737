import { ReactNode, useEffect, useState } from "react";

import { Loading } from "@/components/loading-view";
import { Column, Table } from "@tanstack/react-table";

import { useClimateActionListState } from "../../hooks/useClimateActionListState";
import { FilterByDatePayload } from "../filter-functions/filter-by-date";
import { FilterByTextListPayload } from "../filter-functions/filter-by-text-list";

const DEBUG_SAVED_VIEW_SYNC = false;

const debugLog = (...args: unknown[]) => {
  if (DEBUG_SAVED_VIEW_SYNC) {
    debugLog(...args);
  }
};

function makeFilterByTextListPayload(textList: string[], mode: "included" | "not_included") {
  return {
    type: "filter_by_text_list" as const,
    mode: mode,
    textList: textList,
  };
}

function makeFilterByDatePayload(date: string, mode: "before" | "after") {
  return {
    type: "filter_by_date" as const,
    mode: mode,
    date: date,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function clearFilter(table: Table<any>) {
  table.setColumnFilters([]);
  table.setGlobalFilter("");
}

function setFilter(column: Column<unknown> | null | undefined, payload: FilterByTextListPayload | FilterByDatePayload) {
  if (!column) {
    return;
  }
  column.setFilterValue(payload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setFilterByPhase(searchParams: URLSearchParams | null | undefined, table: Table<any>) {
  if (!searchParams?.get("phase")) {
    return;
  }
  return setFilter(
    table.getColumn("phase"),
    makeFilterByTextListPayload([searchParams.get("phase") ?? ""], "included"),
  );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setFilterByLastUpdated(searchParams: URLSearchParams | null | undefined, table: Table<any>) {
  if (!searchParams?.get("lastUpdated")) {
    return;
  }

  const mode = searchParams.get("lastUpdated")?.includes("before") ? "before" : "after";
  const date = searchParams.get("lastUpdated")?.split(":")[1] ?? "";

  debugLog("setting filter by last updated", date, mode);
  debugLog(table.getColumn("last_updated"));
  debugLog(makeFilterByDatePayload(date, mode));

  return setFilter(table.getColumn("last_updated"), makeFilterByDatePayload(date, mode));
}

function searchParamsHaveFilters(searchParams: URLSearchParams | null | undefined): boolean {
  return !!(searchParams?.get("phase") || searchParams?.get("lastUpdated"));
}

interface ApplyFiltersFromSearchParamsToTableProps {
  searchParams: URLSearchParams | null;
  children: ReactNode;
}
export const ApplyFiltersFromSearchParamsToTable = ({
  searchParams,
  children,
}: ApplyFiltersFromSearchParamsToTableProps) => {
  const { table } = useClimateActionListState();

  // if we don not have any filters, we don't need to process the search params
  const [searchParamsProcessed, setSearchParamsProcessed] = useState<boolean>(!searchParamsHaveFilters(searchParams));

  useEffect(() => {
    if (searchParamsProcessed) {
      return;
    }
    // no matter how we return, we want to render the page the next time
    setSearchParamsProcessed(true);

    // clear the table filters to avoid having multiple filters on other columns applied
    clearFilter(table);

    setFilterByPhase(searchParams, table);
    setFilterByLastUpdated(searchParams, table);

    debugLog("[7.1] ApplyFiltersFromSearchParamsToTable (useEffect )");
    // We only want to run this once on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!searchParamsProcessed) {
    return <Loading />;
  }
  debugLog("[7] ApplyFiltersFromSearchParamsToTable – children rendered");

  return <>{children}</>;
};
