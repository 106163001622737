import { FC, useState } from "react";

import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { AsChildProps, Slot } from "@/components/slot";
import { removeWorkspaceSettings } from "@/services/workspace.service";

import { WorkspaceDeleteFormWithEmail } from "../workspace-delete-form";

type WorkspaceDeleteDialogWithEmail = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  workspace: {
    id: string;
    name: string;
  };
  redirectAfterDelete?: boolean;
  asChild?: boolean;
  className?: string;
  style?: React.CSSProperties;
};
export const WorkspaceDeleteDialogWithEmail: FC<WorkspaceDeleteDialogWithEmail> = ({
  asChild,
  workspace,
  redirectAfterDelete,
  ...props
}) => {
  const Comp = asChild ? Slot : "button";
  const [dialogisOpen, setDialogisOpen] = useState(false);

  async function handleButtonPressed() {
    setDialogisOpen(true);
  }

  return (
    <>
      <Comp {...props} onClick={handleButtonPressed} />

      <Dialog open={dialogisOpen} onOpenChange={setDialogisOpen}>
        <DialogContent>
          <WorkspaceDeleteFormWithEmail
            workspace={workspace}
            onSuccess={() => {
              setDialogisOpen(false);
              removeWorkspaceSettings();
              if (redirectAfterDelete) {
                window.location.replace(new URL(window.location.origin));
              }
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
