import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from "react";

import { NotFoundView } from "@/components/not-found-view";
import { useWorkspaceGetByUrl } from "@/hooks/api/common/useWorkspace";
import { posthogService } from "@/services/posthog.service";
import { removeWorkspaceSettings, setWorkspaceSettings } from "@/services/workspace.service";
import * as Sentry from "@sentry/react";

import { CurrentWorkspace } from "../models/current-workspace-model";

type WorkspaceContextType = {
  currentWorkspace: CurrentWorkspace;
};

export interface WorkspaceContextProps extends PropsWithChildren {
  workspaceUrl: string;
  userId: string;
}

export const WorkspaceContext = createContext<WorkspaceContextType | null>(null);

export const CurrentWorkspaceContextProvider = ({ children, workspaceUrl, userId }: WorkspaceContextProps) => {
  const { workspace, loading, error } = useWorkspaceGetByUrl({ workspaceUrl: workspaceUrl });

  const workspaceWithMembership = useMemo(() => {
    // Make sure that the "current" workspaceMembership is set
    if (!workspace) {
      return null;
    }

    const wsm = workspace.workspaceMembershipList.find((wsm) => wsm.userId === userId);

    return {
      id: workspace.id,
      name: workspace.name,
      workspaceUrl: workspace.workspaceUrl,
      logo: workspace.logo,
      createdAt: workspace.createdAt,
      updatedAt: workspace.updatedAt,
      workspaceMembership: {
        role: wsm?.role ?? "USER",
        status: wsm?.status ?? "SUSPENDED",
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  useEffect(() => {
    if (error) {
      removeWorkspaceSettings();
    }
  }, [error]);

  useEffect(() => {
    if (!workspaceWithMembership) {
      return;
    }

    setWorkspaceSettings(workspaceWithMembership);
    Sentry.setUser({ workspace: workspaceWithMembership.workspaceUrl });
    posthogService.group("workspace", workspaceWithMembership.id, {
      workspaceId: workspaceWithMembership.id,
      workspaceUrl: workspaceWithMembership.workspaceUrl,
      name: workspaceWithMembership.name,
    });
  }, [workspaceWithMembership]);

  if (loading) {
    return <></>;
  }

  if (error) {
    return <NotFoundView />;
  }

  if (!workspaceWithMembership) {
    return <NotFoundView />;
  }

  return (
    <WorkspaceContext.Provider value={{ currentWorkspace: workspaceWithMembership }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

// Define the useCurrentWorkspace hook
export const useCurrentWorkspaceContext = (): WorkspaceContextType => {
  const context = useContext(WorkspaceContext);

  if (!context) {
    throw new Error("useCurrentWorkspace must be used within a WorkspaceProvider");
  }

  return context;
};

// This is an unsafe hook that returns the current workspace context or NULL
//
// - Generally it is better to use the **useCurrentWorkspaceContext** hook
// - Only use this hook if cannot be sure that the **useCurrentWorkspaceContext** hook is  available
export const useUnsafeCurrentWorkspaceContext = (): WorkspaceContextType | null => {
  return useContext(WorkspaceContext);
};
