import { useMemo, useState } from "react";
import { MoreHorizontal } from "lucide-react";

import { Button } from "@/components/_ui/button";
import { Dialog, DialogContent } from "@/components/_ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { UpdatedWorkspace } from "@/hooks/api/common/useWorkspace";
import { getWorkspaceSettings, removeWorkspaceSettings } from "@/services/workspace.service";
import { Row } from "@tanstack/react-table";

import WorkspaceMemberList from "../../workspace-membership/workspace-member-list";
import { WorkspaceDeleteFormWithEmail } from "../workspace-delete-form";
import { WorkspaceEditForm } from "../workspace-edit-form";

import { workspaceSchema } from "./model";

interface DatatableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DatatableRowActions<TData>({ row }: DatatableRowActionsProps<TData>) {
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [membershipDialogIsOpen, setMembershipDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const workspace = useMemo(() => {
    return workspaceSchema.parse(row.original);
  }, [row]);

  const isCurrentWorkspace = useMemo(() => {
    if (!workspace) {
      return false;
    }

    const storedWorkspace = getWorkspaceSettings();

    if (!storedWorkspace) {
      return false;
    }

    return workspace.id === storedWorkspace.id;
  }, [workspace]);

  const handleWorkspaceUpdated = (_updatedWorkspace: UpdatedWorkspace) => {
    // Let's not close the dialog automatically
    // e.g., if only the logo was updated, admin users might want to make additional changes
    // setDialogisOpen(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex w-full flex-row items-center justify-end">
            <Button variant="ghost" size="icon" className="h-8 w-8 self-end p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem className="cursor-pointer" onClick={() => setEditDialogIsOpen(true)}>
            Bearbeiten
          </DropdownMenuItem>
          <DropdownMenuItem className="cursor-pointer" onClick={() => setMembershipDialogIsOpen(true)}>
            Mitglieder verwalten
          </DropdownMenuItem>

          <DropdownMenuItem className="cursor-pointer" onClick={() => setDeleteDialogIsOpen(true)}>
            Löschen
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={editDialogIsOpen} onOpenChange={setEditDialogIsOpen}>
        <DialogContent className="top-[1%] max-w-xl translate-y-[0%] md:top-[20%]">
          <WorkspaceEditForm workspace={workspace} onUpdateComplete={handleWorkspaceUpdated} />
        </DialogContent>
      </Dialog>

      <Dialog open={membershipDialogIsOpen} onOpenChange={setMembershipDialogIsOpen}>
        <DialogContent className="top-[1%] max-w-3xl translate-y-[0%] md:top-[10%] ">
          <div className="w-full overflow-y-scroll p-8" style={{ maxHeight: "calc(100vh - 15vh)", minHeight: "20rem" }}>
            <WorkspaceMemberList workspace={workspace} />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteDialogIsOpen} onOpenChange={setDeleteDialogIsOpen}>
        <DialogContent className="top-[1%] max-w-xl translate-y-[0%] md:top-[20%]">
          <WorkspaceDeleteFormWithEmail
            workspace={workspace}
            onSuccess={() => {
              if (isCurrentWorkspace) {
                removeWorkspaceSettings();
              }
              setDeleteDialogIsOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
