import { Outlet, useParams } from "react-router-dom";

import { CurrentIndicatorContextProvider } from "@/components/_domain/indicator/hooks/useCurrentIndicatorContext";
import { NotFoundView } from "@/components/not-found-view";

export const IndicatorLayout = () => {
  const { indicatorId } = useParams();

  if (!indicatorId) {
    return <NotFoundView />;
  }

  return (
    <CurrentIndicatorContextProvider indicatorId={indicatorId}>
      <Outlet />
    </CurrentIndicatorContextProvider>
  );
};
