import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { PageHeader } from "@/components/page-header";

import { PhaseTable } from "./phase-table";

export default function Page() {
  const { currentWorkspace } = useCurrentWorkspaceContext();

  return (
    <main className="relative max-h-screen place-items-stretch overflow-auto">
      <HeaderLayout className="border-none"></HeaderLayout>

      <div className="overflow-y-scroll pb-12">
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <PageHeader
              title={currentWorkspace.name}
              subtitle="Verwalten Sie die Liste an Phasen, die Objekten in Ihrem Team zugeordnet werden können"
            />

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <div>
                <div>
                  <p className="font-light text-muted-foreground">
                    Ordnen Sie Maßnahmen in Ihrem Team einer Phase zu, um sie zu organisieren und zu filtern. Die in
                    diesem Abschnitt erstellten Phasen sind für alle Mitglieder des Teams verfügbar.
                  </p>
                  <div className="my-8" />
                  <PhaseTable workspaceId={currentWorkspace.id} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
