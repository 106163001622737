import { useMemo } from "react";

import {
  deleteClimateActionMutationDocument,
  getClimateActionListQueryDocument,
} from "@/graphql/common/climate-action";
import { useMutation } from "@apollo/client";

import { onClimateActionDelete } from "./_utils";
import { EVENT_CLIMATE_ACTION_DELETED, trackClimateActionEvent } from "./tracking";

type ClimateActionFragment = {
  id: string;
};

export interface UseDeleteClimateActionProps {
  onCompleted?: (o: ClimateActionFragment | undefined) => void;
  onError?: (e: Error) => void;
  teamId?: string | undefined;
  workspaceId: string;
}

export const useDeleteClimateAction = ({ onCompleted, onError, teamId, workspaceId }: UseDeleteClimateActionProps) => {
  const refetchQueries = useMemo(() => {
    if (!teamId) {
      return [
        {
          query: getClimateActionListQueryDocument,
          variables: { input: { workspaceId: workspaceId } },
        },
      ];
    }
    return [
      {
        query: getClimateActionListQueryDocument,
        variables: { input: { workspaceId: workspaceId } },
      },
    ];
  }, [teamId, workspaceId]);

  const [deleteClimateAction] = useMutation(deleteClimateActionMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const climateAction = data?.deleteClimateAction;
      if (!climateAction) {
        return;
      }
      onClimateActionDelete(cache, climateAction);

      trackClimateActionEvent(EVENT_CLIMATE_ACTION_DELETED, {
        id: climateAction.id,
        title: climateAction.title,
        workspaceId: climateAction.workspaceId,
      });
    },
    refetchQueries: refetchQueries,
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const id = o?.deleteClimateAction?.id;

    if (onCompleted) {
      if (id) {
        onCompleted({ id: id });
      } else {
        onCompleted(undefined);
      }
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteClimateAction,
  };
};
