import { FC, useState } from "react";
import { HiChartBar } from "react-icons/hi2";

import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import { Button } from "@/components/_ui/button";
import { Dialog, DialogContentWithoutClose } from "@/components/_ui/dialog";
import { DeleteConfirmation } from "@/components/delete-confirmation";
import { Cross2Icon } from "@radix-ui/react-icons";

import {
  IndicatorCreateContextProvider,
  useIndicatorCreateContext,
  useUnsafeIndicatorCreateContext,
} from "./indicator-forms/hooks/useIndicatorCreateContext";
import { IndicatorCreateForm } from "./indicator-forms/indicator-create-form";

export interface IndicatorCreateDialogProps {
  workspace: Workspace;
}

export const InnerIndicatorCreateDialog: FC<IndicatorCreateDialogProps> = ({ workspace }) => {
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
  const { hasActiveChanges, resetNewIndicatorForm, showModal, setShowModal } = useIndicatorCreateContext();

  if (!workspace) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="ghost"
        size="xs"
        className="shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75"
        onClick={() => setShowModal(true)}
      >
        <HiChartBar className="mr-2 h-4 w-4" />
        Indikator erstellen
        {hasActiveChanges && <div className="ml-1 h-1.5 w-1.5 rounded-full bg-primary"></div>}
      </Button>
      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContentWithoutClose className="top-[1%] m-0 max-w-3xl translate-y-[0%] p-0 md:top-[20%]">
          <IndicatorCreateForm
            workspace={workspace}
            onSuccess={() => {
              setShowModal(false);
            }}
          />
          <div
            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:cursor-pointer hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500 dark:ring-offset-slate-950 dark:focus:ring-slate-300 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-400"
            onClick={() => {
              if (hasActiveChanges) {
                setDeleteConfirmationIsOpen(true);
              } else {
                resetNewIndicatorForm();
                setShowModal(false);
              }
            }}
          >
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </div>
        </DialogContentWithoutClose>
      </Dialog>
      <DeleteConfirmation
        open={deleteConfirmationIsOpen}
        onOpenChange={setDeleteConfirmationIsOpen}
        onConfirm={() => {
          resetNewIndicatorForm();
          setShowModal(false);
        }}
        title="Indikator verwerfen?"
        description="Sind Sie sich sicher, dass Sie diesen Indikator verwerfen möchten?"
        confirmButtonText="Verwerfen"
        cancelButtonText="Abbrechen"
      />
    </>
  );
};

export const IndicatorCreateDialog: FC<IndicatorCreateDialogProps> = ({ workspace }) => {
  // is "unsafe" because it returns a nullable value. We use this to optionally initialize the context
  // at a higher point in the component tree
  const indicatorCreateContext = useUnsafeIndicatorCreateContext();

  // Make sure the form is wrapped in the IndicatorCreateContextProvider
  if (!indicatorCreateContext) {
    return (
      <IndicatorCreateContextProvider workspaceId={workspace.id}>
        <InnerIndicatorCreateDialog workspace={workspace} />
      </IndicatorCreateContextProvider>
    );
  }

  return <InnerIndicatorCreateDialog workspace={workspace} />;
};
