import { createFileMutationDocument, fileListForOwnerQueryDocument } from "@/graphql/common/file";
import { useMutation } from "@apollo/client";

import { EVENT_FILE_CREATED, trackFileEvent } from "./tracking";

export interface UseCreateFileProps {
  ownerId: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreateFile = ({ ownerId, onCompleted, onError }: UseCreateFileProps) => {
  const [createFile] = useMutation(createFileMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: fileListForOwnerQueryDocument,
        variables: { input: { ownerId: ownerId } },
      },
    ],
    update: (_cache, { data }) => {
      const file = data?.createFile;
      if (!file) {
        return;
      }

      trackFileEvent(EVENT_FILE_CREATED, {
        id: file.id,
        name: file.name,
        ownerId: file.ownerId,
      });
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createFile,
  };
};
