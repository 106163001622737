import { FC } from "react";
import { HiSquares2X2 } from "react-icons/hi2";

import { cn } from "@/lib/utils";

export interface FilterByFocusAreaDisplayViewProps {
  className?: string;
}

export const FilterByFocusAreaDisplayView: FC<FilterByFocusAreaDisplayViewProps> = ({ className }) => {
  return (
    <>
      <HiSquares2X2 className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">Handlungsfeld</span>
    </>
  );
};
