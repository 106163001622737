import { ClimateAction } from "@/components/_domain/climate-action/models";
import { ColumnDef } from "@tanstack/react-table";

import { ClimateActionListCellTitle, ClimateActionListColumnHeader } from "../..";
import { filterByTextWithUnknownPayload } from "../../filter-functions";
import { ClimateActionColumnConfiguration } from "../column-definition-type";

import { FilterByTitleConfigurationView, FilterByTitleDisplayView, FilterByTitleFilterPaneView } from "./components";

interface ClimateActionTitleColumnDefinitionProps {
  workspaceId: string;
  columnId: string;
  orderValue: number;
}

export const ClimateActionTitleColumnDefinition = ({
  workspaceId,
  columnId,
  orderValue,
}: ClimateActionTitleColumnDefinitionProps): ClimateActionColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Titel",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<ClimateAction> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: false,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          return rowA.original.title.localeCompare(rowB.original.title);
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          return filterByTextWithUnknownPayload(value, row.original.title);
        },
        header: ({ column }) => (
          <ClimateActionListColumnHeader column={column} title="" className={`px-1 text-xs font-normal`} />
        ),
        cell: ({ row }) => (
          <ClimateActionListCellTitle row={row} climateAction={row.original} workspace={{ id: workspaceId }} />
        ),
      };
    },
    getDisplayView: () => <FilterByTitleDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByTitleConfigurationView column={{ id: columnId }} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "modal",
    getFilterPaneView: () => <FilterByTitleFilterPaneView column={{ id: columnId }} />,
  };
};
