import { Card, CardContent, CardHeader, CardTitle } from "@/components/_ui/card";

export const SidebarSettingsNoTeamCard = () => {
  return (
    <Card className="mt-4 text-sm">
      <CardHeader>
        <CardTitle>💡 Hinweis</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col flex-wrap gap-4 text-xs">
        <p>Es scheint, als ob du noch nicht Teil von einem Team bist.</p>
        <p>
          Du kannst andere Nutzer des Workspaces fragen, ob sie dich zu ihrem Team hinzufügen oder du kannst selbst ein
          Team erstellen.
        </p>
      </CardContent>
    </Card>
  );
};
