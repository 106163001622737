import { createContext, type FC, type PropsWithChildren, useContext } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useGetFocusAreaList } from "@/hooks/api/common/useFocusArea";

import { type FocusArea } from "../models/focus-area-schema";

type CurrentFocusAreaListContext = {
  currentFocusAreaList: FocusArea[];
};

const CurrentFocusAreaListContext = createContext<CurrentFocusAreaListContext | null>(null);

export interface CurrentFocusAreaListContextProviderProps extends PropsWithChildren {
  workspaceId: string;
  required?: boolean;
}

export const CurrentFocusAreaListContextProvider: FC<CurrentFocusAreaListContextProviderProps> = ({
  children,
  workspaceId,
  required = true,
}) => {
  const { focusAreaList, loading, error } = useGetFocusAreaList({ workspaceId });

  if (loading) {
    return <Loading />;
  }

  if (error && required) {
    return <ErrorView />;
  }

  return (
    <CurrentFocusAreaListContext.Provider value={{ currentFocusAreaList: focusAreaList }}>
      {children}
    </CurrentFocusAreaListContext.Provider>
  );
};

export const useCurrentFocusAreaListContext = () => {
  const context = useContext(CurrentFocusAreaListContext);

  if (!context) {
    throw new Error("useCurrentFocusAreaListContext must be used within a CurrentFocusAreaListContextProvider");
  }

  return context;
};
