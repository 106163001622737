import { graphql } from "../generated/gql";

// ============== QUERIES ======================
export const getAllTasksQueryDocument = graphql(/* GraphQL */ `
  query GetAllTasks($input: GetAllTaskListInput!) {
    getAllTasks(getAllTaskListForUserInput: $input) {
      id
      userId
      title
      description
      status
      position
      createdAt
      updatedAt
    }
  }
`);

// ============== MUTATIONS ======================
export const updateTaskMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(updateTaskInput: $input) {
      id
      userId
      title
      description
      status
      position
      createdAt
      updatedAt
    }
  }
`);

export const updateTaskListMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateTaskList($input: UpdateTaskListInput!) {
    updateTaskList(updateTaskListInput: $input) {
      id
      userId
      title
      description
      status
      position
      createdAt
      updatedAt
    }
  }
`);

export const createTaskMutationDocument = graphql(/* GraphQL */ `
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(createTaskInput: $input) {
      id
      userId
      title
      description
      status
      position
      createdAt
      updatedAt
    }
  }
`);

export const deleteTaskMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteTask($input: DeleteTaskByIdInput!) {
    deleteTask(deleteTaskByIdInput: $input) {
      id
      userId
      title
      description
      status
      position
      createdAt
      updatedAt
    }
  }
`);
