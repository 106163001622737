import { FC, useMemo, useState } from "react";

import { TeamIcon } from "@/components/_domain/team/team-icon";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common/useWorkspace";
import { Cross2Icon } from "@radix-ui/react-icons";

import { useClimateActionListState } from "../../../../hooks";
import { FilterByIdMode, FilterByIdPayload, FilterByIdPayloadSchema } from "../../../filter-functions";

import { FilterByTeamConfigurationView, FilterByTeamDisplayView } from ".";

interface TeamInfo {
  id: string;
  name: string;
  logo?: string | null;
}

export interface FilterByTeamFilterPaneViewProps {
  column: {
    id: string;
  };
}

export const FilterByTeamFilterPaneView: FC<FilterByTeamFilterPaneViewProps> = ({ ...props }) => {
  const { table, columnFilters } = useClimateActionListState();
  const [showSelectItemPopover, setShowSelectItemPopover] = useState(false);
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentUser } = useCurrentUserContext();
  const { teamList } = useTeamListForUserInWorkspace({
    workspaceId: currentWorkspace.id,
    userId: currentUser?.id,
  });

  const availableTeams = useMemo(() => {
    return teamList.filter((team) =>
      team.teamMembershipList.some((membership) => membership.userId === currentUser?.id),
    );
  }, [teamList, currentUser?.id]);

  const column = useMemo(() => {
    return table.getColumn(props.column.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const filter = useMemo(() => {
    if (!column) {
      return null;
    }
    if (!column.getCanFilter()) {
      return null;
    }
    const filterValue = column.getFilterValue();
    if (!filterValue) {
      return null;
    }
    return filterValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, columnFilters]);

  const filterByIdFilter = useMemo(() => {
    if (!filter) {
      return null;
    }

    const safePayload = FilterByIdPayloadSchema.safeParse(filter);

    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [filter]);

  const filterModes: FilterByIdMode[] = useMemo(() => {
    if (!filterByIdFilter) {
      return [];
    }
    return ["included", "not_included"];
  }, [filterByIdFilter]);

  const teamsForActiveFilter = useMemo(() => {
    if (!filterByIdFilter) {
      return [];
    }

    return filterByIdFilter.idList.flatMap((id): TeamInfo[] => {
      if (id === null) {
        return [{ id: "null", name: "Nicht zugewiesen", logo: null }];
      }
      if (id === "workspace") {
        return [{ id: "workspace", name: "Workspace", logo: null }];
      }
      const team = availableTeams.find((t) => t.id === id);
      if (!team) {
        return [];
      }
      return [
        {
          id: team.id,
          name: team.name,
          logo: team.logo,
        },
      ];
    });
  }, [filterByIdFilter, availableTeams]);

  const setFilter = (payload: FilterByIdPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const setFilterMode = (filterMode: FilterByIdMode) => {
    if (!filterByIdFilter) {
      return;
    }
    setFilter({
      type: "filter_by_id",
      mode: filterMode,
      idList: filterByIdFilter.idList,
    });
  };

  if (!column) {
    return <></>;
  }

  if (!filter) {
    return <></>;
  }

  if (filterByIdFilter) {
    return (
      <div className="flex flex-row flex-nowrap items-center gap-0 whitespace-nowrap rounded-md border p-0 text-xs">
        <div className="flex flex-row items-center gap-1 pl-1 text-xs">
          <FilterByTeamDisplayView />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
            >
              {filterByIdFilter.mode === "included" && filterByIdFilter.idList.length === 1 && <>ist</>}
              {filterByIdFilter.mode === "included" && filterByIdFilter.idList.length !== 1 && <>ist Teil von</>}
              {filterByIdFilter.mode === "not_included" && filterByIdFilter.idList.length === 1 && <>ist nicht</>}
              {filterByIdFilter.mode === "not_included" && filterByIdFilter.idList.length !== 1 && (
                <>ist nicht Teil von</>
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filterModes.map((mode) => {
              return (
                <DropdownMenuItem
                  key={mode}
                  onClick={() => {
                    setFilterMode(mode);
                  }}
                >
                  {mode === "included" && filterByIdFilter.idList.length === 1 && <>ist</>}
                  {mode === "included" && filterByIdFilter.idList.length !== 1 && <>ist Teil von</>}
                  {mode === "not_included" && filterByIdFilter.idList.length === 1 && <>ist nicht</>}
                  {mode === "not_included" && filterByIdFilter.idList.length !== 1 && <>ist nicht Teil von</>}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>

        <Popover open={showSelectItemPopover} onOpenChange={setShowSelectItemPopover}>
          <PopoverTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
            >
              {filterByIdFilter.idList.length > 1 && (
                <>
                  <div className="mr-1 flex flex-row items-center">
                    {teamsForActiveFilter.map((team) => (
                      <span key={team.id} className="mr-1">
                        {team.logo ? team.logo : team.name.charAt(0)}
                      </span>
                    ))}
                  </div>
                  {`${filterByIdFilter.idList.length} Teams`}
                </>
              )}
              {filterByIdFilter.idList.length === 1 && teamsForActiveFilter[0] && (
                <>
                  {teamsForActiveFilter[0].logo ? (
                    teamsForActiveFilter[0].logo
                  ) : teamsForActiveFilter[0].id === "null" ? (
                    <TeamIcon team={{ id: "null" }} className="mr-1" />
                  ) : (
                    teamsForActiveFilter[0].name.charAt(0)
                  )}{" "}
                  {teamsForActiveFilter[0].name}
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0" side={"bottom"} align={"start"}>
            <FilterByTeamConfigurationView
              column={column}
              onFinished={() => {
                setShowSelectItemPopover(false);
              }}
            />
          </PopoverContent>
        </Popover>
        <Button
          variant="ghost"
          size="xs"
          className="h-6 w-full justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          onClick={() => {
            clearFilter();
          }}
        >
          <Cross2Icon className="h-3.5 w-3.5 text-foreground/60" />
        </Button>
      </div>
    );
  }

  return <></>;
};
