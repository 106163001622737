import { forwardRef } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import { PrefetchConfig, PrefetchHandler } from "./preloader/prefetch-handler";

interface LinkProps extends RouterLinkProps {
  prefetchConfig?: Omit<PrefetchConfig, "to">;
}

/**
 * Custom Link component that extends React Router's Link with prefetching capability
 * @param props - Component props including standard Link props and custom onMouseEnter handler
 * @param ref - Forwarded ref from parent component
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(({ prefetchConfig = {}, ...props }, ref) => {
  return (
    <PrefetchHandler prefetchConfig={{ ...prefetchConfig, to: props.to.toString() }}>
      <RouterLink ref={ref} {...props} />
    </PrefetchHandler>
  );
});

Link.displayName = "Link";
