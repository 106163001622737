import {
  SavedView,
  savedViewSchema,
  TableConfiguration,
  tableConfigurationSchema,
} from "../../models/saved-views-model";

export function parseFilterData(tableConfiguration: string): TableConfiguration | null {
  try {
    const parsedFilters = JSON.parse(tableConfiguration);
    return tableConfigurationSchema.parse(parsedFilters);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function parseSavedView(viewToSave: unknown): SavedView | null {
  if (!viewToSave) {
    return null;
  }

  const view = viewToSave as { tableConfiguration: string };
  const parsedFilterData = parseFilterData(view.tableConfiguration);
  if (!parsedFilterData) {
    console.error("Could not parse table configuration");

    return null;
  }
  const result = savedViewSchema.safeParse({ ...viewToSave, tableConfiguration: parsedFilterData });
  if (!result.success) {
    console.error("Could not parse saved view");
    return null;
  }
  return result.data;
}
