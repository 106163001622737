import { FC } from "react";

import { AvatarWorkspace } from "@/components/_domain/workspace/avatar-workspace";
import { Workspace } from "@/components/_domain/workspace/models/workspace-model";
import { Badge } from "@/components/_ui/badge";

export interface IndicatorHeaderProps {
  workspace: Workspace;
}

export const IndicatorHeader: FC<IndicatorHeaderProps> = ({ workspace }) => {
  return (
    <div className="flex flex-row items-center gap-1 px-3">
      <Badge variant="outline" className="py-2 opacity-40">
        <div className="flex flex-row items-center gap-1">
          <AvatarWorkspace workspace={workspace} className="h-5 w-5 text-xxs" />
          <span className="ml-1 overflow-hidden text-ellipsis font-normal">{workspace.name}</span>
        </div>
      </Badge>
      <span className="ml-1 mr-1">{"›"}</span>
      <div className="block max-w-[8rem] overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs">
        Indikator
      </div>
    </div>
  );
};
