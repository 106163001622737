import { useMemo } from "react";

import { getClimateActionQueryDocument } from "@/graphql/common/climate-action";
import { getKeyResultQueryDocument } from "@/graphql/common/key-result";
import {
  createProgressSnapshotMutationDocument,
  getProgressSnapshotsForKeyResultQueryDocument,
  getProgressSnapshotsQueryDocument,
} from "@/graphql/common/progress-snapshot";
import { useMutation } from "@apollo/client";

import { EVENT_PROGRESS_SNAPSHOT_CREATED, trackProgressSnapshotEvent } from "./tracking";

export interface UseCreateProgressSnapshotProps {
  climateActionId: string;
  keyResultId?: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreateProgressSnapshot = ({
  climateActionId,
  keyResultId,
  onCompleted,
  onError,
}: UseCreateProgressSnapshotProps) => {
  const refetchQueries = useMemo(() => {
    if (keyResultId) {
      return [
        {
          query: getClimateActionQueryDocument,
          variables: { input: { id: climateActionId } },
        },
        {
          query: getProgressSnapshotsQueryDocument,
          variables: { input: { climateActionId: climateActionId } },
        },
        {
          query: getKeyResultQueryDocument,
          variables: { input: { id: keyResultId } },
        },
        {
          query: getProgressSnapshotsForKeyResultQueryDocument,
          variables: { input: { keyResultId: keyResultId } },
        },
      ];
    }

    return [
      {
        query: getClimateActionQueryDocument,
        variables: { input: { id: climateActionId } },
      },
      {
        query: getProgressSnapshotsQueryDocument,
        variables: { input: { climateActionId: climateActionId } },
      },
    ];
  }, [climateActionId, keyResultId]);

  const [createProgressSnapshot] = useMutation(createProgressSnapshotMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
    update: (_cache, { data }) => {
      const progressSnapshot = data?.createProgressSnapshot;
      if (!progressSnapshot) {
        return;
      }
      trackProgressSnapshotEvent(EVENT_PROGRESS_SNAPSHOT_CREATED, progressSnapshot);
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createProgressSnapshot,
  };
};
