import { FC } from "react";

import { PrioritySelectDropdown, PriorityViewOptional } from "@/components/_domain/priority";
import { Button } from "@/components/_ui/button";
import { useGetClimateAction } from "@/hooks/api/common/useClimateAction/useGetClimateAction";
import {
  useCreatePriorityInstance,
  useDeletePriorityInstance,
  useGetPriorityInstance,
  useUpdatePriorityInstance,
} from "@/hooks/api/common/usePriority";

interface ClimateActionPriorityViewProps {
  climateAction: {
    id: string;
  };
}

export const ClimateActionPriorityView: FC<ClimateActionPriorityViewProps> = ({ ...props }) => {
  const { climateAction } = useGetClimateAction(props.climateAction);
  const { priorityInstance } = useGetPriorityInstance({ id: climateAction?.priorityInstance?.id });
  const { updatePriorityInstance } = useUpdatePriorityInstance(); // always prefer this one
  const { createPriorityInstance } = useCreatePriorityInstance();
  const { deletePriorityInstance } = useDeletePriorityInstance();

  function assignPriority(priority: { id: string }) {
    if (!climateAction) {
      return;
    }

    if (priorityInstance) {
      updatePriorityInstance({
        variables: {
          input: {
            id: priorityInstance.id,
            priorityId: priority.id,
          },
        },
      });
    } else {
      createPriorityInstance({
        variables: {
          input: {
            priorityId: priority.id,
            ownerId: props.climateAction.id,
            ownerType: "CLIMATE_ACTION",
            workspaceId: climateAction.workspaceId,
          },
        },
      });
    }
  }

  function removePriority() {
    if (!priorityInstance) {
      return;
    }
    deletePriorityInstance({
      variables: { input: { id: priorityInstance.id } },
    });
  }

  const handleOnPrioritySelect = (priority: { id: string } | null) => {
    if (!priority) {
      return removePriority();
    }
    assignPriority(priority);
  };

  if (!climateAction) {
    return <></>;
  }

  return (
    <div className="mb-5 flex w-full flex-row items-start gap-2 overflow-x-hidden">
      <div className="mt-2 w-24 shrink-0 font-medium">Priorität</div>
      <div className="grow">
        <PrioritySelectDropdown
          workspaceId={climateAction.workspaceId}
          onSelect={handleOnPrioritySelect}
          selected={priorityInstance}
          asChild
        >
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          >
            <PriorityViewOptional priority={priorityInstance ? { id: priorityInstance.priorityId } : null} />
          </Button>
        </PrioritySelectDropdown>
      </div>
    </div>
  );
};

export default ClimateActionPriorityView;
