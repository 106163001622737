import { FC } from "react";

import { ClimateAction } from "@/components/_domain/climate-action/models";
import { FocusAreaSelectDropdown, FocusAreaViewOptional } from "@/components/_domain/focusArea";
import { Button } from "@/components/_ui/button";
import {
  useCreateFocusAreaInstance,
  useDeleteFocusAreaInstance,
  useUpdateFocusAreaInstance,
} from "@/hooks/api/common/useFocusArea";
import { cn } from "@/lib/utils";
import { Row } from "@tanstack/react-table";

interface ClimateActionListCellFocusAreaProps {
  row: Row<ClimateAction>;
  workspace: { id: string };
  className?: string;
  config: {
    hideText: boolean;
  };
}

export const ClimateActionListCellFocusArea: FC<ClimateActionListCellFocusAreaProps> = ({
  row,
  workspace,
  config,
  className,
}) => {
  const climateAction = row.original;
  const focusAreaInstance = row.original.focusAreaInstance;

  const { updateFocusAreaInstance } = useUpdateFocusAreaInstance(); // always prefer this one
  const { createFocusAreaInstance } = useCreateFocusAreaInstance();
  const { deleteFocusAreaInstance } = useDeleteFocusAreaInstance();

  function assignFocusArea(focusArea: { id: string }) {
    if (!climateAction) {
      return;
    }

    if (focusAreaInstance) {
      updateFocusAreaInstance({
        variables: {
          input: {
            id: focusAreaInstance.id,
            focusAreaId: focusArea.id,
          },
        },
      });
    } else {
      createFocusAreaInstance({
        variables: {
          input: {
            focusAreaId: focusArea.id,
            ownerId: climateAction.id,
            ownerType: "CLIMATE_ACTION",
          },
        },
      });
    }
  }

  function removeFocusArea() {
    if (!focusAreaInstance) {
      return;
    }
    deleteFocusAreaInstance({
      variables: { input: { id: focusAreaInstance.id } },
    });
  }

  const handleOnSelect = (focusArea: { id: string } | null) => {
    if (!focusArea) {
      return removeFocusArea();
    }
    assignFocusArea(focusArea);
  };

  if (!climateAction) {
    return <></>;
  }

  return (
    <FocusAreaSelectDropdown
      workspace={{ id: workspace.id }}
      onSelect={handleOnSelect}
      selected={focusAreaInstance}
      side="bottom"
      asChild
    >
      <Button
        variant="ghost"
        size={config.hideText ? "icon" : "xs"}
        className={cn(
          [
            "overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0",
          ],
          className,
        )}
      >
        <FocusAreaViewOptional
          focusArea={focusAreaInstance ? { id: focusAreaInstance.focusAreaId } : null}
          logoOnly={config.hideText}
          className={cn(["h-3 w-3.5", focusAreaInstance ? "" : "text-muted-foreground"])}
        />
      </Button>
    </FocusAreaSelectDropdown>
  );
};
