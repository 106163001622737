import { FC } from "react";
import { Calendar } from "lucide-react";

import { cn } from "@/lib/utils/cn";

export interface FilterByLastUpdatedDisplayViewProps {
  className?: string;
}

export const FilterByLastUpdatedDisplayView: FC<FilterByLastUpdatedDisplayViewProps> = ({ className }) => {
  return (
    <>
      <Calendar className={cn(["h-4 w-4 text-foreground/60", className])} />
      <span className="text-xs">Zuletzt aktualisiert</span>
    </>
  );
};
