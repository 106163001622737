import {
  teamByIdQueryDocument,
  teamsForUserInWorkspaceQueryDocument,
} from "@/components/_domain/team/hooks/useTeam/team.gql";
import { teamMembershipsForTeamQueryDocument } from "@/components/_domain/team/hooks/useTeam/team-membership.gql";
import { Superuser_UpdateTeamMembershipStatusMutation } from "@/graphql/generated/graphql";
import {
  superuser_teamListForWorkspaceQueryDocument,
  superuser_updateTeamMembershipStatusMutationDocument,
} from "@/graphql/superuser";
import { useMutation } from "@apollo/client";

export type UpdatedTeamMembership =
  Superuser_UpdateTeamMembershipStatusMutation["superuser_updateTeamMembershipStatus"];

export interface UseUpdateTeamMembershipStatusProps {
  team: {
    id: string;
    workspaceId: string;
  };
  onCompleted?: (o: UpdatedTeamMembership | undefined) => void;
  onError?: (e: Error) => void;
}

export const useTeamMembershipUpdateStatus = ({
  onCompleted,
  onError,
  ...props
}: UseUpdateTeamMembershipStatusProps) => {
  const [updateStatus, queryResult] = useMutation(superuser_updateTeamMembershipStatusMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
      {
        query: teamByIdQueryDocument,
        variables: { input: props.team.id },
      },
      {
        query: teamsForUserInWorkspaceQueryDocument,
        variables: { input: props.team.workspaceId },
      },
      {
        query: superuser_teamListForWorkspaceQueryDocument,
        variables: { input: props.team.workspaceId },
      },
    ],
  });

  // eslint-disable-next-line
  function onUpdateCompleted(o: any) {
    const createdTeamMembership = o?.superuser_updateTeamMembershipStatus as UpdatedTeamMembership;

    if (onCompleted) {
      onCompleted(createdTeamMembership ?? undefined);
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    updateStatus,
  };
};
