import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";

import { FocusAreaType } from "@/components/_domain/focusArea";
import { FocusAreaIcon } from "@/components/_domain/focusArea";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { ColorPicker } from "@/components/color-picker/color-picker";
import { useCreateFocusArea } from "@/hooks/api/common/useFocusArea";
import { cn } from "@/lib/utils";

const DEFAULT_FOCUS_AREA_COLOR = "#95999F";

export interface FocusAreaFormCreateProps extends PropsWithChildren {
  workspace: {
    id: string;
  };
  focusAreaType: FocusAreaType;
  onSuccess?: () => void;
  onCanceled?: () => void;
  className?: string;
}

export const FocusAreaFormCreate: FC<FocusAreaFormCreateProps> = ({
  workspace,
  focusAreaType,
  onSuccess,
  onCanceled,
  className,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [newFocusAreaName, setNewFocusAreaName] = useState("");
  const [newFocusAreaColor, setNewFocusAreaColor] = useState(DEFAULT_FOCUS_AREA_COLOR);

  const { createFocusArea } = useCreateFocusArea({
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleCreateNewFocusArea() {
    if (newFocusAreaName === "" || newFocusAreaColor === "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Titel und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await createFocusArea({
      variables: {
        input: {
          workspaceId: workspace.id,
          name: newFocusAreaName,
          color: newFocusAreaColor,
          type: focusAreaType,
        },
      },
    });
  }

  function reset() {
    // do not reset the color; the most likely case is that users want to create several objects of the same color
    // setNewFocusAreaColor(DEFAULT_FOCUS_AREA_COLOR)
    setNewFocusAreaName("");
    ref.current?.focus();
  }

  function onUpdateCompleted() {
    reset();
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      className={cn(["my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2", className])}
    >
      <div className="w-9">
        <ColorPicker
          asChild
          value={newFocusAreaColor}
          onChange={(color) => {
            setNewFocusAreaColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <FocusAreaIcon className="h-4 w-4" focusArea={{ type: focusAreaType, color: newFocusAreaColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={newFocusAreaName}
          onChange={(e) => setNewFocusAreaName(e.currentTarget.value)}
          placeholder="Name des Handlungsfeldes"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={newFocusAreaName === ""}
          onClick={handleCreateNewFocusArea}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default FocusAreaFormCreate;
