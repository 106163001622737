import { useLocation, useSearchParams } from "react-router-dom";

import { ClimateActionListOverview } from "@/components/_domain/climate-action/climate-action-list-overview";
import { ClimateActionListConfigurationView } from "@/components/_domain/climate-action/climate-action-list-overview/climate-action-list-configuration-pane-view";
import { ClimateActionListFilterView } from "@/components/_domain/climate-action/climate-action-list-overview/climate-action-list-filter-pane-view";
import { ClimateActionListProviderStackInitializer } from "@/components/_domain/climate-action/climate-action-list-overview/utils/climate-action-list-provider-stack-initializer";
import { useCurrentClimateActionListContext } from "@/components/_domain/climate-action/hooks/useClimateActionListContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import IconMyClimateActions from "@/components/_icons/IconMyClimateActions";
// Actual Page
import { HeaderLayout } from "@/components/_layout/header-layout";
import { Link } from "@/components/link";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";
// Page Context
import { useCurrentUserContext } from "@/hooks/api/common/useUser";

// Page Instrumentalization (Providers, Table Configuration, etc.)
import { useTableConfigurationForMyClimateActionPage } from "./useTableConfigurationForMyClimateActionPage";

export function MyClimateActionsPage() {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { currentUser } = useCurrentUserContext();
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentClimateActionList } = useCurrentClimateActionListContext();

  // the initial default table configuration
  const tableConfiguration = useTableConfigurationForMyClimateActionPage({
    workspace: currentWorkspace,
  });

  return (
    <ClimateActionListProviderStackInitializer
      pathname={pathname}
      searchParams={searchParams}
      workspace={currentWorkspace}
      user={currentUser}
      climateActionList={currentClimateActionList}
      config={tableConfiguration}
    >
      <div className="flex h-full max-h-full flex-col overflow-y-hidden">
        <HeaderLayout>
          <div className="flex flex-row items-center gap-2">
            <Link to={`/${currentWorkspace.workspaceUrl}/my-climate-actions`} className="flex flex-row items-center">
              <IconMyClimateActions className="mr-2 h-3 w-3 text-foreground" />
              <span className="flex flex-row items-center">Meine Maßnahmen</span>
            </Link>
            <FavoriteButton pageName="Meine Maßnahmen" />
          </div>
        </HeaderLayout>

        <ClimateActionListConfigurationView
          config={{
            quicksearch: true,
            filterButton: true,
            displayOptionsButton: true,
            savedViewsMenu: true,
          }}
        />
        <ClimateActionListFilterView />
        <ClimateActionListOverview />
      </div>
    </ClimateActionListProviderStackInitializer>
  );
}
