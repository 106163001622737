import {
  acceptTeamInviteMutationDocument,
  teamMembershipsForTeamQueryDocument,
} from "@/components/_domain/team/hooks/useTeam/team-membership.gql";
import { EVENT_TEAM_INVITE_ACCEPTED, trackTeamEvent } from "@/hooks/api/common/useWorkspace/tracking";
import { useMutation } from "@apollo/client";

export interface UseAcceptTeamMembershipInviteProps {
  team: {
    id: string;
  };
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useTeamInviteAccept = ({ onCompleted, onError, ...props }: UseAcceptTeamMembershipInviteProps) => {
  const [acceptInvitation, queryResult] = useMutation(acceptTeamInviteMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (_cache, { data }) => {
      const teamMembership = data?.acceptTeamInvite;
      if (!teamMembership) {
        return;
      }
      trackTeamEvent(EVENT_TEAM_INVITE_ACCEPTED, teamMembership);
    },
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
    ],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    acceptTeamInvitation: acceptInvitation,
  };
};
