import { FC, useState } from "react";

import { PhaseType } from "@/components/_domain/phase/phase-type";
import { Button } from "@/components/_ui/button";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";

import { Phase } from "../model/phase.schema";

import PhaseFormCreate from "./actions/phase-form-create";
import { PhaseTableRow } from "./phase-table-row";

export interface PhaseTableSectionProps {
  workspaceId: string;
  phaseType: PhaseType;
  phaseList: Phase[];
  className?: string;
}

export const PhaseTableSection: FC<PhaseTableSectionProps> = ({ workspaceId, phaseType, phaseList, className }) => {
  const [showCreatePhaseForm, setShowCreatePhaseForm] = useState(false);
  const { hasPermission: hasPermissionToManageWorkspace } = useCheckPermission({
    resource: "workspace",
    resourceId: workspaceId,
    action: "manage",
  });

  function handleCreatePhaseButtonPressed() {
    setShowCreatePhaseForm(true);
  }

  function onFormCanceled() {
    setShowCreatePhaseForm(false);
  }

  function onFormSuccess() {
    setShowCreatePhaseForm(false);
  }

  return (
    <div className={className} data-testid="phase-table-section">
      <div className="mb-2 flex w-full flex-row items-center justify-between">
        <div className="text-muted-foreground">{phaseType}</div>
        <div>
          {hasPermissionToManageWorkspace && (
            <Button
              size="icon"
              variant="ghost"
              className="text-base text-foreground/80"
              onClick={handleCreatePhaseButtonPressed}
            >
              +
            </Button>
          )}
        </div>
      </div>
      {phaseList.map((p) => (
        <PhaseTableRow key={p.id} phase={p} hideDeleteAction={phaseList.length < 2} />
      ))}
      {showCreatePhaseForm && (
        <PhaseFormCreate
          workspaceId={workspaceId}
          phaseType={phaseType}
          onCanceled={onFormCanceled}
          onSuccess={onFormSuccess}
        />
      )}
    </div>
  );
};

export default PhaseTableSection;
