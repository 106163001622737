import React, { forwardRef, useCallback, useState } from "react";

import { useGetSavedViewListForUrl } from "@/components/_domain/saved-view/hooks/useSavedView/useGetSavedViewForUrl";
import { type PermissionCheck } from "@/hooks/api/common/usePermission/types";
import { useCheckPermission } from "@/hooks/api/common/usePermission/useCheckPermission";
import { useCheckPermissionList } from "@/hooks/api/common/usePermission/useCheckPermissionList";

export interface SavedViewPrefetchProps {
  workspaceId: string;
}

export interface PermissionPrefetchProps extends PermissionCheck {}

export interface PrefetchConfig {
  to?: string;
  prefetch?: {
    savedViewList?: SavedViewPrefetchProps;
    permission?: PermissionPrefetchProps;
    permissionList?: PermissionPrefetchProps[];
  };
  onMouseEnter?: () => void;
}

export interface PrefetchHandlerProps {
  prefetchConfig?: PrefetchConfig;
  children: ((props: { onMouseEnter: () => void }) => React.ReactElement) | React.ReactElement;
}

/**
 * Custom Link component that provides prefetching capability without rendering any DOM element
 */
export const PrefetchHandler = forwardRef<HTMLElement, PrefetchHandlerProps>(
  ({ children, prefetchConfig = {} }, ref) => {
    const [hasHovered, setHasHovered] = useState(false);

    // Memoize the onMouseEnter handler
    const handleMouseEnter = useCallback(() => {
      setHasHovered(true);
      if (prefetchConfig.onMouseEnter) {
        prefetchConfig.onMouseEnter(); // Call original onMouseEnter if provided
      }
    }, [prefetchConfig]);

    useGetSavedViewListForUrl({
      url: prefetchConfig.to ?? "",
      workspaceId: prefetchConfig.prefetch?.savedViewList?.workspaceId ?? "",
      enabled: prefetchConfig.prefetch?.savedViewList && !!prefetchConfig.to && hasHovered,
    });

    useCheckPermission({
      resource: prefetchConfig.prefetch?.permission?.resource ?? "workspace",
      action: prefetchConfig.prefetch?.permission?.action ?? "view",
      resourceId: prefetchConfig.prefetch?.permission?.resourceId ?? "",
      enabled: prefetchConfig.prefetch?.permission && hasHovered,
    });

    useCheckPermissionList({
      permissions: prefetchConfig.prefetch?.permissionList ?? [],
      enabled: !!prefetchConfig.prefetch?.permissionList && hasHovered,
    });

    return typeof children === "function"
      ? children({ onMouseEnter: handleMouseEnter })
      : React.cloneElement(children, { onMouseEnter: handleMouseEnter, ref });
  },
);

PrefetchHandler.displayName = "PrefetchHandler";
