import { FC, useMemo } from "react";

import IconHeartbeatCircle from "@/components/_icons/IconHeartbeatCircle";
import { cn } from "@/lib/utils";

import { StatusList, StatusType } from "./status-types";

interface StatusViewProps {
  type?: StatusType | null;
  modified?: boolean;
  logoOnly?: boolean;
  className?: string;
}

export const StatusView: FC<StatusViewProps> = ({ type, modified, ...props }) => {
  const color = useMemo(() => {
    if (!type) {
      return "#666a73";
    }
    if (type === StatusList.onTrack) {
      return "#42af22";
    }
    if (type === StatusList.atRisk) {
      return "#c98d21";
    }
    if (type === StatusList.offTrack) {
      return "#d85d4f";
    }
    if (type === StatusList.blocked) {
      return "#44484F";
    }
  }, [type]);

  return (
    <div className="flex flex-row items-center gap-2">
      <IconHeartbeatCircle className={cn(["h-4 w-4", props.className])} style={{ color: color }} />
      {!props.logoOnly && (
        <>
          {!type && <span style={{ color: color }}>Nicht zugewiesen</span>}
          {type === StatusList.onTrack && <span style={{ color: color }}>On Track</span>}
          {type === StatusList.atRisk && <span style={{ color: color }}>At Risk</span>}
          {type === StatusList.offTrack && <span style={{ color: color }}>Off Track</span>}
          {type === StatusList.blocked && <span style={{ color: color }}>Blocked</span>}
          {modified && <div className="ml-1 h-1.5 w-1.5 rounded-full" style={{ backgroundColor: color }}></div>}
        </>
      )}
    </div>
  );
};

export default StatusView;
