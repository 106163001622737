import { Navigate } from "react-router-dom";

import { useCurrentTeamContext } from "@/components/_domain/team/hooks/useCurrentTeamContext";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";

export function TeamPage() {
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentTeam } = useCurrentTeamContext();

  // Redirect to the climate actions page
  return <Navigate to={`/${currentWorkspace.workspaceUrl}/teams/${currentTeam.id}/climate-actions`} replace={true} />;
}
