import { graphql } from "../../generated/gql";

export const superuser_teamListForWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query superuser_teamListForWorkspace($input: String!) {
    superuser_teamListForWorkspace(workspaceId: $input) {
      id
      workspaceId
      name
      visibility
      logo
      logoType
      color
      teamMembershipList {
        id
        userId
        teamId
        role
        status
        user {
          id
          email
          username
          fullname
          profilePicture
        }
      }
    }
  }
`);
