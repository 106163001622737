import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { EMPTY_SAVED_VIEW } from "../models/filter-data-constants";
import { SavedView, SavedViewInput, savedViewInputSchema } from "../models/saved-views-model";

import {
  parseSavedView,
  useCreateSavedView,
  useDeleteSavedView,
  useGetSavedViewListForUrl,
  useUpdateSavedView,
} from "./useSavedView";

const DEBUG_SAVED_VIEW_SYNC = false;

const debugLog = (...args: unknown[]) => {
  if (DEBUG_SAVED_VIEW_SYNC) {
    debugLog(...args);
  }
};

interface PathnameState {
  pathname: string;
  savedViews: SavedView[];
  defaultView: SavedView | null | undefined;
  selectedView: SavedView;
  currentView: SavedView;
  loading: boolean;
  error: Error | undefined;
  __fullyLoadedFromContextProvider?: boolean;
}

interface GlobalSavedViewsContextType {
  // State objects (current pathname)
  pathname: string;
  savedViewState: PathnameState;
  isDirty: boolean;

  // State getters (function-based)
  getSavedViewState: (pathname: string) => PathnameState;
  getIsDirty: (pathname: string) => boolean;

  // State setters
  setSelectedView: (pathname: string, view: SavedView) => void;
  setCurrentView: (pathname: string, view: SavedView) => void;
  resetCurrentView: (pathname: string) => void;
  clearState: (pathname: string) => void;

  // Actions
  saveCurrentView: (pathname: string) => Promise<void>;
  createSavedView: (pathname: string, input: SavedViewInput) => Promise<SavedView | null | undefined>;
  updateSavedView: (
    pathname: string,
    id: string,
    input: Partial<SavedViewInput>,
  ) => Promise<SavedView | null | undefined>;
  deleteSavedView: (id: string) => Promise<SavedView | null | undefined>;
}

const GlobalSavedViewsContext = createContext<GlobalSavedViewsContextType | undefined>(undefined);

interface GlobalSavedViewsProviderProps {
  workspaceId: string;
  children: ReactNode;
}

const usePathnameState = (pathname: string, workspaceId: string) => {
  debugLog("usePathnameState", pathname);
  const { savedViews, defaultView, loading, error } = useGetSavedViewListForUrl({
    workspaceId: workspaceId,
    url: pathname,
  });

  const pathnameState: PathnameState = {
    pathname,
    savedViews,
    defaultView,
    loading,
    error,
    // default values, should only be set the first time (see below)
    selectedView: defaultView ?? EMPTY_SAVED_VIEW,
    currentView: defaultView ?? EMPTY_SAVED_VIEW,
    __fullyLoadedFromContextProvider: true,
  };

  return pathnameState;
};

export const GlobalSavedViewsProvider: FC<GlobalSavedViewsProviderProps> = ({ children, workspaceId }) => {
  const [pathnameStates, setPathnameStates] = useState<Record<string, PathnameState>>({});
  const location = useLocation();

  // Initialize state for current pathname
  // - (!) re-renders whenever the component changed
  // - (!) must rerender everytime to get the correct data from the hook
  const currentPathnameState = usePathnameState(location.pathname, workspaceId);

  useEffect(() => {
    // -------------------------------------------------
    // Note:
    // - This effect is triggered when the location changes.
    // - It updates the pathnameStates with the new pathnameState.
    // - The hasDataChanged check is used to prevent infinite re-renders.
    // -------------------------------------------------
    setPathnameStates((prev) => {
      // OPTION A: If this pathname doesn't exist yet (initial load before data is loaded)
      if (!prev[location.pathname]) {
        debugLog("A initializing pathnameState for", location.pathname);
        return {
          ...prev,
          [location.pathname]: currentPathnameState,
        };
      }

      // If this pathname already exists in state
      const existingState = prev[location.pathname];

      // (!) Check if the data has actually changed to avoid infinite re-renders
      // ---------------------------------
      const hasDataChanged =
        JSON.stringify(existingState.savedViews) !== JSON.stringify(currentPathnameState.savedViews) ||
        JSON.stringify(existingState.defaultView) !== JSON.stringify(currentPathnameState.defaultView) ||
        existingState.loading !== currentPathnameState.loading ||
        existingState.error !== currentPathnameState.error;

      // If nothing has changed, return the previous state
      if (!hasDataChanged) {
        return prev;
      }
      // ---------------------------------

      // OPTION B: If this pathname exists but loading is still true (initial load after data is loaded, but not applied yet)
      if (existingState.loading) {
        debugLog("B initializing pathnameState for", location.pathname);
        return {
          ...prev,
          [location.pathname]: currentPathnameState,
        };
      }

      // OPTION C:  If this pathname already exists in state and was fully loaded before (a refetch happening behind the scenes)
      debugLog("C updating pathnameState for", location.pathname);
      return {
        ...prev,
        [location.pathname]: {
          pathname: location.pathname,

          // Update these properties from the fresh data
          savedViews: currentPathnameState.savedViews,
          defaultView: currentPathnameState.defaultView,
          loading: currentPathnameState.loading,
          error: currentPathnameState.error,

          // Preserve these properties from the existing state
          selectedView: existingState.selectedView,
          currentView: existingState.currentView,
        },
      };
    });
    // (!) must rerender on location change
    // (!) must rerender everytime the hook updates (necessary for initial load and when passive refetches are triggered)
  }, [location.pathname, currentPathnameState]);

  const getPathnameState = useCallback(
    (pathname: string): PathnameState => {
      return (
        pathnameStates[pathname] ?? {
          savedViews: [],
          defaultView: null,
          selectedView: EMPTY_SAVED_VIEW,
          currentView: EMPTY_SAVED_VIEW,
          loading: false,
          error: undefined,
        }
      );
    },
    // We want to make sure this also runs, when the pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, pathnameStates],
  );

  const updatePathnameState = useCallback((pathname: string, updates: Partial<PathnameState>) => {
    debugLog("updatePathnameState", pathname, updates);

    setPathnameStates((prev) => {
      return {
        ...prev,
        [pathname]: { ...prev[pathname], ...updates },
      };
    });
  }, []);

  // Create mutation hooks at component level
  const { createSavedViewMutation } = useCreateSavedView();
  const { updateSavedViewMutation } = useUpdateSavedView();
  const { deleteSavedViewMutation } = useDeleteSavedView();

  const contextValue = {
    // State objects (current pathname)
    pathname: location.pathname,
    savedViewState: getPathnameState(location.pathname),
    isDirty: (() => {
      const state = getPathnameState(location.pathname);
      return JSON.stringify(state.selectedView) !== JSON.stringify(state.currentView);
    })(),

    // State getters (function-based)
    getSavedViewState: (pathname: string) => getPathnameState(pathname),
    getIsDirty: (pathname: string) => {
      const state = getPathnameState(pathname);
      return JSON.stringify(state.selectedView) !== JSON.stringify(state.currentView);
    },

    // State setters
    setSelectedView: (pathname: string, view: SavedView) => {
      updatePathnameState(pathname, { selectedView: view, currentView: view });
    },
    setCurrentView: (pathname: string, view: SavedView) => {
      updatePathnameState(pathname, { currentView: view });
    },
    resetCurrentView: (pathname: string) => {
      const state = getPathnameState(pathname);
      updatePathnameState(pathname, { currentView: state.selectedView });
    },
    clearState: (pathname: string) => {
      setPathnameStates((prev) => {
        const newState = { ...prev };
        delete newState[pathname];
        return newState;
      });
    },

    // Actions
    saveCurrentView: async (pathname: string) => {
      const state = getPathnameState(pathname);
      if (!state.selectedView.id || !state.currentView.tableConfiguration) return;

      try {
        const result = await updateSavedViewMutation({
          variables: {
            input: {
              id: state.selectedView.id,
              tableConfiguration: JSON.stringify(state.currentView.tableConfiguration),
            },
          },
        });

        const parsedUpdatedView = parseSavedView(result.data?.updateSavedView);
        if (parsedUpdatedView) {
          updatePathnameState(pathname, { selectedView: parsedUpdatedView, currentView: parsedUpdatedView });
        }
      } catch (error) {
        console.error("Error saving view:", error);
        throw error;
      }
    },

    createSavedView: async (pathname: string, input: SavedViewInput) => {
      const validatedInput = savedViewInputSchema.safeParse(input);
      if (!validatedInput.success) return null;

      const result = await createSavedViewMutation({
        variables: {
          input: {
            ...validatedInput.data,
            tableConfiguration: JSON.stringify(validatedInput.data.tableConfiguration),
          },
        },
      });

      const parsedNewView = parseSavedView(result.data?.createSavedView);
      if (parsedNewView) {
        updatePathnameState(pathname, { selectedView: parsedNewView, currentView: parsedNewView });
      }
      return parsedNewView;
    },

    updateSavedView: async (pathname: string, id: string, input: Partial<SavedViewInput>) => {
      const validatedInput = savedViewInputSchema.partial().safeParse(input);
      if (!validatedInput.success) {
        console.error("Invalid input:", validatedInput.error);
        return null;
      }

      try {
        const result = await updateSavedViewMutation({
          variables: {
            input: {
              id,
              name: validatedInput.data.name ?? undefined,
              isDefault: validatedInput.data.isDefault ?? undefined,
              isBaseView: validatedInput.data.isBaseView ?? undefined,
              tableConfiguration: validatedInput.data.tableConfiguration
                ? JSON.stringify(validatedInput.data.tableConfiguration)
                : undefined,
            },
          },
        });

        const parsedUpdatedView = parseSavedView(result.data?.updateSavedView);
        if (parsedUpdatedView) {
          updatePathnameState(pathname, { selectedView: parsedUpdatedView, currentView: parsedUpdatedView });
        }
      } catch (error) {
        console.error("Error saving view:", error);
        throw error;
      }
    },

    deleteSavedView: async (id: string) => {
      const result = await deleteSavedViewMutation({
        variables: { input: { id } },
      });
      return parseSavedView(result.data?.deleteSavedView);
    },
  };

  useEffect(() => {
    debugLog("[1] GlobalSavedViewsProvider – ContextValue:", contextValue);
    debugLog("[1] GlobalSavedViewsProvider – pathnameStates:", pathnameStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValue]);

  debugLog("[0] GlobalSavedViewsProvider");
  return <GlobalSavedViewsContext.Provider value={contextValue}>{children}</GlobalSavedViewsContext.Provider>;
};

export const useGlobalSavedViewContext = () => {
  const context = useContext(GlobalSavedViewsContext);
  if (context === undefined) {
    throw new Error("useGlobalSavedViewContext must be used within a GlobalSavedViewsProvider");
  }
  return context;
};
