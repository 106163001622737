import { deletePriorityInstanceMutationDocument } from "@/graphql/common/priority-instance";
import { type DeletePriorityInstanceMutation } from "@/graphql/generated/graphql";
import { onGenericEntityInstanceDelete } from "@/services/apollo.service/cache-update-utils";
import { useMutation } from "@apollo/client";

import { EVENT_PRIORITY_INSTANCE_DELETED, trackPriorityEvent } from "./tracking";

export type DeletedPriorityInstance = DeletePriorityInstanceMutation["deletePriorityInstance"];

export interface UseDeletePriorityInstanceProps {
  onCompleted?: (data: DeletedPriorityInstance) => void;
  onError?: (e: Error) => void;
}

export const useDeletePriorityInstance = ({ onCompleted, onError }: UseDeletePriorityInstanceProps = {}) => {
  const [deletePriorityInstance] = useMutation(deletePriorityInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const deletedPriorityInstance = data?.deletePriorityInstance;
      if (!deletedPriorityInstance) {
        return;
      }

      onGenericEntityInstanceDelete({
        cache,
        deletedEntityInstance: {
          ...deletedPriorityInstance,
          __parentId: deletedPriorityInstance.priorityId,
        },
        typename: "PriorityInstance",
      });
      trackPriorityEvent(EVENT_PRIORITY_INSTANCE_DELETED, deletedPriorityInstance);
    },
  });

  function onUpdateCompleted(data: DeletePriorityInstanceMutation) {
    if (onCompleted) {
      onCompleted(data.deletePriorityInstance);
    }
  }

  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deletePriorityInstance,
  };
};
