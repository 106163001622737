import { Outlet, useNavigate } from "react-router-dom";

import { BasePageLayout } from "@/components/_layout/base-page-layout";
import { SidebarAdmin } from "@/components/_layout/sidebar-admin";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useAuth } from "@/hooks/useAuth";
import { posthogService } from "@/services/posthog.service";

export default function Page() {
  const navigate = useNavigate();

  const { isSuperUser } = useCurrentUserContext();
  const { setAuthenticated } = useAuth();

  if (!isSuperUser) {
    posthogService.capture("UNAUTHORIZED_ADMIN_ACCESS_ATTEMPT");

    setAuthenticated(false);
    navigate("/");
    return <></>;
  }

  return (
    <BasePageLayout
      offsetTop={12}
      renderSidebar={() => <SidebarAdmin />}
      renderContent={() => (
        <>
          <div
            className="absolute left-0 right-0 top-0 h-3 select-none text-center text-xxs text-white"
            style={{ background: "red", zIndex: 100 }}
          >
            Admin Panel
          </div>
          <Outlet />
        </>
      )}
    />
  );
}
