import { createContext, type FC, type PropsWithChildren, useContext } from "react";

import { ErrorView } from "@/components/error-view";
import { Loading } from "@/components/loading-view";
import { useGetTeam } from "@/hooks/api/common/useWorkspace/useGetTeam";

import { type Team } from "../models/team-model";

type CurrentTeamContext = {
  currentTeam: Team;
};

const CurrentTeamContext = createContext<CurrentTeamContext | null>(null);

export interface CurrentTeamContextProviderProps extends PropsWithChildren {
  teamId: string;
}

export const CurrentTeamContextProvider: FC<CurrentTeamContextProviderProps> = ({ children, teamId }) => {
  const { team, loading, error } = useGetTeam({ id: teamId });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorView />;
  }

  if (!team) {
    return <ErrorView />;
  }

  return <CurrentTeamContext.Provider value={{ currentTeam: team }}>{children}</CurrentTeamContext.Provider>;
};

export const useCurrentTeamContext = () => {
  const context = useContext(CurrentTeamContext);

  if (!context) {
    throw new Error("useCurrentTeamContext must be used within a CurrentTeamContextProvider");
  }

  return context;
};

// This is an unsafe hook that returns the **CurrentTeamContext | null**
//
// - Generally it is better to use the **useCurrentTeamContext** hook
// - Only use this hook if cannot be sure that the currentTeamContext is available
export const useUnsafeCurrentTeamContext = (): CurrentTeamContext | null => {
  return useContext(CurrentTeamContext);
};
