import { ExpandedState } from "@tanstack/react-table";

export const EMPTY_CLIMATE_ACTION_TABLE_CONFIGURATION = {
  columnFilters: [],
  columnVisibility: {},
  columnOrder: [],
  sorting: [],
  expanded: {},
  rowSelection: {},
  globalFilter: "",
};

export const EMPTY_TABLE_CONFIGURATION = {
  climateActions: EMPTY_CLIMATE_ACTION_TABLE_CONFIGURATION,
};

export const DEFAULT_EXPANDED_STATE: ExpandedState = {
  0: true,
  1: true,
};

export const EMPTY_SAVED_VIEW = {
  id: "",
  name: "",
  tableConfiguration: EMPTY_TABLE_CONFIGURATION,
  isDefault: false,
  url: "",
  isBaseView: false,
};
