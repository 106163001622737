import { posthogService } from "@/services/posthog.service";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

export const EVENT_CLIMATE_ACTION_CREATED = "MUTATION:CLIMATE_ACTION:CREATED";
export const EVENT_CLIMATE_ACTION_UPDATED = "MUTATION:CLIMATE_ACTION:UPDATED";
export const EVENT_CLIMATE_ACTION_DELETED = "MUTATION:CLIMATE_ACTION:DELETED";

type ClimateActionEventType =
  | typeof EVENT_CLIMATE_ACTION_CREATED
  | typeof EVENT_CLIMATE_ACTION_UPDATED
  | typeof EVENT_CLIMATE_ACTION_DELETED;

const trackingEnabled = VITE_ENVIRONMENT === "production";

export const trackClimateActionEvent = (event: ClimateActionEventType, payload: unknown) => {
  if (!trackingEnabled) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posthogService.capture(event, payload as any);
};
