import { FC, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useCurrentClimateActionContext } from "@/components/_domain/climate-action/hooks/useCurrentClimateActionContext";
import { makeTeamUrl } from "@/components/_domain/team/lib/make-team-url";
import { TeamView } from "@/components/_domain/team/team-view";
import { useCurrentWorkspaceContext } from "@/components/_domain/workspace/hooks/useCurrentWorkspaceContext";
import { makeWorkspaceUrl } from "@/components/_domain/workspace/lib/make-workspace-url";
import { HeaderLayout } from "@/components/_layout/header-layout";
import { Link } from "@/components/link";
import { NotFoundView } from "@/components/not-found-view";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";
import { useCurrentUserContext } from "@/hooks/api/common/useUser";
import { useTeamListForUserInWorkspace } from "@/hooks/api/common/useWorkspace";

import { ClimateActionTabNavigationBar } from "./climate-action-tabs/climate-action-tab-navigation-bar";
import { ClimateActionUIContextProvider, useClimateActionUIState } from "./hooks/useClimateActionUIState";
import { ProgressUpdateUIStateContextProvider } from "./hooks/useCreateProgressUpdateUIState";
import { ClimateActionEditDropdown } from "./climate-action-edit-dropdown";
import { ClimateActionParentLabel } from "./climate-action-parent-label";
import { ClimateActionSheet } from "./climate-action-sheet";
import { ClimateActionTabView } from "./climate-action-tabs";
import { ProgressSnapshotDialog } from "./progress-snapshot";

const InnerPage: FC = () => {
  const { currentUser } = useCurrentUserContext();
  const { currentWorkspace } = useCurrentWorkspaceContext();
  const { currentClimateAction } = useCurrentClimateActionContext();

  const { activeTeamList } = useTeamListForUserInWorkspace({
    workspaceId: currentWorkspace.id,
    userId: currentUser?.id,
  });

  const location = useLocation();
  const { setShowCreateChildClimateActionView, selectedTab, setSelectedTab } = useClimateActionUIState();

  useEffect(() => {
    hideCreateChildClimateAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const firstActiveTeamOfUser = useMemo(() => {
    return activeTeamList.find((team) => currentClimateAction?.teamInstanceList.some((ti) => ti.teamId === team.id));
  }, [currentClimateAction, activeTeamList]);

  function showCreateChildClimateAction() {
    if (selectedTab !== "details") {
      setSelectedTab("details");
    }
    setTimeout(function () {
      // use setTimeout to make sure the tab is switched before
      // setShowChildClimateActionView is called
      setShowCreateChildClimateActionView(true);
    }, 1);
  }

  function hideCreateChildClimateAction() {
    setShowCreateChildClimateActionView(false);
  }

  return (
    <div className="flex h-full w-full flex-row">
      <div className="flex h-full max-h-full w-full flex-col overflow-y-hidden">
        <HeaderLayout>
          <div className="flex flex-row items-center gap-2">
            {firstActiveTeamOfUser && (
              <>
                <Link to={makeTeamUrl({ workspace: currentWorkspace, team: firstActiveTeamOfUser, path: "" })}>
                  <TeamView
                    team={{
                      id: firstActiveTeamOfUser.id,
                    }}
                  />
                </Link>
                <div>›</div>
                <Link
                  to={makeTeamUrl({
                    workspace: currentWorkspace,
                    team: firstActiveTeamOfUser,
                    path: "climate-actions",
                  })}
                >
                  <div className="flex max-w-[8rem] flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
                    Maßnahmen
                  </div>
                </Link>
              </>
            )}
            {!firstActiveTeamOfUser && (
              <Link to={makeWorkspaceUrl({ workspace: currentWorkspace, path: "climate-actions" })}>
                <div className="flex max-w-[8rem] flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
                  Maßnahmen
                </div>
              </Link>
            )}
            <div>›</div>
            <div className="block max-w-[8rem] overflow-hidden truncate text-ellipsis whitespace-nowrap">
              {currentClimateAction?.title !== "" ? (
                currentClimateAction.title
              ) : (
                <span className="text-muted-foreground">Kein Titel</span>
              )}
            </div>
            <div>
              <ClimateActionEditDropdown
                teamId={firstActiveTeamOfUser?.id}
                climateAction={currentClimateAction}
                workspaceId={currentWorkspace.id}
                callbacks={{ showCreateChildClimateAction }}
              />
            </div>
            <FavoriteButton pageName={currentClimateAction?.title} />
          </div>
          <div className="mr-4 flex flex-row items-center">
            <ProgressSnapshotDialog climateAction={currentClimateAction} workspace={currentWorkspace} />
          </div>
        </HeaderLayout>

        <div className="flex w-full flex-row flex-wrap items-center px-6 py-6 text-sm">
          {!!currentClimateAction?.parentId && (
            <div className="w-full">
              <div className="mb-4">
                <ClimateActionParentLabel climateAction={{ id: currentClimateAction.parentId }} />
              </div>
            </div>
          )}
          <div className="mb-2 text-lg">
            {currentClimateAction?.title !== "" ? (
              currentClimateAction.title
            ) : (
              <span className="text-muted-foreground">Kein Titel</span>
            )}
          </div>
          <ClimateActionTabNavigationBar />
        </div>

        <div className="overflow-y-scroll pb-24">
          <div className="mx-auto flex grow flex-col items-stretch px-6">
            <ClimateActionTabView
              climateAction={currentClimateAction}
              workspace={currentWorkspace}
              tabId={selectedTab}
            />
          </div>
        </div>
      </div>
      <ClimateActionSheet climateAction={currentClimateAction} />
    </div>
  );
};

export const ClimateActionPage = () => {
  const { climateActionId, tab } = useParams();
  const { currentWorkspace } = useCurrentWorkspaceContext();

  if (!climateActionId) {
    return <NotFoundView />;
  }

  return (
    <ClimateActionUIContextProvider
      workspace={currentWorkspace}
      climateAction={{ id: climateActionId }}
      selectedTab={tab ? { id: tab } : undefined}
    >
      <ProgressUpdateUIStateContextProvider>
        <InnerPage />
      </ProgressUpdateUIStateContextProvider>
    </ClimateActionUIContextProvider>
  );
};
