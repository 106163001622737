import { useMemo } from "react";

import { getLabelListForWorkspaceQueryDocument } from "@/graphql/common/label";
import { groupBy } from "@/lib/utils";
import { useQuery } from "@apollo/client";

export interface UseGetLabelListForWorkspaceProps {
  workspaceId?: string;
  enabled?: boolean;
}

export const useGetLabelListForWorkspace = ({ workspaceId, enabled = true }: UseGetLabelListForWorkspaceProps) => {
  const queryResult = useQuery(getLabelListForWorkspaceQueryDocument, {
    variables: { input: { workspaceId: workspaceId ?? "" } },
    skip: !enabled,
  });

  const labelList = useMemo(() => {
    const { data } = queryResult;

    if (!data) {
      return [];
    }

    return data.labelsForWorkspace;
  }, [queryResult]);

  const labelListWithoutGroup = useMemo(() => {
    return labelList.filter((l) => !l.labelGroupId);
  }, [labelList]);

  const labelListGroupedByGroupId = useMemo(() => {
    return groupBy(labelList, (label) => label.labelGroupId);
  }, [labelList]);

  return {
    queryResult,
    labelList,
    labelListWithoutGroup,
    labelListGroupedByGroupId,
  };
};
