import { useMemo } from "react";
import { cva } from "class-variance-authority";
import { GripVertical } from "lucide-react";

import { Button } from "@/components/_ui/button";
import { ScrollArea, ScrollBar } from "@/components/_ui/scroll-area";
import { cn } from "@/lib/utils";
import { useDndContext } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { BoardCard } from "./board-card";
import { BoardColumn, BoardColumnConfig, BoardItem, BoardItemConfig, ColumnDragData } from "./board-types";

interface BoardColumnViewProps<U extends BoardItem> {
  column: BoardColumn<U>;
  items: U[];
  isOverlay?: boolean;
  canMoveColumn?: boolean;
  renderItem: (config: BoardItemConfig<U>) => React.ReactNode;
  renderColumnHeader: (config: BoardColumnConfig<U>) => React.ReactNode;
  renderColumnFooter: (config: BoardColumnConfig<U>) => React.ReactNode;
}

export function BoardColumnView<U extends BoardItem>({
  column,
  items,
  isOverlay,
  canMoveColumn,
  renderColumnHeader,
  renderColumnFooter,
  renderItem,
}: BoardColumnViewProps<U>) {
  const itemIds = useMemo(() => {
    return items.map((item) => item.id);
  }, [items]);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    } satisfies ColumnDragData<U>,
    attributes: {
      roleDescription: `Column: ${column.title}`,
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cn(
        "group flex h-[90vh] w-[330px] flex-shrink-0 snap-center flex-col rounded-lg border-transparent bg-muted/20 px-2 py-4 dark:bg-canvas/30",
        {
          dragging: isOverlay ? "overlay" : isDragging ? "border-2 border-transparent" : "",
        },
      )}
    >
      {renderColumnHeader({
        column,
        isHover: false,
      })}
      <div className="flex flex-col gap-2 pt-4"></div>

      {canMoveColumn && (
        <Button
          variant={"ghost"}
          {...attributes}
          {...listeners}
          className=" relative -ml-2 h-auto cursor-grab p-1 text-primary/50"
        >
          <span className="sr-only">{`Move column: ${column.title}`}</span>
          <GripVertical />
        </Button>
      )}

      <div className="flex flex-grow flex-col gap-2 p-2">
        <SortableContext items={itemIds}>
          {items.map((item) => {
            return <BoardCard key={item.id} item={item} renderItem={renderItem} isOverlay={isOverlay} />;
          })}
        </SortableContext>
        {renderColumnFooter({
          column,
          isHover: false,
        })}
      </div>
    </div>
  );
}

export function BoardContainer({ children }: { children: React.ReactNode }) {
  const dndContext = useDndContext();

  const variations = cva("px-2 md:px-0 flex lg:justify-center pb-4", {
    variants: {
      dragging: {
        default: "snap-x snap-mandatory",
        active: "snap-none",
      },
    },
  });

  return (
    <ScrollArea
      className={variations({
        dragging: dndContext.active ? "active" : "default",
      })}
    >
      <div className="flex flex-row items-center justify-center gap-4 px-2">{children}</div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}
