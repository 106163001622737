import { ClimateAction } from "@/components/_domain/climate-action/models";
import { TableCell, TableRow } from "@/components/_ui/table";
import { flexRender, Row } from "@tanstack/react-table";

interface ClimateActionListViewRowProps {
  row: Row<ClimateAction>;
}

export function ClimateActionListViewRow({ row }: ClimateActionListViewRowProps) {
  return (
    <TableRow
      key={row.id}
      data-state={row.getIsSelected() && "selected"}
      className="text-xs last:border-b"
      data-testid={"climate-action-list-view-row"}
    >
      {row.getVisibleCells().map((cell, j) => (
        <TableCell
          key={cell.id}
          style={{
            width: cell.column.getSize() === Number.MAX_SAFE_INTEGER ? "min-content" : cell.column.getSize(),
            paddingRight: row.getVisibleCells().length === j + 1 ? "30px" : undefined,
          }}
          className="px-0.5"
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  );
}
