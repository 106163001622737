import { useMemo } from "react";

import { getPriorityQueryDocument } from "@/graphql/common/priority";
import { useQuery } from "@apollo/client";

export interface UseGetPriorityProps {
  id?: string;
  enabled?: boolean;
}

export const useGetPriority = ({ id, enabled = true }: UseGetPriorityProps) => {
  const queryResult = useQuery(getPriorityQueryDocument, {
    variables: { input: { id: id ?? "" } },
    skip: !id || !enabled,
  });

  const priority = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.priority;
  }, [queryResult.data]);

  return {
    priority,
    loading: queryResult.loading,
    error: queryResult.error,
    queryResult,
  };
};
